import { Col, Empty, Image, Row, Space } from "antd";
import React, { useState } from "react";
import previewIcon from "assets/icons/previewIc.svg";

export default function ImageGallery(props) {
  const preSignedImg = props?.data;
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);

  return (
    <div>
      <Row>
        {preSignedImg.length === 0 ? (
          <Col span={16} md={24} xs={24} sm={24} className="center-btn">
            <div className="no-data">
              <Col md={24}>
                <Empty />
              </Col>
            </div>
          </Col>
        ) : (
          <Col span={20} md={24} xs={24} sm={24}>
            <Row gutter={[20, 20]}>
              {preSignedImg.map((image, index) => (
                <Col span={8} md={8} xs={12} sm={24}>
                  <div key={index} className="service-image-preview">
                    <Image
                      src={image}
                      className="service-image-preview-img"
                      onClick={() => setPreviewIndex(index)}
                      preview={{
                        visible: isPreviewVisible && previewIndex === index,
                        onVisibleChange: (visible, prevVisible) => {
                          if (!visible) setPreviewIndex(null);
                          setPreviewVisible(visible);
                        },
                      }}
                    />
                    <div className="hide-icon">
                      <Space>
                        <img
                          src={previewIcon}
                          alt="preview-icon"
                          onClick={() => {
                            setPreviewIndex(index);
                            setPreviewVisible(true);
                          }}
                        />
                      </Space>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}
