const formatDate = (dateString) => {
  const messageDate = new Date(dateString);
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const isSameDay = (date1, date2) => {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  };

  const options = {
    hour: "numeric",
    minute: "numeric",
  };

  if (isSameDay(messageDate, today)) {
    return `Today`;
  } else if (isSameDay(messageDate, yesterday)) {
    return `Yesterday`;
  } else {
    return `${messageDate.toLocaleDateString("en-GB")}`;
  }
};

export default formatDate;
