import { Grid } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";
import GlobalJoyride from "utility/GlobalJoyride";
import HomeTourStep from "utility/HomeTourStep";

export default function HomeTour(props) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const userProfileData = useSelector((state) => state.userReducer?.userData);
  const currentStepOffset = props?.isInviteCompleted ? -1 : 0;
  const individualOffset = props?.isIndividual ? -1 : 0;
  const dependentOffset = userProfileData?.role === "DEPENDANT" ? -1 : 0;

  let totalSteps = 8; // Default value

  if (userProfileData?.role === "DEPENDANT") {
    totalSteps = 6;
  } else if (props.isInviteCompleted) {
    if (props.isIndividual) {
      totalSteps = 6;
    } else {
      totalSteps = 7;
    }
  } else if (props.isIndividual) {
    if (props.isInviteCompleted) {
      totalSteps = 6;
    } else {
      totalSteps = 7;
    }
  }

  const homeSteps = [
    {
      target: ".search-first-step",
      content: (
        <HomeTourStep
          heading="Global Search"
          details="Perform a comprehensive search across the platform for
          any information or content."
          numbers={{ currentStep: 1, totalSteps: totalSteps }}
          previousClick=""
          nextClick={() =>
            props?.isInviteCompleted ||
            (props.isIndividual && props?.isInviteCompleted) ||
            userProfileData?.role === "DEPENDANT"
              ? setJoyrideStepIndex(2)
              : setJoyrideStepIndex(1)
          }
          hidePrevBtn={true}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".first-step",
      content: (
        <HomeTourStep
          heading="Invite Dependent"
          details="Click to invite your Dependent/Spouse."
          numbers={{
            currentStep: 2 + currentStepOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(0)}
          nextClick={() => setJoyrideStepIndex(2)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".second-step",
      content: (
        <HomeTourStep
          heading="Profile"
          details="Manage and personalize your experience with ease in the
          My Profile section."
          numbers={{
            currentStep: 3 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() =>
            props?.isInviteCompleted ||
            (props.isIndividual && props?.isInviteCompleted) ||
            userProfileData?.role === "DEPENDANT"
              ? setJoyrideStepIndex(0)
              : setJoyrideStepIndex(1)
          }
          nextClick={() => setJoyrideStepIndex(3)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-first-step",
      content: (
        <HomeTourStep
          heading="Services"
          details="Choose from a list of services"
          numbers={{
            currentStep: 4 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(2)}
          nextClick={() => setJoyrideStepIndex(4)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-second-step",
      content: (
        <HomeTourStep
          heading="Procedures"
          details="Users can access a comprehensive list of procedures, compare pricing and view available rewards."
          numbers={{
            currentStep: 5 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(3)}
          nextClick={() =>
            props.isIndividual ? setJoyrideStepIndex(6) : setJoyrideStepIndex(5)
          }
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-third-step",
      content: (
        <HomeTourStep
          heading="Get My Reward"
          details="After you've had a procedure, by submitting your
          Explantation of Benefits (EOB), you become eligible for
          a reward."
          numbers={{
            currentStep: 6 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(4)}
          nextClick={() => setJoyrideStepIndex(6)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-forth-step",
      content: (
        <HomeTourStep
          heading="FAQs"
          details="Find answers to commonly asked questions in our FAQs section.
"
          numbers={{
            currentStep:
              7 + currentStepOffset + individualOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() =>
            props.isIndividual ? setJoyrideStepIndex(4) : setJoyrideStepIndex(5)
          }
          nextClick={() => setJoyrideStepIndex(7)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".service-second-step",
      content: (
        <HomeTourStep
          heading="Ask your advocate"
          details="Communicate easily with our team of advocates."
          numbers={{
            currentStep:
              8 + currentStepOffset + individualOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(6)}
          nextClick=""
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
          isFinishBtn={true}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  const homeStepsMobile = [
    {
      target: ".search-first-step",
      content: (
        <HomeTourStep
          heading="Global Search"
          details="Perform a comprehensive search across the platform for
          any information or content."
          numbers={{ currentStep: 1, totalSteps: totalSteps }}
          previousClick=""
          nextClick={() =>
            props?.isInviteCompleted ||
            (props.isIndividual && props?.isInviteCompleted) ||
            userProfileData?.role === "DEPENDANT"
              ? setJoyrideStepIndex(2)
              : setJoyrideStepIndex(1)
          }
          hidePrevBtn={true}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".first-step",
      content: (
        <HomeTourStep
          heading="Invite Dependent"
          details="Click to invite your Dependent/Spouse."
          numbers={{
            currentStep: 2 + currentStepOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(0)}
          nextClick={() => setJoyrideStepIndex(2)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-forth-step",
      content: (
        <HomeTourStep
          heading="FAQs"
          details="Find answers to commonly asked questions in our FAQs section.
"
          numbers={{
            currentStep: 3 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() =>
            props?.isInviteCompleted
              ? setJoyrideStepIndex(0)
              : props.isIndividual &&
                screens.xs &&
                userProfileData?.role === "DEPENDANT"
              ? setJoyrideStepIndex(0)
              : props.isIndividual && screens.xs
              ? setJoyrideStepIndex(1)
              : setJoyrideStepIndex(1)
          }
          nextClick={() => setJoyrideStepIndex(3)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".service-second-step",
      content: (
        <HomeTourStep
          heading="Ask your advocate"
          details="Communicate easily with our team of advocates."
          numbers={{
            currentStep: 4 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(2)}
          nextClick={() => setJoyrideStepIndex(4)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },

    {
      target: ".home-first-step",
      content: (
        <HomeTourStep
          heading="Services"
          details="Choose from a list of services."
          numbers={{
            currentStep: 5 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(3)}
          nextClick={() => setJoyrideStepIndex(5)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-second-step",
      content: (
        <HomeTourStep
          heading="Procedures"
          details="Users can access a comprehensive list of procedures, compare pricing and view available rewards."
          numbers={{
            currentStep: 6 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(4)}
          nextClick={() =>
            props.isIndividual ? setJoyrideStepIndex(7) : setJoyrideStepIndex(6)
          }
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-third-step",
      content: (
        <HomeTourStep
          heading="Get My Reward"
          details="After you've had a procedure, by submitting your
          Explantation of Benefits (EOB), you become eligible for
          a reward."
          numbers={{
            currentStep: 7 + currentStepOffset + dependentOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() => setJoyrideStepIndex(5)}
          nextClick={() => setJoyrideStepIndex(7)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".home-myprofile-step",
      content: (
        <HomeTourStep
          heading="Profile"
          details="Manage and personalize your experience with ease in the
          My Profile section."
          numbers={{
            currentStep:
              8 + currentStepOffset + dependentOffset + individualOffset,
            totalSteps: totalSteps,
          }}
          previousClick={() =>
            userProfileData?.role === "DEPENDANT"
              ? setJoyrideStepIndex(5)
              : props?.isInviteCompleted ||
                (props.isIndividual && props?.isInviteCompleted)
              ? setJoyrideStepIndex(6)
              : setJoyrideStepIndex(5)
          }
          nextClick={() => setJoyrideStepIndex(3)}
          hidePrevBtn={false}
          closeTour={props?.setIsTourRunning}
          index={setJoyrideStepIndex}
          isFinishBtn={true}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={screens.xs ? homeStepsMobile : homeSteps}
        run={props?.isTourRunning}
        stepIndex={joyrideStepIndex}
        setTourIndex={setJoyrideStepIndex}
      />
    </>
  );
}
