import GlobalJoyride from "utility/GlobalJoyride";
import HomeTourStep from "utility/HomeTourStep";
import TourStepContent from "utility/TourStepContent";

export default function AdminInviteEmployee(props) {
  const adminEmployee = [
    {
      target: ".admin-first-step",
      content: (
        <HomeTourStep
          heading="Invite Employee"
          details="Click to Invite New Employee"
          numbers={{ currentStep: 1, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-second-step",
      content: (
        <TourStepContent
          heading="Invite Employee"
          details="Click to invite your employee."
          numbers={{ currentStep: 2, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-third-step",
      content: (
        <TourStepContent
          heading="Invite Link"
          details="Click on this button in order to invite your employee via
              link."
          numbers={{ currentStep: 3, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-forth-step",
      content: (
        <TourStepContent
          heading="Invite via link"
          details="Copy the link in order to invite your employee via invitation
              link"
          numbers={{ currentStep: 4, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-fifth-step",
      content: (
        <TourStepContent
          heading="Employee Email"
          details="Enter employee’s email address in order to sent the invitation"
          numbers={{ currentStep: 5, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-sixth-step",
      content: (
        <TourStepContent
          heading="Send Invite"
          details="Click to Send"
          numbers={{ currentStep: 6, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-seventh-step",
      content: (
        <TourStepContent
          heading="Invitation sent"
          details="Great! Your invitation has been sent successfully.
              "
          numbers={{ currentStep: 7, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={adminEmployee}
        run={props?.inviteAdminTour}
        stepIndex={props?.inviteAdminTourIndex}
        setTourIndex={props?.setInviteAdminTourIndex}
      />
    </>
  );
}
