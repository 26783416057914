import {
  Col,
  Row,
  Card,
  Radio,
  Divider,
  Button,
  Form,
  InputNumber,
  DatePicker,
  Input,
  message,
  Typography,
} from "antd";
import Container from "commons/Container";
import moment from "moment";
import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import "./Dpc.less";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import checked from "assets/icons/checked.svg";
import user from "assets/icons/emptyUser.svg";
import cross from "assets/icons/cross.svg";
import addMore from "assets/icons/addMoreDependent.svg";
import {
  cityPattern,
  disableSubmitButton,
  formatNumberUS,
  streetAddressPattern,
  tenDigit,
  zipPattern,
} from "utility/constant";
import IndividualInvite from "containers/MainLayout/InviteModals/IndividualInvite";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import SuccessModel from "utility/SuccessModel";
import { ListBillingPlans, UpdateEmployeeProfile } from "utility/action";
import { getEmployeeRegi } from "graphql/queries";
import { setUserData } from "store/actions/userData";
import { queries } from "@testing-library/react";
import { updateDpcDetails } from "graphql/mutations";

export default function DirectPrimaryCareBilling() {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [stripe, setStripe] = useState([]);
  const [inviteIndDependent, setInviteIndDependent] = useState(false);
  const [date, setDate] = useState("");
  const [dateValidation, setDateValidation] = useState("");
  const [loading, setLoading] = useState(false);
  const [checkutloading, setCheckoutLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [removedMembersEmail, setRemovedMembersEmail] = useState([]);

  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [memberForm] = Form.useForm();
  const userProfileData = useSelector((state) => state.userReducer?.userData);
  const dispatch = useDispatch();
  // const advocacyDependent = userProfileData?.members?.items?.map((member) => ({
  //   name: `${member.firstName} ${member.lastName}`,
  //   email: member.email,
  // }));
  // console.log("advocacyDependent", advocacyDependent);
  const [dependentList, setDependentList] = useState([]);
  const [showDependentList, setShowDependentList] = useState(false);

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const { Text } = Typography;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPlanFromUrl = searchParams.get("selectedPlan");

  const planDuration = selectedPlan === "DPC_MONTHLY" ? "month" : "year";
  const currentPlanPrize = selectedPlan === "DPC_MONTHLY" ? 120 : 1200;
  const dependentPrize = selectedPlan === "DPC_MONTHLY" ? 108 : 1080;

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const handleFormChange = disableSubmitButton([
    "streetAddress",
    "city",
    "state",
    "zip",
  ])(form, setSubmitDisabled);

  const onChangeDatepicker = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");

    setDateValidation(formattedEighteenYearsAgo);
    setDate(dateString);
  };

  const onChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    resolveStripe();
    console.log("userProfileData", userProfileData);
    if (userProfileData?.profile?.dob !== null) {
      setShowCheckout(true);
      setShowDependentList(true);

      const newDependentList = userProfileData.dpcMembers.items?.map(
        (member) => ({
          name: `${member.firstName} ${member.lastName}`,
          email: member.email,
        })
      );

      setDependentList([...newDependentList, ...dependentList]);
    } else {
      setShowCheckout(false);
      setShowDependentList(false);
    }
  }, []);

  useEffect(() => {
    if (selectedPlanFromUrl) {
      setSelectedPlan(selectedPlanFromUrl);
    }
  }, [location.search]);

  const IndInvite = async (values) => {
    setLoading(true);
    let url = `/employee/inviteDpcDep`;
    try {
      const myInit = {
        body: {
          email: values?.email,
          employeeID: userProfileData?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          relation: values?.relation,
          phone: values?.phone,
          dob: moment(values?.dob).format("YYYY-MM-DD"),
        },
      };

      await API.post("bazarhealthREST", url, myInit);

      const newDependent = {
        name: `${values?.firstName} ${values?.lastName}`,
        email: values?.email,
      };
      setDependentList([newDependent, ...dependentList]);
      setShowDependentList(true);
      setLoading(false);
      memberForm.setFieldsValue({
        dob: null, // Set the date of birth field to null or an initial value
      });

      // Reset other fields
      memberForm.resetFields();
      // memberForm.resetFields();
      setInviteIndDependent(false);
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      setRemovedMembersEmail(
        removedMembersEmail.filter((item) => item !== values?.email)
      );
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data?.message}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const updateProfile = async (values) => {
    try {
      setLoading(true);
      const inputData = {
        email: userProfileData?.emailID,
        dob: date ? moment(date).format("YYYY-MM-DD") : null,
        streetAdress: values?.streetAdress,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        phone: values?.phone,
        profileCompleted: true,
      };
      await UpdateEmployeeProfile(inputData);
      const responce = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: userProfileData?.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      dispatch(setUserData(responce.data.getEmployeeRegi));

      setShowCheckout(true);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const getOrgCheckoutSession = async (planId, stripePriceId, values) => {
    let url = `/billing/checkout/dpc`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: userProfileData?.id,
          planId,
          removedMembersEmail,
          dpcDoctorID: localStorage.getItem("dpcDoctor"),
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId) => {
    try {
      let sessionResult = await getOrgCheckoutSession(planId, stripePriceId);
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };

  const handlePlanCheckout = async (filterCriteria, setLoadingState) => {
    setLoadingState(true);
    try {
      const res = await ListBillingPlans(filterCriteria);
      const planArray = await Promise.all(
        res?.filter((param) => {
          const details = JSON.parse(param?.planDetails);
          return details?.planName === selectedPlan;
        })
      );
      const obj = JSON.parse(planArray[0].planDetails);
      await createStripeSession(planArray[0].id, obj?.stripePriceId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingState(false);
    }
    // const inputData = {
    //   input: {
    //     id: "b079967a-83c2-4f64-81de-78f7c418c925",
    //     doctorID: "a0565149-bb97-4fc7-a56a-6397de8a2dd4",
    //     planID: "774c09ed-ba2c-471d-9da4-0f926e41fc08",
    //     stripeCustomerID: "cus_POEcNeYXMglhax",
    //     stripePriceID: "price_1OH4PXD15HGf4DZkDJ4cTJyg",
    //     stripeSubscriptionID: "sub_1OZSSVD15HGf4DZkN86gFgbB",
    //     accountStatus: "ACTIVE",
    //     metadata: undefined,
    //   },
    // };
    // const responce = await API.graphql({
    //   query: updateDpcDetails,
    //   variables: inputData,
    //   authMode: "AMAZON_COGNITO_USER_POOLS",
    // });

    // console.log(responce);
  };

  const initialValues = {
    streetAdress: userProfileData?.profile?.streetAdress,
    city: userProfileData?.profile?.city,
    state: userProfileData?.profile?.state,
    zip: userProfileData?.profile?.zip
      ? zeroPad(userProfileData?.profile?.zip, 5)
      : "",
    phone: userProfileData?.profile?.phone,
    name:
      userProfileData?.profile?.firstName +
      " " +
      userProfileData?.profile?.lastName,
    email: userProfileData?.profile?.email,
    InsuranceCarrier: userProfileData?.profile?.insuranceCarrier,
    PlanID: userProfileData?.profile?.planID,
    memberID: userProfileData?.profile?.memberID,
    // dob: userDob,
  };

  // const handleRemove = (indexToRemove) => {
  //   setRemovedMembersEmail(
  //     removedMembersEmail.push(dependentList[indexToRemove]?.email)
  //   );
  //   const updatedList = dependentList.filter(
  //     (_, index) => index !== indexToRemove
  //   );
  //   setDependentList(updatedList);
  // };

  const handleRemove = (indexToRemove) => {
    const updatedRemovedMembersEmail = [
      ...removedMembersEmail,
      dependentList[indexToRemove]?.email,
    ];
    setRemovedMembersEmail(updatedRemovedMembersEmail);

    const updatedList = dependentList.filter(
      (_, index) => index !== indexToRemove
    );
    setDependentList(updatedList);
  };

  return (
    <>
      {contextHolder}
      <Container>
        <Row className="top-box"></Row>

        <Card className="mt-10 mb-20 card-container">
          <Row justify="center" className="billing-title mt-30">
            <h1>Delphi Membership Subscription</h1>
            <p>Simplify healthcare, Increase access, Reduce costs.</p>
          </Row>

          <Row justify="center" className="mt-10" gutter={[20, 20]}>
            {showCheckout ? (
              <Col md={showDependentList ? 15 : 11} xs={24} sm={24}>
                <Card
                  className={`doctor-profile bottom-padding  ${
                    selectedPlan !== "" ? "hover-pricing" : "card-border"
                  } `}
                >
                  <Row justify="center" className="billing-cards">
                    <h4>Enhanced Primary Care</h4>
                    <Divider />

                    {showDependentList ? (
                      <>
                        <Col md={24} className="mt-10 mb-10 d-flex ">
                          <Col md={3} className="dependent-avt align-top">
                            <img
                              src={addMore}
                              alt="dep"
                              onClick={() => setInviteIndDependent(true)}
                            />
                            <p>Add More</p>
                          </Col>

                          <div className="scroll-avt">
                            {dependentList?.map((dependent, index) => (
                              <Col
                                key={index}
                                md={3}
                                className="dependent-avt "
                              >
                                <img
                                  src={cross}
                                  className="cancel"
                                  alt=""
                                  onClick={() => handleRemove(index)}
                                />
                                <img src={user} alt="dep" />

                                <p className="break-after-12">
                                  {" "}
                                  {dependent.name}
                                </p>
                                <span className="break-after-12">
                                  {dependent.email}
                                </span>
                              </Col>
                            ))}
                          </div>
                        </Col>
                        <Divider />
                      </>
                    ) : (
                      ""
                    )}

                    <Col
                      md={showDependentList ? 8 : 14}
                      className=" dpc-pricing"
                    >
                      <Radio
                        value="DPC_MONTHLY"
                        onChange={onChange}
                        checked={selectedPlan === "DPC_MONTHLY"}
                        // disabled={submitDisabled ? true : false}
                      >
                        Monthly
                      </Radio>

                      <p>
                        <span
                          className={`pricing ${
                            selectedPlan === "DPC_MONTHLY"
                              ? "selected-plan"
                              : ""
                          }`}
                        >
                          $
                          {dependentList?.length
                            ? (dependentList?.length || 0) * 108 + 120
                            : "120"}
                        </span>{" "}
                        / month
                      </p>
                    </Col>

                    <Col md={8} className="dpc-pricing">
                      <Radio
                        value="DPC_ANNUALLY"
                        onChange={onChange}
                        checked={selectedPlan === "DPC_ANNUALLY"}
                        // disabled={submitDisabled ? true : false}
                      >
                        Annually
                      </Radio>

                      <p>
                        <span
                          className={`pricing ${
                            selectedPlan === "DPC_ANNUALLY"
                              ? "selected-plan"
                              : ""
                          }`}
                        >
                          $
                          {dependentList?.length
                            ? (dependentList?.length || 0) * 1080 + 1200
                            : "1200"}
                        </span>
                        / year
                      </p>
                    </Col>

                    {showDependentList ? (
                      <Col md={8}>
                        <Col md={24} className=" dependent-pricing">
                          Effective price :
                        </Col>
                        <Col md={24} className="mt-10 dependent-pricing">
                          My self : <br />
                          <b>
                            {" "}
                            ${currentPlanPrize} /{planDuration}
                          </b>
                        </Col>
                        <Col md={24} className="mt-10 dependent-pricing">
                          Dependent: <br />
                          <b>
                            {dependentList?.length} X {dependentPrize} /
                            {planDuration}
                          </b>
                        </Col>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <Row justify="center" className="billing-cards mt-20">
                    <div className="w-100 d-flex align-center justify-content-center">
                      <h5>Features</h5>
                    </div>
                    <Divider />

                    <Col md={12} className="services mt-10">
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Unlimited Access to your PCP
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Call, Text, Facetime with your Dr.
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Stronger Dr./Patient Relationship
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Healthcare Cost Savings
                      </p>
                    </Col>
                    <Col md={12} className="services mt-10">
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Same Day/Next Day Appointments
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Increased Care Management
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Streamlined Referral Process
                      </p>
                      <p>
                        <img src={checked} alt="" srcSet="" />
                        Advanced Care Advocacy
                      </p>
                    </Col>

                    {showDependentList ? (
                      ""
                    ) : (
                      <>
                        <Col md={12} className="mt-10 services">
                          <Button
                            type="primary"
                            className="theme-btn w-100 dependent"
                            onClick={() => setInviteIndDependent(true)}
                            // disabled={submitDisabled ? true : false}
                          >
                            Add Dependent
                          </Button>
                        </Col>
                        <p className="mt-20 dpc-discount">
                          Add your dependents and get <b> 10% off</b> on their
                          DPC membership purchases.
                        </p>
                      </>
                    )}

                    <Col md={24} className="mt-10">
                      <Button
                        type="primary"
                        className="theme-btn w-100 checkout-btn"
                        htmlType="submit"
                        disabled={selectedPlan === null ? true : false}
                        loading={checkutloading}
                        onClick={() =>
                          handlePlanCheckout(
                            { name: { contains: "DPC" } },
                            setCheckoutLoading
                          )
                        }
                      >
                        Checkout
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ) : (
              <Col
                span={11}
                md={11}
                xs={24}
                sm={24}
                className="d-flex align-items-center"
              >
                <Card
                  bordered={false}
                  className={`doctor-profile bottom-padding card-border`}
                >
                  <Row justify="center" className="billing-cards">
                    <h4>Required Details</h4>
                    <Divider />

                    <Form
                      name="basic"
                      form={form}
                      layout="vertical"
                      // className="mt-20"
                      initialValues={initialValues}
                      onFinish={updateProfile}
                      onFieldsChange={handleFormChange}
                    >
                      <Row gutter={20}>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Phone number"
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Please enter phone number",
                              },
                              {
                                pattern: tenDigit,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <InputNumber
                              maxLength={14}
                              controls={false}
                              formatter={(value) => formatNumberUS(value)}
                              parser={(value) => {
                                const cleanedValue = value.replace(/\D/g, "");
                                return cleanedValue.slice(0, 10);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Date of birth"
                            name="dob"
                            rules={[
                              {
                                required: true,
                                message: "Please select your date of birth",
                              },
                              {
                                validator: (_, value) => {
                                  if (date < dateValidation) {
                                    return Promise.resolve(); // Validation passed
                                  }
                                  return Promise.reject(
                                    "Users under 18 are not permitted"
                                  );
                                },
                              },
                            ]}
                          >
                            <DatePicker
                              onChange={onChangeDatepicker}
                              disabledDate={(d) => !d || d.isAfter(new Date())}
                              className="w-100"
                              format={"MM/DD/YYYY"}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={24} md={24} xs={24} sm={24}>
                          <Form.Item
                            label="Street address"
                            name="streetAdress"
                            rules={[
                              {
                                required: true,
                                message: "Please enter street address",
                              },
                              {
                                pattern: streetAddressPattern,
                                message: "Please enter a valid street address",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="City"
                            name="city"
                            rules={[
                              {
                                required: true,
                                message: "Please enter city name",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid city name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="State"
                            name="state"
                            rules={[
                              {
                                required: true,
                                message: "Please enter state name",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid state name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Zip"
                            name="zip"
                            rules={[
                              {
                                required: true,
                                message: "Please enter zip code",
                              },
                              {
                                pattern: zipPattern,
                                message: "Please enter a valid zip code",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col
                          span={12}
                          md={24}
                          xs={24}
                          sm={24}
                          style={{ textAlign: "center" }}
                        >
                          <Button
                            className="theme-btn"
                            htmlType="submit"
                            loading={loading}
                            // disabled={
                            //   userProfileData?.profile?.profileCompleted
                            //     ? false
                            //     : submitDisabled
                            // }
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                  </Row>
                </Card>
              </Col>
            )}
          </Row>

          <Row className="bottom-box"></Row>
        </Card>
      </Container>

      <SuccessModel
        message="Dependent Added Successfully!"
        open={successModel}
      />

      <IndividualInvite
        title="Add your Dependent"
        open={inviteIndDependent}
        onOk={() => setInviteIndDependent(false)}
        onCancel={() => setInviteIndDependent(false)}
        loading={loading}
        IndInvite={IndInvite}
        memberForm={memberForm}
      />
    </>
  );
}
