import { Card, Row } from "antd";
import Container from "commons/Container";
import React from "react";
import successIcon from "assets/icons/Success.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { getEmployeeRegi } from "graphql/queries";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { setUserData } from "store/actions/userData";
// import { useNavigate} from "react-router-dom";
export default function PaymentSuccess() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userReducer.userData?.id);

  const procedure = localStorage.getItem("procedureName");
  const dpcDoctor = localStorage.getItem("dpcDoctor");

  useEffect(() => {
    const fetchUser = async () => {
      const responce = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: userData,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      dispatch(setUserData(responce.data.getEmployeeRegi));
    };
    fetchUser();
  }, []);

  const [state, setState] = useState(10);

  if (state === 0 && procedure) {
    navigate(`/user-procedure-detail/${procedure}`);
  } else if (state === 0 && dpcDoctor) {
    navigate("/dpc-home");
  } else if (state === 0) {
    navigate("/");
  }

  useEffect(() => {
    // Increase state with every 1 second
    const interval = setInterval(
      () => setState((prevState) => prevState - 1),
      1000
    );
    // Clean up interval
    return () => interval;
  }, []);

  return (
    <Container>
      <Card
        className="my-2"
        style={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Row justify="center" gutter={20}>
          <div className="no-search">
            <div className="no-search-box">
              <img src={successIcon} alt="" style={{ width: "200px" }} />
              <h6>Payment Successful!!</h6>
              <h4>
                Redirecting to{" "}
                {dpcDoctor ? (
                  <Link to={`/dpc-home`} style={{ color: "inherit" }}>
                    <u>
                      <i>DPC Homepage</i>
                    </u>
                  </Link>
                ) : procedure ? (
                  <Link
                    to={`/user-procedure-detail/${procedure}`}
                    style={{ color: "inherit" }}
                  >
                    <u>
                      <i>Procedure</i>
                    </u>
                  </Link>
                ) : (
                  <Link to="/" style={{ color: "inherit" }}>
                    <u>
                      <i>Homepage</i>
                    </u>
                  </Link>
                )}{" "}
                in {state} seconds.
              </h4>
            </div>
          </div>
        </Row>
      </Card>
    </Container>
  );
}
