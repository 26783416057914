import {
  Button,
  Row,
  Typography,
  Divider,
  Table,
  Form,
  Col,
  Select,
  Input,
  Modal,
  Dropdown,
  Menu,
  Tabs,
  message,
  Empty,
  Spin,
} from "antd";
import React, { useEffect, useState } from "react";
import empty from "assets/delphy/empty.svg";
import exportCSV from "assets/icons/exportcsv.svg";

import { LeftOutlined, MoreOutlined, LoadingOutlined } from "@ant-design/icons";
import editIcon from "assets/icons/edit.svg";
import { API } from "aws-amplify";
import { CSVLink } from "react-csv";
import { getCompanyRegi, getEmployeeRegi } from "graphql/queries";
import { Link } from "react-router-dom";
import successIcon from "assets/icons/Success.svg";
import { disableSubmitButton, emailPattern } from "utility/constant";
import { useCallback } from "react";
import {
  ListCompanyRegis,
  ListCutomEmployeesRegis,
  ListEligibleMembers,
  ListEmployeesRegis,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";
import toggle from "assets/icons/downToggle.svg";
import upToggle from "assets/icons/upToggle.svg";

export default function AllEmployees() {
  const [employees, setEmployees] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [messageApi, contextHolder] = message.useMessage();
  const [unregisterEmp, setUnregisterEmp] = useState([]);
  const [massemail, setMassEmail] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteModel, setInviteModel] = useState(false);
  const [inviteSuccessModel, setInviteSuccessModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [massInvite, setMassInvite] = useState(false);
  const [massInviteModal, setMassInviteModal] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unRegiloading, setUnregiEmpLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [unRegiCsvData, setUnRegiCsvData] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [isLoading, setIsLoading] = useState({});

  const companyId = localStorage.getItem("companyId");
  const companyName = localStorage.getItem("companyName");

  const antIcon = <LoadingOutlined style={{ fontSize: 69 }} spin />;

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleFormChange = disableSubmitButton(["employeeEmail"])(
    form,
    setSubmitDisabled
  );

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setInviteModel(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setInviteModel(false);
    setMassInviteModal(false);
    form.resetFields();
  };

  const setEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      searchByName: row?.searchByName,
      Username: (
        <div>
          {row?.employeeEmail}
          <br />
          <span className="last-login">
            {row?.lastLogin ? (
              <>
                Last Login{" "}
                {(() => {
                  const currentTime = new Date();
                  const lastLoginTime = new Date(row?.lastLogin);
                  const timeDifference = currentTime - lastLoginTime;
                  const minutesAgo = Math.round(timeDifference / 1000 / 60);
                  if (minutesAgo < 1) {
                    return "Just now";
                  } else if (minutesAgo < 60) {
                    return `${minutesAgo} minutes ago`;
                  } else if (minutesAgo < 1440) {
                    return `${Math.floor(minutesAgo / 60)} hours ago`;
                  } else {
                    return (
                      "on" +
                      " " +
                      new Date(row.lastLogin).toLocaleDateString() +
                      " " +
                      new Date(row.lastLogin).toLocaleTimeString()
                    );
                  }
                })()}
              </>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
      searchEmail: row?.employeeEmail,
      Employer: row?.companyID?.name ? row?.companyID?.name : "-",
      FirstName: row?.profile?.firstName
        ? row?.profile?.firstName
        : row?.firstName
        ? row?.firstName
        : "-",
      LastName: row?.profile?.lastName
        ? row?.profile?.lastName
        : row?.lastName
        ? row?.lastName
        : "-",
      Role:
        row?.userType === "CORPORATE_ADMIN"
          ? "ADMIN"
          : row?.userType === "INDIVIDUAL"
          ? "INDIVIDUAL"
          : "MEMBER",
      Profile: row?.profile,
      Member: row?.members,
      Active: row?.accountStatus === "ACTIVE" ? "ACTIVE" : "INACTIVE",
      billingMode: row?.billing_status === "NOT_REQUIRED" ? "MANUAL" : "STRIPE",
    };
  };

  const setNotRegiEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Username: row?.emailID,
      searchByName: row?.searchByName,
      Employer: row?.companyID?.name ? row?.companyID?.name : "-",
      FirstName: row?.profile?.firstName
        ? row?.profile?.firstName
        : row?.firstName
        ? row?.firstName
        : "-",
      LastName: row?.profile?.lastName
        ? row?.profile?.lastName
        : row?.lastName
        ? row?.lastName
        : "-",
      Role:
        row?.userType === "CORPORATE_ADMIN"
          ? "ADMIN"
          : row?.userType === "INDIVIDUAL"
          ? "INDIVIDUAL"
          : "MEMBER",
    };
  };

  const setEligibleMember = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Username: row?.email ? row?.email : "-",
      Payer: row?.payer,
      searchByName: row?.searchByName,
      Employer: row?.GroupName ? row?.GroupName : "-",
      FirstName: row?.memberFirstName ? row?.memberFirstName : "-",
      LastName: row?.memberLastName ? row?.memberLastName : "-",
      Role: row?.payer === "HPI" ? "MEMBER" : "-",
    };
  };

  const fetchEmployeeData = useCallback(async () => {
    setLoading(true);
    const filter = {
      companyRegiEmployeeId: { contains: companyId },
      lastLogin: { attributeExists: true },
    };
    await ListCutomEmployeesRegis(filter, 999)
      .then((response) => {
        const empData = response.map((row) => setEmployeesRow(row));
        setEmployees(empData);
        setCsvData(empData);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    setLoading(false);
  }, [companyId]);

  const fetchNotRegisteredEmployee = useCallback(async () => {
    setUnregiEmpLoading(true);
    const filterNotRegistered = {
      companyRegiEmployeeId: { contains: companyId },
      lastLogin: { attributeExists: false },
    };
    const filterEligible = {
      registered: { eq: false },
      invited: { eq: false },
      companyID: { contains: companyId },
    };

    const [notRegisteredResponse, eligibleResponse] = await Promise.all([
      ListEmployeesRegis(filterNotRegistered),
      ListEligibleMembers(filterEligible),
    ]);

    const notRegisteredData = notRegisteredResponse.map((row) =>
      setNotRegiEmployeesRow(row)
    );
    const eligibleData = eligibleResponse.map((row) => setEligibleMember(row));
    const mergedData = [...notRegisteredData, ...eligibleData];

    const emailList = mergedData.map((row) => row.Username);
    const filteredEmails = emailList.filter((email) => email !== "-");

    setMassEmail(filteredEmails);
    setUnregisterEmp(mergedData);
    setUnRegiCsvData(mergedData);
    setUnregiEmpLoading(false);
  }, []);

  const initialValue = {
    emails: massemail,
  };

  const sendMassInvite = async () => {
    setMassInviteModal(true);
    setMassInvite(false);
    const myInit = {
      body: {
        id: companyId,
      },
    };
    try {
      await API.post(
        "bazarhealthREST",
        `/superAdmin/mass_resend_employer_members`,
        myInit
      );
    } catch (error) {
      console.log(error, "error");
    }
    setMassInvite(true);
  };

  const fetchEmployersData = useCallback(async () => {
    await ListCompanyRegis()
      .then((response) => {
        setEmployers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchNotRegisteredEmployee();
  }, [fetchNotRegisteredEmployee]);

  useEffect(() => {
    fetchEmployersData();
  }, [fetchEmployersData]);

  useEffect(() => {
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  const createEmployee = async (values) => {
    try {
      setCreateLoading(true);
      const myInit = {
        body: {
          companyId: values?.companyID,
          email: values?.employeeEmail,
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/invite_employee`, myInit);
      setCreateLoading(false);
      form.resetFields();
      setIsModalOpen(false);
      setSuccessModel(true);
    } catch (error) {
      setCreateLoading(false);
      messageApi.open({
        type: "error",
        content: "An account with the given email already exists.",
      });
    }
  };

  const makeAdmin = async (id) => {
    try {
      const responce = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: id[0],
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (responce.data.getEmployeeRegi.userType === "CORPORATE_ADMIN") {
        const adminCount = await API.graphql({
          query: getCompanyRegi,
          variables: {
            id: responce.data.getEmployeeRegi.companyRegiEmployeeId,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        messageApi.open({
          key: "member",
          type: "loading",
          content: `Making ${id[1]} Member`,
          duration: 0,
        });

        if (
          adminCount.data?.getCompanyRegi?.profile?.adminDetails.length === 1
        ) {
          return messageApi.open({
            key: "member",
            type: "warning",
            content: `There must be at least one Admin in any employer!`,
            duration: 5,
          });
        }

        const myInit = {
          body: {
            id: id[0],
          },
        };
        let result = await API.post(
          "bazarhealthREST",
          `/hr/make_employee`,
          myInit
        );

        if (result.status) {
          messageApi.open({
            key: "member",
            type: "success",
            content: `${id[1]} is now an member.`,
            duration: 5,
          });
          setEmployees([]);
          fetchEmployeeData();
        } else {
          messageApi.open({
            key: "member",
            type: "error",
            content: `error making ${id[1]} an member`,
            duration: 5,
          });
        }
      } else if (responce.data.getEmployeeRegi.userType === "CORPORATE") {
        messageApi.open({
          key: "admin",
          type: "loading",
          content: `Making ${id[1]} Admin`,
          duration: 0,
        });
        const myInit = {
          body: {
            id: id[0],
          },
        };
        let result = await API.post(
          "bazarhealthREST",
          `/hr/make_admin`,
          myInit
        );

        if (result.status) {
          messageApi.open({
            key: "admin",
            type: "success",
            content: `${id[1]} is now an admin.`,
            duration: 5,
          });
          setEmployees([]);
          fetchEmployeeData();
        } else {
          messageApi.open({
            key: "admin",
            type: "warning",
            content: `${id[1]} is already an Admin`,
            duration: 5,
          });
        }
      }
    } catch (error) {
      messageApi.open({
        key: "admin",
        type: "error",
        content: `Error making ${id[1]} an Admin`,
        duration: 5,
      });
      console.log(error);
    }
  };

  const sendMassEmail = async (values) => {
    try {
      setInviteLoading(true);
      const myInit = {
        body: {
          emails: values?.emails,
          subject: values?.subject,
          description: values?.description,
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/send_bulk_email`, myInit);
      setInviteLoading(false);
      setInviteModel(false);
      setInviteSuccessModel(true);
      setTimeout(() => {
        setInviteSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Sending Invitation.",
      });
      setInviteLoading(false);
    }
  };

  const headers = [
    { label: "Member Email ID", key: "email" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Role", key: "role" },
  ];

  const csvExp = csvData.map((row) => ({
    email: row?.searchEmail,
    firstName: row?.FirstName,
    lastName: row?.LastName,
    role:
      row?.userType === "CORPORATE_ADMIN"
        ? "ADMIN"
        : row?.userType === "INDIVIDUAL"
        ? "INDIVIDUAL"
        : row?.userType === "CORPORATE"
        ? "EMPLOYEE"
        : "MEMBER",
  }));

  const unRegicsvExp = unRegiCsvData.map((row) => ({
    email: row?.Username,
    firstName: row?.FirstName,
    lastName: row?.LastName,
    role:
      row?.userType === "CORPORATE_ADMIN"
        ? "ADMIN"
        : row?.userType === "INDIVIDUAL"
        ? "INDIVIDUAL"
        : row?.userType === "CORPORATE"
        ? "EMPLOYEE"
        : "MEMBER",
  }));

  const columns = [
    {
      title: "Member Email",
      dataIndex: "Username",
      width: "40%",
    },

    {
      title: "First Name",
      dataIndex: "FirstName",
      width: "15%",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      width: "15%",
    },
    {
      title: "Role",
      dataIndex: "Role",
      width: "15%",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      width: "8%",
      render: (id, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  {/* <Link rel="noopener noreferrer" onClick={() => makeAdmin(id)}>
                    Make Admin
                  </Link> */}
                  {record?.Role === "ADMIN" ? (
                    <Link
                      rel="noopener noreferrer"
                      onClick={() => makeAdmin([id, record?.searchEmail])}
                    >
                      Make Member
                    </Link>
                  ) : (
                    <Link
                      rel="noopener noreferrer"
                      onClick={() => makeAdmin([id, record?.searchEmail])}
                    >
                      Make an Admin
                    </Link>
                  )}
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const unRegicolumns = [
    {
      title: "Member Email",
      dataIndex: "Username",
    },

    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      width: "8%",
      render: (id, records) => {
        return (
          <Link
            rel="noopener noreferrer"
            to="/member-detail"
            state={{ id: [id, records?.Payer] }}
          >
            <img src={editIcon} alt="" />
          </Link>
        );
      },
    },
  ];

  const handleExpand = async (expanded, record) => {
    if (expanded && !nestedData[record.key]) {
      setIsLoading({
        [record.key]: true,
      });
      let dependentList = [];
      const filterE = {
        email: { eq: record?.Profile?.email },
      };
      const response = await ListEligibleMembers(filterE, 999);
      if (response) {
        if (response.length > 0) {
          let memID = response[0]?.memberIDSeq.slice(0, -1);
          const filter = {
            memberIDSeq: { contains: memID },
            memberSfx: { ne: 0 },
            or: {
              registered: { eq: false },
              invited: { eq: false },
            },
          };
          dependentList = await ListEligibleMembers(filter, 999);
          dependentList = dependentList.map((item) => ({
            emailID: item?.email ? item?.email : "-",
            // employer: record?.Employer,
            firstName: item?.memberFirstName,
            lastName: item?.memberLastName,
            role: "Dependent",
            billingMode: record?.billingMode,
            billing_status: "INACTIVE",
            type: "HPI",
            id: item?.id,
          }));
        }
      }
      const childData = (record.Member?.items || []).map((item) => ({
        ...item,
        emailID: (
          <div>
            {item?.emailID ? item.emailID : "-"}
            <br />
            <span className="last-login">
              {item?.lastLogin ? (
                <>
                  Last Login{" "}
                  {(() => {
                    const currentTime = new Date();
                    const lastLoginTime = new Date(item?.lastLogin);
                    const timeDifference = currentTime - lastLoginTime;
                    const minutesAgo = Math.round(timeDifference / 1000 / 60);
                    if (minutesAgo < 1) {
                      return "Just now";
                    } else if (minutesAgo < 60) {
                      return `${minutesAgo} minutes ago`;
                    } else if (minutesAgo < 1440) {
                      return `${Math.floor(minutesAgo / 60)} hours ago`;
                    } else {
                      return (
                        "on" +
                        " " +
                        new Date(item.lastLogin).toLocaleDateString() +
                        " " +
                        new Date(item.lastLogin).toLocaleTimeString()
                      );
                    }
                  })()}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        ),
        role: "Dependent",
        // emailID: item?.email ? item?.email : "-",
        billingMode: record?.billingMode,
        billing_status:
          item?.accountStatus === "ACTIVE" ? "ACTIVE" : "INACTIVE",
        // employer: record?.Employer,
        type: "Member",
      }));

      setNestedData((state) => ({
        ...state,
        [record.key]: [...childData, ...dependentList],
      }));
      setIsLoading({
        [record.key]: false,
      });
    }
  };

  const expandedRowRender = (record) => {
    const inline = [
      {
        title: "Member Email",
        dataIndex: "emailID", // Map to "emailID" property
        width: "40%",
      },

      {
        title: "First Name",
        dataIndex: "firstName",
        width: "15%",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        width: "15%",
      },
      {
        title: "Role",
        dataIndex: "role",
        width: "15%",
      },
      {
        title: "",
        dataIndex: "Action",
        className: "actions",
        width: "8%",
      
      },
    ];

    const data = nestedData[record.key];
    return (
      <Table
        className="common-table hide-th hide-table-head"
        columns={inline}
        loading={isLoading[record.key] && !data}
        dataSource={nestedData[record.key]}
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                description="No dependents found"
                style={{ fontSize: "15px" }}
              />
            </>
          ),
        }}
      />
    );
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <Button
          type="text"
          className="back-btn"
          onClick={() => window.history.back()}
          icon={<LeftOutlined />}
        >
          Back
        </Button>
        <Typography.Title level={4} className="text-center mb-0">
          Members of {companyName}
        </Typography.Title>
        <Button className="blue-btn"></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>
      <Tabs className="employer-tabs" defaultActiveKey="1" centered>
        <Tabs.TabPane tab="Registered Members" key="1">
          <Row justify="space-between table-header">
            <Row align="middle"></Row>

            <Row className="gap-10 mtr-20">
              <CSVLink
                data={csvExp}
                headers={headers}
                filename={"Registered Members.csv"}
                className="export-btn"
              >
                <img src={exportCSV} alt="" className="action-icons" />
              </CSVLink>

              <Button className="blue-btn inherit-btn" onClick={showModal}>
                Add Member
              </Button>
            </Row>
          </Row>
          <Table
            columns={columns}
            locale={{
              emptyText: (
                <>
                  <Empty
                    image={empty}
                    description="No dependents found"
                    style={{ fontSize: "15px" }}
                  />
                </>
              ),
            }}
            sticky
            dataSource={employees}
            loading={loading}
            className="common-table empty-img"
            scroll={{ x: 992 }}
            pagination={{ position: ["", "bottomCenter"] }}
            expandedRowRender={(parentRecord) =>
              expandedRowRender(parentRecord)
            }
            onExpand={handleExpand}
            expandable={{
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <img src={upToggle} onClick={(e) => onExpand(record, e)} />
                ) : (
                  <img src={toggle} onClick={(e) => onExpand(record, e)} />
                ),
            }}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Non Registered Members" key="2">
          <Row justify="space-between table-header">
            <Row align="middle"></Row>

            <Row className="gap-10 mtr-20">
              <CSVLink
                data={unRegicsvExp}
                headers={headers}
                filename={"Non Registered Members.csv"}
                className="export-btn "
              >
                <img src={exportCSV} alt="" className="action-icons" />
              </CSVLink>
              <Button
                className="blue-btn inherit-btn"
                disabled={
                  unRegiloading || unregisterEmp.length === 0 ? true : false
                }
                onClick={sendMassInvite}
              >
                Send Mass Invite
              </Button>
              <Button
                className="blue-btn inherit-btn"
                disabled={unRegiloading ? true : false}
                onClick={() => setInviteModel(true)}
              >
                Send Mass Email
              </Button>
              <Button className="blue-btn inherit-btn" onClick={showModal}>
                Add Member
              </Button>
            </Row>
          </Row>
          <Table
            columns={unRegicolumns}
            dataSource={unregisterEmp}
            loading={unRegiloading}
            className="common-table"
            scroll={{ x: 992 }}
            pagination={{ position: ["", "bottomCenter"] }}
          />
        </Tabs.TabPane>
      </Tabs>

      <Modal
        className="form-modal"
        centered
        footer={null}
        title={<Typography.Title level={3}>Add New Member</Typography.Title>}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={createEmployee}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Member email"
                name="employeeEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter member email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                initialValue={companyId}
                label="Employer"
                name="companyID"
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select employer"
                  getPopupContainer={(trigger) => trigger.parentNode}
                >
                  {employers.map((item) => (
                    <Select.Option
                      value={item.id}
                    >{`${item.name}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="First name" name="firstName">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Last name" name="lastName">
                <Input />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        className="form-modal"
        centered
        footer={null}
        title={<Typography.Title level={3}>Send a Mass email</Typography.Title>}
        visible={inviteModel}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={sendMassEmail}
          initialValues={initialValue}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row gutter={20}>
            <Col span={12} md={24} xs={24} sm={24}>
              <Form.Item
                label="To"
                name="emails"
                rules={[
                  {
                    required: true,
                    message: "Please input email",
                  },
                  {
                    validator: (rule, value) => {
                      if (!value || value.length === 0) {
                        return Promise.reject(
                          "Please enter at least one email address"
                        );
                      }
                      const emailRegex =
                        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                      const invalidEmails = value.filter(
                        (email) => !emailRegex.test(email)
                      );
                      if (invalidEmails.length > 0) {
                        return Promise.reject(
                          `The following emails are invalid: ${invalidEmails.join(
                            ", "
                          )}`
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Select
                  className="invite-select"
                  mode="tags"
                  style={{ width: "100%" }}
                  maxTagCount="responsive"
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={12} md={24} xs={24} sm={24}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Please input subject",
                  },
                  {
                    pattern: /^(?!\s)/,
                    message: "The first character cannot be a space",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={24} xs={24} sm={24}>
              <Form.Item
                label="Message"
                name="description"
                rules={[
                  {
                    required: true,
                    message: "Please input message",
                  },
                  {
                    pattern: /^(?!\s)/,
                    message: "The first character cannot be a space",
                  },
                ]}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={inviteLoading}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        open={successModel}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal"
      >
        <img src={successIcon} alt="Success Img" />
        <h6>Member Added Successfully!</h6>
      </Modal>

      <Modal
        title="Basic Modal"
        open={massInviteModal}
        onOk={handleOk}
        closable={massInvite}
        centered
        onCancel={massInvite ? handleCancel : ""}
        className="reward-success-modal"
      >
        {massInvite ? (
          <>
            <img src={successIcon} alt="Success Img" />
            <h6>Mass Invite Sent Succesfully!</h6>
          </>
        ) : (
          <>
            <Spin indicator={antIcon} />
            <h6>Sending mass invite to {unregisterEmp.length} members ... </h6>
          </>
        )}
      </Modal>
      <SuccessModel
        message="Mass Email Sent Successfully!"
        open={inviteSuccessModel}
      />
    </>
  );
}
