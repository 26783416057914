import { Divider, Row, Table, Typography } from "antd";
import React, { useState } from "react";

export const ReferralList = () => {
  return (
    <>
      <Typography.Title level={4} className="text-center mb-0">
        Notification
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list"></Row>
      </Row>
      {/* <Table
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
        loading={loading}
      /> */}
      {/* /> */}
    </>
  );
};

export default ReferralList;
