import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import MainLayout from "containers/MainLayout/MainLayout";
import { useSelector } from "react-redux";

const MainLayoutRoute = (props) => {
  const { token } = useSelector((state) => state.loginReducer);

  return (
    <Routes>
      {token && (
        <Route
          path={props.path}
          element={<MainLayout>{props.children}</MainLayout>}
        />
      )}
      <Route
        path={props.path}
        element={
          token === null || token === undefined ? <Navigate to="/login" /> : "/"
        }
      />
    </Routes>
  );
};

export default MainLayoutRoute;
