/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createDoctorProfile = /* GraphQL */ `
  mutation CreateDoctorProfile(
    $input: CreateDoctorProfileInput!
    $condition: ModelDoctorProfileConditionInput
  ) {
    createDoctorProfile(input: $input, condition: $condition) {
      id
      physicianID
      doctorName
      searchByName
      byName
      doctorProfilePhoto
      doctorDegree
      doctorEmail
      aboutDoctor
      doctorEducation
      doctorOfficeAdd
      areaOfSpeciality
      bodyArea
      specialities
      doctorOfficeCity
      doctorOfficeState
      doctorOfficeZip
      doctorOfficePhone
      doctorOfficeFax
      doctorPracticeName
      doctorWebsite
      doctorFacility
      surgeryCenter
      googleRating
      facebookRating
      testimonial
      activeOnPortal
      distance
      specialty
      location {
        lat
        lon
        __typename
      }
      startDate
      isDpcDoctor
      dpcUsers {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDoctorProfile = /* GraphQL */ `
  mutation UpdateDoctorProfile(
    $input: UpdateDoctorProfileInput!
    $condition: ModelDoctorProfileConditionInput
  ) {
    updateDoctorProfile(input: $input, condition: $condition) {
      id
      physicianID
      doctorName
      searchByName
      byName
      doctorProfilePhoto
      doctorDegree
      doctorEmail
      aboutDoctor
      doctorEducation
      doctorOfficeAdd
      areaOfSpeciality
      bodyArea
      specialities
      doctorOfficeCity
      doctorOfficeState
      doctorOfficeZip
      doctorOfficePhone
      doctorOfficeFax
      doctorPracticeName
      doctorWebsite
      doctorFacility
      surgeryCenter
      googleRating
      facebookRating
      testimonial
      activeOnPortal
      distance
      specialty
      location {
        lat
        lon
        __typename
      }
      startDate
      isDpcDoctor
      dpcUsers {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDoctorProfile = /* GraphQL */ `
  mutation DeleteDoctorProfile(
    $input: DeleteDoctorProfileInput!
    $condition: ModelDoctorProfileConditionInput
  ) {
    deleteDoctorProfile(input: $input, condition: $condition) {
      id
      physicianID
      doctorName
      searchByName
      byName
      doctorProfilePhoto
      doctorDegree
      doctorEmail
      aboutDoctor
      doctorEducation
      doctorOfficeAdd
      areaOfSpeciality
      bodyArea
      specialities
      doctorOfficeCity
      doctorOfficeState
      doctorOfficeZip
      doctorOfficePhone
      doctorOfficeFax
      doctorPracticeName
      doctorWebsite
      doctorFacility
      surgeryCenter
      googleRating
      facebookRating
      testimonial
      activeOnPortal
      distance
      specialty
      location {
        lat
        lon
        __typename
      }
      startDate
      isDpcDoctor
      dpcUsers {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSurgeryCenterDetails = /* GraphQL */ `
  mutation CreateSurgeryCenterDetails(
    $input: CreateSurgeryCenterDetailsInput!
    $condition: ModelSurgeryCenterDetailsConditionInput
  ) {
    createSurgeryCenterDetails(input: $input, condition: $condition) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSurgeryCenterDetails = /* GraphQL */ `
  mutation UpdateSurgeryCenterDetails(
    $input: UpdateSurgeryCenterDetailsInput!
    $condition: ModelSurgeryCenterDetailsConditionInput
  ) {
    updateSurgeryCenterDetails(input: $input, condition: $condition) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSurgeryCenterDetails = /* GraphQL */ `
  mutation DeleteSurgeryCenterDetails(
    $input: DeleteSurgeryCenterDetailsInput!
    $condition: ModelSurgeryCenterDetailsConditionInput
  ) {
    deleteSurgeryCenterDetails(input: $input, condition: $condition) {
      id
      surgeryCenterName
      searchByName
      surgeryCenterProfilePhoto
      surgeryPhotos
      surgeryMapSource
      aboutSurgeryCenter
      surgeryCenterAddLine1
      surgeryCenterAddLine2
      surgeryCenterAddLine3
      surgeryCenterZip
      surgeryCenterPhone
      surgeryCenterSpeciality
      specialty
      specailtyAndBodyPart {
        specialty
        surgeryCenterSpeciality
        __typename
      }
      surgeryCenterWebsite
      surgeryCenterPhotos
      surgeryCenterGoogleRating
      surgeryCenterFaceBookRating
      surgeryCenterEmail
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createLabDetails = /* GraphQL */ `
  mutation CreateLabDetails(
    $input: CreateLabDetailsInput!
    $condition: ModelLabDetailsConditionInput
  ) {
    createLabDetails(input: $input, condition: $condition) {
      id
      labName
      labFacilityID
      labMapSource
      labAppointmentLink
      labServicesLink
      labEmail
      labProfilePhoto
      aboutLab
      labOfficeAdd
      labOfficeCity
      labOfficeState
      labOfficeZip
      labOfficePhone
      labAddLine2
      labWebsite
      labGoogleRating
      labFaceBookRating
      labType
      labPhotos
      searchByName
      testimonial
      activeOnPortal
      occupationalTherapy
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateLabDetails = /* GraphQL */ `
  mutation UpdateLabDetails(
    $input: UpdateLabDetailsInput!
    $condition: ModelLabDetailsConditionInput
  ) {
    updateLabDetails(input: $input, condition: $condition) {
      id
      labName
      labFacilityID
      labMapSource
      labAppointmentLink
      labServicesLink
      labEmail
      labProfilePhoto
      aboutLab
      labOfficeAdd
      labOfficeCity
      labOfficeState
      labOfficeZip
      labOfficePhone
      labAddLine2
      labWebsite
      labGoogleRating
      labFaceBookRating
      labType
      labPhotos
      searchByName
      testimonial
      activeOnPortal
      occupationalTherapy
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteLabDetails = /* GraphQL */ `
  mutation DeleteLabDetails(
    $input: DeleteLabDetailsInput!
    $condition: ModelLabDetailsConditionInput
  ) {
    deleteLabDetails(input: $input, condition: $condition) {
      id
      labName
      labFacilityID
      labMapSource
      labAppointmentLink
      labServicesLink
      labEmail
      labProfilePhoto
      aboutLab
      labOfficeAdd
      labOfficeCity
      labOfficeState
      labOfficeZip
      labOfficePhone
      labAddLine2
      labWebsite
      labGoogleRating
      labFaceBookRating
      labType
      labPhotos
      searchByName
      testimonial
      activeOnPortal
      occupationalTherapy
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInfusionCenter = /* GraphQL */ `
  mutation CreateInfusionCenter(
    $input: CreateInfusionCenterInput!
    $condition: ModelInfusionCenterConditionInput
  ) {
    createInfusionCenter(input: $input, condition: $condition) {
      id
      profilePhoto
      name
      infusionDrugs
      about
      email
      website
      phone
      fax
      streetAddress
      city
      state
      zip
      googleRating
      infusionCenterPhotos
      facebookRating
      googleMapLink
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInfusionCenter = /* GraphQL */ `
  mutation UpdateInfusionCenter(
    $input: UpdateInfusionCenterInput!
    $condition: ModelInfusionCenterConditionInput
  ) {
    updateInfusionCenter(input: $input, condition: $condition) {
      id
      profilePhoto
      name
      infusionDrugs
      about
      email
      website
      phone
      fax
      streetAddress
      city
      state
      zip
      googleRating
      infusionCenterPhotos
      facebookRating
      googleMapLink
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInfusionCenter = /* GraphQL */ `
  mutation DeleteInfusionCenter(
    $input: DeleteInfusionCenterInput!
    $condition: ModelInfusionCenterConditionInput
  ) {
    deleteInfusionCenter(input: $input, condition: $condition) {
      id
      profilePhoto
      name
      infusionDrugs
      about
      email
      website
      phone
      fax
      streetAddress
      city
      state
      zip
      googleRating
      infusionCenterPhotos
      facebookRating
      googleMapLink
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPhysicalTherapyDetails = /* GraphQL */ `
  mutation CreatePhysicalTherapyDetails(
    $input: CreatePhysicalTherapyDetailsInput!
    $condition: ModelPhysicalTherapyDetailsConditionInput
  ) {
    createPhysicalTherapyDetails(input: $input, condition: $condition) {
      id
      ptFacilityID
      ptName
      ptMapSource
      aboutPT
      ptAddLine1
      ptAddLine2
      ptAddLine3
      ptZipCode
      ptPhone
      ptFax
      ptProfilePhoto
      ptEmail
      ptWebsite
      ptGoogleRating
      ptFaceBookRating
      ptPhotos
      ptType
      ptOccupationalTherapy
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePhysicalTherapyDetails = /* GraphQL */ `
  mutation UpdatePhysicalTherapyDetails(
    $input: UpdatePhysicalTherapyDetailsInput!
    $condition: ModelPhysicalTherapyDetailsConditionInput
  ) {
    updatePhysicalTherapyDetails(input: $input, condition: $condition) {
      id
      ptFacilityID
      ptName
      ptMapSource
      aboutPT
      ptAddLine1
      ptAddLine2
      ptAddLine3
      ptZipCode
      ptPhone
      ptFax
      ptProfilePhoto
      ptEmail
      ptWebsite
      ptGoogleRating
      ptFaceBookRating
      ptPhotos
      ptType
      ptOccupationalTherapy
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePhysicalTherapyDetails = /* GraphQL */ `
  mutation DeletePhysicalTherapyDetails(
    $input: DeletePhysicalTherapyDetailsInput!
    $condition: ModelPhysicalTherapyDetailsConditionInput
  ) {
    deletePhysicalTherapyDetails(input: $input, condition: $condition) {
      id
      ptFacilityID
      ptName
      ptMapSource
      aboutPT
      ptAddLine1
      ptAddLine2
      ptAddLine3
      ptZipCode
      ptPhone
      ptFax
      ptProfilePhoto
      ptEmail
      ptWebsite
      ptGoogleRating
      ptFaceBookRating
      ptPhotos
      ptType
      ptOccupationalTherapy
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createImgagingCenterDetails = /* GraphQL */ `
  mutation CreateImgagingCenterDetails(
    $input: CreateImgagingCenterDetailsInput!
    $condition: ModelImgagingCenterDetailsConditionInput
  ) {
    createImgagingCenterDetails(input: $input, condition: $condition) {
      id
      icPractiseName
      icMapSource
      icAddLine2
      icAppointmentLink
      icID
      icEmail
      icSpecialities
      aboutIC
      icOfficeAdd
      icOfficeCity
      icOfficeState
      icOfficeZip
      icOfficePhone
      icOfficeFax
      icWebsite
      icFacility
      icOfficeName
      icGoogleRating
      icFaceBookRating
      icProfilePhoto
      icPhotos
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateImgagingCenterDetails = /* GraphQL */ `
  mutation UpdateImgagingCenterDetails(
    $input: UpdateImgagingCenterDetailsInput!
    $condition: ModelImgagingCenterDetailsConditionInput
  ) {
    updateImgagingCenterDetails(input: $input, condition: $condition) {
      id
      icPractiseName
      icMapSource
      icAddLine2
      icAppointmentLink
      icID
      icEmail
      icSpecialities
      aboutIC
      icOfficeAdd
      icOfficeCity
      icOfficeState
      icOfficeZip
      icOfficePhone
      icOfficeFax
      icWebsite
      icFacility
      icOfficeName
      icGoogleRating
      icFaceBookRating
      icProfilePhoto
      icPhotos
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteImgagingCenterDetails = /* GraphQL */ `
  mutation DeleteImgagingCenterDetails(
    $input: DeleteImgagingCenterDetailsInput!
    $condition: ModelImgagingCenterDetailsConditionInput
  ) {
    deleteImgagingCenterDetails(input: $input, condition: $condition) {
      id
      icPractiseName
      icMapSource
      icAddLine2
      icAppointmentLink
      icID
      icEmail
      icSpecialities
      aboutIC
      icOfficeAdd
      icOfficeCity
      icOfficeState
      icOfficeZip
      icOfficePhone
      icOfficeFax
      icWebsite
      icFacility
      icOfficeName
      icGoogleRating
      icFaceBookRating
      icProfilePhoto
      icPhotos
      searchByName
      testimonial
      activeOnPortal
      distance
      location {
        lat
        lon
        __typename
      }
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSuggestService = /* GraphQL */ `
  mutation CreateSuggestService(
    $input: CreateSuggestServiceInput!
    $condition: ModelSuggestServiceConditionInput
  ) {
    createSuggestService(input: $input, condition: $condition) {
      id
      customerID
      serviceType
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSuggestService = /* GraphQL */ `
  mutation UpdateSuggestService(
    $input: UpdateSuggestServiceInput!
    $condition: ModelSuggestServiceConditionInput
  ) {
    updateSuggestService(input: $input, condition: $condition) {
      id
      customerID
      serviceType
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSuggestService = /* GraphQL */ `
  mutation DeleteSuggestService(
    $input: DeleteSuggestServiceInput!
    $condition: ModelSuggestServiceConditionInput
  ) {
    deleteSuggestService(input: $input, condition: $condition) {
      id
      customerID
      serviceType
      description
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createFaq = /* GraphQL */ `
  mutation CreateFaq(
    $input: CreateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    createFaq(input: $input, condition: $condition) {
      id
      faqType
      faqQuestion
      faqAnswer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateFaq = /* GraphQL */ `
  mutation UpdateFaq(
    $input: UpdateFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    updateFaq(input: $input, condition: $condition) {
      id
      faqType
      faqQuestion
      faqAnswer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteFaq = /* GraphQL */ `
  mutation DeleteFaq(
    $input: DeleteFaqInput!
    $condition: ModelFaqConditionInput
  ) {
    deleteFaq(input: $input, condition: $condition) {
      id
      faqType
      faqQuestion
      faqAnswer
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createDoctorAppointment = /* GraphQL */ `
  mutation CreateDoctorAppointment(
    $input: CreateDoctorAppointmentInput!
    $condition: ModelDoctorAppointmentConditionInput
  ) {
    createDoctorAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      docData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      description
      openToDifferentProvider
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDoctorAppointment = /* GraphQL */ `
  mutation UpdateDoctorAppointment(
    $input: UpdateDoctorAppointmentInput!
    $condition: ModelDoctorAppointmentConditionInput
  ) {
    updateDoctorAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      docData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      description
      openToDifferentProvider
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDoctorAppointment = /* GraphQL */ `
  mutation DeleteDoctorAppointment(
    $input: DeleteDoctorAppointmentInput!
    $condition: ModelDoctorAppointmentConditionInput
  ) {
    deleteDoctorAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      docData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      description
      openToDifferentProvider
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createIcAppointment = /* GraphQL */ `
  mutation CreateIcAppointment(
    $input: CreateIcAppointmentInput!
    $condition: ModelIcAppointmentConditionInput
  ) {
    createIcAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      icData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      bodyPartToBeScanned
      description
      referralOfDoctor
      doctorName
      firstVisit
      followUpAppointment
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateIcAppointment = /* GraphQL */ `
  mutation UpdateIcAppointment(
    $input: UpdateIcAppointmentInput!
    $condition: ModelIcAppointmentConditionInput
  ) {
    updateIcAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      icData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      bodyPartToBeScanned
      description
      referralOfDoctor
      doctorName
      firstVisit
      followUpAppointment
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteIcAppointment = /* GraphQL */ `
  mutation DeleteIcAppointment(
    $input: DeleteIcAppointmentInput!
    $condition: ModelIcAppointmentConditionInput
  ) {
    deleteIcAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      icData {
        id
        name
        email
        phone
        fax
        speciality
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      bodyPartToBeScanned
      description
      referralOfDoctor
      doctorName
      firstVisit
      followUpAppointment
      status
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPtAppointment = /* GraphQL */ `
  mutation CreatePtAppointment(
    $input: CreatePtAppointmentInput!
    $condition: ModelPtAppointmentConditionInput
  ) {
    createPtAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      ptData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      openToOther
      comment
      haveReferral
      status
      preferredContact
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePtAppointment = /* GraphQL */ `
  mutation UpdatePtAppointment(
    $input: UpdatePtAppointmentInput!
    $condition: ModelPtAppointmentConditionInput
  ) {
    updatePtAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      ptData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      openToOther
      comment
      haveReferral
      status
      preferredContact
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePtAppointment = /* GraphQL */ `
  mutation DeletePtAppointment(
    $input: DeletePtAppointmentInput!
    $condition: ModelPtAppointmentConditionInput
  ) {
    deletePtAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      ptData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      openToOther
      comment
      haveReferral
      status
      preferredContact
      viewed
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createInfusionAppointment = /* GraphQL */ `
  mutation CreateInfusionAppointment(
    $input: CreateInfusionAppointmentInput!
    $condition: ModelInfusionAppointmentConditionInput
  ) {
    createInfusionAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      infusionCenterData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      status
      viewed
      referringProvider
      providerPracticeName
      providerPhoneNumber
      drugBeingInfused
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateInfusionAppointment = /* GraphQL */ `
  mutation UpdateInfusionAppointment(
    $input: UpdateInfusionAppointmentInput!
    $condition: ModelInfusionAppointmentConditionInput
  ) {
    updateInfusionAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      infusionCenterData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      status
      viewed
      referringProvider
      providerPracticeName
      providerPhoneNumber
      drugBeingInfused
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteInfusionAppointment = /* GraphQL */ `
  mutation DeleteInfusionAppointment(
    $input: DeleteInfusionAppointmentInput!
    $condition: ModelInfusionAppointmentConditionInput
  ) {
    deleteInfusionAppointment(input: $input, condition: $condition) {
      id
      userData {
        id
        name
        appointmentFor
        preferredDay
        otherName
        preferredWeekDay
        preferredTime
        memberID
        insuranceCarrier
        relationship
        email
        phone
        dob
        otherDob
        __typename
      }
      userID
      infusionCenterData {
        id
        name
        email
        phone
        fax
        __typename
      }
      messageID
      faxID
      faxDetails {
        to
        message
        faxID
        sentDateTime
        faxStatus
        statusReason
        statusDateTime
        __typename
      }
      emailDetails {
        to
        cc
        emailuuID
        mailRecieved
        subject
        message
        messageID
        sentDateTime
        emailOpenTime
        __typename
      }
      status
      viewed
      referringProvider
      providerPracticeName
      providerPhoneNumber
      drugBeingInfused
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createTestimonials = /* GraphQL */ `
  mutation CreateTestimonials(
    $input: CreateTestimonialsInput!
    $condition: ModelTestimonialsConditionInput
  ) {
    createTestimonials(input: $input, condition: $condition) {
      id
      centerID
      testimonial
      by
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTestimonials = /* GraphQL */ `
  mutation UpdateTestimonials(
    $input: UpdateTestimonialsInput!
    $condition: ModelTestimonialsConditionInput
  ) {
    updateTestimonials(input: $input, condition: $condition) {
      id
      centerID
      testimonial
      by
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTestimonials = /* GraphQL */ `
  mutation DeleteTestimonials(
    $input: DeleteTestimonialsInput!
    $condition: ModelTestimonialsConditionInput
  ) {
    deleteTestimonials(input: $input, condition: $condition) {
      id
      centerID
      testimonial
      by
      rating
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRewardSubmissions = /* GraphQL */ `
  mutation CreateRewardSubmissions(
    $input: CreateRewardSubmissionsInput!
    $condition: ModelRewardSubmissionsConditionInput
  ) {
    createRewardSubmissions(input: $input, condition: $condition) {
      id
      customerID
      customerEmail
      rewardFor
      name
      dob
      typeOfCare
      nameOfProcedure
      claimNumber
      status
      uploadedImages
      approvedBy
      rewardAmount
      approvedDate
      dateSubmitted
      searchByName
      searchByProcedure
      feedback {
        rating
        comment
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRewardSubmissions = /* GraphQL */ `
  mutation UpdateRewardSubmissions(
    $input: UpdateRewardSubmissionsInput!
    $condition: ModelRewardSubmissionsConditionInput
  ) {
    updateRewardSubmissions(input: $input, condition: $condition) {
      id
      customerID
      customerEmail
      rewardFor
      name
      dob
      typeOfCare
      nameOfProcedure
      claimNumber
      status
      uploadedImages
      approvedBy
      rewardAmount
      approvedDate
      dateSubmitted
      searchByName
      searchByProcedure
      feedback {
        rating
        comment
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRewardSubmissions = /* GraphQL */ `
  mutation DeleteRewardSubmissions(
    $input: DeleteRewardSubmissionsInput!
    $condition: ModelRewardSubmissionsConditionInput
  ) {
    deleteRewardSubmissions(input: $input, condition: $condition) {
      id
      customerID
      customerEmail
      rewardFor
      name
      dob
      typeOfCare
      nameOfProcedure
      claimNumber
      status
      uploadedImages
      approvedBy
      rewardAmount
      approvedDate
      dateSubmitted
      searchByName
      searchByProcedure
      feedback {
        rating
        comment
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeRegi = /* GraphQL */ `
  mutation CreateEmployeeRegi(
    $input: CreateEmployeeRegiInput!
    $condition: ModelEmployeeRegiConditionInput
  ) {
    createEmployeeRegi(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const updateEmployeeRegi = /* GraphQL */ `
  mutation UpdateEmployeeRegi(
    $input: UpdateEmployeeRegiInput!
    $condition: ModelEmployeeRegiConditionInput
  ) {
    updateEmployeeRegi(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const deleteEmployeeRegi = /* GraphQL */ `
  mutation DeleteEmployeeRegi(
    $input: DeleteEmployeeRegiInput!
    $condition: ModelEmployeeRegiConditionInput
  ) {
    deleteEmployeeRegi(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      employeeEmail
      emailType
      verified
      role
      userType
      emailID
      inList
      lastLogin
      billing_status
      total_user
      members {
        nextToken
        __typename
      }
      dpcMembers {
        nextToken
        __typename
      }
      redeemedCoupon {
        promotionalID
        date
        __typename
      }
      promotionalID
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      epcCheckoutLink {
        checkoutID
        url
        expiry
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        dob
        insuranceCarrier
        planID
        editStatus
        editRequested
        memberID
        streetAdress
        city
        state
        zip
        phone
        gender
        createdAt
        updatedAt
        owner
        __typename
      }
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiEmployeeId
      employeeRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const createDpcDetails = /* GraphQL */ `
  mutation CreateDpcDetails(
    $input: CreateDpcDetailsInput!
    $condition: ModelDpcDetailsConditionInput
  ) {
    createDpcDetails(input: $input, condition: $condition) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateDpcDetails = /* GraphQL */ `
  mutation UpdateDpcDetails(
    $input: UpdateDpcDetailsInput!
    $condition: ModelDpcDetailsConditionInput
  ) {
    updateDpcDetails(input: $input, condition: $condition) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteDpcDetails = /* GraphQL */ `
  mutation DeleteDpcDetails(
    $input: DeleteDpcDetailsInput!
    $condition: ModelDpcDetailsConditionInput
  ) {
    deleteDpcDetails(input: $input, condition: $condition) {
      id
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createPlan = /* GraphQL */ `
  mutation CreatePlan(
    $input: CreatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    createPlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePlan = /* GraphQL */ `
  mutation UpdatePlan(
    $input: UpdatePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    updatePlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePlan = /* GraphQL */ `
  mutation DeletePlan(
    $input: DeletePlanInput!
    $condition: ModelPlanConditionInput
  ) {
    deletePlan(input: $input, condition: $condition) {
      id
      name
      planDetails
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEmployeeProfile = /* GraphQL */ `
  mutation CreateEmployeeProfile(
    $input: CreateEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    createEmployeeProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateEmployeeProfile = /* GraphQL */ `
  mutation UpdateEmployeeProfile(
    $input: UpdateEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    updateEmployeeProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteEmployeeProfile = /* GraphQL */ `
  mutation DeleteEmployeeProfile(
    $input: DeleteEmployeeProfileInput!
    $condition: ModelEmployeeProfileConditionInput
  ) {
    deleteEmployeeProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      dob
      insuranceCarrier
      planID
      editStatus
      editRequested
      memberID
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      streetAdress
      city
      state
      zip
      phone
      gender
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createMember = /* GraphQL */ `
  mutation CreateMember(
    $input: CreateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    createMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const updateMember = /* GraphQL */ `
  mutation UpdateMember(
    $input: UpdateMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    updateMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const deleteMember = /* GraphQL */ `
  mutation DeleteMember(
    $input: DeleteMemberInput!
    $condition: ModelMemberConditionInput
  ) {
    deleteMember(input: $input, condition: $condition) {
      id
      firstName
      lastName
      fcmToken
      searchByName
      lastLogin
      relation
      group
      email
      emailType
      verified
      role
      userType
      inList
      emailID
      DpcBillingID
      DpcDetails {
        id
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        __typename
      }
      dpcEmployeeID
      dpcEmployee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      dpcDoctorID
      dpcDoctor {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      companyID {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      employeeID {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      profile {
        email
        profilePhoto
        profileCompleted
        firstName
        lastName
        searchByName
        editStatus
        editRequested
        gender
        dob
        insuranceCarrier
        planID
        memberID
        streetAdress
        city
        state
        zip
        phone
        createdAt
        updatedAt
        owner
        __typename
      }
      billing_status
      accountStatus
      stripeSubscriptionID
      createdAt
      updatedAt
      employeeRegiMembersId
      companyRegiMemberId
      memberUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const createMemberProfile = /* GraphQL */ `
  mutation CreateMemberProfile(
    $input: CreateMemberProfileInput!
    $condition: ModelMemberProfileConditionInput
  ) {
    createMemberProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMemberProfile = /* GraphQL */ `
  mutation UpdateMemberProfile(
    $input: UpdateMemberProfileInput!
    $condition: ModelMemberProfileConditionInput
  ) {
    updateMemberProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMemberProfile = /* GraphQL */ `
  mutation DeleteMemberProfile(
    $input: DeleteMemberProfileInput!
    $condition: ModelMemberProfileConditionInput
  ) {
    deleteMemberProfile(input: $input, condition: $condition) {
      email
      profilePhoto
      profileCompleted
      firstName
      lastName
      searchByName
      editStatus
      editRequested
      gender
      dob
      firstVisit {
        reward
        procedure
        homePage
        firstVisit
        doctor
        surgery
        imaging
        infusion
        lab
        physicalTherapy
        __typename
      }
      insuranceCarrier
      planID
      memberID
      streetAdress
      city
      state
      zip
      phone
      location {
        lat
        lon
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createCompanyRegi = /* GraphQL */ `
  mutation CreateCompanyRegi(
    $input: CreateCompanyRegiInput!
    $condition: ModelCompanyRegiConditionInput
  ) {
    createCompanyRegi(input: $input, condition: $condition) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const updateCompanyRegi = /* GraphQL */ `
  mutation UpdateCompanyRegi(
    $input: UpdateCompanyRegiInput!
    $condition: ModelCompanyRegiConditionInput
  ) {
    updateCompanyRegi(input: $input, condition: $condition) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const deleteCompanyRegi = /* GraphQL */ `
  mutation DeleteCompanyRegi(
    $input: DeleteCompanyRegiInput!
    $condition: ModelCompanyRegiConditionInput
  ) {
    deleteCompanyRegi(input: $input, condition: $condition) {
      id
      approved
      name
      searchByName
      url
      phoneNumber
      email
      emailDomain
      planCategory
      customPlanDetails {
        price
        maxEmployess
        name
        __typename
      }
      profileID
      profile {
        email
        name
        url
        phoneNumber
        benefitInfoCall
        adminDetails
        companyID
        streetAddress
        zip
        city
        state
        benefitDocs
        visionDocs
        otherDocs
        createdAt
        updatedAt
        owner
        __typename
      }
      unSubscribeID {
        id
        reason
        createdAt
        updatedAt
        unSubscribeEmployeeId
        unSubscribeMemberId
        unSubscribeCompanyId
        owner
        __typename
      }
      employee {
        nextToken
        __typename
      }
      member {
        nextToken
        __typename
      }
      billing_status
      accountStatus
      stripeCustomerID
      stripeSubscriptionID
      stripePriceID
      planID
      plan {
        id
        name
        planDetails
        createdAt
        updatedAt
        __typename
      }
      metadata
      createdAt
      updatedAt
      companyRegiUnSubscribeIDId
      owner
      __typename
    }
  }
`;
export const createCompanyProfile = /* GraphQL */ `
  mutation CreateCompanyProfile(
    $input: CreateCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    createCompanyProfile(input: $input, condition: $condition) {
      email
      name
      url
      phoneNumber
      benefitInfoCall
      adminDetails
      companyID
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      streetAddress
      zip
      city
      state
      benefitDocs
      visionDocs
      otherDocs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateCompanyProfile = /* GraphQL */ `
  mutation UpdateCompanyProfile(
    $input: UpdateCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    updateCompanyProfile(input: $input, condition: $condition) {
      email
      name
      url
      phoneNumber
      benefitInfoCall
      adminDetails
      companyID
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      streetAddress
      zip
      city
      state
      benefitDocs
      visionDocs
      otherDocs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteCompanyProfile = /* GraphQL */ `
  mutation DeleteCompanyProfile(
    $input: DeleteCompanyProfileInput!
    $condition: ModelCompanyProfileConditionInput
  ) {
    deleteCompanyProfile(input: $input, condition: $condition) {
      email
      name
      url
      phoneNumber
      benefitInfoCall
      adminDetails
      companyID
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      streetAddress
      zip
      city
      state
      benefitDocs
      visionDocs
      otherDocs
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createUnSubscribe = /* GraphQL */ `
  mutation CreateUnSubscribe(
    $input: CreateUnSubscribeInput!
    $condition: ModelUnSubscribeConditionInput
  ) {
    createUnSubscribe(input: $input, condition: $condition) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const updateUnSubscribe = /* GraphQL */ `
  mutation UpdateUnSubscribe(
    $input: UpdateUnSubscribeInput!
    $condition: ModelUnSubscribeConditionInput
  ) {
    updateUnSubscribe(input: $input, condition: $condition) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const deleteUnSubscribe = /* GraphQL */ `
  mutation DeleteUnSubscribe(
    $input: DeleteUnSubscribeInput!
    $condition: ModelUnSubscribeConditionInput
  ) {
    deleteUnSubscribe(input: $input, condition: $condition) {
      id
      reason
      employee {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      member {
        id
        firstName
        lastName
        fcmToken
        searchByName
        lastLogin
        relation
        group
        email
        emailType
        verified
        role
        userType
        inList
        emailID
        DpcBillingID
        dpcEmployeeID
        dpcDoctorID
        billing_status
        accountStatus
        stripeSubscriptionID
        createdAt
        updatedAt
        employeeRegiMembersId
        companyRegiMemberId
        memberUnSubscribeIDId
        owner
        __typename
      }
      company {
        id
        approved
        name
        searchByName
        url
        phoneNumber
        email
        emailDomain
        planCategory
        profileID
        billing_status
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiUnSubscribeIDId
        owner
        __typename
      }
      createdAt
      updatedAt
      unSubscribeEmployeeId
      unSubscribeMemberId
      unSubscribeCompanyId
      owner
      __typename
    }
  }
`;
export const createProcedures = /* GraphQL */ `
  mutation CreateProcedures(
    $input: CreateProceduresInput!
    $condition: ModelProceduresConditionInput
  ) {
    createProcedures(input: $input, condition: $condition) {
      id
      name
      searchByName
      savings
      bazarPrice
      marketPrice
      reward
      bodyPart
      focusOnBody
      about
      description
      faq
      belongsToService
      image
      activeOnPortal
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProcedures = /* GraphQL */ `
  mutation UpdateProcedures(
    $input: UpdateProceduresInput!
    $condition: ModelProceduresConditionInput
  ) {
    updateProcedures(input: $input, condition: $condition) {
      id
      name
      searchByName
      savings
      bazarPrice
      marketPrice
      reward
      bodyPart
      focusOnBody
      about
      description
      faq
      belongsToService
      image
      activeOnPortal
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProcedures = /* GraphQL */ `
  mutation DeleteProcedures(
    $input: DeleteProceduresInput!
    $condition: ModelProceduresConditionInput
  ) {
    deleteProcedures(input: $input, condition: $condition) {
      id
      name
      searchByName
      savings
      bazarPrice
      marketPrice
      reward
      bodyPart
      focusOnBody
      about
      description
      faq
      belongsToService
      image
      activeOnPortal
      byName
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createCsvFileData = /* GraphQL */ `
  mutation CreateCsvFileData(
    $input: CreateCsvFileDataInput!
    $condition: ModelCsvFileDataConditionInput
  ) {
    createCsvFileData(input: $input, condition: $condition) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateCsvFileData = /* GraphQL */ `
  mutation UpdateCsvFileData(
    $input: UpdateCsvFileDataInput!
    $condition: ModelCsvFileDataConditionInput
  ) {
    updateCsvFileData(input: $input, condition: $condition) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteCsvFileData = /* GraphQL */ `
  mutation DeleteCsvFileData(
    $input: DeleteCsvFileDataInput!
    $condition: ModelCsvFileDataConditionInput
  ) {
    deleteCsvFileData(input: $input, condition: $condition) {
      payer
      produt
      fileyear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCountry
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSelfInsuredEmp = /* GraphQL */ `
  mutation CreateSelfInsuredEmp(
    $input: CreateSelfInsuredEmpInput!
    $condition: ModelSelfInsuredEmpConditionInput
  ) {
    createSelfInsuredEmp(input: $input, condition: $condition) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSelfInsuredEmp = /* GraphQL */ `
  mutation UpdateSelfInsuredEmp(
    $input: UpdateSelfInsuredEmpInput!
    $condition: ModelSelfInsuredEmpConditionInput
  ) {
    updateSelfInsuredEmp(input: $input, condition: $condition) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSelfInsuredEmp = /* GraphQL */ `
  mutation DeleteSelfInsuredEmp(
    $input: DeleteSelfInsuredEmpInput!
    $condition: ModelSelfInsuredEmpConditionInput
  ) {
    deleteSelfInsuredEmp(input: $input, condition: $condition) {
      email
      firstName
      lastName
      companyName
      companyID
      registered
      searchByName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createPromotionalPartner = /* GraphQL */ `
  mutation CreatePromotionalPartner(
    $input: CreatePromotionalPartnerInput!
    $condition: ModelPromotionalPartnerConditionInput
  ) {
    createPromotionalPartner(input: $input, condition: $condition) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updatePromotionalPartner = /* GraphQL */ `
  mutation UpdatePromotionalPartner(
    $input: UpdatePromotionalPartnerInput!
    $condition: ModelPromotionalPartnerConditionInput
  ) {
    updatePromotionalPartner(input: $input, condition: $condition) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deletePromotionalPartner = /* GraphQL */ `
  mutation DeletePromotionalPartner(
    $input: DeletePromotionalPartnerInput!
    $condition: ModelPromotionalPartnerConditionInput
  ) {
    deletePromotionalPartner(input: $input, condition: $condition) {
      id
      name
      email
      phone
      url
      searchByName
      streetAddress
      city
      state
      zip
      promotionalCode
      referal {
        discountType
        discount
        planType
        couponID
        promotionalID
        promotionalCode
        active
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createEligibleMembers = /* GraphQL */ `
  mutation CreateEligibleMembers(
    $input: CreateEligibleMembersInput!
    $condition: ModelEligibleMembersConditionInput
  ) {
    createEligibleMembers(input: $input, condition: $condition) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateEligibleMembers = /* GraphQL */ `
  mutation UpdateEligibleMembers(
    $input: UpdateEligibleMembersInput!
    $condition: ModelEligibleMembersConditionInput
  ) {
    updateEligibleMembers(input: $input, condition: $condition) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteEligibleMembers = /* GraphQL */ `
  mutation DeleteEligibleMembers(
    $input: DeleteEligibleMembersInput!
    $condition: ModelEligibleMembersConditionInput
  ) {
    deleteEligibleMembers(input: $input, condition: $condition) {
      payer
      lastSend
      product
      fileYear
      fileMonth
      groupCode
      cvgLevel
      memberSSN
      memberID
      memberSfx
      memberIDSeq
      memberRelCode
      memberGender
      memberDOB
      memberAddress1
      memberAddress2
      memberCity
      memberState
      memberZip
      memberCounty
      memberMedCvg
      subscrLastName
      subscrFirstName
      subscrMI
      memberLastName
      memberFirstName
      memberMI
      medEffDt
      medTermDt
      medStatus
      deptCode
      medBenPlanCode
      rxBenPlanCode
      cobFlag
      pcpTaxID
      pcpFirstName
      pcpLastName
      pcpAddressLine1
      pcpAddressLine2
      pcpCity
      pcpState
      pcpZipCode
      medicareCovSel
      lasered
      laseredAmount
      GroupName
      registered
      invited
      companyID
      regiID
      email
      phone
      searchByName
      id
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createSelfServingUsers = /* GraphQL */ `
  mutation CreateSelfServingUsers(
    $input: CreateSelfServingUsersInput!
    $condition: ModelSelfServingUsersConditionInput
  ) {
    createSelfServingUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateSelfServingUsers = /* GraphQL */ `
  mutation UpdateSelfServingUsers(
    $input: UpdateSelfServingUsersInput!
    $condition: ModelSelfServingUsersConditionInput
  ) {
    updateSelfServingUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteSelfServingUsers = /* GraphQL */ `
  mutation DeleteSelfServingUsers(
    $input: DeleteSelfServingUsersInput!
    $condition: ModelSelfServingUsersConditionInput
  ) {
    deleteSelfServingUsers(input: $input, condition: $condition) {
      id
      firstName
      lastName
      dob
      email
      phoneNumber
      streetAddress
      city
      state
      zipCode
      insuranceCarrier
      reference
      question
      doctorID
      doctorDetail {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      startDate
      isDependent
      uuid
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      content
      senderID
      systemContent
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      chatId
      chat {
        id
        senderID
        admin
        status
        createdAt
        feedback
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createChat = /* GraphQL */ `
  mutation CreateChat(
    $input: CreateChatInput!
    $condition: ModelChatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const updateChat = /* GraphQL */ `
  mutation UpdateChat(
    $input: UpdateChatInput!
    $condition: ModelChatConditionInput
  ) {
    updateChat(input: $input, condition: $condition) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
export const deleteChat = /* GraphQL */ `
  mutation DeleteChat(
    $input: DeleteChatInput!
    $condition: ModelChatConditionInput
  ) {
    deleteChat(input: $input, condition: $condition) {
      id
      senderID
      senderDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      admin
      adminDetails {
        id
        firstName
        lastName
        fcmToken
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        promotionalID
        dpcDoctorID
        DpcBillingID
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
        __typename
      }
      messages {
        nextToken
        __typename
      }
      status
      createdAt
      feedback
      updatedAt
      __typename
    }
  }
`;
