import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Col,
  Modal,
  Typography,
  Divider,
  message,
  Dropdown,
  Menu,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import exportCSV from "assets/icons/exportcsv.svg";

import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  disableSubmitButton,
  doctorNameRegex,
  emailPattern,
  formatCSVData,
  formatNumberUS,
  tenDigit,
  urlPattern,
} from "utility/constant";
import { CSVLink } from "react-csv";
import {
  CreatePromotionalPartner,
  DeleteDoctor,
  ListPromotionalPartners,
} from "utility/action";
import { useCallback } from "react";
import search from "utility/search";
import SuccessModel from "utility/SuccessModel";
import { DeleteService } from "utility/DeleteService";
import DeleteModal from "utility/DeleteModal";
import TableSkeleton from "utility/TableSkeleton";

export default function Partners() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doctorData, setdoctordata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [dataSource, setDataSource] = useState(doctorData);
  const [csvData, setCsvData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [form] = Form.useForm();

  const handleFormChange = disableSubmitButton(["name"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const labelKeyMap = {
    "Partner Name": "name",
    "Partner Email": "email",
    "Phone Number": "phone",
    "Discount Type": "discountType",
    Discount: "discount",
    "Referral Code": "promotionalCode",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const setDoctorsRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      Action: row?.id,
      name: row?.name,
      email: row?.email,
      createdAt: row?.createdAt,
      phone: row?.phone,
      referral: row?.referal?.promotionalCode?.toUpperCase(),
      couponID: row?.referal?.promotionalID,
      discountType:
        row?.referal?.discountType === "AMOUNT"
          ? "Amount ($)"
          : row?.referal?.discountType === "PERCENT"
          ? "Percent (%)"
          : "",
      discount: row?.referal?.discount,
    };
  };

  const columns = [
    {
      title: "Partner Name",
      dataIndex: "name",
    },
    {
      title: "Partner Email",
      dataIndex: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
    },

    {
      title: "Discount Type",
      dataIndex: "discountType",
    },
    {
      title: "Discount",
      dataIndex: "discount",
    },
    {
      title: "Referral Code",
      dataIndex: "referral",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id, data) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to={`/partner-detail/${id}`}>Edit</Link>
                </Menu.Item>

                {data?.couponID && (
                  <Menu.Item key={2}>
                    <Link
                      to={`/referred-list/${data?.couponID}`}
                      rel="noopener noreferrer"
                    >
                      View all members
                    </Link>
                  </Menu.Item>
                )}
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchDoctor = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListPromotionalPartners(999);
      const data = response.map((row) => setDoctorsRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setdoctordata(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  const createDoctorProfileData = async (values) => {
    try {
      setCreateLoading(true);
      setDeleteId("");
      const inputData = {
        name: values?.name,
        // searchByName: (values?.name).toLowerCase(),
        email: values?.email,
        phone: values?.phone,
        url: values?.url,
      };
      const response = await CreatePromotionalPartner(inputData);
      const mappedData = {};
      Object.entries(response).forEach(([key, value]) => {
        if (value !== null) {
          mappedData[key] = value;
        }
      });

      const tableData = {
        searchByName: mappedData?.searchByName,
        Action: mappedData?.id,
        name: mappedData?.name,
        email: mappedData?.email,
        phone: mappedData?.phone,
        referal: mappedData.referal?.promotionalCode,
        createdAt: mappedData?.createdAt,
      };

      const sortByCreatedAt = (data) =>
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setDataSource((previous) => sortByCreatedAt([...previous, tableData]));
      setdoctordata((previous) => sortByCreatedAt([...previous, tableData]));

      setCreateLoading(false);
      setIsModalOpen(false);
      form.resetFields();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error While Creating Partner Profile",
      });
      console.log(error);
    } finally {
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Action",
      "referal",
      "email",
      "name",
      "key",
      "searchByName",
    ];
    const filteredData = search(doctorData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const deleteDoctorCenter = async () => {
    try {
      setDeleteLoading(true);
      await DeleteService(deleteId, DeleteDoctor);
      setDeleteModelPrompt(false);
      setSuccessModel(true);
      fetchDoctor();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Promotional Partners
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>
        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <CSVLink
            data={resData}
            headers={headers}
            filename={"Partners.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Add Partner
          </Button>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Partner
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={createDoctorProfileData}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Partner name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter a partner name",
                  },
                  {
                    pattern: doctorNameRegex,
                    message: "Please enter a valid partner name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Partner email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter partner email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please provide partner email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Partner phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter partner phone number",
                  },
                  {
                    pattern: tenDigit,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  formatter={(value) => formatNumberUS(value)}
                  parser={(value) => {
                    const cleanedValue = value.replace(/\D/g, "");
                    return cleanedValue.slice(0, 10);
                  }}
                 
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Partner url"
                name="url"
                rules={[
                  {
                    required: true,
                    message: "Please enter partner url",
                  },
                  {
                    pattern: urlPattern,
                    message: "Please input valid url",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message={
          deleteId !== ""
            ? "Partner Deleted Successfully"
            : "Partner Added Successfully"
        }
        open={successModel}
      />

      <DeleteModal
        content="Are you sure you want to delete the doctor?"
        open={deleteModelPrompt}
        onClick={deleteDoctorCenter}
        buttonLoading={deleteLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        buttonText="Yes"
      />
    </>
  );
}
