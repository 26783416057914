import React, { useEffect, useRef, useState } from "react";
import "./styles/main.css";
import EmojiTray from "./components/EmojiTray";
import ChatInput from "./components/ChatInput";
import { useParams } from "react-router-dom";

import Header from "./components/Header";
import Conversation from "./components/Conversation";
import Sidebar from "./Sidebar";
import "./styles/overrides.css";
import { Button, Col, message, Row } from "antd";
import { ListEmployeesRegis, ListUserChats } from "utility/action";
import { useCallback } from "react";
import { API } from "aws-amplify";
import SkeletonBlock from "utility/SkeletonBlocks";
import { onCreateChat, onCreateMessage } from "graphql/subscriptions";
import { useDispatch, useSelector } from "react-redux";
import { createMessage } from "graphql/mutations";
import { setChatCount } from "store/actions/chatCount";
import EmployeeListModal from "./components/EmployeeListModal";
import newMessageSound from "assets/sounds/Notification.mp3";

const Chat = ({ history }) => {
  const { category, id } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();

  const lastMsgRef = useRef(null);
  const [showAttach, setShowAttach] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showEmojis, setShowEmojis] = useState(false);
  const [openEmployeeList, setOpenEmployeeList] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [userData, setUserData] = useState([]);

  const userState = useSelector((state) => state.userReducer?.userData);
  const sound = new Audio(newMessageSound);
  const userId = id;
  let user = userData?.filter((user) => user.id === userId)[0];

  const scrollToLastMsg = () => {
    lastMsgRef.current.scrollIntoView();
  };

  const submitNewMessage = async (content) => {
    try {
      const newMessageData = {
        chatId: user?.id,
        content: newMessage || content,
        senderID: userState.id,
      };

      const newMessageResponse = await API.graphql({
        query: createMessage,
        variables: { input: newMessageData },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const createdMessage = newMessageResponse.data.createMessage;

      setUserData((prevUserData) => {
        const updatedUserData = prevUserData.map((user) => {
          if (user.id === createdMessage.chatId) {
            return {
              ...user,
              messages: {
                ...user.messages,
                items: [...user.messages.items, createdMessage],
              },
            };
          }
          return user;
        });

        return updatedUserData;
      });

      setNewMessage(""); // Clear the input field
      scrollToLastMsg(); // Scroll to the last message
    } catch (error) {
      console.log(error);
    }
  };

  const fetchUserChats = useCallback(async () => {
    try {
      setLoading(true);

      const response = await ListUserChats(undefined, 999);

      const counts = {
        UNASSIGNED: 0,
        OPEN: 0,
        CLOSED: 0,
      };

      const mergedChatsMap = new Map();

      // Process and merge chats
      response.forEach((chat) => {
        if (category && chat.status !== category) {
          return; // Skip chats not matching the specified category
        }

        if (mergedChatsMap.has(chat.senderID)) {
          const existingChat = mergedChatsMap.get(chat.senderID);
          existingChat.messages.items = existingChat.messages.items.concat(
            chat.messages.items
          );
        } else {
          mergedChatsMap.set(chat.senderID, { ...chat });
        }
      });

      response.forEach((chat) => {
        counts[chat.status]++;
      });

      const mergedChats = Array.from(mergedChatsMap.values());

      dispatch(setChatCount(counts));
      setUserData(mergedChats);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: error.message,
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [category, id]);

  useEffect(() => {
    fetchUserChats();
  }, [fetchUserChats]);

  useEffect(() => {
    const createMessageSubscriber = API.graphql({
      query: onCreateMessage,
      variables: {
        filter: {
          chatId: { eq: user?.id },
          senderID: { ne: userState.id },
        },
      },
    }).subscribe({
      next: async (d) => {
        const newMsg = d?.value?.data?.onCreateMessage;
        if (newMsg && newMsg.senderID) {
          // Update the user's messages in state
          setUserData((prevUserData) => {
            const updatedUserData = prevUserData.map((userDataItem) => {
              if (userDataItem.id === newMsg.chatId) {
                // If it's the user of the new message, update the messages
                return {
                  ...userDataItem,
                  messages: {
                    ...userDataItem.messages,
                    items: [...userDataItem.messages.items, newMsg],
                  },
                };
              }
              return userDataItem;
            });

            return updatedUserData;
          });
          sound.play();
        }
      },
      error: (e) => {
        console.log("Subscription error:", e);
      },
    });

    // Clean up the subscription when the component unmounts
    return () => createMessageSubscriber.unsubscribe();
  }, []);

  useEffect(() => {
    const createMessageSubscriber = API.graphql({
      query: onCreateChat,
      variables: {
        filter: {
          status: { eq: "UNASSIGNED" },
        },
      },
    }).subscribe({
      next: async (d) => {
        const newChat = d?.value?.data?.onCreateChat;
        if (newChat) {
          sound.play();
          if (category === "UNASSIGNED") {
            setUserData((prevUserData) => [...prevUserData, newChat]);
          }
        }
      },
      error: (e) => {
        console.log("Subscription error:", e);
      },
    });

    // Clean up the subscription when the component unmounts
    return () => createMessageSubscriber.unsubscribe();
  }, [category]);

  const [superAdmin, setSuperAdmin] = useState([]);

  const fetchSuperAdmin = async () => {
    try {
      const filter = {
        userType: { eq: "SUPERADMIN" },
      };

      const response = await ListEmployeesRegis(filter, 999);
      setSuperAdmin(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSuperAdmin();
  }, []);

  const updateUserInfo = (newUser) => {
    setUserData((prevUserData) => [...prevUserData, newUser]);
  };

  const closeChatInput = () => {
    fetchUserChats();
  };

  const updateUserInfoInTransfer = (newUser) => {
    setUserData((prevUserData) => {
      const updatedUserData = prevUserData.map((userItem) => {
        if (userItem.id === newUser.id) {
          // If it's the user with the matching id, update the user information
          return newUser;
        }
        return userItem;
      });

      return updatedUserData;
    });
  };

  return (
    <>
      {contextHolder}
      <div className="app">
        <div className="app-content">
          {loading ? (
            <>
              <Row gutter={[20, 20]} className="mt-20 w-100 chat-loader">
                <Col md={7} xs={7}>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                  <Col md={7} className="mt-20">
                    <SkeletonBlock style={{ width: 300, height: 84 }} />
                  </Col>
                </Col>

                <Col md={15}>
                  <Col md={6} className="mt-20">
                    <SkeletonBlock style={{ width: 770, height: "78vh" }} />
                  </Col>
                </Col>
              </Row>
            </>
          ) : (
            <>
              {userData.length === 0 ? (
                ""
              ) : (
                <Sidebar
                  userData={userData}
                  setOpenEmployeeList={setOpenEmployeeList}
                />
              )}

              {id === null || id === undefined ? (
                <>
                  <div className="home">
                    <div className="home__img-wrapper">
                      <img
                        src="https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/images/icons/inviteMember.png"
                        alt=""
                        className="home__img"
                      />
                    </div>

                    {userData.length === 0 ? (
                      <>
                        <h1 className="home__title">No chat found</h1>
                        <div className="chat-app-btn">
                          <Button
                            className="theme-btn chat"
                            onClick={() => {
                              setOpenEmployeeList(true);
                            }}
                          >
                            Start new chat
                          </Button>
                        </div>
                      </>
                    ) : (
                      <h1 className="home__title">
                        Select chat to {category === "CLOSED" ? "view" : "start"} a conversation
                      </h1>
                    )}
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="chat">
                    <div className="chat__body">
                      <div className="chat__bg"></div>

                      <Header user={user} />
                      <Header user={user} showAssignedUser={true} />
                      <div className="chat__content">
                        <Conversation
                          lastMsgRef={lastMsgRef}
                          messages={user?.messages}
                        />
                      </div>
                      <footer className="chat__footer">
                        <ChatInput
                          showEmojis={showEmojis}
                          setShowEmojis={setShowEmojis}
                          showAttach={showAttach}
                          setShowAttach={setShowAttach}
                          newMessage={newMessage}
                          setNewMessage={setNewMessage}
                          submitNewMessage={submitNewMessage}
                          userMessage={user}
                          superAdmin={superAdmin}
                          updateUserInfoInTransfer={updateUserInfoInTransfer}
                          closeChatInput={closeChatInput}
                        />
                      </footer>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>

      <EmployeeListModal
        open={openEmployeeList}
        setOpen={setOpenEmployeeList}
        updateUserInfo={updateUserInfo}
      />
    </>
  );
};

export default Chat;
