import {
  Breadcrumb,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  Row,
  Tabs,
  Typography,
  List,
  message,
  Skeleton,
  Select,
  Modal,
  Grid,
  Tooltip,
} from "antd";
import { RightOutlined, StarFilled, LeftOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import locationIcon from "assets/icons/location.svg";
import DummyImg from "assets/icons/blank.png";
import bazar from "assets/delphy/delphiprice.svg";
import rewardClaim from "assets/icons/rewardclaim.svg";
import Container from "commons/Container";
import React, { useCallback, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import overview from "assets/icons/overview.svg";
import reward from "assets/icons/rewardPR.svg";
import rewardGrey from "assets/icons/rewardPRGrey.svg";
import operates from "assets/icons/operates.svg";
import faqtab from "assets/icons/faqTab.svg";
import ReactGA from "react-ga";

import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import DoctorIcon from "assets/icons/docPR.svg";
import stepRound from "assets/icons/stepRound.svg";

import doctor2 from "assets/img/doctor2.svg";
import doctor3 from "assets/img/doctor3.svg";
import doctor4 from "assets/img/doctor4.svg";
import doctor5 from "assets/img/doctor5.svg";
import doctor6 from "assets/img/doctor6.svg";

import {
  ListNearByDoctor,
  ListNearByImagingCenters,
  ListNearByLabs,
  ListNearByPhysicalTherapies,
  ListNearBySurgeryCenter,
} from "utility/action";
import { useEffect } from "react";
import {
  GetMulipleFileFromS3,
  GetPublicFileFromS3,
} from "utility/uploadFileToS3";
import { useDispatch, useSelector } from "react-redux";
import { procedureByName, proceduresByName } from "graphql/queries";
import awsmobile from "aws-exports";
import { API } from "aws-amplify";
import SkeletonBlock from "utility/SkeletonBlocks";
import { setDoctorData } from "store/actions/doctorAction";
import { setSurgeryState } from "store/actions/surgeryAction";
import { setICData } from "store/actions/IcAction";
import { setLabDataState } from "store/actions/labAction";
import { setSpecialityOption } from "store/actions/specialityOptions";
import { setSurgerySpeciality } from "store/actions/surgerySpeciality";
import { setAreaOfSpeciality } from "store/actions/areaOfSpecialty";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";
import { END_TOUR } from "utility/constant";
import { setTourSteps } from "store/actions/tourSteps";

export default function HomeProcedureDetail() {
  const [procedureData, setProcedure] = useState([]);
  const [procedureList, setProcedureList] = useState([]);
  const [refrencesData, setReferencesData] = useState([]);
  const [serviceBelong, setServiceBelongs] = useState([]);
  const [ratingProps, setRatingProps] = useState([]);
  const [locationData, setLocationData] = useState([]);
  const [originalResponse, setOriginalResponse] = useState([]);
  const [bodyPart, setBodyPart] = useState([]);
  const [focusOnBody, setFocusOnBody] = useState([]);
  const { Option } = Select;
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [procedureLoading, setProcedureLoading] = useState(false);
  const [claimReward, setClaimReward] = useState(false);
  const [procedureImg, setProcedureImg] = useState("");
  const [faq, setFaq] = useState([{ question: "", answer: "" }]);
  const [messageApi, contextHolder] = message.useMessage();
  const { Panel } = Collapse;
  const [displayCount, setDisplayCount] = useState(10);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);

  const pList = procedureList.map((faq) => [faq?.name, faq?.searchByName]);
  const displayedProcedures = pList.slice(0, displayCount);

  const loc = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const userData = useSelector((state) => state.userReducer?.userData);
  const billingStatus = useSelector(
    (state) => state?.userReducer?.userData?.billing_status
  );
  const userType = useSelector(
    (state) => state?.userReducer?.userData?.userType
  );
  const companyID = useSelector(
    (state) => state?.userReducer?.userData?.companyRegiEmployeeId
  );
  const companyDetails = useSelector(
    (state) => state?.userReducer?.userData?.companyID
  );

  const isEmployee =
    (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
    companyID &&
    companyDetails &&
    userData &&
    billingStatus === "NOT_REQUIRED";

  const location = userData?.profile?.location;
  const currentUrl = window.location.href;
  const urlParts = currentUrl.split("/");
  let procedureName = urlParts[urlParts.length - 1];

  procedureName = procedureName.split("#")[0]; // remove the anchor link if it exists
  procedureName = decodeURIComponent(procedureName);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchProcedure = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await API.graphql({
        query: procedureByName,
        variables: {
          searchByName: procedureName,
        },
        authMode: "API_KEY",
        authToken: awsmobile.aws_appsync_apiKey,
      });

      const data = response?.data?.procedureByName?.items[0];
      setProcedure(data);
      const fetchedFaqData = JSON.parse(data?.faq ?? "[]");
      setFaq(fetchedFaqData);

      setServiceBelongs(data?.belongsToService);
      setBodyPart(data?.bodyPart);
      setFocusOnBody(data?.focusOnBody);
      if (userData !== null) {
        const serviceList = await fetchServiceList(
          data?.belongsToService,
          data?.bodyPart,
          data?.focusOnBody
        );

        setReferencesData(serviceList);
        setLocationData(serviceList);
      }

      if (data?.image) {
        let procedure = await GetPublicFileFromS3(data?.image);
        setProcedureImg(procedure);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      if (loc?.state?.isTourRunning === true) {
        setIsTourRunning(true);
      }
      setskeletonLoader(false);
    }
  }, [procedureName]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: "Porcedure Details Page",
      action: `Visited ${document.title}`,
      label: window.location.pathname,
    });
    fetchProcedure();
  }, [fetchProcedure]);

  async function fetchServiceList(belongsToService, filter, focusOnBody) {
    let response = [];
    let ratingProps = ["facebookRating", "googleRating"];

    switch (belongsToService) {
      case "DOCTOR":
        response = await ListNearByDoctor(
          999,
          {
            lat: location?.lat,
            lon: location?.lon,
          },
          focusOnBody,
          filter
        );
        await Promise.all(
          response.map(async (item) => {
            const key = item?.doctorProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
              }
            }
            return item;
          })
        );

        break;
      case "SURGERY":
        response = await ListNearBySurgeryCenter(
          999,
          {
            lat: location?.lat,
            lon: location?.lon,
          },
          focusOnBody,
          filter
        );
        await Promise.all(
          response.map(async (item) => {
            const key = item?.surgeryCenterProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.surgeryCenterProfilePhoto)
                  item.surgeryCenterProfilePhoto = url;
              }
            }
            return item;
          })
        );
        ratingProps = [
          "surgeryCenterFaceBookRating",
          "surgeryCenterGoogleRating",
        ];
        break;
      case "IMAGING_CENTER":
        response = await ListNearByImagingCenters(
          999,
          {
            lat: location?.lat,
            lon: location?.lon,
          },
          [filter]
        );
        await Promise.all(
          response.map(async (item) => {
            const key = item?.icProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.icProfilePhoto) item.icProfilePhoto = url;
              }
            }
            return item;
          })
        );
        ratingProps = ["icFaceBookRating", "icGoogleRating"];
        break;
      case "LABS":
        // response = await ListPublicLabs(999, labSpeciality);
        response = await ListNearByLabs(
          999,
          {
            lat: location?.lat,
            lon: location?.lon,
          },
          [filter]
        );
        await Promise.all(
          response.map(async (item) => {
            const key = item?.labProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.labProfilePhoto) item.labProfilePhoto = url;
              }
            }
            return item;
          })
        );
        ratingProps = ["labFaceBookRating", "GoogleRating"]; // labs rating properties
        break;
      case "PHYSICAL_THERAPHY":
        response = await ListNearByPhysicalTherapies(
          999,
          {
            lat: location?.lat,
            lon: location?.lon,
          },
          [filter]
        );
        // response = await ListPublicPTCenter(999);
        await Promise.all(
          response.map(async (item) => {
            const key = item?.ptProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.ptProfilePhoto) item.ptProfilePhoto = url;
              }
            }
            return item;
          })
        );
        ratingProps = ["ptFaceBookRating", "ptGoogleRating"];
        break;
      default:
        break;
    }

    setOriginalResponse(response);
    setRatingProps(ratingProps);
    return response.slice(0, 6);
    // return getUniqueRandomElements(response, 6, ratingProps);
  }

  const fetchProcedureList = useCallback(async () => {
    try {
      setProcedureLoading(true);
      const response = await API.graphql({
        query: proceduresByName,
        variables: {
          byName: "procedures",
        },
        authMode: "API_KEY",
        authToken: awsmobile.aws_appsync_apiKey,
      });
      setProcedureList(response?.data?.proceduresByName?.items);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setProcedureLoading(false);
    }
  }, []);

  const handleViewMore = () => {
    setDisplayCount(displayCount + 10);
  };

  useEffect(() => {
    fetchProcedureList();
  }, [fetchProcedureList]);

  const handleReferences = (values) => {
    if (values === "Location") {
      setReferencesData(locationData);
    } else {
      for (let i = originalResponse.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [originalResponse[i], originalResponse[j]] = [
          originalResponse[j],
          originalResponse[i],
        ];
        const sorted = originalResponse.sort((a, b) => {
          const aRating = Math.max(...ratingProps.map((prop) => a[prop]));
          const bRating = Math.max(...ratingProps.map((prop) => b[prop]));
          return bRating - aRating;
        });
        setReferencesData(sorted.slice(0, 6));
      }
    }
  };

  const navigateToService = async (url, action, state) => {
    const updatedResponse = await Promise.all(
      originalResponse.map(async (item) => {
        const key =
          item?.doctorProfilePhoto ||
          item?.surgeryCenterProfilePhoto ||
          item?.labProfilePhoto ||
          item?.icProfilePhoto;
        if (key && !key.startsWith("http")) {
          const url = await GetMulipleFileFromS3(key, "public");
          if (url) {
            if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
            if (item?.surgeryCenterProfilePhoto)
              item.surgeryCenterProfilePhoto = url;
            if (item?.labProfilePhoto) item.labProfilePhoto = url;
            if (item?.icProfilePhoto) item.icProfilePhoto = url;
          }
        }
        return item;
      })
    );
    if (url === "/surgical-center-list") {
      dispatch(setSurgerySpeciality(bodyPart));
    }
    if (url === "/doctor-list") {
      dispatch(setAreaOfSpeciality(focusOnBody));
    }
    dispatch(action(updatedResponse));
    dispatch(setSpecialityOption(bodyPart));
    navigate(url, { state: state });
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      const offset = -100; // Adjust this offset based on your layout
      const scrollPosition = section.offsetTop + offset;
      window.scrollTo({ top: scrollPosition, behavior: "smooth" });
    }
  };

  const endTour = () => {
    setIsTourRunning(false);
    dispatch(setTourSteps(END_TOUR));
    localStorage.setItem("activeTabKey", "item-3");
    navigate("/");
  };

  const items = [
    {
      label: (
        <span className="pr-fifth-step" onClick={() => setTourIndex(4)}>
          <img src={overview} className="tabs-icon" />
          {isTourRunning ? (
            <a className="sub-header-font">Details</a>
          ) : (
            <a href="#description" className="sub-header-font">
              Details
            </a>
          )}
        </span>
      ),
      key: "item-1",
    },
    {
      label: (
        <span className="pr-sixth-step" onClick={() => setTourIndex(5)}>
          <img src={faqtab} className="tabs-icon" />
          {isTourRunning ? (
            <a className="sub-header-font">FAQs</a>
          ) : (
            <a
              href="#faq"
              className="sub-header-font"
              onClick={() => scrollToSection("faq")}
            >
              FAQs
            </a>
          )}
        </span>
      ),
      key: "item-2",
    },
    {
      label: (
        <span className="pr-seventh-step">
          <img src={operates} className="tabs-icon" />
          {isTourRunning ? (
            <a className="sub-header-font" onClick={endTour}>
              References
            </a>
          ) : (
            <a href="#references" className="sub-header-font">
              References
            </a>
          )}
        </span>
      ),
      key: "item-3",
    },
  ];

  const doctorSteps = [
    {
      target: ".pr-second-step",
      content: (
        <TourStepContent
          heading="Price"
          details="Here you can access a comprehensive list of procedures, check
          corresponding prices, and view average savings."
          numbers={{ currentStep: 2, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".pr-third-step",
      content: (
        <TourStepContent
          heading="Reward Amount"
          details="Click here to submit reward(s)."
          numbers={{ currentStep: 3, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".pr-forth-step",
      content: (
        <TourStepContent
          heading="Find Related Service"
          details="This will direct you to the Doctors/Facilities that perform
          this procedure."
          numbers={{ currentStep: 4, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".pr-fifth-step",
      content: (
        <TourStepContent
          heading="Details"
          details="By default, you will get details page of the specific
          procedure."
          numbers={{ currentStep: 5, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".pr-sixth-step",
      content: (
        <TourStepContent
          heading="FAQs"
          details="Click to view related FAQs of this procedure."
          numbers={{ currentStep: 6, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".pr-seventh-step",
      content: (
        <TourStepContent
          heading="References"
          details="Click to access a list of references who provide same procedure and you can also sort them by location and ratings."
          numbers={{ currentStep: 7, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  const serviceButton = () => {
    switch (serviceBelong) {
      case "DOCTOR":
        return (
          <Button
            className="border-btn w-100 find-doc-btn button-font"
            onClick={() => {
              if (isTourRunning && loc?.state?.isTourRunning === true) {
                setTourIndex(3);
              } else {
                navigateToService("/doctor-list", setDoctorData);
              }
            }}
          >
            <div>
              <img src={DoctorIcon} className="icon" /> Find a Doctor
            </div>
            <RightOutlined />
          </Button>
        );

      case "SURGERY":
        return (
          <Button
            className="border-btn w-100 find-doc-btn button-font"
            onClick={() => {
              if (isTourRunning && loc?.state?.isTourRunning === true) {
                setTourIndex(3);
              } else {
                navigateToService("/surgical-center-list", setSurgeryState);
              }
            }}
          >
            <div>
              <img src={DoctorIcon} className="icon" /> Find Surgical Center
            </div>{" "}
            <RightOutlined />
          </Button>
        );

      case "IMAGING_CENTER":
        return (
          <Button
            className="border-btn w-100 find-doc-btn button-font"
            onClick={() => {
              if (isTourRunning && loc?.state?.isTourRunning === true) {
                setTourIndex(3);
              } else {
                navigateToService("/imaging-center-list", setICData);
              }
            }}
          >
            <div>
              <img src={DoctorIcon} className="icon" /> Find Imaging Center
            </div>{" "}
            <RightOutlined />
          </Button>
        );

      case "LABS":
        return (
          <Button
            className="border-btn w-100 find-doc-btn button-font"
            onClick={() => {
              if (isTourRunning && loc?.state?.isTourRunning === true) {
                setTourIndex(3);
              } else {
                navigateToService("/lab-center-list", setLabDataState);
              }
            }}
          >
            <div>
              <img src={DoctorIcon} className="icon" /> Find Labs
            </div>{" "}
            <RightOutlined />
          </Button>
        );

      case "PHYSICAL_THERAPHY":
        return (
          <Button
            className="border-btn w-100 find-doc-btn button-font"
            onClick={() => {
              if (isTourRunning && loc?.state?.isTourRunning === true) {
                setTourIndex(3);
              } else {
                navigateToService("/physical-therapy-list", setLabDataState);
              }
            }}
          >
            <div>
              <img src={DoctorIcon} className="icon" /> Find Physical Therapy
            </div>{" "}
            <RightOutlined />
          </Button>
          // </Link>
        );

      default:
        return <></>;
    }
  };

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={doctorSteps}
        run={isTourRunning}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />

      <Helmet>
        <meta charSet="utf-8" />
        <title> {`${procedureData.name} | Delphi Health Care`}</title>
        <meta
          name="description"
          content={`${procedureData?.description?.replace(/<[^>]+>/g, "")}`}
          data-react-helmet="true"
        />
        <link
          rel="canonical"
          href={`http://member.delphihc.com/home-procedure-detail/${encodeURIComponent(
            procedureData?.searchByName
          )}`}
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`${procedureData.name} | Delphi Health Care`}
        />
        <meta
          property="og:description"
          content={`${procedureData?.description?.replace(/<[^>]+>/g, "")}`}
        />
        <meta
          property="og:url"
          content={`http://member.delphihc.com/home-procedure-detail/${encodeURIComponent(
            procedureData?.searchByName
          )}`}
        />
        <meta property="og:site_name" content="Delphi" />
      </Helmet>
      <Container>
        <Breadcrumb className="hide-version-no">
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Home
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Procedures
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {" "}
            <span className="p-font">
              {procedureData?.name ? procedureData?.name : "-"}
            </span>
          </Breadcrumb.Item>
        </Breadcrumb>
        <Card className="doctor-profile procedure-detail-page procedure-details-page mobile-card">
          <Row gutter={[20, 20]}>
            {skeletonLoader ? (
              <>
                <Skeleton avatar paragraph={{ rows: 5 }}></Skeleton>{" "}
              </>
            ) : (
              <>
                <Col span={19} lg={19} md={16} sm={24} xs={24}>
                  <div className="d-flex gap-4">
                    <img
                      src={procedureImg ? procedureImg : DummyImg}
                      alt={procedureData?.name}
                      className="doctor-img"
                    />

                    <div className="doctor-detail">
                      <h4 className="doctor-name header-font">
                        {procedureData?.name ? procedureData?.name : "-"}
                      </h4>
                      {procedureData?.about ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: procedureData?.about,
                          }}
                          className="no-margin p-font"
                        ></p>
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </Col>

                <Col lg={5} md={5} sm={24} xs={24}>
                  <Row
                    className="procedure-prices pr-second-step"
                    onClick={() =>
                      isTourRunning && loc?.state?.isTourRunning === true
                        ? setTourIndex(1)
                        : ""
                    }
                    gutter={[10, 0]}
                  >
                    <Col lg={24} md={24} sm={24} xs={8}>
                      <div className="price-box">
                        <Typography.Title level={5} className="sub-header-font">
                          <img src={bazar} style={{ marginBottom: 5 }} />
                          &nbsp;
                          <span className="p-search-head">Price </span>
                        </Typography.Title>
                        <Typography.Title
                          level={3}
                          className="mb-5 header-font"
                        >
                          {procedureData?.bazarPrice
                            ? `$${parseInt(
                                procedureData?.bazarPrice
                              ).toLocaleString()}`
                            : "-"}
                        </Typography.Title>
                      </div>
                    </Col>
                    <Col lg={24} md={24} sm={24} xs={8}>
                      <div className="price-box pro-mt-15">
                        <Typography.Title level={5} className="sub-header-font">
                          Market <span className="p-search-head"> Price</span>
                        </Typography.Title>

                        <Typography.Title
                          level={3}
                          className="mb-5 header-font"
                        >
                          {procedureData?.marketPrice
                            ? `$${parseInt(
                                procedureData?.marketPrice
                              ).toLocaleString()}`
                            : "-"}
                        </Typography.Title>
                      </div>
                    </Col>

                    <Col lg={24} md={24} sm={24} xs={8}>
                      <div className="price-box blue-box pro-mt-15">
                        <Typography.Title level={5} className="sub-header-font">
                          Average
                          <span className="p-search-head"> Savings </span>
                        </Typography.Title>
                        <Typography.Title
                          level={3}
                          className="mb-5 header-font"
                        >
                          {procedureData?.savings
                            ? `$${parseInt(
                                procedureData?.savings
                              ).toLocaleString()}`
                            : "-"}
                        </Typography.Title>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="mt-20 show-procedure-btn"
                    >
                      <Button
                        className={`theme-btn w-100 find-doc-btn ${
                          screens.xs ? "pr-third-step" : ""
                        } `}
                        onClick={() => {
                          if (
                            isTourRunning &&
                            loc?.state?.isTourRunning === true
                          ) {
                            setTourIndex(2);
                          } else {
                            setClaimReward(true);
                          }
                        }}
                      >
                        <span className="sub-header-font">
                          <img src={reward} className="icon" />
                          Reward Amount:
                        </span>
                        <span className="reward-amount header-font">
                          ${procedureData?.reward}
                        </span>
                      </Button>
                    </Col>

                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="mb-30 show-procedure-btn"
                    >
                      <div
                        className={`procedure-details  ${
                          screens.xs ? "pr-forth-step" : ""
                        } `}
                      >
                        {serviceButton()}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Card>{" "}
        <Card className="profile-tabs procedure-detail-tabs mobile-card">
          {skeletonLoader ? (
            ""
          ) : (
            <>
              <Row>
                <Col span={24}>
                  <Tabs items={items} className="procedure-tabs" />
                </Col>
              </Row>
            </>
          )}
        </Card>
        <Row className="procedure-details nr-mt-20" gutter={[10, 10]}>
          <Col span={18} lg={18} md={24} sm={24} xs={24} className="scrollbox">
            <Card id="description" className="card mobile-card">
              <Typography.Title level={3} className="header-font">
                Description
              </Typography.Title>
              {skeletonLoader ? (
                <>
                  <Skeleton paragraph={{ rows: 5 }}></Skeleton>{" "}
                </>
              ) : (
                <>
                  {procedureData?.description ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: procedureData?.description,
                      }}
                      className="no-margin"
                    ></p>
                  ) : (
                    "-"
                  )}
                </>
              )}
            </Card>

            <Card id="faq" className="faq_tabs card mobile-card">
              <Typography.Title level={3} className="header-font">
                FAQs
              </Typography.Title>
              {skeletonLoader ? (
                <>
                  {" "}
                  <Skeleton paragraph={{ rows: 5 }}></Skeleton>{" "}
                </>
              ) : (
                <>
                  {faq.length === 0 ? (
                    "-"
                  ) : (
                    <>
                      <Collapse
                        collapsible={userData === null ? "disabled" : ""}
                        bordered={false}
                        defaultActiveKey={["0"]}
                      >
                        {faq.map((faq, index) => (
                          <Panel
                            header={
                              <span className="sub-header-font">
                                {faq.question}
                              </span>
                            }
                            key={index}
                          >
                            <p className="p-font">{faq.answer}</p>
                          </Panel>
                        ))}
                      </Collapse>
                    </>
                  )}
                </>
              )}
            </Card>

            <Card id="references" className="list-content card mobile-card">
              <Row justify="space-between" align="middle">
                <Typography.Title level={3} className="sub-header-font mb-0">
                  References
                </Typography.Title>
                <Row justify="center" align="center" className="align-center">
                  <p
                    className="sub-header-font mr-5 mb-0"
                    style={{ fontWeight: 500 }}
                  >
                    Sort By:{" "}
                  </p>
                  <Select
                    defaultValue="Location"
                    className="select-font"
                    style={{ width: 100 }}
                    placeholder="Sort By"
                    maxTagCount="responsive"
                    onChange={handleReferences}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option value="Location" className="filter-option">
                      Location
                    </Option>
                    <Option value="Rating" className="filter-option">
                      Rating
                    </Option>
                  </Select>
                </Row>
              </Row>

              <Row
                gutter={[20, 20]}
                className={`procedure-details ${
                  userData === null ? "procedure-blur" : ""
                }`}
              >
                {skeletonLoader ? (
                  <>
                    <Row gutter={[20, 20]} className="mt-3">
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                      <SkeletonBlock style={{ width: 250, height: 250 }} />
                    </Row>
                  </>
                ) : (
                  <>
                    {userData === null ? (
                      <>
                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor3}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                Francis Lamy, D.O
                              </h5>
                              <p className="doctor-associates p-font">
                                Nutfield Eye Associates
                              </p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {5}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {5}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 0.35 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>

                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor2}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                Christiana E, M.D.
                              </h5>
                              <p className="doctor-associates p-font">
                                NH Eye Associates
                              </p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {7}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {8}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 2.5 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>

                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor3}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                Anthony J. Correnti, M.D.
                              </h5>
                              <p className="doctor-associates p-font">HI</p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {7}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {8}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 6 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>

                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor4}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                Melissa M. Wong, M.D.
                              </h5>
                              <p className="doctor-associates p-font">
                                Orthopaedics Northeast
                              </p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {7}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {8}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 2.9 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>

                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor5}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                Nikhil A Thakur, MD
                              </h5>
                              <p className="doctor-associates p-font">
                                New Hampshire Orthopaedic Center
                              </p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {7}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {8}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 6 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>

                        <Col span={8} lg={8} md={12} sm={24} xs={24}>
                          <div className="single-list-card mt-20">
                            <img
                              src={doctor6}
                              alt="Doctor Img"
                              className="list-avatar"
                            />
                            <div>
                              <h5 className="sub-header-font">
                                John Younghein, M.D.
                              </h5>
                              <p className="doctor-associates p-font">
                                Agility Orthopedics
                              </p>
                            </div>
                            <div className="d-flex justify-content-center rat-box">
                              <p className="p-font">
                                <img src={facebook} alt="facebook Icon" /> {7}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              <Divider className="h-60" type="vertical" />
                              <p className="p-font">
                                <img src={google} alt="facebook Icon" /> {8}{" "}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                            <p className="single-address p-font">
                              <img src={locationIcon} alt="Address Icon" />{" "}
                              &nbsp; 9 Miles from you
                            </p>
                            <Link>
                              <Button className="theme-btn p-font">
                                Learn More
                              </Button>
                            </Link>
                          </div>
                        </Col>
                      </>
                    ) : (
                      refrencesData.map((item, index) => {
                        let profilePhoto;
                        let name;
                        let practiceName;
                        let fbRating;
                        let googleRating;
                        let id;
                        let linkTo;
                        let distance;

                        switch (serviceBelong) {
                          case "DOCTOR":
                            profilePhoto = item?.doctorProfilePhoto;
                            practiceName = item?.doctorPracticeName;
                            name = `${item.doctorName}, ${item.doctorDegree}`;
                            fbRating = item?.facebookRating;
                            googleRating = item?.googleRating;
                            id = item?.id;
                            linkTo = "/doctor-profile";
                            distance = item?.distance;
                            break;

                          case "SURGERY":
                            profilePhoto = item?.surgeryCenterProfilePhoto;
                            name = item?.surgeryCenterName;
                            fbRating = item?.surgeryCenterFaceBookRating;
                            googleRating = item?.surgeryCenterGoogleRating;
                            id = item?.id;
                            linkTo = "/surgical-center-detail";
                            distance = item?.distance;
                            break;

                          case "IMAGING_CENTER":
                            profilePhoto = item?.icProfilePhoto;
                            name = item?.icOfficeName;
                            fbRating = item?.icFaceBookRating;
                            googleRating = item?.icGoogleRating;
                            id = item?.id;
                            linkTo = "/imaging-center-profile";
                            distance = item?.distance;
                            break;

                          case "LABS":
                            profilePhoto = item?.labProfilePhoto;
                            name = item?.labName;
                            fbRating = item?.labFaceBookRating;
                            googleRating = item?.GoogleRating;
                            id = item?.id;
                            linkTo = "/lab-center-details";
                            distance = item?.distance;
                            break;

                          case "PHYSICAL_THERAPHY":
                            profilePhoto = item?.ptProfilePhoto;
                            name = item?.ptName;
                            fbRating = item?.ptFaceBookRating;
                            googleRating = item?.ptGoogleRating;
                            id = item?.id;
                            linkTo = "/physical-therapy-profile";
                            distance = item?.distance;
                            break;

                          default:
                            profilePhoto = "-";
                            practiceName = "-";
                            name = "-";
                            fbRating = "-";
                            googleRating = "-";
                            id = "-";
                            linkTo = "/";
                            distance = "-";
                        }

                        return (
                          <Col
                            span={12}
                            lg={12}
                            md={12}
                            sm={24}
                            xs={24}
                            key={index}
                          >
                            <div className="procedure-card-list mt-20">
                              <div className="surgery-profile-card ">
                                <div className="d-flex gap-3">
                                  <img
                                    src={profilePhoto}
                                    alt="Doctor Img"
                                    className="list-avatar doctor-img"
                                  />
                                  <h4 className="doctor-name sub-header-font">
                                    {name}
                                  </h4>
                                </div>

                                <div className="d-flex mt-20 rat-box">
                                  <p className="p-font">
                                    <img src={facebook} alt="facebook Icon" />{" "}
                                    {fbRating === 0 || fbRating === null ? (
                                      "NA"
                                    ) : (
                                      <>
                                        {fbRating}
                                        <StarFilled
                                          style={{ color: "#e7a500" }}
                                        />
                                      </>
                                    )}
                                  </p>
                                  <Divider className="h-15em" type="vertical" />
                                  <p className="p-font">
                                    <img src={google} alt="facebook Icon" />{" "}
                                    {googleRating === 0 ||
                                    googleRating === null ? (
                                      "NA"
                                    ) : (
                                      <>
                                        {googleRating}{" "}
                                        <StarFilled
                                          style={{ color: "#e7a500" }}
                                        />
                                      </>
                                    )}
                                  </p>
                                </div>

                                {/* {distance !== null ? (
                                <p className="single-address p-font">
                                  <img src={locationIcon} alt="Address Icon" />{" "}
                                  &nbsp;
                                  {parseFloat(distance).toFixed(2) !== "0.00"
                                    ? `${parseFloat(distance).toFixed(
                                        2
                                      )} Miles from you`
                                    : "Near You"}
                                </p>
                              ) : (
                                " "
                              )} */}

                                <div className="space-between">
                                  <p className="single-address-line p-font">
                                    <img
                                      src={locationIcon}
                                      alt="Address Icon"
                                    />{" "}
                                    &nbsp;
                                    {parseFloat(distance).toFixed(2) !== "0.00"
                                      ? `${parseFloat(distance).toFixed(
                                          2
                                        )} Miles from
                                  you`
                                      : distance === null
                                      ? "-"
                                      : "Near You"}
                                  </p>

                                  <Link
                                    to={linkTo}
                                    state={{ id: id }}
                                    className="learn-more-btn"
                                    onClick={() => {
                                      if (serviceBelong === "SURGERY") {
                                        dispatch(
                                          setSurgerySpeciality(bodyPart)
                                        );
                                      }
                                      if (serviceBelong === "DOCTOR") {
                                        dispatch(
                                          setAreaOfSpeciality(focusOnBody)
                                        );
                                      }
                                      if (
                                        serviceBelong !== "LABS" &&
                                        serviceBelong !== "PHYSICAL_THERAPHY"
                                      ) {
                                        dispatch(setSpecialityOption(bodyPart));
                                      }
                                    }}
                                  >
                                    <Button className="blue-border-btn p-font doc-fifth-step">
                                      Learn More
                                    </Button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })
                    )}
                  </>
                )}
              </Row>
            </Card>
          </Col>

          <Col
            span={6}
            lg={6}
            md={24}
            sm={24}
            xs={24}
            className="scrollbox top-procedure "
          >
            <Button
              className="theme-btn w-100 find-doc-btn pr-third-step hide-procedure-btn"
              onClick={() => {
                if (isTourRunning && loc?.state?.isTourRunning === true) {
                  setTourIndex(2);
                } else if (!isEmployee) {
                  setClaimReward(false); //not opening popup
                } else {
                  userData && isEmployee ? setClaimReward(true) : navigate("/");
                  localStorage.setItem("activeTabKey", "item-5");
                }
              }}
              style={!isEmployee ? { cursor: "not-allowed" } : {}}
            >
              <span className="sub-header-font">
                <img src={reward} className="icon" />
                Reward Amount:
              </span>
              <span className="reward-amount header-font">
                ${procedureData?.reward}
              </span>
            </Button>
            <div className="pr-forth-step hide-procedure-btn">
              {serviceButton()}
            </div>

            <Card className="mobile-card">
              <Typography.Title level={4} className="header sub-header-font">
                Top Procedures
              </Typography.Title>
              {procedureLoading ? (
                <Skeleton paragraph={{ rows: 5 }}></Skeleton>
              ) : (
                <>
                  <List
                    size="small"
                    header={false}
                    footer={false}
                    dataSource={displayedProcedures}
                    renderItem={(item) => (
                      <>
                        <List.Item>
                          <List.Item.Meta
                            className="border-0"
                            avatar={<RightOutlined className="arrow-icon" />}
                            title={
                              userData === null ? (
                                <a
                                  href={`/login?procedureName=${encodeURIComponent(
                                    item[1]
                                  )}`}
                                  className="p-font"
                                >
                                  {item[0]}
                                </a>
                              ) : (
                                <a
                                  href={`/user-procedure-detail/${encodeURIComponent(
                                    item[1]
                                  )}`}
                                  className="p-font"
                                >
                                  {item[0]}
                                </a>
                              )
                            }
                          ></List.Item.Meta>
                        </List.Item>
                      </>
                    )}
                  />
                  {displayCount < procedureList.length && (
                    <Button
                      className="bordered-btn w-100 button-font mt-10"
                      onClick={handleViewMore}
                    >
                      View More
                    </Button>
                  )}
                </>
              )}
            </Card>
          </Col>
        </Row>
        {userData === null && (
          <div className="procedure-bg-text">
            <div className="content">
              <h1 className="header-font">
                Please login/sign up to view details
              </h1>
              <Link to={`/login?procedureName=${procedureName}`}>
                <Button className="theme-btn">Login</Button>
              </Link>
            </div>
          </div>
        )}
      </Container>

      <Modal
        className="procedure-form-modal stepper-reward desired-modal"
        centered
        width={700}
        footer={null}
        visible={claimReward}
        // // visible={emailHistory}
        onOk={() => setClaimReward(false)}
        onCancel={() => setClaimReward(false)}
      >
        <>
          <Row gutter={[20, 10]}>
            <Col xs={24} sm={24} md={10} lg={10} xl={10} className="right-col">
              <img src={rewardClaim} alt="" className="reward-img-title" />
            </Col>

            <Col
              xs={24}
              sm={24}
              md={14}
              lg={14}
              xl={14}
              className="reward-column left-col"
            >
              <h4 className="doctor-name header-font">
                How can you get your reward?
              </h4>
              <h6 className="p-font">
                To claim your reward, kindly follow the steps outlined below.
              </h6>
            </Col>
          </Row>

          <div className="reward-claim mt-30">
            <Timeline mode="alternate">
              <Timeline.Item position="right" dot={<img src={stepRound} />}>
                <span className="sub-header-font">STEP 1:</span> <br />
                <p className="p-font">
                  Please select for whom this reward is intended
                </p>
              </Timeline.Item>
              <Timeline.Item
                className="left-pad"
                position="left"
                dot={<div class="step-circle"></div>}
              >
                <span className="sub-header-font">STEP 2:</span> <br />
                <p className="p-font"> Select Type of care.</p>
              </Timeline.Item>
              <Timeline.Item
                color="red"
                position="right"
                dot={<div class="step-circle"></div>}
              >
                <span className="sub-header-font">STEP 3:</span> <br />
                <p className="p-font"> Enter the Name of the procedure</p>
              </Timeline.Item>
              <Timeline.Item
                className="left-pad"
                position="left"
                dot={<div class="step-circle"></div>}
              >
                <span className="sub-header-font">STEP 4:</span> <br />
                <p className="p-font"> Enter the Claim number</p>
              </Timeline.Item>
              <Timeline.Item
                color="red"
                position="right"
                dot={<div class="step-circle"></div>}
              >
                <span className="sub-header-font">STEP 5:</span> <br />
                <p className="p-font">
                  {" "}
                  Take the picture of your Explanation of Benefits (EOB) and
                  upload it
                </p>
              </Timeline.Item>
              <Timeline.Item
                className="left-pad"
                position="left"
                dot={<div class="step-circle-yellow"></div>}
              >
                <span className="sub-header-font">STEP 6:</span> <br />
                <p className="p-font">
                  {" "}
                  The Delphi Team will review your Explanation of Benefits (EOB)
                  and <br /> get back to you.
                </p>
              </Timeline.Item>
              <div
                onClick={() => {
                  navigate("/");
                  localStorage.setItem("activeTabKey", "item-5");
                }}
                style={{ paddingBottom: "20px" }}
              >
                <Timeline.Item
                  dot={
                    <Button
                      className="theme-btn"
                      onClick={() => {
                        navigate("/");
                        localStorage.setItem("activeTabKey", "item-5");
                      }}
                    >
                      <span className="button-font"> Get My Reward</span>
                    </Button>
                  }
                ></Timeline.Item>
              </div>
            </Timeline>
          </div>
        </>
      </Modal>
    </>
  );
}
