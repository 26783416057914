import React, { useEffect, useState } from "react";
import {
  ListDoctorByName,
  ListICcenterByName,
  ListLabs,
  ListPtCenterByName,
  ListSurgeryCenter,
} from "utility/action";
import SkeletonBlock from "utility/SkeletonBlocks";

function MapView(props) {
  const [mapData, setMapData] = useState([]);
  const [mapLoading, setMapLoading] = useState(false);
  const API_KEY = process.env.REACT_APP_GOOGLEMAP_API_KEY;

  useEffect(() => {
    setMapLoading(true);
    if (!window.google) {
      const googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&callback=initMap`;
      googleMapsScript.async = true;
      window.initMap = initMap;
      document.body.appendChild(googleMapsScript);
    } else {
      setMapLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!mapLoading && window.google && window.google.maps) {
      initMap();
    }
  }, [mapLoading]);

  async function fetchData() {
    setMapLoading(true);
    let response;
    const activeOnPortal = {activeOnPortal: {eq: true}}
    switch (props?.service) {
      case "Doctor":
        response = await ListDoctorByName(999, activeOnPortal);
        break;
      case "IC":
        response = await ListICcenterByName(999, activeOnPortal);
        break;
      case "SC":
        response = await ListSurgeryCenter(999, activeOnPortal);
        break;
      case "Labs":
        response = await ListLabs(999, activeOnPortal);
        break;
      case "PT":
        response = await ListPtCenterByName(999, activeOnPortal);
        break;
    }
    setMapData(response);
    initMap();
    setMapLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, [props.service]);

  useEffect(() => {
    if (!mapLoading) {
      initMap();
    }
  }, [mapLoading]);

  async function initMap() {
    try {
      const mapDiv = document.getElementById(`map-${props?.service}`);
      if (mapDiv && window.google && window.google.maps) {
        const map = new window.google.maps.Map(mapDiv);
        const validServiceProps = {
          IC: ["icOfficeName", "icMapSource"],
          Doctor: ["doctorName"],
          SC: ["surgeryCenterName", "surgeryMapSource"],
          Labs: ["labName", "labMapSource"],
          PT: ["ptName", "ptMapSource"],
        };

        let validCenters = mapData.filter((center) => {
          const validProps = validServiceProps[props.service];
          return validProps.every(
            (prop) =>
              center?.location?.lat !== null &&
              center?.location?.lon !== null &&
              center[prop] !== null
          );
        });

        validCenters.forEach((center) => {
          const lat = parseFloat(center?.location?.lat);
          const lng = parseFloat(center?.location?.lon);

          const marker = new window.google.maps.Marker({
            position: { lat: lat, lng: lng },
            map,
            title: center[validServiceProps[props.service][0]],
            // icon: {
            //   url: "https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/images/icons/Vector.png",
            // },
          });

          // marker.addListener("mouseover", () => {
          //   const infoWindow = new window.google.maps.InfoWindow({
          //     content: `<iframe src="${
          //       center[validServiceProps[props.service][1]]
          //     }" width="300" height="200"></iframe>`,
          //   });
          //   infoWindow.open(map, marker);
          // });

          marker.addListener("mouseover", () => {
            //allow info window only if there is google map src link else not
            const infoWindow = center[validServiceProps[props.service][1]] ? new window.google.maps.InfoWindow({
              content: `<iframe src="${
                center[validServiceProps[props.service][1]]
              }" width="300" height="200"></iframe>`,
            }) : "";
            infoWindow.open(map, marker);

            marker.addListener("mouseout", () => {
              // infoWindow.close();
              if (infoWindow) {
                setTimeout(() => {
                  infoWindow.close();
                  infoWindow = null;
                }, 3000); // 5 second delay
              }
            });
          });
        });

        const bounds = validCenters.reduce((bounds, center) => {
          bounds.extend(
            new window.google.maps.LatLng(
              center?.location?.lat,
              center?.location?.lon
            )
          );
          return bounds;
        }, new window.google.maps.LatLngBounds());

        map.fitBounds(bounds);
      }
    } catch (err) {
      console.log("errrrrrrrr", err);
    }
  }

  return mapLoading ? (
    <SkeletonBlock style={{ width: 940, height: 500 }} />
  ) : (
    <div id={"map-" + props?.service} style={{ height: "500px" }} />
  );
}

export default MapView;
