import React, { useState } from "react";
import {
  Row,
  DatePicker,
  Col,
  Input,
  Button,
  Form,
  Select,
  message,
  InputNumber,
  Grid,
} from "antd";
import { useSelector } from "react-redux";
import {
  formatNumberUS,
  infusionDrugs,
  servicesNamePattern,
  tenDigit,
} from "utility/constant";
import SuccessModel from "utility/SuccessModel";
import { CreateInfusionAppointment } from "utility/action";
export default function InfusionAppointment(props) {
  const [providerCheck, setProviderCheck] = useState(false);
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [successModel, setSuccessModel] = useState(false);
  const [selfSelect, setSelfSelect] = useState(false);
  const [value, setValue] = useState();
  const [dob, setDob] = useState("");
  const [contacted, setContacted] = useState();
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [form] = Form.useForm();
  const { Option } = Select;
  const { TextArea } = Input;
  const isModal = props?.isModal;
  const icData = props?.data ? props?.data : props?.icData;
  const ptName = props?.data?.name ? props?.data?.name : props?.name;
  const userData = useSelector((state) => state?.userReducer?.userData);

  const onChangeDatepickerDOB = (date, dateString) => {
    setDob(dateString);
  };

  //This will only allow those drugs which are provided by that particular infusion center
  const availableDrugs = infusionDrugs.filter((element) => {
    return props?.infusionCenterDetail?.infusionDrugs?.includes(element.value);
  });

  const handleChange = (value) => {
    if (value === "My Self") {
      setIsOtherSelected(false);
      setSelfSelect(true);
      form.setFieldsValue({
        name: userData?.profile?.firstName + " " + userData?.profile?.lastName,
      });
    } else if (value === "Other") {
      setIsOtherSelected(true);
      setSelfSelect(false);
      form.setFieldsValue({
        name: "",
      });
    }
    setAppointmentFor(value);
  };

  const onFinish = async (values) => {
    try {
      setCreateLoading(true);
      const inputData = {
        userData: {
          id: userData?.id,
          name:
            userData?.profile?.firstName + " " + userData?.profile?.lastName,
          otherName: values?.name,
          appointmentFor: appointmentFor,
          email: values?.email ? values?.email : userData?.profile?.email,
          phone: values?.phone ? values?.phone : userData?.profile?.phone,
          dob: userData?.profile?.dob,
          preferredTime: values?.preferredTime,
          otherDob: dob === "" ? null : dob,
          insuranceCarrier: userData?.profile?.insuranceCarrier,
          memberID: userData?.profile?.memberID,
        },
        userID: userData?.id,
        infusionCenterData: props?.icData,
        referringProvider: values?.referringProvider,
        providerPracticeName: values?.providePractiseName,
        providerPhoneNumber: values?.phone,
        drugBeingInfused: values?.drugBeingInfused,
        status: true,
        viewed: false,
      };
      await CreateInfusionAppointment(inputData);

      form.resetFields();
      setSelfSelect(false);
      if (isModal) {
        props?.onCloseModal();
      }
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const handleClick = () => {
    props.onUpdateTourIndex(2);
  };

  return (
    <>
      {contextHolder}
      <Form
        name="doctor"
        onFinish={onFinish}
        autoComplete="off"
        id="appForm"
        layout="vertical"
        form={form}
      >
        <div className="appointment-modal">
          <div className="request-appointment">
            {isModal ? (
              <div className="text-center">
                <h6 className="header-font">Appointment Form</h6>
                <span className="fs-1 p-font">
                  Let Delphi help you find an Infusion Center.
                </span>
              </div>
            ) : screens.xs ? (
              ""
            ) : (
              <h6 className="header-font">
                Request an appointment with: {ptName}
              </h6>
            )}
            <Row className="mt-20">
              <Col md={isModal ? 22 : 24} offset={isModal ? 1 : 0}>
                {/* <Col md={22}>
                 */}
                <div className="reward-input-box r-mt-10">
                  <Row
                    gutter={20}
                    className={props?.className}
                    onClick={handleClick}
                  >
                    <Col md={24}>
                      <Row gutter={20}>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            name="appointmentFor"
                            label={
                              <span className="p-font">
                                Who is the appointment for?
                              </span>
                            }
                            rules={[
                              {
                                required: true,
                                message: "Please select any option!",
                              },
                            ]}
                          >
                            <Select
                              className="form-control select font"
                              defaultValue="Select any option"
                              onChange={handleChange}
                              options={[
                                {
                                  value: "My Self",
                                  label: "My Self",
                                },
                                {
                                  value: "Other",
                                  label: "Other",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={<span className="p-font">Name</span>}
                            name="name"
                            rules={[
                              {
                                required: true,
                                message: "Please input your name!",
                              },
                              {
                                pattern: servicesNamePattern,
                                message: "Please input a valid name",
                              },
                            ]}
                          >
                            <Input disabled={selfSelect} />
                          </Form.Item>
                        </Col>

                        {isOtherSelected && (
                          <>
                            <Col span={12} md={12} xs={24} sm={24}>
                              <Form.Item
                                label={<span className="p-font">DOB</span>}
                                name="dob"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input DOB!",
                                  },
                                ]}
                              >
                                <DatePicker
                                  onChange={onChangeDatepickerDOB}
                                  disabledDate={(d) =>
                                    !d || d.isAfter(new Date())
                                  }
                                  className="datepicker"
                                  allowClear={false}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">
                                Preferred time of day
                              </span>
                            }
                            name="preferredTime"
                            rules={[
                              {
                                required: true,
                                message: "Please select preferred time of day",
                              },
                            ]}
                          >
                            <Select
                              className="form-control select-font"
                              defaultValue="Select Any Option"
                              allowClear
                            >
                              <Option value="MORNING">Morning</Option>
                              <Option value="LUNCH">Lunch</Option>
                              <Option value="AFTERNOON">Afternoon</Option>
                              <Option value="NO_PREFERENCE">
                                No Preference
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">Referring provider</span>
                            }
                            name="referringProvider"
                            rules={[
                              {
                                required: true,
                                message: "Please enter referring provider",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">
                                Provider practice name
                              </span>
                            }
                            name="providePractiseName"
                            rules={[
                              {
                                required: true,
                                message: "Please select preferred time of day",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col
                          span={12}
                          md={12}
                          xs={24}
                          sm={24}
                          className="remove-height"
                        >
                          <Form.Item
                            label={
                              <span className="p-font">
                                Provider/Practice phone number
                              </span>
                            }
                            name="phone"
                            rules={[
                              {
                                required: true,
                                message: "Phone number is required!",
                              },
                              {
                                pattern: tenDigit,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => {
                                return formatNumberUS(value);
                              }}
                              parser={(value) => {
                                const cleanedValue = value.replace(/\D/g, "");
                                return cleanedValue.slice(0, 10);
                              }}
                              style={{
                                height: "35px !important",
                                borderRadius: "5px !important",
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">Drug being infused</span>
                            }
                            name="drugBeingInfused"
                            rules={[
                              {
                                required: true,
                                message: "Please select preferred time of day",
                              },
                            ]}
                          >
                            <Select
                              defaultValue="Select any drug"
                              options={availableDrugs}
                              showSearch
                              allowClear
                              className="form-control select-font"
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Col
                    span={12}
                    md={24}
                    xs={24}
                    sm={24}
                    style={{ textAlign: "center" }}
                    className="mt-20"
                  >
                    <Form.Item className="form-bottom">
                      {props?.tourIndex === 2 && props?.tour ? (
                        <Button
                          onClick={() => props.onUpdateTourIndex(10)}
                          type="primary"
                          className="theme-btn button-font ic-third-step"
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          loading={createLoading}
                          type="primary"
                          className="theme-btn button-font"
                          htmlType="submit"
                          form="appForm"
                          key="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>

      <SuccessModel
        message="Appointment Requested Successfully!"
        open={successModel}
      />
    </>
  );
}
