import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Modal,
  Form,
  Divider,
  message,
  Empty,
  Skeleton,
  Grid,
} from "antd";
import { useState } from "react";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import Container from "commons/Container";
import { Link, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";

import locationIcon from "assets/icons/location.svg";
import { ListNearByInfusionCenters } from "utility/action";
import { useCallback } from "react";
import usePaginationSearch from "utility/usePaginationSearch";
// import PtAppointment from "./Appointment/PtAppointment";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import { useDispatch, useSelector } from "react-redux";
import InfusionAppointment from "./InfusionAppointment";
import { setTourSteps } from "store/actions/tourSteps";
import {
  END_TOUR,
  INFUSION_DETAILS_TOUR_START,
  INFUSION_TOUR_START,
  PT_PROFILE_TOUR_START,
} from "utility/constant";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";
import PaginatedList from "utility/PaginatedList";

export default function InfusionCenterList() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [open, setOpen] = useState(false);
  const [ptData, setPTData] = useState([]);
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [form] = Form.useForm();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [icData, setICData] = useState({});
  const userData = useSelector((state) => state.userReducer?.userData);
  const locationCoordinates = userData?.profile?.location;
  const [tour, setTour] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);

  const totalLen = ptData?.length;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const fetchData = useCallback(async () => {
    try {
      setSkeletonLoader(true);

      const response = await ListNearByInfusionCenters(999, {
        lat: locationCoordinates?.lat,
        lon: locationCoordinates?.lon,
      });
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item.profilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.profilePhoto) item.profilePhoto = url;
            }
          }
          return item;
        })
      );
      setPTData(updatedResponse);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === INFUSION_DETAILS_TOUR_START) {
        setTour(true);
      }
      setTimeout(() => {
        dispatch(setTourSteps(END_TOUR));
      }, 500);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  } = usePaginationSearch(ptData);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderPT = (ptData) => {
    return (
      <Col span={8} lg={8} md={12} xs={24} key={ptData?.id} className="mt-10">
        <div className="single-list-card-surgery ">
          <div className="surgery-profile-card">
            <div className="d-flex gap-3">
              <img
                src={ptData?.profilePhoto}
                alt="Company Img"
                className="doctor-img"
              />
              <h4 className="doctor-name sub-header-font">{ptData?.name}</h4>
            </div>
            <div className="d-flex mt-20 rat-box">
              <p className="p-font">
                <img src={facebook} alt="Nel Img" />{" "}
                {ptData?.facebookRating === 0 ||
                ptData?.facebookRating === null ? (
                  "NA"
                ) : (
                  <>
                    {ptData?.facebookRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
              <Divider className="h-15em" type="vertical" />
              <p className="p-font">
                <img src={google} alt="Nel Img" />{" "}
                {ptData?.googleRating === 0 || ptData?.googleRating === null ? (
                  "NA"
                ) : (
                  <>
                    {ptData?.googleRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
            </div>
            <div className="space-between app-bottom-margin">
              <p className="single-address-line p-font">
                <img src={locationIcon} alt="Address Icon" />{" "}
                {parseFloat(ptData?.distance).toFixed(2) !== "0.00"
                  ? `${parseFloat(ptData?.distance).toFixed(2)} Miles from
                                  you`
                  : "Near You"}
              </p>

              <Link
                to="/up-infusion-center-details"
                state={{ id: ptData?.id }}
                className="learn-more-btn"
                onClick={() => {
                  if (tour) {
                    dispatch(setTourSteps(PT_PROFILE_TOUR_START));
                  }
                }}
              >
                <Button className="blue-border-btn ic-forth-step">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>

          <div
            className="request-app"
            onClick={() => {
              if (tour) {
                setTimeout(() => {
                  setTourIndex(1);
                }, 400);
              }
            }}
          >
            <Button
              className="theme-btn p-font ic-first-step"
              onClick={() => {
                setOpen(true);

                setAppointmentFor(ptData);
                setICData({
                  id: ptData?.id,
                  name: ptData?.name,
                  email: ptData?.email,
                  phone: ptData?.phone,
                  fax: ptData?.fax,
                });
              }}
            >
              Request Appointment
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const serviceSteps = [
    {
      target: ".ic-first-step",
      content: (
        <TourStepContent
          heading="Request Appointment"
          details="Click to schedule an appointment with center of your choice"
          numbers={{ currentStep: 2, totalSteps: 6 }}
          // onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".ic-second-step",
      content: (
        <TourStepContent
          heading="Appointment Form"
          details="Please fill out the required fields."
          numbers={{ currentStep: 3, totalSteps: 6 }}
          // onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".ic-third-step",
      content: (
        <TourStepContent
          heading="Submit"
          details="Click the Submit button to schedule an appointment."
          numbers={{ currentStep: 4, totalSteps: 6 }}
          // onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".ic-forth-step",
      content: (
        <TourStepContent
          heading="Learn More"
          details="Click to view more information about the Infusion Center"
          numbers={{ currentStep: 5, totalSteps: 6 }}
          // onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const handleUpdateTourIndex = (index) => {
    if (index === 10) {
      setTourIndex(3);
      setOpen(false);
    } else {
      setTourIndex(2);
    }
  };

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={serviceSteps}
        run={tour}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />

      <Container>
        <div className="d-flex justify-content-between hide-version-no">
          {/* Sub Header Starts */}
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" className="p-font">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="p-font">Infusion Center List</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* main content starts*/}

        <Card className="list-content mobile-card">
          <div className="space-between">
            <div>
              <p className="card-title-surgery header-font">
                Infusion Center List({totalLen} Result)
              </p>
            </div>
          </div>

          <Col span={24} className="d-flex gap-3 mt-10">
            <Input
              className="main-search border-bottom-only search-font"
              size="large"
              placeholder="Search Here"
              prefix={<SearchOutlined />}
              onChange={handleSearchInputChange}
              value={searchText}
            />
            <Pagination
              onChange={handlePaginationChange}
              total={ptData?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
              className="hide-version-no"
            />
          </Col>
          <Row gutter={[20, 20]} className="r-mt-10">
            {skeletonLoader ? (
              <>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
              </>
            ) : (
              <>
                {paginatedItems.length > 0 ? (
                  <>
                    {paginatedItems.map(renderPT)}
                    {screens.xs && (
                      <Col
                        span={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        className="mt-10"
                      >
                        <Pagination
                          onChange={handlePaginationChange}
                          total={ptData?.length}
                          defaultPageSize={9}
                          current={currentPage}
                          itemRender={PrevNextArrow}
                        />
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="no-data">
                    <Col md={24}>
                      <Empty />
                    </Col>
                  </div>
                )}
              </>
            )}
          </Row>
        </Card>
      </Container>

      <Modal
        title="Modal 1000px width"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        className="appointment-modal desired-modal"
        footer={null}
        keyboard={false}
      >
        <InfusionAppointment
          isModal={true}
          icData={icData}
          tour={tour}
          infusionCenterDetail={appointmentFor}
          onCloseModal={handleCloseModal}
          className={tourIndex === 1 ? "ic-second-step" : ""}
          onUpdateTourIndex={handleUpdateTourIndex}
          tourIndex={2}
        />
      </Modal>
    </>
  );
}
