import {
  Row,
  Card,
  Col,
  Typography,
  Modal,
  Button,
  Form,
  Image,
} from "antd";
import "./Rewards.less";
import { useState } from "react";
import uploadIcon from "assets/icons/upload.svg";
import successIcon from "assets/icons/Success.svg";
import { API } from "aws-amplify";
import { createRewardSubmissions } from "graphql/mutations";
import { uploadFileToS3 } from "utility/uploadFileToS3";
import { useLocation } from "react-router-dom";

export default function RewardSubmission() {
  const [imageKey, setImageLey] = useState("");
  const [rewardFor, setRewardFor] = useState("");
  const [care, setCare] = useState("");
 

  

  // for success modal
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    const inputData = {
      input: {
        customerID: "sfe",
        rewardFor: rewardFor,
        name: null,
        dob: null,
        state: null,
        typeOfCare: care,
        nameOfProcedure: values.nameOfProcedure,
        claimNumber: values.claimNumber,
        uploadedImages: imageKey,
      },
    };
    const responce = await API.graphql({
      query: createRewardSubmissions, 
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
  });
   
    setIsModalOpen(true);
    // navigate("/");
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Card className="mb-2">
      <Row justify="center">
        <Col xl={14} md={24}>
          <div className="reward-header">
            {/* <img src={award} /> */}
            <Typography.Title level={5}>Confirm My Submission</Typography.Title>
            <span>
              Please confirm this submission is for: Myself,
              <br /> The procedure is: Avein and the claim number is: 54321
            </span>
          </div>
          <Form
            name="doctor"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            id="myForm"
          >
            <div className="reward-input-box">
              <Row style={{justifyContent:"center"}}>
                {/* <Col span={24} className="mt-1">
                  <Upload {...props}>
                    <Button icon={<UploadIcn />}>
                      Click here to upload files
                    </Button>
                  </Upload>
                </Col> */}
                <Image
                style={{justifyContent: "center"}}
                  width={620}
                  height={391}
                  src="https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
                />
                <Col span={24} className="mt-3">
                 
                  <br />
                  <Button type="primary" className="mt-3" htmlType="submit">
                    Submit
                  </Button>
                  <Modal
                    title="Basic Modal"
                    open={isModalOpen}
                    onOk={handleOk}
                    centered
                    onCancel={handleCancel}
                    className="reward-success-modal"
                  >
                    <img src={successIcon} alt="Success Img" />
                    <h6>Upload Successful!</h6>
                    <p>
                      Your check will be sent to the address on file, if you
                      would like to change that address, contact member
                      services.
                    </p>
                    <div className="footer-btn">
                      <Button className="theme-btn">Go to My Account</Button>
                    </div>
                  </Modal>
                </Col>
              </Row>
            </div>
          </Form>
        </Col>
      </Row>
    </Card>
  );
}
