import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function LabTour(props) {
  const serviceSteps = [
    {
      target: ".lab-first-step",
      content: (
        <TourStepContent
          heading="Lab"
          details="Click to redirect to a lab web page to book an appointment."
          numbers={{ currentStep: 1, totalSteps: 4 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={serviceSteps}
        run={props?.labTour}
        stepIndex={props?.labTourIndex}
        setTourIndex={props?.setLabTourIndex}
      />
    </>
  );
}
