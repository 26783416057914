import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Col,
  Select,
  Modal,
  Typography,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { getCompanyRegi } from "graphql/queries";
import { updateEmployeeRegi } from "graphql/mutations";
import { useCallback } from "react";

export default function UnsubscribeRequest(props) {
  const { Search } = Input;
  const { Option } = Select;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employee, setEmployeeData] = useState([]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    console.log("Success:", values);
  };

  const location = useLocation();
  const cognitoID = location?.state?.id;

  const approveEmployee = async (id) => {
    const inputData = {
      input: {
        id: id,
        verified: true,
      },
    };
    await API.graphql({
      query: updateEmployeeRegi,
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).then(async (res) => {
      const responce = await API.graphql({
        query: getCompanyRegi,
        variables: {
          id: cognitoID,
          filter: {
            data: {
              getCompanyRegi: {
                employee: { verified: { eq: false } },
              },
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const requestedEmployee = responce.data.getCompanyRegi.employee.items
        .filter((row) => row.verified === false)
        .map((row) => ({
          firstName: row.firstName,
          lastName: row.lastName,
          emailId: row.employeeEmail,
          phoneNumber: "Pending",
          requestDate: row.createdAt,
          Id: row.id,
        }));
      setEmployeeData(requestedEmployee);
    });
  };
  const fetchData = useCallback(async () => {
    const responce = await API.graphql({
      query: getCompanyRegi,
      variables: {
        id: cognitoID,
        filter: {
          data: {
            getCompanyRegi: {
              employee: { verified: { eq: false } },
            },
          },
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const requestedEmployee = responce.data.getCompanyRegi.employee.items
      .filter((row) => row.verified === false)
      .map((row) => ({
        firstName: row.firstName,
        lastName: row.lastName,
        emailId: row.employeeEmail,
        phoneNumber: "Pending",
        requestDate: row.createdAt,
        Id: row.id,
      }));
    setEmployeeData(requestedEmployee);
  }, [cognitoID]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      render: (item) => {
        return <Link to="/imaging-center-detail">{item}</Link>;
      },
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Plan Type",
      dataIndex: "planType",
    },
    {
      title: "Plan Amount",
      dataIndex: "planAmount",
    },
    {
      title: "Billing Date",
      dataIndex: "billingDate",
    },
    {
      title: "Cancellation Reason",
      dataIndex: "cancelReason",
    },
    {
      title: "",
      dataIndex: "Id",
      className: "actions",
      render: (id) => {
        return (
          <>
            <Space wrap>
              <Button
                className="blue-btn"
                onClick={() => {
                  approveEmployee(id);
                }}
              >
                Approve
              </Button>

              <Button className="" onClick={showModal}>
                Reject
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between table-header">
        <Row align="middle">
          <Search
            placeholder="Search"
            className="searchbar"
            onSearch={(e) => console.log(e)}
            style={{ width: 250 }}
          />
        </Row>

        {/* <Row className="gap-10 mtr-20">
          <Button className="blue-btn">Approve All</Button>
          <Button className="blue-border-btn">Reject All</Button>
          <Button className="blue-border-btn">
            <CSVLink
              // data={data}
              // headers={headers}
              // filename={"Unsubscribe.csv"}
              className="export-btn"
            >
              Export CSV
            </CSVLink>
          </Button>
        </Row> */}
      </Row>

      <Table
        rowSelection={{
          type: "Checkbox",
        }}
        columns={columns}
        dataSource={employee}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
      />

      <Modal
        className="form-modal"
        centered
        footer={null}
        title={
          <Typography.Title level={3}>Add Imaging center</Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
          layout="vertical"
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Office Name" name="OfficeName">
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Specialties" name="Specialties">
                <Select
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Tags Mode"
                  maxTagCount="responsive"
                >
                  <Option value="Eyes">Eyes</Option>
                  <Option value="EarNoseAndThroat">Ear Nose and Throat</Option>
                  <Option value="DigestiveSystem">Digestive System</Option>
                  <Option value="Elbow">Elbow</Option>
                  <Option value="SpineAndBack">Spine and Back</Option>
                  <Option value="Hip">Hip</Option>
                  <Option value="Knee">Knee</Option>
                  <Option value="AnkleAndFoot">Ankle and Foot</Option>
                  <Option value="HandAndWrist">Hand and Wrist</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item label="Office Email" name="OfficeEmail">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Button className="blue-btn">Submit</Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
