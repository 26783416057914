import ortho from "assets/icons/orthopaedic.svg";
import ophtha from "assets/icons/ophtha.svg";
import otolary from "assets/icons/otolary.svg";
import pain from "assets/icons/pain.svg";
import physiatrist from "assets/icons/physiatrist.svg";
import podiatrist from "assets/icons/podiatrist.svg";
import gastro from "assets/icons/gastro.svg";
import generalS from "assets/icons/generalS.svg";
import head from "assets/surgery-icons/head.svg";
import sports from "assets/surgery-icons/sports.svg";
import urology from "assets/surgery-icons/urology.svg";

import bmri from "assets/ic-icons/bmri.svg";
import ultrasound from "assets/ic-icons/ultrasound.svg";
import pet from "assets/ic-icons/pet.svg";
import ct from "assets/ic-icons/ct.svg";
import arthogram from "assets/ic-icons/arthogram.svg";
import mammogram from "assets/ic-icons/mammogram.svg";
import bone from "assets/ic-icons/bone.svg";
import xrayNew from "assets/ic-icons/xrayup.svg";
import mriup from "assets/ic-icons/mriup.svg";

export const doctorSpeciality = [
  {
    id: 1,
    title: "General Surgeon",
    image: generalS,
    description: "",
  },
  {
    id: 2,
    title: "Gastroenterologist",
    image: gastro,
    description: "Stomach & Colon",
  },
  {
    id: 3,
    title: "Neuro Surgeon",
    image: head,
    className: "doc-second-step",
    description: "Head, Spine & Back",
  },
  {
    id: 4,
    title: "Ophthalmologist",
    image: ophtha,
    description: "Eyes",
  },
  {
    id: 5,
    title: "Otolaryngologists",
    image: otolary,
    description: "Ear, Nose & Throat",
  },
  {
    id: 6,
    title: "Orthopaedic",
    image: ortho,
    className: "doc-second-step",
    description:
      "Ankle & foot, Elbow, Hip, Hand & Wrist,Head, Knee, Neck, Shoulder, Spine & Back",
  },
  {
    id: 7,
    title: "Podiatrist",
    image: podiatrist,
    description: "Ankle & Foot",
  },
  {
    id: 8,
    title: "Pain Management",
    image: pain,
    description: "Spine & Back, Neck",
  },
  {
    id: 9,
    title: "Physiatrist",
    image: physiatrist,
    description: "Spine & Back, Head, Neck",
  },
  {
    id: 10,
    title: "Sports Medicine",
    image: sports,
    description: "",
  },
  {
    id: 11,
    title: "Urology",
    image: urology,
    description: "Internal",
  },
];

export const surgeryCenter = [
  {
    id: 1,
    title: "General Surgery",
    image: generalS,
    description: "Internal",
  },
  {
    id: 2,
    title: "Gastroenterology",
    image: gastro,
    description: "Stomach",
  },
  {
    id: 3,
    title: "Neuro Surgeon",
    image: head,
    description: "Head, Spine & Back",
  },
  {
    id: 4,
    title: "Opthalmology",
    image: ophtha,
    description: "Eyes",
  },
  {
    id: 5,
    title: "Otolaryngology",
    image: otolary,
    description: "Ear, Nose & Throat",
  },
  {
    id: 6,
    title: "Orthopaedics",
    image: ortho,
    description:
      "Ankle & foot, Elbow, Hip, Hand & Wrist, Knee, Leg, Neck, Shoulder, Spine & Back",
  },
  {
    id: 7,
    title: "Podiatry",
    image: podiatrist,
    description: "Ankle & Foot",
  },
  {
    id: 8,
    title: "Pain Management",
    image: pain,
    description: "Spine & Back, Neck",
  },
  {
    id: 9,
    title: "Physiatrist",
    image: physiatrist,
    description: "Spine & Back, Head, Neck",
  },
  {
    id: 10,
    title: "Sports Medicine",
    image: sports,
    description: "",
  },
  {
    id: 11,
    title: "Urology",
    image: urology,
    description: "Internal",
  },
];

export const imagingCenterList = [
  {
    id: 1,
    title: "Arthrogram",
    image: arthogram,
    description: "Arthrogram",
  },
  {
    id: 2,
    title: "Breast MRI",
    image: bmri,
    description: "Breast MRI",
  },
  {
    id: 3,
    title: "Bone Density",
    image: bone,
    description: "Bone Density",
  },
  {
    id: 4,
    title: "CT",
    image: ct,
    description: "CT",
  },
  {
    id: 5,
    title: "Mammogram",
    image: mammogram,
    description: "Mammogram",
  },
  {
    id: 6,
    title: "MRI",
    image: mriup,
    description: "MRI",
  },
  {
    id: 7,
    title: "PET",
    image: pet,
    description: "PET",
  },
  {
    id: 8,
    title: "Ultrasound",
    image: ultrasound,
    description: "Ultrasound",
  },
  {
    id: 9,
    title: "X-ray",
    image: xrayNew,
    description: "X-ray",
  },
];
