import React, { useState } from "react";
import { Row, Col, Form, Input, Button, Alert } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import bazarLogo from "assets/delphy/delphy.svg";

import Footer from "commons/Footer";

import "./ResetPassword.less";
import { emailPattern } from "utility/constant";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  //forgot pass code
  const confirmReset = async (e) => {
    setLoading(true);
    setError("");
    await Auth.forgotPassword(e.email)
      .then(() => {
        setLoading(false);
        navigate(`/reset-password`, { state: { userName: e.email } });
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message);
      });
  };

  return (
    <>
      <Row className="reset-password-wrapper" justify="center">
        <Col span={12} lg={12} md={0} sm={0} xs={0}>
          <div className="reset-password-page-image"></div>
        </Col>
        <Col
          className="form-footer-container"
          span={13}
          md={24}
          lg={12}
          xs={24}
          sm={24}
        >
          <Row justify="center" align="middle" className="w-100">
            <Col span={12} md={12} lg={12} xs={20} sm={20}>
              <div className="form-logo-container">
                <img src={bazarLogo} alt="" className="bazar-logo h-60" />
                <h2 className="reset-password-title sub-header-font">
                  Reset Password
                </h2>
                {error && (
                  <Alert
                    style={{ marginBottom: "10px" }}
                    type="error"
                    showIcon={false}
                    message={error}
                    banner
                  />
                )}
                <Form
                  requiredMark={false}
                  layout="vertical"
                  className="reset-password-form w-100"
                  onFinish={(e) => confirmReset(e)}
                >
                  <Form.Item
                    className="email-form-item"
                    name="email"
                    rules={[
                      { required: true, message: "Please input your Email!" },
                      {
                        pattern: emailPattern,
                        message: "Please provide valid Email!",
                      },
                    ]}
                  >
                    <Input
                      id="email"
                      key="email"
                      name="email"
                      className="email"
                      placeholder="Email ID"
                      size="large"
                      disabled={loading}
                      prefix={<MailOutlined />}
                    />
                  </Form.Item>

                  <Button
                    className="reset-password-button button-font"
                    htmlType="submit"
                    loading={loading}
                  >
                    Send Code
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
          <Footer />
        </Col>
      </Row>
    </>
  );
};

export default ResetPassword;
