import {
  Col,
  Input,
  Row,
  Card,
  Table,
  message,
  Divider,
  Pagination,
  Tooltip,
  Grid,
  Skeleton,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { SearchOutlined, EyeOutlined } from "@ant-design/icons";
import "./Procedures.less";
import "./ProcedureResponsive.less";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ListPRocedureByName } from "utility/action";
import search from "utility/search";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";
import { END_TOUR } from "utility/constant";
import { setTourSteps } from "store/actions/tourSteps";
import TableSkeleton from "utility/TableSkeleton";
import Info from "assets/img/iota.svg";

export default function Procedures() {
  const [procedureData, setProcedureData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState(procedureData);
  const [messageApi, contextHolder] = message.useMessage();
  const [tourIndex, setTourIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = dataSource.slice(indexOfFirstItem, indexOfLastItem);

  const billingStatus = useSelector(
    (state) => state?.userReducer?.userData?.billing_status
  );
  const userType = useSelector(
    (state) => state?.userReducer?.userData?.userType
  );
  const companyID = useSelector(
    (state) => state?.userReducer?.userData?.companyRegiEmployeeId
  );
  const companyDetails = useSelector(
    (state) => state?.userReducer?.userData?.companyID
  );
  const isIndividual =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    userType === "INDIVIDUAL" &&
    !companyID &&
    !companyDetails;
  const isUnderStripeEmployer =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
    companyID !== null &&
    companyDetails !== null;

  const rewardTour = localStorage.getItem("startProTour");

  const setProcedureRow = function (row) {
    return {
      key: row.id,
      searchByName: row?.searchByName,
      billingStatus: row?.billing_status,
      topProcedures: row?.name,
      averageSavings: row?.savings
        ? `$${parseInt(row?.savings).toLocaleString()}`
        : "-",
      marketPrice: row?.marketPrice
        ? `$${parseInt(row?.marketPrice).toLocaleString()}`
        : "-",
      bazarPrice: row?.bazarPrice
        ? `$${parseInt(row?.bazarPrice).toLocaleString()}`
        : "-",
      reward: row?.reward ? `$${row?.reward}` : "-",
    };
  };

  const fetchProcedure = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        activeOnPortal: { eq: true },
      };
      const response = await ListPRocedureByName("procedures", filter);
      const data = response.map((row) => setProcedureRow(row));
      setProcedureData(data);
      setDataSource(data);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);

      if (rewardTour === "true") {
        setIsTourRunning(true);
        setTourIndex(0);

        setTimeout(() => {
          localStorage.setItem("startProTour", false);
        }, 500);
      }
    }
  }, [rewardTour]);

  useEffect(() => {
    fetchProcedure();
  }, [fetchProcedure]);

  const columns = [
    {
      title: "Top Procedures",
      dataIndex: "topProcedures",
      width: 500,
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Average Savings",
      dataIndex: "averageSavings",
      render: (data) => (
        <span className="table-font fw-600" style={{ color: "#00406A" }}>
          {data}
        </span>
      ),
    },
    {
      title: "Market Price",
      dataIndex: "marketPrice",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Delphi Price",
      dataIndex: "bazarPrice",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: (
        <>
          <span>Reward</span>
          {(isIndividual || isUnderStripeEmployer) && (
            <Tooltip
              title={
                <div>
                  Your profile is an individual so you can not <br /> access
                  reward.
                </div>
              }
              overlayInnerStyle={{
                fontSize: "14px",
                textAlign: "center",
                fontWeight: "700",
                color: "#CECECE",
                backgroundColor: "#252525",
              }}
            >
              <img
                src={Info}
                alt=""
                style={{ marginLeft: 10, color: "#00406A !important" }}
              />
            </Tooltip>
          )}
        </>
      ),
      dataIndex: "reward",
      render: (item) => (
        <span
          style={
            isIndividual || isUnderStripeEmployer
              ? { color: "#A8A8A880" }
              : { color: "#229557" }
          }
          className="table-font fw-600"
        >
          {item}
        </span>
      ),
    },
    {
      title: "",
      dataIndex: "searchByName",
      className: "actions",
      render: (searchByName) => (
        <>
          
          <Link
            to={`/user-procedure-detail/${encodeURIComponent(searchByName)}`}
            className="text-black"
            state={isTourRunning ? { isTourRunning } : undefined}
          >
            <EyeOutlined className="text-black pr-first-step" />
          </Link>
        </>
      ),
    },
  ];

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "topProcedures",
      "averageSavings",
      "marketPrice",
      "bazarPrice",
      "reward",
      "searchByName",
    ];
    const filteredData = search(procedureData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const endTour = () => {
    setIsTourRunning(false);
    dispatch(setTourSteps(END_TOUR));
  };

  const procedureSteps = [
    {
      target: ".pr-first-step",
      content: (
        <TourStepContent
          heading="View Procedure Details"
          details="Click here in order to view specific procedure’s detail page."
          numbers={{ currentStep: 1, totalSteps: 7 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={procedureSteps}
        run={isTourRunning}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
        marginLeft="true"
      />

      <Card className="mb-2 procedures mobile-card procedure-card">
        <Input
          className="main-search mt-30"
          size="large"
          placeholder="Search Procedures"
          prefix={<SearchOutlined />}
          onChange={handleSearch}
        />

{screens.xs === true ? (
          <>
            {loading ? (
              <Skeleton
                title={false}
                paragraph={{ rows: 12 }}
                className="mt-20"
              />
            ) : (
              <>
                {currentItems.map((procedure, index) => (
                  <>
                    <Row
                      justify="space-between mt-20 mobile-procedure"
                      key={procedure.id}
                    >
                      <h1 title={procedure.topProcedures}>
                        {procedure?.topProcedures}
                      </h1>
                      <Link
                        to={`/user-procedure-detail/${encodeURIComponent(
                          procedure?.searchByName
                        )}`}
                        className="text-black"
                        state={isTourRunning ? { isTourRunning } : undefined}
                      >
                        <EyeOutlined
                          className="pr-first-step"
                          style={{ fontSize: "17px", color: "#00406A" }}
                        />
                      </Link>
                    </Row>
                    <Row gutter={[10, 10]} className="vertical-line-row mt-10">
                      <Col
                        xs={7}
                        sm={7}
                        md={7}
                        lg={7}
                        xl={7}
                        className="vertical-line-column"
                      >
                        <div className="label avg-sav">Average Savings</div>

                        <div className="amount avg-sav">
                          {procedure.averageSavings}
                        </div>
                      </Col>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="vertical-line-column"
                      >
                        <div className="label">Market Price</div>
                        <div className="amount">{procedure.marketPrice}</div>
                      </Col>
                      <Col
                        xs={6}
                        sm={6}
                        md={6}
                        lg={6}
                        xl={6}
                        className="vertical-line-column"
                      >
                        <div className="label">Delphi Price</div>
                        <div className="amount">{procedure.bazarPrice}</div>
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={5}
                        lg={5}
                        xl={5}
                        className="vertical-line-column"
                      >
                        <div className="label">Reward</div>
                        <div
                          className="amount"
                          style={{ color: "#229557", fontWeight: 700 }}
                        >
                          {procedure.reward}
                        </div>
                      </Col>
                    </Row>
                    {index !== procedureData.length - 1 && (
                      <Divider className="procedure-divider" />
                    )}{" "}
                  </>
                ))}
                <Pagination
                  current={currentPage}
                  onChange={setCurrentPage}
                  total={procedureData.length}
                  pageSize={itemsPerPage}
                  showSizeChanger={false}
                  className="mt-20"
                />
              </>
            )}
          </>
        ) : loading ? (
          <TableSkeleton />
        ) : (
          <Table
            columns={columns}
            dataSource={dataSource}
            className="common-table mt-20 procedure-table"
            pagination={{
              position: ["", "bottomCenter"],
            }}
            scroll={{ x: 992 }}
          />
        )}
      </Card>
    </>
  );
}
