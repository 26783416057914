import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import doctorReducer from "./doctorReducer";
import ICReducer from "./IcReducer";
import userReducer from "./userReducer";
import userIdReducer from "./userIdReducer";
import specialityReducer from "./specialityReducer";
import areaOfSpecialityReducer from "./areaOfSpecialityReducer";
import labReducer from "./labReducer";
import ptReducer from "./ptReducer";
import tourReducer from "./tourReducer";
import surgeryReducer from "./surgeryReducer";
import paginationReducer from "./paginationReducer";
import AppointmentReducer from "./appointmentReducer";
import surgerySpecialityReducer from "./surgerySpecialityReducer";
import globalSearchReducer from "./globalSearchReducer";
import notificationReducer from "./notificationReducer";
import chatReducer from "./chatReducer";
import cognitoUserReducer from "./cognitoUserReducer";

const appReducer = combineReducers({
  loginReducer,
  doctorReducer,
  ICReducer,
  userReducer,
  cognitoUserReducer,
  userIdReducer,
  specialityReducer,
  areaOfSpecialityReducer,
  labReducer,
  ptReducer,
  surgeryReducer,
  paginationReducer,
  AppointmentReducer,
  tourReducer,
  surgerySpecialityReducer,
  globalSearchReducer,
  notificationReducer,
  chatReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
