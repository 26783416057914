import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Divider,
  Empty,
  message,
  Grid,
} from "antd";
import { useState } from "react";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import Container from "commons/Container";
import locationIcon from "assets/icons/location.svg";
import { Link, useNavigate } from "react-router-dom";

import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import { useDispatch, useSelector } from "react-redux";
import { ListNearByLabs } from "utility/action";
import usePaginationSearch from "utility/usePaginationSearch";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import { useCallback } from "react";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import {
  END_TOUR,
  LAB_DETAILS_TOUR_START,
  LAB_PROFILE_TOUR_START,
} from "utility/constant";
import { setTourSteps } from "store/actions/tourSteps";
import PaginatedList from "utility/PaginatedList";
import SkeletonLoader from "utility/SkeletonLoader";

export default function LabList() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [labData, setLabData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  // const labStateData = useSelector((state) => state?.labReducer?.labData);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tour, setTour] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);

  const locationCordinates = useSelector(
    (state) => state.userReducer?.userData?.profile?.location
  );
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const totalLen = labData?.length;

  const fetchData = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const response = await ListNearByLabs(999, {
        lat: locationCordinates?.lat,
        lon: locationCordinates?.lon,
      });
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item.labProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.labProfilePhoto) item.labProfilePhoto = url;
            }
          }
          return item;
        })
      );
      setLabData(updatedResponse);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === LAB_DETAILS_TOUR_START) {
        setTour(true);
        setTimeout(() => {
          dispatch(setTourSteps(END_TOUR));
        }, 500);
      }
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  } = usePaginationSearch(labData);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderLab = (lab) => {
    return (
      <Col span={8} lg={8} md={12} xs={24} key={lab?.id} className="mt-10">
        <div
          className="single-list-card-surgery"
          onClick={() => setTourIndex(1)}
        >
          <div className="surgery-profile-card">
            <div className="d-flex gap-3">
              <img
                src={lab?.labProfilePhoto}
                alt="Doctor Img"
                className="doctor-img"
              />
              <h4 className="doctor-name sub-header-font">{lab?.labName}</h4>
            </div>
            <div className="d-flex mt-20 rat-box">
              <p className="p-font">
                <img src={facebook} alt="Nel Img" />{" "}
                {lab?.labFaceBookRating === 0 ||
                lab?.labFaceBookRating === null ? (
                  "NA"
                ) : (
                  <>
                    {lab?.labFaceBookRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
              <Divider className="h-15em" type="vertical" />
              <p className="p-font">
                <img src={google} alt="Nel Img" />{" "}
                {lab?.labGoogleRating === 0 || lab?.labGoogleRating === null ? (
                  "NA"
                ) : (
                  <>
                    {lab?.labGoogleRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
            </div>

            <div className="space-between" style={{ marginBottom: "0px" }}>
              <p className="single-address-line p-font">
                <img src={locationIcon} alt="Address Icon" /> &nbsp;
                {parseFloat(lab?.distance).toFixed(2) !== "0.00"
                  ? `${parseFloat(lab?.distance).toFixed(2)} Miles from
              you`
                  : "Near You"}
              </p>
              <Link
                to="/lab-center-details"
                state={{ id: lab?.id }}
                className="learn-more-btn"
                onClick={() => {
                  if (tour) {
                    dispatch(setTourSteps(LAB_PROFILE_TOUR_START));
                  }
                }}
              >
                <Button className="blue-border-btn p-font lab-first-step">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const serviceSteps = [
    {
      target: ".lab-first-step",
      content: (
        <TourStepContent
          heading="Learn More"
          details="Click to discover more information about the Lab."
          numbers={{ currentStep: 2, totalSteps: 4 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={serviceSteps}
        run={tour}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />
      <Container>
        <div className="d-flex justify-content-between hide-version-no">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" className="p-font">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="p-font">Lab Center List</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        <Card className="list-content mobile-card">
          <div className="space-between">
            <div>
              <p className="card-title-surgery header-font">
                Lab Center List ({totalLen} Result)
              </p>
            </div>
          </div>

          <Col span={24} className="d-flex gap-3 mt-10">
            <Input
              className="main-search border-bottom-only search-font"
              size="large"
              placeholder="Search Here"
              prefix={<SearchOutlined />}
              onChange={handleSearchInputChange}
              value={searchText}
            />
            <Pagination
              onChange={handlePaginationChange}
              total={labData?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
              className="hide-version-no"
            />
          </Col>
          <Row gutter={[20, 20]} className="r-mt-10">
            {skeletonLoader ? (
              <>
                <SkeletonLoader cols={9} width="100%" height={200} />
              </>
            ) : (
              <>
                {paginatedItems.length > 0 ? (
                  <>
                    {paginatedItems.map(renderLab)}
                    {screens.xs && (
                      <Col
                        span={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        className="mt-10"
                      >
                        <Pagination
                          onChange={handlePaginationChange}
                          total={labData?.length}
                          defaultPageSize={9}
                          current={currentPage}
                          showPrevNextJumpers
                          itemRender={PrevNextArrow}
                        />
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="no-data">
                    <Col md={24}>
                      <Empty />
                    </Col>
                  </div>
                )}
              </>
            )}
          </Row>
        </Card>
      </Container>
    </>
  );
}
