import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function AdminInviteDependent(props) {
  const adminDependent = [
    {
      target: ".admin-dep-first-step",
      content: (
        <TourStepContent
          heading="Invite Dependent"
          details="Click to invite your dependent."
          numbers={{ currentStep: 1, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-dep-second-step",
      content: (
        <TourStepContent
          heading="Invite Dependent"
          details="You can easily invite your Dependent by simply click on this
          button."
          numbers={{ currentStep: 2, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-dep-third-step",
      content: (
        <TourStepContent
          heading="Select Dependent"
          details="Select your dependents from the drop down and send invite to
          them. If the email address is not available, you can simply
          enter."
          numbers={{ currentStep: 3, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-dep-forth-step",
      content: (
        <TourStepContent
          heading="Select Dependent"
          details="Click to Send button to your selected dependent."
          numbers={{ currentStep: 4, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".admin-dep-fifth-step",
      content: (
        <TourStepContent
          heading="Invitation Sent"
          details="Great! Your invitation has been sent successfully."
          numbers={{ currentStep: 5, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={adminDependent}
        run={props?.inviteAdminTourDep}
        stepIndex={props?.inviteAdminTourDepIndex}
        setTourIndex={props?.setInviteAdminTourDepIndex}
      />
    </>
  );
}
