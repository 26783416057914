import React from "react";
import "./MainLayout.less";
import AdminLayout from "./AdminLayout";
import UserLayout from "./UserLayout";
import { useSelector } from "react-redux";

export default function MainLayout(props) {
  const cognitoUser = useSelector(
    (state) => state.cognitoUserReducer?.cognitoUser
  );
  const userGroup =
    cognitoUser?.signInUserSession?.accessToken?.payload["cognito:groups"][0];

  return (
    <>
      {userGroup === "SuperAdmin" ? (
        <AdminLayout props={props} />
      ) : (
        <UserLayout props={props} />
      )}
    </>
  );
}
