import {
  Divider,
  Row,
  Typography,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Col,
  message,
  Select,
  Menu,
  Dropdown,
} from "antd";
import React, { useEffect, useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import exportCSV from "assets/icons/exportcsv.svg";

import {
  CreateProcedure,
  DeleteProcedure,
  GetPublicProcedure,
  ListProcedure,
} from "utility/action";
import { useCallback } from "react";
import { disableSubmitButton, formatCSVData } from "utility/constant";
import search from "utility/search";
import { handleStatusChange } from "utility/StatusChange";
import SuccessModel from "utility/SuccessModel";
import DeleteModal from "utility/DeleteModal";
import { DeleteService } from "utility/DeleteService";
import TableSkeleton from "utility/TableSkeleton";

export default function Procedures() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [procedures, setProcedure] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [dataSource, setDataSource] = useState(procedures);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [selectedValue, setSelectedValue] = useState(null);
  const [form] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { Option } = Select;

  const handleFormChange = disableSubmitButton(["procedure"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    form.resetFields();
    setSubmitDisabled(true);
    setDeleteModelPrompt(false);
  };

  const labelKeyMap = {
    "Top procedure": "name",
    "Market Price": "savings",
    "Delphi Price": "bazarPrice",
    Reward: "marketPrice",
    Savings: "reward",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const setProcedureRow = function (row) {
    return {
      key: row?.id,
      Action: row.id,
      createdAt: row?.createdAt,
      searchByName: row?.searchByName,
      Topprocedure: row?.name,
      BazarPrice: row?.bazarPrice
        ? `$${parseInt(row?.bazarPrice).toLocaleString()}`
        : "-",
      MarketPrice: row?.marketPrice
        ? `$${parseInt(row?.marketPrice).toLocaleString()}`
        : "-",
      Reward: row?.reward ? `$${parseInt(row?.reward).toLocaleString()}` : "-",
      AverageSavings: row?.savings
        ? `$${parseInt(row?.savings).toLocaleString()}`
        : "-",
    };
  };

  const columns = [
    {
      title: "Procedure",
      dataIndex: "Topprocedure",
    },
    {
      title: "Delphi Price",
      dataIndex: "BazarPrice",
    },
    {
      title: "Market Price",
      dataIndex: "MarketPrice",
    },
    {
      title: "Average Savings",
      dataIndex: "AverageSavings",
    },

    {
      title: "Reward",
      dataIndex: "Reward",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/procedure-detail" state={{ id: id }}>
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key={2}>
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId(id);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchProcedure = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListProcedure(999, undefined);
      const data = response.map((row) => setProcedureRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setProcedure(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchProcedure();
  }, [fetchProcedure]);

  const createProcedure = async (values) => {
    try {
      setCreateLoading(true);
      setDeleteId("");
      const procedureExists = await GetPublicProcedure(
        values?.procedure.toLowerCase().trim()
      );
      if (procedureExists.length === 0) {
        // console.log((values.reward).split(" ")[0], 9999);
        const inputData = {
          name: (values?.procedure).trim(),
          searchByName: values?.procedure.toLowerCase().trim(),
          bazarPrice: values?.bazarPrice
            ? (values?.bazarPrice).trim().split(" ")[0]
            : values?.bazarPrice,
          marketPrice: values?.marketPrice
            ? (values?.marketPrice).trim().split(" ")[0]
            : values?.marketPrice,
          reward: values?.reward
            ? (values?.reward).trim().split(" ")[0]
            : values?.reward,
          savings: values?.savings
            ? (values?.savings).trim().split(" ")[0]
            : values?.savings,
          byName: "procedures",
          activeOnPortal: false,
        };
        const response = await CreateProcedure(inputData);
        const mappedData = {};
        Object.entries(response).forEach(([key, value]) => {
          if (value !== null) {
            mappedData[key] = value;
          }
        });

        const tableData = {
          key: mappedData?.id,
          Action: mappedData.id,
          searchByName: mappedData?.searchByName,
          createdAt: mappedData?.createdAt,
          Topprocedure: mappedData?.name,
          BazarPrice: mappedData?.bazarPrice
            ? `$${parseInt(mappedData?.bazarPrice).toLocaleString()}`
            : "-",
          MarketPrice: mappedData?.marketPrice
            ? `$${parseInt(mappedData?.marketPrice).toLocaleString()}`
            : "-",
          Reward: mappedData?.reward
            ? `$${parseInt(mappedData?.reward).toLocaleString()}`
            : "-",
          AverageSavings: mappedData?.savings
            ? `$${parseInt(mappedData?.savings).toLocaleString()}`
            : "-",
        };

        const sortByCreatedAt = (data) =>
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setProcedure((previous) => sortByCreatedAt([...previous, tableData]));
        setDataSource((previous) => sortByCreatedAt([...previous, tableData]));

        setIsModalOpen(false);
        form.resetFields();
        setSuccessModel(true);
        setSubmitDisabled(true);
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      } else {
        throw new Error(
          `Procedure with the name "${values?.procedure}" already exists!`
        );
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: error.message,
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Topprocedure",
      "AverageSavings",
      "MarketPrice",
      "BazarPrice",
      "Reward",
    ];
    const filteredData = search(procedures, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleChange = async (value) => {
    try {
      setSelectedValue(value);
      setLoading(true);
      const filterData = await handleStatusChange(
        value,
        setProcedureRow,
        ListProcedure,
        procedures
      );
      setDataSource(filterData);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSurgicalCenter = async () => {
    try {
      setDeleteLoading(true);
      await DeleteService(deleteId, DeleteProcedure);
      setDeleteModelPrompt(false);
      setSuccessModel(true);
      setSelectedValue(null);
      fetchProcedure();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Procedures
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Select
            allowClear
            placeholder="Status"
            style={{ width: 100 }}
            onChange={handleChange}
            value={selectedValue}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
          </Select>

          <CSVLink
            data={resData}
            headers={headers}
            filename={"Procedures.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Add New
          </Button>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Procedure
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={createProcedure}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Procedure name"
                name="procedure"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Delphi price ($)"
                name="bazarPrice"
                rules={[
                  {
                    pattern: /^[0-9 .]*$/,
                    message: "Only numeric values are allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Market price ($)"
                name="marketPrice"
                rules={[
                  {
                    pattern: /^[0-9 .]*$/,
                    message: "Only numeric values are allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Average savings ($)"
                name="savings"
                rules={[
                  {
                    pattern: /^[0-9 .]*$/,
                    message: "Only numeric values are allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Reward ($)"
                name="reward"
                rules={[
                  {
                    pattern: /^[0-9 .]*$/,
                    message: "Only numeric values are allowed!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24} className="center-btn">
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message={
          deleteId !== ""
            ? "Procedure Deleted Successfully"
            : "Procedure Added Successfully"
        }
        open={successModel}
      />

      <DeleteModal
        content="Are you sure you want to delete the procedure?"
        open={deleteModelPrompt}
        onClick={deleteSurgicalCenter}
        buttonLoading={deleteLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        buttonText="Yes"
      />
    </>
  );
}
