import {
  Row,
  Input,
  Button,
  Table,
  message,
  Card,
  Tabs,
  Col,
  Modal,
  Skeleton,
  Form,
  Select,
  Divider,
  Badge,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { EyeOutlined, SearchOutlined } from "@ant-design/icons";
import "./MyAccount.less";

import Container from "commons/Container";
import {
  GetDoctorAppointment,
  GetICAppointment,
  GetInfusionAppointment,
  GetPTAppointment,
  ListDoctorAppointments,
  ListICAppointments,
  ListInfusionAppointments,
  ListPTAppointments,
} from "utility/action";
import { useSelector } from "react-redux";
import TableSkeleton from "utility/TableSkeleton";
import SkeletonBlock from "utility/SkeletonBlocks";
import { formatNumberUS } from "utility/constant";
import search from "utility/search";

export default function MyAppointments() {
  const { Option } = Select;
  const [messageApi, contextHolder] = message.useMessage();
  const [appointmentData, setAppointmentData] = useState([]);
  const [dataSource, setDataSource] = useState(appointmentData);
  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state?.userReducer?.userData);

  const [doctor, setDoctor] = useState({});
  const [pt, setPT] = useState({});
  const [ic, setIC] = useState({});
  const [infusion, setInfusion] = useState({});
  const [appointmentService, setAppointmentService] = useState(null);
  const [status, setStatus] = useState(null);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const setAppointmentRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Status: row?.status === false ? "Completed" : "Pending",
      Finder: [
        row?.id,
        row?.icData
          ? "Imaging Center"
          : row?.docData
          ? "Doctor"
          : row?.ptData
          ? "Physical Therapy"
          : row?.infusionCenterData
          ? "Infusion Center"
          : "-",
      ],
      Email: row?.userData?.email,
      Name: row?.userData?.name,
      createdAt: row?.createdAt,
      SubmittedDate: new Date(row?.createdAt).toLocaleDateString(),
      AppointmentFor: row?.icData
        ? "Imaging Center"
        : row?.docData
        ? "Doctor"
        : row?.ptData
        ? "Physical Therapy"
        : row?.infusionCenterData
        ? "Infusion Center"
        : "",
      CenterName: row?.icData?.name
        ? row?.icData?.name
        : row?.docData?.name
        ? row?.docData?.name
        : row?.ptData?.name
        ? row?.ptData?.name
        : row?.infusionCenterData?.name
        ? row?.infusionCenterData?.name
        : "-",
    };
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        userID: { contains: userData?.id },
      };
      const doctorData = await ListDoctorAppointments(999, filter);
      const imagingData = await ListICAppointments(999, filter);
      const physicalTherapyData = await ListPTAppointments(999, filter);
      const infusionData = await ListInfusionAppointments(999, filter);

      const docRes = doctorData?.map((item) => ({
        ...item,
      }));
      const icRes = imagingData?.map((item) => ({
        ...item,
      }));
      const ptRes = physicalTherapyData.map((item) => ({
        ...item,
      }));
      const infusionRes = infusionData.map((item) => ({
        ...item,
      }));

      const mergedArray = docRes.concat(icRes, ptRes, infusionRes);

      const newData = mergedArray.map((row) => setAppointmentRow(row));
      newData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setAppointmentData(newData);
      setDataSource(newData);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Appointment For",
      dataIndex: "AppointmentFor",
    },
    {
      title: "Doctor/Center Name",
      dataIndex: "CenterName",
    },
    {
      title: "Appointment Date",
      dataIndex: "SubmittedDate",
    },
    {
      title: "Appointment Status",
      dataIndex: "Status",
    },

    {
      title: "",
      dataIndex: "Finder",
      className: "actions",
      render: (data, index) => {
        return (
          <EyeOutlined
            onClick={() => {
              openAppointmentDetails(data);
            }}
          />
        );
      },
    },
  ];

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const openAppointmentDetails = async (data) => {
    try {
      const [appointmentId, appointmentType] = data;
      setIsModalOpen(true);
      setSkeletonLoader(true);

      let appointmentRes;
      switch (appointmentType) {
        case "Doctor":
          appointmentRes = await GetDoctorAppointment(appointmentId);
          setDoctor({ ...appointmentRes });
          setIC([]);
          setPT([]);
          setInfusion([]);
          break;
        case "Imaging Center":
          appointmentRes = await GetICAppointment(appointmentId);
          setIC({ ...appointmentRes }); // set viewed flag for the imaging center appointment
          setDoctor([]);
          setInfusion([]);
          setPT([]);
          break;
        case "Physical Therapy":
          appointmentRes = await GetPTAppointment(appointmentId);
          setPT({ ...appointmentRes });
          setDoctor([]);
          setIC([]);
          setInfusion([]);
          break;
        case "Infusion Center":
          appointmentRes = await GetInfusionAppointment(appointmentId);
          setInfusion({ ...appointmentRes });
          setPT([]);
          setDoctor([]);
          setIC([]);
          break;
        default:
          throw new Error(`Invalid appointment type: ${appointmentType}`);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "AppointmentFor",
      "Name",
      "CenterName",
      "SubmittedDate",
      "Status",
    ];
    const filteredData = search(appointmentData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleChange = (value) => {
    try {
      setLoading(true);
      let filteredData = [];

      switch (value) {
        case "Pending":
        case "Completed":
          setStatus(value);
          setAppointmentService(null);
          filteredData = appointmentData.filter((row) => row.Status === value);
          break;

        case "Doctor":
        case "Imaging_Center":
        case "Physical_Therapy":
        case "Infusion_Center":
          setAppointmentService(value);
          setStatus(null);
          filteredData = appointmentData.filter(
            (row) => row.AppointmentFor === value
          );
          break;

        default:
          // No filter selected, use the original data
          setAppointmentService(null);
          setStatus(null);
          filteredData = appointmentData;
          break;
      }

      filteredData.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setDataSource(filteredData);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Container>
        <Card className="my-2">
          <Tabs className="employer-tabs" defaultActiveKey="1" centered>
            <Tabs.TabPane
              tab={
                <div>
                  My Appointments
                  <Badge
                    size="default"
                    color="#00406A"
                    count={
                      appointmentData?.length ? appointmentData?.length : 0
                    }
                    style={{
                      width: 40,
                      height: 27,
                      marginLeft: 5,
                      borderRadius: 20,
                      marginRight: 0,
                      paddingTop: 3,
                      fontSize: 14,
                    }}
                  />
                </div>
              }
              key="1"
            >
              <Row justify="space-between table-header">
                <Row align="middle">
                  <Input
                    prefix={<SearchOutlined />}
                    placeholder="Search"
                    className="searchbar"
                    onChange={handleSearch}
                    style={{ width: 250 }}
                  />
                </Row>

                <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
                  <Select
                    allowClear
                    placeholder="Appointment For"
                    style={{ width: 150 }}
                    onChange={handleChange}
                    value={appointmentService}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option value="Doctor">Doctor</Option>
                    <Option value="Imaging_Center">Imaging Center</Option>
                    <Option value="Physical_Therapy">Physical Therapy</Option>
                    <Option value="Infusion_Center">Infusion Center</Option>
                  </Select>
                  <Select
                    allowClear
                    placeholder="Status"
                    style={{ width: 90 }}
                    onChange={handleChange}
                    value={status}
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    <Option value="Pending">Pending</Option>
                    <Option value="Completed">Completed</Option>
                  </Select>
                </Row>
              </Row>

              {loading ? (
                <TableSkeleton />
              ) : (
                <Table
                  columns={columns}
                  dataSource={dataSource}
                  className="common-table"
                  scroll={{ x: 992 }}
                  pagination={{ position: ["", "bottomCenter"] }}
                />
              )}
            </Tabs.TabPane>
          </Tabs>
        </Card>

        <Modal
          title="Modal 1000px width"
          centered
          open={isModalOpen}
          onOk={() => setIsModalOpen(false)}
          onCancel={() => setIsModalOpen(false)}
          width={851}
          className="appointment-modal desired-modal"
          footer={null}
        >
          <div className="request-appointment" style={{ padding: 15 }}>
            <div className="">
              <h6>Appointment Details</h6>
            </div>

            <Row gutter={[20]} className="app-card">
              <Col span={12} md={12} xs={24} sm={24}>
                <Card
                  className="doctor-profile bottom-padding"
                  style={{
                    background: "rgba(232, 238, 241, 0.50)",
                    border: "1px solid #8FA9B1",
                    height: "95%",
                  }}
                >
                  <div className="doctor-overview">
                    <Row gutter={[10, 10]} className="overview-details mt-20">
                      {skeletonLoader ? (
                        <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                      ) : (
                        <>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b
                              style={{
                                fontSize: "1.125rem",
                                color: "#00406A",
                                fontWeight: "700",
                              }}
                            >
                              Requested by:
                            </b>
                          </Col>

                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Name:</b>
                            <p>
                              {doctor?.userData?.name ??
                                ic?.userData?.name ??
                                pt?.userData?.name ??
                                infusion?.userData?.name}
                            </p>
                          </Col>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Email Id:</b>
                            <p>
                              {doctor?.userData?.email ??
                                ic?.userData?.email ??
                                pt?.userData?.email ??
                                infusion?.userData?.email ??
                                "-"}
                            </p>
                          </Col>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Phone:</b>
                            <p>
                              {doctor?.userData?.phone
                                ? formatNumberUS(doctor?.userData?.phone)
                                : ic?.userData?.phone
                                ? formatNumberUS(ic?.userData?.phone)
                                : pt?.userData?.phone
                                ? formatNumberUS(pt?.userData?.phone)
                                : infusion?.userData?.phone
                                ? formatNumberUS(infusion?.userData?.phone)
                                : "-"}
                            </p>
                          </Col>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Date of Birth:</b>
                            <p>
                              {doctor?.userData?.dob ??
                                ic?.userData?.dob ??
                                pt?.userData?.dob ??
                                infusion?.userData?.dob ??
                                "-"}
                            </p>
                          </Col>

                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Insurance carrier</b>
                            <p>
                              {doctor?.userData?.insuranceCarrier ??
                                ic?.userData?.insuranceCarrier ??
                                pt?.userData?.insuranceCarrier ??
                                infusion?.userData?.insuranceCarrier ??
                                "-"}
                            </p>
                          </Col>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b>Member ID</b>
                            <p>
                              {doctor?.userData?.memberID ??
                                ic?.userData?.memberID ??
                                pt?.userData?.memberID ??
                                infusion?.userData?.memberID ??
                                "-"}
                            </p>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </Card>
              </Col>

              <Col span={12} md={12} xs={24} sm={24}>
                <Card
                  className="doctor-profile bottom-padding"
                  style={{
                    background: "rgba(232, 238, 241, 0.50)",
                    border: "1px solid #8FA9B1",
                    height: "95%",
                  }}
                >
                  <div className="doctor-overview">
                    <Row gutter={[10, 10]} className="overview-details mt-20">
                      {skeletonLoader ? (
                        <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                      ) : (
                        <>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b
                              style={{
                                fontSize: "1.125rem",
                                color: "#00406A",
                                fontWeight: "700",
                              }}
                            >
                              Requested for:
                            </b>
                          </Col>

                          {/* Doctor Appointment Details */}
                          {doctor &&
                            !Object.keys(ic).length &&
                            !Object.keys(pt).length &&
                            !Object.keys(infusion).length && (
                              <>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b className="overview-details">
                                    Doctor name:
                                  </b>
                                  <p>
                                    {doctor?.docData?.name
                                      ? doctor?.docData?.name
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Doctor email id:</b>
                                  <p>
                                    {doctor?.docData?.email
                                      ? doctor?.docData?.email
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Doctor phone:</b>
                                  <p>
                                    {doctor?.docData?.phone
                                      ? formatNumberUS(doctor?.docData?.phone)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Doctor fax:</b>
                                  <p>
                                    {doctor?.docData?.fax
                                      ? formatNumberUS(doctor?.docData?.fax)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Specialty:</b>
                                  <p>
                                    {doctor?.docData?.speciality
                                      ? doctor?.docData?.speciality
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Preferred time of day:</b>
                                  <p>
                                    {doctor?.userData?.preferredTime
                                      ? toTitleCase(
                                          doctor?.userData?.preferredTime
                                            .toLowerCase()
                                            .replace(/_/g, " ")
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Submitted date:</b>
                                  <p>
                                    {new Date(
                                      doctor?.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </Col>
                              </>
                            )}

                          {/* Imaging Center Appointment Details */}
                          {ic &&
                            !Object.keys(doctor).length &&
                            !Object.keys(pt).length &&
                            !Object.keys(infusion).length && (
                              <>
                                {" "}
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b className="overview-details">
                                    Imaging center name:
                                  </b>
                                  <p>
                                    {ic?.icData?.name ? ic?.icData?.name : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Imaging center email id:</b>
                                  <p>
                                    {ic?.icData?.email
                                      ? ic?.icData?.email
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Imaging center phone:</b>
                                  <p>
                                    {ic?.icData?.phone
                                      ? formatNumberUS(ic?.icData?.phone)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Imaging center fax:</b>
                                  <p>
                                    {ic?.icData?.fax
                                      ? formatNumberUS(ic?.icData?.fax)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Specialty:</b>
                                  <p>
                                    {ic?.icData?.speciality
                                      ? ic?.icData?.speciality
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Preferred time of day:</b>
                                  <p>
                                    {ic?.userData?.preferredTime
                                      ? toTitleCase(
                                          ic?.userData?.preferredTime
                                            .toLowerCase()
                                            .replace(/_/g, " ")
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Submitted date::</b>
                                  <p>
                                    {new Date(
                                      ic?.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </Col>
                              </>
                            )}

                          {/* PT Center Appointment Details */}
                          {pt &&
                            !Object.keys(doctor).length &&
                            !Object.keys(ic).length &&
                            !Object.keys(infusion).length && (
                              <>
                                {" "}
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b className="overview-details">
                                    Physical therapy name:
                                  </b>
                                  <p>
                                    {pt?.ptData?.name ? pt?.ptData?.name : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Physical therapy email id:</b>
                                  <p>
                                    {pt?.ptData?.email
                                      ? pt?.ptData?.email
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Physical therapy phone:</b>
                                  <p>
                                    {pt?.ptData?.phone
                                      ? formatNumberUS(pt?.ptData?.phone)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Physical therapy fax:</b>
                                  <p>
                                    {pt?.ptData?.fax
                                      ? formatNumberUS(pt?.ptData?.fax)
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Specialty:</b>
                                  <p>{"-"}</p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Preferred time of day:</b>
                                  <p>
                                    {pt?.userData?.preferredTime
                                      ? toTitleCase(
                                          pt?.userData?.preferredTime
                                            .toLowerCase()
                                            .replace(/_/g, " ")
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Submitted date:</b>
                                  <p>
                                    {new Date(
                                      pt?.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </Col>
                              </>
                            )}

                          {/* Infusion Center Appointment Details */}

                          {infusion &&
                            !Object.keys(doctor).length &&
                            !Object.keys(ic).length &&
                            !Object.keys(pt).length && (
                              <>
                                {" "}
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b className="overview-details">
                                    Infusion center name:
                                  </b>
                                  <p>
                                    {infusion?.infusionCenterData?.name
                                      ? infusion?.infusionCenterData?.name
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Infusion center email id:</b>
                                  <p>
                                    {infusion?.infusionCenterData?.email
                                      ? infusion?.infusionCenterData?.email
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Infusion center phone:</b>
                                  <p>
                                    {infusion?.infusionCenterData?.phone
                                      ? formatNumberUS(
                                          infusion?.infusionCenterData?.phone
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Infusion center fax:</b>
                                  <p>
                                    {infusion?.infusionCenterData?.fax
                                      ? formatNumberUS(
                                          infusion?.infusionCenterData?.fax
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Preferred time of day:</b>
                                  <p>
                                    {infusion?.userData?.preferredTime
                                      ? toTitleCase(
                                          infusion?.userData?.preferredTime
                                            .toLowerCase()
                                            .replace(/_/g, " ")
                                        )
                                      : "-"}
                                  </p>
                                </Col>
                                <Col span={12} md={24} xs={24} sm={24}>
                                  <b>Submitted date:</b>
                                  <p>
                                    {new Date(
                                      infusion?.createdAt
                                    ).toLocaleDateString()}
                                  </p>
                                </Col>
                              </>
                            )}
                        </>
                      )}
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className="user-appointment">
              <Col md={24}>
                <Form
                  name="doctor"
                  autoComplete="off"
                  id="appForm"
                  layout="vertical"
                >
                  <div className="reward-input-box doctor-overview">
                    {skeletonLoader ? (
                      <Skeleton paragraph={{ rows: 7 }}></Skeleton>
                    ) : (
                      <Row>
                        <Col md={24}>
                          <Row className="app-card-bottom">
                            {doctor?.userData?.appointmentFor === "Other" ||
                            pt?.userData?.appointmentFor === "Other" ||
                            ic?.userData?.appointmentFor === "Other" ||
                            infusion?.userData?.appointmentFor === "Other" ? (
                              <Col md={24}>
                                <Card
                                  className="doctor-profile bottom-padding"
                                  style={{
                                    border: "1px solid #DADADA",
                                  }}
                                >
                                  <div className="doctor-overview">
                                    <Row
                                      gutter={[10, 10]}
                                      className="overview-details mt-20"
                                    >
                                      {skeletonLoader ? (
                                        <Row gutter={[20, 20]} className="mt-3">
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                          <SkeletonBlock
                                            style={{ width: 100, height: 50 }}
                                          />
                                        </Row>
                                      ) : (
                                        <>
                                          <>
                                            <Col md={8} className="col-center">
                                              <div className="col-content">
                                                <b>
                                                  Who is the appointment for?
                                                </b>
                                                <p>
                                                  {doctor?.userData
                                                    ?.appointmentFor ??
                                                    pt?.userData
                                                      ?.appointmentFor ??
                                                    ic?.userData
                                                      ?.appointmentFor ??
                                                    infusion?.userData
                                                      ?.appointmentFor}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={8} className="col-center">
                                              <div className="col-content">
                                                <b>Name:</b>
                                                <p>
                                                  {doctor?.userData
                                                    ?.otherName ??
                                                    pt?.userData?.otherName ??
                                                    ic?.userData?.otherName ??
                                                    infusion?.userData
                                                      ?.otherName}
                                                </p>
                                              </div>
                                            </Col>
                                            <Col md={8} className="col-center">
                                              <div className="col-content">
                                                <b>Date of Birth:</b>
                                                <p>
                                                  {doctor?.userData?.otherDob ??
                                                    pt?.userData?.otherDob ??
                                                    ic?.userData?.otherDob ??
                                                    infusion?.userData
                                                      ?.otherDob}
                                                </p>
                                              </div>
                                            </Col>
                                          </>
                                        </>
                                      )}
                                    </Row>
                                  </div>
                                </Card>
                              </Col>
                            ) : (
                              ""
                            )}

                            {/* Hided Brief description for infusion center because it does not have that field */}
                            <Col md={24} className="mt-20">
                              {!infusion || infusion.length === 0 ? (
                                <>
                                  <b>Brief description of injury/symptoms</b>
                                  <p>
                                    {" "}
                                    {doctor?.description ??
                                      ic?.description ??
                                      pt?.comment ??
                                      infusion?.comment}
                                  </p>{" "}
                                </>
                              ) : (
                                " "
                              )}
                            </Col>

                            <Divider
                              style={{ margin: "10px 0px 10px 0px !important" }}
                            />
                            {/* <Card
                              className="doctor-profile bottom-padding"
                              style={{
                                background: "#F8F8F8",
                                border: "1px solid #DADADA",
                              }}
                            > */}
                            <div className="doctor-overview">
                              <Row
                                gutter={[10, 10]}
                                className="overview-details mt-20"
                              >
                                {skeletonLoader ? (
                                  <Row gutter={[20, 20]} className="mt-3">
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                  </Row>
                                ) : (
                                  <>
                                    <Col span={12} md={24} xs={24} sm={24}>
                                      <b
                                        style={{
                                          fontSize: "1.125rem",
                                          color: "#00406A",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Provided details:
                                      </b>
                                    </Col>

                                    {doctor?.userData?.appointmentFor ===
                                      "Other" ||
                                    pt?.userData?.appointmentFor === "Other" ||
                                    ic?.userData?.appointmentFor === "Other" ||
                                    infusion?.userData?.appointmentFor ===
                                      "Other" ? (
                                      ""
                                    ) : (
                                      <Col
                                        md={
                                          ic?.userData?.appointmentFor ===
                                          "My Self"
                                            ? 12
                                            : pt?.userData?.appointmentFor ===
                                              "My Self"
                                            ? 12
                                            : 24
                                        }
                                      >
                                        <b>Who is the appointment for?</b>
                                        <p>
                                          {doctor?.userData?.appointmentFor ??
                                            pt?.userData?.appointmentFor ??
                                            ic?.userData?.appointmentFor ??
                                            infusion?.userData?.appointmentFor}
                                        </p>
                                      </Col>
                                    )}

                                    {doctor &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(pt).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={24}>
                                            <b>
                                              I want the earliest available
                                              appointment. I am open to a
                                              different provider at the same
                                              location if they have a sooner
                                              opening.
                                            </b>
                                            <p>
                                              {doctor?.openToDifferentProvider ===
                                              true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {ic &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(pt).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={12}>
                                            <b>Body part to be scanned</b>
                                            <p>{ic.bodyPartToBeScanned}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Do you have a referral from your
                                              physician?
                                            </b>
                                            <p>
                                              {ic?.referralOfDoctor === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Have you been to this imaging
                                              center before?
                                            </b>
                                            <p>
                                              {ic?.firstVisit === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Referring doctor</b>
                                            <p>{ic?.doctorName}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Do you have a follow-up
                                              appointment?
                                            </b>
                                            <p>
                                              {ic?.followUpAppointment === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {pt &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={12}>
                                            <b>
                                              How do you prefer to be contacted?
                                            </b>
                                            <p>{pt?.preferredContact}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Do you have a referral?</b>
                                            <p>
                                              {pt?.haveReferral === 1
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={24}>
                                            <b>
                                              I want the earliest available
                                              appointment. I am open to a
                                              different provider at the same
                                              location if they have a sooner
                                              opening.
                                            </b>
                                            <p>
                                              {doctor?.openToDifferentProvider ===
                                              true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {infusion &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(pt).length && (
                                        <>
                                          <Col md={12}>
                                            <b>Referring Provider</b>
                                            <p>{infusion?.referringProvider}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Provider Practice Name</b>
                                            <p>
                                              {infusion?.providerPracticeName}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Provider/Practice Phone Number
                                            </b>
                                            <p>
                                              {formatNumberUS(
                                                infusion?.providerPhoneNumber
                                              )}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Drug Being Infused</b>
                                            <p>{infusion?.drugBeingInfused}</p>
                                          </Col>
                                        </>
                                      )}
                                  </>
                                )}
                              </Row>
                            </div>
                            {/* </Card> */}
                          </Row>
                        </Col>
                      </Row>
                    )}
                  </div>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal>
      </Container>
    </>
  );
}
