import "./DoctorOverview.less";
import externalLink from "assets/icons/external-link.svg";
import { Skeleton, Tag } from "antd";

export default function DoctorOverview(props) {
  const doctorOverview = props?.data;

  return (
    <>
      {props?.loader ? (
        <>
          <Skeleton
            title={false}
            paragraph={{ rows: 10 }}
            className="mt-2"
          ></Skeleton>{" "}
        </>
      ) : (
        <>
          <div className="doctor-overview">
            <b className="sub-header-font">About Me</b>
            <p className="p-font">{doctorOverview?.aboutDoctor}</p>

            <b className="sub-header-font">Specialty</b>
            <p className="p-font">
              {doctorOverview?.specialty ? doctorOverview?.specialty : ""}
              {""}

              <span style={{ color: "#646464", fontWeight: "400" }}>
                {doctorOverview?.areaOfSpeciality
                  ? `- ${doctorOverview?.areaOfSpeciality?.join(", ")}`
                  : "-"}
              </span>
            </p>

            <b className="sub-header-font">Education</b>
            <p className="p-font">{doctorOverview?.doctorEducation}</p>

            <b className="sub-header-font">Address</b>
            <p className="p-font">
              {doctorOverview?.doctorOfficeAdd}{" "}
              {doctorOverview?.doctorOfficeCity},{" "}
              {doctorOverview?.doctorOfficeState} -{" "}
              {doctorOverview?.doctorOfficeZip}
            </p>

            <a
              target="_blank"
              href={doctorOverview?.doctorWebsite}
              className="sub-header-font"
            >
              Go to Provider's Website <img src={externalLink} alt="" />
            </a>
          </div>
        </>
      )}
    </>
  );
}
