import React, { useState } from "react";
import { Row, Col, Typography, Form, Input, Button, Alert } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Auth, API } from "aws-amplify";
import bazarLogo from "assets/delphy/delphy.svg";

import { emailPattern } from "utility/constant";
import moment from "moment";
const CreatePassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userGroupId, setUserGroupID] = useState("");
  const id = location.state?.id;
  const [email, setemail] = useState(
    location?.state?.userAttributes?.email || ""
  );
  const [firstName, setFirstName] = useState(
    location?.state?.userAttributes?.given_name || ""
  );
  const [lastName, setLastName] = useState(
    location?.state?.userAttributes?.family_name || ""
  );
  const params = new URLSearchParams(location.search);
  const dpcDoctor = params.get("doctorID");

  const content = (
    <div>
      <p>
        <b>For Company</b>
      </p>
      <p>Enter company email id.</p>
    </div>
  );
  //Signup code

  const handleUpdateProfile = async (value) => {
    setLoading(true);
    let data = {
      userId: id,
      first_name: value?.firstName,
      last_name: value?.lastName,
      email: email,
    };
    let init = {
      body: {
        ...data,
      },
    };

    await API.post("bazarhealthREST", `/createEmployee/update`, init)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const confirmChangePassword = (values) => {
    setLoading(true);
    let oldPassword = location?.state?.oldPassword;
    if (oldPassword) {
      let newPassword = values?.password;
      Auth.signIn(email, oldPassword).then((user) => {
        if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
          Auth.completeNewPassword(user, newPassword, [])
            .then(async (responce) => {
              setUserGroupID(responce?.userName);
              delete values.password;
              await handleUpdateProfile(values);
              navigate(dpcDoctor ? `/login?doctorID=${dpcDoctor}` : `/login`);
            })
            .catch((err) => {
              message.error(err);
            });
        }
        // navigate("/");
      });
    }
  };

  return (
    <Row className="signup-wrapper">
      <Col span={11}>
        <div className="signup-page-image"></div>
      </Col>
      <Col className="form-footer-container" span={13}>
        <Row justify="center" align="middle" className="w-100">
          <Col span={12}>
            <div className="header"></div>
            <div className="form-logo-container">
              <img src={bazarLogo} alt="" className="bazar-logo" />
              <p className="welcome-text">Set New Password!</p>
              <p className="instruction"></p>
              {message && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={message}
                  banner
                />
              )}
              <Form
                requiredMark={false}
                layout="vertical"
                className="signup-form w-100"
                onFinish={(e) => confirmChangePassword(e)}
              >
                <Form.Item
                  label="First Name"
                  name="firstName"
                  className="firstname-form-item"
                  initialValue={firstName}
                  rules={[
                    {
                      required: true,
                      message: "Please input your First Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input className="lastname" />
                </Form.Item>

                <Form.Item
                  label="Last Name"
                  name="lastName"
                  className="lastname-form-item"
                  initialValue={lastName}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Last Name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input className="lastname" />
                </Form.Item>

                <Form.Item
                  className="w-100 email-form-item"
                  initialValue={email}
                  label={
                    <Row justify="space-between w-100">
                      <span>Email</span>
                    </Row>
                  }
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input className="lastname" disabled />
                </Form.Item>
                <Form.Item
                  label="Password"
                  className="password-form-item"
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: "Password must be minimum 8 characters.",
                    },
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password className="lastname" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password className="lastname" />
                </Form.Item>
                <Button
                  className="signup-button"
                  htmlType="submit"
                  loading={loading}
                >
                  Set New Password
                </Button>
                {/* <div className="register-text">
                  Already have an account ?{" "}
                  <Link to="/login" className="register-link">
                    {" "}
                    Login Here
                  </Link>
                </div> */}
              </Form>
            </div>
          </Col>
          <Col span={24} className="hide-version-no">
            <Row justify="center" className="footer">
              <Typography.Text className="footer-text">
                Version {process.env.REACT_APP_VERSION} © {moment().year()}{" "}
                <a
                  href="https://delphihc.com/"
                  target="_blank"
                  style={{ color: "inherit" }}
                >
                  delphihc.com
                </a>{" "}
                copyright all rights reserved.
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreatePassword;
