import { Badge, Card, Tabs } from "antd";
import Container from "commons/Container";
import React, { useEffect } from "react";
import AdminApproval from "./AdminApproval";
import "./Admin.less";
import { API } from "aws-amplify";
import { getCompanyRegi } from "graphql/queries";
import { useState } from "react";
import { useSelector } from "react-redux";
import { ListEmployeesRegis } from "utility/action";

export default function ApprovalTab(props) {
  const companyId = useSelector(
    (state) => state.userReducer?.userData?.companyRegiEmployeeId
  );
  const userData = useSelector((state) => state.userReducer?.userData);

  const [invitationCount, setInvitationCount] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [editCount, setEditCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const responce = await API.graphql({
        query: getCompanyRegi,
        variables: {
          id: companyId,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const totalEmp = responce.data.getCompanyRegi.employee.items.filter(
        (row) => row.verified == true
      );

      setTotalCount(totalEmp.length);
      const requestedEmployee =
        responce.data.getCompanyRegi.employee.items.filter(
          (row) => row.verified == false
        );
      const totalMember = responce.data.getCompanyRegi.member.items.filter(
        (row) => row.verified == false
      );
      const mergedArray = [...requestedEmployee, ...totalMember];

      setInvitationCount(mergedArray);

      const filter = {
        companyRegiEmployeeId: { eq: userData?.id },
      };
      await ListEmployeesRegis(filter)
        .then((response) => {
          const profiles = response
            .filter((item) => item.profile?.editStatus === "REQUESTED")
            .map((item) => item?.profile);
          setEditCount(profiles.length);
        })
        .catch((error) => {
          console.log(error);
        });
    };

    fetchData();
  }, []);

  const totalLength = invitationCount.length;

  return (
    <>
      <Container>
        <Card className="my-2">
          <Tabs className="employer-tabs" defaultActiveKey="1" centered>
            <Tabs.TabPane
              tab={
                <div>
                  All Employees
                  <Badge
                    size="default"
                    color="#00406A"
                    count={totalCount ? totalCount : 0}
                    style={{
                      width: 40,
                      height: 27,
                      marginLeft: 5,
                      borderRadius: 20,
                      marginRight: 0,
                      paddingTop: 3,
                      fontSize: 14,
                    }}
                  />
                </div>
              }
              key="1"
            >
              <AdminApproval />
            </Tabs.TabPane>
            {/* <Tabs.TabPane
              tab={
                <div>
                  Requested Invitations
                  <Badge
                    size="default"
                    color="#00406A"
                    count={totalLength ? totalLength : 0}
                    style={{
                      width: 40,
                      height: 27,
                      marginLeft: 5,
                      borderRadius: 20,
                      marginRight: 0,
                      paddingTop: 3,
                      fontSize: 14,
                    }}
                  />
                </div>
              }
              key="2"
            >
              <Invitation />
            </Tabs.TabPane> */}
            {/* <Tabs.TabPane tab={`Unsubscribe Request`} key="4">
              <UnsubscribeRequest />
            </Tabs.TabPane> */}
          </Tabs>
        </Card>
      </Container>
    </>
  );
}
