import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function InviteDependent(props) {
  const individualInvSteps = [
    {
      target: ".inv-first-step",
      content: (
        <TourStepContent
          heading="Invite Dependent"
          details="Click to invite your Dependent/Spouse."
          numbers={{ currentStep: 1, totalSteps: 5 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },

    {
      target: ".inv-second-step",
      content: (
        <TourStepContent
          heading="Invite Dependent"
          details="Enter your dependent email address and send invitation."
          numbers={{ currentStep: 2, totalSteps: 4 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".inv-third-step",
      content: (
        <TourStepContent
          heading="Send Invitation"
          details="Click on Sent Invite button to invite your dependent
              "
          numbers={{ currentStep: 3, totalSteps: 4 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".inv-four-step",
      content: (
        <TourStepContent
          heading="Invitation Sent"
          details="Great! Your invitation has been sent successfully."
          numbers={{ currentStep: 4, totalSteps: 4 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={individualInvSteps}
        run={props?.inviteTour}
        stepIndex={props?.inviteTourIndex}
        setTourIndex={props?.setInviteTourIndex}
      />
    </>
  );
}
