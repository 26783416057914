import MainLayoutRoute from "./MainLayoutRoute/MainLayoutRoute";
import PublicRoute from "./PublicRoute/PublicRoute";
import DoctorList from "pages/user/Doctors/DoctorList.js";
import DoctorProfile from "pages/user/Doctors/DoctorProfile/DoctorProfile.js";
import CenterDoctorList from "pages/user/SurgicalCenters/CenterDoctorList/CenterDoctorList.js";
import SurgicalCenterDetail from "pages/user/SurgicalCenters/SurgicalCenterDetail/SurgicalCenterDetail.js";
import SurgicalCenters from "pages/user/SurgicalCenters/SurgicalCenters.js";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/user/Home/Home.js";
import Login from "pages/user/Authentication/Login/Login";
import CreateAccount from "pages/user/Authentication/CreateAccount/CreateAccount";
import ConfirmEmail from "pages/user/Authentication/ConfirmEmail/ConfirmEmail";
import ResetPassword from "pages/user/Authentication/ResetPassword/ResetPassword";
import Index from "pages/user/Authentication/ResetPassword";
import UserProfile from "pages/user/UserProfile/UserProfile";
import PhysicalTherapyList from "pages/user/PhysicalTherapy/PhysicalTherapyList";
import PhysicalTherapyProfile from "pages/user/PhysicalTherapy/PhysicalTherapyProfile/PhysicalTherapyProfile";
import ImagingCenterList from "pages/user/ImagingCenter/ImagingCenterList";
import ImagineCenterProfile from "pages/user/ImagingCenter/ImagineCenterProfile/ImagineCenterProfile";
import LabList from "pages/user/Lab/LabList";
import Employees from "pages/admin/Employee/Employees";
import EmployeeDetail from "pages/admin/Employee/EmployeeDetail";
import Employers from "pages/admin/Employers/Employers";
import EmployerDetail from "pages/admin/Employers/EmployerDetail";
import AllEmployees from "pages/admin/Employers/AllEmployees";
import EmployerChart from "pages/admin/Employers/EmployerChart";
import EOBStatus from "pages/admin/EOBStatus/EOBStatus";
import OpenEobDetail from "pages/admin/EOBStatus/OpenEobDetail";
import ClosedEobDetail from "pages/admin/EOBStatus/ClosedEobDetail";
import PTCenter from "pages/admin/PtCenter/PTCenter";
import PtCenterDetail from "pages/admin/PtCenter/PtCenterDetail";
import Procedures from "pages/admin/Procedures/Procedures";
import ProcedureDetail from "pages/admin/Procedures/ProcedureDetail";
import SurgeryCenter from "pages/admin/SurgeryCenter/SurgeryCenter";
import SurgeryCenterDetail from "pages/admin/SurgeryCenter/SurgeryCenterDetail";
import Labs from "pages/admin/Labs/Labs";
import LabsDetail from "pages/admin/Labs/LabsDetail";
import MedGroups from "pages/admin/MedGroups/MedGroups";
import MedGroupDetail from "pages/admin/MedGroups/MedGroupDetail";
import ImagingCenter from "pages/admin/ImagingCenter/ImagingCenter";
import ImagingCenterDetail from "pages/admin/ImagingCenter/ImagingCenterDetail";
import PysicianDetail from "pages/admin/Doctor/DoctorDetail";

import LabProfile from "pages/user/Lab/LabProfile/LabProfile";
import LabOverView from "pages/user/Lab/LabOverview/LabOverView";
import Onboarding from "pages/user/Authentication/Onboarding/Onboarding";
import Approval from "pages/user/Authentication/Onboarding/Approval";
import ApprovalTab from "pages/Approval/ApprovalTab";
import RewardSubmission from "pages/user/Home/Rewards/RewardSubmission";
import PaymentSuccess from "pages/user/IndividualBilling/PaymentSuccess";
import PaymentFailed from "pages/user/IndividualBilling/PaymentFailed";
import PrivateRoute from "./PrivateRoute/PrivateRoute";
import HrHomePage from "pages/user/Hr/HrHomePage";
import CreatePassword from "pages/user/Authentication/CreatePassword/CreatePassword";
import HomeProcedureDetail from "pages/user/Home/Procedures/HomeProcedureDetail";
import AppointmentTab from "pages/admin/Appointment/AppointmentTab";
import Physician from "pages/admin/Doctor/Doctors";
import CloseEobDetail from "pages/admin/EOBStatus/ClosedEobDetail";
import AppointmentSuccess from "pages/admin/Appointment/AppointmentSuccess";
import InfusionCenter from "pages/admin/InfusionCenter/InfusionCenter";
import InfusionCenterDetails from "pages/admin/InfusionCenter/InfusionCenterDetails";
import InfusionCenterList from "pages/user/InfusionCenter/InfusionCenterList";
import InfusionCenterProfile from "pages/user/InfusionCenter/InfusionCenterProfile";
import DoctorTour from "pages/user/Doctors/DoctorTour";
import Faq from "pages/user/Home/Faq/Faq";
import BillingPlan from "pages/user/FamilyBilling/BillingPlan";
import ManageBilling from "pages/user/FamilyBilling/ManageBilling";
import StripeEmployerPlans from "pages/user/StripeEmployers/StripeEmployerPlans";
import Partners from "pages/admin/Partners/Partners";
import PartnerDetail from "pages/admin/Partners/PartnerDetail";
import ReferredList from "pages/admin/Partners/ReferredList";
import AdminNotification from "pages/admin/Notification/AdminNotification";
import PublicEmployerPlan from "pages/user/StripeEmployers/PublicEmployerPlan";
import MyAppointments from "pages/user/UserProfile/MyAppointments";
import DirectPrimaryCareBilling from "pages/user/DirectPrimaryCare/DirectPrimaryCareBilling";
import DpcList from "pages/admin/Dpc/DpcList";
import DpcHome from "pages/user/DirectPrimaryCare/DpcHome";
import Chat from "pages/admin/Chat/Index";
import { EpcSelfServingOnBoarding } from "pages/public/EpcSelfServingOnBoarding";
import { StripePaymentSuccess } from "pages/public/paymentSuccess";
import PrivateRouteMFA from "./PrivateRoute/PrivateMFARoute";
import SetupMFA from "pages/user/Authentication/SetupMFA/SetupMFA";
import ReferralList from "pages/admin/Dpc/ReferralList";

export default function Routers() {
  return (
    <>
      <BrowserRouter>
        <PublicRoute path={"/login"}>
          <Login />
        </PublicRoute>
        <PublicRoute path={"/signup"}>
          <CreateAccount />
        </PublicRoute>
        <PublicRoute path={"/signup/?id=:id"}>
          <CreateAccount />
        </PublicRoute>
        <PublicRoute path={"/appointment/emailRecievedCheck"}>
          <AppointmentSuccess />
        </PublicRoute>
        <PublicRoute path={"/create-password"}>
          <CreatePassword />
        </PublicRoute>

        <PublicRoute
          exact
          path={"/confirm-signup?confirmationcode=:code"}
          render={(matchprops) => <ConfirmEmail {...matchprops} />}
        />

        {/* <PrivateRoutes path="/lab-test">
     <LabList></LabList>
        </PrivateRoutes> */}
        <PublicRoute path={"/confirm-signup"}>
          <ConfirmEmail />
        </PublicRoute>
        <PublicRoute path={"/mfa-authentication"}>
          <SetupMFA />
        </PublicRoute>

        <Routes>
          <Route
            path="/setup-mfa"
            element={
              <PrivateRouteMFA>
                <SetupMFA />
              </PrivateRouteMFA>
            }
          />
        </Routes>
        <PublicRoute path={"/forgot-password"}>
          <ResetPassword />
        </PublicRoute>
        <PublicRoute path={"/reset-password"}>
          <Index />
        </PublicRoute>
        <PublicRoute
          exact
          path={"/reset-password?confirmationcode=:code+email=:email"}
          render={(matchprops) => <Index {...matchprops} />}
        />
        <PublicRoute path={"/enhanced-primary-care/doctor/:id"}>
          <EpcSelfServingOnBoarding />
        </PublicRoute>
        <PublicRoute path={"/stripe/payment-success"}>
          <StripePaymentSuccess />
        </PublicRoute>
        <MainLayoutRoute path={"/user-profile"}>
          <UserProfile />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/my-appointment"}>
          <MyAppointments />
        </MainLayoutRoute>
        <PublicRoute path={"/onboarding"}>
          <Onboarding />
        </PublicRoute>
        <PublicRoute path={"/invite"}>
          <PublicEmployerPlan />
        </PublicRoute>
        <PublicRoute path={"/home-procedure-detail/:name"}>
          <HomeProcedureDetail />
        </PublicRoute>
        <MainLayoutRoute path={"/manage-billing"}>
          <ManageBilling />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/approval"}>
          <Approval />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/payment-success"}>
          <PaymentSuccess />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/payment-failed"}>
          <PaymentFailed />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/admin-approval"}>
          <ApprovalTab />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/billing"}>
          <BillingPlan />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/dpc-billing"}>
          <DirectPrimaryCareBilling />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/dpc-home"}>
          <DpcHome />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/corporate-billing"}>
          <StripeEmployerPlans />
        </MainLayoutRoute>

        <MainLayoutRoute path={"/faq"}>
          <Faq />
        </MainLayoutRoute>

        {/* <MainLayoutRoute path={"/billing-plans"}>
          <BillingPlan />
        </MainLayoutRoute> */}

        {/* Private Routes */}

        <PrivateRoute path={"/doctor-list"}>
          <DoctorList />
        </PrivateRoute>
        <PrivateRoute path={"/doctor-profile"}>
          <DoctorProfile />
        </PrivateRoute>
        <PrivateRoute path={"/surgical-center-list"}>
          <SurgicalCenters />
        </PrivateRoute>
        <PrivateRoute path={"/surgical-center-detail"}>
          <SurgicalCenterDetail />
        </PrivateRoute>
        <PrivateRoute path={"/surgical-center-detail/doctor-list"}>
          <CenterDoctorList />
        </PrivateRoute>
        <PrivateRoute path={"/physical-therapy-list"}>
          <PhysicalTherapyList />
        </PrivateRoute>
        <PrivateRoute path={"/physical-therapy-profile"}>
          <PhysicalTherapyProfile />
        </PrivateRoute>
        <PrivateRoute path={"/imaging-center-list"}>
          <ImagingCenterList />
        </PrivateRoute>
        <PrivateRoute path={"/imaging-center-profile"}>
          <ImagineCenterProfile />
        </PrivateRoute>
        <MainLayoutRoute path={"/infusion-center"}>
          <InfusionCenter />
        </MainLayoutRoute>
        <PrivateRoute path={"/up-infusion-center"}>
          <InfusionCenterList />
        </PrivateRoute>
        <PrivateRoute path={"/up-infusion-center-details"}>
          <InfusionCenterProfile />
        </PrivateRoute>

        <PrivateRoute path={"/lab-center-list"}>
          <LabList />
        </PrivateRoute>
        <PrivateRoute path={"/lab-center-details"}>
          <LabProfile />
        </PrivateRoute>
        <PrivateRoute path={"/lab-center-profile"}>
          <LabOverView />
        </PrivateRoute>
        <PrivateRoute path={"/reward-submission"}>
          <RewardSubmission />
        </PrivateRoute>
        <PrivateRoute path={"/hr-home"}>
          <HrHomePage />
        </PrivateRoute>
        <PrivateRoute path={"/"}>
          <Home />
        </PrivateRoute>
        <PrivateRoute path={"/doctor-tour"}>
          <DoctorTour />
        </PrivateRoute>

        <MainLayoutRoute path={"/members"}>
          <Employees />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/appointments"}>
          <AppointmentTab />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/member-detail"}>
          <EmployeeDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/employers"}>
          <Employers />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/partners"}>
          <Partners />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/partner-detail/:id"}>
          <PartnerDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/referred-list/:id"}>
          <ReferredList />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/notifications"}>
          <AdminNotification />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/dpc"}>
          <DpcList />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/referral-list-self-serving-form"}>
          <ReferralList />
        </MainLayoutRoute>
        <MainLayoutRoute path="/admin-chat/:category/">
          <Chat />
        </MainLayoutRoute>
        <MainLayoutRoute path="/admin-chat/:category/:id">
          <Chat />
        </MainLayoutRoute>
        <MainLayoutRoute path="/admin-chat">
          <Chat />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/employer-detail"}>
          <EmployerDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/all-employees"}>
          <AllEmployees />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/employer-chart"}>
          <EmployerChart />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/eobstatus"}>
          <EOBStatus />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/open-eob-detail"}>
          <OpenEobDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/close-eob-detail"}>
          <CloseEobDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/closed-eob-detail"}>
          <ClosedEobDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/pt-center"}>
          <PTCenter />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/pt-center-detail"}>
          <PtCenterDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/infusion-center-details"}>
          <InfusionCenterDetails />
        </MainLayoutRoute>

        <MainLayoutRoute path={"/procedures"}>
          <Procedures />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/procedure-detail"}>
          <ProcedureDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/surgery-center"}>
          <SurgeryCenter />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/surgery-center-detail"}>
          <SurgeryCenterDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/labs"}>
          <Labs />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/lab-detail"}>
          <LabsDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/medical-groups"}>
          <MedGroups />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/medical-group-detail"}>
          <MedGroupDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/imaging-center"}>
          <ImagingCenter />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/imaging-center-detail"}>
          <ImagingCenterDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/doctors"}>
          <Physician />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/doctor-detail"}>
          <PysicianDetail />
        </MainLayoutRoute>
        <MainLayoutRoute path={"/user-procedure-detail/:name"}>
          <HomeProcedureDetail />
        </MainLayoutRoute>
      </BrowserRouter>
    </>
  );
}
