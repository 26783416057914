import React, { useCallback } from "react";
import "./DoctorOperatesAt.less";
import { StarFilled } from "@ant-design/icons";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import location from "assets/icons/locationS.svg";
import downArrow from "assets/icons/downArrow.svg";
import upArrow from "assets/icons/upArrow.svg";
import externalLink from "assets/icons/external-link.svg";
import { useEffect } from "react";
import { useState } from "react";
import { Button, Col, Collapse, Divider, Empty, message, Skeleton } from "antd";
import { ListSurgeryCenter } from "utility/action";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import { Link } from "react-router-dom";

export default function DoctorOperatesAt(props) {
  const surgeryCenterName = props?.data?.surgeryCenter;
  const [surgeryData, setSurgeryData] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [docOperates, setDocOperates] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { Panel } = Collapse;

  const panelStyle = {
    marginBottom: 24,
    backgroundColor: "#F2F9FD",
    border: "none",
  };

  const fetchSurgeryData = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const updatedResponse = [];

      for (const name of props?.data?.surgeryCenter) {
        const filter = { surgeryCenterName: { eq: name } };
        const response = await ListSurgeryCenter(999, filter);

        const updatedItems = await Promise.all(
          response.map(async (item) => {
            const key = item?.surgeryCenterProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url && item?.surgeryCenterProfilePhoto) {
                item.surgeryCenterProfilePhoto = url;
              }
            }
            return item;
          })
        );
        updatedResponse.push(...updatedItems);
      }

      setSurgeryData(updatedResponse);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  }, [props?.data?.surgeryCenter]);

  useEffect(() => {
    fetchSurgeryData();
  }, [fetchSurgeryData]);

  return (
    <>
      {contextHolder}
      {skeletonLoader ? (
        <>
          {" "}
          <Skeleton avatar paragraph={{ rows: 10 }}></Skeleton>{" "}
        </>
      ) : (
        <>
          {surgeryCenterName === undefined || surgeryData.length === 0 ? (
            <>
              <>
                <div className="no-data">
                  <Col md={24}>
                    <Empty />
                  </Col>
                </div>
              </>
            </>
          ) : (
            <>
              <Collapse
                className="doctor-surgery"
                bordered={false}
                expandIconPosition="end"
                defaultActiveKey={["1"]}
                expandIcon={({ isActive }) => (
                  <img src={isActive ? upArrow : downArrow} />
                )}
                style={{
                  background: "none",
                }}
              >
                {surgeryData.map((surgery, index) => (
                  <Panel
                    header={
                      <div className="d-flex gap-3">
                        <img
                          src={surgery?.surgeryCenterProfilePhoto}
                          alt=""
                          className="surgery-img"
                        />
                        <div className="doctor-detail">
                          <h4 className="doctor-name header-font">
                            {surgery?.surgeryCenterName}
                          </h4>
                          <div className="d-flex ">
                            <p className="no-margin-b p-font">
                              <img src={facebook} alt="facebook Icon" />
                              {surgery?.surgeryCenterFaceBookRating}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </p>
                            <Divider className="divider" type="vertical" />
                            <p className="no-margin-b p-font">
                              <img src={google} alt="facebook Icon" />
                              {surgery?.surgeryCenterGoogleRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </p>
                          </div>
                        </div>
                      </div>
                    }
                    key={index + 1}
                    style={panelStyle}
                  >
                    <div className="doctor-overview doctor-operates doctor-profile">
                      <p className="p-font d-flex">
                        <img
                          src={location}
                          alt="location"
                          className="location"
                        />{" "}
                        {surgery?.surgeryCenterAddLine1},{" "}
                        {surgery?.surgeryCenterAddLine2} -{" "}
                        {surgery?.surgeryCenterZip}
                      </p>
                      <p className="p-font">{surgery?.aboutSurgeryCenter}</p>

                      <div className="space-between">
                        <a
                          target="_blank"
                          className="sub-header-font"
                          href={surgery?.surgeryCenterWebsite}
                          // className="mt-3 d-block"
                        >
                          Go to Provider's Website{" "}
                          <img src={externalLink} alt="" />
                        </a>

                        <a>
                          <Link
                            to="/surgical-center-detail"
                            state={{ id: surgery?.id }}
                          >
                            <Button className="blue-border-btn p-font">
                              Learn More
                            </Button>
                          </Link>
                        </a>
                      </div>
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </>
          )}
        </>
      )}
    </>
  );
}
