import React, { useEffect, useRef, useState } from "react";
import { Button, Row, Col, Carousel, Skeleton } from "antd";
import { LeftOutlined, RightOutlined, StarFilled } from "@ant-design/icons";
import quote from "assets/icons/quote.svg";
import externalLink from "assets/icons/external-link.svg";
import SkeletonBlock from "utility/SkeletonBlocks";
import { formatNumberUS } from "utility/constant";
export default function InfusionCenterOverview(props) {
  const ptData = props?.data;
  const ref = useRef();

  const [testimonial, setTestimonial] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const ttt = props?.data?.testimonial;

  useEffect(() => {
    const testimonialData = JSON.parse(ttt ?? "[]");
    setTestimonial(testimonialData);
  }, [ttt]);

  return (
    <>
      {props?.loader ? (
        <>
          {" "}
          <Skeleton title={false} paragraph={{ rows: 4 }}></Skeleton>{" "}
          <SkeletonBlock style={{ width: 1100, height: 200 }} />
          <Skeleton
            title={false}
            paragraph={{ rows: 4 }}
            className="mt-2"
          ></Skeleton>{" "}
        </>
      ) : (
        <>
          <div className="doctor-overview">
            <b className="sub-header-font">About Center</b>
            <p className="p-font">{ptData?.about ? ptData?.about : "-"}</p>

            <b className="sub-header-font">Infusion Drugs</b>
            <p className="p-font">
              {ptData?.infusionDrugs ? ptData?.infusionDrugs?.join(", ") : "-"}
            </p>
            <Row>
              <Col xs={10}>
                {" "}
                <b className="sub-header-font">Address</b>
                <p className="p-font">
                  {ptData?.streetAddress ? ptData?.streetAddress : "-"}{" "}
                  {ptData?.city ? ptData?.city : "-"} -{" "}
                  {ptData?.state ? ptData?.state : "-"} -{" "}
                  {ptData?.zip ? ptData?.zip : "-"}
                </p>
              </Col>
              <Col>
                <b className="sub-header-font">Phone No</b>
                <p className="p-font">
                  {ptData?.phone ? formatNumberUS(ptData?.phone) : "-"}
                </p>
              </Col>
            </Row>

            {ptData?.googleMapLink ? (
              <iframe
                src={ptData?.googleMapLink}
                height="200"
                width="100%"
                allowFullScreen=""
                loading="lazy"
                className="border-0 mb-2 mt-1"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              <br />
            )}
            <a
              target="_blank"
              href={ptData?.website}
              className="sub-header-font"
            >
              Go to Provider's Website <img src={externalLink} alt="" />
            </a>

            <div className="surgical-testimonial">
              <div className="d-flex justify-content-between">
                <b className="sub-header-font">Testimonials</b>
                <div className="testimonial-nav">
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => {
                      ref.current.prev();
                    }}
                    className="nxt-prv-btn mr-1"
                  />
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => {
                      ref.current.next();
                    }}
                    className="nxt-prv-btn"
                  />
                </div>
              </div>
              <div className="testimonial-card">
                <Carousel
                  autoplay
                  draggable
                  ref={ref}
                  arrows={true}
                  prevArrow={
                    <LeftOutlined className="ant-carousel-btn prev-carousel" />
                  }
                  nextArrow={
                    <RightOutlined className="button ant-carousel-btn next-carousel" />
                  }
                >
                  <div>
                    <Row gutter={20}>
                      {testimonial.map((reviews) => (
                        <>
                          <Col md={8} xs={12}>
                            <div className="testimonial-single">
                              <img src={quote} alt="Quote Icon" />
                              <p className="sub-header-font">
                                {reviews?.review}
                              </p>
                              <b className="p-font">
                                {reviews?.review}{" "}
                                <span>{reviews?.testimonialRating}</span>
                              </b>
                              <StarFilled />
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
