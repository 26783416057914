import {
  Row,
  Input,
  Button,
  Table,
  Form,
  message,
  Col,
  Modal,
  Card,
  Skeleton,
  Space,
  Badge,
  Typography,
  Select,
  Tabs,
  InputNumber,
} from "antd";
import warning from "assets/icons/warning.svg";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import exportCSV from "assets/icons/exportcsv.svg";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { EyeOutlined, SearchOutlined, CheckOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { CSVLink } from "react-csv";
import {
  GetDoctorAppointment,
  GetICAppointment,
  GetPTAppointment,
  GetInfusionAppointment,
  ListDoctorAppointments,
  ListICAppointments,
  ListPTAppointments,
  UpdateDocAppointment,
  UpdateICAppointment,
  UpdatePTAppointment,
  ListInfusionAppointments,
  UpdateInfusionAppointment,
} from "utility/action";
import bazarLogo from "assets/logo.png";
import search from "utility/search";
import SkeletonBlock from "utility/SkeletonBlocks";
import SuccessModel from "utility/SuccessModel";
import { setAppointmentData } from "store/actions/appointment";
import { useDispatch } from "react-redux";
import { API } from "aws-amplify";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { emailPattern, formatNumberUS, tenDigit } from "utility/constant";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  pdf,
  Image,
} from "@react-pdf/renderer";
import axios from "axios";
import TableSkeleton from "utility/TableSkeleton";

export default function OpenAppointments() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [employersData, setEmployersData] = useState([]);
  const [appID, setAppID] = useState("");
  const [appData, setAppData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [approvedModal, setApproveModal] = useState(false);
  const [visible, setVisible] = useState(false);
  const [ccVisible, setCCVisible] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [emailHistory, setEmailHistory] = useState(false);
  const [emailHistoryData, setEmailHistoryData] = useState([]);
  const [faxHistoryData, setFaxHistoryData] = useState([]);
  const [faxEditorData, setFaxEditorData] = useState([]);
  const [emailEditorData, setEmailEditorData] = useState([]);
  const [fxData, setFxData] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dataSource, setDataSource] = useState(employersData);
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailSuccess, setEmailSuccess] = useState(false);
  const [appointmentFor, setAppointmentFor] = useState("");
  const [activeKey, setActiveKey] = useState("1");
  const [forceRender, setForceRender] = useState(false);
  const [isFax, setIsFax] = useState(false);
  const [sendHistoryEmail, setSendHistoryEmail] = useState(false);
  const editorRef = useRef(null);
  const historyRef = useRef(null);
  const [form] = Form.useForm();
  const [doctor, setDoctor] = useState({});
  const [pt, setPT] = useState({});
  const [ic, setIC] = useState({});
  const [infusion, setInfusion] = useState({});
  const dispatch = useDispatch();
  const { Option } = Select;
  const [emailRecieved, setEmailRecived] = useState(null);
  const [status, setStatus] = useState(null);
  // const uniqueID = uuidv4();
  const [uniqueID, setUniqueID] = useState(uuidv4());

  const [descriptionEditorState, setDecriptionEditorState] = useState(
    EditorState.createEmpty()
  );

  const [emailHistoryEditorState, setEmailHistoryEditorState] = useState(
    EditorState.createEmpty()
  );

  const hostWithProtocol =
    window.location.protocol + "//" + window.location.host;

  // const [text, setText] = useState(
  //   `${hostWithProtocol}/signup/?id=${companyID}`
  // );
  let url = `${hostWithProtocol}/appointment/emailRecievedCheck?appointmetnID=${appID}&emailuuID=${uniqueID}&appointmentFor=${appointmentFor}`;

  const handleTabChange = (key) => {
    setActiveKey(key);
    const email = emailHistoryData[key - 1];
    const html = email?.message ? email?.message : "";
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setEmailHistoryEditorState(EditorState.createWithContent(contentState));
  };

  const handleFaxTabChange = (key) => {
    setActiveKey(key);
    const email = faxHistoryData[key - 1];
    const html = email?.message ? email?.message : "";
    const contentBlock = htmlToDraft(html);
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setEmailHistoryEditorState(EditorState.createWithContent(contentState));
  };

  const handleOk = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSuccessModel(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSuccessModel(false);
    setApproveModal(false);
  };

  //Email/Fax Filter
  const handleChange = async (value) => {
    try {
      setStatus(value);
      setEmailRecived(null);
      if (value === "Email_Sent") {
        const notNullObjects = employersData.filter((obj) => obj?.emailDetails);
        setDataSource(notNullObjects);
      } else if (value === "Email_Not_Sent") {
        const nullObjects = employersData.filter(
          (obj) => obj?.emailDetails === null
        );
        setDataSource(nullObjects);
      } else if (value === "Fax_Sent") {
        const notNullObjects = employersData.filter(
          (obj) => obj?.faxDetails !== null
        );
        setDataSource(notNullObjects);
      } else if (value === "Fax_Not_Sent") {
        const nullObjects = employersData.filter(
          (obj) => obj?.faxDetails === null
        );
        setDataSource(nullObjects);
      } else {
        setDataSource(employersData);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  //Email Received/Not Received Filter
  const handleEmailChange = async (value) => {
    try {
      setStatus(null);
      setEmailRecived(value);
      if (value === "Received") {
        //Filtering out when some mailRecieved is true
        const filteredArray = employersData.filter(
          (obj) =>
            obj.emailDetails &&
            obj.emailDetails.some((detail) => detail.mailRecieved === true)
        );
        setDataSource(filteredArray);
      } else if (value === "Not_Received") {
        //Filtering out when every mailRecieved is null
        const filteredArray = employersData.filter(
          (obj) =>
            obj.emailDetails &&
            obj.emailDetails.every((detail) => detail.mailRecieved === null)
        );
        setDataSource(filteredArray);
      } else {
        setDataSource(employersData);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onEditorStateChangeDecription = (descriptionEditorState) => {
    setDecriptionEditorState(descriptionEditorState);
  };

  const onEmailHistoryEditorStateChangeDecription = (emailEditorState) => {
    setEmailHistoryEditorState(emailEditorState);
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.focusEditor();
    }
  }, []);

  useEffect(() => {
    if (historyRef.current) {
      historyRef.current.focusEditor();
    }
  }, []);

  useEffect(() => {
    if (emailModal) {
      setForceRender(true);
    }

    if (emailHistory) {
      setForceRender(true);
    }
    //doctor default values
    const htmlData = `
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>Document</title>
</head>
<body>
<p>Hello,</p>
Below are the appointment details :<p><strong><span style="font-size: 14px; color: #00406A;">Requested By</span></strong></p>
Email Id: ${doctor?.userData?.email || ""}
Date of Birth: ${doctor?.userData?.dob || ""}
Insurance carrier: ${doctor?.userData?.insuranceCarrier || ""}
Member ID: ${doctor?.userData?.memberID || ""}

<strong><span style="font-size: 14px; color: #00406A;">Appointment For</span></strong>
Doctor Name: ${doctor?.docData?.name || ""}
Doctor Email Id: ${doctor?.docData?.email || ""}
Doctor Phone: ${doctor?.docData?.phone || ""}
Doctor Fax: ${doctor?.docData?.fax || ""}
Specialist: ${doctor?.docData?.speciality || ""}
Preferred time of day: ${doctor?.userData?.preferredTime || ""}
Submitted date: ${new Date(doctor?.createdAt).toLocaleDateString() || ""}

<strong><span style="font-size: 14px; color: #00406A;">Brief description of injury/symptoms</span></strong>
${doctor?.description || ""}

<strong><span style="font-size: 14px; color: #00406A;">Provided Details</strong>
Who is the appointment for?
${doctor?.userData?.appointmentFor || ""}${
      doctor?.userData?.appointmentFor === "Other"
        ? "<br><br>Name : " + doctor?.userData?.otherName
        : ""
    }${
      doctor?.userData?.appointmentFor === "Other"
        ? "<br>DOB : " + doctor?.userData?.otherDob
        : ""
    }

I want the earliest available appointment.
I am open to a different provider at the same location if they have a sooner opening.
${doctor?.openToDifferentProvider === true ? "Yes" : "No"}${
      !isFax
        ? `</br></br><a href=${url} style="padding: 200px !important;">Please click here to confirm that you have received the appointment details.</a>`
        : ""
    }

Regards, 
Delphi Team

<em><strong>Note :</strong> This referral is being sent on a users behalf from the Delphi platform, please contact the Delphi team for questions at (603) 537-6037 phone or ear@delphillc.com email.</em>
  </body>
    </html>
  `;

    //ic default values
    const icHTMLData = `
<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8" />
<meta http-equiv="X-UA-Compatible" content="IE=edge" />
<meta name="viewport" content="width=device-width, initial-scale=1.0" />
<title>Document</title>
</head>
<body>
<p>Hello,</p>
Below are the appointment details :<p><strong><span style="font-size: 14px; color: #00406A;">Requested By</span></strong></p>
Email Id: ${ic?.userData?.email || ""}
Date of Birth: ${ic?.userData?.dob || ""}
Insurance carrier: ${ic?.userData?.insuranceCarrier || ""}
Member ID: ${ic?.userData?.memberID || ""}

<strong><span style="font-size: 14px; color: #00406A;">Appointment For</span></strong>
Imaging center name: ${ic?.icData?.name || ""}
Imaging center email id: ${ic?.icData?.email || ""}
Imaging center phone: ${ic?.icData?.phone || ""}
Imaging center fax: ${ic?.icData?.fax || ""}
Specialist: ${ic?.icData?.speciality || ""}
Preferred time of day: ${ic?.userData?.preferredTime || ""}
Submitted date: ${ic?.createdAt || ""}

<strong><span style="font-size: 14px; color: #00406A;">Brief description of injury/symptoms</span></strong>
${ic?.description || ""}

<strong><span style="font-size: 14px; color: #00406A;">Provided Details</strong>
Who is the appointment for: 
${ic?.userData?.appointmentFor || ""}${
      ic?.userData?.appointmentFor === "Other"
        ? "<br><br>Name : " + ic?.userData?.otherName
        : ""
    }${
      ic?.userData?.appointmentFor === "Other"
        ? "<br>DOB : " + ic?.userData?.otherDob
        : ""
    }

Body part to be scanned:
${ic.bodyPartToBeScanned}

Do you have a referral from your physician? :
${ic.referralOfDoctor === true ? "Yes" : "No"}

Have you been to this imaging center before?:
${ic?.firstVisit === true ? "Yes" : "No"}

Referring doctor:
${ic?.doctorName === true ? "Yes" : "No"}

Do you have a follow-up appointment?:
${ic?.followUpAppointment === true ? "Yes" : "No"}${
      !isFax
        ? `</br></br><a href=${url} style="padding: 200px !important;">Please click here to confirm that you have received the appointment details.</a>`
        : ""
    }

Regards, 
Delphi Team

<em>Note : This referral is being sent on a users behalf from the Delphi platform, please contact the Delphi team for questions at (603) 537-6037 phone or ear@delphillc.com email.</em>
  </body>
    </html>
  `;

    //pt default values
    const ptHTMLData = `
   <!DOCTYPE html>
   <html lang="en">
   <head>
   <meta charset="UTF-8" />
   <meta http-equiv="X-UA-Compatible" content="IE=edge" />
   <meta name="viewport" content="width=device-width, initial-scale=1.0" />
   <title>Document</title>
  
   </head>
   
   <body>
<p>Hello,</p>
Below are the appointment details :<p><strong><span style="font-size: 14px; color: #00406A;">Requested By</span></strong></p>
Email Id: ${pt?.userData?.email || ""}
Date of Birth: ${pt?.userData?.dob || ""}
Insurance carrier: ${pt?.userData?.insuranceCarrier || ""}
Member ID: ${pt?.userData?.memberID || ""}
   
<strong><span style="font-size: 14px; color: #00406A;">Appointment For</span></strong>
Physical therapy name: ${pt?.ptData?.name || ""}
Physical therapy email id: ${pt?.ptData?.email || ""}
Physical therapy phone: ${pt?.ptData?.phone || ""}
Physical therapy fax: ${pt?.ptData?.fax || ""}
Preferred time of day: ${pt?.userData?.preferredTime || ""}
Submitted date: ${new Date(pt?.createdAt).toLocaleDateString() || ""}

<strong><span style="font-size: 14px; color: #00406A;">Brief description of injury/symptoms</span></strong>
${pt?.comment || ""}

<strong><span style="font-size: 14px; color: #00406A;">Provided Details</strong>
Who is the appointment for: 
${pt?.userData?.appointmentFor || ""}${
      pt?.userData?.appointmentFor === "Other"
        ? "<br><br>Name : " + pt?.userData?.otherName
        : ""
    }${
      pt?.userData?.appointmentFor === "Other"
        ? "<br>DOB : " + pt?.userData?.otherDob
        : ""
    }

How do you prefer to be contacted?:
${pt?.preferredContact || ""}

Do you have a referral?: 
${pt?.haveReferral === true ? "Yes" : "No"}

I want the earliest available appointment.
I am open to a different provider at the same location if they have a sooner opening.
${pt?.openToDifferentProvider === true ? "Yes" : "No"}${
      !isFax
        ? `</br></br><a href=${url} style="padding: 200px !important;">Please click here to confirm that you have received the appointment details.</a>`
        : ""
    }

Regards, 
Delphi Team

<em>Note : This referral is being sent on a users behalf from the Delphi platform, please contact the Delphi team for questions at (603) 537-6037 phone or ear@delphillc.com email.</em>
     </body>
       </html>
     `;

    //infusion default values
    const infusionHTMLData = `
      <!DOCTYPE html>
        <html lang="en">
        <head>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>Document</title>
       
        </head>
        
        <body>
<p>Hello,</p>
Below are the appointment details :<p><strong><span style="font-size: 14px; color: #00406A;">Requested By</span></strong></p>
Email Id: ${infusion?.userData?.email || ""}
Date of Birth: ${infusion?.userData?.dob || ""}
Insurance carrier: ${infusion?.userData?.insuranceCarrier || ""}
Member ID: ${infusion?.userData?.memberID || ""}
        
<strong><span style="font-size: 14px; color: #00406A;">Appointment For</span></strong>
Infusion center name: ${infusion?.infusionCenterData?.name || ""}
Infusion center email id: ${infusion?.infusionCenterData?.email || ""}
Infusion center phone: ${
      formatNumberUS(infusion?.infusionCenterData?.phone) || ""
    }
Infusion center fax: ${formatNumberUS(infusion?.infusionCenterData?.fax) || ""}
Preferred time of day: ${infusion?.userData?.preferredTime || ""}
Submitted date: ${new Date(infusion?.createdAt).toLocaleDateString() || ""}
     
     
<strong><span style="font-size: 14px; color: #00406A;">Provided Details</strong>
Who is the appointment for: 
${infusion?.userData?.appointmentFor || ""}${
      infusion?.userData?.appointmentFor === "Other"
        ? "<br><br>Name : " + pt?.userData?.otherName
        : ""
    }${
      infusion?.userData?.appointmentFor === "Other"
        ? "<br>DOB : " + infusion?.userData?.otherDob
        : ""
    }

Referring Provider:${infusion.referringProvider}
Provider Practice Name :${infusion.providerPracticeName}
Provider/Practice Phone Number:${formatNumberUS(infusion?.providerPhoneNumber)}
Drug Being Infused:${infusion?.drugBeingInfused}
         
${
  !isFax
    ? `</br></br><a href=${url} style="padding: 200px !important;">Please click here to confirm that you have received the appointment details.</a>`
    : ""
}
     
Regards, 
Delphi Team
     
<em>Note : This referral is being sent on a users behalf from the Delphi platform, please contact the Delphi team for questions at (603) 537-6037 phone or ear@delphillc.com email.</em>
          </body>
            </html>
          `;

    const contentBlock = htmlToDraft(
      appointmentFor === "doc"
        ? htmlData
        : appointmentFor === "ic"
        ? icHTMLData
        : appointmentFor === "pt"
        ? ptHTMLData
        : appointmentFor === "infusion"
        ? infusionHTMLData
        : ""
    );
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    );
    setDecriptionEditorState(EditorState.createWithContent(contentState));
  }, [doctor, pt, ic, infusion, emailModal, emailHistory]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const setAppointmentRow = function (row) {
    const isNew = Date.now() - Date.parse(row?.createdAt) < 24 * 60 * 60 * 1000;
    const isViewed = row?.viewed || false; // default to false if not set
    return {
      key: row?.id,
      Action: row?.id,
      Finder: [
        row?.id,
        row?.icData
          ? "Imaging Center"
          : row?.docData
          ? "Doctor"
          : row?.ptData
          ? "Physical Therapy"
          : row?.infusionCenterData
          ? "Infusion Center"
          : "-",
      ],
      Email: (
        <div style={{ display: "flex" }}>
          {isNew && !isViewed ? (
            <>
              {row?.userData?.email}
              <Badge
                count="New"
                // color="#f5222d"
                style={{
                  marginLeft: 15,
                }}
              />
            </>
          ) : (
            row?.userData?.email
          )}
        </div>
      ),
      Name: row?.userData?.name,
      createdAt: row?.createdAt,
      SubmittedDate: new Date(row?.createdAt).toLocaleDateString(),
      AppointmentFor: row?.icData
        ? "Imaging Center"
        : row?.docData
        ? "Doctor"
        : row?.ptData
        ? "Physical Therapy"
        : row?.infusionCenterData
        ? "Infusion Center"
        : "",
      CenterName: row?.icData?.name
        ? row?.icData?.name
        : row?.docData?.name
        ? row?.docData?.name
        : row?.ptData?.name
        ? row?.ptData?.name
        : row?.infusionCenterData?.name
        ? row?.infusionCenterData?.name
        : "-",
      viewed: isViewed, // include the viewed flag in the output object
      emailDetails: row?.emailDetails,
      faxDetails: row?.faxDetails,
    };
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        status: { eq: true },
      };
      const docApp = await ListDoctorAppointments(999, filter);
      const icApp = await ListICAppointments(999, filter);
      const ptApp = await ListPTAppointments(999, filter);
      const infusionApp = await ListInfusionAppointments(999, filter);

      const docRes = docApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const icRes = icApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const ptRes = ptApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));
      const infusionRes = infusionApp.map((item) => ({
        ...item,
        isNew: isWithinLast24Hours(item.createdAt),
      }));

      const mergedArray = docRes.concat(icRes, ptRes, infusionRes);

      const newData = mergedArray.map((row) => setAppointmentRow(row));
      newData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setEmployersData(newData);
      setDataSource(newData);
      setCsvData(newData);

      const unviewedAppointments = mergedArray.filter(
        (row) => row.viewed === false
      );
      dispatch(setAppointmentData(unviewedAppointments?.length));
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  function isWithinLast24Hours(createdAt) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const now = new Date();
    const createdAtDate = new Date(createdAt);
    const diffInMilliseconds = now.getTime() - createdAtDate.getTime();
    return diffInMilliseconds <= oneDayInMilliseconds;
  }

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Email",
      "Name",
      "SubmittedDate",
      "AppointmentFor",
      "CenterName",
      "billingMode",
      "Active",
    ];
    const filteredData = search(employersData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const headers = [
    { label: "Email", key: "email" },
    { label: "Name", key: "name" },
    { label: "Date Submitted", key: "submittedDate" },
    { label: "Appointment For", key: "appointmentFor" },
    { label: "Doctor/Center Name", key: "centerName" },
  ];

  const data = csvData.map((row) => ({
    name: row?.Name,
    email: row?.Email,
    submittedDate: row?.SubmittedDate,
    appointmentFor: row?.AppointmentFor,
    centerName: row?.CenterName,
  }));

  const openAppointmentDetails = async (data) => {
    try {
      const [appointmentId, appointmentType] = data;
      setAppID(appointmentId);
      setIsModalOpen(true);
      setSkeletonLoader(true);
      setEmailHistoryData([]);
      setFaxHistoryData([]);
      setAppointmentFor("");
      let updatedAppointment;
      let appointmentRes;
      switch (appointmentType) {
        case "Doctor":
          appointmentRes = await GetDoctorAppointment(appointmentId);
          setDoctor({ ...appointmentRes }); // set viewed flag for the doctor appointment
          setIC([]);
          setPT([]);
          setInfusion([]);
          setAppointmentFor("doc");
          break;
        case "Imaging Center":
          appointmentRes = await GetICAppointment(appointmentId);
          setIC({ ...appointmentRes }); // set viewed flag for the imaging center appointment
          setDoctor([]);
          setInfusion([]);
          setPT([]);
          setAppointmentFor("ic");
          break;
        case "Physical Therapy":
          appointmentRes = await GetPTAppointment(appointmentId);
          setPT({ ...appointmentRes });
          setDoctor([]);
          setIC([]);
          setInfusion([]);
          setAppointmentFor("pt");
          break;
        case "Infusion Center":
          appointmentRes = await GetInfusionAppointment(appointmentId);
          setInfusion({ ...appointmentRes });
          setPT([]);
          setDoctor([]);
          setIC([]);
          setAppointmentFor("infusion");
          break;
        default:
          throw new Error(`Invalid appointment type: ${appointmentType}`);
      }

      setEmailHistoryData(appointmentRes?.emailDetails ?? []);
      setFaxHistoryData(appointmentRes?.faxDetails ?? []);
      const lastRecord =
        appointmentRes?.emailDetails?.[
          appointmentRes.emailDetails.length - 1
        ] ?? {};
      const contentBlock = htmlToDraft(lastRecord?.message ?? "");
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEmailEditorData(contentState);

      const faxLastRecord =
        appointmentRes?.faxDetails?.[appointmentRes.faxDetails.length - 1] ??
        {};

      const faxContentBlock = htmlToDraft(faxLastRecord?.message ?? "");
      const faxContentState = ContentState.createFromBlockArray(
        faxContentBlock.contentBlocks
      );
      setFaxEditorData(faxContentState);

      setCCVisible(lastRecord?.cc !== null);

      if (appointmentRes?.viewed === false) {
        updatedAppointment = { ...appointmentRes, viewed: false };
      } else {
        updatedAppointment = { ...appointmentRes, viewed: true };
      }

      await updateAppointmentBadge(
        appointmentId,
        appointmentType,
        updatedAppointment
      );
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },

    {
      title: "Submitted Date",
      dataIndex: "SubmittedDate",
    },
    {
      title: "Appointment For",
      dataIndex: "AppointmentFor",
    },
    {
      title: "Doctor/Center Name",
      dataIndex: "CenterName",
    },

    {
      title: "",
      dataIndex: "Finder",
      className: "actions",
      render: (data, index) => {
        return (
          <EyeOutlined
            onClick={() => {
              openAppointmentDetails(data);
              setFxData(data);
            }}
          />
        );
      },
    },
    {
      title: "",
      dataIndex: "Finder",
      className: "actions",
      render: (data) => {
        return (
          <Link>
            <CheckOutlined
              onClick={() => {
                setAppData(data);
                setApproveModal(true);
              }}
            />
          </Link>
        );
      },
    },
  ];

  const updateAppointment = async () => {
    try {
      setUpdateLoading(true);
      const inputData = {
        id: appID,
        status: false,
      };
      if (
        doctor &&
        !Object.keys(ic).length &&
        !Object.keys(pt).length &&
        !Object.keys(infusion).length
      ) {
        await UpdateDocAppointment(inputData);
      } else if (
        ic &&
        !Object.keys(doctor).length &&
        !Object.keys(pt).length &&
        !Object.keys(infusion).length
      ) {
        await UpdateICAppointment(inputData);
      } else if (
        pt &&
        !Object.keys(doctor).length &&
        !Object.keys(ic).length &&
        !Object.keys(infusion).length
      ) {
        await UpdatePTAppointment(inputData);
      } else if (
        infusion &&
        !Object.keys(doctor).length &&
        !Object.keys(ic).length &&
        !Object.keys(pt).length
      ) {
        await UpdateInfusionAppointment(inputData);
      }
      setApproveModal(false);
      setIsModalOpen(false);
      setSuccessModel(true);
      fetchData();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (err) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(err);
    } finally {
      setUpdateLoading(false);
    }
  };

  const updateAppointmentBadge = async (id, role, updatedAppointment) => {
    try {
      setUpdateLoading(true);
      const inputData = {
        id: id,
        viewed: true,
      };
      let updateFunction;
      switch (role) {
        case "Doctor":
          updateFunction = UpdateDocAppointment;
          break;
        case "Imaging Center":
          updateFunction = UpdateICAppointment;
          break;
        case "Physical Therapy":
          updateFunction = UpdatePTAppointment;
          break;
        case "Infusion Center":
          updateFunction = UpdateInfusionAppointment;
          break;
        default:
          throw new Error("Invalid role");
      }
      if (updatedAppointment?.viewed === false) {
        const res = await updateFunction(inputData);
        const updatedAppointmentRow = setAppointmentRow(res);
        const appointmentIndex = dataSource.findIndex(
          (appointment) => appointment.key === id
        );
        if (appointmentIndex !== -1) {
          const updatedDataSource = [...dataSource];
          updatedDataSource[appointmentIndex] = updatedAppointmentRow;
          updatedDataSource.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const unviewedAppointments = updatedDataSource.filter(
            (row) => !row.viewed
          );
          dispatch(setAppointmentData(unviewedAppointments.length));
          setEmployersData(updatedDataSource);
          setDataSource(updatedDataSource);
        }
      }
    } catch (err) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(err);
    } finally {
      setUpdateLoading(false);
    }
  };

  const updateAppUsingCheck = async () => {
    try {
      setUpdateLoading(true);
      const inputData = {
        id: appData[0],
        status: false,
      };

      if (appData[1] === "Doctor") {
        await UpdateDocAppointment(inputData);
      } else if (appData[1] === "Imaging Center") {
        await UpdateICAppointment(inputData);
      } else if (appData[1] === "Physical Therapy") {
        await UpdatePTAppointment(inputData);
      } else if (appData[1] === "Infusion Center") {
        await UpdateInfusionAppointment(inputData);
      }
      setApproveModal(false);
      setSuccessModel(true);
      fetchData();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setEmailRecived(null);
      setStatus(null);
      setUpdateLoading(false);
    }
  };

  const sendEmail = async (values, index) => {
    try {
      setEmailLoading(true);
      const emailBody = draftToHtml(
        convertToRaw(descriptionEditorState.getCurrentContent())
      );
      let emailHistoryBody = draftToHtml(
        convertToRaw(emailHistoryEditorState.getCurrentContent())
      );

      let myInit;
      if (values?.faxNumber || values[`faxNumber-${0}`]) {
        const faxBody = descriptionEditorState
          .getCurrentContent()
          .getPlainText("\u0001");

        const pdfDoc = (
          <Document>
            <Page style={styles.body}>
              <Image style={styles.image} src={bazarLogo} />
              <Text style={styles.subtitle}>Fax</Text>

              <Text style={styles.text}>
                Date :{" ".repeat(10)}
                {moment().format("DD-MM-YYYY")}
              </Text>

              <Text style={styles.text}>
                Subject : {" ".repeat(8)}Appointment Request
              </Text>
              <Text style={styles.text}></Text>
              <Text style={styles.text}>
                To :{" ".repeat(14)}
                {values[`faxNumber-${0}`]}
              </Text>

              <Text style={styles.text}></Text>

              <Text style={styles.text}>From :{" ".repeat(9)}Delphi</Text>
              <Text style={styles.text}>
                Phone :{" ".repeat(8)}(603) 537-6037
              </Text>
              <Text style={styles.text}>Fax :{" ".repeat(12)}+16034715011</Text>
              <Text style={styles.text}></Text>

              <Text style={styles.text}>Message :</Text>
              <Text style={styles.text}>
                ------------------------------------------------------------------------------------------------------------
              </Text>
              <Text style={styles.text}>{faxBody}</Text>
            </Page>
          </Document>
        );
        const pdfBlob = await pdf(pdfDoc).toBlob();

        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        const headers = {
          "Content-Type": "application/pdf",
        };
        const response = await axios.put(linkToUpload, pdfBlob, { headers });
        const mediaUrl = response?.config?.url.split("?")[0];

        myInit = {
          body: {
            appointmentID: appID,
            appointmentFor: appointmentFor,
            faxContent:
              sendHistoryEmail === true ? emailHistoryBody : emailBody,
            faxTo: values?.faxNumber
              ? values?.faxNumber
              : values[`faxNumber-${0}`],
            mediaUrl: mediaUrl,
          },
        };
        await API.post("bazarhealthREST", `/superAdmin/sendFax`, myInit);
      } else {
        //this condiiton updates the url in the anchor tag
        if (sendHistoryEmail) {
          const tempElement = document.createElement("div");
          tempElement.innerHTML = emailHistoryBody;
          const anchorTag = tempElement.querySelector("a");
          anchorTag.href = url;
          emailHistoryBody = tempElement.innerHTML;
        }

        myInit = {
          body: {
            appointmentID: appID,
            appointmentFor: appointmentFor,
            emailBody: sendHistoryEmail === true ? emailHistoryBody : emailBody,
            emailTo: values?.emails ? values?.emails : values[`emails-${0}`],
            emailuuID: uniqueID,
            emailSubject: values?.subject
              ? values?.subject
              : values[`subject-${0}`],
            emailCc: values?.ccemail
              ? values?.ccemail
              : values[`ccemail-${0}`]?.length === 0
              ? undefined
              : values[`ccemail-${0}`],
          },
        };
        await API.post(
          "bazarhealthREST",
          `/superAdmin/sendAppointment`,
          myInit
        );
      }

      form.resetFields();
      setEmailModal(false);
      setIsModalOpen(false);
      setEmailHistory(false);
      setEmailSuccess(true);
      setCCVisible(false);
      fetchData();
      setTimeout(() => {
        setEmailSuccess(false);
      }, 3000);
    } catch (error) {
      console.log("error", error);
      messageApi.open({
        type: "error",
        content: "Something Went Wrong.",
      });
    } finally {
      setEmailRecived(null);
      setStatus(null);
      setEmailLoading(false);
    }
  };

  const handleClick = () => {
    setVisible(!visible);
  };

  const handleCCClick = () => {
    setCCVisible(!ccVisible);
  };

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 32,
      textAlign: "left",
      marginBottom: 10,
      // fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 36,
      // margin: 12,
      // fontFamily: "Oswald",
    },
    marginL: {
      marginLeft: 100,
    },

    text: {
      margin: 5,
      fontSize: 14,
      textAlign: "justify",
      // fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 220,
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <>
      {contextHolder}

      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Select
            allowClear
            placeholder="Email Status"
            style={{ width: 140 }}
            onChange={handleEmailChange}
            value={emailRecieved}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="Received">Received</Option>
            <Option value="Not_Received">Not Received</Option>
          </Select>
          <Select
            allowClear
            placeholder="Email/Fax"
            style={{ width: 120 }}
            onChange={handleChange}
            value={status}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="Email_Sent">Email Sent</Option>
            <Option value="Email_Not_Sent">Email Not Sent</Option>
            <Option value="Fax_Sent">Fax Sent</Option>
            <Option value="Fax_Not_Sent">Fax Not Sent</Option>
          </Select>
          <CSVLink
            data={data}
            headers={headers}
            filename={"Open Appointment.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      {/* Appointment Details Popup  */}
      <Modal
        title="Modal 1000px width"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={851}
        className="appointment-modal"
        footer={null}
      >
        <div className="request-appointment" style={{ padding: 15 }}>
          <div className="">
            <h6>Appointment Details</h6>
          </div>

          <Row gutter={[20]} className="app-card">
            <Col span={12} md={12} xs={24} sm={24}>
              <Card
                className="doctor-profile bottom-padding"
                style={{
                  background: "rgba(232, 238, 241, 0.50)",
                  border: "1px solid #8FA9B1",
                  height: "95%",
                }}
              >
                <div className="doctor-overview">
                  <Row gutter={[10, 10]} className="overview-details mt-20">
                    {skeletonLoader ? (
                      <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                    ) : (
                      <>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b
                            style={{
                              fontSize: "1.125rem",
                              color: "#00406A",
                              fontWeight: "700",
                            }}
                          >
                            Requested by:
                          </b>
                        </Col>

                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Name:</b>
                          <p>
                            {doctor?.userData?.name ??
                              ic?.userData?.name ??
                              pt?.userData?.name ??
                              infusion?.userData?.name}
                          </p>
                        </Col>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Email Id:</b>
                          <p>
                            {doctor?.userData?.email ??
                              ic?.userData?.email ??
                              pt?.userData?.email ??
                              infusion?.userData?.email ??
                              "-"}
                          </p>
                        </Col>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Phone:</b>
                          <p>
                            {doctor?.userData?.phone
                              ? formatNumberUS(doctor?.userData?.phone)
                              : ic?.userData?.phone
                              ? formatNumberUS(ic?.userData?.phone)
                              : pt?.userData?.phone
                              ? formatNumberUS(pt?.userData?.phone)
                              : infusion?.userData?.phone
                              ? formatNumberUS(infusion?.userData?.phone)
                              : "-"}
                          </p>
                        </Col>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Date of Birth:</b>
                          <p>
                            {doctor?.userData?.dob ??
                              ic?.userData?.dob ??
                              pt?.userData?.dob ??
                              infusion?.userData?.dob ??
                              "-"}
                          </p>
                        </Col>

                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Insurance carrier</b>
                          <p>
                            {doctor?.userData?.insuranceCarrier ??
                              ic?.userData?.insuranceCarrier ??
                              pt?.userData?.insuranceCarrier ??
                              infusion?.userData?.insuranceCarrier ??
                              "-"}
                          </p>
                        </Col>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b>Member ID</b>
                          <p>
                            {doctor?.userData?.memberID ??
                              ic?.userData?.memberID ??
                              pt?.userData?.memberID ??
                              infusion?.userData?.memberID ??
                              "-"}
                          </p>
                        </Col>
                      </>
                    )}
                  </Row>
                </div>
              </Card>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Card
                className="doctor-profile bottom-padding"
                style={{
                  background: "rgba(232, 238, 241, 0.50)",
                  border: "1px solid #8FA9B1",
                  height: "95%",
                }}
              >
                <div className="doctor-overview">
                  <Row gutter={[10, 10]} className="overview-details mt-20">
                    {skeletonLoader ? (
                      <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                    ) : (
                      <>
                        <Col span={12} md={24} xs={24} sm={24}>
                          <b
                            style={{
                              fontSize: "1.125rem",
                              color: "#00406A",
                              fontWeight: "700",
                            }}
                          >
                            Requested for:
                          </b>
                        </Col>

                        {/* Doctor Appointment Details */}
                        {doctor &&
                          !Object.keys(ic).length &&
                          !Object.keys(pt).length &&
                          !Object.keys(infusion).length && (
                            <>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b className="overview-details">Doctor name:</b>
                                <p>
                                  {doctor?.docData?.name
                                    ? doctor?.docData?.name
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Doctor email id:</b>
                                <p>
                                  {doctor?.docData?.email
                                    ? doctor?.docData?.email
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Doctor phone:</b>
                                <p>
                                  {doctor?.docData?.phone
                                    ? formatNumberUS(doctor?.docData?.phone)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Doctor fax:</b>
                                <p>
                                  {doctor?.docData?.fax
                                    ? formatNumberUS(doctor?.docData?.fax)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Specialty:</b>
                                <p>
                                  {doctor?.docData?.speciality
                                    ? doctor?.docData?.speciality
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Preferred time of day:</b>
                                <p>
                                  {doctor?.userData?.preferredTime
                                    ? toTitleCase(
                                        doctor?.userData?.preferredTime
                                          .toLowerCase()
                                          .replace(/_/g, " ")
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Submitted date:</b>
                                <p>
                                  {new Date(
                                    doctor?.createdAt
                                  ).toLocaleDateString()}
                                </p>
                              </Col>
                            </>
                          )}

                        {/* Imaging Center Appointment Details */}
                        {ic &&
                          !Object.keys(doctor).length &&
                          !Object.keys(pt).length &&
                          !Object.keys(infusion).length && (
                            <>
                              {" "}
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b className="overview-details">
                                  Imaging center name:
                                </b>
                                <p>
                                  {ic?.icData?.name ? ic?.icData?.name : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Imaging center email id:</b>
                                <p>
                                  {ic?.icData?.email ? ic?.icData?.email : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Imaging center phone:</b>
                                <p>
                                  {ic?.icData?.phone
                                    ? formatNumberUS(ic?.icData?.phone)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Imaging center fax:</b>
                                <p>
                                  {ic?.icData?.fax
                                    ? formatNumberUS(ic?.icData?.fax)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Specialty:</b>
                                <p>
                                  {ic?.icData?.speciality
                                    ? ic?.icData?.speciality
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Preferred time of day:</b>
                                <p>
                                  {ic?.userData?.preferredTime
                                    ? toTitleCase(
                                        ic?.userData?.preferredTime
                                          .toLowerCase()
                                          .replace(/_/g, " ")
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Submitted date::</b>
                                <p>
                                  {new Date(ic?.createdAt).toLocaleDateString()}
                                </p>
                              </Col>
                            </>
                          )}

                        {/* PT Center Appointment Details */}
                        {pt &&
                          !Object.keys(doctor).length &&
                          !Object.keys(ic).length &&
                          !Object.keys(infusion).length && (
                            <>
                              {" "}
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b className="overview-details">
                                  Physical therapy name:
                                </b>
                                <p>
                                  {pt?.ptData?.name ? pt?.ptData?.name : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Physical therapy email id:</b>
                                <p>
                                  {pt?.ptData?.email ? pt?.ptData?.email : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Physical therapy phone:</b>
                                <p>
                                  {pt?.ptData?.phone
                                    ? formatNumberUS(pt?.ptData?.phone)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Physical therapy fax:</b>
                                <p>
                                  {pt?.ptData?.fax
                                    ? formatNumberUS(pt?.ptData?.fax)
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Specialty:</b>
                                <p>{"-"}</p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Preferred time of day:</b>
                                <p>
                                  {pt?.userData?.preferredTime
                                    ? toTitleCase(
                                        pt?.userData?.preferredTime
                                          .toLowerCase()
                                          .replace(/_/g, " ")
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Submitted date:</b>
                                <p>
                                  {new Date(pt?.createdAt).toLocaleDateString()}
                                </p>
                              </Col>
                            </>
                          )}

                        {/* Infusion Center Appointment Details */}

                        {infusion &&
                          !Object.keys(doctor).length &&
                          !Object.keys(ic).length &&
                          !Object.keys(pt).length && (
                            <>
                              {" "}
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b className="overview-details">
                                  Infusion center name:
                                </b>
                                <p>
                                  {infusion?.infusionCenterData?.name
                                    ? infusion?.infusionCenterData?.name
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Infusion center email id:</b>
                                <p>
                                  {infusion?.infusionCenterData?.email
                                    ? infusion?.infusionCenterData?.email
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Infusion center phone:</b>
                                <p>
                                  {infusion?.infusionCenterData?.phone
                                    ? formatNumberUS(
                                        infusion?.infusionCenterData?.phone
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Infusion center fax:</b>
                                <p>
                                  {infusion?.infusionCenterData?.fax
                                    ? formatNumberUS(
                                        infusion?.infusionCenterData?.fax
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Preferred time of day:</b>
                                <p>
                                  {infusion?.userData?.preferredTime
                                    ? toTitleCase(
                                        infusion?.userData?.preferredTime
                                          .toLowerCase()
                                          .replace(/_/g, " ")
                                      )
                                    : "-"}
                                </p>
                              </Col>
                              <Col span={12} md={24} xs={24} sm={24}>
                                <b>Submitted date:</b>
                                <p>
                                  {new Date(
                                    infusion?.createdAt
                                  ).toLocaleDateString()}
                                </p>
                              </Col>
                            </>
                          )}
                      </>
                    )}
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col md={24}>
              <Form
                name="doctor"
                autoComplete="off"
                id="appForm"
                layout="vertical"
              >
                <div className="reward-input-box doctor-overview">
                  {skeletonLoader ? (
                    <Skeleton paragraph={{ rows: 7 }}></Skeleton>
                  ) : (
                    <Row>
                      <Col md={24}>
                        <Row className="app-card-bottom">
                          {doctor?.userData?.appointmentFor === "Other" ||
                          pt?.userData?.appointmentFor === "Other" ||
                          ic?.userData?.appointmentFor === "Other" ||
                          infusion?.userData?.appointmentFor === "Other" ? (
                            <Col md={24}>
                              <Card
                                className="doctor-profile bottom-padding"
                                style={{
                                  border: "1px solid #DADADA",
                                }}
                              >
                                <div className="doctor-overview">
                                  <Row
                                    gutter={[10, 10]}
                                    className="overview-details mt-20"
                                  >
                                    {skeletonLoader ? (
                                      <Row gutter={[20, 20]} className="mt-3">
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                        <SkeletonBlock
                                          style={{ width: 100, height: 50 }}
                                        />
                                      </Row>
                                    ) : (
                                      <>
                                        <>
                                          <Col md={8} className="col-center">
                                            <div className="col-content">
                                              <b>Who is the appointment for?</b>
                                              <p>
                                                {doctor?.userData
                                                  ?.appointmentFor ??
                                                  pt?.userData
                                                    ?.appointmentFor ??
                                                  ic?.userData
                                                    ?.appointmentFor ??
                                                  infusion?.userData
                                                    ?.appointmentFor}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col md={8} className="col-center">
                                            <div className="col-content">
                                              <b>Name:</b>
                                              <p>
                                                {doctor?.userData?.otherName ??
                                                  pt?.userData?.otherName ??
                                                  ic?.userData?.otherName ??
                                                  infusion?.userData?.otherName}
                                              </p>
                                            </div>
                                          </Col>
                                          <Col md={8} className="col-center">
                                            <div className="col-content">
                                              <b>Date of Birth:</b>
                                              <p>
                                                {doctor?.userData?.otherDob ??
                                                  pt?.userData?.otherDob ??
                                                  ic?.userData?.otherDob ??
                                                  infusion?.userData?.otherDob}
                                              </p>
                                            </div>
                                          </Col>
                                        </>
                                      </>
                                    )}
                                  </Row>
                                </div>
                              </Card>
                            </Col>
                          ) : (
                            ""
                          )}

                          {/* Hided Brief description for infusion center because it does not have that field */}
                          <Col md={24} className="mt-20">
                            {!infusion || infusion.length === 0 ? (
                              <>
                                <b>Brief description of injury/symptoms</b>
                                <p>
                                  {" "}
                                  {doctor?.description ??
                                    ic?.description ??
                                    pt?.comment ??
                                    infusion?.comment}
                                </p>{" "}
                              </>
                            ) : (
                              " "
                            )}
                          </Col>

                          <Card
                            className="doctor-profile bottom-padding"
                            style={{
                              background: "#F8F8F8",
                              border: "1px solid #DADADA",
                            }}
                          >
                            <div className="doctor-overview">
                              <Row
                                gutter={[10, 10]}
                                className="overview-details mt-20"
                              >
                                {skeletonLoader ? (
                                  <Row gutter={[20, 20]} className="mt-3">
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                    <SkeletonBlock
                                      style={{ width: 100, height: 50 }}
                                    />
                                  </Row>
                                ) : (
                                  <>
                                    <Col span={12} md={24} xs={24} sm={24}>
                                      <b
                                        style={{
                                          fontSize: "1.125rem",
                                          color: "#00406A",
                                          fontWeight: "700",
                                        }}
                                      >
                                        Provided details:
                                      </b>
                                    </Col>

                                    {doctor?.userData?.appointmentFor ===
                                      "Other" ||
                                    pt?.userData?.appointmentFor === "Other" ||
                                    ic?.userData?.appointmentFor === "Other" ||
                                    infusion?.userData?.appointmentFor ===
                                      "Other" ? (
                                      ""
                                    ) : (
                                      <Col
                                        md={
                                          ic?.userData?.appointmentFor ===
                                          "My Self"
                                            ? 12
                                            : pt?.userData?.appointmentFor ===
                                              "My Self"
                                            ? 12
                                            : 24
                                        }
                                      >
                                        <b>Who is the appointment for?</b>
                                        <p>
                                          {doctor?.userData?.appointmentFor ??
                                            pt?.userData?.appointmentFor ??
                                            ic?.userData?.appointmentFor ??
                                            infusion?.userData?.appointmentFor}
                                        </p>
                                      </Col>
                                    )}

                                    {doctor &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(pt).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={24}>
                                            <b>
                                              I want the earliest available
                                              appointment. I am open to a
                                              different provider at the same
                                              location if they have a sooner
                                              opening.
                                            </b>
                                            <p>
                                              {doctor?.openToDifferentProvider ===
                                              true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {ic &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(pt).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={12}>
                                            <b>Body part to be scanned</b>
                                            <p>{ic.bodyPartToBeScanned}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Do you have a referral from your
                                              physician?
                                            </b>
                                            <p>
                                              {ic?.referralOfDoctor === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Have you been to this imaging
                                              center before?
                                            </b>
                                            <p>
                                              {ic?.firstVisit === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Referring doctor</b>
                                            <p>{ic?.doctorName}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Do you have a follow-up
                                              appointment?
                                            </b>
                                            <p>
                                              {ic?.followUpAppointment === true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {pt &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(infusion).length && (
                                        <>
                                          <Col md={12}>
                                            <b>
                                              How do you prefer to be contacted?
                                            </b>
                                            <p>{pt?.preferredContact}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Do you have a referral?</b>
                                            <p>
                                              {pt?.haveReferral === 1
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                          <Col md={24}>
                                            <b>
                                              I want the earliest available
                                              appointment. I am open to a
                                              different provider at the same
                                              location if they have a sooner
                                              opening.
                                            </b>
                                            <p>
                                              {doctor?.openToDifferentProvider ===
                                              true
                                                ? "Yes"
                                                : "No"}
                                            </p>
                                          </Col>
                                        </>
                                      )}

                                    {infusion &&
                                      !Object.keys(doctor).length &&
                                      !Object.keys(ic).length &&
                                      !Object.keys(pt).length && (
                                        <>
                                          <Col md={12}>
                                            <b>Referring Provider</b>
                                            <p>{infusion?.referringProvider}</p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Provider Practice Name</b>
                                            <p>
                                              {infusion?.providerPracticeName}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>
                                              Provider/Practice Phone Number
                                            </b>
                                            <p>
                                              {formatNumberUS(
                                                infusion?.providerPhoneNumber
                                              )}
                                            </p>
                                          </Col>
                                          <Col md={12}>
                                            <b>Drug Being Infused</b>
                                            <p>{infusion?.drugBeingInfused}</p>
                                          </Col>
                                        </>
                                      )}
                                  </>
                                )}
                              </Row>
                            </div>
                          </Card>
                        </Row>

                        <Row className="space-between mt-30">
                          <Row align="middle">
                            <Button
                              className="theme-btn"
                              htmlType="submit"
                              onClick={updateAppointment}
                              loading={updateLoading}
                            >
                              Mark As Completed
                            </Button>
                          </Row>

                          {(() => {
                            let emailButton;
                            switch (true) {
                              case doctor?.emailDetails === null ||
                                ic?.emailDetails === null ||
                                infusion?.emailDetails === null ||
                                pt?.emailDetails === null:
                                emailButton = (
                                  <Button
                                    className="theme-border-btn"
                                    htmlType="submit"
                                    style={{ width: 100 }}
                                    onClick={() => {
                                      setEmailModal(true);
                                      setIsFax(false);
                                    }}
                                  >
                                    Email
                                  </Button>
                                );
                                break;

                              case doctor?.emailDetails?.length >= 1 ||
                                ic?.emailDetails?.length >= 1 ||
                                pt?.emailDetails?.length >= 1 ||
                                infusion?.emailDetails?.length >= 1:
                                emailButton = (
                                  <Button
                                    className="theme-border-btn"
                                    htmlType="submit"
                                    style={{ width: 140 }}
                                    onClick={() => {
                                      setEmailHistory(true);
                                      setIsFax(false);
                                      setSendHistoryEmail(true);
                                      setEmailHistoryEditorState(
                                        EditorState.createEmpty()
                                      );
                                      setEmailHistoryEditorState(
                                        EditorState.createWithContent(
                                          emailEditorData
                                        )
                                      );
                                    }}
                                  >
                                    Email History
                                  </Button>
                                );
                                break;

                              default:
                                break;
                            }

                            let faxButton;
                            switch (true) {
                              case doctor?.faxDetails === null ||
                                ic?.faxDetails === null ||
                                infusion?.faxDetails === null ||
                                pt?.faxDetails === null:
                                faxButton = (
                                  <Button
                                    className="theme-border-btn"
                                    htmlType="submit"
                                    style={{ width: 100 }}
                                    onClick={() => {
                                      setIsFax(true);
                                      setEmailModal(true);
                                    }}
                                  >
                                    Fax
                                  </Button>
                                );
                                break;

                              case doctor?.faxDetails?.length >= 1 ||
                                ic?.faxDetails?.length >= 1 ||
                                infusion?.faxDetails?.length >= 1 ||
                                pt?.faxDetails?.length >= 1:
                                faxButton = (
                                  <Button
                                    className="theme-border-btn"
                                    htmlType="submit"
                                    style={{ width: 135 }}
                                    onClick={() => {
                                      setEmailHistory(true);
                                      setSendHistoryEmail(true);
                                      setIsFax(true);
                                      setEmailHistoryEditorState(
                                        EditorState.createEmpty()
                                      );
                                      setEmailHistoryEditorState(
                                        EditorState.createWithContent(
                                          faxEditorData
                                        )
                                      );
                                    }}
                                  >
                                    Fax History
                                  </Button>
                                );
                                break;

                              default:
                                break;
                            }

                            return (
                              <div>
                                <Space>
                                  {emailButton}
                                  {faxButton}
                                </Space>
                              </div>
                            );
                          })()}
                        </Row>
                      </Col>
                    </Row>
                  )}
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </Modal>

      {/* Close Appointment using badge Modal */}
      <Modal
        title="Basic Modal"
        open={approvedModal}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal "
        footer={null}
      >
        <img src={warning} alt="Delete Img" />
        <h6>Are you sure ?</h6>
        <p>If Yes, it will close the appointment.</p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={() => updateAppUsingCheck()}
            loading={updateLoading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleCancel}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>

      {/* Email Modal */}
      <Modal
        className="form-modal send-modal"
        centered
        width={700}
        footer={null}
        title={
          <Typography.Title level={3} className="mt-10">
            Send Appointment via {isFax ? "Fax" : "Email"}
          </Typography.Title>
        }
        visible={emailModal}
        onOk={() => setEmailModal(false)}
        onCancel={() => setEmailModal(false)}
      >
        <Form
          name="basic"
          onFinish={sendEmail}
          autoComplete="off"
          layout="vertical"
          form={form}
        >
          <Row gutter={20}>
            <Col span={12} md={24} xs={24} sm={24}>
              {isFax ? (
                <Form.Item
                  style={{ position: "relative" }}
                  label="Fax Number"
                  name="faxNumber"
                  rules={[
                    {
                      required: true,
                      message: "Please input Fax number",
                    },

                    {
                      pattern: tenDigit,
                      message: "Please enter a valid fax number",
                    },
                  ]}
                >
                  <InputNumber
                    controls={false}
                    formatter={(value) => formatNumberUS(value)}
                    parser={(value) => {
                      const cleanedValue = value.replace(/\D/g, "");
                      return cleanedValue.slice(0, 10);
                    }}
                    style={{ width: "100%", borderRadius: "5px" }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Form.Item
                    style={{ position: "relative" }}
                    label="To"
                    name="emails"
                    rules={[
                      {
                        required: true,
                        message: "Please input email",
                      },
                      {
                        pattern: emailPattern,
                        message: "Please enter a valid email",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  <span
                    style={{
                      position: "absolute",
                      top: 35,
                      right: 20,
                      fontWeight: 600,
                    }}
                    onClick={handleClick}
                  >
                    Cc
                  </span>
                </>
              )}
            </Col>

            {!isFax && visible && (
              <>
                <Col span={12} md={24} xs={24} sm={24}>
                  <Form.Item
                    style={{ position: "relative" }}
                    label="Cc"
                    name="ccemail"
                    rules={[
                      {
                        validator: (rule, value) => {
                          const emailRegex =
                            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                          const invalidEmails = value.filter(
                            (email) => !emailRegex.test(email)
                          );
                          if (invalidEmails.length > 0) {
                            return Promise.reject(
                              `The following emails are invalid: ${invalidEmails.join(
                                ", "
                              )}`
                            );
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      maxTagCount="responsive"
                      getPopupContainer={(trigger) => trigger.parentNode}
                    />
                  </Form.Item>
                </Col>
              </>
            )}

            {!isFax && (
              <Col span={12} md={24} xs={24} sm={24}>
                <Form.Item
                  label="Subject"
                  name="subject"
                  initialValue={"Appointment Request"}
                  rules={[
                    {
                      required: true,
                      message: "Please input subject",
                    },
                    {
                      pattern: /^(?!\s)/,
                      message: "The first character cannot be a space",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            )}

            <Col span={12} md={24} xs={24} sm={24}>
              <Form.Item label="Message" name="description">
                <div className="editor-border">
                  {forceRender && (
                    <Editor
                      editorState={descriptionEditorState}
                      ref={editorRef}
                      onEditorStateChange={onEditorStateChangeDecription}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      placeholder="Start typing..."
                      toolbar={{
                        options: [
                          "inline",
                          "blockType",
                          "fontSize",
                          "fontFamily",
                          "list",
                          "link",
                        ],
                        inline: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: [
                            "bold",
                            "italic",
                            "underline",
                            "strikethrough",
                          ],
                        },
                        list: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          dropdownClassName: undefined,
                          options: ["unordered", "ordered"],
                        },
                        link: {
                          inDropdown: false,
                          className: undefined,
                          component: undefined,
                          popupClassName: undefined,
                          dropdownClassName: undefined,
                          showOpenOptionOnHover: true,
                          defaultTargetOption: "_self",
                          options: ["link"],
                        },
                      }}
                    />
                  )}
                </div>
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={emailLoading}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* History Modal */}
      <Modal
        className="form-modal"
        centered
        width={921}
        footer={null}
        visible={emailHistory}
        onOk={() => setEmailHistory(false)}
        onCancel={() => setEmailHistory(false)}
      >
        <Form
          name="basic"
          onFinish={(values) => sendEmail(values, activeKey)}
          autoComplete="off"
          layout="vertical"
        >
          <div className="appointment-modal">
            <div className="request-appointment">
              <div>
                <h6>{isFax ? "Fax" : "Email"} History</h6>
                <span className="fs-1"></span>
              </div>
            </div>
          </div>

          <p className="app-title mt-20"> Sent Details</p>
          <Tabs
            defaultActiveKey="1"
            activeKey={activeKey}
            onChange={isFax ? handleFaxTabChange : handleTabChange}
            tabPosition="left"
            className="w-100 app-tabs"
          >
            {(isFax ? faxHistoryData : emailHistoryData)
              .sort((a, b) => moment(b?.sentDateTime) - moment(a?.sentDateTime))
              .map((email, index) => (
                <Tabs.TabPane
                  tab={
                    <div>
                      <div>
                        {moment(email?.sentDateTime).format("D/M/YYYY h:mmA")}
                      </div>
                      {!isFax && email?.mailRecieved === true ? (
                        <div
                          style={{
                            color: "#50DB43",
                            fontSize: "12px",
                            display: "flex",
                          }}
                        >
                          ✅ Request Received
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  }
                  className="app-tabs"
                  key={index + 1}
                >
                  <Row justify="space-between table-header">
                    <Row align="middle">
                      <p className="app-title-last mt-20">
                        Last {isFax ? "Fax" : "Mail"}
                      </p>
                    </Row>
                    {isFax ? (
                      <Row align="middle">
                        <p className="app-tracking mt-20">
                          Status : {email?.faxStatus}
                          {/* Trying out to show fax failed reason ,totally optional purpose */}
                          {email?.faxStatus === "failed" ? (
                            <h6>Reason: {email?.statusReason}</h6>
                          ) : (
                            ""
                          )}
                        </p>
                      </Row>
                    ) : (
                      <Row align="middle">
                        <p className="app-tracking mt-20">
                          First Opened At :{" "}
                          {email?.emailOpenTime === null ? (
                            <i> Not opened!</i>
                          ) : (
                            <>
                              {" "}
                              First Opened At :{" "}
                              {email?.emailOpenTime !== null
                                ? moment(email?.emailOpenTime).format(
                                    "D/M/YYYY h:mmA"
                                  )
                                : "-"}
                            </>
                          )}
                        </p>
                      </Row>
                    )}
                  </Row>

                  <Card
                    className="doctor-profile bottom-padding"
                    style={{
                      background: "rgba(232, 238, 241, 0.50)",
                      border: "1px solid #8FA9B1",
                    }}
                  >
                    <div className="doctor-overview">
                      <Col span={12} md={24} xs={24} sm={24}>
                        <b
                          style={{
                            fontSize: "1.125rem",
                            color: "#00406A",
                            fontWeight: "700",
                          }}
                        >
                          {isFax ? "Fax Number" : "Send to"}
                        </b>
                      </Col>
                      <Col span={12} md={24} xs={24} sm={24}>
                        {isFax ? (
                          <Form.Item
                            initialValue={email?.to}
                            name={`faxNumber-${index}`}
                            rules={[
                              {
                                required: true,
                                message: "Please input fax number",
                              },

                              {
                                pattern: tenDigit,
                                message: "Please enter a valid fax number",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => formatNumberUS(value)}
                              parser={(value) => {
                                const cleanedValue = value.replace(/\D/g, "");
                                return cleanedValue.slice(0, 10);
                              }}
                              style={{ width: "100%", borderRadius: "5px" }}
                              disabled={activeKey === "1" ? false : true}
                            />
                          </Form.Item>
                        ) : (
                          <>
                            <Form.Item
                              name={`emails-${index}`}
                              initialValue={email?.to}
                              rules={[
                                {
                                  required: true,
                                  message: "Please input email",
                                },
                                {
                                  pattern: emailPattern,
                                  message: "Please enter a valid email address",
                                },
                              ]}
                            >
                              <Input
                                disabled={activeKey === "1" ? false : true}
                              />
                            </Form.Item>
                            <span
                              style={{
                                position: "absolute",
                                top: 5,
                                right: 20,
                                fontWeight: 600,
                              }}
                              onClick={handleCCClick}
                            >
                              Cc
                            </span>
                          </>
                        )}
                      </Col>

                      {!isFax && ccVisible && (
                        <>
                          <>
                            <Col span={12} md={24} xs={24} sm={24}>
                              <b
                                style={{
                                  fontSize: "1.125rem",
                                  color: "#00406A",
                                  fontWeight: "700",
                                }}
                              >
                                Cc
                              </b>
                            </Col>

                            <Col span={12} md={24} xs={24} sm={24}>
                              <Form.Item
                                name={`ccemail-${index}`}
                                initialValue={email?.cc ? email?.cc : []}
                                rules={[
                                  {
                                    validator:
                                      activeKey === "1"
                                        ? (rule, value) => {
                                            const emailRegex =
                                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                                            const invalidEmails = value.filter(
                                              (email) => !emailRegex.test(email)
                                            );
                                            if (invalidEmails.length > 0) {
                                              return Promise.reject(
                                                `The following emails are invalid: ${invalidEmails.join(
                                                  ", "
                                                )}`
                                              );
                                            }
                                            return Promise.resolve();
                                          }
                                        : false,
                                  },
                                ]}
                              >
                                <Select
                                  disabled={activeKey === "1" ? false : true}
                                  mode="tags"
                                  style={{ width: "100%" }}
                                  maxTagCount="responsive"
                                  getPopupContainer={(trigger) =>
                                    trigger.parentNode
                                  }
                                ></Select>
                              </Form.Item>
                            </Col>
                          </>
                        </>
                      )}
                    </div>
                  </Card>

                  <Card
                    className="doctor-profile bottom-padding"
                    style={{
                      background: "#F8F8F8",
                      border: "1px solid #8FA9B1",
                    }}
                  >
                    <div>
                      {!isFax && (
                        <>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <b
                              style={{
                                fontSize: "1.125rem",
                                color: "#00406A",
                                fontWeight: "700",
                              }}
                            >
                              Subject
                            </b>
                          </Col>
                          <Col span={12} md={24} xs={24} sm={24}>
                            <Form.Item
                              name={`subject-${index}`}
                              initialValue={email?.subject}
                            >
                              <Input
                                disabled={activeKey === "1" ? false : true}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}

                      <Col span={12} md={24} xs={24} sm={24} className="mt-20">
                        <b
                          style={{
                            fontSize: "1.125rem",
                            color: "#00406A",
                            fontWeight: "700",
                          }}
                        >
                          Message
                        </b>

                        <div className="editor-email">
                          {forceRender && (
                            <Editor
                              editorState={emailHistoryEditorState}
                              onEditorStateChange={
                                onEmailHistoryEditorStateChangeDecription
                              }
                              ref={historyRef}
                              readOnly={activeKey === "1" ? false : true}
                              toolbarClassName="toolbarClassName"
                              wrapperClassName="wrapperClassName"
                              editorClassName="editorClassName"
                              placeholder="Start typing..."
                              toolbar={{
                                options: [
                                  "inline",
                                  "blockType",
                                  "fontSize",
                                  "fontFamily",
                                  "list",
                                  "link",
                                ],
                                inline: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: [
                                    "bold",
                                    "italic",
                                    "underline",
                                    "strikethrough",
                                  ],
                                },
                                list: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  dropdownClassName: undefined,
                                  options: ["unordered", "ordered"],
                                },
                                link: {
                                  inDropdown: false,
                                  className: undefined,
                                  component: undefined,
                                  popupClassName: undefined,
                                  dropdownClassName: undefined,
                                  showOpenOptionOnHover: true,
                                  defaultTargetOption: "_self",
                                  options: ["link"],
                                },
                              }}
                            />
                          )}
                        </div>
                      </Col>
                    </div>
                  </Card>
                </Tabs.TabPane>
              ))}
          </Tabs>

          {activeKey === "1" ? (
            <Row className="mt-30">
              <Col
                span={12}
                md={24}
                xs={24}
                sm={24}
                style={{ textAlign: "center" }}
              >
                <Button
                  className="theme-btn"
                  htmlType="submit"
                  loading={emailLoading}
                >
                  Resend
                </Button>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Form>
      </Modal>

      <SuccessModel
        message="Appointment Closed Successfully"
        open={successModel}
      />

      <SuccessModel
        message={`${isFax ? "Fax" : "Email"} Sent Successfully`}
        open={emailSuccess}
      />
    </>
  );
}
