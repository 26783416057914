import React from 'react';
import { Col } from 'antd';
import SkeletonBlock from './SkeletonBlocks';

const SkeletonLoader = ({
  cols = 1,
  span = 8,
  lg = 8,
  md = 12,
  sm = 24,
  xs = 24,
  width = '100%',
  height = 200,
}) => {
  const colArray = Array.from({ length: cols }, (_, index) => index);

  return (
    <>
      {colArray.map((colIndex) => (
        <Col
          key={colIndex}
          span={span}
          lg={lg}
          md={md}
          sm={sm}
          xs={xs}
        >
          <SkeletonBlock style={{ width, height }} />
        </Col>
      ))}
    </>
  );
};

export default SkeletonLoader;
