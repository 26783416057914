export const handleStatusChange = async (
  value,
  serviceRow,
  actionName,
  defaultData
) => {
  try {
    if (value === "ACTIVE") {
      const filter = {
        activeOnPortal: { eq: true },
      };
      const response = await actionName(999, filter);
      const data = response.map((row) => serviceRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return data;
    } else if (value === "INACTIVE") {
      const filter = {
        activeOnPortal: { eq: false },
      };
      const res = await actionName(999, filter);
      const data = res.map((row) => serviceRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      return data;
    } else {
      return defaultData;
    }
  } catch (error) {
    console.log(error);
  }
};
