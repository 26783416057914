import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Tabs,
  Modal,
  Skeleton,
  message,
  Grid,
} from "antd";
import { StarFilled } from "@ant-design/icons";
import photos from "assets/icons/photos.svg";
import Container from "commons/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import overview from "assets/icons/overview.svg";
import appointment from "assets/icons/appointment.svg";
import { Analytics, Storage } from "aws-amplify";
import LabOverView from "../LabOverview/LabOverView";

import Chat from "pages/user/SupportChatbox/Chat";
import { GetLabDetails } from "utility/action";
import { GetPublicFileFromS3 } from "utility/uploadFileToS3";
import { useCallback } from "react";
import ImageGallery from "utility/ImageGallery";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import { END_TOUR, LAB_PROFILE_TOUR_START } from "utility/constant";
import { useDispatch, useSelector } from "react-redux";
import { setTourSteps } from "store/actions/tourSteps";

export default function LabProfile() {
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const userID = location?.state?.id;
  const [messageApi, contextHolder] = message.useMessage();
  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);
  const [labPhoto, setLabPhoto] = useState(null);
  const [preSignedImg, setPreSignedimg] = useState([]);
  const [activeTab, setActiveTab] = useState("item-1");

  const [labData, setLabData] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const [tour, setTour] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // for chat box
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const search = location?.state?.search;

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const fetchLab = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const response = await GetLabDetails(userID);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);
      setLabData(response);
      if (response?.labProfilePhoto) {
        let labPhoto;

        if (response?.labProfilePhoto.startsWith("http")) {
          labPhoto = response?.labProfilePhoto;
        } else {
          labPhoto = await GetPublicFileFromS3(response?.labProfilePhoto);
        }
        setLabPhoto(labPhoto);
      }
      if (response?.labPhotos) {
        const fileKeyy = response?.labPhotos;
        const urls = [];
        for (const key of fileKeyy) {
          try {
            const url = await Storage.get(key, {
              level: "public",
              expires: 86400,
            });
            urls.push(url);
          } catch (error) {
            console.log(`Error getting pre-signed URL for ${key}: ${error}`);
          }
        }
        setPreSignedimg(urls);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === LAB_PROFILE_TOUR_START) {
        setTour(true);
      }
      setTimeout(() => {
        dispatch(setTourSteps(END_TOUR));
      }, 500);
    }
  }, [userID]);

  useEffect(() => {
    fetchLab();
  }, [fetchLab]);

  const items = [
    {
      label: (
        <span
          className="sub-header-font d-flex align-center lab-first-step"
          onClick={() => {
            if (tour) {
              setTourIndex(1);
            }
          }}
        >
          <img src={overview} className="tabs-icon" />
          {(activeTab === "item-1" && screens.xs) || screens.sm || screens.lg
            ? "Overview"
            : ""}
        </span>
      ),
      key: "item-1",
      children: <LabOverView data={labData} loader={skeletonLoader} />,
    },
    {
      label: (
        <span className="sub-header-font d-flex align-center">
          <img src={photos} className="tabs-icon" />
          {(activeTab === "item-3" && screens.xs) || screens.sm || screens.lg
            ? "Photos"
            : ""}
        </span>
      ),
      key: "item-3",
      children: <ImageGallery data={preSignedImg} />,
    },
    {
      label: (
        <span className="sub-header-font lab-second-step">
          {tour ? (
            <a
              className="w-100 d-flex align-center"
              onClick={() => {
                navigate("/");
                setTour(false);
              }}
              style={{ color: "#000" }}
            >
              <img src={appointment} className="tabs-icon" />
              {(activeTab === "item-4" && screens.xs) ||
              screens.sm ||
              screens.lg
                ? "Request An Appointment"
                : ""}
            </a>
          ) : (
            <a
              href={labData?.labAppointmentLink}
              target="_blank"
              className="w-100 d-flex align-center"
              onClick={() => {
                Analytics.record({
                  name: "Lab Appointment",
                  // Attribute values must be strings
                  attributes: { name: labData?.labName },
                  immediate: true,
                });
              }}
              style={{ color: "#000" }}
            >
              <img src={appointment} className="tabs-icon" />
              {(activeTab === "item-4" && screens.xs) ||
              screens.sm ||
              screens.lg
                ? "Request An Appointment"
                : ""}
            </a>
          )}
        </span>
      ),
      key: "item-4",
      // children: <LabOverView />,
    },
  ];

  const handleEndTour = () => {
    setTour(false);
    dispatch(setTourSteps(END_TOUR));
  };

  const serviceSteps = [
    {
      target: ".lab-first-step",
      content: (
        <TourStepContent
          heading="Lab Profile"
          details="Simply click on different tabs to explore all the details related to the Lab."
          numbers={{ currentStep: 3, totalSteps: 4 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".lab-second-step",
      content: (
        <TourStepContent
          heading="Request an appointment"
          details="Click to redirect to a lab web page for booking an appointment."
          numbers={{ currentStep: 4, totalSteps: 4 }}
          onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}
      <Container>
        {/* Sub Header Starts */}

        <GlobalJoyride
          steps={serviceSteps}
          run={tour}
          stepIndex={tourIndex}
          setTourIndex={setTourIndex}
        />
        <Breadcrumb className="hide-version-no">
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Home
            </Link>
          </Breadcrumb.Item>
          {!search && (
            <Breadcrumb.Item>
              <Link to="/lab-center-list" className="p-font">
                Lab Center List
              </Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <span className="p-font">{labData?.labName}</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* main content starts*/}
        <Card className="doctor-profile mobile-card">
          <Row>
            {skeletonLoader ? (
              <>
                {" "}
                <Skeleton avatar paragraph={{ rows: 2 }}></Skeleton>{" "}
              </>
            ) : (
              <>
                <Col>
                  <div className="d-flex gap-4">
                    <img src={labPhoto} alt="" className="doctor-img" />
                    <div className="doctor-detail">
                      <h4 className="doctor-name sub-header-font">
                        {labData?.labName}
                      </h4>
                      <div className="d-flex gap-3">
                        <p className="p-font">
                          <img src={facebook} alt="facebook Icon" />
                          {labData?.labFaceBookRating
                            ? labData?.labFaceBookRating
                            : 0}{" "}
                          <StarFilled style={{ color: "#e7a500" }} />
                        </p>

                        <p className="p-font">
                          <img src={google} alt="facebook Icon" />
                          {labData?.labGoogleRating
                            ? labData?.labGoogleRating
                            : 0}{" "}
                          <StarFilled style={{ color: "#e7a500" }} />
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <Card className="profile-tabs mobile-card">
          <Row>
            <Col span={24}>
              <Tabs items={items} onChange={handleTabChange} />
            </Col>
          </Row>
        </Card>
      </Container>

      
    
    </>
  );
}
