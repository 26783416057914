import * as actions from "../actions/index";

const initialState = {
    userID: null,
  };

const userIdReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_USER_ID:
            return {
              ...state,
              userID: action.payload,
            };

        default:
            return state;
    }
}
export default userIdReducer;
