import {
  Layout,
  PageHeader,
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  DatePicker,
  message,
  Button,
  Radio,
  Spin,
  Card,
  Space,
  Modal,
  Avatar,
  Select,
} from "antd";
import {
  PlusCircleOutlined,
  PlusOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from "@ant-design/icons";
import logo from "assets/delphy/delphy.svg";
import "./EpcSelfServingOnBoarding.less";
import { v4 as uuidv4 } from "uuid";
import { Content } from "antd/lib/layout/layout";
import SkeletonBlock from "utility/SkeletonBlocks";
import { useEffect, useState } from "react";
import {
  cityPattern,
  emailPattern,
  formatNumberUS,
  namePattern,
  servicesNamePattern,
  specialPatternOnboarding,
  streetAddressPattern,
  tenDigit,
  zipPattern,
} from "utility/constant";
import moment from "moment";
import awsmobile from "aws-exports";
import { API } from "aws-amplify";
import { getDoctorCustom, listDoctorCustom } from "graphql/customQuery";
import { ListBillingPlansSelfServing } from "utility/action";
import { loadStripe } from "@stripe/stripe-js";
const { TextArea } = Input;

export const EpcSelfServingOnBoarding = () => {
  const stripePromise = loadStripe(`${process.env.REACT_APP_EPC_STRIPE}`);
  const [stripe, setStripe] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [doctorName, setDoctorName] = useState(null);
  const [doctorID, setDoctorID] = useState(null);
  const [doctorStartDate, setDoctorStartDate] = useState(null);
  const [nameLoader, setNameLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [planChange, setPlanChange] = useState(null);
  const [planPrice, setPlanPrice] = useState([120, 1200]);
  const [date, setDate] = useState("");
  const [dateDep, setDateDep] = useState("");
  const [dateValidation, setDateValidation] = useState("");
  const [dateValidationDep, setDateValidationDep] = useState("");
  const [modal, setModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentDependent, setCurrentDependent] = useState(null);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [depForm] = Form.useForm();

  const [dependantDetails, setDependantDetails] = useState([]);
  const [doctorList, setDoctorList] = useState([]);
  const onPlanChange = (e) => {
    setPlanChange(e.target.value);
  };

  const referralSources = [
    "Search Engine (Google, Bing, etc.)",
    "Jack Health Radio",
    "Radio",
    "Mailer",
    "Magazine/Newspaper Article",
    "Social Media",
    "Word of Mouth",
    "Referral",
    "Friends or Family",
    "Other",
  ];

  const openAddModal = async () => {
    if (doctorList.length === 0) {
      const responce = await API.graphql({
        query: listDoctorCustom,
        variables: {
          filter: { isDpcDoctor: { eq: true } },
          limit: "9999",
        },
        authMode: "API_KEY",
        authToken: awsmobile.aws_appsync_apiKey,
      });
      setDoctorList(responce?.data?.listDoctorProfiles?.items);
    }
    setIsEditMode(false);
    depForm.resetFields();
    setModal(true);
  };

  const disabledDate = (current) => {
    const todayMinus18Years = moment().subtract(18, "years").endOf("day");
    return (
      current &&
      (current.isAfter(moment()) || current.isAfter(todayMinus18Years))
    );
  };

  // Function to detect if the browser is Safari
  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    );
  };

  const openEditModal = (dependent) => {
    setIsEditMode(true);
    setCurrentDependent(dependent);
    console.log(dependent, "sdfsd");
    depForm.setFieldsValue({
      firstName: dependent.firstName,
      lastName: dependent.lastName,
      email: dependent.email,
      phone: dependent.phoneNumber,
      dob: moment(dependent.dob),
      doctor: dependent.doctorDetails,
    });
    setModal(true);
  };

  const removeDependent = (id) => {
    setDependantDetails(
      dependantDetails.filter((dependent) => dependent.id !== id)
    );
  };
  console.log(dependantDetails);
  const onChangeDatepicker = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");
    setDateValidation(formattedEighteenYearsAgo);
    setDate(moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD"));
  };

  function compareDates(date1, date2) {
    // Convert the date strings into Date objects
    const [year1, month1, day1] = date1.split("-").map(Number);
    const [year2, month2, day2] = date2.split("-").map(Number);

    const dateObj1 = new Date(year1, month1 - 1, day1);
    const dateObj2 = new Date(year2, month2 - 1, day2);

    // Compare the two dates
    return dateObj1 < dateObj2;
  }

  const onChangeDatepickerDep = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    console.log(moment(dateString).format("YYYY-MM-DD"));
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");
    setDateValidationDep(formattedEighteenYearsAgo);
    setDateDep(moment(dateString, "MM-DD-YYYY").format("YYYY-MM-DD"));
  };

  const handleModalDestroy = () => {
    depForm.resetFields();
  };

  const handleDependentAddition = async (values) => {
    const filteredDoctor = doctorList.find(
      (doctor) => doctor.doctorName === values?.doctor
    );
    console.log(doctorList);
    let doctorJson;
    console.log(filteredDoctor);
    if (filteredDoctor) {
      // Create a new object with selected fields and additional properties
      doctorJson = {
        name: filteredDoctor.doctorName,
        id: filteredDoctor.id,
        startDate: filteredDoctor.startDate,
      };
    }
    console.log(values, doctorJson);
    if (isEditMode && currentDependent) {
      const updatedList = dependantDetails.map((dep) =>
        dep.id === currentDependent.id
          ? {
              ...dep,
              ...values,
              fistName: values.firstName.trim(),
              lastName: values.lastName.trim(),
              phoneNumber: values.phone,
              dob: dateDep,
              doctorDetails: doctorJson?.name,
              doctorID: doctorJson?.id,
              startDate: doctorJson?.startDate,
            }
          : dep
      );
      setDependantDetails(updatedList);
    } else {
      const newDependent = {
        id: uuidv4(),
        firstName: (values?.firstName).trim(),
        lastName: (values?.lastName).trim(),
        email: values.email,
        phoneNumber: values.phone,
        dob: dateDep,
        doctorDetails: doctorJson?.name,
        doctorID: doctorJson?.id,
        startDate: doctorJson?.startDate,
      };
      setDependantDetails((prevList) => [...prevList, newDependent]);
    }
    setModal(false);
  };

  const AddSpouseOrDependent = () => {
    return (
      <Card
        bordered
        style={{ margin: "0 auto 20px", border: "1px dashed #B6CCD7" }}
        size="small"
      >
        <Space
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            class="column-container"
            // style={{
            //   display: "flex",
            //   flexDirection: "column",
            //   alignItems: "center",
            //   width: "100%",
            //   marginRight: "190px",
            // }}
          >
            <span
              style={{
                fontSize: "15px",
                fontWeight: "500",
                marginRight: "10px",
                lineHeight: "20px",
                color: "#00274C",
              }}
            >
              Will You be Adding a Spouse or Additional Member(s)?
            </span>
            <br />
            <span
              style={{
                fontSize: "11px",
                fontWeight: "300",
                marginRight: "10px",
                lineHeight: "20px",
                color: "#00274C",
                fontStyle: "italic",
              }}
            >
              Additional Member(s) rate is discounted 10%
            </span>
          </div>
          <Button
            type="dashed"
            icon={<PlusCircleOutlined style={{ color: "#00274C" }} />}
            style={{ backgroundColor: "#E8EEF1" }}
            onClick={() => openAddModal()}
          >
            Yes want to add
          </Button>
        </Space>
      </Card>
    );
  };

  const handleFormSubmit = async (values) => {
    try {
      setLoading(true);
      if (!planChange) {
        messageApi.open({
          type: "error",
          content: "Please Select Plan",
        });
        setLoading(false);
        return;
      }
      console.log("dsdfdsf");

      const res = await ListBillingPlansSelfServing({
        name: { contains: "DPC" },
      });

      const planArray = await Promise.all(
        res?.filter((param) => {
          const details = JSON.parse(param?.planDetails);
          return details?.planName === planChange;
        })
      );

      const obj = JSON.parse(planArray[0].planDetails);
      console.log(obj?.stripePriceId, planArray[0].id);

      const payloadData = {};

      payloadData["parentDetails"] = {
        firstName: (values?.firstName).trim(),
        lastName: (values?.lastName).trim(),
        dob: date,
        email: values?.email,
        phoneNumber: values?.phone,
        streetAddress: values?.streetAddress,
        city: values?.city,
        state: values?.state,
        zipCode: values?.zip,
        insuranceCarrier: (values?.insuranceCarrier).trim(),
        reference: !isOtherSelected
          ? values?.reference
          : values?.otherReference,
        question: values?.questions,
        doctorID: doctorID,
        startDate: doctorStartDate,
      };
      payloadData["dependentDetails"] = dependantDetails;
      console.log(payloadData);
      const myInit = {
        body: {
          payloadData,
          planId: planArray[0].id,
          stripePriceId: obj?.stripePriceId,
        },
      };
      console.log(payloadData);
      const sessionResult = await API.post(
        "bazarhealthREST",
        `/selfServingOnboarding/${doctorID}`,
        myInit
      );
      console.log("43432");
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
      setLoading(false);
    } catch (error) {
      console.log(error, "error");
      setLoading(false);

      messageApi.open({
        type: "error",
        content: error?.response?.data?.error || "Something went wrong!",
      });
    }
  };

  async function fetchDoctorDetails(id) {
    setskeletonLoader(true);
    setNameLoader(true);

    const response = await API.graphql({
      query: getDoctorCustom,
      variables: {
        id,
      },
      authMode: "API_KEY",
      authToken: awsmobile.aws_appsync_apiKey,
    });
    if (!response?.data?.getDoctorProfile?.id) {
      //handle if no id is found
    }
    setDoctorStartDate(response?.data?.getDoctorProfile?.startDate);
    setDoctorName("Join " + response?.data?.getDoctorProfile?.doctorName);
    setNameLoader(false);
    setskeletonLoader(false);
  }

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    resolveStripe();
    const currentUrl = new URL(window.location.href);
    const pathParts = currentUrl?.pathname?.split("/");
    const lastNumber = pathParts[pathParts.length - 1];
    setDoctorID(lastNumber);

    fetchDoctorDetails(lastNumber);
  }, []);

  useEffect(() => {
    setPlanPrice([
      120 + dependantDetails.length * 108,
      1200 + dependantDetails.length * 1080,
    ]);
  }, [dependantDetails]);

  return (
    <>
      {contextHolder}
      <Layout>
        <PageHeader style={{ backgroundColor: "#CEE1ED" }}>
          <Row className="onboarding-page" justify="center" align="middle">
            <Col span={24} className="responsive-mt-20">
              <img src={logo} alt="Bazar Logo" className="bazar-logo" />
            </Col>
          </Row>
          <Row
            className="onboarding-page"
            justify="center"
            align="middle"
            style={{ padding: "25px", color: "#00274C" }}
          >
            <h2
              style={{
                fontWeight: "700",
                fontSize: "30px",
                lineHeight: "24px",
                color: "#00274C",
              }}
            >
              {" "}
              <Spin size="large" spinning={nameLoader}>
                {doctorName || ""}
              </Spin>
            </h2>
          </Row>
        </PageHeader>
        <Content style={{ backgroundColor: "#F3F9FD" }}>
          <Row justify="center" className="update-form">
            <Col span={16} md={16} xs={24} sm={24} style={{ maxWidth: "55%" }}>
              {skeletonLoader ? (
                <>
                  <Row gutter={[20, 20]} className="mt-3">
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 50 }} />
                    <SkeletonBlock style={{ width: "100%", height: 200 }} />
                  </Row>
                </>
              ) : (
                <>
                  <Form
                    name="basic"
                    form={form}
                    onFinish={handleFormSubmit}
                    autoComplete="off"
                    layout="vertical"
                    className="mt-20"
                    validateFirst={true}
                  >
                    <Row gutter={20}>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="First Name"
                          name="firstName"
                          required
                          validateFirst={true}
                          rules={[
                            {
                              required: true,
                              message: "Please input your first name!",
                            },
                            {
                              pattern: /^[A-Za-z]*(?: [A-Za-z]+)*$/,
                              message: "Name can only contain alphabets",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Last Name"
                          name="lastName"
                          required
                          validateFirst={true}
                          rules={[
                            {
                              required: true,
                              message: "Please input your last name!",
                            },
                            {
                              pattern: /^[A-Za-z]*(?: [A-Za-z]+)*$/,
                              message: "Name can only contain alphabets",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={24} xs={24} sm={24}>
                        <div
                          className="referral-code mb-20"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <span
                            className="referral-code"
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              lineHeight: "20px",
                              color: "#00274C",
                            }}
                          >
                            {" "}
                            Please Pick a Membership Option *
                          </span>

                          <Row
                            // gutter={400}
                            className="billing-cards-invite-dpc"
                            style={{
                              display: "flex",
                              width: "100% !important",
                              justifyContent: "center",
                            }}
                          >
                            <Col
                              span={12}
                              md={6}
                              xs={24}
                              sm={24}
                              // className="column-content"
                            >
                              <Col
                                md={24}
                                span={24}
                                className="mt-10 d-flex justify-content-center emp-radio"
                              >
                                <Radio
                                  value="DPC_MONTHLY"
                                  onChange={onPlanChange}
                                  checked={planChange === "DPC_MONTHLY"}
                                >
                                  <span
                                    className={`pricing `}
                                    style={{
                                      fontWeight: "700",
                                      fontSize: "25px",
                                      lineHeight: "35.16px",
                                      color: "#00274C",
                                    }}
                                  >
                                    {" "}
                                    ${planPrice[0]}
                                  </span>{" "}
                                  / month
                                </Radio>
                              </Col>
                            </Col>

                            <Col span={12} md={6} xs={24} sm={24}>
                              <Col
                                md={24}
                                span={24}
                                className="mt-10 d-flex justify-content-center emp-radio"
                              >
                                <Radio
                                  value="DPC_ANNUALLY"
                                  onChange={onPlanChange}
                                  checked={planChange === "DPC_ANNUALLY"}
                                >
                                  <span
                                    className={`pricing `}
                                    style={{
                                      fontWeight: "700",
                                      fontSize: "25px",
                                      lineHeight: "35.16px",
                                      color: "#00274C",
                                    }}
                                  >
                                    ${planPrice[1]}
                                  </span>{" "}
                                  / year
                                </Radio>
                              </Col>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col span={12} md={24} xs={24} sm={24}>
                        {dependantDetails.length === 0 ? (
                          AddSpouseOrDependent()
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              gap: "16px",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: "20px",
                            }}
                          >
                            {dependantDetails.map((dependent) => (
                              <Card
                                key={dependent.id}
                                className={"fdfd"}
                                size="small"
                                style={{
                                  width: 150,
                                  textAlign: "center",
                                  borderRadius: "8px",
                                  boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                                  position: "relative",
                                }}
                                onClick={(event) => {
                                  if (
                                    event.target.classList.contains(
                                      "ant-card-body"
                                    )
                                  ) {
                                    // Ignore click if it was on the icon
                                    openEditModal(dependent);
                                    return;
                                  }
                                }}
                              >
                                <CloseCircleOutlined
                                  style={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                    cursor: "pointer",
                                    color: "#ff4d4f",
                                  }}
                                  className="closeCircleOutlined"
                                  id="fdsfd"
                                  onClick={() => removeDependent(dependent.id)}
                                />
                                <Avatar
                                  size={48}
                                  icon={<UserOutlined />}
                                  //   style={{ marginBottom: "10px" }}
                                />
                                <p
                                  style={{
                                    margin: "5px 0",
                                    color: "#00274C",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {`${dependent.firstName || ""} ${
                                    dependent.lastName || ""
                                  }`}
                                </p>
                                <p
                                  style={{
                                    margin: "5px 0",
                                    fontSize: "12px",
                                    color: "#888",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "wrap",
                                    maxWidth: "130px",
                                  }}
                                >
                                  {dependent.email}
                                </p>
                              </Card>
                            ))}
                            <Card
                              style={{
                                width: 150,
                                textAlign: "center",
                                borderRadius: "8px",
                                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
                                cursor: "pointer",
                                display: "flex",
                                justifyContent: "center",
                                backgroundColor: "transparent",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                              onClick={() => openAddModal()}
                            >
                              <PlusOutlined
                                style={{ fontSize: 32, color: "#00274C" }}
                              />
                              <p
                                style={{
                                  margin: "5px 0",
                                  fontWeight: "bold",
                                  color: "#00274C",
                                }}
                              >
                                Add More
                              </p>
                            </Card>
                          </div>
                        )}
                      </Col>

                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Date of Birth - Must be 18 or older"
                          name="dob"
                          validateFirst={true}
                          rules={[
                            {
                              required: true,
                              message: "Please enter your DOB!",
                            },
                            ...(isSafari()
                              ? []
                              : [
                                  {
                                    validator: (_, value) => {
                                      if (compareDates(date, dateValidation)) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(
                                        new Error("Must be 18 or older!")
                                      );
                                    },
                                  },
                                ]),
                          ]}
                        >
                          <DatePicker
                            onChange={onChangeDatepicker}
                            placeholder="MM-DD-YYYY"
                            format="MM-DD-YYYY"
                            disabledDate={disabledDate}
                            defaultPickerValue={moment()
                              .subtract(18, "years")
                              .startOf("day")}
                            style={{ width: "100%" }}
                            showToday={false}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Email"
                          name="email"
                          rules={[
                            {
                              required: true,
                              message: "Please input your email!",
                            },
                            {
                              pattern: emailPattern,
                              message: "Please enter a valid email",
                            },
                          ]}
                        >
                          <Input placeholder="email@email.com" />
                        </Form.Item>
                      </Col>

                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Cell Phone Number"
                          name="phone"
                          rules={[
                            {
                              required: true,
                              message: "Please input your phone number!",
                            },
                            {
                              pattern: tenDigit,
                              message: "Please enter a valid phone number",
                            },
                          ]}
                        >
                          <InputNumber
                            controls={false}
                            placeholder="(111) 111-1111"
                            formatter={(value) => formatNumberUS(value)}
                            parser={(value) => {
                              const cleanedValue = value?.replace(/\D/g, "");
                              return cleanedValue.slice(0, 10);
                            }}
                            style={{ width: "100%", borderRadius: "5px" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Street Address"
                          name="streetAddress"
                          rules={[
                            {
                              required: true,
                              message: "Please input your street address!",
                            },
                            {
                              pattern: streetAddressPattern,
                              message: "Please enter a valid street address",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="City"
                          name="city"
                          rules={[
                            {
                              required: true,
                              message: "Please input your city!",
                            },
                            {
                              pattern: cityPattern,
                              message: "Please enter a valid city name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="State"
                          name="state"
                          rules={[
                            {
                              required: true,
                              message: "Please input your state!",
                            },
                            {
                              pattern: cityPattern,
                              message: "Please enter a valid state name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Zip Code"
                          name="zip"
                          rules={[
                            {
                              required: true,
                              message: "Please input your zip code!",
                            },
                            {
                              pattern: zipPattern,
                              message: "Please enter a valid ZIP code",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Insurance Carrier"
                          name="insuranceCarrier"
                          rules={[
                            {
                              required: true,
                              message: "Please input your insurance carrier!",
                            },
                            {
                              pattern: specialPatternOnboarding,
                              message: "Please enter a valid insurance carrier",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={24} xs={24} sm={24}>
                        <Form.Item
                          label="How did you hear about us?"
                          name="reference"
                          rules={[
                            {
                              required: true,
                              message: "Required!",
                            },
                          ]}
                        >
                          {/* <Input /> */}
                          <Select
                            allowClear
                            placeholder="How did you hear about us?"
                            onChange={(value) => {
                              //Added a timer as their was some jerk in screen as select closes and the new input field opens or vice-versa
                              setTimeout(
                                () => setIsOtherSelected(value === "Other"),
                                400
                              );
                            }}
                          >
                            {referralSources.map((item, index) => (
                              <Select.Option
                                key={index}
                                value={item} //have start date too, once it is done.
                              >{`${item}`}</Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        {isOtherSelected && (
                          <Form.Item
                            label="Please specify"
                            name="otherReference"
                            validateFirst={true}
                            rules={[
                              {
                                required: true,
                                message: "Please specify the reference!",
                              },
                              {
                                pattern: /^(?=.*\S).*$/, // Rule 1: Prevent only white space
                                message:
                                  "Input cannot be empty or just white spaces.",
                              },
                              {
                                pattern: /^(?=.*[a-zA-Z]).*$/, // Ensure input contains alphabets
                                message:
                                  "Input must include at least one alphabet.", // Third validation rule
                              },
                            ]}
                          >
                            <Input placeholder="Specify your answer" />
                          </Form.Item>
                        )}
                      </Col>
                      <Col span={12} md={24} xs={24} sm={24}>
                        <Form.Item label="Questions?" name="questions">
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                      <Col
                        span={12}
                        md={24}
                        xs={24}
                        sm={24}
                        style={{ textAlign: "center" }}
                      >
                        <Button
                          size="large"
                          className="theme-btn"
                          htmlType="submit"
                          style={{ margin: "5px" }}
                          loading={loading}
                        >
                          Proceed to Checkout
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </>
              )}
            </Col>
          </Row>
        </Content>
      </Layout>

      <Modal
        title={"Add Spouse or Additional Member"}
        width={600}
        open={modal}
        onCancel={() => {
          setModal(false);
        }}
        keyboard={false}
        centered
        className="form-modal inv-second-step desired-modal"
        footer={null}
        afterClose={handleModalDestroy}
      >
        <Form
          name="employee"
          onFinish={handleDependentAddition}
          autoComplete="off"
          id="memberInvite"
          layout="vertical"
          form={depForm}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: "Please enter first name!",
                  },
                  {
                    pattern: specialPatternOnboarding,
                    message: "Please enter a valid first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Last Name"
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: "Please enter last name!",
                  },

                  {
                    pattern: specialPatternOnboarding,
                    message: "Please enter a valid last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Phone"
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please enter phone number",
                  },
                  {
                    pattern: tenDigit,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  placeholder="(111) 111-1111"
                  formatter={(value) => formatNumberUS(value)}
                  parser={(value) => {
                    const cleanedValue = value?.replace(/\D/g, "");
                    return cleanedValue.slice(0, 10);
                  }}
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Date of Birth"
                name="dob"
                // initialValue={date}
                validateFirst={true}
                rules={[
                  {
                    required: true,
                    message: "Please select your date of birth",
                  },
                  ...(isSafari()
                    ? []
                    : [
                        {
                          validator: (_, value) => {
                            if (compareDates(dateDep, dateValidationDep)) {
                              return Promise.resolve(); // Validation passed
                            }
                            return Promise.reject(
                              "Users under 18 are not permitted"
                            );
                          },
                        },
                      ]),
                ]}
              >
                <DatePicker
                  onChange={onChangeDatepickerDep}
                  placeholder="MM-DD-YYYY"
                  format="MM-DD-YYYY"
                  disabledDate={disabledDate}
                  defaultPickerValue={moment()
                    .subtract(18, "years")
                    .startOf("day")}
                  style={{ width: "100%" }}
                  showToday={false}
                />
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Choose Your Medical Provider"
                name="doctor"
                initialValue={doctorName?.replace("Join ", "")}
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select allowClear placeholder="Select Employer">
                  {doctorList.map((item) => (
                    <Select.Option
                      key={item?.id}
                      value={item?.doctorName} //have start date too, once it is done.
                    >{`${item.doctorName}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                type="primary"
                className="theme-btn inv-third-step"
                htmlType="submit"
                loading={loading}
                style={{ marginTop: "0px !important" }}
              >
                Add Member
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
