import React from 'react';

const TestimonialFunctions = ({ testimonialData, setTestimonialData }) => {
  
  const handleAddTestimonial = () => {
    setTestimonialData([
      ...testimonialData,
      { testimonialName: '', testimonialRating: '', review: '' },
    ]);
  };

  const handleUpdateTestimonial = (index, newTestimonialName, newTestimonialRating, newReview) => {
    const updatedTestimonialData = [...testimonialData];
    updatedTestimonialData[index] = {
      testimonialName: newTestimonialName,
      testimonialRating: newTestimonialRating,
      review: newReview,
    };
    setTestimonialData(updatedTestimonialData);
  };

  const handleDeleteTestimonial = (index) => {
    const updatedTestimonialData = [...testimonialData];
    updatedTestimonialData.splice(index, 1);
    setTestimonialData(updatedTestimonialData);
  };

  return {
    handleAddTestimonial,
    handleUpdateTestimonial,
    handleDeleteTestimonial,
  };
};

export default TestimonialFunctions;
