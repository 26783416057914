import { Col, Row, Card, Button, Divider, Modal, Radio } from "antd";
import Container from "commons/Container";
import React from "react";
import "../FamilyBilling/BillingPlan.less";
import { loadStripe } from "@stripe/stripe-js";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ListBillingPlans } from "utility/action";
import checked from "assets/icons/checked.svg";
import paymentIC from "assets/icons/payment.svg";

import EmployerBillingCard from "../FamilyBilling/EmployerBillingCard";

export default function StripeEmployerPlans() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [stripe, setStripe] = useState([]);
  const [individualPlanLoading, setIndividualPlanLoading] = useState(false);
  const [familyPlanLoading, setFamilyPlanLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);

  const userData = useSelector((state) => state?.userReducer?.userData);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const selectedPlanFromUrl = searchParams.get("selectedPlan");
  const urlCheckout = searchParams.get("allowCheckout");

  const getOrgCheckoutSession = async (planId, stripePriceId) => {
    let url = `/billing/checkout/org`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: userData?.id,
          planId,
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId) => {
    try {
      let sessionResult = await getOrgCheckoutSession(planId, stripePriceId);
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };
  console.log(userData);
  const handlePlanCheckout = async (filterCriteria, setLoadingState) => {
    console.log(filterCriteria);
    setLoadingState(true);
    setLoading(true);
    try {
      if (selectedPlan === "CUSTOM_PLAN") {
        const res = await ListBillingPlans({
          name: {
            eq: userData?.companyID?.customPlanDetails?.name,
          },
        });

        const obj = JSON.parse(res[0].planDetails);
        console.log("obj", obj);
        await createStripeSession(res[0].id, obj?.stripePriceId);
      } else {
        const res = await ListBillingPlans(filterCriteria);
        console.log("res", res);
        const planArray = await Promise.all(
          res?.filter((param) => {
            const details = JSON.parse(param?.planDetails);
            return details?.planName === selectedPlan;
          })
        );
        const obj = JSON.parse(planArray[0].planDetails);
        console.log("obj", obj);
        await createStripeSession(planArray[0].id, obj?.stripePriceId);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingState(false);
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedPlanFromUrl = searchParams.get("selectedPlan");
    resolveStripe();

    if (selectedPlanFromUrl) {
      setSelectedPlan(selectedPlanFromUrl);
      // if (selectedPlanFromUrl === "CUSTOM_PLAN") {
      // }
    }
  }, [location.search]);

  return (
    <>
      <Container>
        <Card className="mt-10 mb-20 card-container">
          <Row
            justify="center"
            className="managebill-card"
            style={{
              height: "350px",
              alignItems: "flex-start",
            }}
          >
            <Row className="billing-title mt-30">
              <h1>Delphi Subscription Plan</h1>
              <p>Simplify Healthcare, Increase Access, Reduce Costs.</p>
            </Row>
          </Row>

          <Row justify="center" className="mt-10 emp-plans">
            <Col xl={22} md={24}>
              {selectedPlan === "CUSTOM_PLAN" ? (
                <Row gutter={[20]} align="center">
                  <EmployerBillingCard
                    title="Custom Plan"
                    monthlyValue="CUSTOM_PLAN"
                    selectedPlan={selectedPlan}
                    customPrice={userData?.companyID?.customPlanDetails?.price}
                    onChange={onChange}
                    size={userData?.companyID?.customPlanDetails?.maxEmployess}
                    loading={individualPlanLoading}
                  />
                </Row>
              ) : (
                <>
                  <Row gutter={[20]}>
                    <EmployerBillingCard
                      title="Small Size Employers"
                      monthlyValue="SMALL_SIZE_EMPLOYERS"
                      selectedPlan={selectedPlan}
                      onChange={onChange}
                      size="0 - 30"
                      loading={individualPlanLoading}
                    />

                    <EmployerBillingCard
                      title="Mid Size Employers"
                      monthlyValue="MID_SIZE_EMPLOYERS"
                      selectedPlan={selectedPlan}
                      onChange={onChange}
                      size="31 - 100"
                      loading={familyPlanLoading}
                      isPopular={true}
                    />

                    <EmployerBillingCard
                      title="Large Size Employers"
                      monthlyValue="LARGE_SIZE_EMPLOYERS"
                      selectedPlan={selectedPlan}
                      onChange={onChange}
                      size="101+"
                      loading={individualPlanLoading}
                    />
                  </Row>
                </>
              )}
            </Col>
          </Row>

          <Row
            justify="center"
            className="billing-features-emp mt-30"
            style={{ paddingLeft: "15px" }}
          >
            <Col
              md={24}
              className="services d-flex justify-content-center mt-30"
            >
              <h5>Features</h5>
            </Col>

            <Divider />

            <Col md={6} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Delphi platform
              </p>
              <p>
                <img src={checked} alt="" />
                Appointment scheduling
              </p>
            </Col>
            <Col md={6} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Healthcare advocacy
              </p>
              <p>
                <img src={checked} alt="" />
                Member education
              </p>
            </Col>
            <Col md={7} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Navigation to providers and facilities
              </p>
              <p>
                <img src={checked} alt="" />
                Simplifying healthcare
              </p>
            </Col>
            <Col md={5} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Provider and facility reviews
              </p>
            </Col>
          </Row>

          <Row justify="center" className="mt-30 mb-30">
            <Col md={6}>
              <Button
                className="theme-btn w-100"
                loading={loading}
                disabled={
                  urlCheckout === "yes"
                    ? false
                    : selectedPlan === "" ||
                      selectedPlanFromUrl === selectedPlan
                    ? true
                    : false
                }
                onClick={() =>
                  handlePlanCheckout(
                    { name: { contains: "CORPORATE" } },
                    setIndividualPlanLoading
                  )
                }
                // onClick={() => setPaymentModal(true)}
              >
                Checkout
              </Button>
            </Col>
          </Row>

          <Row className="manage-bottom-box mt-20"></Row>
        </Card>

        <Modal
          title="Basic Modal"
          open={paymentModal}
          onOk={() => setPaymentModal(false)}
          centered
          onCancel={() => setPaymentModal(false)}
          className="reward-success-modal"
          footer={null}
          width={500}
        >
          <img src={paymentIC} alt="Delete Img" />
          <h6 className="mt-20">
            Select your payment method to purchase the plan
          </h6>
          <Row className="mt-20" gutter={20}>
            <Col md={24}>
              <Radio.Group>
                <Radio value={true}>Card</Radio>
                <Radio value={false}>Account</Radio>
              </Radio.Group>
            </Col>
            <Col md={24}>
              {" "}
              <Button
                type="primary"
                className="theme-btn mt-20"
                //   onClick={() => setLoginModal(true)}
              >
                Link Account
              </Button>
            </Col>
          </Row>
        </Modal>
      </Container>
    </>
  );
}
