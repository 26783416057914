import React from "react";
import { Modal, Form, Select, Row, Button, Input } from "antd"; 
import CopyToClipboard from "react-copy-to-clipboard";

function AdminInvite({
  inviteModel,
  handleOk,
  EmployeeInvite,
  onFinishFailed,
  form,
  inviteAdminTour,
  setInviteAdminTourIndex,
  screens,
  showInput,
  openUrlBox,
  text,
  hostWithProtocol,
  companyID,
  loading,
  setInviteModel,
  setSuccessModel,
  setSuccessModelAdmin,
  onCopy,
  copy,
  invite,
  setShowInput,
}) {
  return (
    <Modal
      title="Invite Employee"
      open={inviteModel}
      onOk={handleOk}
      centered
      onCancel={handleOk}
      footer={null}
      className="suggest-modal"
      keyboard={false}
    >
      <Form
        name="employee"
        onFinish={EmployeeInvite}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        id="employeeInvite"
        layout="vertical"
        form={form}
      >
        <Form.Item
          className="admin-fifth-step"
          onClick={() => {
            if (inviteAdminTour) {
              setInviteAdminTourIndex(5);
            }
          }}
          label="Email ID"
          name="emails"
          rules={[
            {
              validator: (rule, value) => {
                if (!value || value.length === 0) {
                  return Promise.reject(
                    "Please enter at least one email address"
                  );
                }
                const emailRegex =
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                const invalidEmails = value.filter(
                  (email) => !emailRegex.test(email)
                );
                if (invalidEmails.length > 0) {
                  return Promise.reject(
                    `The following emails are invalid: ${invalidEmails.join(
                      ", "
                    )}`
                  );
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Select
            disabled={inviteAdminTour ? true : false}
            className="invite-select"
            mode="tags"
            style={{ width: "100%" }}
            maxTagCount="responsive"
          ></Select>
        </Form.Item>
        <Row justify="space-between table-header copy-link-row">
          <Row
            align="middle"
            onClick={openUrlBox}
            className="invite-emp admin-third-step "
          >
            <img src={invite} /> &nbsp; Invite Via Link
          </Row>

          {screens.xs ? (
            <>
              {showInput && (
                <>
                  <Row
                    className="gap-10 mtr-20 my-2 admin-forth-step copy-link"
                    onClick={() => {
                      if (inviteAdminTour) {
                        setInviteAdminTourIndex(4);
                        setShowInput(false);
                      }
                    }}
                  >
                    Invite your employee by sending them the link below.{" "}
                    <CopyToClipboard text={text}>
                      <Input
                        defaultValue={`${hostWithProtocol}/signup/?id=${companyID}`}
                        addonAfter={<img src={copy} alt="" onClick={onCopy} />}
                      />
                    </CopyToClipboard>
                  </Row>
                </>
              )}
              <Row className="gap-10 mtr-20">
                <Button
                  loading={loading}
                  className="blue-btn-invite admin-sixth-step"
                  htmlType="submit"
                  onClick={() => {
                    if (inviteAdminTour) {
                      setTimeout(() => {
                        setInviteAdminTourIndex(6);
                      }, 400);

                      setInviteModel(false);
                      setSuccessModel(false);
                      setSuccessModelAdmin(true);
                    }
                  }}
                >
                  Send
                </Button>
              </Row>{" "}
            </>
          ) : (
            <>
              <Row className="gap-10 mtr-20">
                <Button
                  loading={loading}
                  className="blue-btn-invite admin-sixth-step"
                  htmlType="submit"
                  onClick={() => {
                    if (inviteAdminTour) {
                      setTimeout(() => {
                        setInviteAdminTourIndex(6);
                      }, 400);

                      setInviteModel(false);
                      setSuccessModel(false);
                      setSuccessModelAdmin(true);
                    }
                  }}
                >
                  Send
                </Button>
              </Row>

              {showInput && (
                <>
                  <Row
                    className="gap-10 mtr-20 my-2 admin-forth-step copy-link"
                    onClick={() => {
                      if (inviteAdminTour) {
                        setInviteAdminTourIndex(4);
                        setShowInput(false);
                      }
                    }}
                  >
                    Invite your employee by sending them the link below.{" "}
                    <CopyToClipboard text={text}>
                      <Input
                        defaultValue={`${hostWithProtocol}/signup/?id=${companyID}`}
                        addonAfter={<img src={copy} alt="" onClick={onCopy} />}
                      />
                    </CopyToClipboard>
                  </Row>
                </>
              )}
            </>
          )}
        </Row>
      </Form>
    </Modal>
  );
}

export default AdminInvite;
