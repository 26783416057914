import {
  Divider,
  Row,
  Typography,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Col,
  message,
  Select,
  Menu,
  Dropdown,
  InputNumber,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import exportCSV from "assets/icons/exportcsv.svg";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  disableSubmitButton,
  emailPattern,
  formatCSVData,
  formatNumberUS,
  phonePattern,
  servicesNamePattern,
  tenDigit,
  urlPattern,
} from "utility/constant";
import { CSVLink } from "react-csv";
import {
  CreatePhysicalTherapyDetails,
  DeletePTCenter,
  ListPtCenterByName,
} from "utility/action";
import search from "utility/search";
import { DeleteService } from "utility/DeleteService";
import { handleStatusChange } from "utility/StatusChange";
import SuccessModel from "utility/SuccessModel";
import DeleteModal from "utility/DeleteModal";
import TableSkeleton from "utility/TableSkeleton";

export default function PTCenter() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ptData, setPTData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [dataSource, setDataSource] = useState(ptData);
  const [csvData, setCsvData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [form] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const { Option } = Select;

  const handleFormChange = disableSubmitButton(["PTCenterName"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const setPTRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      Action: row?.id,
      PTCentername: row?.ptName,
      Email: row?.ptEmail,
      Phone: row?.ptPhone,
      Website: row?.ptWebsite,
      createdAt: row?.createdAt,
    };
  };

  const columns = [
    {
      title: "Physical Therapy Name",
      dataIndex: "PTCentername",
    },
    {
      title: "Physical Therapy Website",
      dataIndex: "Website",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
    },
    {
      title: "Email",
      dataIndex: "Email",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/pt-center-detail" state={{ id: id }}>
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key={2}>
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId(id);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchptCenter = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListPtCenterByName(999);
      const data = response.map((row) => setPTRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setPTData(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchptCenter();
  }, [fetchptCenter]);

  const createPtCenter = async (values) => {
    try {
      setDeleteId("");
      setCreateLoading(true);
      const inputData = {
        ptName: values?.PTCenterName,
        searchByName: (values?.PTCenterName).toLowerCase(),
        ptEmail: values?.ptEmail,
        ptPhone: values?.ptPhone,
        ptWebsite: values?.ptWebsite,
        byName: "pt",
        activeOnPortal: false,
      };
      const response = await CreatePhysicalTherapyDetails(inputData);

      const mappedData = {};
      Object.entries(response).forEach(([key, value]) => {
        if (value !== null) {
          mappedData[key] = value;
        }
      });

      const tableData = {
        searchByName: mappedData?.searchByName,
        Action: mappedData?.id,
        createdAt: mappedData?.createdAt,
        Email: mappedData?.ptEmail,
        PTCentername: mappedData?.ptName,
        Phone: mappedData.ptPhone,
        Website: mappedData.ptWebsite,
      };

      const sortByCreatedAt = (data) =>
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setPTData((previous) => sortByCreatedAt([...previous, tableData]));
      setDataSource((previous) => sortByCreatedAt([...previous, tableData]));

      setIsModalOpen(false);
      form.resetFields();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setCreateLoading(false);
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = ["PTCentername", "Website", "Phone", "Speciality"];
    const filteredData = search(ptData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const labelKeyMap = {
    "PT Facility ID": "ptFacilityID",
    "PT Name": "ptName",
    "Google Map Link": "ptMapSource",
    "About PT": "aboutPT",
    Address: "ptAddLine1",
    City: "ptAddLine2",
    State: "ptAddLine3",
    "PT Zip Code": "ptZipCode",
    "PT Phone": "ptPhone",
    "PT Website": "ptWebsite",
    "Google Rating": "ptGoogleRating",
    "Facebook Rating": "ptFaceBookRating",
    "PT Type": "ptType",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const handleChange = async (value) => {
    try {
      setSelectedValue(value);
      setLoading(true);
      const filterData = await handleStatusChange(
        value,
        setPTRow,
        ListPtCenterByName,
        ptData
      );
      setDataSource(filterData);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deletePtCenter = async () => {
    try {
      setDeleteLoading(true);
      await DeleteService(deleteId, DeletePTCenter);
      setDeleteModelPrompt(false);
      setSuccessModel(true);
      setSelectedValue(null);
      fetchptCenter();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Physical Therapy
      </Typography.Title>
      <Divider />

      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Select
            allowClear
            placeholder="Status"
            style={{ width: 100 }}
            onChange={handleChange}
            value={selectedValue}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
          </Select>
          <CSVLink
            data={resData}
            headers={headers}
            filename={"PT Center.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Add New
          </Button>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Physical Therapy Center
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          onFinish={createPtCenter}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Physical therapy name"
                name="PTCenterName"
                rules={[
                  {
                    required: true,
                    message: "Required!",
                  },
                  {
                    pattern: servicesNamePattern,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Physical therapy website"
                name="ptWebsite"
                rules={[
                  {
                    pattern: urlPattern,
                    message: "Please input valid url",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Phone"
                name="ptPhone"
                rules={[
                  {
                    pattern: tenDigit,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  formatter={(value) => {
                    return formatNumberUS(value);
                  }}
                  parser={(value) => {
                    const cleanedValue = value.replace(/\D/g, "");
                    return cleanedValue.slice(0, 10);
                  }}
               
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Email"
                name="ptEmail"
                rules={[
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={24} xs={24} sm={24} className="center-btn">
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message={
          deleteId !== ""
            ? "PT Center Deleted Successfully"
            : "PT Center Added Successfully"
        }
        open={successModel}
      />

      <DeleteModal
        content="Are you sure you want to delete the physical therapy center?"
        open={deleteModelPrompt}
        onClick={deletePtCenter}
        buttonLoading={deleteLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        buttonText="Yes"
      />
    </>
  );
}
