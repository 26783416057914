import { Modal, Button, Space } from "antd";
import React, { useEffect, useState } from "react";
import deleteIcon from "assets/icons/delete.svg";

export default function DeleteModal(props) {
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(
    props?.open || false
  );

  useEffect(() => {
    setDeleteModelPrompt(props?.open || false);
  }, [props?.open]);

  const handleOk = () => {
    setDeleteModelPrompt(false);
    props.onOk();
  };

  const handleCancel = () => {
    setDeleteModelPrompt(false);
    props.onCancel();
  };

  //

  return (
    <Modal
      title={props.title}
      open={deleteModelPrompt}
      onOk={handleOk}
      onCancel={handleCancel}
      centered
      className="reward-success-modal"
      footer={null}
    >
      <img src={deleteIcon} alt="Delete Img" />
      <h6>{props.content}</h6>

      <Space>
        <Button
          type="primary"
          className="theme-btn"
          onClick={() => props.onClick()}
          style={{ width: "106px" }}
          loading={props?.buttonLoading}
        >
          {props.buttonText}
        </Button>
        <Button
          type="primary"
          className="theme-btn "
          onClick={handleCancel}
          style={{ width: "106px" }}
        >
          Cancel
        </Button>
      </Space>
    </Modal>
  );
}
