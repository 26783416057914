import { Button, Col, message, Row, Select, Upload } from "antd";
import { API } from "aws-amplify";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateChat } from "utility/action";
import Icon from "../Icon";
import attach from "assets/chat-images/attach.svg";
import SuccessModel from "utility/SuccessModel";

const ChatInput = ({
  showEmojis,
  setShowEmojis,
  newMessage,
  setNewMessage,
  submitNewMessage,
  userMessage,
  superAdmin,
  updateUserInfoInTransfer,
  closeChatInput,
}) => {
  const userData = useSelector((state) => state.userReducer?.userData);
  const [messageApi, contextHolder] = message.useMessage();
  const [successModel, setSuccessModel] = useState(false);

  const detectEnterPress = (e) => {
    const trimmedValue = newMessage.trim();

    if ((e.key === "Enter" || e.keyCode === 13) && trimmedValue !== "") {
      submitNewMessage(userMessage?.id);
    }
  };

  const navigate = useNavigate();
  const [showInput, setShowInput] = useState(false);
  const [asssignedAdmin, setAssignedAdmin] = useState(false);

  const closeChat = async () => {
    try {
      const inputData = {
        admin: userData.id,
        id: userMessage?.id,
        status: "CLOSED",
      };
      await UpdateChat(inputData);
      setSuccessModel(true);

      setTimeout(() => {
        setSuccessModel(false);
        closeChatInput();
        navigate(`/admin-chat/OPEN`);
      }, 2000);
    } catch (error) {
      console.log(error);
    }
  };

  const assignChat = async () => {
    try {
      const inputData = {
        admin: userData.id,
        id: userMessage?.id,
        status: "OPEN",
      };

      await UpdateChat(inputData);

      navigate(`/admin-chat/OPEN/${userMessage?.id}`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      userData?.id !== userMessage?.admin &&
      userMessage?.status === "UNASSIGNED"
    ) {
      setShowInput(false);
      setAssignedAdmin(false);
    } else if (
      userData?.id === userMessage?.admin &&
      userMessage?.status === "OPEN"
    ) {
      setShowInput(true);
      setAssignedAdmin(false);
    } else {
      setAssignedAdmin(true);
    }
  }, [userMessage?.admin]);

  const handleAdminChange = async (e) => {
    try {
      const inputData = {
        admin: e,
        id: userMessage?.id,
        status: "OPEN",
      };

      const res = await UpdateChat(inputData);
      updateUserInfoInTransfer(res);
    } catch (error) {
      console.log(error);
    }
  };

  const props = {
    onChange({ file }) {
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        const myInit = {
          body: {
            fileExtension: fileExtension,
          },
        };
        const linkToUpload = await API.post(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`,
          myInit
        );

        await axios.put(linkToUpload, file);
        setNewMessage(linkToUpload.split("?")[0]);

        onSuccess();
        await submitNewMessage(linkToUpload.split("?")[0]);
      } catch (error) {
        onError();
      }
    },
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("File must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <>
      {contextHolder}
      <div className="chat__input-wrapper">
        {showEmojis && (
          <button
            aria-label="Close emojis"
            onClick={() => setShowEmojis(false)}
          >
            <Icon id="cancel" className="chat__input-icon" />
          </button>
        )}

        {asssignedAdmin ? (
          <Row
            justify="center"
            className="chat-app-btn d-flex w-100"
            gutter={20}
          >
            {userMessage?.status === "CLOSED" ? (
              <p style={{ fontWeight: 700 }}>
                Conversation has been closed by:{" "}
                {userMessage?.adminDetails?.firstName +
                  " " +
                  userMessage?.adminDetails?.lastName}
              </p>
            ) : (
              <p style={{ fontWeight: 700 }}>
                This chat is assigned to:{" "}
                {userMessage?.adminDetails?.firstName +
                  " " +
                  userMessage?.adminDetails?.lastName}
              </p>
            )}
          </Row>
        ) : showInput ? (
          <>
            <input
              className="chat__input"
              placeholder="Type a message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={detectEnterPress}
            />

            <div className="pos-rel">
              <Upload
                {...props}
                showUploadList={false}
                className="basic-img-upload chat-upload"
                beforeUpload={beforeUpload}
              >
                <img src={attach} alt="" srcset="" />
              </Upload>
            </div>

            <button aria-label="Send message" onClick={newMessage?.trim() !== ""
                    ? () => submitNewMessage()
                    : console.log("Enter message to send")}>
              <Icon id="send" className="chat__input-icon" />
            </button>
          </>
        ) : (
          <Row
            justify="center"
            className="chat-app-btn d-flex w-100"
            gutter={20}
          >
            <Col>
              <Button className="theme-btn chat" onClick={assignChat}>
                Start Conversation
              </Button>
            </Col>
            <Col className="assign-admin">
              <Select
                allowClear
                placeholder="Transfer to"
                style={{ width: 200, marginBottom: "15px" }}
                onChange={handleAdminChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {superAdmin?.map((item) => (
                  <Select.Option
                    key={item?.id}
                    value={item?.id}
                    disabled={userData?.id === item?.id}
                  >
                    {item?.firstName + " " + item?.lastName}
                  </Select.Option>
                ))}
              </Select>
            </Col>
          </Row>
        )}
      </div>

      {asssignedAdmin ? (
        ""
      ) : showInput ? (
        <>
          <div className="d-flex justify-content-between w-100 chat-app-btn">
            <Button className="theme-btn chat" onClick={closeChat}>
              Mark as closed
            </Button>
            <div className="filter-dropdown-list chat-select">
              <Select
                allowClear
                placeholder="Transfer to"
                style={{ width: 130 }}
                onChange={handleAdminChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {superAdmin?.map((item) => (
                  <Select.Option
                    key={item?.id}
                    value={item?.id}
                    disabled={userData?.id === item?.id}
                  >
                    {item?.firstName + " " + item?.lastName}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <SuccessModel message="Chat closed successfully" open={successModel} />
    </>
  );
};

export default ChatInput;
