import {
  Row,
  Typography,
  Input,
  Button,
  Table,
  Modal,
  Form,
  Select,
  Col,
  message,
  Dropdown,
  Menu,
  Space,
} from "antd";
import React, { useEffect, useState } from "react";
import editIcon from "assets/icons/edit.svg";
import successDelete from "assets/icons/successDelete.svg";
import deleteIcon from "assets/icons/delete.svg";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import exportCSV from "assets/icons/exportcsv.svg";
import { API } from "aws-amplify";
import { disableSubmitButton, emailPattern } from "utility/constant";
import {
  ListCompanyRegis,
  ListEligibleMembers,
  ListEmployeesRegis,
  ListMembers,
} from "utility/action";
import { useCallback } from "react";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import search from "utility/search";
import TableSkeleton from "utility/TableSkeleton";
import SuccessModel from "utility/SuccessModel";

export default function NotRegisteredEmployees() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employers, setEmployers] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [unregiEmpChangeLoading, setUnregiEmpChangeLoading] = useState(false);
  const [unregiEmpLoading, setUnregiEmpLoading] = useState(false);
  const [unregisterEmp, setUnregisterEmp] = useState([]);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [indDeleteLoading, setIndDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [unregisterEmpDataSource, setUnregisterEmpDataSource] =
    useState(unregisterEmp);
  const [createLoading, setCreateLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const handleFormChange = disableSubmitButton(["employeeEmail", "companyID"])(
    form,
    setSubmitDisabled
  );

  //for deleting individual users
  const deleteUser = async (id) => {
    try {
      setIndDeleteLoading(true);
      const myInit = {
        body: {
          id: [deleteId[0]],
          type: deleteId[1],
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/delete_employee`, myInit);
      const newArray = unregisterEmpDataSource.filter(function (obj) {
        return obj.key !== deleteId;
      });
      setIndDeleteLoading(false);
      setUnregisterEmpDataSource(newArray);
      setDeleteId("");
      fetchNotRegisteredEmployee();
      setDeleteModelPrompt(false);
      setDeleteSuccess(true);
    } catch (error) {
      console.log(error);
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Deleting Member",
        duration: 5,
      });
      setIndDeleteLoading(false);
      setDeleteSuccess(false);
      setDeleteModelPrompt(false);
    }
  };

  const handleOk = () => {
    setIsModalOpen(false);
    form.resetFields();
    setSubmitDisabled(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    setSubmitDisabled(true);
    setDeleteModelPrompt(false);
    setDeleteId("");
  };

  const setNotRegiEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      group: row?.group,
      Username: row?.emailID ? row?.emailID : "-",
      searchByName: row?.searchByName,
      Employer: row?.companyID?.name ? row?.companyID?.name : "-",
      FirstName: row?.profile?.firstName
        ? row?.profile?.firstName
        : row?.firstName
        ? row?.firstName
        : "-",
      LastName: row?.profile?.lastName
        ? row?.profile?.lastName
        : row?.lastName
        ? row?.lastName
        : "-",
      Role:
        row?.userType === "CORPORATE_ADMIN" && row.group !== "Member"
          ? "ADMIN"
          : row?.userType === "INDIVIDUAL" && row.group !== "Member"
          ? "INDIVIDUAL"
          : row?.userType === "CORPORATE" && row.group !== "Member"
          ? "EMPLOYEE"
          : row.group === "Member"
          ? "DEPENDENT"
          : "-",
    };
  };

  const setEligibleMember = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Username: row?.email ? row?.email : "-",
      searchByName: row?.searchByName,
      Payer: row?.payer,
      Employer: row?.GroupName ? row?.GroupName : "-",
      FirstName: row?.memberFirstName ? row?.memberFirstName : "-",
      LastName: row?.memberLastName ? row?.memberLastName : "-",
      Role: row?.payer === "HPI" ? "MEMBER" : "-",
    };
  };

  const fetchNotRegisteredEmployee = useCallback(async () => {
    setUnregiEmpLoading(true);
    const filterNotRegistered = {
      lastLogin: { attributeExists: false },
    };
    const filterEligible = {
      registered: { eq: false },
      invited: { eq: false },
    };

    const [notRegisteredResponse, eligibleResponse, notRegisteredDependent] =
      await Promise.all([
        ListEmployeesRegis(filterNotRegistered, 999),
        ListEligibleMembers(filterEligible, 999),
        ListMembers(filterNotRegistered, 999),
      ]);

    const notRegisteredData = notRegisteredResponse.map((row) =>
      setNotRegiEmployeesRow(row)
    );
    const notRegDependentData = notRegisteredDependent.map((row) =>
      setNotRegiEmployeesRow(row)
    );
    const eligibleData = eligibleResponse.map((row) => setEligibleMember(row));
    const mergedData = [
      ...notRegisteredData,
      ...eligibleData,
      ...notRegDependentData,
    ];

    setUnregisterEmp(mergedData);
    setUnregisterEmpDataSource(mergedData);
    setUnregiEmpLoading(false);
    setCsvData(mergedData);
  }, []);

  const fetchEmployersData = useCallback(async () => {
    await ListCompanyRegis()
      .then((response) => {
        setEmployers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchNotRegisteredEmployee();
  }, [fetchNotRegisteredEmployee]);

  useEffect(() => {
    fetchEmployersData();
  }, [fetchEmployersData]);

  const handleUnregiEmployerChange = async (value) => {
    setUnregiEmpChangeLoading(true);
    if (!value) {
      setUnregisterEmpDataSource(unregisterEmp);
    } else {
      const filterNotRegistered = {
        lastLogin: { attributeExists: false },
        companyRegiEmployeeId: { contains: value },
      };
      const filterEligible = {
        registered: { eq: false },
        invited: { eq: false },
        companyID: { contains: value },
      };

      const [notRegisteredResponse, eligibleResponse] = await Promise.all([
        ListEmployeesRegis(filterNotRegistered),
        ListEligibleMembers(filterEligible),
      ]);

      const notRegisteredData = notRegisteredResponse.map((row) =>
        setNotRegiEmployeesRow(row)
      );
      const eligibleData = eligibleResponse.map((row) =>
        setEligibleMember(row)
      );
      const mergedData = [...notRegisteredData, ...eligibleData];
      setUnregisterEmpDataSource(mergedData);
    }
    setUnregiEmpChangeLoading(false);
  };

  const handleUnregiEmpSearch = async (e) => {
    const currValue = e.target.value;
    const searchFields = [
      "Username",
      "Employer",
      "FirstName",
      "LastName",
      "Role",
    ];
    const filteredData = search(unregisterEmp, currValue, searchFields);
    setUnregisterEmpDataSource(filteredData);
  };

  const createEmployee = async (values) => {
    try {
      setCreateLoading(true);
      const myInit = {
        body: {
          companyId: values?.companyID,
          email: values?.employeeEmail?.toLowerCase(),
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      };

      await API.post("bazarhealthREST", `/superAdmin/invite_employee`, myInit);
      // fetchNotRegisteredEmployee();
      setCreateLoading(false);
      form.resetFields();
      setIsModalOpen(false);
      setSubmitDisabled(true);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: " An account with the given email already exists.",
      });
      setCreateLoading(false);
    }
  };

  const column = [
    {
      title: "Member Email",
      dataIndex: "Username",
    },
    {
      title: "Employer",
      dataIndex: "Employer",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "Role",
      dataIndex: "Role",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link
                    rel="noopener noreferrer"
                    to="/member-detail"
                    state={{
                      id: [id, record?.Payer],
                      member: record?.group,
                    }}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                {/* Not Giving delete permission for HPI */}
                {console.log(record)}
                <Menu.Item key="2">
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId([id, record?.Role]);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const headers = [
    { label: "Member Email", key: "email" },
    { label: "Employer", key: "Employer" },
    { label: "First Name", key: "FirstName" },
    { label: "Last Name", key: "LastName" },
    { label: "Role", key: "Role" },
  ];

  const csvExp = csvData.map((row) => ({
    email: row?.Username,
    Employer: row?.Employer ? row?.Employer : "-",
    FirstName: row?.FirstName ? row?.FirstName : "-",
    LastName: row?.LastName ? row?.LastName : "-",
    Role: row?.Role,
  }));

  return (
    <div>
      {contextHolder}
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleUnregiEmpSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 filter-dropdown-list">
          <Select
            // className="filter-border-btn"
            allowClear
            placeholder="Select Employer"
            style={{ width: 200 }}
            onChange={handleUnregiEmployerChange}
          >
            {employers.map((item) => (
              <Select.Option value={item.id}>{`${item.name}`}</Select.Option>
            ))}
          </Select>

          <CSVLink
            data={csvExp}
            headers={headers}
            filename={"Non Registered Members.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button
            className="blue-btn"
            onClick={showModal}
            style={{ marginLeft: 0, height: "inherit" }}
          >
            Add Member
          </Button>
        </Row>
      </Row>

      {unregiEmpLoading || unregiEmpChangeLoading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={column}
          dataSource={unregisterEmpDataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Member
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={createEmployee}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Member Email"
                name="employeeEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input member email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Employer"
                name="companyID"
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select allowClear placeholder="Select Employer">
                  {employers.map((item) => (
                    <Select.Option
                      value={item.id}
                    >{`${item.name}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Last lame"
                name="lastName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteModelPrompt}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal "
        footer={null}
      >
        <img src={deleteIcon} alt="Delete Img" />
        <h6>Are you sure ?</h6>
        <p>If Yes, it will delete an member permanently.</p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={deleteUser}
            loading={indDeleteLoading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleCancel}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>
      <SuccessModel
        message="Delete Successful!"
        open={deleteSuccess}
        imageSrc={successDelete}
      />
    </div>
  );
}
