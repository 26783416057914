import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import successIcon from "assets/icons/Success.svg";

export default function SuccessModel(props) {
  const [successModel, setSuccessModel] = useState(props?.open || false);

  useEffect(() => {
    setSuccessModel(props?.open || false);
  }, [props?.open]);

  const handleOk = () => {
    setSuccessModel(false);
  };

  const handleCancel = () => {
    setSuccessModel(false);
  };

  return (
    <Modal
      title="Basic Modal"
      open={successModel}
      onOk={handleOk}
      centered
      keyboard={false}
      onCancel={handleCancel}
      className={`reward-success-modal ${props?.className}`}
    >
      {props?.imageSrc ? (
        <img src={props?.imageSrc} alt="Success Img" />
      ) : (
        <img src={successIcon} alt="Success Img" />
      )}

      <h6 className="mt-20">{props?.message}</h6>
    </Modal>
  );
}
