import {
  Avatar,
  Button,
  Card,
  Col,
  Collapse,
  Divider,
  List,
  Row,
  Skeleton,
  Tag,
  Typography,
} from "antd";
import Container from "commons/Container";
import React, { useCallback, useEffect, useState } from "react";
import search from "assets/icons/searchBanner.svg";
import doctorIcon from "assets/search-icons/doctor.svg";
import surgery from "assets/search-icons/surgery.svg";
import imaging from "assets/search-icons/imaging.svg";
import infusion from "assets/search-icons/infusion.svg";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import locationIcon from "assets/search-icons/location.svg";
import lab from "assets/search-icons/lab.svg";
import pt from "assets/search-icons/pt.svg";
import procedureIC from "assets/search-icons/procedures.svg";
import noresult from "assets/search-icons/noresult.svg";
import "./GlobalSearch.less";
import "./GlobalSearchResponsive.less";

import { RightOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";
import { globalSearch } from "graphql/queries";
import { API } from "aws-amplify";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export default function GlobalSearch(props) {
  const [tagSearch, setTagSearch] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [doc, setDoc] = useState([]);
  const [surgeryData, setSurgeryData] = useState([]);
  const [imagingData, setImagingData] = useState([]);
  const [infusionData, setInfusionData] = useState([]);
  const [ptData, setPTData] = useState([]);
  const [labData, setLabData] = useState([]);
  const [procedure, setProcedure] = useState([]);
  const { Panel } = Collapse;
  const userData = useSelector((state) => state.userReducer?.userData);

  const locationCoordinates = userData?.profile?.location;

  const [loadingState, setLoadingState] = useState({
    doctors: false,
    surgeryCenters: false,
    imgagingCenters: false,
    infusionCenters: false,
    labs: false,
    ptCenters: false,
    procedures: false,
  });

  const [hideView, setHideView] = useState({
    doctors: false,
    surgeryCenters: false,
    imgagingCenters: false,
    infusionCenters: false,
    labs: false,
    ptCenters: false,
    procedures: false,
  });

  const handleClick = async (values) => {
    props.setSearchValue(values);
  };

  const tags = [
    "ankle",
    "foot",
    "orthopaedic",
    "spine",
    "back",
    "pain management",
    "mri",
    "mammogram",
    "hand",
    "wrist",
    "podiatrist",
    "stomach",
    "general surgeon",
    "head",
    "neck",
    "elbow",
    "hip",
    "ophthalmologist",
    "knee",
    "shoulder",
  ];

  const createHandleViewMore = (indexParam, lengthParam, setData, resData) => {
    return async () => {
      const inputData = {
        location: {
          lat: locationCoordinates?.lat,
          lon: locationCoordinates?.lon,
        },
        query: props?.searchValue,
        index: indexParam,
        nextToken: lengthParam?.length,
        size: 5,
      };
      try {
        setLoadingState({
          [resData]: true,
        });
        const res = await API.graphql({
          query: globalSearch,
          variables: inputData,
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const newData = res?.data?.globalSearch?.[resData]?.data || [];
        const newTotal = res?.data?.globalSearch?.[resData]?.total || "";

        if (!Array.isArray(newData)) {
          console.error("New data is not an array:", newData);
          return;
        }

        if (newData?.length === 0) {
          setHideView({
            [resData]: true,
          });
        } else {
          setData({
            data: [...(lengthParam || []), ...newData],
            total: newTotal,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingState(() => ({
          [resData]: false,
        }));
      }
    };
  };

  const handleViewMoreDoc = createHandleViewMore(
    "doctorprofile",
    doc?.data,
    setDoc,
    "doctors"
  );

  const handleViewMoreSC = createHandleViewMore(
    "surgerycenterdetails",
    surgeryData?.data,
    setSurgeryData,
    "surgeryCenters"
  );

  const handleViewMoreImaging = createHandleViewMore(
    "imgagingcenterdetails",
    imagingData?.data,
    setImagingData,
    "imgagingCenters"
  );

  const handleViewMoreInfusion = createHandleViewMore(
    "infusioncenter",
    infusionData?.data,
    setInfusionData,
    "infusionCenters"
  );

  const handleViewMoreLab = createHandleViewMore(
    "labdetails",
    labData?.data,
    setLabData,
    "labs"
  );

  const handleViewMorePT = createHandleViewMore(
    "physicaltherapydetails",
    ptData?.data,
    setPTData,
    "ptCenters"
  );

  const handleViewMoreProcedure = createHandleViewMore(
    "procedures",
    procedure?.data,
    setProcedure,
    "procedures"
  );

  const generateLoadMore = (data, loadingState, handleViewMore, totalData) => {
    const dataInt = parseInt(data, 10);
    return !loadingState && dataInt > 3 && dataInt !== totalData ? (
      <div className="show-more">
        <Button className="load-more-btn" onClick={handleViewMore}>
          View More
        </Button>
      </div>
    ) : null;
  };

  const loadMore = generateLoadMore(
    doc?.total,
    loadingState.doctors,
    handleViewMoreDoc,
    doc?.data?.length
  );

  const loadMoreSC = generateLoadMore(
    surgeryData?.total,
    loadingState.surgeryCenters,
    handleViewMoreSC,
    surgeryData?.data?.length
  );

  const loadMoreImaging = generateLoadMore(
    imagingData?.total,
    loadingState.imgagingCenters,
    handleViewMoreImaging,
    imagingData?.data?.length
  );

  const loadMoreIC = generateLoadMore(
    infusionData?.total,
    loadingState.infusionCenters,
    handleViewMoreInfusion,
    infusionData?.data?.length
  );

  const loadMorePT = generateLoadMore(
    ptData?.total,
    loadingState.ptCenters,
    handleViewMorePT,
    ptData?.data?.length
  );

  const loadMoreProcedure = generateLoadMore(
    procedure?.total,
    loadingState.procedures,
    handleViewMoreProcedure,
    procedure?.data?.length
  );

  const loadMoreLab = generateLoadMore(
    labData?.total,
    loadingState.labs,
    handleViewMoreLab,
    labData?.data?.length
  );

  const panelStyle = {
    marginBottom: 24,
    border: "none",
  };

  function debounce(func, delay) {
    let timerId;
    return function (...args) {
      clearTimeout(timerId);
      timerId = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const handleGlobalSearch = useCallback(
    debounce(async (searchValue) => {
      setDoc([]);
      setSurgeryData([]);
      setImagingData([]);
      setInfusionData([]);
      setLabData([]);
      setPTData([]);
      setProcedure([]);

      const inputData = {
        location: {
          lat: locationCoordinates?.lat,
          lon: locationCoordinates?.lon,
        },
        query: searchValue,
      };
      setHideView(false);
      setEmptyData(false);
      if (searchValue === "" || searchValue === null) {
        setTagSearch(false);
      }
      try {
        if (searchValue) {
          const res = await API.graphql({
            query: globalSearch,
            variables: inputData,
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });

          const isEmptyResponse =
            res.data.globalSearch.doctors?.data === null &&
            res.data.globalSearch.imgagingCenters?.data === null &&
            res.data.globalSearch.infusionCenters?.data === null &&
            res.data.globalSearch.labs?.data === null &&
            res.data.globalSearch.procedures?.data === null &&
            res.data.globalSearch.ptCenters?.data === null &&
            res.data.globalSearch.surgeryCenters?.data === null;

          if (isEmptyResponse) {
            setEmptyData(true);
          } else {
            setDoc(res?.data?.globalSearch?.doctors);
            setSurgeryData(res?.data?.globalSearch?.surgeryCenters);
            setImagingData(res?.data?.globalSearch?.imgagingCenters);
            setInfusionData(res?.data?.globalSearch?.infusionCenters);
            setLabData(res?.data?.globalSearch?.labs);
            setPTData(res?.data?.globalSearch?.ptCenters);
            setProcedure(res?.data?.globalSearch?.procedures);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingState({
          doctors: false,
          surgeryCenters: false,
          imgagingCenters: false,
          infusionCenters: false,
          labs: false,
          ptCenters: false,
          procedures: false,
        });
      }
    }, 500),
    []
  );

  useEffect(() => {
    handleGlobalSearch(props.searchValue);
  }, [handleGlobalSearch, props.searchValue]);

  useEffect(() => {
    setLoadingState({
      doctors: true,
      surgeryCenters: true,
      imgagingCenters: true,
      infusionCenters: true,
      labs: true,
      ptCenters: true,
      procedures: true,
    });
  }, [props.searchValue]);

  const renderList = ({
    imgSrc,
    title,
    data,
    loading,
    hideView,
    loadMoreFun,
    servicePhoto,
    serviceTitle,
    serviceFbRating,
    serviceGoogleRating,
    navigateTo,
    isProcedureList,
    totalLen,
  }) => {
    return (
      <div className="search-collaps">
        <Collapse
          className="doctor-surgery search-list"
          bordered={false}
          expandIconPosition="end"
          defaultActiveKey={["1"]}
          expandIcon={({ isActive }) =>
            isActive ? <UpOutlined /> : <DownOutlined />
          }
        >
          <Panel
            header={
              <div className="search-heading">
                <Typography.Title level={5}>
                  <img src={imgSrc} alt={title} />
                  {title}
                </Typography.Title>
                <div className="search-result">
                  {totalLen !== undefined &&
                    `${totalLen} ${totalLen === "1" ? "Result" : "Results"}`}
                </div>
              </div>
            }
            key={1}
            style={panelStyle}
          >
            <>
              {loadingState.doctors &&
              loadingState.surgeryCenters &&
              loadingState.imgagingCenters &&
              loadingState.infusionCenters &&
              loadingState.labs &&
              loadingState.ptCenters &&
              loadingState.procedures ? (
                <Skeleton style={{ marginTop: 20 }} />
              ) : (
                <List
                  className="demo-loadmore-list"
                  loading={loading}
                  itemLayout="horizontal"
                  loadMore={!hideView && loadMoreFun}
                  dataSource={data}
                  renderItem={(item) => (
                    <>
                      {isProcedureList ? (
                        <Link
                          to={`/user-procedure-detail/${encodeURIComponent(
                            item?.searchByName
                          )}`}
                          className="text-black"
                        >
                          <List.Item>
                            <a href="" className="w-100">
                              <List.Item.Meta
                                avatar={<Avatar src={item?.[servicePhoto]} />}
                                title={<p>{item?.[serviceTitle]}</p>}
                                description={
                                  <div className="d-flex align-center search-result-p mobile-procedure-r">
                                    <p
                                      style={{
                                        width: "150px",
                                        marginRight: "20px",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Market Price:
                                      <span>${item?.marketPrice}</span>
                                    </p>

                                    <p
                                      style={{
                                        width: "140px",
                                        marginRight: "13px",
                                        color: "#00406A",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      Delphi Price:
                                      <span>${item?.bazarPrice}</span>
                                    </p>
                                  </div>
                                }
                              />
                            </a>
                            <div>
                              <RightOutlined />
                            </div>
                          </List.Item>
                        </Link>
                      ) : (
                        <Link
                          to={navigateTo}
                          state={{ id: item?.id, search: true }}
                          className="w-100"
                        >
                          <List.Item>
                            <a href="" className="w-100">
                              <List.Item.Meta
                                avatar={<Avatar src={item?.[servicePhoto]} />}
                                title={<p>{item?.[serviceTitle]}</p>}
                                description={
                                  <div className="d-flex align-center search-result-p">
                                    <p
                                      style={{
                                        width: "30px",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <img
                                        src={facebook}
                                        alt="facebook Icon"
                                        style={{ height: "13px" }}
                                      />
                                      {item?.[serviceFbRating] === 0 ||
                                      item?.[serviceFbRating] === null ? (
                                        "NA"
                                      ) : (
                                        <>{item?.[serviceFbRating]} </>
                                      )}
                                    </p>

                                    <p
                                      style={{
                                        width: "30px",
                                        marginRight: "20px",
                                      }}
                                    >
                                      <img
                                        src={google}
                                        alt="facebook Icon"
                                        style={{ height: "13px" }}
                                      />{" "}
                                      {item?.[serviceGoogleRating] === 0 ||
                                      item?.[serviceGoogleRating] === null ? (
                                        "NA"
                                      ) : (
                                        <>{item?.[serviceGoogleRating]} </>
                                      )}
                                    </p>

                                    <p
                                      className="single-address-line"
                                      style={{
                                        width: "160px",
                                        marginRight: "0px",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="Address Icon"
                                        style={{ height: "13px" }}
                                      />{" "}
                                      &nbsp;
                                      {parseFloat(item?.distance).toFixed(2) !==
                                      "0.00"
                                        ? `${parseFloat(item?.distance).toFixed(
                                            2
                                          )} Miles from you`
                                        : item?.distance === null
                                        ? "-"
                                        : "Near You"}
                                    </p>
                                  </div>
                                }
                              />
                            </a>
                            <div>
                              <RightOutlined />
                            </div>
                          </List.Item>
                        </Link>
                      )}
                    </>
                  )}
                />
              )}
            </>
          </Panel>
        </Collapse>
      </div>
    );
  };

  return (
    <Container>
      <Card className="mobile-card mt-10 mb-20 rounded-card">
        {props?.searchValue || tagSearch ? (
          <>
            {emptyData ? (
              <>
                <Row justify="center mt-30 no-result">
                  <Col span={24} md={24} className="centered-col">
                    <img src={noresult} alt="sss" />
                    <h5 className="mt-30">
                      No result found <br />
                      <span>We could’t find what you are looking for.</span>
                    </h5>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                {loadingState.doctors &&
                loadingState.surgeryCenters &&
                loadingState.imgagingCenters &&
                loadingState.infusionCenters &&
                loadingState.labs &&
                loadingState.ptCenters &&
                loadingState.procedures ? (
                  <>
                    {renderList({
                      imgSrc: doctorIcon,
                      title: "Doctors",
                      loading: loadingState.doctors,
                    })}

                    {renderList({
                      imgSrc: surgery,
                      title: "Surgery Centers",
                      loading: loadingState.surgeryCenters,
                    })}

                    {renderList({
                      imgSrc: imaging,
                      title: "Imaging Centers",
                      loading: loadingState.imgagingCenters,
                    })}

                    {renderList({
                      imgSrc: infusion,
                      title: "Infusion Centers",
                      loading: loadingState.infusionCenters,
                    })}

                    {renderList({
                      imgSrc: lab,
                      title: "Lab Centers",
                      loading: loadingState.labs,
                    })}

                    {renderList({
                      imgSrc: pt,
                      title: "Physical Therapy Centers",
                      loading: loadingState.ptCenters,
                    })}

                    {renderList({
                      imgSrc: procedureIC,
                      title: "Procedures",
                      loading: loadingState.procedures,
                    })}
                  </>
                ) : (
                  <>
                    {doc?.data !== null &&
                      renderList({
                        imgSrc: doctorIcon,
                        title: "Doctors",
                        data: doc?.data,
                        loading: loadingState.doctors,
                        hideView: hideView.doctors,
                        loadMoreFun: loadMore,
                        servicePhoto: "doctorProfilePhoto",
                        serviceTitle: "doctorName",
                        serviceFbRating: "facebookRating",
                        serviceGoogleRating: "googleRating",
                        navigateTo: "/doctor-profile",
                        totalLen: doc?.total,
                      })}

                    {surgeryData?.data !== null &&
                      renderList({
                        imgSrc: surgery,
                        title: "Surgery Centers",
                        data: surgeryData?.data,
                        loading: loadingState.surgeryCenters,
                        hideView: hideView.surgeryCenters,
                        loadMoreFun: loadMoreSC,
                        servicePhoto: "surgeryCenterProfilePhoto",
                        serviceTitle: "surgeryCenterName",
                        serviceFbRating: "surgeryCenterFaceBookRating",
                        serviceGoogleRating: "surgeryCenterGoogleRating",
                        navigateTo: "/surgical-center-detail",
                        totalLen: surgeryData?.total,
                      })}

                    {imagingData?.data !== null &&
                      renderList({
                        imgSrc: imaging,
                        title: "Imaging Centers",
                        data: imagingData?.data,
                        loading: loadingState.imgagingCenters,
                        hideView: hideView.imgagingCenters,
                        loadMoreFun: loadMoreImaging,
                        servicePhoto: "icProfilePhoto",
                        serviceTitle: "icOfficeName",
                        serviceFbRating: "icFaceBookRating",
                        serviceGoogleRating: "icGoogleRating",
                        navigateTo: "/imaging-center-profile",
                        totalLen: imagingData?.total,
                      })}

                    {infusionData?.data !== null &&
                      renderList({
                        imgSrc: infusion,
                        title: "Infusion Centers",
                        data: infusionData?.data,
                        loading: loadingState.infusionCenters,
                        hideView: hideView.infusionCenters,
                        loadMoreFun: loadMoreIC,
                        servicePhoto: "infusionCenterPhotos",
                        serviceTitle: "name",
                        serviceFbRating: "facebookRating",
                        serviceGoogleRating: "googleRating",
                        navigateTo: "/up-infusion-center-details",
                        totalLen: infusionData?.total,
                      })}

                    {labData?.data !== null &&
                      renderList({
                        imgSrc: lab,
                        title: "Lab Centers",
                        data: labData?.data,
                        loading: loadingState.labs,
                        hideView: hideView.labs,
                        loadMoreFun: loadMoreLab,
                        servicePhoto: "labProfilePhoto",
                        serviceTitle: "labName",
                        serviceFbRating: "labFaceBookRating",
                        serviceGoogleRating: "labGoogleRating",
                        navigateTo: "/lab-center-details",
                        totalLen: labData?.total,
                      })}

                    {ptData?.data !== null &&
                      renderList({
                        imgSrc: pt,
                        title: "Physical Therapy Centers",
                        data: ptData?.data,
                        loading: loadingState.ptCenters,
                        hideView: hideView.ptCenters,
                        loadMoreFun: loadMorePT,
                        servicePhoto: "ptProfilePhoto",
                        serviceTitle: "ptName",
                        serviceFbRating: "ptFaceBookRating",
                        serviceGoogleRating: "ptGoogleRating",
                        navigateTo: "/physical-therapy-profile",
                        totalLen: ptData?.total,
                      })}

                    {procedure?.data !== null &&
                      renderList({
                        imgSrc: procedureIC,
                        title: "Procedures",
                        data: procedure?.data,
                        loading: loadingState.procedures,
                        hideView: hideView.procedures,
                        loadMoreFun: loadMoreProcedure,
                        servicePhoto: "image",
                        serviceTitle: "name",
                        serviceFbRating: "",
                        serviceGoogleRating: "",
                        navigateTo: "",
                        isProcedureList: true,
                        totalLen: procedure?.total,
                      })}
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <div className="global-search-wrapper">
              <Row justify="space-between" align="middle" gutter={[0, 15]}>
                <div>
                  <p>Search Suggestions</p>
                </div>
              </Row>
              <div className="search-suggestions">
                {tags.map((tag, index) => (
                  <Tag key={index} value={tag} onClick={() => handleClick(tag)}>
                    {tag}
                  </Tag>
                ))}
              </div>

              <Divider className="mt-30" />

              <Row justify="center mt-30">
                <Col span={24} md={24} className="centered-col">
                  <img src={search} alt="sss" />
                  <h5 className="mt-10">
                    Here are some points on how you can
                    <span className="search-head"> search on our platform</span>
                  </h5>
                </Col>
              </Row>

              <Row
                className="mb-20 d-flex search-banner"
                justify="space-between"
              >
                <Col
                  span={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="onboard-image "
                >
                  <span> 1. Clear Words: </span>
                  <br />
                  <p>
                    Start with a clear and specific query. The more precise your
                    question or keywords, the better the search results.
                  </p>
                  <div className="mt-20">
                    <span> 2. Explore Related Content: </span>
                    <br />
                    <p>
                    If you find a useful result, check for suggested links that might provide further information on your search.
                    </p>
                  </div>
                </Col>

                <Col
                  xs={23}
                  sm={23}
                  md={1}
                  lg={1}
                  xl={1}
                  className="centered-col search-divider"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1"
                    height="144"
                    viewBox="0 0 1 144"
                    fill="none"
                  >
                    <path d="M0.499994 143.323L0.5 0.32251" stroke="#C6C6C6" />
                  </svg>
                </Col>

                <Col
                  span={11}
                  md={11}
                  xs={24}
                  sm={24}
                  className="onboard-image responsive-mt-20"
                >
                  <span className=""> 3. Search by Location: </span>
                  <br />
                  <p>
                    Searching by location will limit results to a specific
                    location if you're looking for the closest service.
                  </p>
                  <div className="mt-20">
                    <span> 4. Search by Rating: </span>
                    <br />
                    <p>
                    Searching by ratings will narrow down results to only show you the highest rated services.
                    </p>
                  </div>
                </Col>
              </Row>
            </div>
          </>
        )}
      </Card>
    </Container>
  );
}
