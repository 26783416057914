// TourStepContent.js
import { Space } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { setTourSteps } from "store/actions/tourSteps";
import { END_TOUR } from "./constant";
import closeImage from "assets/img/close.svg";

const HomeTourStep = ({
  heading,
  details,
  numbers,
  previousClick,
  nextClick,
  hidePrevBtn,
  closeTour,
  index,
  isFinishBtn,
}) => {

  const dispatch = useDispatch();
  const endTour = () => {
    closeTour(false);
    index(0);
    dispatch(setTourSteps(END_TOUR));
  };

  return (
    <>
      <div className="joyride-tour-heading">
        <div>
          <b>{heading}</b>
        </div>
        <div className="joyride-tour-end" onClick={endTour}>
          <img src={closeImage} />
        </div>
      </div>
      <div className="joyride-tour-details">{details}</div>
      <div className="joyride-tour-numbers">
        <div>
          {numbers.currentStep}
          <span className="joyride-tour-numbers-disable">
            /{numbers.totalSteps}
          </span>{" "}
        </div>

        <Space>
          {!hidePrevBtn && (
            <div className="joyride-tour-navbtn-prev" onClick={previousClick}>
              Previous
            </div>
          )}

          <div
            className="joyride-tour-navbtn"
            onClick={isFinishBtn ? endTour : nextClick}
          >
            {isFinishBtn ? "Finish" : "Next"}
          </div>
        </Space>
      </div>
    </>
  );
};

export default HomeTourStep;
