import React, { useState } from "react";
import {
  Row,
  DatePicker,
  Col,
  Input,
  Form,
  Radio,
  Select,
  Button,
  message,
  Grid,
} from "antd";
import { Analytics, API } from "aws-amplify";
import { createIcAppointment } from "graphql/mutations";
import { useSelector } from "react-redux";
import { aboutPattern, servicesNamePattern } from "utility/constant";
import SuccessModel from "utility/SuccessModel";
export default function IcAppointmnet(props) {
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [value, setValue] = useState(1);
  const [icValue, setICValue] = useState(1);
  const [followupValue, setFollowUpValue] = useState(1);
  const [successModel, setSuccessModel] = useState(false);
  const [selfSelect, setSelfSelect] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [createLoading, setCreateLoading] = useState(false);
  const [dob, setDob] = useState("");
  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const { Option } = Select;
  const { TextArea } = Input;
  const [form] = Form.useForm();
  const isModal = props?.isModal;
  const userData = useSelector((state) => state?.userReducer?.userData);
  const specialityOptions = useSelector(
    (state) => state?.specialityReducer?.specialityOption
  );
  const icName = props?.data?.icOfficeName
    ? props?.data?.icOfficeName
    : props?.icName;

  const icData = props?.data ? props?.data : props?.icData;

  const handleChangeApoointment = (value) => {
    if (value === "My Self") {
      setIsOtherSelected(false);
      setSelfSelect(true);
      form.setFieldsValue({
        name: userData?.profile?.firstName + " " + userData?.profile?.lastName,
      });
    } else if (value === "Other") {
      setIsOtherSelected(true);
      setSelfSelect(false);
      form.setFieldsValue({
        name: "",
      });
    }
    setAppointmentFor(value);
  };

  const onRadioChangeRef = (e) => {
    setValue(e.target.value);
  };

  const onRadioChangeIC = (e) => {
    setICValue(e.target.value);
  };

  const onRadioChangeFollow = (e) => {
    setFollowUpValue(e.target.value);
  };

  const onChangeDatepickerDOB = (date, dateString) => {
    setDob(dateString);
  };

  const onFinish = async (values) => {
    try {
      setCreateLoading(true);
      const inputData = {
        input: {
          userData: {
            id: userData?.id,
            name:
              userData?.profile?.firstName + " " + userData?.profile?.lastName,
            otherName: values?.name,
            appointmentFor: appointmentFor,
            preferredTime: values?.preferredTime,
            relationship: null,
            email: values?.email ? values?.email : userData?.profile?.email,
            phone: values?.phone ? values?.phone : userData?.profile?.phone,
            dob: userData?.profile?.dob,
            otherDob: dob === "" ? null : dob,
            insuranceCarrier: userData?.profile?.insuranceCarrier,
            memberID: userData?.profile?.memberID,
          },
          icData: {
            id: icData?.id ? icData?.id : icData?.icID,
            name: icData?.icOfficeName ? icData?.icOfficeName : icData?.icName,
            email: icData?.icEmail,
            phone: icData?.icOfficePhone
              ? icData?.icOfficePhone
              : icData?.icPhone,
            fax: icData?.icOfficeFax ? icData?.icOfficeFax : icData?.icFax,
            speciality: specialityOptions[0],
          },
          userID: userData?.id,
          status: true,
          bodyPartToBeScanned: values?.bodyPartToBeScanned,
          description: values.description,
          referralOfDoctor: values?.referralOfDoctor,
          doctorName: values?.doctorName,
          firstVisit: values?.firstVisit,
          followUpAppointment: values?.followUpAppointment,
          viewed: false,
        },
      };

      await API.graphql({
        query: createIcAppointment,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      Analytics.record({
        name: "IC Appointment",
        attributes: {
          name: icData?.icOfficeName ? icData?.icOfficeName : icData?.icName,
        },
        immediate: true,
      });
      form.resetFields();
      if (isModal) {
        props?.onCloseModal();
      }
      setSelfSelect(false);
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log("error", error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const handleClick = () => {
    props.onUpdateTourIndex(3);
  };

  console.log("props/", props?.tourIndex);

  return (
    <>
      {contextHolder}
      <Form
        name="doctor"
        onFinish={onFinish}
        autoComplete="off"
        id="appForm"
        layout="vertical"
        form={form}
      >
        <div className="request-appointment">
          {isModal ? (
            <div className="text-center">
              <h6 className="sub-header-font">Appointment Form</h6>
              <span className="fs-1 p-font">
                Request an Appointment with: {icName}
              </span>
            </div>
          ) : screens.xs ? (
            ""
          ) : (
            <h6 className="sub-header-font">
              Request an appointment with: {icName}
            </h6>
          )}

          <Row className=" mt-20">
            <Col md={isModal ? 22 : 24} offset={isModal ? 1 : 0}>
              <div className="reward-input-box mt-20 r-mt-10">
                <Row
                  gutter={20}
                  className={props?.className}
                  onClick={handleClick}
                >
                  <Col md={24}>
                    <Row gutter={20}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Who is the appointment for?
                            </span>
                          }
                          name="appointmentFor"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option",
                            },
                          ]}
                        >
                          <Select
                            className="form-control"
                            defaultValue="Select any option"
                            onChange={handleChangeApoointment}
                            name="appointmentFor"
                            id="appointmentFor"
                            options={[
                              {
                                value: "My Self",
                                label: "My Self",
                              },
                              {
                                value: "Other",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={<span className="p-font">Name</span>}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your name",
                            },
                            {
                              pattern: servicesNamePattern,
                              message: "Please input a valid name",
                            },
                          ]}
                        >
                          <Input disabled={selfSelect} />
                        </Form.Item>
                      </Col>
                      {isOtherSelected && (
                        <>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label={<span className="p-font">DOB</span>}
                              name="dob"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input DOB",
                                },
                              ]}
                            >
                              <DatePicker
                                onChange={onChangeDatepickerDOB}
                                disabledDate={(d) =>
                                  !d || d.isAfter(new Date())
                                }
                                className="datepicker"
                                allowClear={false}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Preferred time of day
                            </span>
                          }
                          name="preferredTime"
                          rules={[
                            {
                              required: true,
                              message: "Please select preferred time of day",
                            },
                          ]}
                        >
                          <Select
                            className="form-control"
                            defaultValue="Select Any Option"
                            allowClear
                          >
                            <Option value="MORNING">Morning</Option>
                            <Option value="LUNCH">Lunch</Option>
                            <Option value="AFTERNOON">Afternoon</Option>
                            <Option value="NO_PREFERENCE">No Preference</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Body part to be scanned
                            </span>
                          }
                          name="bodyPartToBeScanned"
                          rules={[
                            {
                              required: true,
                              message: "Please input body part name!",
                            },
                            {
                              pattern: servicesNamePattern,
                              message: "Please input a valid body part name",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="p-font">
                          Brief description of injury/symptoms
                        </span>
                      }
                      name="description"
                      rules={[
                        {
                          required: true,
                          message: "Please input description!",
                        },
                        {
                          pattern: aboutPattern,
                          message: "Please input a valid brief description",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="p-font">
                          Do you have a referral from your physician ?
                        </span>
                      }
                      name="referralOfDoctor"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Any Option!",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onRadioChangeRef} value={value}>
                        <Radio value="Yes" className="p-font">
                          Yes
                        </Radio>
                        <Radio value="No" className="p-font">
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col md={12} sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="p-font">
                          Have you been to this imaging center before ?
                        </span>
                      }
                      name="firstVisit"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Any Option!",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onRadioChangeIC} value={icValue}>
                        <Radio value="Yes" className="p-font">
                          Yes
                        </Radio>
                        <Radio value="No" className="p-font">
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col md={24} sm={24} xs={24}>
                    <Row gutter={20}>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="p-font">Referring doctor</span>
                          }
                          name="doctorName"
                          rules={[
                            {
                              required: true,
                              message: "Please input refering doctor name!",
                            },
                            {
                              pattern: servicesNamePattern,
                              message: "Please input a valid name",
                            },
                          ]}
                        >
                          <Input placeholder="Doctor Name" />
                        </Form.Item>
                      </Col>
                      <Col md={12} sm={24} xs={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Do you have a follow-up appointment ?
                            </span>
                          }
                          name="followUpAppointment"
                          rules={[
                            {
                              required: true,
                              message: "Please Select Any Option!",
                            },
                          ]}
                        >
                          <Radio.Group
                            onChange={onRadioChangeFollow}
                            value={followupValue}
                          >
                            <Radio value="Yes" className="p-font">
                              Yes
                            </Radio>
                            <Radio value="No" className="p-font">
                              No
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Col
                  span={12}
                  md={24}
                  xs={24}
                  sm={24}
                  style={{ textAlign: "center" }}
                  className="mt-20"
                >
                  <Form.Item className="form-bottom">
                    {props?.tourIndex === 3 && props?.tour ? (
                      <Button
                        onClick={() => props.onUpdateTourIndex(4)}
                        type="primary"
                        className="theme-btn button-font img-forth-step"
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        loading={createLoading}
                        type="primary"
                        className="theme-btn button-font img-forth-step"
                        htmlType="submit"
                        form="appForm"
                        key="submit"
                      >
                        Submit
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Form>

      <SuccessModel
        message="Appointment Requested Successfully!"
        open={successModel}
      />
    </>
  );
}
