import { Button } from "antd";
import { useState, useMemo } from "react";
import doubleBack from "assets/icons/doubleBack.svg";
import doubleForward from "assets/icons/doubleForward.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

function usePaginationSearch(items) {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [searchText, setSearchText] = useState("");

  const filteredItems = useMemo(() => {
    if (!searchText) {
      return items;
    }

    const lowercasedSearchText = searchText.toLowerCase();

    return items.filter((item) => {
      const itemValues = Object.values(item);
      return itemValues.some((value) =>
        String(value).toLowerCase().includes(lowercasedSearchText)
      );
    });
  }, [items, searchText]);

 
  const PrevNextArrow = (current, type, originalElement) => {
    if (type === "jump-prev") {
      return (
        <Button className="nxt-prv-btn d-flex">
          <img src={doubleBack} alt="jump"></img>
        </Button>
      );
    }
    if (type === "prev") {
      return <Button icon={<LeftOutlined />} className="nxt-prv-btn" />;
    }
    if (type === "next") {
      return <Button icon={<RightOutlined />} className="nxt-prv-btn" />;
    }

    if (type === "jump-next") {
      return (
        <Button className="nxt-prv-btn d-flex">
          {" "}
          <img src={doubleForward} alt="jump"></img>
        </Button>
      );
    }

    return originalElement;
  };

  const paginatedItems = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    return filteredItems.slice(startIndex, startIndex + pageSize);
  }, [currentPage, pageSize, filteredItems]);

  const handlePaginationChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  return {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  };
}

export default usePaginationSearch;
