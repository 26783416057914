import * as actions from "../actions/index";

const initialState = {
  notification: null,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };

    default:
      return state;
  }
};
export default notificationReducer;
