import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Alert } from "antd";
import { Auth } from "aws-amplify";
import { useNavigate, useLocation } from "react-router-dom";
import bazarLogo from "assets/delphy/delphy.svg";

import Footer from "commons/Footer";

import "../ConfirmEmail/ConfirmEmail.less";

const Index = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [msg, setmsg] = useState();
  const [loading, setloading] = useState(false);
  const [form] = Form.useForm();
  const [email, setemail] = useState(
    location?.state ? location?.state?.userName : ""
  );

  // Function to handle resend code
  const resendConfirmationCode = async () => {
    try {
      await Auth.forgotPassword(email).then(() => {
        message.success("Your resend code has been sent");
        setloading(false);
      });
    } catch (error) {
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  // Function to confirmation code submit
  const confirmReset = async (e) => {
    setloading(true);
    setmsg({ text: "", type: "" });
    try {
      await Auth.forgotPasswordSubmit(email, e.code, e.password).then(() => {
        setloading(false);
        navigate("/login", {
          state: { feedback: "Signin with new password.", userName: "" },
        });
      });
    } catch (error) {
      console.log("error resetting password:", error);
      setloading(false);
      setmsg({ text: error.message, type: "error" });
    }
  };

  useEffect(() => {
    if (!location.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/reset-password") &&
        window.location.href.includes("email=")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        form.setFieldsValue({
          code: Data.code,
          password: "",
        });
        setemail(window.location.href.split("email=")[1]);
      } else {
        navigate("/");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row className="confirm-signup-wrapper">
      <Col span={12} lg={12} md={0} sm={0} xs={0}>
        <div className="confirm-signup-page-image"></div>
      </Col>
      <Col
        className="form-footer-container"
        span={12}
        lg={12}
        md={0}
        sm={24}
        xs={24}
      >
        <Row justify="center" align="middle" className="w-100">
          <Col span={12} md={12} lg={12} xs={20} sm={20}>
            <div className="header"></div>
            <div className="form-logo-container">
              <img src={bazarLogo} alt="" className="bazar-logo h-60" />
              <p className="instruction sub-header-font">
                Check your email for a confirmation code
              </p>
              {msg?.text && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type={msg?.type}
                  showIcon={false}
                  message={msg.text}
                  banner
                />
              )}
              <Form
                form={form}
                onFinish={(e) => confirmReset(e)}
                layout="vertical"
                className="confirm-signup-form w-100 mt-20"
                requiredMark={false}
              >
                <Form.Item
                  name="code"
                  label={
                    <span className="sub-header-font">
                      Enter code sent in your email
                    </span>
                  }
                  className="code-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirmation Code!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    id="code"
                    key="code"
                    name="code"
                    className="code"
                    tabIndex={1}
                    size="large"
                  />
                </Form.Item>
                <Form.Item
                  label={
                    <span className="sub-header-font">
                      Set your new password
                    </span>
                  }
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  className="email-form-item"
                >
                  <Input.Password
                    className="code"
                    style={{
                      borderRadius: "8px",
                      height: "44px",
                    }}
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={7}>
                      <Button
                        type="link"
                        onClick={resendConfirmationCode}
                        style={{
                          fontSize: 17,
                          textAlign: "right",
                          cursor: "pointer",
                          paddingBottom: 10,
                          color: "#416B88",
                          fontWeight: "bold",
                          paddingLeft: 0,
                        }}
                      >
                        Resend Code
                      </Button>
                    </Col>
                    <Col span={9}>
                      <Button
                        className="confirm-signup"
                        htmlType="submit"
                        loading={loading}
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
        <Footer />
      </Col>
    </Row>
  );
};

export default Index;
