import { Skeleton } from "antd";
import React from "react";

function SkeletonBlock(props) {
  let { height, width, margin, style } = props?.style;
  let BlockStyle = {
    height: height ? height : 200,
    width: width ? width : "24vw",
    marginLeft: margin !== undefined ? margin : 10,
    marginRight: margin !== undefined ? margin : 10,
    borderRadius: "7px",
  };
  if (style) {
    BlockStyle = { ...BlockStyle, ...style };
  }
  return <Skeleton.Button style={BlockStyle} active />;
}
export default SkeletonBlock;

