import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Radio,
  Upload,
  message,
  Avatar,
  InputNumber,
  DatePicker,
} from "antd";
import { UserOutlined, CameraOutlined } from "@ant-design/icons";
import React from "react";
import moment from "moment";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import axios from "axios";
import { GetPublicFileFromS3 } from "utility/uploadFileToS3";
import {
  aboutPattern,
  cityPattern,
  docSpecialty,
  doctorNameRegex,
  emailPattern,
  formatNumberUS,
  gastroenterologist,
  neuro_surgeon,
  ophthalmologist,
  orthopaedic,
  otolaryngologists,
  pain_management,
  physiatrist,
  podiatrist,
  ratingPattern,
  servicesNamePattern,
  streetAddressPattern,
  tenDigit,
  urlPattern,
  urology,
  zipPattern,
  doctorMapSurgerySpeciality,
} from "utility/constant";
import {
  GetDoctorDetails,
  ListSurgeryCenter,
  UpdateDoctorProfile,
} from "utility/action";
import { useCallback } from "react";
import SuccessModel from "utility/SuccessModel";
import { API } from "aws-amplify";

export default function PysicianDetail() {
  const [doctorData, setdoctordata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [surgeryData, setSurgeryData] = useState([]);
  const [key, setKey] = useState("");
  const [doctorPhoto, setDoctorPhoto] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const location = useLocation();
  const [date, setDate] = useState("");
  const params = new URLSearchParams(location.search);
  const isDpcDoctor = params.get("isDpcDoctor");

  const [successModel, setSuccessModel] = useState(false);
  const { TextArea } = Input;

  const doctorId = location?.state.id;
  const [messageApi, contextHolder] = message.useMessage();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [form] = Form.useForm();

  const dependentOptions = {
    Otolaryngologists: otolaryngologists,
    Ophthalmologist: ophthalmologist,
    Orthopaedic: orthopaedic,
    Gastroenterologist: gastroenterologist,
    "Pain Management": pain_management,
    Podiatrist: podiatrist,
    Urology: urology,
    "Neuro Surgeon": neuro_surgeon,
    Physiatrist: physiatrist,
  };

  const [childOptions, setChildOptions] = useState([]);
  const [parentValue, setParentValue] = useState(undefined);

  const handleParentChange = (value) => {
    setParentValue(value);
    fetchSurgeryData(value);
    setChildOptions(dependentOptions[value]);
    setIsOtherSelected(true);
    form.setFieldsValue({
      areaOfSpeciality: [],
      surgeryCenter: [],
    });
  };

  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  // for date picker
  const onChangeDatepicker = (date, dateString) => {
    setDate(dateString);
  };

  function UserImg() {
    return (
      <img
        src={uploadImage === null ? doctorPhoto : uploadImage}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const fetchDoctor = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetDoctorDetails(doctorId);
      form.setFieldsValue({
        doctorName: response?.doctorName,
        doctorEmail: response?.doctorEmail,
        doctorEducation: response?.doctorEducation,
        doctorDegree: response?.doctorDegree,
        aboutDoctor: response?.aboutDoctor,
        startDate: response?.startDate
          ? moment(response?.startDate, "YYYY-MM-DD")
          : null,
        areaOfSpeciality:
          response?.areaOfSpeciality === null ? [] : response?.areaOfSpeciality,
        doctorOfficeAdd: response?.doctorOfficeAdd,
        doctorOfficeCity: response?.doctorOfficeCity,
        doctorOfficeState: response?.doctorOfficeState,
        surgeryCenter:
          response?.surgeryCenter === null ? [] : response?.surgeryCenter,
        doctorPracticeName: response?.doctorPracticeName,
        doctorOfficePhone: response?.doctorOfficePhone,
        doctorOfficeFax: response?.doctorOfficeFax,
        doctorWebsite: response?.doctorWebsite,
        doctorOfficeZip: response?.doctorOfficeZip,
        facebookRating: response?.facebookRating,
        googleRating: response?.googleRating,
        activeOnPortal: response?.activeOnPortal,
        specialty: response?.specialty,
      });
      setdoctordata(response);
      if (response?.memberDOB) {
        setDate(moment(response?.memberDOB, "YYYY-MM-DD"));
      } else {
        setDate(null);
      }
      if (response?.specialty) {
        fetchSurgeryData(response?.specialty);
      }
      if (response?.areaOfSpeciality) {
        setIsOtherSelected(true);
      }
      if (response?.doctorProfilePhoto) {
        let doctorPhoto;

        if (response?.doctorProfilePhoto.startsWith("http")) {
          doctorPhoto = response?.doctorProfilePhoto;
        } else {
          doctorPhoto = await GetPublicFileFromS3(response?.doctorProfilePhoto);
        }
        setDoctorPhoto(doctorPhoto);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [doctorId]);

  const fetchSurgeryData = useCallback(async (specialty) => {
    try {
      const filter = { activeOnPortal: { eq: true } };
      let response = await ListSurgeryCenter(999, filter);

      response = response.filter((element) => {
        return element?.specailtyAndBodyPart?.find((value) => {
          return (
            value?.specialty ===
            doctorMapSurgerySpeciality[specialty ? specialty : parentValue]
          );
        });
      });

      setSurgeryData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  // No need to call fetch Surgery in use Effect
  // useEffect(() => {
  //   fetchSurgeryData();
  // }, [fetchSurgeryData]);

  const updateDoctor = async (values) => {
    try {
      setLoading(true);
      const inputData = {
        id: doctorData?.id,
        doctorName: values?.doctorName,
        doctorEmail: values?.doctorEmail,
        doctorEducation: values?.doctorEducation,
        doctorDegree: values?.doctorDegree,
        aboutDoctor: values?.aboutDoctor,
        areaOfSpeciality: values?.areaOfSpeciality || null,
        startDate: date ? moment(date).format("YYYY-MM-DD") : undefined,
        doctorOfficeAdd: values?.doctorOfficeAdd,
        doctorOfficeCity: values?.doctorOfficeCity,
        doctorOfficeState: values?.doctorOfficeState,
        surgeryCenter: values?.surgeryCenter,
        doctorPracticeName: values?.doctorPracticeName,
        doctorOfficePhone: values?.doctorOfficePhone,
        doctorOfficeFax: values?.doctorOfficeFax,
        doctorWebsite: values?.doctorWebsite,
        doctorOfficeZip: values?.doctorOfficeZip,
        facebookRating: values?.facebookRating,
        googleRating: values?.googleRating,
        doctorProfilePhoto: key === "" ? undefined : key,
        activeOnPortal: values?.activeOnPortal,
        specialty: values?.specialty,
      };
      await UpdateDoctorProfile(inputData);
      fetchDoctor();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Updating Doctor Profile",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const props = {
    onChange({ file }) {
      setSaveDisabled(false);
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        const response = await axios.put(linkToUpload, file);
        setUploadImage(linkToUpload.split("?")[0]);
        setKey(linkToUpload.split("?")[0]);
        onSuccess();
      } catch (error) {
        onError();
      }
    },
  };

  return (
    <>
      {contextHolder}

      <Row justify="space-between">
        <BackButton />
        <Typography.Title
          level={4}
          className="text-center mb-0"
          style={{ textAlign: "center" }}
        >
          {doctorData?.doctorName}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      {skeletonLoader ? (
        ""
      ) : (
        <>
          <Row justify="center" className="mt-20 upload-services-photo">
            <div className="relative">
              <Avatar
                size={120}
                icon={
                  doctorPhoto === null && uploadImage === null ? (
                    <UserOutlined />
                  ) : (
                    <UserImg />
                  )
                }
              />
              <Upload
                {...props}
                showUploadList={false}
                beforeUpload={beforeUpload}
                className="avatar-uploader"
              >
                <Button
                  shape="circle"
                  size="middle"
                  className="btn-primary-dual-color upload-btn"
                >
                  <CameraOutlined />
                </Button>
              </Upload>
            </div>
          </Row>
        </>
      )}
      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              <Form
                form={form}
                onFinish={updateDoctor}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
                onValuesChange={handleValuesChange}
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Name"
                      name="doctorName"
                      rules={[
                        {
                          pattern: doctorNameRegex,
                          message: "Please enter a valid name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Email"
                      name="doctorEmail"
                      rules={[
                        {
                          pattern: emailPattern,
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Undergraduate"
                      name="doctorDegree"
                      rules={[
                        {
                          pattern: servicesNamePattern,
                          message: "Please enter a valid education",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Medical school"
                      name="doctorEducation"
                      rules={[
                        {
                          pattern: servicesNamePattern,
                          message: "Please enter a valid institute",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={24} md={24} xs={24} sm={24}>
                    <Form.Item
                      label="About"
                      name="aboutDoctor"
                      rules={[
                        {
                          pattern: aboutPattern,
                          message: "Please enter a valid about",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  {/* DO not render specialty and focus on body, if the doctor is of dpc */}
                  {!isDpcDoctor && (
                    <>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label="Specialty"
                          name="specialty"
                          rules={[
                            {
                              required: true,
                              message: "Please select any option",
                            },
                          ]}
                        >
                          <Select
                            options={docSpecialty}
                            placeholder="Select specialty"
                            maxTagCount="responsive"
                            onChange={handleParentChange}
                            value={parentValue}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          ></Select>
                        </Form.Item>
                      </Col>

                      {isOtherSelected && (
                        <>
                          {parentValue !== "General Surgeon" &&
                            parentValue !== "Sports Medicine" && (
                              <Col span={12} md={12} xs={24} sm={24}>
                                <Form.Item
                                  label="Focus (on body)"
                                  name="areaOfSpeciality"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please select any option",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="multiple"
                                    allowClear
                                    placeholder="Select body part"
                                    maxTagCount="responsive"
                                    options={childOptions}
                                    getPopupContainer={(trigger) =>
                                      trigger.parentNode
                                    }
                                  ></Select>
                                </Form.Item>
                              </Col>
                            )}
                        </>
                      )}
                    </>
                  )}
                  {!isDpcDoctor && (
                    <Col span={12} md={12} xs={24} sm={24}>
                      <Form.Item label="Surgery center" name="surgeryCenter">
                        <Select
                          allowClear
                          mode="multiple"
                          placeholder="Select surgery center"
                          getPopupContainer={(trigger) => trigger.parentNode}
                          maxTagCount="responsive"
                          style={{
                            width: "100%",
                          }}
                        >
                          {surgeryData.map((item) => (
                            <Select.Option value={item.surgeryCenterName}>
                              {`${item.surgeryCenterName}`}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  )}

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Practice name"
                      name="doctorPracticeName"
                      rules={[
                        {
                          pattern: servicesNamePattern,
                          message: "Please enter a valid practice name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Phone"
                      name="doctorOfficePhone"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>

                  {/* ading field for fax */}
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Fax"
                      name="doctorOfficeFax"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid fax number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Website"
                      name="doctorWebsite"
                      rules={[
                        {
                          pattern: urlPattern,
                          message: "Please input valid url",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Street address"
                      name="doctorOfficeAdd"
                      rules={[
                        {
                          required: true,
                          message: "Please enter street address",
                        },
                        {
                          pattern: streetAddressPattern,
                          message: "Please enter a valid street address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="City"
                      name="doctorOfficeCity"
                      rules={[
                        {
                          required: true,
                          message: "Please enter city name",
                        },
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid city name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="State"
                      name="doctorOfficeState"
                      rules={[
                        {
                          required: true,
                          message: "Please enter state name",
                        },
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid state name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Zip code"
                      name="doctorOfficeZip"
                      rules={[
                        {
                          required: true,
                          message: "Please enter zip code",
                        },
                        {
                          pattern: zipPattern,
                          message: "Please enter a valid ZIP code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Google rating"
                      name="googleRating"
                      rules={[
                        {
                          pattern: ratingPattern,
                          message:
                            "Please enter a valid rating between 0 and 10",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Facebook rating"
                      name="facebookRating"
                      rules={[
                        {
                          pattern: ratingPattern,
                          message:
                            "Please enter a valid rating between 0 and 10",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  {isDpcDoctor ? (
                    <Col span={12} md={12} xs={24} sm={24}>
                      <Form.Item
                        label="Start Date"
                        name="startDate"
                        initialValue={date}
                      >
                        <DatePicker
                          disabled={doctorData?.startDate}
                          onChange={onChangeDatepicker}
                          disabledDate={(d) =>
                            !d || d.isBefore(new Date(), "day")
                          }
                          style={{ width: "100%" }}
                        />
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Account status" name="activeOnPortal">
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Inactive</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                    <Button
                      size="large"
                      className="theme-btn mt-20"
                      htmlType="submit"
                      loading={loading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <SuccessModel
        message="Doctor Details Updated Successfully"
        open={successModel}
      />
    </>
  );
}
