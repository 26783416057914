import React from "react";
import { Route, Routes, Navigate, useNavigate } from "react-router-dom";
import MainLayout from "containers/MainLayout/MainLayout";
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { LOGOUT } from "store/actions";

export default function PrivateRoute(props) {
  const [cogGroup, setCogGroup] = useState("");
  const [loggedOut, setLoggedOut] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accountStatus = useSelector(
    (state) => state?.userReducer?.userData?.accountStatus
  );
  const profileCompleted = useSelector(
    (state) => state?.userReducer?.userData?.profile?.profileCompleted
  );
  const memberProfileCompleted = useSelector(
    (state) => state?.userReducer?.userData?.profile?.profileCompleted
  );
  const userType = useSelector(
    (state) => state?.userReducer?.userData?.userType
  );
  const userCompany = useSelector(
    (state) => state?.userReducer?.userData?.companyID?.approved
  );
  const userGroup = useSelector((state) => state?.userReducer?.userData?.group);
  const userVerified = useSelector(
    (state) => state?.userReducer?.userData?.verified
  );

  // const fetchUserGroup = async () => {
  //   try {
  //     const user = await Auth.currentAuthenticatedUser();
  //     const cognitoGroup =
  //       user.signInUserSession.accessToken.payload["cognito:groups"][0];
  //     setCogGroup(cognitoGroup);
  //   } catch (error) {
  //     if (
  //       error?.message === "No current user" ||
  //       error?.message === "The user is not authenticated"
  //     ) {
  //       await Auth.signOut();
  //       dispatch({
  //         type: LOGOUT,
  //       });
  //       localStorage.clear();
  //       navigate("/login");
  //     }
  //     console.log("Errrr", error);
  //   }
  // };

  // useEffect(() => {
  //   fetchUserGroup();
  // });

  function determineNavigationDestination() {
    if (cogGroup === "SuperAdmin") {
      return <Navigate to="/members" />;
    }

    if (
      cogGroup === "SuperAdmin" &&
      profileCompleted === null &&
      accountStatus === "NOT_REQUIRED"
    ) {
      return <Navigate to="/members" />;
    }

    if (
      userGroup === "Member" &&
      memberProfileCompleted === false &&
      accountStatus === null
    ) {
      return <Navigate to="/user-profile" />;
    }

    if (
      (userGroup === "Member" && memberProfileCompleted === true) ||
      (profileCompleted === true && accountStatus === "ACTIVE")
    ) {
      return <Navigate to="/" />;
    }

    if (userType === null || userType === undefined) {
      return <Navigate to="/approval" />;
    }

    // if (userVerified === false && userType === "CORPORATE") {
    //   return <Navigate to="/approval" />;
    // }

    // if (userType === "CORPORATE_ADMIN" && userCompany === false) {
    //   return <Navigate to="/approval" />;
    // }

    if (userType === "CORPORATE_ADMIN" && profileCompleted === false) {
      return <Navigate to="/user-profile" />;
    }

    if (
      userType === "CORPORATE_ADMIN" &&
      profileCompleted === true &&
      accountStatus === null
    ) {
      return <Navigate to="/corporate-billing" />;
    }

    if (userType === "CORPORATE_ADMIN" && accountStatus === null) {
      return <Navigate to="/corporate-billing" />;
    }

    if (
      userType === "CORPORATE_ADMIN" &&
      accountStatus === "ACTIVE" &&
      profileCompleted === false
    ) {
      return <Navigate to="/user-profile" />;
    }

    if (
      userType === "CORPORATE_ADMIN" &&
      accountStatus === "ACTIVE" &&
      profileCompleted === true
    ) {
      return <Navigate to="/" />;
    }

    if (profileCompleted === false) {
      return <Navigate to="/user-profile" />;
    }

    if (profileCompleted === true && accountStatus === null) {
      return <Navigate to="/billing" />;
    }

    if (accountStatus === "ACTIVE") {
      return <Navigate to="/" />;
    }

    return "/";
  }
  return (
    <>
      <Routes>
        {accountStatus === "ACTIVE" && profileCompleted === true && (
          <Route
            path={props.path}
            element={<MainLayout>{props.children}</MainLayout>}
          />
        )}

        <Route path={props.path} element={determineNavigationDestination()} />
      </Routes>
    </>
  );
}
