import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Col,
  Select,
  Modal,
  Typography,
  Divider,
  message,
  Dropdown,
  Menu,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import exportCSV from "assets/icons/exportcsv.svg";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import {
  disableSubmitButton,
  doctorNameRegex,
  emailPattern,
  formatCSVData,
  formatNumberUS,
  tenDigit,
  urlPattern,
} from "utility/constant";
import { CSVLink } from "react-csv";
import {
  CreateDoctorProfile,
  DeleteDoctor,
  ListDoctorByName,
} from "utility/action";
import { useCallback } from "react";
import search from "utility/search";
import SuccessModel from "utility/SuccessModel";
import { handleStatusChange } from "utility/StatusChange";
import { DeleteService } from "utility/DeleteService";
import DeleteModal from "utility/DeleteModal";
import TableSkeleton from "utility/TableSkeleton";

export default function Physician() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doctorData, setdoctordata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [dataSource, setDataSource] = useState(doctorData);
  const [csvData, setCsvData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [deleteId, setDeleteId] = useState("");
  const { Option } = Select;
  const [form] = Form.useForm();

  const handleFormChange = disableSubmitButton(["doctorName"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
  };

  const labelKeyMap = {
    "Physician ID": "physicianID",
    "Doctor Name": "doctorName",
    "Doctor Degree": "doctorDegree",
    "Doctor Email": "doctorEmail",
    "About Doctor": "aboutDoctor",
    Education: "doctorEducation",
    "Area of Speciality": "areaOfSpeciality",
    Specialities: "specialities",
    Address: "doctorOfficeAdd",
    City: "doctorOfficeCity",
    State: "doctorOfficeState",
    Zip: "doctorOfficeZip",
    Phone: "doctorOfficePhone",
    "Doctor Practice Name": "doctorPracticeName",
    Website: "doctorWebsite",
    "Doctor Facility": "doctorFacility",
    "Surgery Center": "surgeryCenter",
    "Google Rating": "googleRating",
    "Facebook Rating": "facebookRating",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const setDoctorsRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      doctorPracticeName: row?.doctorPracticeName,
      Action: row?.id,
      ProviderName: row?.doctorName,
      PracticeName: row?.doctorPracticeName,
      createdAt: row?.createdAt,
      Specialties: row?.specialty,
      OfficeLocation: row.doctorOfficeCity,
    };
  };

  const columns = [
    {
      title: "Doctor's Name",
      dataIndex: "ProviderName",
    },
    {
      title: "Practice Name",
      dataIndex: "PracticeName",
    },
    {
      title: "Specialty",
      dataIndex: "Specialties",
    },
    {
      title: "Office Location",
      dataIndex: "OfficeLocation",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/doctor-detail" state={{ id: id }}>
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key={2}>
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId(id);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchDoctor = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListDoctorByName(999);
      const data = response.map((row) => setDoctorsRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setdoctordata(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  const createDoctorProfileData = async (values) => {
    try {
      setCreateLoading(true);
      setDeleteId("");
      const inputData = {
        doctorName: values?.doctorName,
        searchByName: (values?.doctorName).toLowerCase(),
        doctorEmail: values?.doctorEmail,
        doctorOfficePhone: values?.doctorOfficePhone,
        doctorWebsite: values?.doctorWebsite,
        byName: "doctor",
        activeOnPortal: false,
      };
      const response = await CreateDoctorProfile(inputData);
      const mappedData = {};
      Object.entries(response).forEach(([key, value]) => {
        if (value !== null) {
          mappedData[key] = value;
        }
      });

      const tableData = {
        searchByName: mappedData?.searchByName,
        doctorPracticeName: mappedData?.doctorPracticeName,
        Action: mappedData?.id,
        ProviderName: mappedData?.doctorName,
        PracticeName: mappedData?.doctorPracticeName,
        Specialties: mappedData?.areaOfSpeciality,
        OfficeLocation: mappedData.doctorOfficeCity,
        createdAt: mappedData?.createdAt,
      };

      const sortByCreatedAt = (data) =>
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setDataSource((previous) => sortByCreatedAt([...previous, tableData]));
      setdoctordata((previous) => sortByCreatedAt([...previous, tableData]));

      setCreateLoading(false);
      setIsModalOpen(false);
      form.resetFields();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error While Creating Doctor Profile",
      });
      console.log(error);
    } finally {
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Action",
      "OfficeLocation",
      "PracticeName",
      "ProviderName",
      "doctorPracticeName",
      "key",
      "searchByName",
      "Speciality",
    ];
    const filteredData = search(doctorData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleChange = async (value) => {
    try {
      setSelectedValue(value);
      setLoading(true);
      const filterData = await handleStatusChange(
        value,
        setDoctorsRow,
        ListDoctorByName,
        doctorData
      );
      setDataSource(filterData);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteDoctorCenter = async () => {
    try {
      setDeleteLoading(true);
      await DeleteService(deleteId, DeleteDoctor);
      setDeleteModelPrompt(false);
      setSuccessModel(true);
      setSelectedValue(null);
      fetchDoctor();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Doctors
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Select
            allowClear
            placeholder="Status"
            style={{ width: 100 }}
            onChange={handleChange}
            value={selectedValue}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
          </Select>
          <CSVLink
            data={resData}
            headers={headers}
            filename={"Doctor.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Add Doctor
          </Button>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Doctor
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          onFinish={createDoctorProfileData}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Doctor's name"
                name="doctorName"
                rules={[
                  {
                    required: true,
                    message: "Please enter a doctor name",
                  },
                  {
                    pattern: doctorNameRegex,
                    message: "Please enter a valid doctor name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Doctor’s email"
                name="doctorEmail"
                rules={[
                  {
                    required: true,
                    message: "Please enter doctor email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please provide valid Email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Phone number"
                name="doctorOfficePhone"
                rules={[
                  {
                    pattern: tenDigit,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  formatter={(value) => formatNumberUS(value)}
                  parser={(value) => {
                    const cleanedValue = value.replace(/\D/g, "");
                    return cleanedValue.slice(0, 10);
                  }}
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Website"
                name="doctorWebsite"
                rules={[
                  {
                    pattern: urlPattern,
                    message: "Please input valid url",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message={
          deleteId !== ""
            ? "Doctor Deleted successfully"
            : "Doctor Added successfully"
        }
        open={successModel}
      />

      <DeleteModal
        content="Are you sure you want to delete the doctor?"
        open={deleteModelPrompt}
        onClick={deleteDoctorCenter}
        buttonLoading={deleteLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        buttonText="Yes"
      />
    </>
  );
}
