import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  message,
  Switch,
  InputNumber,
} from "antd";
import React, { useCallback } from "react";
import { CopyOutlined } from "@ant-design/icons";

import BackButton from "commons/BackButton";
import "./PartnerDetails.less";
import { useParams } from "react-router-dom";
import { useState } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import {
  cityPattern,
  doctorNameRegex,
  emailPattern,
  formatNumberUS,
  onlyNumberPattern,
  streetAddressPattern,
  tenDigit,
  urlPattern,
  zipPattern,
} from "utility/constant";
import {
  GetPartners,
  ListPromotionalPartners,
  UpdatePartner,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";
import { useEffect } from "react";
import { API } from "aws-amplify";
import CopyToClipboard from "react-copy-to-clipboard";

export default function PartnerDetail() {
  const [partnerData, setPartnerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [skeletonLoader, setskeletonLoader] = useState(false);

  const [successModel, setSuccessModel] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showReferral, setShowReferral] = useState(false);
  const [codeLoading, setCodeLoading] = useState(false);
  const [showCode, setShowCode] = useState(false);
  const [activatedCode, setActivatedCode] = useState(false);
  const [showDiscount, setShowDiscount] = useState(false);
  const [discountType, setDiscountType] = useState("");
  const [membershipType, setMembershipType] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [form] = Form.useForm();
  const { Option } = Select;
  const { id } = useParams();
  const { Text } = Typography;

  const planAmount = membershipType === "INDIVIDUAL_MONTHLY" ? 9 : 99;

  // Function to handle input changes and set the value to uppercase
  const handleInputChange = (e) => {
    const newValue = e.target.value.toUpperCase();
    form.setFieldsValue({
      promotionalCode: newValue,
    });
  };

  const fetchPartners = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetPartners(id);
      setPartnerData(response);

      if (response?.referal?.promotionalCode) {
        setShowCode(true);
        if (response?.referal?.active) {
          setActivatedCode(true);
        } else {
          setActivatedCode(false);
        }
      }

      form.setFieldsValue({
        name: response?.name,
        email: response?.email,
        phone: response?.phone,
        url: response?.url,
        streetAddress: response?.streetAddress,
        city: response?.city,
        state: response?.state,
        zip: response?.zip,
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [id]);

  const updatePartner = async (values) => {
    try {
      if (
        values?.discountType &&
        values?.planType &&
        values?.discount &&
        values?.promotionalCode
      ) {
        try {
          setCodeLoading(true);
          const promotionalCodeFilter = {
            promotionalCode: {
              eq: values?.promotionalCode,
            },
          };
          const partnersRes = await ListPromotionalPartners(
            999,
            promotionalCodeFilter
          );
          if (partnersRes[0]?.promotionalCode === values?.promotionalCode) {
            messageApi.open({
              type: "error",
              content:
                "Coupon code name already in use. Please choose another.",
            });
          } else {
            let url = `/billing/coupon/genrateCoupon`;
            const refData = {
              body: {
                id: id,
                discountType: values?.discountType,
                discount: values?.discount,
                promotionalCode: values?.promotionalCode,
                planType: values?.planType,
              },
            };
            await API.post("bazarhealthREST", url, refData);
            const response = await GetPartners(id);
            setPartnerData(response);
            setShowCode(true);
            setActivatedCode(true);
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        setLoading(true);
        const inputData = {
          id: id,
          name: values?.name,
          email: values?.email,
          phone: values?.phone,
          url: values?.url,
          streetAddress: values?.streetAddress,
          city: values?.city,
          state: values?.state,
          zip: values?.zip,
        };
        await UpdatePartner(inputData);
        fetchPartners();
        setSuccessModel(true);
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Updating Doctor Profile",
      });
      console.log(error);
    } finally {
      setLoading(false);
      setCodeLoading(false);
    }
  };

  useEffect(() => {
    fetchPartners();
  }, [fetchPartners]);

  const handleValuesChange = (values) => {
    setSaveDisabled(false);
  };

  const onChange = async (checked) => {
    let url = `/billing/coupon/changeCouponStatus`;
    let status = checked === true ? true : false;
    const refData = {
      body: {
        id: id,
        promotionalID: partnerData?.referal?.promotionalID,
        currentStatus: status,
      },
    };
    if (checked) {
      setActivatedCode(true);
      messageApi.open({
        type: "success",
        content: `Referral code is activated`,
        duration: 5,
      });
      await API.post("bazarhealthREST", url, refData);
    } else {
      setActivatedCode(false);
      messageApi.open({
        type: "success",
        content: `Referral code is deactivated`,
        duration: 5,
      });
      await API.post("bazarhealthREST", url, refData);
    }
  };

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const handleChange = (value, type) => {
    if (type === "discountType") {
      setDiscountType(value);
    } else {
      setMembershipType(value);
    }
  };

  const validateDiscount = (rule, value) => {
    if (discountType === "AMOUNT") {
      if (
        form.getFieldValue("planType") === "INDIVIDUAL_MONTHLY" &&
        parseFloat(value) > 9
      ) {
        return Promise.reject(
          "Discount should not be more than $9 for individual - monthly plan."
        );
      } else if (
        form.getFieldValue("planType") === "INDIVIDUAL_ANNUALLY" &&
        parseFloat(value) > 99
      ) {
        return Promise.reject(
          "Discount should not be more than $99 for individual - annually plan."
        );
      }
    } else if (discountType === "PERCENT" && parseFloat(value) > 100) {
      return Promise.reject(
        "Discount should not be more than 100% for percentage discount."
      );
    }
    return Promise.resolve();
  };

  // const handleDiscountChange = (e) => {
  //   if (e.target.value > 0 && membershipType && discountType) {
  //     setShowDiscount(true);

  //     if (membershipType === "INDIVIDUAL_MONTHLY") {
  //       if (e.target.value > 9) {
  //         setShowDiscount(false);
  //       } else {
  //         setRemainingAmount(9 - e.target.value);
  //       }
  //     } else if (membershipType === "INDIVIDUAL_ANNUALLY") {
  //       if (e.target.value > 99) {
  //         setShowDiscount(false);
  //       } else {
  //         setRemainingAmount(99 - e.target.value);
  //       }
  //     }
  //   } else {
  //     setShowDiscount(false);
  //   }
  // };

  // const handleDiscountChange = (e) => {
  //   if (e.target.value > 0 && membershipType && discountType) {
  //     const maxAmount = membershipType === "INDIVIDUAL_MONTHLY" ? 9 : 99;
  //     const remaining = maxAmount - e.target.value;

  //     setShowDiscount(remaining >= 0);
  //     setRemainingAmount(Math.max(remaining, 0));
  //   } else {
  //     setShowDiscount(false);
  //   }
  // };

  const handleDiscountChange = (e) => {
    const enteredAmount = e.target.value;
    if (enteredAmount > 0 && membershipType && discountType) {
      const maxAmount = membershipType === "INDIVIDUAL_MONTHLY" ? 9 : 99;
      let remaining = 0;

      if (membershipType === "BOTH") {
        setShowDiscount(false);
        return;
      } else if (
        (membershipType === "INDIVIDUAL_MONTHLY" &&
          enteredAmount > 9 &&
          discountType === "AMOUNT") ||
        (membershipType === "INDIVIDUAL_ANNUALLY" &&
          enteredAmount > 99 &&
          discountType === "AMOUNT")
      ) {
        setShowDiscount(false);
        return; // Exit the function early
      } else if (
        (membershipType === "INDIVIDUAL_MONTHLY" ||
          membershipType === "INDIVIDUAL_ANNUALLY") &&
        enteredAmount > 100 &&
        discountType === "PERCENT"
      ) {
        setShowDiscount(false);
        return; // Exit the function early
      }

      if (discountType === "AMOUNT") {
        remaining = Math.max(maxAmount - enteredAmount, 0);
      } else if (discountType === "PERCENT") {
        // Convert percent to a decimal (e.g., 10% to 0.10)
        const discountPercent = parseFloat(enteredAmount) / 100;
        remaining = Math.max(maxAmount - maxAmount * discountPercent, 0);
        remaining = parseFloat(remaining.toFixed(2));
      }

      setShowDiscount(remaining >= 0);
      setRemainingAmount(remaining);
    } else {
      setShowDiscount(false);
    }
  };

  return (
    <>
      {contextHolder}

      <Row justify="space-between">
        <BackButton />
        <Typography.Title
          level={4}
          className="text-center mb-0"
          style={{ textAlign: "center" }}
        >
          {partnerData?.name}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              <Form
                form={form}
                onFinish={updatePartner}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
                onValuesChange={handleValuesChange}
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Partner name"
                      name="name"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: doctorNameRegex,
                          message: "Please enter a valid name",
                        },
                      ]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Partner email id"
                      name="email"
                      rules={[
                        {
                          required: true,
                        },
                        {
                          pattern: emailPattern,
                          message: "Please enter a valid email",
                        },
                      ]}
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Partner phone number"
                      name="phone"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid fax number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => {
                          return formatNumberUS(value);
                        }}
                        parser={(value) => {
                          console.log("1");
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        onChange={(e) => {
                          console.log("value", e);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Partner url"
                      name="url"
                      rules={[
                        {
                          pattern: urlPattern,
                          message: "Please input valid url",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Street address"
                      name="streetAddress"
                      rules={[
                        {
                          pattern: streetAddressPattern,
                          message: "Please enter a valid street address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid city name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid state name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      rules={[
                        {
                          pattern: zipPattern,
                          message: "Please enter a valid ZIP code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24}>
                    {showCode ? (
                      <div
                        onClick={() => setShowReferral(true)}
                        style={{
                          border: "1px dashed #000",
                          padding: "15px",
                          borderRadius: "6px",
                        }}
                      >
                        <div className="d-flex justify-content-between switch-code">
                          <span className="referral-code">
                            Generate a referral code
                          </span>

                          <Switch checked={activatedCode} onChange={onChange} />
                        </div>

                        <span className="ref-code-details">
                          {partnerData?.referal?.discount}
                          {partnerData?.referal?.discountType === "AMOUNT"
                            ? "$"
                            : "%"}{" "}
                          discount on{" "}
                          {partnerData?.referal?.planType
                            ?.replace(/_/g, " ")
                            .toLowerCase()}{" "}
                          plan
                        </span>

                        <Row gutter={20} className="mt-10">
                          <Col span={24} md={24} xs={24} sm={24}>
                            <div
                              className="d-flex justify-content-between align-items-center"
                              style={{
                                border: "1px dashed #BEBEBE",
                                padding: "10px",
                                borderRadius: "6px",
                                background: "#F3F3F3",
                              }}
                            >
                              <span className="ref-code">
                                Referral Code:{" "}
                                <span className="ref-data">
                                  {partnerData?.referal?.promotionalCode &&
                                    partnerData?.referal?.promotionalCode?.toUpperCase()}
                                </span>
                              </span>

                              <span>
                                <CopyToClipboard
                                  onCopy={onCopy}
                                  text={
                                    partnerData?.referal?.promotionalCode &&
                                    partnerData?.referal?.promotionalCode?.toUpperCase()
                                  }
                                >
                                  <CopyOutlined />
                                </CopyToClipboard>
                              </span>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    ) : showReferral ? (
                      <div
                        className="referral-code"
                        style={{
                          border: "1px dashed #000",
                          padding: "15px",
                          borderRadius: "6px",
                        }}
                      >
                        <span className="referral-code">
                          Generate a referral code
                        </span>

                        <Row gutter={20} className="mt-20">
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Discount type"
                              name="discountType"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select discount type",
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Select discount ype"
                                onChange={(value) =>
                                  handleChange(value, "discountType")
                                }
                                // onChange={handleChange}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                              >
                                <Option value="AMOUNT">Amount</Option>
                                <Option value="PERCENT">Percent</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Membership type"
                              name="planType"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select membership type",
                                },
                              ]}
                            >
                              <Select
                                allowClear
                                placeholder="Select membership type"
                                onChange={(value) =>
                                  handleChange(value, "membershipType")
                                }
                                // onChange={handleChange}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                              >
                                <Option value="INDIVIDUAL_MONTHLY">
                                  Individual - Monthly <b> ($9)</b>
                                </Option>
                                <Option value="INDIVIDUAL_ANNUALLY">
                                  Individual - Annually <b>($99)</b>
                                </Option>
                                {discountType === "PERCENT" ? (
                                  <Option value="BOTH">Both</Option>
                                ) : null}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Discount"
                              name="discount"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter discount ${discountType?.toLowerCase()}`,
                                },
                                {
                                  pattern:
                                    discountType === "AMOUNT"
                                      ? onlyNumberPattern
                                      : "",
                                  message: `Please enter a valid ${discountType?.toLowerCase()}`,
                                },
                                {
                                  validator: validateDiscount,
                                },
                              ]}
                            >
                              <Input
                                onChange={handleDiscountChange}
                                addonBefore={
                                  discountType === null
                                    ? "$"
                                    : discountType === "AMOUNT"
                                    ? "$"
                                    : "%"
                                }
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Coupon name"
                              name="promotionalCode"
                              rules={[
                                {
                                  required: true,
                                  message: `Please enter coupon name`,
                                },
                              ]}
                            >
                              <Input onChange={handleInputChange} />
                            </Form.Item>
                          </Col>

                          {showDiscount ? (
                            <Col
                              span={24}
                              md={24}
                              xs={24}
                              sm={24}
                              className="d-flex justify-content-center align-center"
                            >
                              <Form.Item label="Discounted price">
                                <div className="d-flex justify-content-center align-center">
                                  <span className="remaining-amt">
                                    ${remainingAmount}
                                  </span>
                                  <Text
                                    className="plan-amount"
                                    delete
                                    style={{ marginLeft: "10px" }}
                                  >
                                    ${planAmount}
                                  </Text>
                                </div>
                              </Form.Item>
                            </Col>
                          ) : (
                            ""
                          )}

                          <Col
                            span={24}
                            md={24}
                            xs={24}
                            sm={24}
                            className="center-btn"
                          >
                            <Button
                              size="large"
                              className="theme-btn mb-10"
                              htmlType="submit"
                              loading={codeLoading}
                              disabled={saveDisabled}
                            >
                              Generate Code
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    ) : (
                      <div
                        onClick={() => setShowReferral(true)}
                        className="d-flex justify-content-center "
                        style={{
                          border: "1px dashed #000",
                          padding: "15px",
                          borderRadius: "6px",
                        }}
                      >
                        <span className="referral-code">
                          Generate a referral code
                        </span>
                      </div>
                    )}
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                    <Button
                      size="large"
                      className="theme-btn mt-10 mt-30 mb-10"
                      htmlType="submit"
                      loading={loading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <SuccessModel
        message="Promotional Partners Details Updated Successfully"
        open={successModel}
      />
    </>
  );
}
