import { Row, Input, Button, Table, Space } from "antd";
import "./Admin.less";
import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { getCompanyRegi } from "graphql/queries";
import { updateEmployeeRegi, updateMember } from "graphql/mutations";
import exportCSV from "assets/icons/exportcsv.svg";

import { useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import { useCallback } from "react";
import search from "utility/search";

export default function Invitation() {
  const { Search } = Input;
  const [employee, setEmployeeData] = useState([]);
  const [dataSource, setDataSource] = useState(employee);
  const [loading, setLoading] = useState(false);

  const headers = [
    { label: "Email ID", key: "emailID" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Requested Date", key: "date" },
  ];

  const csvExp = dataSource.map((row) => ({
    emailID: row?.emailId,
    firstName: row?.firstName,
    lastName: row?.lastName,
    date: moment(row?.createdAt).format("YYYY-MM-DD"),
  }));

  const userId = useSelector(
    (state) => state.userReducer?.userData?.companyRegiEmployeeId
  );

  const approveEmployee = async (id, group) => {
    const inputData = {
      input: {
        id: id,
        verified: true,
      },
    };
    await API.graphql({
      query: group?.group === "Member" ? updateMember : updateEmployeeRegi,
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    fetchData();
    // .then(async (res) => {
    //   const responce = await API.graphql({
    //     query: getCompanyRegi,
    //     variables: {
    //       id: userId,
    //       filter: {
    //         data: {
    //           getCompanyRegi: {
    //             employee: { verified: { eq: false } },
    //           },
    //         },
    //       },
    //     },
    //     authMode: "AMAZON_COGNITO_USER_POOLS",
    //   });

    //   const requestedEmployee = responce.data.getCompanyRegi.employee.items
    //     .filter((row) => row.verified === false)
    //     .map((row) => ({
    //       firstName: row.firstName,
    //       lastName: row.lastName,
    //       emailId: row.employeeEmail,
    //       requestDate: moment(row?.createdAt).format("YYYY-MM-DD"),
    //       Id: row.id,
    //     }));
    //   setEmployeeData(requestedEmployee);
    //   setDataSource(requestedEmployee);
    // });
  };

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const responce = await API.graphql({
        query: getCompanyRegi,
        variables: {
          id: userId,
          filter: {
            data: {
              getCompanyRegi: {
                employee: { verified: { eq: false } },
              },
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const memberResponce = await API.graphql({
        query: getCompanyRegi,
        variables: {
          id: userId,
          filter: {
            data: {
              getCompanyRegi: {
                member: { verified: { eq: false } },
              },
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const arrayA = responce.data.getCompanyRegi.employee.items;
      const arrayB = memberResponce.data.getCompanyRegi.member.items;

      const mergedArray = [...arrayA, ...arrayB];

      const requestedEmployee = mergedArray
        .filter((row) => row.verified === false)
        .map((row) => ({
          key: row?.emailID,
          searchByName: row?.searchByName,
          firstName: row.firstName,
          lastName: row.lastName,
          emailId: row.emailID,
          requestDate: moment(row?.createdAt).format("YYYY-MM-DD"),
          Id: row.id,
          group: row?.group,
        }));
      setEmployeeData(requestedEmployee);
      setDataSource(requestedEmployee);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "emailId",
      "firstName",
      "lastName",
      "requestDate",
      "Role",
      "billingMode",
      "Active",
    ];
    const filteredData = search(employee, currValue, searchFields);
    setDataSource(filteredData);
  };

  const columns = [
    {
      title: "Email ID",
      dataIndex: "emailId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },

    {
      title: "Request Date",
      dataIndex: "requestDate",
    },
    {
      title: "",
      dataIndex: "group",
    },
    {
      title: "",
      dataIndex: "Id",
      className: "actions",
      render: (id, group) => {
        return (
          <>
            <Space wrap>
              <Button
                className="blue-btn"
                onClick={() => {
                  approveEmployee(id, group);
                }}
              >
                Approve
              </Button>

              <Button className="blue-border-btn">Deny</Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      <Row justify="space-between table-header">
        <Row align="middle">
          <Search
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20">
         

          <CSVLink
            data={csvExp}
            headers={headers}
            filename={"Employees.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>
        </Row>
      </Row>

      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
      />
    </>
  );
}
