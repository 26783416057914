import React, { useState } from "react";
import {
  Row,
  Col,
  Typography,
  Form,
  Input,
  Button,
  Alert,
  Popover,
} from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { Auth, API } from "aws-amplify";
import logo from "assets/delphy/delphy.svg";
import icon from "assets/icons/i.svg";
import "./CreateAccount.less";
import { emailPattern } from "utility/constant";
import { useEffect } from "react";
// import { getCompanyRegi } from "graphql/queries";
import SkeletonBlock from "utility/SkeletonBlocks";
import moment from "moment";

const CreateAccount = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [employer, setEmployer] = useState(null);
  const [employerId, setEmployerId] = useState("");
  const [inviting, setInviting] = useState(null);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const location = useLocation();

  const urlParams = new URLSearchParams(window.location.search);
  const procedureName = urlParams.get("procedureName");
  const employerInvite = urlParams.get("employerInvite");
  const dpcDoctor = urlParams.get("doctorID");

  const fetchEmployer = async () => {
    setskeletonLoader(true);
    const params = new URLSearchParams(location.search);
    setEmployerId(params.get("id"));
    setInviting(params.get("inviting") === "member" ? "member" : null);
    const myInit = {
      body: {
        companyID: params.get("id"),
      },
    };
    try {
      const res = await API.post(
        "bazarhealthREST",
        `/createEmployee/getCompanyName`,
        myInit
      );
      setEmployer(res?.data);
    } catch (error) {
      console.log(error);
    }

    setskeletonLoader(false);
  };

  useEffect(() => {
    fetchEmployer();
  }, [location.search]);

  const content = (
    <div>
      <p>
        <b>For Company</b>
      </p>
      <p>Enter company email id.</p>
    </div>
  );
  //Signup code
  const handleSignUp = async (e) => {
    if (e?.firstName?.length <= 1 || e?.lastName?.length <= 1) {
      setMessage("Please enter valid name");
    } else {
      setLoading(true);
      setMessage("");
      //For Users registering under Employer, checks if their exists any limit or not.
      if (employerId) {
        try {
          const myInit = {
            body: {
              companyID: employerId,
            },
          };
          await API.post(
            "bazarhealthREST",
            `/createEmployee/checkCompanyLimit`,
            myInit
          );
        } catch (error) {
          if (error.response) {
            setMessage(error.response.data);
          } else {
            setMessage(error.message);
          }
          setLoading(false);
          return;
        }
      }

      await Auth.signUp({
        username: e.email,
        password: e.password,
        attributes: {
          email: e.email,
          given_name: e.firstName,
          family_name: e.lastName,
        },
      })
        .then(async (response) => {
          localStorage.setItem("given_name", e.firstName);
          window?.freshpaint?.identify(e?.email, {
            email: e?.email,
            name: `${e?.firstName} ${e?.lastName}`,
          });
          if (employerId) {
            if (inviting === "member") {
              const myInit = {
                body: {
                  employeeID: employerId,
                  email: e?.email,
                  id: response.userSub,
                },
              };
              const res = await API.post(
                "bazarhealthREST",
                `/signup/dependant`,
                myInit
              );
            } else {
              const myInit = {
                body: {
                  companyID: employerId,
                  email: e?.email,
                  id: response.userSub,
                },
              };
              const res = await API.post(
                "bazarhealthREST",
                `/signup/employee`,
                myInit
              );
            }
          } else {
            await API.get(
              "bazarhealthREST",
              `/createEmployee?id=${response.userSub}`
            );
          }

          if (procedureName) {
            localStorage.setItem("procedureName", procedureName);
          }
          if (employerInvite) {
            localStorage.setItem("employerInvite", employerInvite);
          }
          if (dpcDoctor) {
            localStorage.setItem("dpcDoctor", dpcDoctor);
          }

          setLoading(false);
          navigate(
            dpcDoctor
              ? `/confirm-signup?doctorID=${dpcDoctor}`
              : `/confirm-signup`,
            {
              state: { feedback: "", userName: e.email, id: response.userSub },
            }
          );
        })
        .catch((err) => {
          setLoading(false);
          setMessage(err.message);
        });
    }
  };

  return (
    <Row className="signup-wrapper">
      <Col span={11} md={0} lg={11} xs={0} sm={0}>
        <div className="signup-page-image"></div>
      </Col>
      <Col
        className="form-footer-container"
        span={13}
        lg={13}
        md={24}
        xs={24}
        sm={24}
      >
        <Row justify="center" align="middle" className="w-100">
          <Col span={12} lg={12} md={12} xs={20} sm={20}>
            <div className="header"></div>
            <div className="form-logo-container">
              <img src={logo} alt="" className="bazar-logo" />
              <p className="welcome-text header-font">Welcome!</p>
              <p className="instruction sub-header-font">
                Please enter your details to get started.
              </p>
              {message && (
                <Alert
                  style={{ marginBottom: "10px" }}
                  type="error"
                  showIcon={false}
                  message={message}
                  banner
                />
              )}
              <Form
                requiredMark={false}
                layout="vertical"
                className="signup-form w-100"
                onFinish={(e) => handleSignUp(e)}
              >
                {employerId && inviting !== "member" && (
                  <>
                    {skeletonLoader ? (
                      <Row gutter={[20, 20]} className="mt-3">
                        <SkeletonBlock
                          style={{ width: 390, height: 50, marginBottom: 20 }}
                        />
                      </Row>
                    ) : (
                      <>
                        {" "}
                        <Form.Item
                          label={
                            <span className="sub-header-font">Employer</span>
                          }
                          name="employer"
                          className="firstname-form-item"
                          initialValue={employer}
                        >
                          <Input className="lastname" disabled />
                        </Form.Item>
                      </>
                    )}
                  </>
                )}

                <Form.Item
                  label={<span className="sub-header-font">First name</span>}
                  name="firstName"
                  className="firstname-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Please input your first name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input className="lastname" disabled={loading} />
                </Form.Item>

                <Form.Item
                  label={<span className="sub-header-font">Last name</span>}
                  name="lastName"
                  className="lastname-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Please input your last name!",
                    },
                    {
                      pattern: /^[a-zA-Z]+$/,
                      message: "symbol, number or whitespace are not allowed",
                    },
                  ]}
                >
                  <Input className="lastname" disabled={loading} />
                </Form.Item>

                <Form.Item
                  className="w-100 email-form-item"
                  label={
                    <Row justify="space-between w-100">
                      <span className="sub-header-font">Email</span>
                      {employerId ? (
                        ""
                      ) : (
                        <Popover content={content} mode="dark">
                          <img
                            src={icon}
                            alt=""
                            style={{
                              float: "right",
                              marginTop: "10px",
                            }}
                          />
                        </Popover>
                      )}
                    </Row>
                  }
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                    {
                      pattern: emailPattern,
                      message: "Please provide valid Email!",
                    },
                  ]}
                >
                  <Input className="email" disabled={loading} />
                </Form.Item>
                <Form.Item
                  label={<span className="sub-header-font">Password</span>}
                  className="password-form-item"
                  name="password"
                  rules={[
                    {
                      min: 8,
                      message: "Password must be minimum 8 characters.",
                    },
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password className="password" disabled={loading} />
                </Form.Item>
                <div className="p-font">
                  By Registration, You are agreeing to our
                  <a
                    href="https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/policies/Terms+%26+Conditions+Delphi.pdf"
                    target="_blank"
                    className="register-a"
                    rel="noreferrer"
                  >
                    {" "}
                    Terms & Conditions
                  </a>{" "}
                  &{" "}
                  <a
                    href="https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/policies/Privacy+Policy+Delphi.pdf"
                    target="_blank"
                    className="register-a"
                    rel="noreferrer"
                  >
                    {" "}
                    Privacy Policy.
                  </a>
                </div>
                <Button
                  className="signup-button"
                  htmlType="submit"
                  loading={loading}
                >
                  Register
                </Button>
                {/* <Button
                  className="login-google-button"
                  onClick={signInWithGoogle}
                >
                  <img src={google} alt="" style={{ marginRight: "10px" }} />{" "}
                  Register with google
                </Button> */}
                {/* <button onClick={() => Auth.federatedSignIn({provider: CognitoHostedUIIdentityProvider.Google })}>Open Google</button> */}
                <div className="register-text">
                  Already have an account?{" "}
                  {dpcDoctor || procedureName ? (
                    <>
                      <Link
                        to={`/login${
                          dpcDoctor
                            ? `?doctorID=${dpcDoctor}`
                            : `?procedureName=${procedureName}`
                        }`}
                        className="register-link sub-header-font"
                      >
                        Login Here
                      </Link>
                    </>
                  ) : (
                    <Link to="/login" className="register-link sub-header-font">
                      Login Here
                    </Link>
                  )}
                </div>
              </Form>
            </div>
          </Col>

          <Col span={24} className="hide-version-no">
            <Row justify="center" className="footer mobile-card">
              <Typography.Text className="footer-text p-font">
                Version {process.env.REACT_APP_VERSION} © {moment().year()}{" "}
                <a
                  href="https://delphihc.com/"
                  target="_blank"
                  style={{ color: "inherit" }}
                  rel="noreferrer"
                >
                  delphihc.com
                </a>{" "}
                copyright all rights reserved.
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CreateAccount;
