import { GetMulipleFileFromS3 } from "./uploadFileToS3";

export const handleChangeSpeciality = async (
  apiCall,
  value,
  dispatch,
  dispatchAction,
  locationCordinates
) => {
  await apiCall(999, locationCordinates, value)
    .then(async (response) => {
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key =
            item?.labProfilePhoto ||
            item?.surgeryCenterProfilePhoto ||
            item?.icProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.labProfilePhoto) item.labProfilePhoto = url;
              if (item?.icProfilePhoto) item.icProfilePhoto = url;
              if (item?.surgeryCenterProfilePhoto)
                item.surgeryCenterProfilePhoto = url;
            }
          }
          return item;
        })
      );
      dispatch({ type: dispatchAction, payload: updatedResponse });
    })
    .catch((error) => {
      console.log(error);
    });
};
