import { API } from "aws-amplify";
import { getEmployeeRegi, getMember } from "graphql/queries";
import { setUserData } from "store/actions/userData";

const navigateBasedOnUser = async (
  navigate,
  cognitoGroup,
  dpcDoctor,
  planName,
  planLocalStorage,
  procedureName,
  variable,
  dispatch
) => {
  const responce = await API.graphql({
    query: getEmployeeRegi,
    variables: {
      id: variable,
      filter: {
        verified: false,
      },
    },
    authMode: "AMAZON_COGNITO_USER_POOLS",
  });
  const userData = responce?.data?.getEmployeeRegi;
  dispatch(setUserData(responce.data.getEmployeeRegi));
  let planCategory = responce?.data?.getEmployeeRegi?.companyID?.planCategory;
  let accountStatus = responce?.data?.getEmployeeRegi?.accountStatus;
  if (cognitoGroup === "SuperAdmin") {
    navigate("/members");
  } else if (dpcDoctor || dpcDoctor !== null) {
    navigate(`/dpc-billing/`);
    localStorage.setItem("currentPlatform", "DPC");
  } else if (planName || planLocalStorage || (!accountStatus && planCategory)) {
    navigate(
      `/corporate-billing/?selectedPlan=${
        planName ? planName : planLocalStorage ? planLocalStorage : planCategory
      }&allowCheckout=yes`
    );
  } else if (cognitoGroup === "Employee") {
    if (userData) {
      if (userData?.emailType === "PUBLIC") {
        if (
          userData?.profile?.profileCompleted === false &&
          userData?.userType !== null
        ) {
          navigate("/user-profile", {
            state: { userName: userData?.id },
          });
        } else if (
          userData?.profile?.profileCompleted === false &&
          userData?.userType === null
        ) {
          navigate("/onboarding", {
            state: { id: userData?.id },
          });
        } else {
          navigate("/");
        }
      }
    }
    if (userData) {
      if (userData?.emailType === "PRIVATE") {
        if (userData?.userType === "INDIVIDUAL") {
          if (userData?.companyID === null) {
            navigate("/user-profile");
          }
        } else if (userData?.emailType === "PRIVATE") {
          if (userData?.companyID === null && userData.userType === null) {
            navigate("/onboarding", {
              state: { id: userData?.id },
            });
          }
        }
      }
    }

    if (userData) {
      if (
        userData?.userType === "INDIVIDUAL" &&
        userData?.profile?.profileCompleted === false
      ) {
        navigate("/user-profile");
      } else if (
        userData?.userType === "INDIVIDUAL" &&
        userData?.profile?.profileCompleted === true &&
        procedureName
      ) {
        navigate(`/user-procedure-detail/${procedureName}`);
      }
    }

    if (userData) {
      if (userData?.companyID) {
        if (userData?.verified === false) {
          navigate("/approval");
        } else {
          if (userData?.profile?.profileCompleted === false) {
            navigate("/user-profile");
          } else if (
            userData?.profile?.profileCompleted === true &&
            procedureName
          ) {
            navigate(`/user-procedure-detail/${procedureName}`);
          } else {
            navigate("/");
          }
        }
      } else {
        if (
          userData?.companyID === null &&
          userData.userType === "CORPORATE" &&
          userData?.verified === false
        ) {
          navigate("/approval");
        }
      }
    }
  } else if (cognitoGroup === "Admin") {
    if (userData) {
      if (userData?.companyID?.approved === false) {
        navigate("/approval");
      } else if (
        userData?.profile?.profileCompleted === true &&
        procedureName
      ) {
        navigate(`/user-procedure-detail/${procedureName}`);
      } else {
        navigate("/");
      }
    }
  } else if (cognitoGroup === "Member") {
    const responce = await API.graphql({
      query: getMember,
      variables: {
        id: variable,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    const memberData = responce?.data?.getMember;
    dispatch(setUserData(responce?.data?.getMember));

    if (memberData) {
      if (memberData?.emailType === "PUBLIC") {
        if (memberData?.userType === "INDIVIDUAL") {
          if (
            memberData?.accountStatus === "ACTIVE" &&
            memberData?.profileCompleted === true &&
            procedureName
          ) {
            navigate(`/user-procedure-detail/${procedureName}`);
          }
          if (
            memberData?.accountStatus === null ||
            memberData?.accountStatus === undefined
          ) {
            const myInit = {
              body: {
                userId: memberData?.id,
              },
            };
            const res = await API.post(
              "bazarhealthREST",
              `/billing/update/individualPlanQuantity`,
              myInit
            );

            navigate("/user-profile", {
              state: { userName: userData?.id },
            });
          } else if (memberData?.accountStatus === "ACTIVE") {
            navigate("/user-profile", {
              state: { userName: userData?.id },
            });
          }
        }
      }
    }

    if (memberData) {
      if (memberData?.emailType === "PRIVATE") {
        if (memberData?.userType === "INDIVIDUAL") {
          if (
            memberData?.accountStatus === null ||
            memberData?.accountStatus === undefined
          ) {
            const myInit = {
              body: {
                userId: memberData?.id,
              },
            };
            const res = await API.post(
              "bazarhealthREST",
              `/billing/update/individualPlanQuantity`,
              myInit
            );
            navigate("/user-profile", {
              state: { userName: userData?.id },
            });
          } else if (memberData?.accountStatus === "ACTIVE") {
            navigate("/user-profile", {
              state: { userName: userData?.id },
            });
          }
        }
      }
    }

    if (memberData) {
      if (memberData?.emailType === "PRIVATE") {
        if (
          memberData?.userType === "CORPORATE" ||
          memberData?.userType === "CORPORATE_ADMIN"
        ) {
          if (
            memberData?.accountStatus === null ||
            (memberData?.accountStatus === undefined &&
              userData?.verified === false)
          ) {
            navigate("/approval");
          } else {
            navigate("/user-profile");
          }
        }
      }
    }

    if (memberData) {
      if (memberData?.profile?.profileCompleted === true) {
        navigate("/");
      }
    }
  } else {
    navigate("/corporate-billing", {
      state: {
        id: userData?.id,
      },
    });
  }
};

export default navigateBasedOnUser;
