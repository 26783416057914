import { Button, Col, Divider, Row, Typography, Form, Input } from 'antd'
import React from 'react'
import BackButton from 'commons/BackButton'

export default function MedGroupDetail() {

  return (
    <>
        <Row justify="space-between">
            <BackButton />
            <Typography.Title level={4} className="text-center mb-0">Agility Orthopaedics</Typography.Title>
            <Button className="blue-border-btn"></Button>
            <Divider style={{margin: '8px 0'}} />
        </Row>

        <Row justify="center" className="update-form">

            <Col span={16} md={16} xs={24} sm={24}>
                <Typography.Title level={5} className="fw-bolder">Office Name : <span style={{fontWeight: 500}}>Agility Orthopaedics </span></Typography.Title>
                <Typography.Title level={5} className="fw-bolder">Medical Group : <span style={{fontWeight: 500}}>Medical Office</span> </Typography.Title>
                <Form
                    name="basic"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={() => console.log("Finish")}
                    autoComplete="off"
                    layout="vertical"
                    className="mt-30"
                >
                    <Row gutter={20}>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                                label="Current Password"
                                name="CurrentPassword"
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                                label="Group Login Pass"
                                name="GroupLoginPass"
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                                label="Confirm Group Login Pass"
                                name="ConfirmGroupLoginPass"
                            >
                                <Input.Password />
                            </Form.Item>
                        </Col>
                        <Col span={24} md={24} xs={24} sm={24}>
                            <Button size="large" className="blue-btn mt-20">Update</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    </>
  )
}
