import { Col, Row, Card, Typography, message, Skeleton } from "antd";
import Container from "commons/Container";
import React, { useCallback } from "react";
import "./Dpc.less";
import { StarFilled } from "@ant-design/icons";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import calender from "assets/icons/calender.svg";
import externalLink from "assets/icons/external-link.svg";
import location from "assets/icons/locationS.svg";
import opLocation from "assets/icons/dpcLocation.svg";

import { useState, useEffect } from "react";
import { GetDoctorDetails, ListSurgeryCenter } from "utility/action";
import dpcHome from "assets/delphy/dpchome.svg";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import SkeletonBlock from "utility/SkeletonBlocks";
import { useSelector } from "react-redux";

export default function DpcHome() {
  const [doctorData, setdoctordata] = useState([]);
  const [surgeryData, setSurgeryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const docID = localStorage.getItem("dpcDoctor");
  const [messageApi, contextHolder] = message.useMessage();
  const userProfileData = useSelector((state) => state.userReducer?.userData);

  const fetchDoctor = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetDoctorDetails(
        docID !== null ? docID : userProfileData?.dpcDoctorID
      );
      setdoctordata(response);

      const updatedResponse = [];
      console.log(response);
      if (response?.surgeryCenter) {
        for (const name of response?.surgeryCenter) {
          const filter = { surgeryCenterName: { eq: name } };
          const response = await ListSurgeryCenter(999, filter);

          const updatedItems = await Promise.all(
            response.map(async (item) => {
              const key = item?.surgeryCenterProfilePhoto;
              if (key && !key.startsWith("http")) {
                const url = await GetMulipleFileFromS3(key, "public");
                if (url && item?.surgeryCenterProfilePhoto) {
                  item.surgeryCenterProfilePhoto = url;
                }
              }
              return item;
            })
          );
          updatedResponse.push(...updatedItems);
        }
      }
      setSurgeryData(updatedResponse[0]);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  return (
    <>
      {contextHolder}
      <Container>
        <Card className="mt-30 mb-20 card-container ">
          <Row
            justify="center"
            className=" dpc-card"
            style={{
              height: "30vh",
              alignItems: "flex-start",
            }}
          >
            <Row className="dpchome-title">
              <div className="d-flex">
                <img src={dpcHome} alt="" />
                <h1> Enhanced Primary Care</h1>
              </div>
              <p className="mt-20">
                You have successfully purchased your Enhanced Primary Care
                membership.{" "}
              </p>
              <p>
                Our dedicated team will be reaching out to you shortly for the
                further process, adding an extra value to your healthcare
                benefits.
              </p>
            </Row>
          </Row>
        </Card>

        <Card className="doctor-profile procedure-detail-page procedure-details-page mobile-card  mt-30  mb-20">
          <Row gutter={[20, 20]} className="mt-30 dpc-details">
            <>
              {loading ? (
                <Col span={14} lg={14} md={14} sm={24} xs={24}>
                  <Skeleton
                    avatar
                    paragraph={{
                      rows: 10,
                    }}
                  />
                </Col>
              ) : (
                <Col span={14} lg={14} md={14} sm={24} xs={24}>
                  <div className="d-flex gap-4">
                    <img
                      src={doctorData?.doctorProfilePhoto}
                      alt=""
                      className="doctor-img"
                    />
                    <div className="doctor-detail">
                      <h4 className="doctor-name header-font">
                        {doctorData?.doctorName}, {doctorData?.doctorDegree}
                      </h4>
                      <h6 className="sub-header-font">
                        {doctorData?.doctorPracticeName}
                      </h6>
                      <div className="d-flex gap-3">
                        <p className="p-font">
                          <img src={facebook} alt="facebook Icon" />

                          {doctorData?.facebookRating === 0 ||
                          doctorData?.facebookRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {doctorData?.facebookRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>
                        <p className="p-font">
                          <img src={google} alt="facebook Icon" />

                          {doctorData?.googleRating === 0 ||
                          doctorData?.googleRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {doctorData?.googleRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Row className=" mt-10">
                    <Col span={23} className="mt-30">
                      {/* <b className="sub-header-font">About Me</b> */}
                      <p className="p-font">{doctorData?.aboutDoctor}</p>
                    </Col>

                    <Col span={12}>
                      <b className="sub-header-font">Specialty</b>
                      <p className="p-font">
                        {doctorData?.specialty ? doctorData?.specialty : ""}
                        {""}

                        <span style={{ color: "#646464", fontWeight: "400" }}>
                          {doctorData?.areaOfSpeciality
                            ? ` - ${doctorData?.areaOfSpeciality?.join(", ")}`
                            : "-"}
                        </span>
                      </p>
                    </Col>

                    <Col span={11}>
                      <b className="sub-header-font">Education</b>
                      <p className="p-font">{doctorData?.doctorDegree}</p>
                    </Col>

                    <Col span={12}>
                      <b className="sub-header-font">Medical School</b>
                      <p className="p-font">{doctorData?.doctorEducation}</p>
                    </Col>
                    <Col span={12}>
                      <b className="sub-header-font">Address</b>
                      <p className="p-font">
                        {doctorData?.doctorOfficeAdd}{" "}
                        {doctorData?.doctorOfficeCity},{" "}
                        {doctorData?.doctorOfficeState} -{" "}
                        {doctorData?.doctorOfficeZip}
                      </p>
                    </Col>

                    <Col span={12}>
                      <b className="sub-header-font">Fax</b>
                      <p className="p-font">{doctorData?.doctorOfficeFax}</p>
                    </Col>
                    <Col span={12}>
                      <b className="sub-header-font">Website</b>
                      <p className="p-font">{doctorData?.doctorWebsite}</p>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col lg={10} md={10} sm={24} xs={24}>
                {loading ? (
                  <Row gutter={[20, 20]} className="mt-3">
                    <SkeletonBlock style={{ width: 400, height: 150 }} />
                    <SkeletonBlock style={{ width: 400, height: 300 }} />
                  </Row>
                ) : (
                  <>
                    <Row className="appointment-days" gutter={[10, 0]}>
                      <Col lg={24} md={24} sm={24} xs={8}>
                        <div className="appointment-days-box">
                          <Typography.Title
                            level={5}
                            className="sub-header-font"
                          >
                            {/* <img src={calender} /> */}
                            <span className="p-search-head">
                              Contact Details{" "}
                            </span>
                          </Typography.Title>
                        </div>
                      </Col>

                      <Col lg={15} md={15} sm={15} xs={15}>
                        <b>Email</b>
                        <p>{doctorData?.doctorEmail}</p>
                      </Col>
                      <Col lg={9} md={9} sm={9} xs={9}>
                        <b>Phone</b>
                        <p>{doctorData?.doctorOfficePhone}</p>
                      </Col>
                    </Row>

                    <Row className="mt-30">
                      <Col
                        className="dpc-surgery-center"
                        lg={24}
                        md={24}
                        sm={24}
                        xs={8}
                      >
                        <div className="title">
                          <img src={opLocation} alt="" /> Practice at
                        </div>
                        <div className="d-flex gap-3 mt-20">
                          <img
                            src={surgeryData?.surgeryCenterProfilePhoto}
                            alt=""
                            className="dpc-surgery-img"
                          />
                          <div className="doctor-detail">
                            <h4 className="doctor-name header-font">
                              {surgeryData?.surgeryCenterName}
                            </h4>
                            <div className="d-flex ">
                              <p className="no-margin-b p-font">
                                <img src={facebook} alt="facebook Icon" />
                                {surgeryData?.surgeryCenterFaceBookRating}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                              {/* <Divider className="divider" type="vertical" /> */}
                              <p className="no-margin-b p-font">
                                <img
                                  src={google}
                                  alt="facebook Icon"
                                  style={{ marginLeft: "10px" }}
                                />
                                {surgeryData?.surgeryCenterGoogleRating}
                                <StarFilled style={{ color: "#e7a500" }} />
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="doctor-overview doctor-operates doctor-profile">
                          <p className="p-font d-flex">
                            <img
                              src={location}
                              alt="location"
                              className="location"
                              style={{ marginRight: "10px" }}
                            />{" "}
                            {surgeryData?.surgeryCenterAddLine1},{" "}
                            {surgeryData?.surgeryCenterAddLine2} -{" "}
                            {surgeryData?.surgeryCenterZip}
                          </p>
                          <p className="p-font">
                            {surgeryData?.aboutSurgeryCenter}
                          </p>

                          <div className="space-between">
                            <a
                              target="_blank"
                              className="sub-header-font"
                              href={surgeryData?.surgeryCenterWebsite}
                              // className="mt-3 d-block"
                            >
                              <img
                                src={externalLink}
                                alt=""
                                style={{ marginRight: "10px" }}
                              />
                              Go to Provider's Website{" "}
                            </a>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                )}
              </Col>
            </>
          </Row>
        </Card>
      </Container>
    </>
  );
}
