import { Button, Modal, Grid } from "antd";
import React, { useState, useEffect } from "react";

import "./Home.less";
import supportIcon from "assets/icons/support.svg";
import Chat from "../SupportChatbox/Chat";
import { useDispatch, useSelector } from "react-redux";
import { API, Auth } from "aws-amplify";
import { setUserID } from "store/actions/userID";
import { getEmployeeRegi, getMember } from "graphql/queries";
import { setUserData } from "store/actions/userData";
import { LOGOUT } from "store/actions";
import { useNavigate } from "react-router-dom";
import MenuBar from "./MenuBar";

export default function Home(props) {
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [destroyModal, setDestroyModal] = useState(false);

  const [isTourRunning, setIsTourRunning] = useState(false);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const navigate = useNavigate();

  const userType = useSelector(
    (state) => state?.userReducer?.userData?.userType
  );
  const companyID = useSelector(
    (state) => state?.userReducer?.userData?.companyRegiEmployeeId
  );
  const companyDetails = useSelector(
    (state) => state?.userReducer?.userData?.companyID
  );
  const billingStatus = useSelector(
    (state) => state?.userReducer?.userData?.billing_status
  );

  const isIndividual =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    userType === "INDIVIDUAL" &&
    !companyID &&
    !companyDetails;
  const isUnderStripeEmployer =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
    companyID !== null &&
    companyDetails !== null;

  const dispatch = useDispatch();

  const cognitoData = async () => {
    try {
      const userData = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      const result = Object.values(userData);
      dispatch(setUserID(result[9]?.sub));
      const usersID = result[9]?.sub;

      const cognitoGroup =
        userData.signInUserSession.accessToken.payload["cognito:groups"][0];

      if (cognitoGroup === "Member") {
        const responce = await API.graphql({
          query: getMember,
          variables: {
            id: usersID,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        dispatch(setUserData(responce.data.getMember));
      }
      // adding else for situation when user is other than member
      else {
        const responce = await API.graphql({
          query: getEmployeeRegi,
          variables: {
            id: usersID,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        dispatch(setUserData(responce.data.getEmployeeRegi));
        localStorage.setItem("planName", null);
      }
    } catch (error) {
      if (error?.message === "No current user") {
        await Auth.signOut();
        dispatch({
          type: LOGOUT,
        });
        localStorage.clear();
        navigate("/login");
      }
    } finally {
    }
  };

  useEffect(() => {
    cognitoData();
  }, []);

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
    setTimeout(() => {
      setDestroyModal(true);
    }, 2000);

    setTimeout(() => {
      setDestroyModal(false);
    }, 5000);
  };

  useEffect(() => {
    if (tourSteps === "IND_TOUR_HOME_START" && screens.xs) {
      setIsTourRunning(true);
      setJoyrideStepIndex(4);
    } else if (tourSteps === "IND_TOUR_HOME_START") {
      setIsTourRunning(true);
      setJoyrideStepIndex(0);
    } else if (tourSteps === "PREVIOUS_TOUR_TO_SERVICE") {
      setIsTourRunning(true);
      isIndividual || isUnderStripeEmployer
        ? setJoyrideStepIndex(2)
        : setJoyrideStepIndex(3);
    }
  }, [tourSteps]);

  return (
    <>
      {!screens.xs && <MenuBar isTourRunning={isTourRunning} />}

      {/* <Button
        type="primary"
        className="support-btn bottom-75 service-second-step"
        onClick={showModalSupport}
      >
        <img src={supportIcon} alt="" />
      </Button>

      <Modal
        open={isSupportModalOpen}
        onOk={handleOkSupport}
        onCancel={handleOkSupport}
        className="support-modal"
        destroyOnClose={destroyModal}
      >
        <Chat closeModal={handleOkSupport} />
      </Modal> */}
    </>
  );
}
