import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Radio,
  Upload,
  message,
  Avatar,
  Tabs,
  Image,
  Space,
  InputNumber,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import deleteIcon from "assets/icons/deletee.svg";
import React, { useCallback, useState } from "react";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import downloadIcon from "assets/icons/downloadIc.svg";
import previewIcon from "assets/icons/previewIc.svg";
import {
  GetPublicFileFromS3,
  uploadPublicFileToS3,
} from "utility/uploadFileToS3";
import { GetLabDetails, UpdateLabDetails } from "utility/action";
import {
  aboutPattern,
  cityPattern,
  emailPattern,
  phonePattern,
  ratingPattern,
  servicesNamePattern,
  streetAddressPattern,
  tenDigit,
  urlPattern,
  zipPattern,
} from "utility/constant";
import SuccessModel from "utility/SuccessModel";
import { API, Storage } from "aws-amplify";
import axios from "axios";
export default function LabsDetail() {
  const [labData, setlabData] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [key, setKey] = useState("");
  const [labPhoto, setLabPhoto] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const { TextArea } = Input;
  const location = useLocation();
  const labId = location?.state.id;
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();

  const [imageObject, setImageObject] = useState([]);
  const [activeStatus, setActiveStatus] = useState();
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [preSignedImg, setPreSignedimg] = useState([]);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const handleAddTestimonial = () => {
    setTestimonialData([
      ...testimonialData,
      { testimonialName: "", testimonialRating: "", review: "" },
    ]);
  };

  const handleupdateTestimonial = (
    index,
    newTestimonialName,
    newTestimonialRating,
    newReview
  ) => {
    const updatedTestimonialData = [...testimonialData];
    updatedTestimonialData[index] = {
      testimonialName: newTestimonialName,
      testimonialRating: newTestimonialRating,
      review: newReview,
    };
    setTestimonialData(updatedTestimonialData);
  };

  const handleDeleteTestimonial = (index) => {
    const updatedFaq = [...testimonialData];
    updatedFaq.splice(index, 1);
    setTestimonialData(updatedFaq);
  };

  function UserImg() {
    return (
      <img
        src={uploadImage === null ? labPhoto : uploadImage}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const fetchLab = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetLabDetails(labId);
      setActiveStatus(response.activeOnPortal);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);
      setlabData(response);
      if (response?.labProfilePhoto) {
        let labPhoto;

        if (response?.labProfilePhoto.startsWith("http")) {
          labPhoto = response?.labProfilePhoto;
        } else {
          labPhoto = await GetPublicFileFromS3(response?.labProfilePhoto);
        }
        setLabPhoto(labPhoto);
      }

      if (response?.labPhotos) {
        const fileKeyy = response?.labPhotos;
        const urls = [];
        for (const key of fileKeyy) {
          try {
            const url = await Storage.get(key, {
              level: "public",
              expires: 86400,
            });
            urls.push(url);
          } catch (error) {
            console.log(`Error getting pre-signed URL for ${key}: ${error}`);
          }
        }
        setPreSignedimg(urls);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [labId]);

  useEffect(() => {
    fetchLab();
  }, [fetchLab]);

  const updatelabCenter = async (values) => {
    try {
      setLoading(true);
      const testJson = JSON.stringify(testimonialData);
      const inputData = {
        id: labData?.id,
        labName: values?.labName,
        activeOnPortal: values?.activeOnPortal,
        labWebsite: values?.labWebsite,
        labOfficePhone: values?.labOfficePhone,
        labEmail: values?.labEmail,
        labOfficeCity: values?.labOfficeCity,
        labOfficeAdd: values?.labOfficeAdd,
        labOfficeState: values?.labOfficeState,
        labOfficeZip: values?.labOfficeZip,
        labServicesLink: values?.labServicesLink,
        labMapSource: values?.labMapSource,
        aboutLab: values?.aboutLab,
        labGoogleRating: values?.labGoogleRating,
        labFaceBookRating: values?.labFaceBookRating,
        labProfilePhoto: key === "" ? undefined : key,
        testimonial: testJson,
      };

      await UpdateLabDetails(inputData);
      fetchLab();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updatelabCenterImages = async (values) => {
    try {
      setLoading(true);
      let existingImages = labData?.labPhotos || [];
      const inputData = {
        id: labData?.id,
        labPhotos: [...existingImages, ...imageObject],
      };
      await UpdateLabDetails(inputData);
      fetchLab();
      setSuccessModel(true);
      form.resetFields();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    labName: labData?.labName,
    activeOnPortal: labData?.activeOnPortal,
    labWebsite: labData?.labWebsite,
    labEmail: labData?.labEmail,
    labOfficePhone: labData?.labOfficePhone,
    labOfficeCity: labData?.labOfficeCity,
    labOfficeAdd: labData?.labOfficeAdd,
    labOfficeState: labData?.labOfficeState,
    labOfficeZip: labData?.labOfficeZip,
    labServicesLink: labData?.labServicesLink,
    labMapSource: labData?.labMapSource,
    aboutLab: labData?.aboutLab,
    labGoogleRating: labData?.labGoogleRating,
    labFaceBookRating: labData?.labFaceBookRating,
    labProfilePhoto: labData?.labProfilePhoto,
    testimonial:
      testimonialData && testimonialData.length > 0
        ? testimonialData.map((item, index) => ({
          [`testimonialName_${index}`]: item?.testimonialName,
          [`testimonialRating_${index}`]: item?.testimonialRating,
          [`review_${index}`]: item?.review,
        }))
        : [{ testimonialName_0: "", testimonialRating_0: "", review_0: "" }],
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const props = {
    onChange({ file }) {
      setSaveDisabled(false);
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        const response = await axios.put(linkToUpload, file);
        // uploadPublicFileToS3(file)
        //   .then(async (res) => {
        //     const link = await GetPublicFileFromS3(res.key);
        setUploadImage(linkToUpload.split("?")[0]);
        setKey(linkToUpload.split("?")[0]);
        onSuccess();
      } catch (error) {
        onError();
      }
    },
  };

  const IcImagesProps = {
    customRequest({ file, onError, onSuccess }) {
      uploadPublicFileToS3(file)
        .then(async (res) => {
          setImageObject((item) => [...item, res.key]);
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    },
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onDelete = async (index) => {
    const newImageObject = [...imageObject];
    const deletedKey = newImageObject.splice(index, 1)[0];
    try {
      await Storage.remove(deletedKey, { level: "public" });
      setImageObject(newImageObject);
      const updatedImages = preSignedImg.filter((img, i) => i !== index);
      setPreSignedimg(updatedImages);

      messageApi.success({
        content: "Image Deleted Successfully",
        duration: 5,
      });
    } catch (error) {
      console.log("Error deleting image from S3:", error);
      messageApi.error({
        content: "Error Deleting Image",
      });
    }
  };

  const activeOnPortal = (e) => {
    setActiveStatus(e.target.value);
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {labData?.labName}
        </Typography.Title>
        <Button style={{ marginLeft: 110 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row className="service-detail-tab">
        <Tabs defaultActiveKey="1" tabPosition="left" className="w-100">
          <Tabs.TabPane tab="Details" key="1">
            <Row justify="start">
              <Col span={20} md={20} xs={24} sm={24}>
                {skeletonLoader ? (
                  <>
                    <Row gutter={[20, 20]} className="mt-3">
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 200 }} />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row
                      justify="center"
                      className="mt-20 upload-services-photo"
                    >
                      <div className="relative">
                        <Avatar
                          size={120}
                          icon={
                            labPhoto === null && uploadImage === null ? (
                              <UserOutlined />
                            ) : (
                              <UserImg />
                            )
                          }
                        />
                        <Upload
                          {...props}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          className="avatar-uploader"
                        >
                          <Button
                            shape="circle"
                            size="middle"
                            className="btn-primary-dual-color upload-btn"
                          >
                            <CameraOutlined />
                          </Button>
                        </Upload>
                      </div>
                    </Row>
                    <Row className="update-form">
                    <Form
                      name="basic"
                      initialValues={initialValues}
                      onFinish={updatelabCenter}
                      autoComplete="off"
                      layout="vertical"
                      className="mt-30"
                      onValuesChange={handleValuesChange}
                    >
                      <Row gutter={20}>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Name"
                            name="labName"
                            rules={[
                              {
                                pattern: servicesNamePattern,
                                message: "Please enter a valid name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Website"
                            name="labWebsite"
                            rules={[
                              {
                                pattern: urlPattern,
                                message: "Please input valid url",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Email"
                            name="labEmail"
                            rules={[
                              {
                                pattern: emailPattern,
                                message: "Please enter a valid email",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Phone"
                            name="labOfficePhone"
                            rules={[
                              {
                                pattern: tenDigit,
                                message: "Please enter a valid phone number",
                              },
                            ]}
                          >
                            <InputNumber
                              controls={false}
                              formatter={(value) => {
                                if (!value) return '';
                                // Remove all non-digit characters from the input value
                                const cleanedValue = value.replace(/\D/g, '');

                                // Format the phone number using regex or any desired logic
                                let formattedNumber = '';
                                if (cleanedValue.length > 0) {
                                  formattedNumber += `(${cleanedValue.slice(0, 3)}`;
                                }
                                if (cleanedValue.length > 3) {
                                  formattedNumber += `) ${cleanedValue.slice(3, 6)}`;
                                }
                                if (cleanedValue.length > 6) {
                                  formattedNumber += `-${cleanedValue.slice(6, 10)}`;
                                }
                                return formattedNumber;
                              }}
                              parser={(value) => {
                                const cleanedValue = value.replace(/\D/g, '');
                                return cleanedValue.slice(0, 10);
                              }}
                              style={{ width: '100%', borderRadius: "5px" }}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={24} xs={24} sm={24}>
                          <Form.Item
                            label="About"
                            name="aboutLab"
                            rules={[
                              {
                                pattern: aboutPattern,
                                message: "Please enter a valid about",
                              },
                            ]}
                          >
                            <TextArea rows={4} />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Street address"
                            name="labOfficeAdd"
                            rules={[
                              {
                                required: true,
                                message: "Please enter street address",
                              },
                              {
                                pattern: streetAddressPattern,
                                message: "Please enter a valid street address",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="City"
                            name="labOfficeCity"
                            rules={[
                              {
                                required: true,
                                message: "Please enter city name",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid city name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="State"
                            name="labOfficeState"
                            rules={[
                              {
                                required: true,
                                message: "Please enter state name",
                              },
                              {
                                pattern: cityPattern,
                                message: "Please enter a valid state name",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Zip code"
                            name="labOfficeZip"
                            rules={[
                              {
                                required: true,
                                message: "Please enter zip code",
                              },
                              {
                                pattern: zipPattern,
                                message: "Please enter a valid ZIP code",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={12} sm={24}>
                          <Form.Item
                            label="Google rating"
                            name="labGoogleRating"
                            rules={[
                              {
                                pattern: ratingPattern,
                                message:
                                  "Please enter a valid rating between 0 and 10",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Facebook rating"
                            name="labFaceBookRating"
                            rules={[
                              {
                                pattern: ratingPattern,
                                message:
                                  "Please enter a valid rating between 0 and 10",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={12} md={24} xs={24} sm={24}>
                          <Form.Item
                            label="Google map link"
                            name="labMapSource"
                            rules={[
                              {
                                message: "Please input valid url",
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col span={24} md={24} xs={24} sm={24}>
                          {initialValues?.testimonial?.map((item, index) => (
                            <div key={index}>
                              <Row gutter={[16, 16]}>
                                <Col span={12}>
                                  <Form.Item
                                    label={`Testimonial name ${index + 1}`}
                                    name={`testimonialName_${index}`}
                                    initialValue={
                                      item[`testimonialName_${index}`]
                                    }
                                    rules={[
                                      {
                                        pattern: servicesNamePattern,
                                        message: "Please enter a valid name",
                                      },
                                    ]}
                                  >
                                    <Input
                                      onChange={(e) =>
                                        handleupdateTestimonial(
                                          index,
                                          e.target.value,
                                          item[`testimonialRating_${index}`],
                                          item[`review_${index}`]
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                                <Col span={12}>
                                  <Form.Item
                                    label={`Testimonial rating ${index + 1}`}
                                    name={`testimonialRating_${index}`}
                                    initialValue={
                                      item[`testimonialRating_${index}`]
                                    }
                                    rules={[
                                      {
                                        pattern: ratingPattern,
                                        message:
                                          "Please enter a valid rating between 0 and 10",
                                      },
                                    ]}
                                  >
                                    <Input
                                      onChange={(e) =>
                                        handleupdateTestimonial(
                                          index,
                                          item[`testimonialName_${index}`],
                                          e.target.value,
                                          item[`review_${index}`]
                                        )
                                      }
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>

                              <Form.Item
                                label={`Review ${index + 1}`}
                                name={`review_${index}`}
                                initialValue={item[`review_${index}`]}
                                rules={[
                                  {
                                    pattern: aboutPattern,
                                    message: "Please enter a valid review",
                                  },
                                ]}
                              >
                                <Input.TextArea
                                  onChange={(e) =>
                                    handleupdateTestimonial(
                                      index,
                                      item[`testimonialName_${index}`],
                                      item[`testimonialRating_${index}`],
                                      e.target.value
                                    )
                                  }
                                />
                              </Form.Item>

                              {initialValues?.testimonial.length > 1 && (
                                <Row justify="space-between table-header">
                                  <Row align="middle">
                                    <Typography.Title
                                      level={5}
                                    ></Typography.Title>
                                  </Row>
                                  <Row align="middle">
                                    <Typography.Text
                                      className="hover-thumb"
                                      level={3}
                                      onClick={() =>
                                        handleDeleteTestimonial(index)
                                      }
                                      style={{ color: "red" }}
                                    >
                                      <u>Delete</u>
                                    </Typography.Text>
                                  </Row>
                                </Row>
                              )}
                            </div>
                          ))}

                          <Typography.Text
                            level={3}
                            onClick={handleAddTestimonial}
                            className="hover-thumb add-more-reviews"
                      
                          >
                            <u>+ Add More Testimonials</u>
                          </Typography.Text>
                        </Col>

                        <Col
                          span={12}
                          md={12}
                          xs={24}
                          sm={24}
                          className="mt-20"
                        >
                          <Form.Item
                            label="Account status"
                            name="activeOnPortal"
                          >
                            <Radio.Group onChange={activeOnPortal}>
                              <Radio value={true}>Active</Radio>
                              <Radio value={false}>Inactive</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Col>

                        <Col
                          span={24}
                          md={24}
                          xs={24}
                          sm={24}
                          className="center-btn"
                        >
                          <Button
                            size="large"
                            className="theme-btn mt-20"
                            htmlType="submit"
                            loading={loading}
                            disabled={saveDisabled}
                          >
                            Save
                          </Button>
                        </Col>
                      </Row>
                    </Form>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Images" key="2">
            <Form
              onFinish={updatelabCenterImages}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Row>
                <Col span={20} md={24} xs={24} sm={24}>
                  <Row gutter={[20, 20]}>
                    {preSignedImg.map((image, index) => (
                      <Col span={8} md={8} xs={24} sm={24}>
                        <div key={index} className="service-image-preview">
                          <Image
                            src={image}
                            className="service-image-preview-img"
                            onClick={() => setPreviewIndex(index)}
                            preview={{
                              visible:
                                isPreviewVisible && previewIndex === index,
                              onVisibleChange: (visible, prevVisible) => {
                                if (!visible) setPreviewIndex(null);
                                setPreviewVisible(visible);
                              },
                            }}
                          />
                          <div className="hide-icon">
                            <Space>
                              <img
                                src={previewIcon}
                                alt="preview-icon"
                                onClick={() => {
                                  setPreviewIndex(index);
                                  setPreviewVisible(true);
                                }}
                              />
                              <a href={image} download>
                                <img src={downloadIcon} alt="download-icon" />
                              </a>
                              <a onClick={() => onDelete(index)}>
                                <img src={deleteIcon} alt="download-icon" />
                              </a>
                            </Space>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Form.Item name="files">
                    <Upload
                      listType="picture-card"
                      onPreview={onPreview}
                      className="basic-img-upload"
                      {...IcImagesProps}
                      beforeUpload={beforeUpload}
                    >
                      <CloudUploadOutlined /> Click here to upload image
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                  <Button
                    size="large"
                    className="theme-btn mt-20"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Row>

      <SuccessModel
        message="Lab Details Updated Successfully"
        open={successModel}
      />
    </>
  );
}
