import { Col, Row, Card, Button, Modal, Input, Space, Grid } from "antd";
import Container from "commons/Container";
import unChecked from "assets/icons/unChecked.svg";

import React from "react";
import "./BillingPlan.less";
import checked from "assets/icons/checked.svg";
import manageBill from "assets/icons/managebill.svg";
import email from "assets/icons/email.svg";
import phone from "assets/icons/call.svg";

import { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import SuccessModel from "utility/SuccessModel";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { iOS } from "utility/constant";

export default function ManageBilling() {
  const { TextArea } = Input;
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [cancelModal, setCancelModal] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const userData = useSelector((state) => state.userReducer.userData);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dpcPlanDetails = searchParams.get("source");
  console.log(userData);
  const laptopImg =
    "https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/images/icons/inviteMember.png";
  const planObj = JSON.parse(
    dpcPlanDetails
      ? userData?.DpcDetails?.plan?.planDetails
      : userData?.plan?.planDetails || "{}"
  );
  console.log("planObj", planObj);
  const planCategory = userData?.companyID?.planCategory;

  const startDate = dpcPlanDetails
    ? userData?.DpcDetails?.plan?.createdAt
    : userData?.plan?.createdAt;

  const monthly = moment(startDate).add(30, "days");
  const yearly = moment(startDate).add(365, "days");

  const finalDays =
    planObj?.planName === "INDIVIDUAL_MEMBERSHIP_MONTHLY" ||
    planObj?.planName === "FAMILY_MEMBERSHIP_MONTHLY" ||
    planObj?.planName === "DPC_MONTHLY" ||
    planObj?.type == "MONTHLY"
      ? monthly
      : yearly;

  const nextBillingDateStr = finalDays.format("MM/DD/YYYY");
  console.log(nextBillingDateStr, "nextBillingDateStr", finalDays);
  const formattedDate = moment(nextBillingDateStr, "YYYY/MM/DD").format(
    "Do MMM YYYY"
  );

  const planName = planObj?.planName
    ?.replace(/_/g, " ")
    ?.replace(/ ([^ ]+)$/, " - $1")
    ?.toLowerCase()
    ?.replace(/\b\w/g, (c) => c.toUpperCase());

  const cancelSubscription = async () => {
    setLoading(true);
    let url = `/billing/cancelSubscription`;
    try {
      const myInit = {
        body: {
          stripeSubscriptionId: userData?.stripeSubscriptionID,
          name:
            userData?.profile?.firstName + " " + userData?.profile?.lastName,
          email: userData?.profile?.email,
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);

      setSuccessModel(true);
      // setTimeout(() => {
      //   setSuccessModel(false);
      // }, 3000);
      return result;
    } catch (e) {
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    } finally {
      setLoading(false);
      setCancelModal(false);
    }
  };

  return (
    <>
      <Container>
        {iOS() ? (
          <Card
            className="my-2"
            style={{
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row justify="center" gutter={20}>
              <div className="no-search">
                <div className="no-search-box">
                  <img src={laptopImg} alt="" style={{ width: "200px" }} />
                  <h6>
                    You can't manage billing in the app.
                    <br />
                    We know, it's not ideal.
                  </h6>
                  <h3>Use desktop to manage billing.</h3>
                </div>
              </div>
            </Row>
          </Card>
        ) : (
          <Card className="mt-10 mb-20 card-container">
            <Row
              className="managebill-card"
              style={{
                height: screens.xs ? "80vh" : "60vh",
                alignItems: "center",
              }}
            >
              <Col md={15} xs={15} className="plan-details">
                <h1>
                  {dpcPlanDetails
                    ? "Enhanced Primary Care Membership"
                    : "Subscribed plan"}
                </h1>
                <p>Simplify healthcare, increase access, reduce costs.</p>

                <div className="manage-billing-cards mt-30">
                  <h4> {planName}</h4>
                  <span className="pricing">${planObj?.planPrice}</span>{" "}
                  <span>
                    /{" "}
                    {planObj?.planName === "INDIVIDUAL_MEMBERSHIP_ANNUALLY" ||
                    planObj?.planName === "FAMILY_MEMBERSHIP_ANNUALLY"
                      ? "year"
                      : "month"}
                  </span>
                  <br />
                  {/* Hide plan update if the plan is custom */}
                  {planCategory !== "CUSTOM_PLAN" ? (
                    <Link
                      to={
                        dpcPlanDetails
                          ? `/dpc-billing/?selectedPlan=${planObj?.planName}`
                          : userData?.billing_status === "REQUIRED" &&
                            userData?.userType === "CORPORATE_ADMIN"
                          ? `/corporate-billing/?selectedPlan=${planObj?.planName}`
                          : `/billing/?selectedPlan=${planObj?.planName}`
                      }
                    >
                      <Button className="theme-btn plan-btn mt-30">
                        Update plan
                      </Button>
                    </Link>
                  ) : (
                    ""
                  )}
                </div>
              </Col>

              <Col md={8} xs={9}>
                <img src={manageBill} alt="" style={{ width: "100%" }} />
              </Col>
            </Row>

            <Row
              justify="center"
              className="billing-features mt-30"
              style={{ paddingLeft: "15px" }}
            >
              <Col md={24} xs={24} className="services">
                <h5>Features</h5>
              </Col>

              {dpcPlanDetails ? (
                <>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Unlimited Access to your PCP
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Call, Text, Facetime with your Dr.
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Streamlined Referral Process
                    </p>
                  </Col>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Same Day/Next Day Appointments
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Increased Care Management
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Healthcare Cost Savings
                    </p>
                  </Col>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Appointment scheduling
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Stronger Dr./Patient Relationship
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Advanced Care Advocacy
                    </p>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Delphi platform
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Member education
                    </p>
                  </Col>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Healthcare advocacy
                    </p>
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Bill review
                    </p>
                  </Col>
                  <Col md={8} xs={24} className="mt-10">
                    <p>
                      <img src={checked} alt="" srcset="" />
                      Appointment scheduling
                    </p>
                    {planName === "FAMILY_MEMBERSHIP_MONTHLY" ||
                    planName === "INDIVIDUAL_MEMBERSHIP_MONTHLY" ? (
                      <p>
                        <img
                          src={
                            planObj?.planName ===
                              "INDIVIDUAL_MEMBERSHIP_MONTHLY" ||
                            planObj?.planName ===
                              "INDIVIDUAL_MEMBERSHIP_ANNUALLY"
                              ? unChecked
                              : checked
                          }
                          alt=""
                          srcset=""
                        />
                        Invite upto 3 dependents
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>
                </>
              )}

              <Col md={24} className="subscription-date mt-20">
                <p>
                  Subscription end date :{" "}
                  <span className="date"> {nextBillingDateStr}</span>{" "}
                </p>
              </Col>

              <Col md={24} className="subscription-date mt-30 mb-30">
                <Button
                  className="theme-btn plan-btn"
                  onClick={() => setConfirmModal(true)}
                >
                  Cancel Subscription
                </Button>
              </Col>
            </Row>

            <Row className="manage-bottom-box mt-20"></Row>
          </Card>
        )}
        <Modal
          title="Basic Modal"
          onOk={() => {
            setCancelModal(false);
          }}
          centered
          open={cancelModal}
          onCancel={() => {
            setCancelModal(false);
          }}
          className="reward-success-modal not-sure-modal"
          destroyOnClose={true}
          closable={false}
          footer={null}
          width={600}
        >
          {/* <img src={notSure} alt="Delete Img" /> */}
          <h6 className="header-font">Contact your advocate</h6>
          <p className="sub-header-font">
            Are you sure you want to cancel your subscription?
          </p>

          <Card
            className="doctor-profile bottom-padding mt-30"
            style={{ background: "#F2F9FD" }}
          >
            <Row className="cancel-reason">
              <Col md={24}>
                <span> Get in touch with us</span>
              </Col>
              <Col md={12} xs={12}>
                <p>
                  <img src={email} alt="Delete Img" />
                  ear@delphillc.com
                </p>
              </Col>

              <Col md={12} xs={12}>
                <p>
                  <img src={phone} alt="Delete Img" />
                  (603) 537-6037
                </p>
              </Col>
            </Row>
          </Card>

          <Row className="mt-20">
            <TextArea rows={3} placeholder="Reason" />
          </Row>

          <div className="form-bottom mt-30">
            <Button
              type="primary"
              className="theme-btn button-font"
              style={{ width: "106px" }}
              onClick={cancelSubscription}
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </Modal>
        <Modal
          title="Basic Modal"
          open={confirmModal}
          onOk={() => setConfirmModal(false)}
          centered
          onCancel={() => setConfirmModal(false)}
          className="reward-success-modal"
          footer={null}
        >
          <h6 className="mt-20">
            Are you sure you want to cancel your subscription?{" "}
          </h6>

          <Space>
            <Button
              type="primary"
              className="theme-btn"
              style={{ width: "106px" }}
              onClick={() => {
                setCancelModal(true);
                setConfirmModal(false);
              }}
              loading={loading}
            >
              Yes
            </Button>
            <Button
              type="primary"
              className="theme-btn "
              onClick={() => setConfirmModal(false)}
              style={{ width: "106px" }}
            >
              Cancel
            </Button>
          </Space>
        </Modal>
        <SuccessModel
          message={`Your plan has been cancelled, as requested, with effect from ${formattedDate}`}
          open={successModel}
        />
      </Container>
    </>
  );
}
