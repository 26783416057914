import { Card, Row } from "antd";
import Container from "commons/Container";
import React, { useState } from "react";
import successIcon from "assets/icons/Success.svg";
import { useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import awsmobile from "aws-exports";

export default function AppointmentSuccess() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const appointmentID = searchParams.get("appointmetnID");
  const emailID = searchParams.get("emailuuID");
  const appointmentFor = searchParams.get("appointmentFor");


  const handleEmailReceivedCheck = async () => {
    try {
      const response = await axios.post(
        `${awsmobile.aws_cloud_logic_custom[0].endpoint}/appointment/emailRecievedCheck`,
        {
          appointmentID: appointmentID,
          emailuuID: emailID,
          appointmentFor: appointmentFor
        }
      );

      setLoading(false)
      setTimeout(() => {
          window.close();
          window.history.back();
      }, 3000);
    } catch (error) {
      console.error(error);
      // Handle error
    }
  };

  useEffect(() => {
    handleEmailReceivedCheck();
    // Increase state with every 1 second
  }, []);

  return (
    <Container>
      <Card className="my-2" loading={loading}>
        <Row justify="center" gutter={20} >
          <div className="no-search">
            <div className="no-search-box">
              <img src={successIcon} alt="" style={{ width: "200px" }} />
              <h6> Thank you for the confirmation!!</h6>
            </div>
          </div>
        </Row>
      </Card>
    </Container>
  );
}
