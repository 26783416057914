import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function InfusionCenterTour(props) {
  const icServiceSteps = [
    {
      target: ".ic-first-step",
      content: (
        <TourStepContent
          heading="Infusion Center"
          details="Click to choose your desired infusion center and proceed to the next steps for assistance."
          numbers={{ currentStep: 1, totalSteps: 6 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={icServiceSteps}
        run={props?.icTour}
        stepIndex={props?.icTourIndex}
        setTourIndex={props?.setIcTourIndex}
      />
    </>
  );
}
