import * as actions from "../actions/index";

const initialState = {
    doctor: null,
  };

const doctorReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_DOCTOR_DATA:
            return {
              ...state,
              doctor: action.payload,
            };

        default:
            return state;
    }
}
export default doctorReducer;
