import {
  Col,
  Typography,
  Row,
  Card,
  Button,
  Modal,
  Input,
  message,
  Empty,
  Grid,
} from "antd";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import "./Services.less";
import "./ImagineCenterModel.less";
import successIcon from "assets/icons/Success.svg";
import { Link, useNavigate } from "react-router-dom";
import doctorBag from "assets/icons/doctor-bag.svg";
import imagingCenter from "assets/icons/imaging-center.svg";
import infusionCenter from "assets/icons/infusion-center.svg";
import labIcon from "assets/icons/lab.svg";
import surgicalIcon from "assets/icons/surgical.svg";
import therapyIcon from "assets/icons/therapy.svg";
import healthlogo from "assets/img/healthLogo.svg";

import notSure from "assets/icons/notsure.svg";
import email from "assets/icons/email.svg";
import phone from "assets/icons/call.svg";

import { setDoctorData } from "store/actions/doctorAction";
import { useDispatch, useSelector } from "react-redux";
import { setICData } from "store/actions/IcAction";
import { setSpecialityOption } from "store/actions/specialityOptions";
import {
  GetEmployeeDetails,
  GetMemberProfile,
  ListNearByDoctor,
  ListNearByImagingCenters,
  ListNearBySurgeryCenter,
  UpdateEmployeeProfile,
  UpdateMemberProfile,
} from "utility/action";
import { setSurgeryState } from "store/actions/surgeryAction";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import { setAreaOfSpeciality } from "store/actions/areaOfSpecialty";
import { setSurgerySpeciality } from "store/actions/surgerySpeciality";
import Chat from "pages/user/SupportChatbox/Chat";
import { setTourSteps } from "store/actions/tourSteps";
import {
  DOCTOR_DETAILS_TOUR_START,
  DOCTOR_TOUR_START,
  END_TOUR,
  IMAGING_DETAILS_TOUR_START,
  IMAGING_TOUR_START,
  INFUSION_DETAILS_TOUR_START,
  INFUSION_TOUR_START,
  LAB_DETAILS_TOUR_START,
  LAB_TOUR_START,
  PT_DETAILS_TOUR_START,
  PT_TOUR_START,
  SURGICAL_DETAILS_TOUR_START,
  SURGICAL_TOUR_START,
} from "utility/constant";
import { setUserData } from "store/actions/userData";
import {
  doctorSpeciality,
  imagingCenterList,
  surgeryCenter,
} from "./SpecialityOptions";
import LabTour from "./Tours/LabTour";
import DoctorTour from "./Tours/DoctorTour";
import ImagingTour from "./Tours/ImagingTour";
import InfusionCenterTour from "./Tours/InfusionCenterTour";
import SurgeryTour from "./Tours/SurgeryTour";
import PtTour from "./Tours/PtTour";

export default function Services() {
  const [messageApi, contextHolder] = message.useMessage();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [open, setOpen] = useState(false);
  const [openDoctor, setOpenDoctor] = useState(false);
  const [openSurgery, setOpenSurgery] = useState(false);
  const [notSureModal, setNotSureModal] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [activeColumn, setActiveColumn] = useState(999);
  const [destroyModal, setDestroyModal] = useState(false);
  const [specialty, setSpecialty] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector((state) => state.userReducer?.userData);
  const firstVisit = userData?.profile?.firstVisit;
  const location = userData?.profile?.location;

  const showModal = () => {
    setSupportIsModalOpen(true);
  };

  const [isTourRunning, setIsTourRunning] = useState(false);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);
  let tourNumberForDoc = screens.xs ? 3 : 5;

  const [docTour, setDocTour] = useState(false);
  const [docTourIndex, setDocTourIndex] = useState(0);

  const [imgTour, setImgTour] = useState(false);
  const [imgTourIndex, setImgTourIndex] = useState(0);

  const [labTour, setLabTour] = useState(false);
  const [labTourIndex, setLabTourIndex] = useState(0);

  const [icTour, setIcTour] = useState(false);
  const [icTourIndex, setIcTourIndex] = useState(0);

  const [surgicalTour, setSurgicalTour] = useState(false);
  const [surgicalTourIndex, setSurgicalTourIndex] = useState(0);

  const [ptTour, setPtTour] = useState(false);
  const [ptTourIndex, setPtTourIndex] = useState(0);

  // for chat box
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
    setTimeout(() => {
      setDestroyModal(true);
    }, 2000);

    setTimeout(() => {
      setDestroyModal(false);
    }, 5000);
  };

  const handleOk = () => {
    setActiveColumn(999);
    setOpenDoctor(false);
    setOpen(false);
    setSuccessModel(false);
    setOpenSurgery(false);
  };

  const handleSearch = async (
    apiFunc,
    location,
    areaOfSpeciality,
    updateFunc,
    optionValue,
    navigationPath,
    specialty
  ) => {
    setloading(true);
    await apiFunc(
      999,
      {
        lat: location?.lat,
        lon: location?.lon,
      },
      areaOfSpeciality,
      navigationPath === "/doctor-list" ||
        navigationPath === "/surgical-center-list"
        ? specialty
        : null
    )
      .then(async (response) => {
        response.sort(
          (a, b) => parseFloat(a.distance) - parseFloat(b.distance)
        );
        const updatedResponse = await Promise.all(
          response.map(async (item) => {
            const key =
              item?.doctorProfilePhoto ||
              item?.surgeryCenterProfilePhoto ||
              item?.labProfilePhoto ||
              item?.icProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
                if (item?.surgeryCenterProfilePhoto)
                  item.surgeryCenterProfilePhoto = url;
                if (item?.labProfilePhoto) item.labProfilePhoto = url;
                if (item?.icProfilePhoto) item.icProfilePhoto = url;
              }
            }
            return item;
          })
        );
        setloading(false);
        dispatch(updateFunc(updatedResponse));
        dispatch(
          navigationPath === "/surgical-center-list"
            ? setSurgerySpeciality(specialty)
            : setSpecialityOption(
                navigationPath === "/doctor-list" ? specialty : areaOfSpeciality
              )
        );
        dispatch(setAreaOfSpeciality(areaOfSpeciality));
        navigate(navigationPath, {
          state: {
            specialty: areaOfSpeciality,
            specialtyName: specialty,
          },
        });
      })
      .catch((error) => {
        console.log(error);
        setloading(false);
        messageApi.open({
          type: "error",
          content: `Error Fetching Data`,
        });
      });
  };

  const handleClick = async (specialty, areaOfSpeciality) => {
    setloading(true);
    await handleSearch(
      ListNearByDoctor,
      location,
      areaOfSpeciality,
      setDoctorData,
      areaOfSpeciality,
      "/doctor-list",
      specialty
    );
    setloading(false);
  };

  const handleSurgeryCenter = async (specialty, areaOfSpeciality) => {
    await handleSearch(
      ListNearBySurgeryCenter,
      location,
      areaOfSpeciality,
      setSurgeryState,
      areaOfSpeciality,
      "/surgical-center-list",
      specialty
    );
  };

  const handleImagingClick = async (areaOfSpeciality) => {
    await handleSearch(
      ListNearByImagingCenters,
      location,
      areaOfSpeciality,
      setICData,
      areaOfSpeciality,
      "/imaging-center-list"
    );
  };

  const handleClickActive = (index) => {
    setActiveColumn(index);
  };

  const getColumnClass = (index) => {
    if (index === activeColumn) {
      return "active-column";
    }
    return "";
  };

  const filteredServiceBoxes = doctorSpeciality.filter(
    (box) =>
      box.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (box.description &&
        box.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const surgeryServiceBoxes = surgeryCenter.filter(
    (box) =>
      box.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (box.description &&
        box.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  const icServiceBoxes = imagingCenterList.filter(
    (box) =>
      box.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (box.description &&
        box.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  useEffect(() => {
    let tourSetter;
    switch (tourSteps) {
      case DOCTOR_TOUR_START:
        tourSetter = setDocTour;
        break;
      case IMAGING_TOUR_START:
        tourSetter = setImgTour;
        break;
      case LAB_TOUR_START:
        tourSetter = setLabTour;
        break;
      case INFUSION_TOUR_START:
        tourSetter = setIcTour;
        break;
      case SURGICAL_TOUR_START:
        tourSetter = setSurgicalTour;
        break;
      case PT_TOUR_START:
        tourSetter = setPtTour;
        break;
      default:
        tourSetter = null; // No tour to set
    }

    if (tourSetter) {
      setTimeout(() => {
        tourSetter(true);
      }, 400);
    }
  }, [tourSteps]);

  const handleFirstVisit = async (value) => {
    try {
      if (value === "doctor") {
        setDocTour(true);
      } else if (value === "surgery") {
        setSurgicalTour(true);
      } else if (value === "imaging") {
        setImgTour(true);
      } else if (value === "infusion") {
        setIcTour(true);
      } else if (value === "lab") {
        setLabTour(true);
      } else if (value === "pt") {
        setPtTour(true);
      }

      const inputData = {
        email: userData?.emailID,
        firstVisit: {
          doctor: value === "doctor" ? true : firstVisit?.doctor,
          imaging: value === "imaging" ? true : firstVisit?.imaging,
          infusion: value === "infusion" ? true : firstVisit?.infusion,
          lab: value === "lab" ? true : firstVisit?.lab,
          physicalTherapy: value === "pt" ? true : firstVisit?.physicalTherapy,
          surgery: value === "surgery" ? true : firstVisit?.surgery,
          firstVisit: firstVisit?.firstVisit === true ? true : false,
          homePage: firstVisit?.homePage === true ? true : false,
          procedure: firstVisit?.procedure === true ? true : false,
          reward: firstVisit?.reward === true ? true : false,
        },
      };
      if (userData?.role === "DEPENDANT") {
        await UpdateMemberProfile(inputData);
        const res = await GetMemberProfile(userData?.id);
        dispatch(setUserData(res));
      } else {
        await UpdateEmployeeProfile(inputData);
        const res = await GetEmployeeDetails(userData?.id);
        dispatch(setUserData(res));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleServiceClick = async (
    tourType,
    setOpenFunction,
    setTourIndexFunction
  ) => {
    if (tourType) {
      setTimeout(() => {
        setOpenFunction(true);
      }, 400);

      setTimeout(() => {
        setTourIndexFunction(1);
      }, 700);
    } else {
      setOpenFunction(true);
    }
  };

  const handleEndTour = () => {
    setSurgicalTour(false);
    setIcTour(false);
    setLabTour(false);
    dispatch(setTourSteps(END_TOUR));
    setPtTour(false);
    setImgTour(false);
    setOpen(false);
    dispatch(setTourSteps(END_TOUR));
    setOpenSurgery(false);
    setImgTourIndex(0);
    setSurgicalTourIndex(0);
  };

  const skipTour = (closeModal, Imaging) => {
    if (closeModal) {
      setOpenDoctor(false);
      setDocTour(false);
      setDocTourIndex(0);
      dispatch(setTourSteps(END_TOUR));
    } else if (Imaging) {
      setImgTour(false);
      setImgTourIndex(0);
      dispatch(setTourSteps(END_TOUR));
    } else {
      setDocTour(false);
      dispatch(setTourSteps(END_TOUR));
    }
  };

  return (
    <>
      {contextHolder}

      {labTour && (
        <LabTour
          labTour={labTour}
          labTourIndex={labTourIndex}
          setLabTourIndex={setLabTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {docTour && (
        <DoctorTour
          docTour={docTour}
          docTourIndex={docTourIndex}
          setDocTourIndex={setDocTourIndex}
          skipTour={skipTour}
        />
      )}

      {imgTour && (
        <ImagingTour
          imgTour={imgTour}
          imgTourIndex={imgTourIndex}
          setImgTourIndex={setImgTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {icTour && (
        <InfusionCenterTour
          icTour={icTour}
          icTourIndex={icTourIndex}
          setIcTourIndex={setIcTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {surgicalTour && (
        <SurgeryTour
          surgicalTour={surgicalTour}
          surgicalTourIndex={surgicalTourIndex}
          setSurgicalTourIndex={setSurgicalTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {ptTour && (
        <PtTour
          ptTour={ptTour}
          ptTourIndex={ptTourIndex}
          setPtTourIndex={setPtTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      <Card className="mb-2 pb_10 mobile-card disable-scrolling">
        {/* <button onClick={() => setDocTour(true)}>Start</button> */}
        <Row gutter={{ md: 0, xs: 16 }}>
          <Col xl={8} md={12} sm={12} xs={12}>
            <div
              className="service-box doc-first-step"
              onClick={
                firstVisit?.doctor === false
                  ? () => handleFirstVisit("doctor")
                  : () =>
                      handleServiceClick(
                        docTour,
                        setOpenDoctor,
                        setDocTourIndex
                      )
              }
            >
              <img src={doctorBag} alt="" />
              <Typography.Title level={5} className="header-font">
                Doctor
              </Typography.Title>
            </div>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12}>
            <div
              className="service-box sc-first-step"
              onClick={
                firstVisit?.surgery === false
                  ? () => handleFirstVisit("surgery")
                  : () =>
                      handleServiceClick(
                        surgicalTour,
                        setOpenSurgery,
                        setSurgicalTourIndex
                      )
              }
            >
              <img src={surgicalIcon} alt="" />
              <Typography.Title level={5} className="header-font">
                Surgical Center
              </Typography.Title>
            </div>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12}>
            <div
              className="service-box img-first-step"
              onClick={
                firstVisit?.imaging === false
                  ? () => handleFirstVisit("imaging")
                  : () => handleServiceClick(imgTour, setOpen, setImgTourIndex)
              }
            >
              <img src={imagingCenter} alt="" />
              <Typography.Title level={5} className="header-font">
                Imaging Center
              </Typography.Title>
            </div>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12}>
            <Link
              to={
                firstVisit?.infusion === false
                  ? undefined
                  : "/up-infusion-center"
              }
              onClick={() => {
                if (firstVisit?.infusion === false) {
                  handleFirstVisit("infusion");
                } else if (icTour) {
                  dispatch(setTourSteps(INFUSION_DETAILS_TOUR_START));
                }
              }}
            >
              <div className="service-box ic-first-step">
                <img src={infusionCenter} alt="" />
                <Typography.Title level={5} className="header-font">
                  Infusion Center
                </Typography.Title>
              </div>
            </Link>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12}>
            <Link
              to={firstVisit?.lab === false ? undefined : "/lab-center-list"}
              onClick={() => {
                if (firstVisit?.lab === false) {
                  handleFirstVisit("lab");
                } else if (labTour) {
                  dispatch(setTourSteps(LAB_DETAILS_TOUR_START));
                }
              }}
            >
              <div className="service-box lab-first-step">
                <img src={labIcon} alt="" />
                <Typography.Title level={5} className="header-font">
                  Lab
                </Typography.Title>
              </div>
            </Link>
          </Col>
          <Col xl={8} md={12} sm={12} xs={12}>
            {firstVisit?.physicalTherapy === false ? (
              <Link>
                <div
                  className="service-box pt-first-step"
                  onClick={() => handleFirstVisit("pt")}
                >
                  <img src={therapyIcon} alt="" />
                  <Typography.Title level={5} className="header-font">
                    Physical Therapy
                  </Typography.Title>
                </div>
              </Link>
            ) : (
              <Link
                to={
                  firstVisit?.physicalTherapy === false
                    ? undefined
                    : "/physical-therapy-list"
                }
              >
                <div
                  className="service-box pt-first-step"
                  onClick={() => {
                    if (firstVisit?.physicalTherapy === false) {
                      handleFirstVisit("pt");
                    } else if (ptTour) {
                      dispatch(setTourSteps(PT_DETAILS_TOUR_START));
                    }
                  }}
                >
                  <img src={therapyIcon} alt="" />
                  <Typography.Title level={5} className="header-font">
                    Physical Therapy
                  </Typography.Title>
                </div>
              </Link>
            )}
          </Col>
        </Row>
        {/* <p className="suggest-btn button-font fw-600 ">
          Didn't find what you are looking for?
          <Button
            type="primary"
            className={`button-font fw-600 pb-0 service-first-step`}
            onClick={showModal}
          >
            Ask your Advocate
          </Button>
        </p> */}

        <Modal
          title="Basic Modal"
          open={successModel}
          onOk={handleOk}
          centered
          onCancel={handleOk}
          className="reward-success-modal"
        >
          <img src={successIcon} alt="Success Img" />
          <h6>Thank you for your suggestion</h6>
        </Modal>
      </Card>

      {/* Doctor Modal */}
      <Modal
        centered
        open={openDoctor}
        onOk={handleOk}
        onCancel={docTour ? "" : handleOk}
        width={screens.xxl ? "50%" : 769}
        className="appointment-modal desired-modal"
        footer={null}
      >
        <div className="imagine-process">
          <div className="text-center">
            <Row justify="center" align="middle">
              <img src={healthlogo} alt="" style={{ marginRight: "1rem" }} />
              <h6 className="header-font">Select a specialty</h6>
            </Row>
          </div>

          <Input
            prefix={<SearchOutlined />}
            placeholder="Find Specialty"
            allowClear
            size="large"
            className="bodyarea-search mt-20 search-font"
            onChange={(e) => setSearchQuery(e.target.value.trim())}
          />

          <Row gutter={[10, 10]} className="body-area-surgery mt-20">
            {filteredServiceBoxes.length > 0 ? (
              filteredServiceBoxes.map((box) => (
                <Col md={8} xs={12} key={box.id}>
                  <div
                    className={`service-box ${getColumnClass(box.id - 1)} ${
                      box.id === tourNumberForDoc ? "doc-second-step" : ""
                    }`}
                    onClick={() => {
                      handleClickActive(box.id - 1);
                      setSpecialty({
                        specialty:
                          box.id === 1
                            ? "General Surgeon"
                            : box.id === 2
                            ? "Gastroenterologist"
                            : box.id === 3
                            ? "Neuro Surgeon"
                            : box.id === 4
                            ? "Ophthalmologist"
                            : box.id === 5
                            ? "Otolaryngologists"
                            : box.id === 6
                            ? "Orthopaedic"
                            : box.id === 7
                            ? "Podiatrist"
                            : box.id === 8
                            ? "Pain Management"
                            : box.id === 9
                            ? "Physiatrist"
                            : box.id === 10
                            ? "Sports Medicine"
                            : box.id === 11
                            ? "Urology"
                            : "",

                        areaOfSpeciality:
                          box.id === 1
                            ? []
                            : box.id === 2
                            ? ["Stomach"]
                            : box.id === 3
                            ? ["Head", "Spine and Back"]
                            : box.id === 4
                            ? ["Eyes"]
                            : box.id === 5
                            ? ["Ear Nose and Throat"]
                            : box.id === 6
                            ? [
                                "Ankle and Foot",
                                "Elbow",
                                "Hip",
                                "Hand and Wrist",
                                "Knee",
                                "Shoulder",
                                "Spine and Back",
                                "Neck",
                                "Head",
                                "Sports Medicine",
                              ]
                            : box.id === 7
                            ? ["Ankle and Foot"]
                            : box.id === 8
                            ? ["Spine and Back", "Neck"]
                            : box.id === 9
                            ? ["Spine and Back", "Head", "Neck"]
                            : box.id === 10
                            ? []
                            : box.id === 11
                            ? ["Internal"]
                            : "",
                      });
                      if (docTour) {
                        setDocTourIndex(2);
                      }
                    }}
                  >
                    <img
                      src={box.image}
                      alt=""
                      className={
                        box.id === 1
                          ? "sports-medicine-img"
                          : box.id !== 6
                          ? "sports-medicine-text"
                          : ""
                      }
                    />
                    <Typography.Title level={5} className="sub-header-font">
                      {box.title}
                    </Typography.Title>
                    {box.description && (
                      <p className="p-font">{box.description}</p>
                    )}
                  </div>
                </Col>
              ))
            ) : (
              <div className="no-data">
                <Col md={24}>
                  <Empty />
                </Col>
              </div>
            )}
          </Row>

          <Row justify="space-between mt-20">
            <Row align="middle">
              <Button
                className="theme-border-btn button-font"
                onClick={() => {
                  setNotSureModal(true);
                }}
              >
                Not Sure?
              </Button>
            </Row>

            <Row align="middle">
              <Button
                className="theme-btn button-font doc-third-step"
                htmlType="submit"
                loading={loading}
                disabled={activeColumn === 999 ? true : false}
                onClick={() => {
                  handleClick(
                    specialty?.specialty,
                    specialty?.areaOfSpeciality
                  );
                  if (docTour) {
                    setDocTour(false);
                    dispatch(setTourSteps(DOCTOR_DETAILS_TOUR_START));
                  }
                }}
              >
                Continue
              </Button>
            </Row>
          </Row>
        </div>
      </Modal>

      {/* Surgical center Modal */}
      <Modal
        centered
        open={openSurgery}
        onOk={handleOk}
        onCancel={surgicalTour ? "" : handleOk}
        width={769}
        className="appointment-modal desired-modal"
        footer={null}
      >
        <div className="imagine-process">
          <div className="text-center">
            <Row justify="center" align="middle">
              <img src={healthlogo} alt="" style={{ marginRight: "1rem" }} />
              <h6 className="header-font">Select a specialty</h6>
            </Row>
          </div>

          <Input
            prefix={<SearchOutlined />}
            placeholder="Find Specialty"
            allowClear
            size="large"
            className="bodyarea-search mt-20 search-font"
            onChange={(e) => setSearchQuery(e.target.value.trim())}
          />

          {/* <div style={{ maxHeight: "420px", overflow: "auto" }}> */}
          <Row gutter={[10, 10]} className="body-area-surgery mt-20">
            {surgeryServiceBoxes.length > 0 ? (
              surgeryServiceBoxes.map((box) => (
                <Col md={8} xs={12} key={box.id}>
                  <div
                    className={`service-box ${getColumnClass(box.id - 1)} ${
                      box.id === tourNumberForDoc ? "sc-second-step" : ""
                    }`}
                    onClick={() => {
                      handleClickActive(box.id - 1);
                      setSpecialty({
                        specialty:
                          box.id === 1
                            ? "General Surgery"
                            : box.id === 2
                            ? "Gastroenterology"
                            : box.id === 3
                            ? "Neuro Surgeon"
                            : box.id === 4
                            ? "Opthalmology"
                            : box.id === 5
                            ? "Otolaryngology"
                            : box.id === 6
                            ? "Orthopaedics"
                            : box.id === 7
                            ? "Podiatry"
                            : box.id === 8
                            ? "Pain Management"
                            : box.id === 9
                            ? "Physiatrist"
                            : box.id === 10
                            ? "Sports Medicine"
                            : box.id === 11
                            ? "Urology"
                            : "",

                        areaOfSpeciality:
                          box.id === 1
                            ? ["Internal"]
                            : box.id === 2
                            ? ["Stomach"]
                            : box.id === 3
                            ? ["Head", "Spine and Back"]
                            : box.id === 4
                            ? ["Eyes"]
                            : box.id === 5
                            ? ["Ear Nose and Throat"]
                            : box.id === 6
                            ? [
                                "Ankle and Foot",
                                "Elbow",
                                "Hip",
                                "Hand and Wrist",
                                "Knee",
                                "Leg",
                                "Shoulder",
                                "Spine and Back",
                                "Neck",
                              ]
                            : box.id === 7
                            ? ["Ankle and Foot"]
                            : box.id === 8
                            ? ["Spine and Back", "Neck"]
                            : box.id === 9
                            ? ["Spine and Back", "Head", "Neck"]
                            : box.id === 10
                            ? ""
                            : box.id === 11
                            ? ["Internal"]
                            : "",
                      });
                      if (surgicalTour) {
                        setSurgicalTourIndex(2);
                      }
                    }}
                  >
                    <img
                      src={box.image}
                      alt=""
                      className={box.id === 8 ? "sports-medicine-img" : ""}
                    />
                    <Typography.Title level={5} className="sub-header-font">
                      {box.title}
                    </Typography.Title>
                    {box.description && (
                      <p className="p-font">{box.description}</p>
                    )}
                  </div>
                </Col>
              ))
            ) : (
              <div className="no-data">
                <Col md={24}>
                  <Empty />
                </Col>
              </div>
            )}
          </Row>
          {/* </div> */}

          <Row justify="space-between mt-20">
            <Row align="middle">
              <Button
                className="theme-border-btn button-font"
                onClick={() => {
                  // setOpenSurgery(false);
                  setNotSureModal(true);
                }}
              >
                Not Sure?
              </Button>
            </Row>

            <Row align="middle">
              <Button
                className="theme-btn button-font sc-third-step"
                htmlType="submit"
                loading={loading}
                disabled={activeColumn === 999 ? true : false}
                onClick={() => {
                  handleSurgeryCenter(
                    specialty?.specialty,
                    specialty?.areaOfSpeciality
                  );
                  if (surgicalTour) {
                    setSurgicalTour(false);
                    dispatch(setTourSteps(SURGICAL_DETAILS_TOUR_START));
                  }
                }}
              >
                Continue
              </Button>
            </Row>
          </Row>
        </div>
      </Modal>

      {/* Imaging Center Modal */}
      <Modal
        centered
        open={open}
        onOk={handleOk}
        onCancel={imgTour ? "" : handleOk}
        width={screens.xxl ? "50%" : 769}
        className="appointment-modal desired-modal"
        footer={null}
      >
        <div className="imagine-process">
          <div className="text-center">
            <Row justify="center" align="middle">
              <img src={healthlogo} alt="" style={{ marginRight: "1rem" }} />
              <h6 className="header-font">Select a specialty</h6>
            </Row>
          </div>

          <Input
            prefix={<SearchOutlined />}
            placeholder="Find Specialty"
            allowClear
            size="large"
            className="bodyarea-search mt-20 search-font"
            onChange={(e) => setSearchQuery(e.target.value.trim())}
          />

          <Row gutter={[10, 10]} className="body-area-ic mt-20">
            {icServiceBoxes.length > 0 ? (
              icServiceBoxes.map((box) => (
                <Col md={8} xs={12} key={box.id}>
                  <div
                    className={`service-box ${getColumnClass(box.id - 1)} ${
                      box.id === tourNumberForDoc ? "img-second-step" : ""
                    }`}
                    onClick={() => {
                      handleClickActive(box.id - 1);
                      setSpecialty({
                        specialty:
                          box.id === 1
                            ? "Arthrogram"
                            : box.id === 2
                            ? "Breast MRI"
                            : box.id === 3
                            ? "Bone Density"
                            : box.id === 4
                            ? "CT"
                            : box.id === 5
                            ? "Mammogram"
                            : box.id === 6
                            ? "MRI"
                            : box.id === 7
                            ? "PET"
                            : box.id === 8
                            ? "Ultrasound"
                            : box.id === 9
                            ? "X-ray"
                            : "",

                        areaOfSpeciality:
                          box.id === 1
                            ? ["Arthrogram"]
                            : box.id === 2
                            ? ["Breast MRI"]
                            : box.id === 3
                            ? ["Bone Density"]
                            : box.id === 4
                            ? ["CT"]
                            : box.id === 5
                            ? ["Mammogram"]
                            : box.id === 6
                            ? ["MRI"]
                            : box.id === 7
                            ? ["PET"]
                            : box.id === 8
                            ? ["Ultrasound"]
                            : box.id === 9
                            ? ["X-ray"]
                            : "",
                      });
                      if (imgTour) {
                        setImgTourIndex(2);
                      }
                    }}
                  >
                    <img src={box.image} alt="" />
                    <Typography.Title level={5} className="sub-header-font">
                      {box.title}
                    </Typography.Title>
                    {/* {box.description && <p>{box.description}</p>} */}
                  </div>
                </Col>
              ))
            ) : (
              <div className="no-data">
                <Col md={24}>
                  <Empty />
                </Col>
              </div>
            )}
          </Row>

          <Row justify="space-between mt-20">
            <Row align="middle">
              <Button
                className="theme-border-btn button-font"
                onClick={() => {
                  // setOpenDoctor(false);
                  setNotSureModal(true);
                }}
              >
                Not Sure?
              </Button>
            </Row>

            <Row align="middle">
              <Button
                className="theme-btn button-font img-third-step"
                htmlType="submit"
                loading={loading}
                disabled={activeColumn === 999 ? true : false}
                onClick={() => {
                  handleImagingClick(specialty?.areaOfSpeciality);
                  if (imgTour) {
                    setImgTour(false);
                    dispatch(setTourSteps(IMAGING_DETAILS_TOUR_START));
                  }
                }}
              >
                Continue
              </Button>
            </Row>
          </Row>
        </div>
      </Modal>

      {/* Not Sure Modal */}

      <Modal
        title="Basic Modal"
        onOk={handleOk}
        centered
        open={notSureModal}
        onCancel={() => {
          setNotSureModal(false);
        }}
        className="reward-success-modal not-sure-modal"
        destroyOnClose={true}
        closable={false}
        footer={null}
        width={521}
      >
        <img src={notSure} alt="Delete Img" />
        <h6 className="header-font mb-0 mt-20">Not Sure?</h6>
        <p className="sub-header-font">Get in touch with us for any queries!</p>

        <Card
          className="doctor-profile bottom-padding mt-30"
          style={{ background: "#F2F9FD" }}
        >
          <Row>
            <Col md={12} xs={12}>
              <img src={email} alt="Delete Img" /> <br />
              <p className="sub-header-font">ear@delphillc.com</p>
            </Col>

            <Col md={12} xs={12}>
              <img src={phone} alt="Delete Img" /> <br />
              <p className="sub-header-font">(603) 537-6037</p>
            </Col>
          </Row>
        </Card>

        <div className="form-bottom mt-30">
          <Button
            type="primary"
            className="theme-border-btn button-font"
            style={{ width: "106px" }}
            onClick={() => {
              setNotSureModal(false);
            }}
          >
            Back
          </Button>
        </div>
      </Modal>

      <Modal
        title="Basic Modal"
        open={isSupportModalOpen}
        onOk={handleOkSupport}
        onCancel={handleOkSupport}
        destroyOnClose={destroyModal}
        className={`support-modal service-second-step  desired-modal`}
      >
        <Chat closeModal={handleOkSupport} />
      </Modal>
    </>
  );
}
