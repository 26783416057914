import {
  Row,
  Card,
  Col,
  Typography,
  Input,
  Modal,
  Button,
  Upload,
  Select,
  Form,
  message,
} from "antd";
import moment from "moment";
import "./Rewards.less";
import { useEffect, useState } from "react";
import award from "assets/icons/award.svg";
import uploadIcon from "assets/icons/upload.svg";
import successIcon from "assets/icons/Success.svg";
import { CheckOutlined } from "@ant-design/icons";
import { uploadFileToS3, GetFileFromS3 } from "utility/uploadFileToS3";
import { useSelector } from "react-redux";
import {
  disableSubmitButton,
  satisfactionLevels,
  typeOfCare,
} from "utility/constant";
import { API, Auth } from "aws-amplify";
import { createRewardSubmissions } from "graphql/mutations";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import SuccessModel from "utility/SuccessModel";

export default function Rewards() {
  const [rewardFor, setRewardFor] = useState("");
  const [imageObject, setImageObject] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [successModel, setSuccessModel] = useState(false);
  const [care, setCare] = useState("");
  const [cognitoId, setCognitoId] = useState("");
  const [imageCount, setImageCount] = useState(0);
  const [uploadFileName, setUploadFileName] = useState([]);
  const [joyrideStepIndex, setJoyrideStepIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [rewardRes, setRewardRes] = useState([]);
  const [selectedRating, setSelectedRating] = useState(null);

  // for success modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [feedbackForm] = Form.useForm();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [feedbackDiabled, setFeedbackDisabled] = useState(true);
  const [review, setReview] = useState(false);
  const [reviewLoading, setReviewLoading] = useState(false);

  const handleFormChange = disableSubmitButton([
    "rewardFor",
    "typeOfCare",
    "nameOfProcedure",
    "claimNumber",
  ])(form, setSubmitDisabled);

  const handleFeedbackFormChange = disableSubmitButton(["comment"])(
    feedbackForm,
    setFeedbackDisabled
  );

  // const userId = useSelector((state) => state.userIdReducer.userID);
  const userData = useSelector((state) => state?.userReducer?.userData);
  const userId = useSelector((state) => state?.userReducer?.userData?.id);
  const { TextArea } = Input;

  const userFName = useSelector(
    (state) => state?.userReducer?.userData?.firstName
  );
  const userLName = useSelector(
    (state) => state?.userReducer?.userData?.lastName
  );
  const billingStatus = useSelector(
    (state) => state?.userReducer?.userData?.billing_status
  );
  // const billingStatus = "REQUIRED";
  // for Dropdown
  const handleChange = (value) => {
    setRewardFor(value);
  };
  const handleChangeCare = (value) => {
    setCare(value);
  };
  const rewardTour = localStorage.getItem("startRewardTour");

  useEffect(() => {
    if (rewardTour === "true") {
      setTimeout(() => {
        setIsTourRunning(true);
        setJoyrideStepIndex(0);
      }, 400);

      localStorage.setItem("startRewardTour", false);
    }
  }, [rewardTour]);

  // for Checkbox

  // for Upload Btn Icon
  function UploadIcn() {
    return <img src={uploadIcon} alt="" className="h-30" />;
  }

  useEffect(() => {
    const cognitoData = async () => {
      const loggedInUser = await Auth.currentUserInfo();
      setCognitoId(loggedInUser?.id);
    };
    cognitoData();
  }, []);

  const handleOk = () => {
    setIsModalOpen(false);
    setIsTourRunning(false);
    setSubmitDisabled(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setIsTourRunning(false);
    setSubmitDisabled(true);
  };

  const props = {
    onChange({ file }) {
      if (file.status === "removed") {
        setImageCount(imageCount - 1);
        setImageObject(
          imageObject.filter((item) => item["fileName"] !== file.name)
        );
        setUploadFileName(uploadFileName.filter((item) => item !== file.name));
      }

      if (file.status === "error") {
        console.log("error encountered");
      }
    },

    customRequest({ file, onError, onSuccess }) {
      setImageCount(imageCount + 1);
      setUploadFileName((item) => [...item, file.name]);
      uploadFileToS3(file)
        .then(async (res) => {
          const link = await GetFileFromS3(res.key);
          setImageObject((item) => [
            ...item,
            {
              fileName: file.name,
              fileKey: "private/" + cognitoId + "/" + res.key,
            },
          ]);
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          onError();
        });
    },
  };

  const manageAllImages = async () => {
    setImageObject(
      imageObject.filter((item) => uploadFileName.includes(item.fileName))
    );
  };

  const onFinish = async (values) => {
    setSelectedRating(null);
    if (!isTourRunning) {
      await manageAllImages();
      setLoading(true);
      if (imageObject.length !== imageCount) {
        message.warn("Please let the image upload before submitting!");
        setLoading(false);
        return;
      }
      if (imageObject.length === 0 || imageCount === 0) {
        message.warn("Attachments are Required to Upload!");
        setLoading(false);
        return;
      }
      const inputData = {
        input: {
          customerID: userId,
          customerEmail: userData?.emailID,
          rewardFor: rewardFor.trim(),
          name: userFName + " " + userLName,
          status: "PENDING",
          typeOfCare: care.trim(),
          searchByName:
            userFName?.toLowerCase() + " " + userLName?.toLowerCase(),
          nameOfProcedure: values.nameOfProcedure.trim(),
          claimNumber: values.claimNumber.trim(),
          dateSubmitted: moment().format("YYYY-MM-DD"),
          uploadedImages: imageObject,
        },
      };
      const res = await API.graphql({
        query: createRewardSubmissions,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      setRewardRes(res?.data?.createRewardSubmissions);

      setIsModalOpen(true);

      setTimeout(() => {
        setIsModalOpen(false);
        setReview(true);
      }, 3000);
    } else {
      setIsModalOpen(true);
      setTimeout(() => {
        setJoyrideStepIndex(2);
      }, 400);
    }
    setLoading(false);
    form.resetFields();
    setImageObject([]);
    setImageCount(0);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const endTour = () => {
    setIsTourRunning(false);
    setIsModalOpen(false);
  };

  const rating1 = `<div style="background: #FF3838;display: table;height: 5px;width: 40px;padding: 10px;text-align: center;line-height: 5px;font-weight: 500;color: #fff;">1</div>`;
  const rating2 = `<div style="background: #FF7575; display: table;height: 5px;width: 40px;padding: 10px;text-align: center;line-height: 5px;font-weight: 500;margin-left: 5px;color: #fff;">2</div>`;
  const rating3 = `<div style="background: #F0AA00; display: table;height: 5px;width: 40px;padding: 10px;text-align: center;line-height: 5px;font-weight: 500;margin-left: 5px;color: #fff;">3</div>`;
  const rating4 = `<div style="background: #FFB500; display: table;height: 5px;width: 40px;padding: 10px;text-align: center;line-height: 5px;margin-left: 5px;font-weight: 500;color: #fff;">4</div>`;
  const rating5 = `<div style="background: #73CE38; display: table;height: 5px;width: 40px;padding: 10px;text-align: center;line-height: 5px;margin-left: 5px;font-weight: 500;color: #fff;">5</div>`;

  const ratingMsg = `<div style="display: flex; text-align: center; margin-left: 5px; line-height: 5px; padding: 10px; color: #000; font-style: bold;">${
    selectedRating === 0 || selectedRating === 1
      ? "Very dissatisfied"
      : selectedRating === 2 || selectedRating === 3
      ? "Partially satisfied"
      : "Very satisfied"
  }</div>
</div>`;

  let selectedRatingHtml;
  switch (selectedRating) {
    case 0:
      selectedRatingHtml = `<div style="display: flex;">${rating1 + ratingMsg}`;
      break;
    case 1:
      selectedRatingHtml = `<div style="display: flex;">${
        rating1 + rating2 + ratingMsg
      }`;
      break;
    case 2:
      selectedRatingHtml = `<div style="display: flex;">${
        rating1 + rating2 + rating3 + ratingMsg
      }</div>`;
      break;
    case 3:
      selectedRatingHtml = `<div style="display: flex;">${
        rating1 + rating2 + rating3 + rating4 + ratingMsg
      }</div>`;
      break;
    case 4:
      selectedRatingHtml = `<div style="display: flex;">${
        rating1 + rating2 + rating3 + rating4 + rating5 + ratingMsg
      }</div>`;
      break;
    default:
      selectedRatingHtml = "";
  }

  const submitFeedback = async (values) => {
    if (selectedRating === null) {
      messageApi.open({
        type: "error",
        content: "Please select rating",
      });
    } else {
      try {
        setReviewLoading(true);
        let url = `/employee/sendFeedback`;

        const myInit = {
          body: {
            id: rewardRes?.id,
            rating: selectedRating === 0 ? "0" : selectedRating,
            name: userData?.firstName,
            emailRating: selectedRatingHtml,
            comment: values?.comment,
          },
        };

        await API.post("bazarhealthREST", url, myInit);
        setComment("");
        setReview(false);
        setSuccessModel(true);
        feedbackForm.resetFields();
      } catch (err) {
        console.log("error", err);
      } finally {
        setReviewLoading(false);
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      }
    }
  };

  const rewardSteps = [
    {
      target: ".my-first-step",
      content: (
        <TourStepContent
          heading="Reward Form"
          details="Please fill out the required fields. Information
          should match EOB being submitted."
          numbers={{ currentStep: 1, totalSteps: 3 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".my-second-step",
      content: (
        <TourStepContent
          heading="Reward Form"
          details="  Click on Submit button to submit your reward."
          numbers={{ currentStep: 2, totalSteps: 3 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".my-third-step",
      content: (
        <TourStepContent
          heading="Submit"
          details="Great! Your reward details have been uploaded
          successfully."
          numbers={{ currentStep: 3, totalSteps: 3 }}
          onSkipTour={endTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  const handleRating = (index) => {
    setSelectedRating(index);
  };

  const [comment, setComment] = useState("");
  const maxCharacterLimit = 500;

  const handleCommentChange = (e) => {
    const value = e.target.value;

    if (value.length <= maxCharacterLimit) {
      setComment(value);
    }
  };

  return (
    <>
      {contextHolder}
      <Card className="mb-2 mobile-card r-card">
        <Row justify="center">
          <Col xl={14} md={24}>
            {billingStatus === "REQUIRED" ? (
              <>
                <div className="reward-header">
                  <Typography.Title level={5} className="header-font">
                    You are not eligible for submitting rewards
                  </Typography.Title>
                </div>
              </>
            ) : (
              <>
                <div className="reward-header">
                  <img src={award} />
                  <Typography.Title level={5} className="header-font">
                    You should be rewarded for using Delphi
                  </Typography.Title>
                  <span className="sub-header-font">
                    Take a picture of your Explanation of Benefits (EOB) and
                    upload it below.
                  </span>
                </div>

                <GlobalJoyride
                  steps={rewardSteps}
                  run={isTourRunning}
                  stepIndex={joyrideStepIndex}
                  setTourIndex={setJoyrideStepIndex}
                />

                <Form
                  name="doctor"
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  id="myForm"
                  layout="vertical"
                  form={form}
                  onValuesChange={handleFormChange}
                >
                  <div className="reward-input-box mt-20">
                    <Row gutter={[20, 15]}>
                      <div
                        className="my-first-step"
                        style={{ display: "inline" }}
                        onClick={
                          isTourRunning
                            ? () => {
                                setTimeout(() => {
                                  setJoyrideStepIndex(1);
                                }, 100);
                                setSubmitDisabled(false);
                              }
                            : ""
                        }
                      >
                        <Row gutter={[20, 15]}>
                          <Col md={12} xs={24}>
                            <Form.Item
                              className="reward-form"
                              label={
                                <span className="sub-header-font">
                                  Who is this for?
                                </span>
                              }
                              name="rewardFor"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select any option",
                                },
                              ]}
                            >
                              <Select
                                className="form-control select-font"
                                defaultValue="Select any option"
                                onChange={handleChange}
                                disabled={isTourRunning}
                                options={[
                                  {
                                    value: "MySelf",
                                    label: "MySelf",
                                  },
                                  {
                                    value: "family",
                                    label: "Family",
                                  },
                                ]}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              className="reward-form"
                              label={
                                <span className="sub-header-font">
                                  Type of care
                                </span>
                              }
                              name="typeOfCare"
                              rules={[
                                {
                                  required: true,
                                  message: "Please select type of care",
                                },
                              ]}
                            >
                              <Select
                                className="form-control"
                                defaultValue="Select type of care"
                                onChange={handleChangeCare}
                                disabled={isTourRunning}
                                options={typeOfCare}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              className="reward-form"
                              label={
                                <span className="sub-header-font">
                                  Name of procedure
                                </span>
                              }
                              name="nameOfProcedure"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input procedure name",
                                },
                              ]}
                            >
                              <Input disabled={isTourRunning} />
                            </Form.Item>
                          </Col>
                          <Col md={12} xs={24}>
                            <Form.Item
                              className="reward-form"
                              label={
                                <span className="sub-header-font">
                                  Claim number
                                </span>
                              }
                              name="claimNumber"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your claim number",
                                },
                              ]}
                            >
                              <Input disabled={isTourRunning} />
                            </Form.Item>
                          </Col>
                          <Col span={24} className="mt-1">
                            <Form.Item
                              name="files"
                              // rules={[
                              //   {
                              //     required: true,
                              //     message: "Please upload files",
                              //   },
                              // ]}
                            >
                              <Upload {...props} disabled={isTourRunning}>
                                <Button
                                  icon={<UploadIcn />}
                                  className="sub-header-font"
                                >
                                  &nbsp;Click here to upload files
                                </Button>
                              </Upload>
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                      <Col span={24} className="center-btn">
                        {isTourRunning ? (
                          <Button
                            type="primary"
                            className=" theme-btn my-second-step button-font"
                            onClick={onFinish}
                            disabled={submitDisabled}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            type="primary"
                            className=" theme-btn my-second-step button-font"
                            htmlType="submit"
                            loading={loading}
                            disabled={submitDisabled}
                          >
                            Submit
                          </Button>
                        )}

                        <Modal
                          title="Basic Modal"
                          width={700}
                          open={isModalOpen}
                          onOk={handleOk}
                          centered
                          onCancel={handleCancel}
                          className="reward-success-modal my-third-step"
                        >
                          <div onClick={isTourRunning ? handleCancel : {}}>
                            <img src={successIcon} alt="Success Img" />
                            <h6>Upload Successful!</h6>
                            <p
                              style={{ fontSize: "18px", lineHeight: "21.6px" }}
                            >
                              Thank you for submitting for your Reward! Delphi
                              will review your submission and send your reward
                              within 2 to 4 weeks to the address in your
                              account. Please check to make sure your address is
                              correct.
                            </p>
                          </div>
                        </Modal>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </>
            )}
          </Col>
        </Row>

        {/* Commenting this as we will deploy this in next sprint */}
        <Modal
          className="form-modal"
          centered
          footer={null}
          width={750}
          title={
            <Typography.Title level={3} className="modal-title">
              Rate your Experience
            </Typography.Title>
          }
          open={review}
          onOk={() => setReview(false)}
          onCancel={() => setReview(false)}
        >
          <Form
            name="basic"
            onFinish={submitFeedback}
            autoComplete="off"
            layout="vertical"
            form={feedbackForm}
            onValuesChange={handleFeedbackFormChange}
          >
            <Row gutter={20} className="reward-input-box">
              {satisfactionLevels.map((level, index) => (
                <div
                  key={index}
                  className="equal-width-div"
                  onClick={() => handleRating(index)}
                  style={{
                    background: level.background,
                    borderRadius:
                      index === 0
                        ? "6px 0 0 6px"
                        : index === 4
                        ? "0 6px 6px 0"
                        : "",
                    border:
                      selectedRating === index &&
                      !(index === 5 || index === 6 || index === 7)
                        ? "2px solid #00406A"
                        : "",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "40px",
                    width: index === 5 || index === 6 ? "270px" : "130px",
                    marginLeft: "10px",
                    color:
                      index === 5 || index === 6 || index === 7
                        ? "#000"
                        : "#fff",
                    fontWeight: "500",
                    opacity: index === 5 || index === 6 ? 0.8 : 1,
                  }}
                >
                  {selectedRating === index &&
                  !(index === 5 || index === 6 || index === 7) ? (
                    <CheckOutlined />
                  ) : (
                    level.text
                  )}
                </div>
              ))}

              <Col span={24} md={24} xs={24} sm={24} className="mt-30">
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please input your feedback",
                    },
                  ]}
                >
                  <TextArea
                    rows={5}
                    placeholder="Tell us how we can improve"
                    value={comment}
                    onChange={handleCommentChange}
                    maxLength={maxCharacterLimit}
                  />
                </Form.Item>
                <div style={{ marginTop: 5 }}>
                  {comment.length}/{maxCharacterLimit}
                </div>
              </Col>

              <Col
                span={12}
                md={24}
                xs={24}
                sm={24}
                style={{ textAlign: "center" }}
              >
                <Button
                  className="theme-btn"
                  htmlType="submit"
                  loading={reviewLoading}
                  disabled={
                    feedbackDiabled === false && selectedRating !== null
                      ? false
                      : true
                  }
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      </Card>

      <SuccessModel message="Thank you for your feedback" open={successModel} />
    </>
  );
}
