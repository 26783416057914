// Create a reusable BillingCard component
import { Card, Col, Divider, Radio, Row, Tag } from "antd";

export default function EmployerBillingCard({
  title,
  monthlyValue,
  selectedPlan,
  onChange,
  size,
  isPopular = false,
  customPrice,
}) {
  const isActiveCard = selectedPlan === monthlyValue;
  return (
    <Col span={8} md={8} xs={24} sm={24}>
      <Card
        className={`doctor-profile bottom-padding  ${
          isActiveCard ? "hover-pricing" : "card-border"
        } `}
      >
        {isPopular && (
          <Row justify="center" className="top-tag">
            <span> Most Popular</span>
          </Row>
        )}
        <Row
          justify="center"
          className="billing-cards"
          style={{ marginTop: isPopular ? "2px" : "" }}
        >
          <h4 style={{ marginTop: "10px" }}>{title}</h4>
          <Divider />
          <Col
            md={24}
            className="mt-10 d-flex justify-content-center emp-radio"
          >
            <Radio
              value={monthlyValue}
              onChange={onChange}
              checked={selectedPlan === monthlyValue}
            >
              <span
                style={{ marginLeft: "10px" }}
                className={`pricing ${
                  selectedPlan === monthlyValue ? "selected-plan" : ""
                }`}
              >
                {selectedPlan === "CUSTOM_PLAN"
                  ? `$${customPrice}`
                  : monthlyValue === "SMALL_SIZE_EMPLOYERS"
                  ? "$99"
                  : monthlyValue === "MID_SIZE_EMPLOYERS"
                  ? "$149"
                  : "$249"}
              </span>{" "}
              / month
            </Radio>
          </Col>
          <Col>
            <Tag className="mt-20 members d-flex justify-centent-center align-items-center">
              {size} Members
            </Tag>
          </Col>
        </Row>
      </Card>
    </Col>
  );
}
