import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Button, message } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { Auth, API } from "aws-amplify";

import bazarLogo from "assets/delphy/delphy.svg";

import "./ConfirmEmail.less";
import { useNavigate, useLocation } from "react-router-dom";
import Footer from "commons/Footer";
const ConfirmEmail = (props) => {
  const location = useLocation();
  const [loading, setloading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [email] = useState(location?.state?.userName);
  const [id] = useState(location?.state?.id);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const urlParams = new URLSearchParams(window.location.search);
  const dpcDoctor = urlParams.get("doctorID");

  useEffect(() => {
    if (!location?.state) {
      if (
        window.location.href.includes("confirmationcode=") &&
        window.location.href.includes("/confirm-signup")
      ) {
        let Data = {};
        Data.code = window.location.href
          .split("confirmationcode=")[1]
          .split("&email=")[0];
        Data.email = window.location.href.split("email=")[1];
        form.setFieldsValue({
          code: Data.code,
          email: Data.email,
        });
        handleConfirmSignUp(form.getFieldsValue(["email", "code"]));
      } else {
        navigate(dpcDoctor ? `/login?doctorID=${dpcDoctor}` : `/login`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //resend code stuff
  const handleResendCode = async () => {
    setloading(true);
    try {
      await Auth.resendSignUp(email);
      message.success("A new confirmation code has been sent.");
      setloading(false);
    } catch (error) {
      console.log("error signing up:", error);
      message.error(error.message);
      setloading(false);
    }
  };

  //verification code
  const handleConfirmSignUp = async (e) => {
    try {
      setConfirmLoading(true);
      if (email && e.code) {
        setloading(true);
        await Auth.confirmSignUp(email, e.code);
        setloading(false);
        const myInit = {
          body: {
            id: id,
          },
        };
        await API.post(
          "bazarhealthREST",
          "/createEmployee/sendWelcomeEmail",
          myInit
        );
        navigate(dpcDoctor ? `/login?doctorID=${dpcDoctor}` : `/login`, {
          state: {
            feedback: "Sign in with your new account",
            userName: e.email,
          },
        });
      } else {
        message.error("Something Went Wrong");
      }
    } catch (error) {
      if (
        error.message.includes(
          "User cannot be confirmed. Current status is CONFIRMED"
        )
      ) {
        navigate(dpcDoctor ? `/login?doctorID=${dpcDoctor}` : `/login`);
        message.success("Already Verified");
      }
      console.log("error signing up:", error);
      message.error(error.message);
      setloading(false);
    } finally {
      setConfirmLoading(false);
    }
  };

  return (
    <Row className="confirm-signup-wrapper">
      <Col span={12} lg={12} md={0} sm={0} xs={0}>
        <div className="confirm-signup-page-image"></div>
      </Col>
      <Col
        className="form-footer-container"
        span={12}
        lg={12}
        md={0}
        sm={24}
        xs={24}
      >
        <Row justify="center" align="middle" className="w-100">
          <Col span={12} lg={12} md={12} xs={20} sm={20}>
            <div className="header"></div>
            <div className="form-logo-container">
              <img src={bazarLogo} alt="" className="bazar-logo" />
              <p className="instruction">
                Check your email for a confirmation code
              </p>
              <Form
                form={form}
                layout="vertical"
                className="confirm-signup-form w-100"
                requiredMark={false}
                onFinish={(e) => handleConfirmSignUp(e)}
              >
                <Form.Item
                  initialValue={email}
                  name="email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  className="email-form-item"
                >
                  <Input
                    value={email}
                    id="email"
                    key="email"
                    name="email"
                    placeholder="Email"
                    className="email"
                    disabled={localStorage.getItem("User")}
                    size="large"
                    prefix={<MailOutlined />}
                  />
                </Form.Item>

                <Form.Item
                  name="code"
                  className="code-form-item"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Confirmation Code!",
                    },
                  ]}
                >
                  <Input
                    autoFocus
                    id="code"
                    key="code"
                    name="code"
                    className="code"
                    placeholder="Confirmation code"
                    tabIndex={1}
                    size="large"
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify="space-between" align="middle">
                    <Col span={7}>
                      <Button
                        type="link"
                        className="button-font"
                        onClick={handleResendCode}
                        disabled={loading}
                        style={{
                          textAlign: "right",
                          cursor: "pointer",
                          paddingBottom: 10,
                          color: "#416B88",
                          fontWeight: "bold",
                          paddingLeft: 0,
                        }}
                      >
                        Resend Code
                      </Button>
                    </Col>
                    <Col span={9}>
                      <Button
                        className="confirm-signup-button button-font"
                        htmlType="submit"
                        disabled={loading}
                        loading={confirmLoading}
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </Form.Item>
              </Form>
            </div>
          </Col>
        </Row>
        <Footer />
      </Col>
    </Row>
  );
};

export default ConfirmEmail;
