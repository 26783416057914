const search = (data, searchValue, searchFields) => {
  const currValue = searchValue.toLowerCase();
  const regex = new RegExp(currValue, "i");
  const filteredData = data.filter((item) => {
    const matches = searchFields.some((field) => {
      if (field.includes(".")) {
        const fields = field.split(".");
        const nestedItem = item[fields[0]];
        return nestedItem ? regex.test(nestedItem[fields[1]]) : false;
      } else if (Array.isArray(item[field])) {
        // handle array fields
        return item[field].some((element) => regex.test(element.toLowerCase()));
      } else if (field === "Speciality") {
        // handle children field
        const childrenArray = item?.Specialties?.props?.children
          ? item?.Specialties?.props?.children
              .split("\n")
              .map((child) => child.trim())
          : []; // null check added
        return childrenArray.some((child) => regex.test(child.toLowerCase()));
      } else {
        // handle regular fields
        return regex.test(item[field]);
      }
    });
    return matches;
  });
  return filteredData;
};

export default search;
