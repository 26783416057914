import * as actions from "../actions/index";

const initialState = {
    ICData: null,
  };

const ICReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_IC_DATA:
            return {
              ...state,
              ICData: action.payload,
            };

        default:
            return state;
    }
}
export default ICReducer;
