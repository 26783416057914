import React, { useEffect, useRef } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Modal,
  Form,
  Divider,
  message,
  Select,
  Grid,
  Skeleton,
  Tag,
  Tooltip,
  Dropdown,
} from "antd";
import { Empty } from "antd";
import successIcon from "assets/icons/Success.svg";
import { useState } from "react";
import { SearchOutlined, StarFilled, PlusOutlined } from "@ant-design/icons";
import Container from "commons/Container";
import "./DoctorList.less";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import locationIcon from "assets/icons/location.svg";

import Pagination from "rc-pagination";

import { ListNearByDoctor } from "utility/action";
import { useDispatch, useSelector } from "react-redux";
import { setDoctorData } from "store/actions/doctorAction";

import { setSpecialityOption } from "store/actions/specialityOptions";
import usePaginationSearch from "utility/usePaginationSearch";
import AppointmentRequest from "./AppointmentRequest/AppointmentRequest";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import {
  docSpecialty,
  DOCTOR_DETAILS_TOUR_START,
  DOC_PROFILE_TOUR_START,
  gastroenterologist,
  ophthalmologist,
  orthopaedic,
  otolaryngologists,
  pain_management,
  podiatrist,
  SURGICAL_SELECT_DOCTOR_TOUR_END,
  urology,
  neuro_surgeon,
  physiatrist,
  END_TOUR,
} from "utility/constant";
import { Analytics } from "aws-amplify";
import { setAreaOfSpeciality } from "store/actions/areaOfSpecialty";
import { setTourSteps } from "store/actions/tourSteps";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import PaginatedList from "utility/PaginatedList";

export default function DoctorList() {
  const [open, setOpen] = useState(false);
  const [doctorName, setDoctorName] = useState([]);
  const [docData, setDocData] = useState({});
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [docTour, setDocTour] = useState(false);
  const [docTourIndex, setDocTourIndex] = useState(0);
  const [selectDocTour, setSelectDocTour] = useState(false);
  const [surgeryAreaOfSpeciality, setSurgeryAreaOfSpeciality] = useState([]);
  const doctorStateData = useSelector((state) => state?.doctorReducer?.doctor);
  const userData = useSelector((state) => state.userReducer?.userData);
  const [tourIndex, setTourIndex] = useState(0);
  const [successModel, setSuccessModel] = useState(false);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const dispatch = useDispatch();
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  const navigate = useNavigate();

  const surgeryCenterName = location?.state?.surgicalName;
  const defaultDoctorImage =
    "https://bazarhealtha2da62319dd04201b98d37699a417a56111916-prod.s3.us-east-2.amazonaws.com/staticFiles/images/icons/Doctor.png";
  const totalLen = doctorStateData?.length;
  const specialityOptions = useSelector(
    (state) => state?.specialityReducer?.specialityOption
  );
  const areaOfSpecialityStore = useSelector(
    (state) => state?.areaOfSpecialityReducer?.areaOfSpecialty
  );
  const locationCoordinates = userData?.profile?.location;
  //giving the parent specialty default specailtyOptions from reducer, casue, if it comes from some other page, specailty stays correct.
  const [parentSpecialty, setParentSpecialty] = useState(specialityOptions);
  const [areaOfSpeciality, setAreaOfSpecialityState] = useState(
    areaOfSpecialityStore
  );
  const [tags, setTags] = useState(areaOfSpeciality ? areaOfSpeciality : []);

  const {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  } = usePaginationSearch(doctorStateData);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleTourChange = () => {
    if (selectDocTour) {
      navigate("/");
      setSelectDocTour(false);
    } else if (docTourIndex === 1) {
      setTimeout(() => {
        setDocTourIndex(2);
      }, 400);
    } else if (tourIndex === 0) {
      setTourIndex(1);
    } else if (tourIndex === 1) {
      setTourIndex(2);
    }
  };

  const renderDoctor = (doctor) => {
    return (
      <Col
        span={8}
        lg={8}
        md={12}
        sm={24}
        xs={24}
        key={doctor?.id}
        className="mt-10"
      >
        <div
          className="single-list-card-surgery dr-first-step select-doc-first-step"
          onClick={handleTourChange}
        >
          <div className="surgery-profile-card ">
            <div className="d-flex gap-3">
              <img
                src={
                  doctor.doctorProfilePhoto
                    ? doctor?.doctorProfilePhoto
                    : defaultDoctorImage
                }
                alt="Doctor Img"
                className="doctor-img"
              />
              <h4 className="doctor-name sub-header-font">
                {doctor.doctorName}, {doctor.doctorDegree}
              </h4>
            </div>
            <div className="d-flex mt-20 r-mt-10 rat-box">
              <p className="p-font">
                <img src={facebook} alt="facebook Icon" />{" "}
                {doctor.facebookRating === 0 ||
                doctor.facebookRating === null ? (
                  "NA"
                ) : (
                  <>
                    {doctor.facebookRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
              <Divider className="h-15em" type="vertical" />
              <p className="p-font">
                <img src={google} alt="facebook Icon" />{" "}
                {doctor.googleRating === 0 || doctor.googleRating === null ? (
                  "NA"
                ) : (
                  <>
                    {doctor.googleRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
            </div>

            <div className="space-between app-bottom-margin">
              <p className="single-address-line p-font">
                <img src={locationIcon} alt="Address Icon" /> &nbsp;
                {parseFloat(doctor?.distance).toFixed(2) !== "0.00"
                  ? `${parseFloat(doctor?.distance).toFixed(2)} Miles from
                                  you`
                  : doctor?.distance === null
                  ? "-"
                  : "Near You"}
              </p>

              <Link
                to="/doctor-profile"
                state={{ id: doctor?.id, surgicalName: surgeryCenterName }}
                className="learn-more-btn"
                onClick={() => {
                  if (docTour) {
                    dispatch(setTourSteps(DOC_PROFILE_TOUR_START));
                  }
                }}
              >
                <Button
                  className="blue-border-btn p-font doc-fifth-step"
                  onClick={() =>
                    searchText
                      ? Analytics.record({
                          name: "Search",
                          attributes: {
                            Service: "Doctors",
                            SearchFor: doctor.doctorName,
                          },
                          immediate: true,
                        })
                      : {}
                  }
                >
                  Learn More
                </Button>
              </Link>
            </div>
          </div>

          <div className="request-app">
            <Button
              className="theme-btn p-font dr-second-step doc-second-step"
              onClick={() => {
                setTourIndex(2);
                setOpen(true);
                setDoctorName(doctor.doctorName + ", " + doctor.doctorDegree);
                setDocData({
                  docID: doctor.id,
                  docName: doctor.doctorName,
                  docEmail: doctor.doctorEmail,
                  docPhone: doctor.doctorOfficePhone,
                  docSpecialties: specialityOptions,
                  docFax: doctor.doctorOfficeFax,
                });
              }}
            >
              Request Appointment
            </Button>
          </div>
        </div>
      </Col>
    );
  };

  useEffect(() => {
    setDoctorData(doctorStateData);
  });

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const getFilterBySpecialityforSurgery = (speciality) => {
    const specialityFilters = {
      "General Surgeon": [],
      Gastroenterologist: ["Stomach"],
      Ophthalmologist: ["Eyes"],
      Otolaryngologists: ["Ear Nose and Throat"],
      Orthopaedic: [
        "Ankle and Foot",
        "Elbow",
        "Hip",
        "Hand and Wrist",
        "Head",
        "Knee",
        "Neck",
        "Shoulder",
        "Spine and Back",
        "Sports Medicine",
      ],
      Podiatrist: ["Ankle and Foot"],
      "Pain Management": ["Spine and Back", "Neck"],
      Urology: ["Internal"],
      "Neuro Surgeon": ["Spine and Back", "Head"],
      Physiatrist: ["Spine and Back", "Head", "Neck"],
      "Sports Medicine": [],
    };
    return specialityFilters[speciality] || undefined;
  };

  const specialtyExists = getFilterBySpecialityforSurgery(parentSpecialty);
  const handleSpecialtyChange = async (specialty) => {
    try {
      setSearchText("");
      setParentSpecialty(specialty);
      setTags([]);
      setAreaOfSpecialityState([]);
      setDropdownOptions([]);
      setSkeletonLoader(true);
      setInputVisible(false);
      const data = getFilterBySpecialityforSurgery(specialty);
      setAreaOfSpecialityState(getFilterBySpecialityforSurgery(specialty));
      setTags(data);
      const response = await ListNearByDoctor(
        999,
        {
          lat: locationCoordinates?.lat,
          lon: locationCoordinates?.lon,
        },
        getFilterBySpecialityforSurgery(specialty),
        specialty,
        surgeryCenterName ? surgeryCenterName : undefined
      );
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item?.doctorProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
            }
          }
          return item;
        })
      );

      dispatch(setSpecialityOption(specialty));
      dispatch(setDoctorData(updatedResponse));
      dispatch(setAreaOfSpeciality(data));
    } catch (err) {
      console.log(err);
    } finally {
      setSkeletonLoader(false);
    }
  };

  //Surgery Center change dropdown
  const handleChangeSpeciality = async (value) => {
    try {
      setSearchText("");
      const data = getFilterBySpecialityforSurgery(value);
      setSurgeryAreaOfSpeciality(data);
      setAreaOfSpecialityState(getFilterBySpecialityforSurgery(value));

      setSkeletonLoader(true);
      const response = await ListNearByDoctor(
        999,
        {
          lat: locationCoordinates?.lat,
          lon: locationCoordinates?.lon,
        },
        undefined,
        value,
        surgeryCenterName ? surgeryCenterName : undefined
      );
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item?.doctorProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
            }
          }
          return item;
        })
      );

      dispatch(setSpecialityOption(value));
      dispatch(setDoctorData(updatedResponse));
      dispatch(setAreaOfSpeciality(data));
    } catch (error) {
      console.log(error);
      setSkeletonLoader(false);
      messageApi.open({
        type: "error",
        content: `Error Fetching Data`,
      });
    } finally {
      setSkeletonLoader(false);
    }
  };

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [inputValue]);

  let items =
    parentSpecialty === "General Surgeon"
      ? []
      : parentSpecialty === "Gastroenterologist"
      ? gastroenterologist
      : parentSpecialty === "Otolaryngologists"
      ? otolaryngologists
      : parentSpecialty === "Ophthalmologist"
      ? ophthalmologist
      : parentSpecialty === "Orthopaedic"
      ? orthopaedic
      : parentSpecialty === "Pain Management"
      ? pain_management
      : parentSpecialty === "Podiatrist"
      ? podiatrist
      : parentSpecialty === "Urology"
      ? urology
      : parentSpecialty === "Neuro Surgeon"
      ? neuro_surgeon
      : parentSpecialty === "Physiatrist"
      ? physiatrist
      : [];
  const [dropdownOption, setDropdownOptions] = useState([]);
  // console.log(dropdownOption, "dropDownOptions")
  const handleClose = async (removedTag) => {
    try {
      setSearchText("");
      setSkeletonLoader(true);
      setInputVisible(true);
      const newTags = tags.filter((tag) => tag !== removedTag);
      setTags(newTags);
      dispatch(setAreaOfSpeciality(newTags));
      // Check if the removedTag is already in the dropdown options
      const existingOption = dropdownOption.find(
        (option) => option.value === removedTag
      );

      // Add the removedTag back to the dropdown options if it doesn't exist
      if (!existingOption) {
        setDropdownOptions((prevOptions) => [
          ...prevOptions,
          {
            label: removedTag,
            value: removedTag,
            key: removedTag,
          },
        ]);
      }

      const response = await ListNearByDoctor(
        999,
        {
          lat: locationCoordinates?.lat,
          lon: locationCoordinates?.lon,
        },
        newTags,
        parentSpecialty,
        surgeryCenterName ? surgeryCenterName : undefined
      );
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item?.doctorProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
            }
          }
          return item;
        })
      );

      dispatch(setDoctorData(updatedResponse));
    } catch (error) {
    } finally {
      setSkeletonLoader(false);
    }
  };

  if ((surgeryAreaOfSpeciality.length === 0) & surgeryCenterName) {
    {
      setSurgeryAreaOfSpeciality(
        getFilterBySpecialityforSurgery(specialityOptions)
      );
    }
  }

  const onClick = async ({ key }) => {
    try {
      setSearchText("");
      setSkeletonLoader(true);
      setInputValue(key);

      if (dropdownOption?.length === 1) {
        setInputVisible(false);
      } else {
        setInputVisible(true);
      }

      if (key && tags.indexOf(key) === -1) {
        setTags([...tags, key]);

        // Check if the clicked option is in the dropdown options
        const existingOption = dropdownOption.find(
          (option) => option.value === key
        );

        // Remove the clicked option from the dropdown options if it exists
        if (existingOption) {
          setDropdownOptions((prevOptions) =>
            prevOptions.filter((option) => option.value !== key)
          );
        }

        const response = await ListNearByDoctor(
          999,
          {
            lat: locationCoordinates?.lat,
            lon: locationCoordinates?.lon,
          },
          [...tags, key],
          parentSpecialty,
          surgeryCenterName ? surgeryCenterName : undefined
        );
        const updatedResponse = await Promise.all(
          response.map(async (item) => {
            const key = item?.doctorProfilePhoto;
            if (key && !key.startsWith("http")) {
              const url = await GetMulipleFileFromS3(key, "public");
              if (url) {
                if (item?.doctorProfilePhoto) item.doctorProfilePhoto = url;
              }
            }
            return item;
          })
        );

        dispatch(setDoctorData(updatedResponse));
        dispatch(setAreaOfSpeciality([...tags, key]));
      }
      setInputValue("");
    } catch (e) {
      console.log(e);
    } finally {
      setSkeletonLoader(false);
    }
  };

  const preventDefault = (e) => {
    e.preventDefault();
    messageApi.open({
      type: "error",
      content: `Filtering requires selecting at least one body part.
      `,
    });
  };

  useEffect(() => {
    // Update the defaultSelectedKeys prop when the tags state changes
    inputRef.current?.rcMenu?.current?.setSelectedKeys(tags);
  }, [tags]);

  useEffect(() => {
    const existingOption = items.filter(
      (option) => !areaOfSpecialityStore?.includes(option.value)
    );
    if (existingOption.length > 0) {
      setDropdownOptions(existingOption);
      setInputVisible(true);
    }
  }, []);

  const handleUpdateTourIndex = (index) => {
    if (index === 3) {
      setTourIndex(3);
    } else if (index === 5) {
      if (docTour) {
        setDocTourIndex(4);
        setOpen(false);
      } else {
        setTourIndex(4);
        setOpen(false);
        setSuccessModel(true);
      }
    } else if (index === 10) {
      setDocTourIndex(3);
    }
  };

  useEffect(() => {
    if (tourSteps === DOCTOR_DETAILS_TOUR_START) {
      setDocTour(true);
      setTimeout(() => {
        dispatch(setTourSteps(END_TOUR));
      }, 500);
    }
  }, [tourSteps]);

  useEffect(() => {
    if (tourSteps === SURGICAL_SELECT_DOCTOR_TOUR_END) {
      setSelectDocTour(true);
      setTimeout(() => {
        dispatch(setTourSteps(END_TOUR));
      }, 500);
    }
  }, [tourSteps]);

  const serviceSteps = [
    {
      target: ".select-doc-first-step",
      content: (
        <TourStepContent
          heading="Select doctor from list"
          details="Select the specific Doctor of your choice and remember to make sure the specialty is accurate"
          numbers={{ currentStep: 7, totalSteps: 7 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  const doctorSteps = [
    {
      target: ".doc-first-step",
      content: (
        <TourStepContent
          heading="Filters"
          details="Cross off Body Parts to narrow your search for the area in which the Doctor Specializes"
          numbers={{ currentStep: 4, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".doc-second-step",
      content: (
        <TourStepContent
          heading="Request Appointment"
          details="Click to schedule an appointment with a specific doctor of
          your choice."
          numbers={{ currentStep: 5, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "top",
    },
    {
      target: ".doc-third-step",
      content: (
        <TourStepContent
          heading="Appointment Form"
          details="Please provide the required information to schedule an
          appointment with the doctor."
          numbers={{ currentStep: 6, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".doc-forth-step",
      content: (
        <TourStepContent
          heading="Submit"
          details="Click the Submit button to schedule an appointment."
          numbers={{ currentStep: 7, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".doc-fifth-step",
      content: (
        <TourStepContent
          heading="Learn More"
          details="Click to review more information about the doctor."
          numbers={{ currentStep: 8, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={doctorSteps}
        run={docTour}
        stepIndex={docTourIndex}
        setTourIndex={setDocTourIndex}
      />

      <GlobalJoyride
        steps={serviceSteps}
        run={selectDocTour}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />

      <Container>
        <div className="d-flex justify-content-between hide-version-no">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" className="p-font">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="p-font">Doctor List</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
        <Card className="list-content mobile-card" loading={false}>
          <Row justify="space-between" align="middle" gutter={[0, 0]}>
            <Col>
              <p className="card-title-surgery header-font">
                Doctor List {surgeryCenterName ? `- ${surgeryCenterName}` : ""}{" "}
                ({totalLen} Result)
              </p>

              {surgeryCenterName && (
                <p className="sub-title-box-surgery">
                  {surgeryCenterName && areaOfSpecialityStore?.length > 0 ? (
                    <>You are looking for {areaOfSpecialityStore?.join(", ")}</>
                  ) : (
                    <p className="mt-30"></p>
                  )}
                </p>
              )}
            </Col>

            <Col md={6} xs={24}>
              {surgeryCenterName ? (
                <Select
                  className="specialty-dropdown select-font"
                  defaultValue={
                    specialityOptions
                      ? specialityOptions
                      : "Select Any Specialty"
                  }
                  options={
                    location?.state?.surgeryCenterSpeciality
                      ? location?.state?.surgeryCenterSpeciality
                      : docSpecialty
                  }
                  onChange={handleChangeSpeciality}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              ) : (
                <Input
                  className="main-search br-40 search-font r-mt-10"
                  size="large"
                  placeholder="Search here"
                  prefix={<SearchOutlined />}
                  onChange={handleSearchInputChange}
                  value={searchText}
                />
              )}
            </Col>
          </Row>

          {surgeryCenterName ? (
            <Col span={24} className="d-flex gap-3 r-mt-5">
              <Input
                className="main-search search-font"
                placeholder="Search here"
                prefix={<SearchOutlined />}
                onChange={handleSearchInputChange}
                value={searchText}
              />
              <Pagination
                showTitle={false}
                onChange={handlePaginationChange}
                total={doctorStateData?.length}
                defaultPageSize={9}
                current={currentPage}
                itemRender={PrevNextArrow}
                className="hide-version-no"
              />
            </Col>
          ) : (
            <Col span={24} className={` w-100 doc-dropdown mt-20`}>
              <Row>
                <Col span={22} md={22} sm={20} xs={24}>
                  <div
                    className="doc-first-step"
                    onClick={() => {
                      if (docTour) {
                        setDocTourIndex(1);
                      }
                    }}
                  >
                    <Input.Group compact>
                      <Select
                        defaultValue={
                          specialtyExists
                            ? specialityOptions
                            : "Select Any Specailty"
                        }
                        disabled={docTour ? true : false}
                        onChange={handleSpecialtyChange}
                        className="speciality-selectbar"
                        options={docSpecialty}
                      ></Select>
                      <div className="doc-dropdown-bodyPart">
                        <div
                          className="doc-dropdown-bodyPart-child"
                          onClick={() => {
                            if (docTour) {
                              setDocTourIndex(1);
                            }
                          }}
                        >
                          {tags &&
                            tags.length > 0 &&
                            specialtyExists &&
                            tags?.map((tag, index) => {
                              const isLongTag = tag.length > 20;
                              const handleCloseCallback =
                                tags.length === 1
                                  ? preventDefault
                                  : () => handleClose(tag);

                              const tagElem = (
                                <Tag
                                  className="edit-tag"
                                  key={tag}
                                  closable={docTour ? false : true}
                                  onClose={
                                    docTour ? false : handleCloseCallback
                                  }
                                >
                                  <span
                                    onDoubleClick={(e) => {
                                      if (index !== 0) {
                                        e.preventDefault();
                                      }
                                    }}
                                  >
                                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                                  </span>
                                </Tag>
                              );
                              return isLongTag ? (
                                <Tooltip title={tag} key={tag}>
                                  {tagElem}
                                </Tooltip>
                              ) : (
                                tagElem
                              );
                            })}

                          {inputVisible && (
                            <Tag className="site-tag-plus">
                              <Dropdown
                                menu={{
                                  items: dropdownOption,
                                  onClick,
                                }}
                                overlayClassName="doc-body-part"
                                ref={inputRef}
                                placement="bottomLeft"
                                trigger={["click"]}
                              >
                                <span className="">
                                  <PlusOutlined /> Add New
                                </span>
                              </Dropdown>
                            </Tag>
                          )}
                        </div>
                      </div>
                    </Input.Group>
                  </div>
                </Col>
                <Col span={2} md={2} sm={4} xs={4} className="hide-version-no">
                  <Pagination
                    showTitle={false}
                    onChange={handlePaginationChange}
                    total={doctorStateData?.length}
                    defaultPageSize={9}
                    current={currentPage}
                    itemRender={PrevNextArrow}
                  />
                </Col>
              </Row>
            </Col>
          )}

          <Row gutter={[20, 10]} className="r-mt-10">
            {skeletonLoader ? (
              <>
                {/* <Row gutter={[20, 20]} className="mt-3"> */}
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                {/* </Row> */}
              </>
            ) : (
              <>
                {doctorStateData.length > 0 ? (
                  <>
                    {paginatedItems.length > 0 ? (
                      <>
                        {paginatedItems.map(renderDoctor)}

                        {screens.xs && (
                          <Col
                            span={8}
                            lg={8}
                            md={12}
                            sm={24}
                            xs={24}
                            className="mt-10"
                          >
                            <Pagination
                              showTitle={false}
                              onChange={handlePaginationChange}
                              total={doctorStateData?.length}
                              defaultPageSize={9}
                              current={currentPage}
                              itemRender={PrevNextArrow}
                            />
                          </Col>
                        )}
                      </>
                    ) : (
                      <div className="no-data">
                        <Col md={24}>
                          <Empty />
                        </Col>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="no-data">
                      <Col md={24}>
                        <Empty />
                      </Col>
                    </div>
                  </>
                )}
              </>
            )}
          </Row>
        </Card>
      </Container>

      <Modal
        title="Modal 1000px width"
        centered
        open={open}
        onOk={() => {
          setOpen(false);
          form.resetFields();
        }}
        onCancel={() => {
          setOpen(false);
          form.resetFields();
        }}
        width={1000}
        className="appointment-modal desired-modal"
        footer={null}
        keyboard={false}
      >
        <AppointmentRequest
          isModal={true}
          docName={doctorName}
          docData={docData}
          tour={docTour}
          onCloseModal={handleCloseModal}
          tourIndex={docTourIndex === 3 ? docTourIndex : tourIndex}
          className={
            docTourIndex === 2
              ? "doc-third-step"
              : tourIndex === 4
              ? "dr-forth-step"
              : tourIndex === 2
              ? "dr-third-step"
              : ""
          }
          onUpdateTourIndex={handleUpdateTourIndex}
        />
      </Modal>

      <Modal
        title="Basic Modal"
        open={successModel}
        onOk={() => setSuccessModel(false)}
        centered
        onCancel={() => setSuccessModel(false)}
        className="reward-success-modal dr-fifth-step"
      >
        <img src={successIcon} alt="Success Img" />

        <h6>Appointment Requested Successfully!</h6>
      </Modal>
    </>
  );
}
