import * as actions from "../actions/index";

const initialState = {
    userData: null,
  };

const userReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_USER_DATA:
            return {
              ...state,
              userData: action.payload,
            };

        default:
            return state;
    }
}
export default userReducer;
