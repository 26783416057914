import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function SurgeryTour(props) {
  const surgeryServiceSteps = [
    {
      target: ".sc-first-step",
      content: (
        <TourStepContent
          heading="Surgical Center"
          details="Click to choose your desired Surgical Center and proceed to the next step for assistance."
          numbers={{ currentStep: 1, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".sc-second-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click the Specialty of the Center you are looking for"
          numbers={{ currentStep: 2, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".sc-third-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click to continue for List."
          numbers={{ currentStep: 3, totalSteps: 7 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={surgeryServiceSteps}
        run={props?.surgicalTour}
        stepIndex={props?.surgicalTourIndex}
        setTourIndex={props?.setSurgicalTourIndex}
      />
    </>
  );
}
