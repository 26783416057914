import { Divider, Row, Typography, Input, Button, Table, Dropdown, Menu, Modal, Form, Select, Col } from 'antd'
import React, {useState} from 'react'
import { DownOutlined, EyeOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom';


export default function MedGroups() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Option } = Select;

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const columns = [
        {
          title: 'Office Name',
          dataIndex: 'officeName',
          render: (text) => <Link to="/medical-group-detail">{text}</Link>,
        },
        {
            title: 'Med. Group type',
            dataIndex: 'MedGrouptype',
        },
        {
            title: '',
            dataIndex: 'Action',
            className:'actions',
            render: (text) => {
                return(
                    <EyeOutlined />
                )
            }
        },
    ]

    const data = [
        {
          key: '1',
          officeName: 'Agility Orthopaedics',
          MedGrouptype: 'Medical Office',
        },
        {
            key: '2',
            officeName: 'Alantic Digestive Specialists',
            MedGrouptype: 'Medical Office',
        },
        {
            key: '3',
            officeName: 'Atrius Health',
            MedGrouptype: 'Medical Office',
        },
        {
            key: '4',
            officeName: 'BASC Imaging',
            MedGrouptype: 'Imaging Office',
        },
        {
            key: '5',
            officeName: 'Boston Orthopaedic & Spine',
            MedGrouptype: 'Medical Office',
        },
    ]

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const DownloadOptions = [
        {
            key: '1',
            label: (
              <Link rel="noopener noreferrer" to="#">
                Excel
              </Link>
            ),
        },
        {
            key: '2',
            label: (
              <Link rel="noopener noreferrer" to="#">
                CSV
              </Link>
            ),
        },
    ]

  return (
    <>
        <Typography.Title level={4} className="text-center mb-0">Medical Groups</Typography.Title>
        <Divider  />

        <Row justify="space-between table-header">
            <Row align="middle">
                <Select allowClear placeholder="Select Office" style={{width: 300}}
                        getPopupContainer={(trigger) => trigger.parentNode}
                        > 
                    <Option value="1">Office 1</Option>
                    <Option value="2">Office 2</Option>
                    <Option value="3">Office 3</Option>
                </Select>            
            </Row>

            <Row className="gap-10 mtr-20">
                <Dropdown overlay={<Menu items={DownloadOptions} />} placement="bottomRight" overlayClassName="download-btn">
                    <Button className="blue-border-btn"> Download data <DownOutlined /></Button>
                </Dropdown>                
                <Button className="blue-btn"  onClick={showModal}>Add New</Button>
            </Row>
        </Row>

        <Table
            columns={columns}
            dataSource={data}
            className="common-table"
            scroll={{ x: 992 }}
            pagination={{ position: ["", "bottomCenter"] }}
        />

        <Modal className="form-modal" centered footer={null} title={<Typography.Title level={3}>Add Medical Groups</Typography.Title>} visible={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
            <Form
                name="basic"
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                autoComplete="off"
                layout="vertical"
            >
                <Row gutter={20}>
                    <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                            label="Office Name"
                            name="OfficeName"
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                    <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                            label="Group Type"
                            name="GroupType"
                        >
                            <Select
                                placeholder="Select Group Type"
                                allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                            >
                                <Option value="MedicalOffice">Medical Office</Option>
                                <Option value="ImagingOffice">Imaging Office</Option>
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={24} md={24} xs={24} sm={24}>
                        <Button className="blue-btn">Submit</Button>
                    </Col>
                </Row>
               
            </Form>
        </Modal>

    </>
  )
}
