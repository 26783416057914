import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Tabs,
  Modal,
  Skeleton,
  message,
  Grid,
} from "antd";
import { StarFilled } from "@ant-design/icons";
import photos from "assets/icons/photos.svg";
import Container from "commons/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import overview from "assets/icons/overview.svg";
import appointment from "assets/icons/appointment.svg";
// import PhysicalTherapyOverview from "../PhysicalTherapyOverview/PhysicalTherapyOverview";
import { Storage } from "aws-amplify";
// import PtAppointment from "../Appointment/PtAppointment";
import Chat from "pages/user/SupportChatbox/Chat";

import { GetPublicFileFromS3 } from "utility/uploadFileToS3";
import { GetInfusionDetails } from "utility/action";
import { useCallback } from "react";
import ImageGallery from "utility/ImageGallery";
import InfusionCenterOverview from "./InfusionCenterOverview";
import InfusionAppointment from "./InfusionAppointment";
import { END_TOUR, PT_PROFILE_TOUR_START } from "utility/constant";
import { setTourSteps } from "store/actions/tourSteps";
import { useDispatch, useSelector } from "react-redux";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";

export default function InfusionCenterProfile() {
  const location = useLocation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const userID = location?.state?.id;
  const [messageApi, contextHolder] = message.useMessage();

  const [icData, setICData] = useState([]);
  const [appointmentData, setAppointmentData] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [activeTab, setActiveTab] = useState("item-1");

  const [preSignedImg, setPreSignedimg] = useState([]);
  const [ptPhoto, setPtPhoto] = useState(null);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);
  const [tour, setTour] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);
  const dispatch = useDispatch;
  const navigate = useNavigate();
  const search = location?.state?.search;

  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  // for chat box
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const fetchptCenter = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const response = await GetInfusionDetails(userID);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);
      setICData(response);
      setAppointmentData({
        id: response?.id,
        name: response?.name,
        email: response?.email,
        phone: response?.phone,
        fax: response?.fax,
      });
      if (response?.profilePhoto) {
        let ptPhoto;

        if (response?.profilePhoto.startsWith("http")) {
          ptPhoto = response?.profilePhoto;
        } else {
          ptPhoto = await GetPublicFileFromS3(response?.profilePhoto);
        }
        setPtPhoto(ptPhoto);
      }

      if (response?.infusionCenterPhotos) {
        const fileKeyy = response?.infusionCenterPhotos;
        const urls = [];
        for (const key of fileKeyy) {
          try {
            const url = await Storage.get(key, {
              level: "public",
              expires: 86400,
            });
            urls.push(url);
          } catch (error) {
            console.log(`Error getting pre-signed URL for ${key}: ${error}`);
          }
        }
        setPreSignedimg(urls);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === PT_PROFILE_TOUR_START) {
        setTour(true);
      }
      setTimeout(() => {
        dispatch(setTourSteps(END_TOUR));
      }, 500);
    }
  }, [userID]);

  useEffect(() => {
    fetchptCenter();
  }, [fetchptCenter]);

  const items = [
    {
      label: (
        <span className="sub-header-font">
          <img src={overview} className="tabs-icon" />
          {(activeTab === "item-1" && screens.xs) || screens.sm || screens.lg
            ? "Overview"
            : ""}
        </span>
      ),
      key: "item-1",
      children: (
        <InfusionCenterOverview data={icData} loader={skeletonLoader} />
      ),
    },
    {
      label: (
        <span className="sub-header-font">
          <img src={photos} className="tabs-icon" />
          {(activeTab === "item-2" && screens.xs) || screens.sm || screens.lg
            ? "Photos"
            : ""}
        </span>
      ),
      key: "item-2",
      children: <ImageGallery data={preSignedImg} />,
    },
    {
      label: (
        <span className="sub-header-font">
          <img src={appointment} className="tabs-icon" />
          {(activeTab === "item-3" && screens.xs) || screens.sm || screens.lg
            ? "Request An Appointment"
            : ""}
        </span>
      ),
      key: "item-3",
      children: (
        <InfusionAppointment
          icData={appointmentData}
          infusionCenterDetail={icData}
        />
      ),
    },
  ];

  const serviceSteps = [
    {
      target: ".ic-first-step",
      content: (
        <TourStepContent
          heading="Infusion Center Profile"
          details="Simply click on different tabs to explore all the details related to the Infusion Center."
          numbers={{ currentStep: 6, totalSteps: 6 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}
      <GlobalJoyride
        steps={serviceSteps}
        run={tour}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />
      <Container>
        <Breadcrumb className="hide-version-no">
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Home
            </Link>
          </Breadcrumb.Item>
          {!search && (
            <Breadcrumb.Item>
              <Link to="/up-infusion-center" className="p-font">
                Infusion Center List
              </Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <span className="p-font">{icData?.name}</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* main content starts*/}
        <Card
          className="doctor-profile ic-first-step mobile-card"
          onClick={() => {
            if (tour) {
              navigate("/");
              setTour(false);
            }
          }}
        >
          <Row>
            {skeletonLoader ? (
              <>
                {" "}
                <Skeleton avatar paragraph={{ rows: 2 }}></Skeleton>{" "}
              </>
            ) : (
              <>
                <Col>
                  <div className="d-flex gap-4">
                    <img src={ptPhoto} alt="" className="doctor-img" />
                    <div className="doctor-detail">
                      <h4 className="doctor-name header-font mb-10">
                        {icData?.name}
                      </h4>
                      {/* <h6>Reliant Medical Group (Shrewsbury)</h6> */}
                      <div className="d-flex gap-3">
                        <p className="p-font">
                          <img src={facebook} alt="facebook Icon" />
                          {/* {icData?.facebookRating
                            ? icData?.facebookRating
                            : 0}{" "} */}
                          {icData?.facebookRating === 0 ||
                          icData?.facebookRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {icData?.facebookRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>

                        <p className="p-font">
                          <img src={google} alt="facebook Icon" />
                          {/* {icData?.googleRating ? icData?.googleRating : 0}{" "} */}
                          {icData?.googleRating === 0 ||
                          icData?.googleRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {icData?.googleRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <Card className="profile-tabs mobile-card">
          <Row>
            <Col span={24}>
              <Tabs items={items} onChange={handleTabChange} />
            </Col>
          </Row>
        </Card>
      </Container>
      
    
    </>
  );
}
