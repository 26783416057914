import * as actions from "../actions/index";

const initialState = {
    ptData: null,
  };

const ptReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_PT_CENTER:
            return {
              ...state,
              ptData: action.payload,
            };

        default:
            return state;
    }
}
export default ptReducer;
