const deleteImages = (
  imageObject,
  preSignedImg,
  index,
  surgeryData,
  setImageObject,
  setPreSignedimg,
  messageApi,
  UpdateSurgeryCenterDetails,
  Storage
) => {
  return async () => {
    const newImageObject = [...imageObject];
    const deletedKey = newImageObject.splice(index, 1)[0];
    try {
      await Storage.remove(deletedKey, { level: "public" });

      const inputData = {
        id: surgeryData?.id,
        surgeryPhotos: newImageObject,
      };
      await UpdateSurgeryCenterDetails(inputData);

      setImageObject(newImageObject);

      const updatedImages = preSignedImg.filter((img, i) => i !== index);
      setPreSignedimg(updatedImages);

      messageApi.success({
        content: "Image Deleted Successfully",
        duration: 5,
      });
    } catch (error) {
      console.log("Error deleting image from S3:", error);
      messageApi.error({
        content: "Error Deleting Image",
      });
    }
  };
};

const imagePreviewer = (file) => {
  return async () => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };
};

const fetchPreSignedURLs = async (fileKeys, Storage) => {
  const urls = [];
  for (const key of fileKeys) {
    try {
      const url = await Storage.get(key, {
        level: "public",
        expires: 86400,
      });
      urls.push(url);
    } catch (error) {
      console.log(`Error getting pre-signed URL for ${key}: ${error}`);
    }
  }
  return urls;
};

const ImageActions = {
  deleteImages,
  imagePreviewer,
  fetchPreSignedURLs,
};

export default ImageActions;
