import { Col, Row, Skeleton } from "antd";
import React from "react";

function TableSkeleton() {
  return (
    <>
      <Row gutter={[20, 20]} style={{ padding: "20px 0" }}>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
        <Col span={4} style={{ position: "relative" }}>
          <Skeleton.Button
            style={{ width: "95%", position: "absolute", top: 0 }}
            active
          />
        </Col>
      </Row>
      <Row style={{ padding: "20px 0" }}>
        <Skeleton paragraph={{ rows: 10 }} active />
      </Row>
    </>
  );
}

export default TableSkeleton;
