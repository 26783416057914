import React, { useState } from "react";
import { Select } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import DynamicConfirmModal from "./DynamicConfirmModal";
import { API } from "aws-amplify";

export const DoctorDropdown = ({ doctorList, initialValue, userData }) => {
  const [selectedDoctor, setSelectedDoctor] = useState(initialValue);
  const [tempDoctor, setTempDoctor] = useState(initialValue);
  const [hasSelectionChanged, setHasSelectionChanged] = useState(false); // Track if the selection has changed
  const [loading, setLoading] = useState(false); // Loader state
  const [isModalVisible, setIsModalVisible] = useState(false); // Confirmation modal state
  const [modalContent, setModalContent] = useState({
    content: "",
    action: () => {},
  });

  const doctorOptions = doctorList.map((item) => ({
    label: item.name,
    value: item.name,
  }));

  const showModal = (content, action) => {
    setModalContent({ content, action });
    setIsModalVisible(true);
  };

  const handleSelectChange = (value) => {
    if (value !== selectedDoctor) {
      setTempDoctor(value); // Update the temporary doctor selection
      setHasSelectionChanged(true); // Mark that the selection has changed
    } else {
      setTempDoctor(selectedDoctor); // Update the temporary doctor selection
      setHasSelectionChanged(false);
    }
  };

  const handleDoctorChange = async () => {
    setLoading(true); // Start loader
    try {
      const myInit = {
        body: {
          currentDoctor: doctorList.find(
            (item) => item?.name === selectedDoctor
          ),
          futureDoctor: doctorList.find((item) => item?.name === tempDoctor),
          userID: userData?.key,
          userType: userData?.role,
        },
      };
      let url = `/superAdmin/changeEpcDoctor`;

      await API.post("bazarhealthREST", url, myInit);
      // let url = `/superAdmin/send_presigned_url`;

      // let result = await API.post("bazarhealthREST", url, myInit);

      setSelectedDoctor(tempDoctor); // Save the selected doctor
    } catch (error) {
      console.error("Error saving:", error);
      // Handle error (e.g., show notification)
    } finally {
      setLoading(false); // Stop loader
      setHasSelectionChanged(false); // Reset change tracker
      setIsModalVisible(false); // Close the confirmation modal
    }
  };

  const handleCancel = () => {
    setTempDoctor(selectedDoctor); // Reset to the previously selected doctor
    setHasSelectionChanged(false); // Reset change tracker
    setIsModalVisible(false); // Close the confirmation modal
  };

  const handleAction = (actionType) => {
    const actionConfig = {
      delete: {
        content: "Are you sure you want to delete this item?",
        action: () => {
          // Perform delete action here
          console.log("Item deleted");
        },
      },
      changeDoctor: {
        content: `This action will change the primary care for ${userData?.fullName} from ${selectedDoctor} to ${tempDoctor}`,
        action: handleDoctorChange,
      },
    };

    showModal(
      actionConfig[actionType].content,
      actionConfig[actionType].action
    );
  };

  return (
    <div
      style={{ position: "relative", display: "flex", alignItems: "center" }}
    >
      <Select
        value={tempDoctor}
        onChange={handleSelectChange}
        options={doctorOptions}
        style={{ minWidth: 150, marginRight: "50px" }}
      />
      {hasSelectionChanged && ( // Show icons only if selection has changed
        <div
          style={{
            position: "absolute", // Ensure buttons don't take extra space
            right: 0, // Align buttons to the right
            top: "50%", // Center vertically
            transform: "translateY(-50%)",
            display: "flex",
            alignItems: "center",
          }}
        >
          <CloseOutlined
            onClick={handleCancel}
            style={{ marginRight: 8, cursor: "pointer", color: "red" }}
          />
          <CheckOutlined
            onClick={() => handleAction("changeDoctor")} // Show confirmation modal
            style={{ cursor: "pointer", color: "green" }}
          />
        </div>
      )}

      <DynamicConfirmModal
        visible={isModalVisible}
        onConfirm={modalContent.action}
        onCancel={handleCancel}
        content={modalContent.content}
        loading={loading}
      />
    </div>
  );
};

export default DoctorDropdown;
