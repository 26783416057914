import React from "react";
import "../styles/common.css";
import { EyeOutlined } from "@ant-design/icons";
import DummyImg from "assets/icons/blank.png";
import { Link } from "react-router-dom";

const Header = ({
  user,
  openProfileSidebar,
  openSearchSidebar,
  showAssignedUser,
}) => {
  return showAssignedUser === true ? (
    <header className="header-chat chat__header assigned-user-header">
      <div
        className="chat__contact-wrapper assigned-user"
        onClick={openProfileSidebar}
      >
        <h2 className="chat__contact-name"> Assigned to:</h2>
        <h2 className="chat__contact-name assigned">
          {localStorage.getItem("adminName") !== null}
          {user?.adminDetails?.firstName
            ? user?.adminDetails?.firstName + " " + user?.adminDetails?.lastName
            : "NA"}
        </h2>
      </div>
    </header>
  ) : (
    <header className="header-chat chat__header">
      <div className="chat__avatar-wrapper" onClick={openProfileSidebar}>
        <img
          // src={user[0]?.senderDetails?.profile?.profilePhoto}
          src={DummyImg}
          alt="User"
          className="avatar"
        />
      </div>

      <div className="chat__contact-wrapper" onClick={openProfileSidebar}>
        <h2 className="chat__contact-name">
          {" "}
          {user?.senderDetails?.profile?.firstName +
            " " +
            user?.senderDetails?.profile?.lastName}
        </h2>
      </div>
      <div className="chat__actions">
        <Link to="/member-detail" state={{ id: [user?.senderDetails?.id] }}>
          <EyeOutlined />
        </Link>
      </div>
    </header>
  );
};

export default Header;
