import React, { useState } from "react";
import {
  Row,
  DatePicker,
  Col,
  Input,
  Button,
  Checkbox,
  Form,
  Select,
  message,
  Grid,
} from "antd";
import "./AppointmentRequest.less";
import { API, Analytics } from "aws-amplify";
import { createDoctorAppointment } from "graphql/mutations";
import { useSelector } from "react-redux";
import { aboutPattern } from "utility/constant";
import SuccessModel from "utility/SuccessModel";

export default function AppointmentRequest(props) {
  const [providerCheck, setProviderCheck] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [dob, setDob] = useState("");
  const [selfSelect, setSelfSelect] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const { Option } = Select;
  const { TextArea } = Input;

  const isModal = props?.isModal;
  const userData = useSelector((state) => state?.userReducer?.userData);
  const specialityOptions = useSelector(
    (state) => state?.specialityReducer?.specialityOption
  );
  const docData = props?.data ? props.data : props?.docData;
  const docName = props?.data?.doctorName
    ? props?.data?.doctorName +
      (props?.data?.doctorDegree ? ", " + props?.data?.doctorDegree : "")
    : props?.docName;
  const handleChange = (value) => {
    if (value === "My Self") {
      setIsOtherSelected(false);
      setSelfSelect(true);
      form.setFieldsValue({
        name: userData?.profile?.firstName + " " + userData?.profile?.lastName,
      });
    } else if (value === "Other") {
      setIsOtherSelected(true);
      setSelfSelect(false);
      form.setFieldsValue({
        name: "",
      });
    }
    setAppointmentFor(value);
  };

  const onChange = (e) => {
    setProviderCheck(e.target.checked);
  };

  const onChangeDatepickerDOB = (date, dateString) => {
    setDob(dateString);
  };

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const inputData = {
        input: {
          userData: {
            id: userData?.id,
            name:
              userData?.profile?.firstName + " " + userData?.profile?.lastName,
            otherName: values?.name,
            appointmentFor: appointmentFor,

            preferredTime: values?.preferredTime,
            relationship: null,
            email: userData?.profile?.email,
            phone: userData?.profile?.phone,
            dob: userData?.profile?.dob,
            otherDob: dob === "" ? null : dob,
            insuranceCarrier: userData?.profile?.insuranceCarrier,
            memberID: userData?.profile?.memberID,
          },
          docData: {
            id: docData?.docID ? docData?.docID : docData?.id,
            name: docData?.docName ? docData?.docName : docData?.doctorName,
            email: docData?.docEmail ? docData?.docEmail : docData?.doctorEmail,
            phone: docData?.docPhone
              ? docData?.docPhone
              : docData?.doctorOfficePhone,
            fax: docData?.docFax ? docData?.docFax : docData?.doctorOfficeFax,
            speciality: specialityOptions,
          },
          userID: userData?.id,
          description: values?.description,
          openToDifferentProvider: providerCheck,
          status: true,
          viewed: false,
        },
      };
      await API.graphql({
        query: createDoctorAppointment,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      Analytics.record({
        name: "Doctor Appointment",
        attributes: {
          name: docData?.docName ? docData?.docName : docData?.doctorName,
        },
        immediate: true,
      });
      form.resetFields();
      setProviderCheck(false);
      if (isModal) {
        props?.onCloseModal();
      }
      setIsModalOpen(true);
      setSelfSelect(false);
      setTimeout(() => {
        setIsModalOpen(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    if (props?.className === "doc-third-step") {
      props.onUpdateTourIndex(10);
    } else {
      props.onUpdateTourIndex(3);
    }
  };

  return (
    <>
      {contextHolder}
      <Form
        name="doctor"
        onFinish={onFinish}
        autoComplete="off"
        id="appForm"
        form={form}
        layout="vertical"
      >
        <div className="appointment-modal">
          <div className="request-appointment">
            {isModal ? (
              <div className="text-center">
                <h6 className="header-font">Appointment Form</h6>
                <span className="fs-1 p-font">
                  Request an Appointment with: {docName}
                </span>
              </div>
            ) : screens.xs ? (
              ""
            ) : (
              <h6 className="header-font align-left">
                Request an appointment with: {docName}
              </h6>
            )}

            <Row className="remove-side mt-20">
              <Col md={isModal ? 22 : 24} offset={isModal ? 1 : 0}>
                <div className="reward-input-box r-mt-10 ">
                  <Row
                    gutter={20}
                    className={props?.className}
                    onClick={handleClick}
                  >
                    {/* <div className={props?.className}> */}
                    <Col md={24}>
                      <Row gutter={20} className="remove-side">
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">
                                Who is the appointment for?
                              </span>
                            }
                            name="appointmentFor"
                            rules={[
                              {
                                required: true,
                                message: "Please select any option!",
                              },
                            ]}
                          >
                            <Select
                              className="form-control select-font"
                              defaultValue="Select any option"
                              onChange={handleChange}
                              name="appointmentFor"
                              id="appointmentFor"
                              options={[
                                {
                                  value: "My Self",
                                  label: "My Self",
                                },
                                {
                                  value: "Other",
                                  label: "Other",
                                },
                              ]}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={<span className="p-font">Name</span>}
                            name="name"
                            className="form-control"
                            rules={[
                              {
                                required: true,
                              },
                            ]}
                          >
                            <Input disabled={selfSelect} />
                          </Form.Item>
                        </Col>

                        {isOtherSelected && (
                          <>
                            <Col span={12} md={12} xs={24} sm={24}>
                              <Form.Item
                                label={<span className="p-font">DOB</span>}
                                name="dob"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input DOB",
                                  },
                                ]}
                              >
                                <DatePicker
                                  onChange={onChangeDatepickerDOB}
                                  disabledDate={(d) =>
                                    !d || d.isAfter(new Date())
                                  }
                                  className="datepicker"
                                  allowClear={false}
                                />
                              </Form.Item>
                            </Col>
                          </>
                        )}

                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label={
                              <span className="p-font">
                                Preferred time of day
                              </span>
                            }
                            name="preferredTime"
                            rules={[
                              {
                                required: true,
                                message: "Please select preferred time of day",
                              },
                            ]}
                          >
                            <Select
                              className="form-control select-font"
                              defaultValue="Select Any Option"
                              allowClear
                            >
                              <Option value="MORNING">Morning</Option>
                              <Option value="LUNCH">Lunch</Option>
                              <Option value="AFTERNOON">Afternoon</Option>
                              <Option value="NO_PREFERENCE">
                                No Preference
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col span={12} md={24} xs={24} sm={24}>
                      <Form.Item
                        label={
                          <span className="p-font">
                            Brief description of Injury/symptoms
                          </span>
                        }
                        name="description"
                        rules={[
                          {
                            required: true,
                            message: "Please input brief description!",
                          },
                          {
                            pattern: aboutPattern,
                            message: "Please input a valid brief description",
                          },
                        ]}
                      >
                        <TextArea rows={4} style={{ marginBottom: 0 }} />
                      </Form.Item>
                    </Col>

                    <Col span={12} md={24} xs={24} sm={24}>
                      <Form.Item name="checkbox" className="form-bottom">
                        <span className="fs-1 p-font">
                          I want the earliest available appointment.
                        </span>
                        <br />
                        <Checkbox onChange={onChange}>
                          <span className="fs-15 p-font">
                            I am open to a different provider at the same
                            location if they have a sooner opening.
                          </span>
                        </Checkbox>
                      </Form.Item>

                      <span className="default-msg p-font">
                        This request will be sent to the provider’s scheduling
                        coordinator.
                      </span>
                    </Col>
                    {/* </div> */}
                  </Row>
                  <Col
                    span={12}
                    md={24}
                    xs={24}
                    sm={24}
                    style={{ textAlign: "center" }}
                    className="mt-20"
                  >
                    <Form.Item className="form-bottom">
                      {(props?.tourIndex === 3 || props?.tourIndex === 10) &&
                      props?.tour ? (
                        <Button
                          onClick={() => props.onUpdateTourIndex(5)}
                          type="primary"
                          className="theme-btn dr-forth-step doc-forth-step"
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          loading={loading}
                          type="primary"
                          className="theme-btn dr-forth-step button-font"
                          htmlType="submit"
                          form="appForm"
                          key="submit"
                        >
                          Submit
                        </Button>
                      )}
                    </Form.Item>
                  </Col>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Form>

      <SuccessModel
        message={
          <span className="header-font">
            Great! Your appointment request has been sent successfully
          </span>
        }
        open={isModalOpen}
      />
    </>
  );
}
