import {
  Row,
  Typography,
  Input,
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Form,
  Col,
  Tabs,
  Space,
  Select,
  Radio,
  message,
  InputNumber,
  Tag,
  Divider,
} from "antd";
import deleteIcon from "assets/icons/delete.svg";
import successDelete from "assets/icons/successDelete.svg";
import exportCSV from "assets/icons/exportcsv.svg";
import employerInvite from "assets/icons/employerInvite.svg";

import deleteEmp from "assets/icons/deleteEmp.svg";
import "./Employers.less";

import React, { useEffect, useState } from "react";
import comingsoon from "assets/icons/coming.png";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import "../admin.less";
import EmployerChart from "./EmployerChart";
import { getCompanyRegi } from "graphql/queries";
import { API } from "aws-amplify";
import { CSVLink } from "react-csv";
import {
  disableSubmitButton,
  emailPattern,
  formatNumberUS,
  onlyNumberPattern,
  servicesNamePattern,
  tenDigit,
} from "utility/constant";
import { ListCompanyRegis } from "utility/action";
import SuccessModel from "utility/SuccessModel";
import { useCallback } from "react";
import search from "utility/search";
import TableSkeleton from "utility/TableSkeleton";
import CopyToClipboard from "react-copy-to-clipboard";

export default function Employers() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [convertLoading, setConvertLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [employersData, setEmployersData] = useState([]);
  const [showInvitePlan, setShowInvitePlan] = useState(false);
  const [employeeInviteUrl, setEmployeeInviteUrl] = useState(false);

  const [deleteId, setDeleteId] = useState("");
  const [employeeCount, setEmployeeCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employerLoading, setEmployerLoding] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [employerModel, setEmplorModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [convertSuccess, setConvertSuccess] = useState(false);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [billingValue, setBillingValue] = useState([]);
  const [planChange, setPlanChange] = useState("");
  const [dataSource, setDataSource] = useState(employersData);
  const [employerID, setEmployerID] = useState("");
  const [totalEmployeesCustom, setTotalEmployeesCustom] = useState(null);
  const [customPrice, setCustomPrice] = useState(null);
  const handleNumberOfEmployeesCustom = (e) => {
    if (onlyNumberPattern.test(e.target.value)) {
      setTotalEmployeesCustom(e.target.value);
    }

    if (e.target.value.trim() === "") {
      setTotalEmployeesCustom(null);
    }
  };

  const handlePriceCustom = (e) => {
    if (onlyNumberPattern.test(e.target.value)) {
      setCustomPrice(e.target.value);
    }

    if (e.target.value.trim() === "") {
      setCustomPrice(null);
    }
  };
  const [form] = Form.useForm();
  const { Option } = Select;

  const encodedPlanName = btoa(planChange);
  const hostWithProtocol =
    window.location.protocol + "//" + window.location.host;
  const text = `${hostWithProtocol}/signup/?id=${employerID}`;

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitDisabledInvite, setSubmitDisabledInvite] = useState(true);
  const handleFormChange = disableSubmitButton([
    "companyName",
    "hrEmail",
    "billingStatus",
  ])(form, setSubmitDisabled);

  const handleFormChangeInvite = disableSubmitButton(["companyID"])(
    form,
    setSubmitDisabledInvite
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setConvertSuccess(false);
    setDeleteSuccess(false);
    setIsModalOpen(false);
    setDeleteModelPrompt(false);
    setSuccessModel(false);
    setEmplorModel(false);
    setDeleteModel(false);
    form.resetFields();
    setShowInvitePlan(false);
  };

  const setEmployersRow = function (row) {
    const adminList = row?.profile?.adminDetails?.map((admin) =>
      JSON?.parse(admin)
    );

    return {
      key: row?.id,
      searchByName: row?.searchByName,
      BusinessName: row?.name,
      Action: row.id,
      Approved: row.approved,
      BusinessEmail: adminList ? adminList[0]?.employeeEmail : "",
      Active: row.accountStatus === "ACTIVE" ? "Active" : "Inactive",
      billingMode: row?.billing_status === "NOT_REQUIRED" ? "Manual" : "Stripe",
      createdAt: row?.createdAt,
      billingPlan:
        row?.planCategory === "MID_SIZE_EMPLOYERS"
          ? "Medium"
          : row?.planCategory === "SMALL_SIZE_EMPLOYERS"
          ? "Small"
          : row?.planCategory === "LARGE_SIZE_EMPLOYERS"
          ? "Large"
          : row?.planCategory === "CUSTOM_PLAN"
          ? "Custom"
          : "",
    };
  };

  const fetchCompany = useCallback(async () => {
    setLoading(true);
    await ListCompanyRegis()
      .then((response) => {
        const data = response.map((row) => setEmployersRow(row));
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setEmployersData(data);

        setDataSource(data);
        setCsvData(response);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    setLoading(false);
  }, []);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  const createCompany = async (values) => {
    try {
      setEmployerLoding(true);
      // if(planCategory === CustomPlan)
      const myInit = {
        body: {
          companyName: values?.companyName,
          companyNumber: values?.companyNumber,
          hrEmail: values?.hrEmail,
          billingStatus: values?.billingStatus,
          inviteLink: `${hostWithProtocol}/login/?data=${encodedPlanName}&email=${values?.hrEmail}`,
          planCategory: planChange,
        },
      };

      if (planChange === "CUSTOM_PLAN") {
        // Add another object inside body
        myInit.body.customPlanDetails = {
          price: customPrice,
          maxEmployess: totalEmployeesCustom,
        };
      }
      console.log(myInit, "myInit");
      await API.post("bazarhealthREST", `/superAdmin/invite_company`, myInit);
      setDataSource([]);
      fetchCompany();
      setEmployerLoding(false);
      form.resetFields();
      setIsModalOpen(false);
      setEmplorModel(true);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "An account with the given email already exists.",
      });
      setEmployerLoding(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "BusinessName",
      "key",
      "BusinessEmail",
      "billingMode",
      "Active",
      "Action",
      "Status",
    ];
    const filteredData = search(employersData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleStatusChange = async (value) => {
    if (value === "ACTIVE") {
      setStatusLoading(true);
      const filter = {
        accountStatus: { eq: value },
      };
      await ListCompanyRegis(filter)
        .then((response) => {
          const data = response.map((row) => setEmployersRow(row));
          setDataSource(data);
        })
        .catch((error) => {
          console.log(error);
        });
      setStatusLoading(false);
    } else if (value === "INACTIVE") {
      setStatusLoading(true);
      const filter = {
        accountStatus: { ne: "ACTIVE" },
      };
      await ListCompanyRegis(filter)
        .then((response) => {
          const data = response.map((row) => setEmployersRow(row));
          setDataSource(data);
        })
        .catch((error) => {
          console.log(error);
        });
      setStatusLoading(false);
    } else {
      setDataSource(employersData);
    }
  };

  const headers = [
    { label: "Approved", key: "approved" },
    { label: "Name", key: "name" },
    { label: "URL", key: "url" },
    { label: "Phone", key: "phoneNumber" },
    { label: "Email", key: "email" },
    { label: "Email Domain", key: "emailDomain" },
    { label: "Profile Id", key: "profileID" },
    { label: "Unsubscribe Id", key: "unSubscribeID" },
    { label: "Employees", key: "employee" },
    { label: "Member", key: "member" },
    { label: "Account Status", key: "accountStatus" },
  ];

  const data = csvData.map((row) => ({
    approved: row?.approved,
    name: row?.name,
    url: row?.url,
    phoneNumber: row?.phoneNumber,
    email: row?.email,
    emailDomain: row?.emailDomain,
    profileID: row?.profileID,
    unSubscribeID: row?.unSubscribeID,
    employee: row?.employee,
    member: row?.member,
    accountStatus: row?.accountStatus,
  }));

  const columns = [
    {
      title: "Employer Name",
      dataIndex: "BusinessName",
      width: "15%",
    },
    {
      title: "Employer ID",
      dataIndex: "key",
      width: "30%",
    },
    {
      title: "Admin Email",
      dataIndex: "BusinessEmail",
      width: "22%",
    },
    {
      title: "Billing Mode",
      dataIndex: "billingMode",
    },
    {
      title: "Billing Plan",
      dataIndex: "billingPlan",
    },
    {
      title: "Billing Status",
      dataIndex: "Active",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      width: "5%",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item>
                  <Link
                    rel="noopener noreferrer"
                    to="/employer-detail"
                    state={{ id: id }}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item>
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId(id);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchEmployeeCount = async () => {
    setDeleteModelPrompt(false);
    try {
      const response = await API.graphql({
        query: getCompanyRegi,
        variables: {
          id: deleteId,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setEmployeeCount(response?.data?.getCompanyRegi?.employee?.items.length);
      setDeleteLoading(false);
      setDeleteModel(true);
    } catch (error) {
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Fetching Data",
      });
    }
  };

  const deletePermanent = async () => {
    try {
      setDeleteLoading(true);
      const myInit = {
        body: {
          companyID: deleteId,
          deleteAll: true,
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/delete_employer`, myInit);
      const newArray = dataSource.filter(function (obj) {
        return obj.key !== deleteId;
      });

      setDataSource(newArray);
      setEmployersData(newArray);
      setDeleteLoading(false);
      setDeleteModel(false);
      setDeleteSuccess(true);
    } catch (error) {
      setDeleteLoading(false);
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Deleting Employee",
      });
    }
  };

  const convertIndividual = async () => {
    try {
      setConvertLoading(true);
      const myInit = {
        body: {
          companyID: deleteId,
          deleteAll: false,
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/delete_employer`, myInit);
      const newArray = dataSource.filter(function (obj) {
        return obj.key !== deleteId;
      });

      setDataSource(newArray);
      setEmployersData(newArray);
      setConvertLoading(false);

      setConvertSuccess(true);
    } catch (error) {
      setConvertLoading(false);
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Converting Employee",
      });
    }
  };

  const onRadioChangeRef = (e) => {
    const value = e.target.value;
    if (value === "REQUIRED") {
      setPlanChange("");
      setShowInvitePlan(false);
      setBillingValue(e.target.value);
    } else {
      setBillingValue(e.target.value);
    }
  };

  const onPlanChange = (e) => {
    if (planChange !== "CUSTOM_PRICE" && customPrice && totalEmployeesCustom) {
      setCustomPrice(null);
      setTotalEmployeesCustom(null);
      form.resetFields(["employee", "price"]);
    }
    setPlanChange(e.target.value);
  };

  const handleEmployerChange = (e) => {
    setEmployerID(e);
  };

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const validateEmployeeNumber = (_, value) => {
    if (value && parseInt(value, 10) > 300) {
      return Promise.resolve();
    }
    return Promise.reject("Number of employees must be greater than 300");
  };

  return (
    <>
      {contextHolder}
      <Tabs className="employer-tabs" defaultActiveKey="1" centered>
        <Tabs.TabPane tab="Employers" key="1">
          <Row justify="space-between table-header">
            <Row align="middle">
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search"
                className="searchbar"
                onChange={handleSearch}
                style={{ width: 250 }}
              />
            </Row>

            <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
              <Select
                allowClear
                placeholder="Billing Status"
                style={{ width: 150 }}
                onChange={handleStatusChange}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="ACTIVE">ACTIVE</Option>
                <Option value="INACTIVE">INACTIVE</Option>
              </Select>

              <CSVLink
                data={data}
                headers={headers}
                filename={"Employers.csv"}
                className="export-btn"
              >
                <img src={exportCSV} alt="" className="action-icons" />
              </CSVLink>

              <img
                src={employerInvite}
                className="employer-invite"
                alt=""
                onClick={() => setEmployeeInviteUrl(true)}
              />

              <Button className="blue-btn inherit-btn" onClick={showModal}>
                Add Employer
              </Button>
            </Row>
          </Row>

          {loading || statusLoading ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={columns}
              dataSource={dataSource}
              sticky
              className="common-table"
              scroll={{ x: 992 }}
              pagination={{ position: ["", "bottomCenter"] }}
            />
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Employers chart" key="2">
          <EmployerChart />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Billing" key="3">
          <div className="no-search">
            <div className="no-search-box">
              <img src={comingsoon} alt="" width={500} />
            </div>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Plans" key="4">
          <div className="no-search">
            <div className="no-search-box">
              <img src={comingsoon} alt="" width={500} />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={800}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Employer
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <Form
          name="basic"
          onFinish={createCompany}
          autoComplete="off"
          layout="vertical"
          onFinishFailed={onFinishFailed}
          id="myForm"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Employer name"
                name="companyName"
                rules={[
                  {
                    required: true,
                    message: "Please input employer name",
                  },
                  {
                    pattern: servicesNamePattern,
                    message: "Please input employer name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Employer phone number"
                name="companyNumber"
                rules={[
                  {
                    pattern: tenDigit,
                    message: "Please enter a valid phone number",
                  },
                ]}
              >
                <InputNumber
                  controls={false}
                  formatter={(value) => {
                    return formatNumberUS(value);
                  }}
                  parser={(value) => {
                    const cleanedValue = value.replace(/\D/g, "");
                    return cleanedValue.slice(0, 10);
                  }}
                  style={{ width: "100%", borderRadius: "5px" }}
                />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Admin email"
                name="hrEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input admin email id",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Billing mode"
                name="billingStatus"
                rules={[
                  {
                    required: true,
                    message: "Required",
                  },
                ]}
              >
                <Radio.Group onChange={onRadioChangeRef}>
                  <Radio value="REQUIRED">Stripe</Radio>
                  <Radio value="NOT_REQUIRED">Manual</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {billingValue === "REQUIRED" ? (
              <Col span={24} md={24} xs={24} sm={24}>
                {showInvitePlan ? (
                  <div
                    className="referral-code mb-20"
                    style={{
                      border: "1px dashed #000",
                      padding: "20px 10px 20px 10px",
                      borderRadius: "6px",
                    }}
                  >
                    <span className="referral-code">Select a plan</span>

                    <Row gutter={20} className="mt-20 billing-cards-invite">
                      <Col
                        span={6}
                        md={6}
                        xs={24}
                        sm={24}
                        className="column-content"
                      >
                        <h4> Small size employers</h4>
                        <Divider />
                        <Col
                          md={24}
                          span={24}
                          className="mt-10 d-flex justify-content-center emp-radio"
                        >
                          <Radio
                            value="SMALL_SIZE_EMPLOYERS"
                            onChange={onPlanChange}
                            checked={planChange === "SMALL_SIZE_EMPLOYERS"}
                          >
                            <span className={`pricing `}>$99</span> / month
                          </Radio>
                        </Col>

                        <Col
                          span={24}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tag className="mt-10 members d-flex justify-content-center align-items-center">
                            0 - 30 Members
                          </Tag>
                        </Col>
                      </Col>

                      <Col
                        span={6}
                        md={6}
                        xs={24}
                        sm={24}
                        className="column-content"
                      >
                        <h4> Mid size employers</h4>
                        <Divider />
                        <Col
                          md={24}
                          span={24}
                          className="mt-10 d-flex justify-content-center emp-radio"
                        >
                          <Radio
                            value="MID_SIZE_EMPLOYERS"
                            onChange={onPlanChange}
                            checked={planChange === "MID_SIZE_EMPLOYERS"}
                          >
                            <span className={`pricing `}>$149</span>
                            <span> / month</span>
                          </Radio>
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tag className="mt-10 members d-flex justify-content-center align-items-center">
                            31 - 100 Members
                          </Tag>
                        </Col>
                      </Col>

                      <Col
                        span={6}
                        md={6}
                        xs={24}
                        sm={24}
                        className="column-content"
                      >
                        <h4> Large size employers</h4>
                        <Divider />
                        <Col
                          md={24}
                          span={24}
                          className="mt-10 d-flex justify-content-center emp-radio"
                        >
                          <Radio
                            value="LARGE_SIZE_EMPLOYERS"
                            onChange={onPlanChange}
                            checked={planChange === "LARGE_SIZE_EMPLOYERS"}
                          >
                            <span className={`pricing `}>$249</span> / month
                          </Radio>
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tag className="mt-10 members d-flex justify-content-center align-items-center">
                            101+ Members
                          </Tag>
                        </Col>
                      </Col>

                      <Col span={6} md={6} xs={24} sm={24}>
                        <h4>Custom plan</h4>
                        <Divider />
                        <Col
                          md={24}
                          span={24}
                          className="mt-10 d-flex justify-content-center emp-radio"
                        >
                          <Radio
                            value="CUSTOM_PLAN"
                            onChange={onPlanChange}
                            checked={planChange === "CUSTOM_PLAN"}
                          >
                            <span
                              className={customPrice ? `pricing` : `c-pricing`}
                            >
                              {" "}
                              {customPrice ? `$${customPrice}` : "Price"}{" "}
                            </span>{" "}
                            {customPrice ? "/ month" : ""}
                          </Radio>
                        </Col>
                        <Col
                          span={24}
                          className="d-flex justify-content-center align-items-center"
                        >
                          <Tag className="mt-10 members d-flex justify-content-center align-items-center">
                            {totalEmployeesCustom} Members
                          </Tag>
                        </Col>
                      </Col>
                    </Row>

                    {planChange === "CUSTOM_PLAN" ? (
                      <Row gutter={20} className="mt-30 billing-cards-invite">
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Number of Employees"
                            name="employee"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the number of employers",
                              },
                              {
                                pattern: onlyNumberPattern,
                                message: "Please enter a valid number",
                              },
                              {
                                validator: (_, value) => {
                                  console.log(
                                    "Value received in validator:",
                                    value
                                  );

                                  if (value && value > 300) {
                                    console.log("Validation passed");
                                    return Promise.resolve();
                                  }

                                  console.log("Validation failed");
                                  return Promise.reject(
                                    "Number of employees must be greater than 300"
                                  );
                                },
                              },
                            ]}
                          >
                            <Input onChange={handleNumberOfEmployeesCustom} />
                          </Form.Item>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Price"
                            name="price"
                            rules={[
                              {
                                required: true,
                                message:
                                  "Please enter price to charge per month",
                              },
                              {
                                pattern: onlyNumberPattern,
                                message: "Please enter a valid price",
                              },
                            ]}
                          >
                            <Input onChange={handlePriceCustom} />
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  <div
                    onClick={() => setShowInvitePlan(true)}
                    className="d-flex justify-content-center"
                    style={{
                      border: "1px dashed #000",
                      padding: "12px",
                      borderRadius: "6px",
                      margin: "0px 0px 24px 0px",
                    }}
                  >
                    <span className="referral-code">
                      Click here to select a plan
                    </span>
                  </div>
                )}
              </Col>
            ) : (
              ""
            )}

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={employerLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteModelPrompt}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal "
        // className="suggest-modal"
        footer={null}
      >
        <img src={deleteIcon} alt="Delete Img" />
        <h6>Are you sure want to delete the employer?</h6>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            onClick={fetchEmployeeCount}
            style={{ width: "106px" }}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleOk}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteModel}
        onOk={handleOk}
        width={600}
        centered
        onCancel={handleOk}
        className="reward-success-modal "
        footer={null}
      >
        <img src={deleteEmp} alt="Delete Img" />
        <p className="delete-propmt">
          {" "}
          Are you sure want to permanently delete all{" "}
          <span style={{ color: "red", fontStyle: "bold" }}>
            {employeeCount}
          </span>{" "}
          users in your employer ? <br />
          or <br /> You want to convert them as individual user{" "}
        </p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            onClick={convertIndividual}
            style={{ width: "140px" }}
            loading={convertLoading}
          >
            Convert All
          </Button>
          <Button
            type="primary"
            className="theme-btn"
            onClick={deletePermanent}
            style={{ width: "140px" }}
            loading={deleteLoading}
          >
            Delete All
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteSuccess}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal "
        // className="suggest-modal"
        footer={null}
      >
        <img src={successDelete} alt="Delete Img" />
        <h6>Employer Delete Successfully</h6>
        <p>
          Also all{" "}
          <span style={{ color: "red", fontStyle: "bold" }}>
            {employeeCount}
          </span>{" "}
          employees account in your employer successfully deleted.
        </p>
      </Modal>

      <Modal
        title="Basic Modal"
        open={convertSuccess}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal "
        // className="suggest-modal"
        footer={null}
      >
        <img src={successDelete} alt="Delete Img" />
        <h6>Employer Converted Successfully</h6>
        <p>
          Also all{" "}
          <span style={{ color: "red", fontStyle: "bold" }}>
            {employeeCount}
          </span>{" "}
          employees is convert in to individual user.
        </p>
      </Modal>

      <SuccessModel message="Approved Successfully!" open={successModel} />

      <SuccessModel
        message="Employer Added Successfully!"
        open={employerModel}
      />

      <Modal
        className="form-modal"
        centered
        footer={null}
        title={<Typography.Title level={3}>Invite employee</Typography.Title>}
        visible={employeeInviteUrl}
        onOk={() => setEmployeeInviteUrl(false)}
        onCancel={() => setEmployeeInviteUrl(false)}
      >
        <Form
          name="basic"
          onFinish={""}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChangeInvite}
        >
          <Row gutter={20}>
            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                // initialValue={companyId}
                label="Select Employer"
                name="companyID"
                rules={[
                  {
                    required: true,
                    message: "Please select any employer",
                  },
                ]}
              >
                <Select
                  allowClear
                  placeholder="Select employer"
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={handleEmployerChange}
                >
                  {employersData?.map((item) => (
                    <Select.Option
                      value={item.Action}
                    >{`${item.BusinessName}`}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item label="Invite your employees by sending them the link below">
                <Input
                  value={`${hostWithProtocol}/signup/?id=${employerID}`}
                  disabled
                />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <CopyToClipboard text={text}>
                <Button
                  className="theme-btn"
                  htmlType="submit"
                  onClick={onCopy}
                  disabled={submitDisabledInvite}
                >
                  Copy Link
                </Button>
              </CopyToClipboard>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
}
