import { Col, Row, Card, Button, Divider, Modal } from "antd";
import Container from "commons/Container";
import React from "react";
import "../FamilyBilling/BillingPlan.less";
import { loadStripe } from "@stripe/stripe-js";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ListBillingPlans } from "utility/action";
import checked from "assets/icons/checked.svg";
import upgrade from "assets/icons/upgrade.svg";

import EmployerBillingCard from "../FamilyBilling/EmployerBillingCard";
import Login from "../Authentication/Login/Login";

export default function PublicEmployerPlan() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [stripe, setStripe] = useState([]);
  const [individualPlanLoading, setIndividualPlanLoading] = useState(false);
  const [familyPlanLoading, setFamilyPlanLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [warning, setWarning] = useState(false);
  const [loginModal, setLoginModal] = useState(false);

  const userData = useSelector((state) => state?.userReducer?.userData);

  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const selectedPlanFromUrl = searchParams.get("planName");

  const getOrgCheckoutSession = async (planId, stripePriceId) => {
    let url = `/billing/checkout/org`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: userData?.id,
          planId,
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId) => {
    try {
      let sessionResult = await getOrgCheckoutSession(planId, stripePriceId);
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };

  const handlePlanCheckout = async (filterCriteria, setLoadingState) => {
    setLoadingState(true);
    setLoading(true);
    try {
      const res = await ListBillingPlans(filterCriteria);
      const planArray = await Promise.all(
        res?.filter((param) => {
          const details = JSON.parse(param?.planDetails);
          return details?.planName === selectedPlan;
        })
      );
      const obj = JSON.parse(planArray[0].planDetails);
      await createStripeSession(planArray[0].id, obj?.stripePriceId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingState(false);
      setLoading(false);
    }
  };

  const onChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedPlanFromUrl = searchParams.get("data");
    const planName = atob(selectedPlanFromUrl);

    resolveStripe();

    if (selectedPlanFromUrl) {
      setSelectedPlan(planName);
    }
  }, [location.search]);

  const handleLogin = () => {
    if (userData === null) {
      setWarning(true);
    } else {
      handlePlanCheckout(
        { name: { contains: "CORPORATE" } },
        setIndividualPlanLoading
      );
    }
  };

  return (
    <>
      <Container>
        <Card className="mt-10 mb-20 card-container">
          <Row
            justify="center"
            className="managebill-card"
            style={{
              height: "350px",
              alignItems: "flex-start",
            }}
          >
            <Row className="billing-title mt-30">
              <h1>Delphi Subscription Plan</h1>
              <p>Simplify Healthcare, Increase Access, Reduce Costs.</p>
            </Row>
          </Row>

          <Row justify="center" className="mt-10 emp-plans">
            <Col xl={22} md={24}>
              <Row gutter={[20]}>
                <EmployerBillingCard
                  title="Small Size Employers"
                  monthlyValue="SMALL_SIZE_EMPLOYERS"
                  selectedPlan={selectedPlan}
                  onChange={onChange}
                  size="0 - 30"
                  loading={individualPlanLoading}
                />

                <EmployerBillingCard
                  title="Mid Size Employers"
                  monthlyValue="MID_SIZE_EMPLOYERS"
                  selectedPlan={selectedPlan}
                  onChange={onChange}
                  size="31 - 100"
                  loading={familyPlanLoading}
                  isPopular={true}
                />

                <EmployerBillingCard
                  title="Large Size Employers"
                  monthlyValue="LARGE_SIZE_EMPLOYERS"
                  selectedPlan={selectedPlan}
                  onChange={onChange}
                  size="101+"
                  loading={individualPlanLoading}
                />
              </Row>
            </Col>
          </Row>

          <Row
            justify="center"
            className="billing-features-emp mt-30"
            style={{ paddingLeft: "15px" }}
          >
            <Col
              md={24}
              className="services d-flex justify-content-center mt-30"
            >
              <h5>Features</h5>
            </Col>

            <Divider />

            <Col md={6} className="mt-10">
              <p>
                <img src={checked} alt="" />
              Delphi platform
              </p>
              <p>
                <img src={checked} alt="" />
                Appointment scheduling
              </p>
            </Col>
            <Col md={6} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Healthcare advocacy
              </p>
              <p>
                <img src={checked} alt="" />
                Member Education
              </p>
            </Col>
            <Col md={7} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Navigation to providers and facilities
              </p>
              <p>
                <img src={checked} alt="" />
                Simplifying healthcare
              </p>
            </Col>
            <Col md={5} className="mt-10">
              <p>
                <img src={checked} alt="" />
                Provider and facility reviews
              </p>
            </Col>
          </Row>

          <Row justify="center" className="mt-30 mb-30">
            <Col md={6}>
              <Button
                className="theme-btn w-100"
                loading={loading}
                // disabled={
                //   selectedPlan === "" || selectedPlanFromUrl === selectedPlan
                //     ? true
                //     : false
                // }
                onClick={handleLogin}
              >
                Checkout
              </Button>
            </Col>
          </Row>

          <Row className="manage-bottom-box mt-20"></Row>

          <Modal
            title="Basic Modal"
            open={warning}
            onOk={() => setWarning(false)}
            centered
            onCancel={() => setWarning(false)}
            className="reward-success-modal"
            footer={null}
            width={520}
          >
            <img src={upgrade} alt="Delete Img" />
            <h6 className="mt-20">
              To complete your purchase, please <br /> log in or sign up.
            </h6>

            <Link to={`/login?employerInvite=${selectedPlanFromUrl}`}>
              <Button
                type="primary"
                className="theme-btn mt-20"
                //   onClick={() => setLoginModal(true)}
              >
                Login/Sign Up
              </Button>
            </Link>
          </Modal>

          <Modal
            title="Basic Modal"
            open={loginModal}
            onOk={() => setLoginModal(false)}
            centered
            onCancel={() => setLoginModal(false)}
            className="reward-success-modal"
            footer={null}
            width={1000}
          >
            <Login hideImg={true} />
          </Modal>
        </Card>
      </Container>
    </>
  );
}
