import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Upload,
  message,
  Avatar,
  Radio,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { UserOutlined, CameraOutlined } from "@ant-design/icons";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import SkeletonBlock from "utility/SkeletonBlocks";
import {
  GetPublicFileFromS3,
  uploadPublicFileToS3,
} from "utility/uploadFileToS3";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  GetProcedures,
  GetPublicProcedure,
  UpdateProcedures,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import {
  aboutPattern,
  docSpecialty,
  surgerySpecialty,
  gastroenterologist,
  imagingCenterOptions,
  labOptions,
  onlyNumberPattern,
  ophthalmologist,
  orthopaedic,
  otolaryngologists,
  pain_management,
  podiatrist,
  servicesNamePattern,
  surgicalSpecialityOptions,
  urology,
  neuro_surgeon,
  physiatrist,
} from "utility/constant";

export default function ProcedureDetail() {
  const [procedureData, setProcedure] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [procedureImg, setProcedureImg] = useState(null);
  const location = useLocation();
  const procedureId = location?.state.id;
  const [key, setKey] = useState("");
  const [uploadImage, setUploadImage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [faq, setFaq] = useState([{ question: "", answer: "" }]);
  const MAX_CHARS = 500;
  const [procedureDetailsForm] = Form.useForm();

  const [saveDisabled, setSaveDisabled] = useState(true);

  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const [aboutEditorState, setAboutEditorState] = useState(
    EditorState.createEmpty()
  );
  const [descriptionEditorState, setDecriptionEditorState] = useState(
    EditorState.createEmpty()
  );

  const parentOptions = [
    { value: "DOCTOR", label: "Doctor" },
    { value: "IMAGING_CENTER", label: "Imaging Center" },
    { value: "LABS", label: "Labs" },
    { value: "PHYSICAL_THERAPHY", label: "Physical Therapy" },
    { value: "SURGERY", label: "Surgical Center" },
  ];

  const dependentOptions = {
    DOCTOR: docSpecialty,
    IMAGING_CENTER: imagingCenterOptions,
    LABS: labOptions,
    SURGERY: surgerySpecialty,
  };

  const grandChildDependentOptions = {
    Otolaryngologists: otolaryngologists,
    Ophthalmologist: ophthalmologist,
    Orthopaedic: orthopaedic,
    Gastroenterologist: gastroenterologist,
    "Pain Management": pain_management,
    Podiatrist: podiatrist,
    Urology: urology,
    Physiatrist: physiatrist,
    "Neuro Surgeon": neuro_surgeon
  };

    //Child Specialities
    const otolaryngologistsSurgery = [
      { label: "Ear Nose and Throat", value: "Ear Nose and Throat" },
    ];
    const ophthalmologistSuregry = [{ label: "Eyes", value: "Eyes" }];
    const generalSurgery = [{ label: "Internal", value: "Internal" }];
    const gastroenterologistSurgery = [{ label: "Stomach", value: "Stomach" }];
  
    const neuro_surgeonSurgery = [
      { label: "Head", value: "Head" },
      { label: "Spine and Back", value: "Spine and Back" },
    ];
  
    const orthopaedicSurgery = [
      { label: "Ankle and Foot", value: "Ankle and Foot" },
      { label: "Elbow", value: "Elbow" },
      { label: "Hip", value: "Hip" },
      { label: "Hand and Wrist", value: "Hand and Wrist" },
      { label: "Leg", value: "Leg" },
      { label: "Knee", value: "Knee" },
      { label: "Neck", value: "Neck" },
      { label: "Shoulder", value: "Shoulder" },
      { label: "Spine and Back", value: "Spine and Back" },
    ];
  
    const physiatristSurgery = [
      { label: "Spine and Back", value: "Spine and Back" },
      { label: "Neck", value: "Neck" },
      { label: "Head", value: "Head" },
    ];
  
    const urologySurgery = [{ label: "Internal", value: "Internal" }];
    const podiatristSurgery = [{ label: "Ankle and Foot", value: "Ankle and Foot" }];
  
    const dependentOptionsSurgery = {
      "General Surgery": generalSurgery,
      Gastroenterology: gastroenterologistSurgery,
      "Neuro Surgeon": neuro_surgeonSurgery,
      Opthalmology: ophthalmologistSuregry,
      Otolaryngology: otolaryngologistsSurgery,
      Orthopaedics: orthopaedicSurgery,
      "Pain Management": pain_management,
      Physiatrist: physiatristSurgery,
      Urology: urologySurgery,
      Podiatry: podiatristSurgery,
    };

  const [childOptions, setChildOptions] = useState([]);
  const [parentValue, setParentValue] = useState(undefined);
  const [childValue, setChildValue] = useState(undefined);
  const [grandChildOptions, setGrandChildOptions] = useState([])
  const handleParentChange =  (value) => {
    setParentValue(value);
    setChildOptions(dependentOptions[value]);
    setIsOtherSelected(true);
    procedureDetailsForm.setFieldsValue({
      "bodyPart": "",
    })
    procedureDetailsForm.setFieldsValue({
      "areaOfSpeciality": [],
    })
    setChildValue(undefined)
  };

  const handleChildChange = (value) => {
    setChildValue(value);
    setGrandChildOptions(parentValue==="DOCTOR" ? grandChildDependentOptions[value]: dependentOptionsSurgery[value]);
    // (!firstTime && (
      procedureDetailsForm.setFieldsValue({
        "areaOfSpeciality": [],
      })
  }

  const onEditorStateChange = (aboutEditorState) => {
    setSaveDisabled(false);
    const content = aboutEditorState.getCurrentContent();
    const plainText = content.getPlainText().length;
    if (plainText <= MAX_CHARS) {
      setAboutEditorState(aboutEditorState);
    }
  };

  const handleBeforeInput = (char) => {
    const content = aboutEditorState.getCurrentContent();
    const charCount = content.getPlainText().length;
    if (charCount >= 500 && char !== "\b" && char !== "\t") {
      return "handled";
    }
  };

  const onEditorStateChangeDecription = (descriptionEditorState) => {
    setSaveDisabled(false);
    setDecriptionEditorState(descriptionEditorState);
  };

  const handleAddFaq = () => {
    setFaq([...faq, { question: "", answer: "" }]);
  };

  const handleUpdateFaq = (index, newQuestion, newAnswer) => {
    const updatedFaq = [...faq];
    updatedFaq[index] = { question: newQuestion, answer: newAnswer };
    setFaq(updatedFaq);
  };

  const handleDeleteFaq = (index) => {
    const updatedFaq = [...faq];
    updatedFaq.splice(index, 1);
    setFaq(updatedFaq);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  function UserImg() {
    return (
      <img
        src={uploadImage === null ? procedureImg : uploadImage}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const fetchProcedure = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetProcedures(procedureId);
      handleParentChange(response?.belongsToService)
      // handleChildChange(response?.bodyPart)
      setChildValue(response?.bodyPart);
      setGrandChildOptions(response?.belongsToService==="DOCTOR" ? grandChildDependentOptions[response?.bodyPart]: dependentOptionsSurgery[response?.bodyPart]);
      if (response?.image) {
        let procedure = await GetPublicFileFromS3(response?.image);
        setProcedureImg(procedure);
      }
      setProcedure(response);
      const fetchedFaqData = JSON.parse(response?.faq ?? "[]");
      setFaq(fetchedFaqData);
      procedureDetailsForm.setFieldsValue({
        name: response?.name,
        bazarPrice: response?.bazarPrice,
        belongsToService: response?.belongsToService,
        marketPrice: response?.marketPrice,
        reward: response?.reward,
        savings: response?.savings,
        bodyPart: response?.bodyPart,
        areaOfSpeciality : response?.focusOnBody,
        about: response?.about,
        description: response?.description,
        activeOnPortal: response?.activeOnPortal,
        // faq:
        //   faq && faq.length > 0
        //     ? faq.map((item, index) => ({
        //         [`question_${index}`]: item.question,
        //         [`answer_${index}`]: item.answer,
        //       }))
        //     : [{ question_0: "", answer_0: "" }],
      })

      if (response?.faq && response?.faq.length > 0) {
        response.faq.forEach((item, index) => {
          procedureDetailsForm.setFieldsValue({
            [`question_${index}`]: item.question,
                [`answer_${index}`]: item.answer,
          });
        });
      } else {
        procedureDetailsForm.setFieldsValue({
          question_0: "",
          answer_0: "",
        });
      }
      if (response?.bodyPart) {
        setIsOtherSelected(true);
      }

      if (response?.about) {
        const html = response?.about ? response?.about : "";
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setAboutEditorState(EditorState.createWithContent(contentState));
      }
      if (response?.description) {
        const html = response?.description ? response?.description : "";
        const contentBlock = htmlToDraft(html);
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        setDecriptionEditorState(EditorState.createWithContent(contentState));
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [procedureId]);

  useEffect(() => {
    fetchProcedure();
  }, [fetchProcedure]);

  const updateProcedure = async (values) => {
    try {
      setUpdateLoading(true);
      //Checks if procedure name is empty or not!
      if ((values?.name).trim() === "") {
        messageApi.open({
          type: "warning",
          content: "Procedure name is required!",
        });
        return;
      }
      const faqJson = JSON.stringify(faq);
      const about = draftToHtml(
        convertToRaw(aboutEditorState.getCurrentContent())
      );
      const description = draftToHtml(
        convertToRaw(descriptionEditorState.getCurrentContent())
      );

      const procedureExists = procedureData?.name !== values?.name.trim() ? await GetPublicProcedure(
        values?.name.toLowerCase().trim()
      ) : [];
      if (procedureExists?.length === 0) {
        const inputData = {
          id: procedureData?.id,
          name: (values?.name).trim(),
          searchByName: values?.name.toLowerCase().trim(),
          bazarPrice: values?.bazarPrice
            ? (values?.bazarPrice).trim().split(" ")[0]
            : values?.bazarPrice,
          marketPrice: values?.marketPrice
            ? (values?.marketPrice).trim().split(" ")[0]
            : values?.marketPrice,
          bodyPart: values?.bodyPart || null,
          focusOnBody: values?.areaOfSpeciality || null,
          reward: values?.reward
            ? (values?.reward).trim().split(" ")[0]
            : values?.reward,
          savings: values?.savings
            ? (values?.savings).trim().split(" ")[0]
            : values?.savings,
          belongsToService: values?.belongsToService,
          about: about,
          description: description,
          faq: faqJson,
          image: key === "" ? undefined : key,
          activeOnPortal: values?.activeOnPortal,
        };
        await UpdateProcedures(inputData);
        fetchProcedure();
        setSuccessModel(true);
        setTimeout(() => {
          setSuccessModel(false);
        }, 3000);
      } else {
        messageApi.open({
          type: "error",
          content: `Procedure with the name "${values?.name}" already exists!`,
        });
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Error updating procedure",
      });
      console.log(error);
    } finally {
      setUpdateLoading(false);
    }
  };

  const initialValues = {
    name: procedureData?.name,
    bazarPrice: procedureData?.bazarPrice,
    belongsToService: procedureData?.belongsToService,
    marketPrice: procedureData?.marketPrice,
    reward: procedureData?.reward,
    savings: procedureData?.savings,
    bodyPart: procedureData?.bodyPart,
    about: procedureData?.about,
    description: procedureData?.description,
    activeOnPortal: procedureData?.activeOnPortal,
    faq:
      faq && faq.length > 0
        ? faq.map((item, index) => ({
            [`question_${index}`]: item.question,
            [`answer_${index}`]: item.answer,
          }))
        : [{ question_0: "", answer_0: "" }],
  };

  const props = {
    onChange({ file }) {
      setSaveDisabled(false);
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    customRequest({ file, onError, onSuccess }) {
      uploadPublicFileToS3(file)
        .then(async (res) => {
          const link = await GetPublicFileFromS3(res.key);
          setUploadImage(link);
          setKey(res.key);
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    },
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {procedureData?.name}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>
      {skeletonLoader ? (
        ""
      ) : (
        <>
          <Row justify="center" className="mt-20 upload-services-photo">
            <div className="relative">
              {/* commening style to make it circular as PM suggested us */}
              <Avatar
                // style={{ borderRadius: 0 }}
                size={120}
                icon={
                  procedureImg === null && uploadImage === null ? (
                    <UserOutlined />
                  ) : (
                    <UserImg />
                  )
                }
              />
              <Upload
                {...props}
                showUploadList={false}
                beforeUpload={beforeUpload}
                className="avatar-uploader"
              >
                <Button
                  shape="circle"
                  size="middle"
                  className="btn-primary-dual-color upload-btn"
                  // style={{ bottom: -10, right: -5 }}
                >
                  <CameraOutlined />
                </Button>
              </Upload>
            </div>
          </Row>
        </>
      )}

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              <Form
                name="basic"
                form={procedureDetailsForm}
                // initialValues={initialValues}
                onFinish={updateProcedure}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
                onValuesChange={handleValuesChange}
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Procedure name"
                      name="name"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Delphi price ($)"
                      name="bazarPrice"
                      rules={[
                        {
                          pattern: /^[0-9 .]*$/,
                          message: "Only numeric values are allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Market price ($)"
                      name="marketPrice"
                      rules={[
                        {
                          pattern: /^[0-9 .]*$/,
                          message: "Only numeric values are allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Average savings ($)"
                      name="savings"
                      rules={[
                        {
                          pattern: /^[0-9 .]*$/,
                          message: "Only numeric values are allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="My reward ($)"
                      name="reward"
                      rules={[
                        {
                          pattern: /^[0-9 .]*$/,
                          message: "Only numeric values are allowed!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Procedure belongs to service"
                      name="belongsToService"
                      rules={[
                        {
                          required: true,
                          message: "Please select any option",
                        },
                      ]}
                    >
                      <Select
                        allowClear
                        placeholder="Select service"
                        options={parentOptions}
                        onChange={handleParentChange}
                        value={parentValue}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      />
                    </Form.Item>
                  </Col>

                  {isOtherSelected && (
                    <>
                      { ( parentValue !== "PHYSICAL_THERAPHY" && parentValue !== "LABS" ) && (
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Specialities"
                            name="bodyPart"
                            rules={[
                              {
                                required: true,
                                message: "Please select any option",
                              },
                            ]}
                          >
                            <Select
                              allowClear
                              placeholder="Select specialities"
                              options={childOptions}
                              onChange={handleChildChange}
                              value={childValue}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}

              {(parentValue === "DOCTOR" || parentValue === "SURGERY") && childValue && (
                    <>
                      {childValue !== "General Surgeon" && childValue !== "Sports Medicine" && (
                        <Col span={12} md={12} xs={24} sm={24}>
                          <Form.Item
                            label="Focus (on body)"
                            name="areaOfSpeciality"
                            rules={[
                              {
                                required: true,
                                message: "Please select any option",
                              },
                            ]}
                          >
                            <Select
                              mode="multiple"
                              allowClear
                              placeholder="Select body part"
                              maxTagCount="responsive"
                              options={grandChildOptions}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            ></Select>
                          </Form.Item>
                        </Col>
                      )}
                    </>
                  )}

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Account status" name="activeOnPortal">
                      <Radio.Group>
                        <Radio value={true}>Active</Radio>
                        <Radio value={false}>Inactive</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24}>
                    <Form.Item
                      label="About"
                      name="about"
                      rules={[
                        {
                          pattern: aboutPattern,
                          message: "Please enter a valid about",
                        },
                      ]}
                    >
                      <div className="editor-border">
                        <Editor
                          editorState={aboutEditorState}
                          onEditorStateChange={onEditorStateChange}
                          handleBeforeInput={handleBeforeInput}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Start typing..."
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "link",
                            ],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                              ],
                            },
                            list: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ["unordered", "ordered"],
                            },
                            link: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              popupClassName: undefined,
                              dropdownClassName: undefined,
                              showOpenOptionOnHover: true,
                              defaultTargetOption: "_self",
                              options: ["link"],
                            },
                          }}
                        />
                        <p>
                          {
                            aboutEditorState.getCurrentContent().getPlainText()
                              .length
                          }
                          /500
                        </p>
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24}>
                    <Form.Item label="Description" name="description">
                      <div className="editor-border">
                        <Editor
                          editorState={descriptionEditorState}
                          onEditorStateChange={onEditorStateChangeDecription}
                          toolbarClassName="toolbarClassName"
                          wrapperClassName="wrapperClassName"
                          editorClassName="editorClassName"
                          placeholder="Start typing..."
                          toolbar={{
                            options: [
                              "inline",
                              "blockType",
                              "fontSize",
                              "fontFamily",
                              "list",
                              "link",
                            ],
                            inline: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: [
                                "bold",
                                "italic",
                                "underline",
                                "strikethrough",
                              ],
                            },
                            list: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              dropdownClassName: undefined,
                              options: ["unordered", "ordered"],
                            },
                            link: {
                              inDropdown: false,
                              className: undefined,
                              component: undefined,
                              popupClassName: undefined,
                              dropdownClassName: undefined,
                              showOpenOptionOnHover: true,
                              defaultTargetOption: "_self",
                              options: ["link"],
                            },
                          }}
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24}>
                    <label htmlFor="">FAQ</label>
                    {initialValues?.faq.map((item, index) => (
                      <div key={index} style={{ marginTop: 10 }}>
                        <Form.Item
                          label={`Question ${index + 1}`}
                          name={`question_${index}`}
                          initialValue={item[`question_${index}`]}
                          rules={[
                            {
                              // pattern: servicesNamePattern,
                              message: "Please enter a valid question",
                            },
                          ]}
                        >
                          <Input
                            onChange={(e) =>
                              handleUpdateFaq(
                                index,
                                e.target.value,
                                initialValues?.faq[index][`answer_${index}`]
                              )
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          label={`Answer ${index + 1}`}
                          name={`answer_${index}`}
                          initialValue={item[`answer_${index}`]}
                          rules={[
                            {
                              // pattern: servicesNamePattern,
                              message: "Please enter a valid answer",
                            },
                          ]}
                        >
                          <Input.TextArea
                            onChange={(e) =>
                              handleUpdateFaq(
                                index,
                                initialValues?.faq[index][`question_${index}`],
                                e.target.value
                              )
                            }
                          />
                        </Form.Item>
                        {initialValues.faq.length > 1 && (
                          <Row justify="space-between table-header">
                            <Row align="middle">
                              <Typography.Title level={5}></Typography.Title>
                            </Row>
                            <Row align="middle">
                              <Typography.Text
                                className="hover-thumb"
                                level={3}
                                onClick={() => handleDeleteFaq(index)}
                                style={{ color: "red" }}
                              >
                                <u>Delete</u>
                              </Typography.Text>
                            </Row>
                          </Row>
                        )}
                      </div>
                    ))}

                    <Typography.Text
                      level={5}
                      onClick={handleAddFaq}
                      className="hover-thumb add-more-reviews"
                    >
                      <u>+ Add more faq</u>
                    </Typography.Text>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                    <Button
                      size="large"
                      className="theme-btn mt-20 "
                      htmlType="submit"
                      loading={updateLoading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <SuccessModel
        message="Procedure Details Updated Successfully"
        open={successModel}
      />
    </>
  );
}
