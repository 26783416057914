import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Select,
  Modal,
  Divider,
  Empty,
  message,
  Skeleton,
  Grid,
} from "antd";
import Pagination from "rc-pagination";
import { useEffect, useState } from "react";
import locationIcon from "assets/icons/location.svg";
import { SearchOutlined } from "@ant-design/icons";
import Container from "commons/Container";
import "./SurgicalCenters.less";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import { StarFilled } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  surgerySpecialty,
  SURGICAL_DETAILS_TOUR_START,
  SURGICAL_PROFILE_TOUR_START,
  SURGICAL_TOUR_END,
} from "utility/constant";

// import SkeletonBlock from "utility/SkeletonBlocks";
import { useDispatch, useSelector } from "react-redux";
import { setSurgeryState } from "store/actions/surgeryAction";

import { ListNearBySurgeryCenter } from "utility/action";
import usePaginationSearch from "utility/usePaginationSearch";
import { GetMulipleFileFromS3 } from "utility/uploadFileToS3";
import { setTourSteps } from "store/actions/tourSteps";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import { setSurgerySpeciality } from "store/actions/surgerySpeciality";
import PaginatedList from "utility/PaginatedList";

export default function SurgicalCenters() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();

  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [tour, setTour] = useState(false);
  const [tourIndex, setTourIndex] = useState(0);

  const [messageApi, contextHolder] = message.useMessage();
  const location = useLocation();

  const surgeryStatedata = useSelector(
    (state) => state.surgeryReducer?.surgery
  );

  const specialityFilters = {
    "General Surgery": ["Internal"],
    Gastroenterology: ["Stomach"],
    "Neuro Surgeon": ["Head", "Spine and Back"],
    Opthalmology: ["Eyes"],
    Otolaryngology: ["Ear Nose and Throat"],
    Orthopaedics: [
      "Ankle and Foot",
      "Elbow",
      "Hip",
      "Hand and Wrist",
      "Knee",
      "Leg",
      "Neck",
      "Shoulder",
      "Spine and Back",
    ],
    Physiatrist: ["Spine and Back", "Head", "Neck"],
    "Sports Medicine": [],
    "Pain Management": ["Spine and Back", "Neck"],
    Podiatry: ["Ankle and Foot"],
    Urology: ["Internal"],
  };

  const specialityOptionsPreCheck = useSelector(
    (state) => state?.surgerySpecialityReducer?.surgerySpeciality
  );

  const specialityOptions = specialityFilters.hasOwnProperty(
    specialityOptionsPreCheck
  )
    ? specialityOptionsPreCheck
    : undefined;

  const [areaOfSpeciality, setAreaOfSpecialty] = useState(
    specialityOptions === undefined
      ? [specialityOptionsPreCheck]
      : specialityFilters[specialityOptionsPreCheck]
  );
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const dispatch = useDispatch();
  const totalLen = surgeryStatedata?.length;
  const locationCordinates = useSelector(
    (state) => state.userReducer?.userData?.profile?.location
  );

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  useEffect(() => {
    setSurgeryState(surgeryStatedata);
  });

  const getFilterBySpeciality = (speciality) => {
    const specialityFilters = {
      "General Surgery": ["Internal"],
      Gastroenterology: ["Stomach"],
      "Neuro Surgeon": ["Head", "Spine and Back"],
      Opthalmology: ["Eyes"],
      Otolaryngology: ["Ear Nose and Throat"],
      Orthopaedics: [
        "Ankle and Foot",
        "Elbow",
        "Hip",
        "Hand and Wrist",
        "Knee",
        "Leg",
        "Neck",
        "Shoulder",
        "Spine and Back",
      ],
      Physiatrist: ["Spine and Back", "Head", "Neck"],
      "Sports Medicine": [],
      "Pain Management": ["Spine and Back", "Neck"],
      Podiatry: ["Ankle and Foot"],
      Urology: ["Internal"],
    };
    return specialityFilters[speciality] || "";
  };

  const handleSurgeryChange = async (value) => {
    try {
      // const specialty =
      setAreaOfSpecialty(getFilterBySpeciality(value));
      setSkeletonLoader(true);
      let response = await ListNearBySurgeryCenter(
        999,
        {
          lat: locationCordinates?.lat,
          lon: locationCordinates?.lon,
        },
        getFilterBySpeciality(value),
        value
      );
      if (value === "Orthopaedics") {
        const sportsData = await ListNearBySurgeryCenter(
          999,
          {
            lat: locationCordinates?.lat,
            lon: locationCordinates?.lon,
          },
          [],
          "Sports Medicine"
        );

        const painManagementData = await ListNearBySurgeryCenter(
          999,
          {
            lat: locationCordinates?.lat,
            lon: locationCordinates?.lon,
          },
          ["Spine and Back", "Neck"],
          "Pain Management"
        );

        response = await (async () =>
          response.concat(sportsData, painManagementData))();
      }
      const updatedResponse = await Promise.all(
        response.map(async (item) => {
          const key = item?.surgeryCenterProfilePhoto;
          if (key && !key.startsWith("http")) {
            const url = await GetMulipleFileFromS3(key, "public");
            if (url) {
              if (item?.surgeryCenterProfilePhoto)
                item.surgeryCenterProfilePhoto = url;
            }
          }
          return item;
        })
      );
      dispatch(setSurgerySpeciality(value));
      dispatch(setSurgeryState(updatedResponse));
    } catch (error) {
      console.log(error);
      setSkeletonLoader(false);
      messageApi.open({
        type: "error",
        content: `Error Fetching Data`,
      });
    } finally {
      setSkeletonLoader(false);
    }
  };

  const {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  } = usePaginationSearch(surgeryStatedata);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const renderSurgeryCenter = (surgery) => {
    return (
      <Col
        span={8}
        lg={8}
        md={12}
        xs={24}
        sm={24}
        key={surgery?.id}
        className="mt-10"
      >
        <div className="single-list-card-surgery">
          <div className="surgery-profile-card">
            <div className="d-flex gap-3">
              <img
                src={surgery?.surgeryCenterProfilePhoto}
                alt="Doctor Img"
                className="doctor-img"
              />
              <h4 className="doctor-name sub-header-font">
                {surgery?.surgeryCenterName}
              </h4>
            </div>
            <div className="d-flex mt-20 rat-box">
              <p className="p-font">
                <img src={facebook} alt="facebook Icon" />{" "}
                {surgery?.surgeryCenterFaceBookRating === 0 ||
                surgery?.surgeryCenterFaceBookRating === null ? (
                  "NA"
                ) : (
                  <>
                    {surgery?.surgeryCenterFaceBookRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
              <Divider className="h-15em" type="vertical" />
              <p className="p-font">
                <img src={google} alt="facebook Icon" />{" "}
                {surgery?.surgeryCenterGoogleRating === 0 ||
                surgery?.surgeryCenterGoogleRating === null ? (
                  "NA"
                ) : (
                  <>
                    {surgery?.surgeryCenterGoogleRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
            </div>

            <div className="space-between" style={{ marginBottom: "0px" }}>
              <p className="single-address-line p-font">
                <img src={locationIcon} alt="Address Icon" /> &nbsp;
                {parseFloat(surgery?.distance).toFixed(2) !== "0.00"
                  ? `${parseFloat(surgery?.distance).toFixed(2)} Miles from
                                  you`
                  : surgery?.distance === null
                  ? "-"
                  : "Near You"}
              </p>

              <Link
                to="/surgical-center-detail"
                state={{ id: surgery?.id }}
                className="learn-more-btn"
                onClick={() => {
                  if (tour) {
                    dispatch(setTourSteps(SURGICAL_PROFILE_TOUR_START));
                  }
                }}
              >
                <Button className="blue-border-btn p-font sc-first-step">
                  Learn More
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (tourSteps === SURGICAL_DETAILS_TOUR_START) {
      setTour(true);
    }
    setTimeout(() => {
      dispatch(setTourSteps(SURGICAL_TOUR_END));
    }, 500);
  }, [tourSteps]);

  const serviceSteps = [
    {
      target: ".sc-first-step",
      content: (
        <TourStepContent
          heading="Learn More"
          details="Click to discover more information about the Surgical Facility Center."
          numbers={{ currentStep: 4, totalSteps: 7 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".sc-second-step",
      content: (
        <TourStepContent
          heading="Surgical Center"
          details="Please choose your desired Surgical Center and proceed to the next step for assistance."
          numbers={{ currentStep: 1, totalSteps: 6 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}
      <Container>
        <GlobalJoyride
          steps={serviceSteps}
          run={tour}
          stepIndex={tourIndex}
          setTourIndex={setTourIndex}
        />

        {/* Sub Header Starts */}
        <div className="d-flex justify-content-between hide-version-no">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" className="p-font">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="p-font"> Surgical Center List</span>
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>

        {/* main content starts*/}
        <Card className="list-content mobile-card">
          <Row
            justify="space-between"
            align="middle"
            gutter={[0, 15]}
            className="mb-20 r-mb-10 "
          >
            <Col>
              <p className="card-title-surgery header-font">
                Surgical Center List ({totalLen} Result)
              </p>

              {areaOfSpeciality?.length !== 0 ? (
                <p className="sub-title-box-surgery p-font mb-0">
                  You are looking for{" "}
                  {areaOfSpeciality ? areaOfSpeciality.join(", ") : "-"}
                </p>
              ) : (
                ""
              )}
            </Col>
            {screens.xs ? (
              ""
            ) : (
              <Col xs={24} md={6}>
                <Select
                  className="specialty-dropdown select-font"
                  defaultValue={
                    specialityOptions
                      ? specialityOptions
                      : "Select Any Specialty"
                  }
                  options={surgerySpecialty}
                  onChange={handleSurgeryChange}
                />
              </Col>
            )}
          </Row>

          <Col span={24} className="d-flex gap-3">
            <Input
              className="main-search border-bottom-only search-font"
              size="large"
              placeholder="Search Here"
              prefix={<SearchOutlined />}
              onChange={handleSearchInputChange}
              value={searchText}
            />

            <Pagination
              onChange={handlePaginationChange}
              total={surgeryStatedata?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
              className="hide-version-no"
            />
          </Col>

          {screens.xs && (
            <Col xs={24} md={6} className="mt-10">
              <Select
                className="specialty-dropdown select-font"
                defaultValue={
                  specialityOptions ? specialityOptions : "Select Any Specialty"
                }
                options={surgerySpecialty}
                onChange={handleSurgeryChange}
              />
            </Col>
          )}

          <Row gutter={[20, 10]} className="r-mt-10">
            {skeletonLoader ? (
              <>
                <>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  <Col span={8} lg={8} md={12} sm={24} xs={24}>
                    <Skeleton.Avatar
                      shape="square"
                      size="500"
                      active
                      style={{
                        width: screens.lg ? "350px" : "300px",
                        height: screens.lg ? "220px" : "200px",
                      }}
                    />
                  </Col>
                  {/* </Row> */}
                </>
              </>
            ) : (
              <>
                {paginatedItems.length > 0 ? (
                  <>
                    {paginatedItems.map(renderSurgeryCenter)}
                    {screens.xs && (
                      <Col
                        span={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        className="mt-10"
                      >
                        <Pagination
                          onChange={handlePaginationChange}
                          total={surgeryStatedata?.length}
                          defaultPageSize={9}
                          current={currentPage}
                          itemRender={PrevNextArrow}
                        />
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="no-data">
                    <Col md={24}>
                      <Empty />
                    </Col>
                  </div>
                )}
              </>
            )}
          </Row>
        </Card>
      </Container>
    </>
  );
}
