import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Upload,
  message,
  Tag,
  Card,
  Skeleton,
  Modal,
  Space,
  InputNumber,
  Radio,
} from "antd";
import CopyToClipboard from "react-copy-to-clipboard";
import copy from "assets/icons/copy.svg";

import {
  CloudUploadOutlined,
  DownloadOutlined,
  CopyOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "commons/BackButton";
import { getCompanyRegi } from "graphql/queries";
import { API, Auth } from "aws-amplify";
import SkeletonBlock from "utility/SkeletonBlocks";
import {
  ListEligibleMembers,
  ListEmployeesRegis,
  UpdateCompanyProfile,
  UpdateCompanyRegi,
} from "utility/action";
import {
  cityPattern,
  formatNumberUS,
  streetAddressPattern,
  tenDigit,
  urlPattern,
  zipPattern,
} from "utility/constant";
import SuccessModel from "utility/SuccessModel";
import { uploadFileToS3 } from "utility/uploadFileToS3";
import { useCallback } from "react";

export default function EmployerDetail() {
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [billingModal, setBillingModal] = useState(false);
  const [billModeLoader, setBillModeLoader] = useState(false);
  const [billSuccessModel, setBillSuccessModel] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [benefitFiles, setBenefitFiles] = useState([]);
  const [otherFiles, setOtherFiles] = useState([]);
  const [visionFiles, setVisionFiles] = useState([]);
  const [employersData, setEmployerData] = useState([]);
  const [eligibleCount, setEligibleCount] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [successModel, setSuccessModel] = useState(false);
  const [registerCount, setRegisterCount] = useState();
  const [unregiCount, setUnregiCount] = useState([]);
  const [benefitPreview, setBenefitPreview] = useState([]);
  const [visionPreview, setVisionPreview] = useState([]);
  const [otherPreview, setOtherPreview] = useState([]);
  const [benefitDocs, setBenefitDocs] = useState([]);
  const [visionDocs, setVisionDocs] = useState([]);
  const [otherDocs, setOtherDocs] = useState([]);
  const [showInvitePlan, setShowInvitePlan] = useState(false);

  const [cognitoId, setCognitoId] = useState("");
  const [loading, setLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [inviteLoading, setInviteLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const employerId = location?.state?.id;
  const [form] = Form.useForm();

  const [planChange, setPlanChange] = useState("");
  const onPlanChange = (e) => {
    setPlanChange(e.target.value);
  };
  const hostWithProtocol =
    window.location.protocol + "//" + window.location.host;

  const encodedPlanName = btoa(planChange);
  const adminDetails = adminData.map((admin) => JSON.parse(admin));

  const text = `${hostWithProtocol}/login/?data=${encodedPlanName}&email=${adminDetails[0]?.employeeEmail}`;

  const [saveDisabled, setSaveDisabled] = useState(true);
  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const fetchNotRegisteredEmployee = useCallback(async () => {
    const filterNotRegistered = {
      companyRegiEmployeeId: { contains: employerId },
      lastLogin: { attributeExists: false },
    };
    const filterEligible = {
      registered: { eq: false },
      invited: { eq: false },
      companyID: { contains: employerId },
    };

    const [notRegisteredResponse, eligibleResponse] = await Promise.all([
      ListEmployeesRegis(filterNotRegistered, 999),
      ListEligibleMembers(filterEligible, 999),
    ]);
    const mergedData = [...notRegisteredResponse, ...eligibleResponse];
    setUnregiCount(mergedData);
  }, []);

  useEffect(() => {
    fetchNotRegisteredEmployee();
  }, [fetchNotRegisteredEmployee]);

  const fetchCompany = useCallback(async () => {
    setskeletonLoader(true);
    const response = await API.graphql({
      query: getCompanyRegi,
      variables: {
        id: employerId,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const myInit = {
      body: {
        companyID: employerId,
      },
    };

    let url = `/superAdmin/send_presigned_url`;
    setBenefitDocs(response.data.getCompanyRegi?.benefitDocs);
    setVisionDocs(response.data.getCompanyRegi?.visionDocs);
    setOtherDocs(response.data.getCompanyRegi?.otherDocs);

    if (response.data?.getCompanyRegi?.profile?.benefitDocs) {
      const myDemo = {
        body: {
          keys: response.data?.getCompanyRegi?.profile?.benefitDocs,
        },
      };
      let result = await API.post("bazarhealthREST", url, myDemo);
      setBenefitPreview(result);
    }
    if (response.data?.getCompanyRegi?.profile?.visionDocs) {
      const myDemo = {
        body: {
          keys: response.data?.getCompanyRegi?.profile?.visionDocs,
        },
      };
      let result = await API.post("bazarhealthREST", url, myDemo);
      setVisionPreview(result);
    }
    if (response.data?.getCompanyRegi?.profile?.otherDocs) {
      const myDemo = {
        body: {
          keys: response.data?.getCompanyRegi?.profile?.otherDocs,
        },
      };
      let result = await API.post("bazarhealthREST", url, myDemo);
      setOtherPreview(result);
    }

    const totalCount = await API.post(
      "bazarhealthREST",
      `/superAdmin/get_count_eliMembers`,
      myInit
    );

    setEligibleCount(totalCount);
    setEmployerData(response.data?.getCompanyRegi);
    setPlanChange(response.data?.getCompanyRegi?.planCategory);
    if (response.data?.getCompanyRegi?.planCategory !== null) {
      setShowInvitePlan(true);
    }

    setAdminData(response.data?.getCompanyRegi?.profile?.adminDetails);
    setskeletonLoader(false);
  }, [employerId]);

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const fetchRegisterCount = useCallback(async () => {
    setLoading(true);
    const filter = {
      companyRegiEmployeeId: { contains: employerId },
      lastLogin: { attributeExists: true },
    };
    await ListEmployeesRegis(filter)
      .then((response) => {
        setRegisterCount(response.length);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
    setLoading(false);
  }, [employerId]);

  useEffect(() => {
    fetchCompany();
  }, [fetchCompany]);

  useEffect(() => {
    fetchRegisterCount();
  }, [fetchRegisterCount]);

  useEffect(() => {
    const cognitoData = async () => {
      const loggedInUser = await Auth.currentUserInfo();
      setCognitoId(loggedInUser?.id);
    };
    cognitoData();
  }, []);

  const initialValues = {
    name: employersData?.profile?.name,
    phoneNumber: employersData?.profile?.phoneNumber,
    benefitInfoCall: employersData?.profile?.benefitInfoCall,
    email: employersData?.profile?.email,
    url: employersData?.profile?.url,
    streetAddress: employersData?.profile?.streetAddress,
    city: employersData?.profile?.city,
    state: employersData?.profile?.state,
    zip: employersData?.profile?.zip,
    employerID: employersData?.id,
    benefitInfoCall: employersData?.profile?.benefitInfoCall,
  };

  const updateEmployerProfile = async (values) => {
    try {
      setUpdateLoading(true);
      const inputData = {
        email: values?.email,
        url: values?.url,
        phoneNumber: values?.phoneNumber,
        benefitInfoCall: values?.benefitInfoCall,
        streetAddress: values?.streetAddress,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        benefitDocs: benefitFiles.length === 0 ? benefitDocs : benefitFiles,
        visionDocs: visionFiles.length === 0 ? visionDocs : visionFiles,
        otherDocs: otherFiles.length === 0 ? otherDocs : otherFiles,
      };
      await UpdateCompanyProfile(inputData);
      fetchCompany();
      setUpdateLoading(false);
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      form.resetFields();
    } catch (error) {
      setLoading(false);
      messageApi.open({
        type: "error",
        content: "Error updating data",
      });
    }
  };

  const vielAllEmployees = () => {
    localStorage.setItem("companyId", employersData?.id);
    localStorage.setItem("companyName", employersData?.name);
    navigate("/all-employees", {});
  };

  const visionUpload = {
    onChange({ file }) {
      if (file.status === "uploading") {
        setLoading(true);
      }
      if (file.status === "done") {
        setLoading(false);
      }
    },
    customRequest({ file, onError, onSuccess }) {
      uploadFileToS3(file)
        .then(async (res) => {
          setVisionFiles((visionItem) => [
            ...visionItem,
            {
              fileName: file.name,
              fileKey: "private/" + cognitoId + "/" + res.key,
            },
          ]);
          onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          onError();
          setLoading(false);
        });
    },
  };

  const benefitUpload = {
    onChange({ file }) {
      if (file.status === "uploading") {
        setLoading(true);
      }
      if (file.status === "done") {
        setLoading(false);
      }
    },
    customRequest({ file, onError, onSuccess }) {
      uploadFileToS3(file)
        .then(async (res) => {
          setBenefitFiles((benefitItem) => [
            ...benefitItem,
            {
              fileName: file.name,
              fileKey: "private/" + cognitoId + "/" + res.key,
            },
          ]);
          onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          onError();
          setLoading(false);
        });
    },
  };

  const otherUpload = {
    onChange({ file }) {
      if (file.status === "uploading") {
        setLoading(true);
      }
      if (file.status === "done") {
        setLoading(false);
      }
    },
    customRequest({ file, onError, onSuccess }) {
      uploadFileToS3(file)
        .then(async (res) => {
          setOtherFiles((otherItem) => [
            ...otherItem,
            {
              fileName: file.name,
              fileKey: "private/" + cognitoId + "/" + res.key,
            },
          ]);
          onSuccess();
          setLoading(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
          onError();
          setLoading(false);
        });
    },
  };

  const changeBillingMode = async () => {
    try {
      setBillModeLoader(true);
      const myInit = {
        body: {
          companyID: employersData?.id,
        },
      };
      await API.post("bazarhealthREST", `/superAdmin/stripeToManual`, myInit);

      fetchCompany();
      setBillingModal(false);
      setBillSuccessModel(true);
      setTimeout(() => {
        setBillSuccessModel(false);
      }, 3000);
    } catch (err) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setBillModeLoader(false);
    }
  };

  const generateInviteLink = async () => {
    try {
      setInviteLoading(true);
      const inputData = {
        id: employersData?.id,
        planCategory: planChange,
      };
      const res = await UpdateCompanyRegi(inputData);
      setEmployerData(res);
    } catch (err) {
      console.log(err);
    } finally {
      setInviteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title
          level={4}
          className="text-center mb-0"
          style={{ justifyContent: "Center" }}
        >
          {employersData?.name}
        </Typography.Title>
        <Button className="temp-fix">Download PDF</Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          <Row justify="space-between" align="middle">
            <div>
              <Typography.Title level={5} className="mb-0">
                Total <br />
                Members
              </Typography.Title>
              <Typography.Title
                level={4}
                className="mt-0 mb-0 text-primary fw-bolder"
              >
                {skeletonLoader ? (
                  <>
                    <Row gutter={[20, 20]}>
                      <SkeletonBlock style={{ width: 100, height: 50 }} />
                    </Row>
                  </>
                ) : (
                  <>
                    {eligibleCount !== 0
                      ? eligibleCount
                      : registerCount + unregiCount.length}
                  </>
                )}
              </Typography.Title>
            </div>
            <Row align="middle">
              <div>
                <Typography.Title level={5} className="mb-0">
                  Total Registered
                  <br /> Members
                </Typography.Title>
                <Typography.Title
                  level={4}
                  className="mt-0 mb-0 text-primary fw-bolder"
                >
                  {skeletonLoader ? (
                    <>
                      <Row gutter={[20, 20]}>
                        <SkeletonBlock style={{ width: 100, height: 50 }} />
                      </Row>
                    </>
                  ) : (
                    <>{registerCount === 0 ? "0" : registerCount}</>
                  )}
                </Typography.Title>
              </div>
            </Row>
            <Row align="middle">
              <div>
                <Typography.Title level={5} className="mb-0">
                  Total Not Registered
                  <br /> Members
                </Typography.Title>
                <Typography.Title
                  level={4}
                  className="mt-0 mb-0 text-primary fw-bolder"
                >
                  {skeletonLoader ? (
                    <>
                      <Row gutter={[20, 20]}>
                        <SkeletonBlock style={{ width: 100, height: 50 }} />
                      </Row>
                    </>
                  ) : (
                    unregiCount.length
                  )}
                </Typography.Title>
              </div>
            </Row>
            <Row align="middle">
              {skeletonLoader ? (
                ""
              ) : (
                <Button className="blue-btn" onClick={vielAllEmployees}>
                  View All Members
                </Button>
              )}
            </Row>
          </Row>
          <Divider style={{ marginBottom: 0 }} />
        </Col>

        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]} className="mt-3">
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 50 }} />
                <SkeletonBlock style={{ width: 700, height: 200 }} />
              </Row>
            </>
          ) : (
            <>
              {employersData?.billing_status === "REQUIRED" ? (
                <div className="employer-card mb-20">
                  <Card
                    className="doctor-profile bottom-padding"
                    style={{
                      border: "1px solid #A9C8DD",
                      borderStyle: "dashed",
                    }}
                  >
                    <div className="doctor-overview">
                      <Row className="overview-details">
                        {skeletonLoader ? (
                          <Skeleton paragraph={{ rows: 10 }}></Skeleton>
                        ) : (
                          <>
                            <Row justify="space-between table-header w-100">
                              <Row align="middle">
                                <Typography.Title
                                  level={5}
                                  style={{ marginBottom: 0 }}
                                >
                                  Billing Mode :{" "}
                                  {employersData?.billing_status ===
                                  "NOT_REQUIRED"
                                    ? "MANUAL"
                                    : "STRIPE"}
                                </Typography.Title>
                              </Row>

                              <Row className="gap-10 mtr-20">
                                <Button
                                  className="blue-border-btn"
                                  onClick={() => setBillingModal(true)}
                                >
                                  Change Billing Mode
                                </Button>
                              </Row>
                            </Row>
                          </>
                        )}
                      </Row>
                    </div>
                  </Card>
                </div>
              ) : (
                ""
              )}

              <Form
                name="basic"
                initialValues={initialValues}
                onFinish={updateEmployerProfile}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
                onValuesChange={handleValuesChange}
              >
                <Row gutter={20}>
                  {employersData?.accountStatus === "ACTIVE" ? (
                    <Col span={24} md={24} xs={24} sm={24}>
                      <Form.Item
                        label="Upload employees list file"
                        name="employeeList"
                      >
                        <Upload {...otherUpload}>
                          <Button
                            icon={<CloudUploadOutlined />}
                            className="border-radius-6px"
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            Drag and drop or click to select employees list CSV
                            here
                          </Button>
                        </Upload>
                        {otherPreview.map((image, index) => (
                          <a href={image?.fileKey} download>
                            <Tag
                              color="success"
                              className="downloadable mr-10"
                              style={{ marginTop: 10 }}
                            >
                              {image?.fileName} <DownloadOutlined />
                            </Tag>
                          </a>
                        ))}
                      </Form.Item>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Employer name" name="name">
                      <Input disabled />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Employer email id" name="email">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <CopyToClipboard text={employerId}>
                      <Form.Item
                        label="Employer id"
                        name="employerID"
                        onClick={onCopy}
                      >
                        <Input
                          suffix={
                            <>
                              <CopyToClipboard text={employerId}>
                                <CopyOutlined />
                              </CopyToClipboard>
                            </>
                          }
                          disabled
                        />
                      </Form.Item>
                    </CopyToClipboard>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Employer url"
                      name="url"
                      rules={[
                        {
                          pattern: urlPattern,
                          message: "Please input valid url",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Employer phone number"
                      name="phoneNumber"
                      rules={[
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Benefit info call"
                      name="benefitInfoCall"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        {
                          pattern: tenDigit,
                          message: "Please enter a valid phone number",
                        },
                      ]}
                    >
                      <InputNumber
                        controls={false}
                        formatter={(value) => formatNumberUS(value)}
                        parser={(value) => {
                          const cleanedValue = value.replace(/\D/g, "");
                          return cleanedValue.slice(0, 10);
                        }}
                        style={{ width: "100%", borderRadius: "5px" }}
                      />
                    </Form.Item>
                  </Col>

                  {adminDetails.map((admin, index) => (
                    <>
                      <Col span={12} md={12} xs={24} sm={24} key={index}>
                        <Form.Item
                          label={`Admin name ${index + 1}`}
                          name={`adminName[${index}]`}
                          initialValue={
                            admin.firstName
                              ? `${admin.firstName} ${admin.lastName}`
                              : ""
                          }
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label={`Admin email ${index + 1}`}
                          name={`adminEmail[${index}]`}
                          initialValue={
                            admin.employeeEmail ? admin.employeeEmail : ""
                          }
                        >
                          <Input disabled />
                        </Form.Item>
                      </Col>
                    </>
                  ))}

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Street address"
                      name="streetAddress"
                      rules={[
                        {
                          pattern: streetAddressPattern,
                          message: "Please enter a valid street address",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid city name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="State"
                      name="state"
                      rules={[
                        {
                          pattern: cityPattern,
                          message: "Please enter a valid state name",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Zip"
                      name="zip"
                      rules={[
                        {
                          pattern: zipPattern,
                          message: "Please enter a valid ZIP code",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Upload benefit docs" name="benefitDocs">
                      <Upload {...benefitUpload}>
                        <Button
                          icon={<CloudUploadOutlined />}
                          className="border-radius-6px"
                        >
                          Click here to upload Files
                        </Button>
                      </Upload>
                      {benefitPreview.map((image, index) => (
                        <a href={image?.fileKey} download>
                          <Tag
                            color="success"
                            className="downloadable mr-10"
                            style={{ marginTop: 10 }}
                          >
                            {image?.fileName} <DownloadOutlined />
                          </Tag>
                        </a>
                      ))}
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Upload vision docs" name="visionDocs">
                      <Upload {...visionUpload}>
                        <Button
                          icon={<CloudUploadOutlined />}
                          className="border-radius-6px"
                        >
                          Click here to upload Files
                        </Button>
                      </Upload>
                      {visionPreview.map((image, index) => (
                        <a href={image?.fileKey} download>
                          <Tag
                            color="success"
                            className="downloadable mr-10"
                            style={{ marginTop: 10 }}
                          >
                            {image?.fileName} <DownloadOutlined />
                          </Tag>
                        </a>
                      ))}
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Upload other docs" name="otherDocs">
                      <Upload {...otherUpload}>
                        <Button
                          icon={<CloudUploadOutlined />}
                          className="border-radius-6px"
                        >
                          Click here to upload Files
                        </Button>
                      </Upload>
                      {otherPreview.map((image, index) => (
                        <a href={image?.fileKey} download>
                          <Tag
                            color="success"
                            className="downloadable mr-10"
                            style={{ marginTop: 10 }}
                          >
                            {image?.fileName} <DownloadOutlined />
                          </Tag>
                        </a>
                      ))}
                    </Form.Item>
                  </Col>
                  <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                    <Button
                      size="large"
                      className="theme-btn mt-20"
                      htmlType="submit"
                      loading={updateLoading}
                      disabled={saveDisabled}
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
      </Row>

      <SuccessModel
        message="Employer Details Updated Successfully!"
        open={successModel}
      />

      <SuccessModel
        message="Billing Mode Updated Successfully!"
        open={billSuccessModel}
      />

      <Modal
        title="Basic Modal"
        open={billingModal}
        onOk={() => setBillingModal(false)}
        centered
        width={400}
        onCancel={() => setBillingModal(false)}
        className="reward-success-modal employer-app-card-prompt"
        closable={false}
        footer={null}
      >
        <h6>Are you sure want to change billing mode?</h6>

        <p style={{ fontSize: 14, marginTop: 0 }}>
          This will also change billing mode of members under that employer.
        </p>

        <Space>
          <Button
            type="primary"
            className="theme-btn grey-color"
            onClick={() => setBillingModal(false)}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            className="theme-btn"
            onClick={changeBillingMode}
            style={{ width: "106px" }}
            loading={billModeLoader}
          >
            Yes
          </Button>
        </Space>
      </Modal>
    </>
  );
}
