import React, { useState } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Input,
  Form,
  Typography,
  Modal,
  Upload,
  message,
  Avatar,
  DatePicker,
  Select,
  Table,
  Space,
  Grid,
  Divider,
  InputNumber,
} from "antd";
import { PhoneFilled, CameraOutlined, UserOutlined } from "@ant-design/icons";
import copy from "assets/icons/copy.svg";
import "./UserProfile.less";
import Container from "commons/Container";
import invite from "assets/icons/invite.svg";
import { Tabs } from "antd";
import moment from "moment";
import { API, Auth } from "aws-amplify";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserSubmissions from "./UserSubmissions";
import { getEmployeeRegi, getMember } from "graphql/queries";
import SkeletonBlock from "utility/SkeletonBlocks";
import { setUserData } from "store/actions/userData";
import { useDispatch, useSelector } from "react-redux";
import { setUserID } from "store/actions/userID";
import successIcon from "assets/icons/Success.svg";
import { GetFileFromS3, uploadFileToS3 } from "utility/uploadFileToS3";
import { updateEmployeeProfile, updateMemberProfile } from "graphql/mutations";
import CopyToClipboard from "react-copy-to-clipboard";
import SuccessModel from "utility/SuccessModel";
import { LOGOUT } from "store/actions";
import { ListEligibleMembers } from "utility/action";
import {
  cityPattern,
  emailPattern,
  formatNumberUS,
  streetAddressPattern,
  tenDigit,
  zipPattern,
} from "utility/constant";
import axios from "axios";

export default function UserProfile() {
  const gridStyle = {
    width: "50%",
    border: "0.5px solid #646464",
  };
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [userProfileData, setUserProfileData] = useState([]);
  const [showInput, setShowInput] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [date, setDate] = useState("");
  const [requestDOB, setRequestDOB] = useState("");
  const [dp, setDp] = useState(null);

  const [key, setKey] = useState();
  const [loadingButtons, setLoadingButtons] = useState([]);
  const [userDp, setUserDp] = useState();
  const [userGroup, setUserGroup] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [hpiModel, sethpiModel] = useState(false);
  const [inviteSuccessModel, setInviteSuccessModel] = useState(false);
  const [editSuccess, setEditSuccess] = useState(false);
  const [inviteModel, setInviteModel] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const [dependentData, setDependentData] = useState([]);
  const [dateValidation, setDateValidation] = useState("");

  const [loading, setLoading] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [memberDependentModal, setMemberDependentModal] = useState(false);
  const [memberForm] = Form.useForm();

  const [editLoading, setEditLoading] = useState(false);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [invitingName, setInvitingName] = useState("");
  const [invitingID, setInvitingID] = useState("");
  const [invitingEmail, setInvitingEmail] = useState("");

  const userData = useSelector((state) => state.userReducer?.userData);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hostWithProtocol =
    window.location.protocol + "//" + window.location.host;
  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const [text] = useState(`${hostWithProtocol}/signup/?id=${userData?.id}`);
  const procedure = localStorage.getItem("procedureName");
  const userType = useSelector(
    (state) => state?.userReducer?.userData?.userType
  );
  const companyID = useSelector(
    (state) => state?.userReducer?.userData?.companyRegiEmployeeId
  );
  const companyDetails = useSelector(
    (state) => state?.userReducer?.userData?.companyID
  );
  const billingStatus = useSelector(
    (state) => state?.userReducer?.userData?.billing_status
  );

  const isIndividual =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    userType === "INDIVIDUAL" &&
    !companyID &&
    !companyDetails;
  const isUnderStripeEmployer =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
    companyID !== null &&
    companyDetails !== null;

  const openUrlBox = () => {
    setShowInput(true);
  };

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const handleOk = () => {
    setIsModalOpen(false);
    sethpiModel(false);
    setInviteModel(false);
    setInviteSuccessModel(false);
    setEditModal(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    sethpiModel(false);
    setInviteModel(false);
    setMemberDependentModal(false);
    setInviteSuccessModel(false);
    setEditModal(false);
  };

  useEffect(() => {
    const getImage = async () => {
      const photo = userData?.profile?.profilePhoto?.startsWith("https")
        ? userData?.profile?.profilePhoto
        : await GetFileFromS3(userData?.profile?.profilePhoto);
      console.log(photo);
      setUserDp(photo);
    };

    if (userData?.profile?.profilePhoto !== null) {
      getImage();
    }
  }, [userData?.profile?.profilePhoto]);

  // for date picker
  const onChangeDatepicker = (date, dateString) => {
    const eighteenYearsAgo = moment().subtract(18, "years");
    const formattedEighteenYearsAgo = eighteenYearsAgo.format("YYYY-MM-DD");
    setDateValidation(formattedEighteenYearsAgo);
    setDate(dateString);
  };

  const onRequestDOB = (date, dateString) => {
    setRequestDOB(dateString);
  };

  const createHpiEmp = async (values) => {
    setLoading(true);
    const myInit = {
      body: {
        id: userData?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        memeberID: values?.memeberID,
        zipCode: values?.zipCode,
        dob: date,
      },
    };
    await API.post("bazarhealthREST", `/hpi/verify`, myInit);
    setLoading(false);
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  function UserImg() {
    return (
      <img
        src={dp === null ? userDp : dp}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const onFinish = async (values) => {
    setCreateLoading(true);
    const inputData = {
      input: {
        email: userProfileData?.email,
        profilePhoto: key,
        dob: date ? moment(date).format("YYYY-MM-DD") : undefined,
        firstName: values?.firstName,
        lastName: values?.lastName,
        insuranceCarrier: values?.InsuranceCarrier
          ? values?.InsuranceCarrier
          : undefined,
        companyURL: values?.companyURL,
        streetAdress: values?.streetAdress,
        city: values?.city,
        state: values?.state,
        zip: values?.zip,
        profileCompleted: true,
        phone: values?.phone,
        editStatus: "COMPLETED",
      },
    };

    await API.graphql({
      query:
        userGroup === "Member" ? updateMemberProfile : updateEmployeeProfile,
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const empRegi = await API.graphql({
      query: getEmployeeRegi,
      variables: {
        id: userData?.id,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    dispatch(setUserData(empRegi.data.getEmployeeRegi));

    if (
      userData.billing_status === "NOT_REQUIRED" ||
      (userData?.userType === "CORPORATE_ADMIN" &&
        userData?.accountStatus === "ACTIVE") ||
      (userData?.userType === "CORPORATE" &&
        userData?.accountStatus === "ACTIVE") ||
      (userData?.userType === "INDIVIDUAL" &&
        userData?.accountStatus === "ACTIVE")
    ) {
      if (procedure) {
        setTimeout(() => {
          navigate(`/user-procedure-detail/${procedure}`);
        }, 3000);
      }
      fetchUserProfile();
      setIsModalOpen(true);
      // navigate("/");
      setCreateLoading(false);
    } else if (
      userData?.userType === "CORPORATE_ADMIN" &&
      userData?.group !== "Member"
    ) {
      navigate("/corporate-billing");
      setCreateLoading(false);
    } else {
      navigate("/billing");
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const fetchUserProfile = async () => {
    try {
      setskeletonLoader(true);
      const userData = await Auth.currentAuthenticatedUser({
        bypassCache: false,
      });
      const result = Object.values(userData);
      dispatch(setUserID(result[9]?.sub));
      const usersID = result[9]?.sub;

      const cognitoGroup =
        userData.signInUserSession.accessToken.payload["cognito:groups"][0];
      setUserGroup(cognitoGroup);
      if (cognitoGroup === "Member") {
        const responce = await API.graphql({
          query: getMember,
          variables: {
            id: usersID,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        setskeletonLoader(false);

        const hardcodedEntry = {
          key: "hardcodedKey",
          Username: responce?.data?.getMember?.employeeID?.emailID,
          FirstName: responce?.data?.getMember?.employeeID?.firstName,
          LastName: responce?.data?.getMember?.employeeID?.lastName,
          Role:
            responce?.data?.getMember?.employeeID?.userType ===
              "CORPORATE_ADMIN" &&
            responce?.data?.getMember?.employeeID?.group !== "Member"
              ? "ADMIN"
              : responce?.data?.getMember?.employeeID?.userType ===
                  "INDIVIDUAL" &&
                responce?.data?.getMember?.employeeID?.group !== "Member"
              ? "INDIVIDUAL"
              : responce?.data?.getMember?.employeeID?.userType ===
                  "CORPORATE" &&
                responce?.data?.getMember?.employeeID?.group !== "Member"
              ? "EMPLOYEE"
              : responce?.data?.getMember?.employeeID?.group === "Member"
              ? "DEPENDENT"
              : "-",
          Active: responce?.data?.getMember?.employeeID?.lastLogin
            ? "ACTIVE"
            : "INACTIVE",
          style: { background: "red" },
        };

        const memberDep = {
          key: "almostHardcodedKey",
          Username: responce?.data?.getMember?.emailID,
          FirstName: responce?.data?.getMember?.firstName,
          LastName: responce?.data?.getMember?.lastName,
          Role:
            responce?.data?.getMember?.userType === "CORPORATE_ADMIN" &&
            responce?.data?.getMember?.group !== "Member"
              ? "ADMIN"
              : responce?.data?.getMember?.userType === "INDIVIDUAL" &&
                responce?.data?.getMember?.group !== "Member"
              ? "INDIVIDUAL"
              : responce?.data?.getMember?.userType === "CORPORATE" &&
                responce?.data?.getMember?.group !== "Member"
              ? "EMPLOYEE"
              : responce?.data?.getMember?.group === "Member"
              ? "DEPENDENT"
              : "-",
          Active: responce?.data?.getMember?.lastLogin ? "ACTIVE" : "INACTIVE",
          // style: { background: "red" },
        };

        setDependentData([hardcodedEntry, memberDep]);
        setUserProfileData(responce.data.getMember?.profile);
        // setMemberData(responce.data.getMember?.profile);
        dispatch(setUserData(responce.data.getMember));
      } else {
        const responce = await API.graphql({
          query: getEmployeeRegi,
          variables: {
            id: usersID,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const userData = responce.data.getEmployeeRegi;
        if (userData?.profile?.dob) {
          const userDob = moment(userData?.profile?.dob, "YYYY-MM-DD");
          setDate(userDob);

          const eighteenYearsAgo = moment().subtract(18, "years");
          const formattedEighteenYearsAgo =
            eighteenYearsAgo.format("YYYY-MM-DD");

          setDateValidation(moment(formattedEighteenYearsAgo, "YYYY-MM-DD"));
        } else {
          setDate(null);
        }
        setUserProfileData(userData?.profile);
        const filterE = {
          email: { eq: userData?.profile?.email },
        };
        const response = await ListEligibleMembers(filterE, 999);
        let dependentList = [];
        if (response) {
          if (response.length > 0) {
            let memID = response[0]?.memberIDSeq.slice(0, -1);
            const filter = {
              memberIDSeq: { contains: memID },
              memberSfx: { ne: 0 },
              or: {
                registered: { eq: false },
                invited: { eq: false },
              },
            };
            dependentList = await ListEligibleMembers(filter, 999);
            dependentList = dependentList.map((item) => ({
              Key: item?.id,
              Username: item?.email ? item?.email : "",
              FirstName: item?.memberFirstName,
              LastName: item?.memberLastName,
              Role: "DEPENDENT",
              Active: "INACTIVE",
              type: "HPI",
            }));
          }
        }

        const dependent = responce.data.getEmployeeRegi?.members?.items?.map(
          (row) => {
            const dependentRow = setDependentRow(row);
            dependentRow.rowClassName =
              dependentRow.key === "hardcodedKey" ? "hardcoded-row" : "";
            return dependentRow;
          }
        );

        const hardcodedEntry = {
          key: "hardcodedKey",
          Username: userData?.profile?.email,
          FirstName: userData?.profile?.firstName,
          LastName: userData?.profile?.lastName,
          Role:
            userData?.userType === "CORPORATE_ADMIN" &&
            userData?.group !== "Member"
              ? "ADMIN"
              : userData?.userType === "INDIVIDUAL" &&
                userData?.group !== "Member"
              ? "INDIVIDUAL"
              : userData?.userType === "CORPORATE" &&
                userData?.group !== "Member"
              ? "EMPLOYEE"
              : userData?.group === "Member"
              ? "DEPENDENT"
              : "-",
          Active: userData?.lastLogin ? "ACTIVE" : "INACTIVE",
          style: { background: "red" },
        };
        dependent.unshift(hardcodedEntry);
        const data = [...dependent, ...dependentList];

        setMemberData(data);
        dispatch(setUserData(responce.data.getEmployeeRegi));
      }
    } catch (error) {
      if (error?.message === "No current user") {
        await Auth.signOut();
        dispatch({
          type: LOGOUT,
        });
        localStorage.clear();
        navigate("/login");
      }
    } finally {
      setskeletonLoader(false);
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, [dispatch]);

  const initialValues = {
    streetAdress: userProfileData?.streetAdress,
    city: userProfileData?.city,
    state: userProfileData?.state,
    zip: userProfileData?.zip ? zeroPad(userProfileData?.zip, 5) : "",
    phone: userProfileData?.phone,
    name: userProfileData?.firstName + " " + userProfileData?.lastName,
    email: userProfileData?.email,
    InsuranceCarrier: userProfileData?.insuranceCarrier,
  };

  // const props = {
  //   customRequest({ file, onError, onSuccess }) {
  //     uploadFileToS3(file)
  //       .then(async (res) => {
  //         const link = await GetFileFromS3(res.key);
  //         setDp(link);
  //         setKey(res.key);
  //         onSuccess();
  //       })
  //       .catch(() => {
  //         onError();
  //       });
  //   },
  // };

  const props = {
    onChange({ file }) {
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        const response = await axios.put(linkToUpload, file);
        // setUploadImage(linkToUpload.split("?")[0]);
        setDp(linkToUpload.split("?")[0]);
        //         setKey(res.key);
        setKey(linkToUpload.split("?")[0]);
        onSuccess();
      } catch (error) {
        onError();
      }
    },
  };

  const MemberInvitePRO = async (e) => {
    // setLoading(true);
    setSendLoading(true);

    let url = `/employee/inviteDependentHPI`;
    // const memberInviteUrl =
    //   hostWithProtocol + `/signup/?id=${userNewId}&inviting=member`;
    try {
      const myInit = {
        body: {
          id: invitingID,
          email: e?.depEmail,
          employeeID: userData?.id,
        },
      };

      await API.post("bazarhealthREST", url, myInit);
      setSendLoading(false);
      fetchUserProfile();
      memberForm.resetFields();
      setIsModalOpen(false);
      setInviteSuccessModel(true);
      setMemberDependentModal(false);
      setInvitingID("");
      setInvitingName("");

      setTimeout(() => {
        setInviteSuccessModel(false);
      }, 3000);
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const MemberInvite = async (e) => {
    setLoading(true);
    let url = `/employee/invite`;
    const inviteUrl = hostWithProtocol + `/signup/?id=${userData?.id}`;
    try {
      const myInit = {
        body: {
          userId: userData?.id,
          emails: e?.emails,
          inviteUrl: inviteUrl,
          inviting: "Member", //this will become member when inviting member, doing so cause backend can identify easily and do the work in short amount of code.
        },
      };
      let result = await API.post("bazarhealthREST", url, myInit);
      setLoading(false);
      setInviteModel(false);
      setInviteSuccessModel(true);
      memberForm.resetFields();
      return result;
    } catch (e) {
      messageApi.open({
        type: "error",
        content: " Something went wrong",
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const requestChange = async (values) => {
    try {
      const emptyFields = Object.values(values).every(
        (value) => value === undefined || value === "" || value === null
      );

      if (emptyFields) {
        messageApi.open({
          type: "warning",
          content:
            "Please fill out at least one field before submitting the form.",
        });
      } else {
        setEditLoading(true);
        const response = await API.graphql({
          query: updateEmployeeProfile,
          variables: {
            input: {
              email: userProfileData.email,
              editStatus: "REQUESTED",
              editRequested: new Date().getTime(),
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const myInit = {
          body: {
            email: userData?.emailID,
            dob: requestDOB !== "" || requestDOB !== null ? requestDOB : "-",
            insuranceCarrier: values?.insuranceCarrier
              ? values?.insuranceCarrier
              : "-",
            memberID: values?.memberID ? values?.memberID : "-",
            planID: values?.PlanID ? values?.PlanID : "-",
          },
        };

        await API.post(
          "bazarhealthREST",
          `/employee/requestProfileChanges`,
          myInit
        );
        //here need to refresh to let the data be there
        form.resetFields();
        setEditModal(false);
        setEditSuccess(true);
        setTimeout(() => {
          setEditSuccess(false);
        }, 3000);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setEditLoading(false);
    }
  };

  const resendInvite = async (id) => {
    setLoadingButtons((prevLoadingButtons) => [...prevLoadingButtons, id]);
    const myInit = {
      body: {
        id: id,
      },
    };
    await API.post(
      "bazarhealthREST",
      `/superAdmin/resend_invite_members`,
      myInit
    );

    setInviteSuccessModel(true);
    setMemberDependentModal(false);

    setLoadingButtons((prevLoadingButtons) =>
      prevLoadingButtons.filter((btnKey) => btnKey !== id)
    );
    setInvitingID("");
    setInvitingName("");
    setTimeout(() => {
      setInviteSuccessModel(false);
    }, 3000);
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "Username",
      render: (id, record) => {
        return (
          <div
            className="table-font"
            style={{
              paddingLeft: record?.key !== "hardcodedKey" ? "15px" : "",
            }}
          >
            {record?.Username}
          </div>
        );
      },
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Role",
      dataIndex: "Role",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Login Status",
      dataIndex: "Active",
      // className: "actions",
      render: (id, status) => {
        return (
          <>
            <Space wrap>
              {id === "ACTIVE" ? (
                <span className="table-font">Active</span>
              ) : status.type === "HPI" ? (
                <>
                  <Button
                    className="blue-btn p-font"
                    onClick={() => {
                      setInvitingID(status.Key);
                      setInvitingName(status.FirstName + " " + status.LastName);
                      setInvitingEmail(status.Username);
                      setMemberDependentModal(true);
                      // setDeleteId([id]);
                    }}
                  >
                    Invite Now
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    className="blue-btn p-font"
                    loading={loadingButtons.includes(status.key)}
                    onClick={() => {
                      resendInvite(status.key);
                    }}
                  >
                    Resend Invite
                  </Button>
                </>
              )}
            </Space>
          </>
        );
      },
    },
  ];

  const setDependentRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      Username: row?.email,
      FirstName: row?.firstName,
      LastName: row?.lastName,
      Role:
        row?.userType === "CORPORATE_ADMIN" && row.group !== "Member"
          ? "ADMIN"
          : row?.userType === "INDIVIDUAL" && row.group !== "Member"
          ? "INDIVIDUAL"
          : row?.userType === "CORPORATE" && row.group !== "Member"
          ? "EMPLOYEE"
          : row.group === "Member"
          ? "DEPENDENT"
          : "-",
      Active: row?.lastLogin ? "ACTIVE" : "INACTIVE",
    };
  };

  return (
    <>
      {contextHolder}
      <Container>
        <Card className="mb-2 my-2 mobile-card responsive-card">
          <Row justify="center">
            <Col xl={17} md={24} xs={24}>
              <Tabs defaultActiveKey="1" className="faq_tabs userprofile-tab">
                <Tabs.TabPane
                  tab={<span className="sub-header-font">My Profile</span>}
                  key="1"
                >
                  <Row justify="center">
                    <Col xl={24} md={17} xs={24}>
                      <Col span={24} className="mt-2">
                        <div className="headline">
                          <div className="sub-header mobile-sub-header d-flex justify-content-between sub-head">
                            <div className="sub-header-nav">
                              <div className="sub-title-box d-flex">
                                <div className="sub-title">
                                  <Typography.Title
                                    level={3}
                                    className="sub-header-font"
                                  >
                                    Benefit Info Call
                                  </Typography.Title>
                                  <Typography.Text className="p-font">
                                    Get in touch with us for any queries!
                                  </Typography.Text>
                                </div>
                              </div>
                            </div>
                            <Button className="theme-btn">
                              <span className="p-font benefit-call">
                                <PhoneFilled className="phone-icon p-font" />{" "}
                                {/* If employee's employer has benefit info call, then updating that otherwise default */}
                                {userData?.companyID?.profile?.benefitInfoCall
                                  ? formatNumberUS(
                                      userData?.companyID?.profile
                                        ?.benefitInfoCall
                                    )
                                  : "(603) 537-6037"}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </Col>

                      {skeletonLoader ? (
                        <>
                          <Row
                            gutter={[20, 20]}
                            className="mt-3"
                            style={{ flexDirection: "column" }}
                          >
                            {Array.from({ length: 5 }).map((_, index) => (
                              <SkeletonBlock
                                key={index}
                                style={{
                                  width: "100%",
                                  height: 50,
                                  margin: "10px 0",
                                }}
                              />
                            ))}
                            <SkeletonBlock
                              style={{
                                width: "100%",
                                height: 400,
                                margin: "10px 0",
                              }}
                            />
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row
                            justify="center"
                            className="mt-20 upload-section"
                          >
                            <div className="relative user-ic">
                              <Avatar
                                className="user-avt"
                                size={120}
                                icon={
                                  dp === null &&
                                  userData?.profile?.profilePhoto === null ? (
                                    <UserOutlined />
                                  ) : (
                                    <UserImg />
                                  )
                                }
                              />

                              <Upload
                                {...props}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                className="avatar-uploader"
                              >
                                <Button
                                  shape="circle"
                                  size="middle"
                                  className="btn-primary-dual-color upload-btn"
                                >
                                  <CameraOutlined />
                                </Button>
                                {/* {uploadButton} */}
                              </Upload>
                            </div>
                          </Row>

                          {/* Hiding Personal Information if user type is not individual because other tha individual, all details will be set by DB, Only for individual, they will be able to add personal information via form */}
                          {userData?.userType !== "INDIVIDUAL" && (
                            <Row
                              justify="space-between"
                              className="table-header mt-30"
                              align="middle"
                            >
                              <Col xs={10}>
                                <Typography.Title
                                  level={4}
                                  className="header-font mb-0"
                                >
                                  Personal Information
                                </Typography.Title>
                              </Col>

                              <Col
                                xs={14}
                                style={{
                                  display: "flex",
                                  justifyContent: "flex-end",
                                }}
                              >
                                <Button
                                  className="blue-border-btn disable-request p-font"
                                  onClick={() => setEditModal(true)}
                                  disabled={
                                    userProfileData?.editStatus ===
                                      "REQUESTED" &&
                                    new Date().getTime() -
                                      userProfileData?.editRequested <
                                      86400000
                                      ? true
                                      : false
                                  }
                                >
                                  Request For Change
                                </Button>
                              </Col>

                              <Card className=" profile-card d-flex">
                                <Card.Grid
                                  style={gridStyle}
                                  className="no-border-bottom"
                                >
                                  <Row>
                                    <Col
                                      md={6}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      Name:
                                    </Col>
                                    <Col
                                      md={18}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      {userProfileData?.firstName}{" "}
                                      {userProfileData?.lastName}
                                    </Col>
                                  </Row>
                                </Card.Grid>

                                <Card.Grid
                                  style={gridStyle}
                                  className="no-border"
                                >
                                  <Row>
                                    <Col
                                      md={10}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      DOB:
                                    </Col>
                                    <Col
                                      md={14}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      {userProfileData?.dob
                                        ? userProfileData?.dob
                                        : "-"}
                                    </Col>
                                  </Row>
                                </Card.Grid>
                                <Card.Grid
                                  style={gridStyle}
                                  // className="no-border-bottom-top"
                                >
                                  <Row>
                                    <Col
                                      md={6}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      Email:
                                    </Col>
                                    <Col
                                      md={18}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      <span title={userProfileData?.email}>
                                        {userProfileData?.email}
                                      </span>
                                    </Col>
                                  </Row>
                                </Card.Grid>
                                <Card.Grid
                                  style={gridStyle}
                                  className="no-border-top"
                                >
                                  <Row>
                                    <Col
                                      md={10}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      Insurance carrier:
                                    </Col>
                                    <Col
                                      md={14}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      {userProfileData?.insuranceCarrier
                                        ? userProfileData?.insuranceCarrier
                                        : "-"}
                                    </Col>
                                  </Row>
                                </Card.Grid>
                                {/* Removing Member ID and Plan ID */}
                                {/* <Card.Grid style={gridStyle}> */}
                                {/*  <Row>
                                    <Col
                                      md={6}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      Plan id:{" "}
                                    </Col>
                                    <Col
                                      md={18}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      {userProfileData?.planID
                                        ? userProfileData?.planID
                                        : "-"}
                                    </Col>
                                  </Row> */}
                                {/* </Card.Grid> */}
                                {/* <Card.Grid
                                  style={gridStyle}
                                  className="no-border-top"
                                > */}
                                {/*   <Row>
                                    <Col
                                      md={10}
                                      xs={24}
                                      className="profile-bold sub-header-font"
                                    >
                                      Member id:
                                    </Col>
                                    <Col
                                      md={14}
                                      xs={24}
                                      className="sub-header-font"
                                    >
                                      {userProfileData?.memberID
                                        ? userProfileData?.memberID
                                        : "-"}
                                    </Col>
                                  </Row> */}
                                {/* </Card.Grid> */}
                              </Card>
                            </Row>
                          )}

                          <Card className="mt-20 contact-card">
                            <Form
                              name="basic"
                              initialValues={initialValues}
                              onFinish={onFinish}
                              onFinishFailed={onFinishFailed}
                              autoComplete="off"
                              layout="vertical"
                              // className=""
                            >
                              <div className="reward-input-box mt-0 request-appointment">
                                <Row justify="space-between table-header">
                                  <Row align="middle">
                                    <Typography.Title
                                      level={4}
                                      className="header-font"
                                    >
                                      Contact Information
                                    </Typography.Title>
                                  </Row>
                                </Row>
                                <Row gutter={20}>
                                  {userData?.userType === "INDIVIDUAL" && (
                                    <>
                                      <Col md={12} xs={24}>
                                        <Form.Item
                                          label={
                                            <span className="p-font">
                                              Insurance carrier
                                            </span>
                                          }
                                          name="InsuranceCarrier"
                                          rules={[
                                            {
                                              pattern: /^[a-zA-Z0-9]+$/,
                                              message:
                                                "Please enter a valid insurance carrier",
                                            },
                                          ]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>

                                      <Col md={12} xs={24}>
                                        <Form.Item
                                          label={
                                            <span className="p-font">
                                              Plan ID
                                            </span>
                                          }
                                          name="PlanID"
                                          rules={[
                                            {
                                              pattern: /^[a-zA-Z0-9]+$/,
                                              message:
                                                "Please enter a valid plan id ",
                                            },
                                          ]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>

                                      <Col md={12} xs={24}>
                                        <Form.Item
                                          label={
                                            <span className="p-font">
                                              Member ID
                                            </span>
                                          }
                                          name="memberID"
                                          rules={[
                                            {
                                              pattern: /^[a-zA-Z0-9]+$/,
                                              message:
                                                "Please enter a valid member id ",
                                            },
                                          ]}
                                        >
                                          <Input />
                                        </Form.Item>
                                      </Col>

                                      <Col md={12} xs={24}>
                                        <Form.Item
                                          label={
                                            <span className="p-font">DOB</span>
                                          }
                                          name="dob"
                                          initialValue={date}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Please input Your DOB!",
                                            },
                                            {
                                              validator: (_, value) => {
                                                if (date < dateValidation) {
                                                  return Promise.resolve();
                                                }
                                                return Promise.reject(
                                                  "Users under 18 are not permitted"
                                                );
                                              },
                                            },
                                          ]}
                                        >
                                          <DatePicker
                                            onChange={onChangeDatepicker}
                                            disabledDate={(d) =>
                                              !d || d.isAfter(new Date())
                                            }
                                            className="datepicker"
                                            allowClear={false}
                                          />
                                        </Form.Item>
                                      </Col>
                                    </>
                                  )}

                                  <Col md={24} xs={24}>
                                    <Form.Item
                                      label={
                                        <span className="p-font">
                                          Street Address
                                        </span>
                                      }
                                      name="streetAdress"
                                      rules={[
                                        {
                                          required: true,
                                          message:
                                            "Please enter street address",
                                        },
                                        {
                                          pattern: streetAddressPattern,
                                          message:
                                            "Please enter a valid street address",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={
                                        <span className="p-font">City</span>
                                      }
                                      name="city"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter city name",
                                        },
                                        {
                                          pattern: cityPattern,
                                          message:
                                            "Please enter a valid city name",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={
                                        <span className="p-font">State</span>
                                      }
                                      name="state"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter state name",
                                        },
                                        {
                                          pattern: cityPattern,
                                          message:
                                            "Please enter a valid state name",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={
                                        <span className="p-font">Zip</span>
                                      }
                                      name="zip"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter zip code",
                                        },
                                        {
                                          pattern: zipPattern,
                                          message:
                                            "Please enter a valid ZIP code",
                                        },
                                      ]}
                                    >
                                      <Input />
                                    </Form.Item>
                                  </Col>
                                  <Col md={12} xs={24}>
                                    <Form.Item
                                      label={
                                        <span className="p-font">Phone</span>
                                      }
                                      name="phone"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please enter phone number",
                                        },
                                        {
                                          pattern: tenDigit,
                                          message:
                                            "Please enter a valid phone number",
                                        },
                                      ]}
                                    >
                                      <InputNumber
                                        controls={false}
                                        formatter={(value) =>
                                          formatNumberUS(value)
                                        }
                                        parser={(value) => {
                                          const cleanedValue = value.replace(
                                            /\D/g,
                                            ""
                                          );
                                          return cleanedValue.slice(0, 10);
                                        }}
                                        style={{
                                          width: "100%",
                                          borderRadius: "5px",
                                        }}
                                        // maxLength={14}
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={24}>
                                    <Form.Item className="mb-0">
                                      {userProfileData?.profileCompleted ===
                                      true ? (
                                        <>
                                          <Button
                                            type="primary"
                                            className="mt-3 mt-0 button-font"
                                            htmlType="submit"
                                            loading={createLoading}
                                          >
                                            Update
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            type="primary"
                                            className="mt-3 mt-0 button-font"
                                            htmlType="submit"
                                            loading={createLoading}
                                          >
                                            Submit
                                          </Button>
                                        </>
                                      )}
                                    </Form.Item>
                                  </Col>
                                </Row>
                              </div>
                            </Form>
                          </Card>

                          {userData?.userType === "INDIVIDUAL" &&
                            userData?.group !== "Member" && (
                              <div className="dep-table">
                                <Table
                                  columns={columns}
                                  dataSource={memberData}
                                  className="common-table"
                                  scroll={{ x: screens.xs ? 992 : "" }}
                                  // pagination={{ position: ["", "bottomCenter"] }}
                                  pagination={false}
                                />
                              </div>
                            )}

                          {userData?.group === "Member" && (
                            <div className="dep-table">
                              <Table
                                columns={columns}
                                dataSource={dependentData}
                                className="common-table"
                                scroll={{ x: screens.xs ? 992 : "" }}
                                // pagination={{ position: ["", "bottomCenter"] }}
                                pagination={false}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </Col>
                  </Row>
                </Tabs.TabPane>
                {!isIndividual && !isUnderStripeEmployer ? (
                  <Tabs.TabPane
                    tab={<span className="sub-header-font">My Submission</span>}
                    key="2"
                  >
                    {billingStatus === "REQUIRED" ? (
                      <>
                        <div className="reward-header">
                          <Typography.Title level={5} className="header-font">
                            You are not eligible for submitting rewards
                          </Typography.Title>
                        </div>
                      </>
                    ) : (
                      <UserSubmissions />
                    )}
                  </Tabs.TabPane>
                ) : (
                  ""
                )}
                {userData?.userType === "CORPORATE_ADMIN" ||
                (userData?.userType === "CORPORATE" &&
                  userData?.group !== "Member") ? (
                  <>
                    <Tabs.TabPane
                      tab={
                        <span className="sub-header-font">
                          Employer Details
                        </span>
                      }
                      key="3"
                    >
                      {screens.xs ? (
                        <Row className="mt-20 mobile-employer">
                          <Col
                            md={6}
                            xs={24}
                            className="profile-bold sub-header-font"
                          >
                            Employer Name:
                          </Col>

                          <Col md={18} xs={24} className="sub-header-font">
                            <span title={userProfileData?.email}>
                              {userData?.companyID?.name}
                            </span>
                          </Col>

                          <Divider />

                          <Col
                            md={6}
                            xs={24}
                            className="profile-bold sub-header-font"
                          >
                            Employer Email:
                          </Col>
                          <Col md={18} xs={24} className="sub-header-font">
                            <span title={userData?.companyID?.email}>
                              {userData?.companyID?.email}
                            </span>
                          </Col>

                          {userData?.userType === "CORPORATE_ADMIN" && (
                            <>
                              <Divider />
                              <Col
                                md={6}
                                xs={24}
                                className="profile-bold sub-header-font"
                              >
                                Billing Mode:
                              </Col>
                              <Col md={18} xs={24} className="sub-header-font">
                                <span title={userProfileData?.email}>
                                  {userData?.billing_status === "NOT_REQUIRED"
                                    ? "Manual"
                                    : "Stripe"}
                                </span>
                              </Col>

                              <Divider />
                              <Col
                                md={6}
                                xs={24}
                                className="profile-bold sub-header-font"
                              >
                                Employer ID:
                              </Col>
                              <Col md={19} xs={24}>
                                <Row justify="space-between">
                                  {userData?.companyID?.id}
                                  <CopyToClipboard
                                    text={userData?.companyID?.id}
                                  >
                                    <img
                                      src={copy}
                                      alt=""
                                      onClick={onCopy}
                                      style={{ marginRight: "10px" }}
                                    />
                                  </CopyToClipboard>
                                </Row>
                              </Col>
                            </>
                          )}
                        </Row>
                      ) : (
                        <Row className="mt-20">
                          <Col md={5} xs={24}>
                            <div className="bordered-column sub-header-font top-left-border profile-bold">
                              Employer Name:
                            </div>
                          </Col>
                          <Col md={19} xs={24}>
                            <div className="bordered-column sub-header-font top-right-border profile-details">
                              {userData?.companyID?.name}
                            </div>
                          </Col>
                          <Col md={5} xs={24}>
                            <div
                              className={`bordered-column sub-header-font profile-bold ${
                                userData?.userType === "CORPORATE" &&
                                "bottom-left-border"
                              }`}
                            >
                              Employer Email:
                            </div>
                          </Col>
                          <Col md={19} xs={24}>
                            <div
                              className={`bordered-column sub-header-font profile-details ${
                                userData?.userType === "CORPORATE" &&
                                "bottom-right-border"
                              }`}
                            >
                              {userData?.companyID?.email}
                            </div>
                          </Col>

                          {userData?.userType === "CORPORATE_ADMIN" && (
                            <>
                              <Col md={5} xs={24}>
                                <div className="bordered-column sub-header-font profile-bold">
                                  Billing Mode:
                                </div>
                              </Col>
                              <Col md={19} xs={24}>
                                <div className="bordered-column sub-header-font profile-details">
                                  {userData?.billing_status === "NOT_REQUIRED"
                                    ? "Manual"
                                    : "Stripe"}
                                </div>
                              </Col>
                              <Col md={5} xs={24}>
                                <div className="bordered-column sub-header-font bottom-left-border profile-bold">
                                  Employer ID:
                                </div>
                              </Col>
                              <Col md={19} xs={24}>
                                <Row
                                  className="bordered-column sub-header-font bottom-right-border profile-details"
                                  justify="space-between"
                                >
                                  {userData?.companyID?.id}
                                  <CopyToClipboard
                                    text={userData?.companyID?.id}
                                  >
                                    <img
                                      src={copy}
                                      alt=""
                                      onClick={onCopy}
                                      style={{ marginRight: "10px" }}
                                    />
                                  </CopyToClipboard>
                                </Row>
                              </Col>
                            </>
                          )}
                        </Row>
                      )}

                      <div className="dep-table" style={{ overflowX: "auto" }}>
                        {screens.xs ? (
                          <div className=" mt-20">
                            {memberData.map((data, index) => (
                              <>
                                <Row
                                  justify="space-between r-mt-5 mobile-procedure"
                                  style={{
                                    background:
                                      index !== 0 ? "#F4F4F4" : "#F2F9FD",
                                    padding: "5px",
                                    marginLeft: index !== 0 ? "10px" : "0",
                                    width: "100%",
                                  }}
                                >
                                  <h1>{data?.Username}</h1>
                                </Row>

                                <Row
                                  gutter={[10, 10]}
                                  className={`vertical-line-row ${
                                    index !== 0 ? "" : "mt-10"
                                  }`}
                                  style={{
                                    background: index !== 0 ? "#F4F4F4" : "0",
                                    marginLeft: index !== 0 ? "10px" : "0",
                                    marginRight: index !== 0 ? "-10px" : "0",
                                  }}
                                >
                                  <Col xs={6} className="vertical-line-column">
                                    <div className="label avg-sav">
                                      First Name
                                    </div>
                                    <div className="amount avg-sav">
                                      {data?.FirstName}
                                    </div>
                                  </Col>
                                  <Col xs={6} className="vertical-line-column">
                                    <div className="label">Last Name</div>
                                    <div className="amount">
                                      {data?.LastName}
                                    </div>
                                  </Col>
                                  <Col xs={6} className="vertical-line-column">
                                    <div className="label">Role</div>
                                    <div className="amount">{data?.Role}</div>
                                  </Col>
                                  <Col xs={6} className="vertical-line-column">
                                    <div className="label">Login</div>
                                    <div
                                      className="amount"
                                      style={{
                                        color: "#229557",
                                        fontWeight: 700,
                                      }}
                                    >
                                      {data?.Active === "ACTIVE" ? (
                                        <span className="table-font">
                                          Active
                                        </span>
                                      ) : data?.type === "HPI" ? (
                                        <>
                                          <Button
                                            className="blue-btn p-font"
                                            onClick={() => {
                                              setInvitingID(data.Key);
                                              setInvitingName(
                                                data.FirstName +
                                                  " " +
                                                  data.LastName
                                              );
                                              setInvitingEmail(data.Username);
                                              setMemberDependentModal(true);
                                              // setDeleteId([id]);
                                            }}
                                          >
                                            Invite Now
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            className="blue-btn p-font"
                                            loading={loadingButtons.includes(
                                              data.key
                                            )}
                                            onClick={() => {
                                              resendInvite(data.key);
                                            }}
                                          >
                                            Resend Invite
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </Col>
                                </Row>
                              </>
                            ))}
                          </div>
                        ) : (
                          <Table
                            columns={columns}
                            dataSource={memberData}
                            className="common-table"
                            pagination={false}
                            scroll={{ x: screens.xs ? 992 : "" }}
                          />
                        )}
                      </div>
                    </Tabs.TabPane>
                  </>
                ) : (
                  ""
                )}
              </Tabs>
            </Col>
          </Row>
        </Card>
      </Container>

      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal"
      >
        <img src={successIcon} alt="Success Img" className="h-75" />
        <h6 className="header-font">Profile Updated Successfully</h6>
      </Modal>

      <Modal
        // title="Employee Form"
        open={hpiModel}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <Form.Item>
            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              form="hpiForm"
              key="submit"
              loading={loading}
            >
              Submit
            </Button>
            ,
          </Form.Item>,
        ]}
        className="suggest-modal"
      >
        <div className="request-appointment">
          <div className="text-center my-2">
            <h6 className="header-font">Employee Form</h6>
            <span className="fs-1 p-font">
              Please enter your details to get started.
            </span>
          </div>
        </div>
        <Form
          className="request-appointment"
          name="Employee Form"
          onFinish={createHpiEmp}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="hpiForm"
          form={form}
          layout="vertical"
        >
          <Form.Item
            label={<span className="p-font">First Name</span>}
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please Input Your First Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<span className="p-font">Last Name</span>}
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please Input Your Last Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<span className="p-font">DOB</span>}
            name="dob"
            rules={[
              {
                required: true,
                message: "Please Input Your DOB!",
              },
              {
                validator: (_, value) => {
                  if (date < dateValidation) {
                    return Promise.resolve(); // Validation passed
                  }
                  return Promise.reject("Users under 18 are not permitted");
                },
              },
            ]}
          >
            <DatePicker onChange={onChangeDatepicker} />
          </Form.Item>

          <Form.Item
            label={<span className="p-font">Member ID</span>}
            name="memeberID"
            rules={[
              {
                required: true,
                message: "Please Input Memeber Id!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={<span className="p-font">Zip Code</span>}
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please Input Zip Code!!",
              },
              {
                pattern: /^[\d]{0,5}$/,
                message: "Value should not be more than 5 character",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Symbol, number or whitespace are not allowed",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={<span className="header-font">Invite Dependent</span>}
        open={inviteModel}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={null}
        className="suggest-modal"
      >
        <Form
          name="employee"
          onFinish={MemberInvite}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="employeeInvite"
          layout="vertical"
          form={form}
        >
          <Form.Item
            label={<span className="p-font">Email</span>}
            name="emails"
            rules={[
              {
                validator: (rule, value) => {
                  if (!value || value.length === 0) {
                    return Promise.reject(
                      "Please enter at least one email address"
                    );
                  }
                  const emailRegex =
                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                  const invalidEmails = value.filter(
                    (email) => !emailRegex.test(email)
                  );
                  if (invalidEmails.length > 0) {
                    return Promise.reject(
                      `The following emails are invalid: ${invalidEmails.join(
                        ", "
                      )}`
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              className="invite-select"
              mode="tags"
              style={{ width: "100%" }}
              maxTagCount="responsive"
            ></Select>
          </Form.Item>
          <Row justify="space-between table-header">
            <Row
              align="middle"
              onClick={openUrlBox}
              className="invite-emp sub-header-font"
            >
              <img src={invite} className="h-20" /> &nbsp; Invite Via Link
            </Row>

            <Row className="gap-10 mtr-20">
              <Button
                className="blue-btn-invite button-font h-auto"
                htmlType="submit"
              >
                Send
              </Button>
            </Row>

            {showInput && (
              <>
                <Row className="gap-10 mt-30 my-2">
                  <span className="p-font">
                    Invite your employees by sending them the link below.
                  </span>{" "}
                  <Input.Group compact>
                    <Input
                      className="p-font"
                      defaultValue={`${hostWithProtocol}/signup/?id=${userData?.id}`}
                      style={{
                        width: "300px",
                      }}
                    />
                    <CopyToClipboard text={text}>
                      <Button
                        className="invite-btn-blue p-font h-100"
                        onClick={onCopy}
                      >
                        Copy Link
                      </Button>
                    </CopyToClipboard>
                  </Input.Group>
                </Row>
              </>
            )}
          </Row>
        </Form>
      </Modal>

      <Modal
        className="form-modal"
        centered
        footer={null}
        visible={editModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <div className="text-center my-2 mt-20 pt-20">
          <Typography.Title level={3} className="header-font mb-0">
            Request For Change
          </Typography.Title>
          <span className="fs-1 p-font" style={{ marginTop: -20 }}>
            Please enter the information which you like to update here.
          </span>
        </div>
        <Form
          name="basic"
          onFinish={requestChange}
          autoComplete="off"
          layout="vertical"
          form={form}
          className="mt-30"
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label={<span className="p-font">DOB</span>} name="dob">
                <DatePicker
                  onChange={onRequestDOB}
                  disabledDate={(d) => !d || d.isAfter(new Date())}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            {/* Removing Member ID and Plan ID as suggested by client */}
            {/* <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                className="w-100 email-form-item"
                label={<span className="p-font">Member ID</span>}
                name="memberID"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: "Please enter a valid member id ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label={<span className="p-font">Plan ID</span>}
                name="PlanID"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: "Please enter a valid plan id ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label={<span className="p-font">Insurance carrier</span>}
                name="insuranceCarrier"
                rules={[
                  {
                    pattern: /^[a-zA-Z0-9]+$/,
                    message: "Please enter a valid insurance carrier ",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn button-font"
                htmlType="submit"
                loading={editLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      {/* This is the modal to send invite to dependents from the relation table */}
      <Modal
        title={<span className="header-font">Invite Dependent</span>}
        open={memberDependentModal}
        // onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            className="theme-btn button-font"
            htmlType="submit"
            form="memberInvite"
            key="submit"
            loading={sendLoading}
          >
            Send
          </Button>,
        ]}
        className="suggest-modal"
      >
        <Form
          name="employee"
          onFinish={MemberInvitePRO}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="memberInvite"
          layout="vertical"
          form={memberForm}
        >
          <>
            <Form.Item
              label={<span className="p-font">Select Dependent Name</span>}
              name="depName"
              initialValue={invitingName}
              rules={[
                {
                  required: true,
                  message: "Please select any dependent!",
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              label={<span className="p-font">Enter Dependent Email</span>}
              name="depEmail"
              initialValue={invitingEmail}
              rules={[
                {
                  required: true,
                  message: "Please enter dependent email!",
                },
                {
                  pattern: emailPattern,
                  message: "Please enter a valid email address",
                },
              ]}
            >
              <Input
                disabled={invitingEmail && invitingEmail !== "" ? true : false}
              />
            </Form.Item>
          </>
        </Form>
      </Modal>

      <SuccessModel
        message="Invitation Sent Successfully!"
        open={inviteSuccessModel}
      />

      <SuccessModel
        message="Edit Request Sent Successfully!"
        open={editSuccess}
      />
    </>
  );
}
