// Create a reusable BillingCard component
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  Radio,
  Row,
  Space,
  Tag,
  Typography,
} from "antd";
import React from "react";
import checked from "assets/icons/checked.svg";
import unChecked from "assets/icons/unChecked.svg";
import warning from "assets/icons/warning.svg";
import { useState } from "react";

export default function BillingCard({
  title,
  monthlyValue,
  annualValue,
  selectedPlan,
  onChange,
  selectedPlanFromUrl,
  onCheckout,
  loading,
  isPopular = false,
}) {
  const { Text } = Typography;
  const [checkout, setCheckout] = useState(false);
  const isActiveCard =
    selectedPlan === monthlyValue || selectedPlan === annualValue;

  const currentPlan = selectedPlanFromUrl
    ? selectedPlanFromUrl
        .replace(/_/g, " ")
        .replace(/ ([^ ]+)$/, " - $1")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase())
    : "";

  const newPlan = selectedPlan
    ? selectedPlan
        .replace(/_/g, " ")
        .replace(/ ([^ ]+)$/, " - $1")
        .toLowerCase()
        .replace(/\b\w/g, (c) => c.toUpperCase())
    : "";

  return (
    <>
      <Col span={12} md={12} xs={24} sm={24}>
        <Card
          className={`doctor-profile bottom-padding ${
            isActiveCard ? "hover-pricing" : "card-border"
          } `}
        >
          {isPopular && (
            <Row justify="center" className="top-tag">
              <span> Most Popular</span>
            </Row>
          )}
          <Row
            justify="center"
            className="billing-cards"
            style={{ marginTop: isPopular ? "2px" : "" }}
          >
            <h4>{title}</h4>
            <Divider />
            <Col md={14} className="mt-10">
              <Radio
                value={monthlyValue}
                onChange={onChange}
                checked={selectedPlan === monthlyValue}
              >
                Monthly
              </Radio>
              <p className="mt-10">
                <Text className="strike-text" delete>
                  {monthlyValue === "FAMILY_MEMBERSHIP_MONTHLY"
                    ? "$22.00"
                    : "$10.00"}
                </Text>{" "}
                <Tag>
                  Save{" "}
                  {monthlyValue === "FAMILY_MEMBERSHIP_MONTHLY" ? "10%" : "10%"}
                </Tag>
              </p>
              <span
                className={`pricing ${
                  selectedPlan === monthlyValue ? "selected-plan" : ""
                }`}
              >
                {monthlyValue === "FAMILY_MEMBERSHIP_MONTHLY" ? "$20" : "$9"}
              </span>{" "}
              / month
            </Col>

            <Col md={10} className="mt-10">
              <Radio
                value={annualValue}
                onChange={onChange}
                checked={selectedPlan === annualValue}
              >
                Annually
              </Radio>
              <p className="mt-10">
                <Text className="strike-text" delete>
                  {annualValue === "FAMILY_MEMBERSHIP_ANNUALLY"
                    ? "$240.00"
                    : "$118.80"}
                </Text>{" "}
                <Tag>
                  Save{" "}
                  {annualValue === "FAMILY_MEMBERSHIP_ANNUALLY" ? "20%" : "20%"}
                </Tag>
              </p>
              <span
                className={`pricing ${
                  selectedPlan === annualValue ? "selected-plan" : ""
                }`}
              >
                {annualValue === "FAMILY_MEMBERSHIP_ANNUALLY" ? "$200" : "$99"}
              </span>
              / year
            </Col>
          </Row>

          <Row justify="center" className="billing-cards mt-10">
            <div className="w-100">
              <h5>Features</h5>
            </div>
            <Divider />

            <Col md={24} className="services mt-10">
              <p>
                <img src={checked} alt="" srcSet="" />
                Delphi platform
              </p>
              <p>
                <img src={checked} alt="" srcSet="" />
                Healthcare advocacy
              </p>
              <p>
                <img src={checked} alt="" srcSet="" />
                Appointment scheduling
              </p>
              <p>
                <img src={checked} alt="" srcSet="" />
                Member education
              </p>
              <p>
                <img src={checked} alt="" srcSet="" />
                Bill review
              </p>

              <p>
                <img
                  src={
                    monthlyValue === "FAMILY_MEMBERSHIP_MONTHLY" ||
                    annualValue === "FAMILY_MEMBERSHIP_ANNUALLY"
                      ? checked
                      : unChecked
                  }
                  alt=""
                  srcSet=""
                />
                Invite upto 3 dependents
              </p>
            </Col>

            <Col md={24} className="mt-10">
              <Button
                type="primary"
                className="theme-btn w-100 checkout-btn"
                disabled={
                  (selectedPlan !== monthlyValue &&
                    selectedPlan !== annualValue) ||
                  selectedPlanFromUrl === selectedPlan
                }
                onClick={
                  selectedPlanFromUrl ? () => setCheckout(true) : onCheckout
                }
                loading={selectedPlanFromUrl ? false : loading}
              >
                Checkout
              </Button>
            </Col>
          </Row>
        </Card>
      </Col>

      <Modal
        title="Basic Modal"
        open={checkout}
        onOk={() => setCheckout(false)}
        centered
        onCancel={() => setCheckout(false)}
        className="reward-success-modal"
        footer={null}
      >
        <img src={warning} alt="Delete Img" />
        <h6 className="mt-20"> Are you sure, you want to update your plan</h6>
        <p>
          If yes, your plan will change from{" "}
          <span className="plan-name">{currentPlan} </span>
          to <span className="plan-name">{newPlan}?</span>
        </p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={onCheckout}
            loading={loading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={() => setCheckout(false)}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>
    </>
  );
}
