import React from "react";
import { LeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

export default function BackButton() {
  return (
    <Button
      type="text"
      className="back-btn"
      onClick={() => window.history.back()}
      icon={<LeftOutlined />}
    >
      <span className="responsive-hide">Back</span>
    </Button>
  );
}
