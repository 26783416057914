import * as actions from "../actions/index";

const initialState = {
    labData: null,
  };

const labReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_LAB:
            return {
              ...state,
              labData:  action.payload,
            };

        default:
            return state;
    }
}
export default labReducer;
