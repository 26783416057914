import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Upload,
  message,
  Avatar,
  Radio,
  Tabs,
  Image,
  Space,
  InputNumber,
} from "antd";
import {
  LeftOutlined,
  UserOutlined,
  CameraOutlined,
  CloudUploadOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import deleteIcon from "assets/icons/deletee.svg";
import axios from "axios";
import { useLocation } from "react-router-dom";
import SkeletonBlock from "utility/SkeletonBlocks";
import downloadIcon from "assets/icons/downloadIc.svg";
import previewIcon from "assets/icons/previewIc.svg";
import {
  GetPublicFileFromS3,
  uploadPublicFileToS3,
} from "utility/uploadFileToS3";
import {
  aboutPattern,
  cityPattern,
  emailPattern,
  pain_management,
  phonePattern,
  ratingPattern,
  servicesNamePattern,
  streetAddressPattern,
  surgerySpecialty,
  tenDigit,
  urlPattern,
  zipPattern,
} from "utility/constant";
import {
  GetSurgeryCenterDetails,
  UpdateSurgeryCenterDetails,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";
import { API, Storage } from "aws-amplify";
import TestimonialFunctions from "utility/TestimonialFunctions";
import deleteImages from "utility/imageFunctions";
import ImageActions from "utility/imageFunctions";

export default function SurgeryCenterDetail() {
  const [surgeryData, setSurgeryData] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [imageObject, setImageObject] = useState([]);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [surgicalPhoto, setSurgicalPhoto] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [successModel, setSuccessModel] = useState(false);
  const { TextArea } = Input;
  const location = useLocation();
  const surgeryCenterID = location?.state.id;
  const [form] = Form.useForm();
  const [surgeryForm] = Form.useForm();

  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const [specialty, setSpecialty] = useState([
    { specialty: "", surgeryCenterSpeciality: [] },
  ]);

  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [preSignedImg, setPreSignedimg] = useState([]);
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [childOptions, setChildOptions] = useState([]);
  const [excludedOptions, setExcludedOptions] = useState([]);

  const [parentValue, setParentValue] = useState(undefined);
  const testJson = JSON.stringify(testimonialData);

  //Child Specialities
  const otolaryngologists = [
    { label: "Ear Nose and Throat", value: "Ear Nose and Throat" },
  ];
  const ophthalmologist = [{ label: "Eyes", value: "Eyes" }];
  const generalS = [{ label: "Internal", value: "Internal" }];
  const gastroenterologist = [{ label: "Stomach", value: "Stomach" }];

  const neuro_surgeon = [
    { label: "Head", value: "Head" },
    { label: "Spine and Back", value: "Spine and Back" },
  ];

  const orthopaedic = [
    { label: "Ankle and Foot", value: "Ankle and Foot" },
    { label: "Elbow", value: "Elbow" },
    { label: "Hip", value: "Hip" },
    { label: "Hand and Wrist", value: "Hand and Wrist" },
    { label: "Leg", value: "Leg" },
    { label: "Knee", value: "Knee" },
    { label: "Neck", value: "Neck" },
    { label: "Shoulder", value: "Shoulder" },
    { label: "Spine and Back", value: "Spine and Back" },
  ];

  const physiatrist = [
    { label: "Spine and Back", value: "Spine and Back" },
    { label: "Neck", value: "Neck" },
    { label: "Head", value: "Head" },
  ];

  const urology = [{ label: "Internal", value: "Internal" }];
  const podiatrist = [{ label: "Ankle and Foot", value: "Ankle and Foot" }];

  const dependentOptions = {
    "General Surgery": generalS,
    Gastroenterology: gastroenterologist,
    "Neuro Surgeon": neuro_surgeon,
    Opthalmology: ophthalmologist,
    Otolaryngology: otolaryngologists,
    Orthopaedics: orthopaedic,
    Physiatrist: physiatrist,
    Urology: urology,
    Podiatry: podiatrist,
    "Pain Management": pain_management,
  };

  const handleParentChange = (value, index) => {
    setParentValue(value);
    setChildOptions(dependentOptions[value]);
    setExcludedOptions([...excludedOptions, value]);
    setIsOtherSelected(true);
    surgeryForm.setFieldsValue({
      specialty: [],
    });
    // This is kind of working, but not sure on this
    surgeryForm.setFieldsValue({
      [`surgeryCenterSpeciality_${index}`]: [],
    });
  };

  const {
    handleAddTestimonial,
    handleUpdateTestimonial,
    handleDeleteTestimonial,
  } = TestimonialFunctions({ testimonialData, setTestimonialData });

  const handleAddSpecialty = () => {
    setSaveDisabled(false);
    if (parentValue) {
      setExcludedOptions([...excludedOptions, parentValue]);
    }

    setSpecialty([
      ...specialty,
      { specialty: [], surgeryCenterSpeciality: [] },
    ]);
    setParentValue("");
    setChildOptions(surgerySpecialty);
  };
  const remainingOptions = surgerySpecialty.filter((option) => {
    return !specialty.some((item1) => item1.specialty === option.value);
  });

  const handleupdateSpecialty = (index, newSpecialty, newBodyPart) => {
    const updateSpecialty = [...specialty];
    updateSpecialty[index] = {
      specialty: newSpecialty,
      surgeryCenterSpeciality: newBodyPart,
    };
    setSpecialty(updateSpecialty);
  };

  const handleDeleteSpecialty = (index) => {
    setSaveDisabled(false);
    const updateSpecialty = [...specialty];
    updateSpecialty.splice(index, 1);

    setSpecialty(updateSpecialty);
    for (let i in updateSpecialty) {
      surgeryForm.setFieldsValue({
        [`surgeryCenterSpeciality_${i}`]:
          typeof updateSpecialty[i].surgeryCenterSpeciality === "object"
            ? updateSpecialty[i].surgeryCenterSpeciality
            : updateSpecialty[i].surgeryCenterSpeciality
                .replace(/\[|\]/g, "")
                .replace(/,\s*/g, ",")
                .split(","),
      });
      surgeryForm.setFieldsValue({
        [`specialty_${i}`]: updateSpecialty[i].specialty,
      });
    }
    //Deleting the following index, so it does not appear while adding on the respective index
    // surgeryForm.setFieldsValue({
    //   [`surgeryCenterSpeciality_${index}`]: [],
    // });
    // surgeryForm.setFieldsValue({
    //   [`specialty_${index}`]: [],
    // });
    // Clear form values for the deleted specialty
    surgeryForm.setFieldsValue({
      [`surgeryCenterSpeciality_${updateSpecialty.length}`]: [],
    });
    surgeryForm.setFieldsValue({
      [`specialty_${updateSpecialty.length}`]: [],
    });
  };

  const [saveDisabled, setSaveDisabled] = useState(true);
  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const fetchSurgeryCenter = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetSurgeryCenterDetails(surgeryCenterID);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);
      setSpecialty(
        response?.specailtyAndBodyPart ? response?.specailtyAndBodyPart : []
      );
      surgeryForm.setFieldsValue({
        surgeryCenterName: response?.surgeryCenterName,
        surgeryCenterWebsite: response?.surgeryCenterWebsite,
        aboutSurgeryCenter: response?.aboutSurgeryCenter,
        surgeryCenterPhone: response?.surgeryCenterPhone,
        surgeryCenterEmail: response?.surgeryCenterEmail,
        surgeryCenterAddLine1: response?.surgeryCenterAddLine1,
        surgeryCenterAddLine2: response?.surgeryCenterAddLine2,
        surgeryCenterAddLine3: response?.surgeryCenterAddLine3,
        surgeryCenterZip: response?.surgeryCenterZip,
        phone: response?.phone,
        surgeryMapSource: response?.surgeryMapSource,
        surgeryCenterFaceBookRating: response?.surgeryCenterFaceBookRating,
        surgeryCenterGoogleRating: response?.surgeryCenterGoogleRating,
        specialty: response?.specialty,
        activeOnPortal: response?.activeOnPortal,
      });
      if (response?.testimonial && response?.testimonial.length > 0) {
        response.testimonial.forEach((item, index) => {
          surgeryForm.setFieldsValue({
            [`testimonialName_${index}`]: item?.testimonialName,
            [`testimonialRating_${index}`]: item?.testimonialRating,
            [`review_${index}`]: item?.review,
          });
        });
      } else {
        surgeryForm.setFieldsValue({
          testimonialName_0: "",
          testimonialRating_0: "",
          review_0: "",
        });
      }
      if (
        response?.specailtyAndBodyPart &&
        response?.specailtyAndBodyPart.length > 0
      ) {
        response.specailtyAndBodyPart.forEach((item, index) => {
          surgeryForm.setFieldsValue({
            [`specialty_${index}`]: item?.specialty,
            [`surgeryCenterSpeciality_${index}`]: item?.surgeryCenterSpeciality
              .slice(1, -1) // Remove the brackets [ ]
              .split(",") // Split the string by comma
              .map((part) => part.trim()), // Trim whitespace from each part,
          });
        });
      } else {
        surgeryForm.setFieldsValue({
          specialty_0: [],
          surgeryCenterSpeciality_0: [],
        });
      }

      if (response?.surgeryCenterProfilePhoto) {
        let surgeryphoto;
        if (response?.surgeryCenterProfilePhoto.startsWith("http")) {
          surgeryphoto = response?.surgeryCenterProfilePhoto;
        } else {
          surgeryphoto = await GetPublicFileFromS3(
            response?.surgeryCenterProfilePhoto
          );
        }
        setSurgicalPhoto(surgeryphoto);
      }
      if (response?.surgeryPhotos) {
        const fileKeyy = response?.surgeryPhotos;
        setImageObject(fileKeyy);
        const urls = await ImageActions.fetchPreSignedURLs(fileKeyy, Storage);
        setPreSignedimg(urls);
      }
      setSurgeryData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [surgeryCenterID]);

  useEffect(() => {
    fetchSurgeryCenter();
  }, [fetchSurgeryCenter]);

  function UserImg() {
    return (
      <img
        src={uploadImage === null ? surgicalPhoto : uploadImage}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const updateSurgeryCenter = async (values) => {
    try {
      setLoading(true);
      const testJson = JSON.stringify(testimonialData);
      const specialtyJson = JSON.stringify(specialty);
      const specialtyData = JSON.parse(specialtyJson);

      const inputData = {
        id: surgeryData?.id,
        surgeryCenterName: values?.surgeryCenterName,
        searchByName: (values?.surgeryCenterName).toLowerCase(),
        surgeryCenterZip: values?.surgeryCenterZip,
        surgeryCenterPhone: values?.surgeryCenterPhone,
        surgeryCenterEmail: values?.surgeryCenterEmail,
        surgeryCenterWebsite: values?.surgeryCenterWebsite,
        byName: "surgeryCenter",
        surgeryCenterAddLine1: values?.surgeryCenterAddLine1,
        surgeryCenterAddLine2: values?.surgeryCenterAddLine2,
        surgeryCenterAddLine3: values?.surgeryCenterAddLine3,
        // surgeryCenterSpeciality: specialtyJson,
        aboutSurgeryCenter: values?.aboutSurgeryCenter,
        surgeryMapSource: values?.surgeryMapSource,
        surgeryCenterGoogleRating: values?.surgeryCenterGoogleRating,
        surgeryCenterFaceBookRating: values?.surgeryCenterFaceBookRating,
        surgeryCenterProfilePhoto: key === "" ? undefined : key,
        activeOnPortal: values?.activeOnPortal,
        testimonial: testJson,
        specailtyAndBodyPart: specialtyData,
      };

      await UpdateSurgeryCenterDetails(inputData);
      fetchSurgeryCenter();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateSurgeryCenterPhotos = async (values) => {
    try {
      setLoading(true);
      let existingImages = surgeryData?.surgeryPhotos || [];
      const inputData = {
        id: surgeryData?.id,
        surgeryPhotos: [...existingImages, ...imageObject],
      };
      await UpdateSurgeryCenterDetails(inputData);
      fetchSurgeryCenter();
      setSuccessModel(true);
      form.resetFields();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    testimonial:
      testimonialData && testimonialData.length > 0
        ? testimonialData.map((item, index) => ({
            [`testimonialName_${index}`]: item?.testimonialName,
            [`testimonialRating_${index}`]: item?.testimonialRating,
            [`review_${index}`]: item?.review,
          }))
        : [{ testimonialName_0: "", testimonialRating_0: "", review_0: "" }],

    specialty:
      specialty && specialty.length > 0
        ? specialty.map((item, index) => ({
            [`specialty_${index}`]: item?.specialty,
            [`surgeryCenterSpeciality_${index}`]: item?.surgeryCenterSpeciality,
          }))
        : [{ specialty_0: "", surgeryCenterSpeciality_0: [] }],
  };

  const props = {
    onChange({ file }) {
      setSaveDisabled(false);
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        await axios.put(linkToUpload, file);
        setUploadImage(linkToUpload.split("?")[0]);
        setKey(linkToUpload.split("?")[0]);
        onSuccess();
      } catch (error) {
        console.log(error);
      }
    },
  };

  const IcImagesProps = {
    customRequest({ file, onError, onSuccess }) {
      uploadPublicFileToS3(file)
        .then(async (res) => {
          setImageObject((item) => [...item, res.key]);
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    },
  };

  const onPreview = async (file) => {
    const previewImage = ImageActions.imagePreviewer(file);
    await previewImage();
  };

  const onDelete = async (index) => {
    const deleteImage = ImageActions.deleteImages(
      imageObject,
      preSignedImg,
      index,
      surgeryData,
      setImageObject,
      setPreSignedimg,
      messageApi,
      UpdateSurgeryCenterDetails,
      Storage
    );
    await deleteImage();
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <Button
          type="text"
          className="back-btn"
          onClick={() => window.history.back()}
          icon={<LeftOutlined />}
        >
          Back
        </Button>
        <Typography.Title level={4} className="text-center mb-0">
          {surgeryData?.surgeryCenterName}
        </Typography.Title>
        <Button style={{ marginLeft: 110 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row className="service-detail-tab">
        <Tabs defaultActiveKey="1" tabPosition="left" className="w-100">
          <Tabs.TabPane tab="Details" key="1">
            <Row justify="start">
              <Col span={20} md={20} xs={24} sm={24}>
                {skeletonLoader ? (
                  <>
                    <Row gutter={[20, 20]} className="mt-3">
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 200 }} />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row
                      justify="center"
                      className="mt-20 upload-services-photo"
                    >
                      <div className="relative">
                        <Avatar
                          size={120}
                          icon={
                            surgicalPhoto === null && uploadImage === null ? (
                              <UserOutlined />
                            ) : (
                              <UserImg />
                            )
                          }
                        />
                        <Upload
                          {...props}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          className="avatar-uploader"
                        >
                          <Button
                            shape="circle"
                            size="middle"
                            className="btn-primary-dual-color upload-btn"
                          >
                            <CameraOutlined />
                          </Button>
                        </Upload>
                      </div>
                    </Row>
                    <Row className="update-form">
                      <Form
                        form={surgeryForm}
                        // initialValues={initialValues}
                        onFinish={updateSurgeryCenter}
                        autoComplete="off"
                        layout="vertical"
                        className="mt-30"
                        onValuesChange={handleValuesChange}
                      >
                        <Row gutter={20}>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Surgical center name"
                              name="surgeryCenterName"
                              rules={[
                                {
                                  pattern: servicesNamePattern,
                                  message: "Please enter a valid name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Surgical center website"
                              name="surgeryCenterWebsite"
                              rules={[
                                {
                                  pattern: urlPattern,
                                  message: "Please input valid url",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Surgical center email"
                              name="surgeryCenterEmail"
                              rules={[
                                {
                                  pattern: emailPattern,
                                  message: "Please enter a valid email",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Surgical center phone"
                              name="surgeryCenterPhone"
                              rules={[
                                {
                                  pattern: tenDigit,
                                  message: "Please enter a valid phone number",
                                },
                              ]}
                            >
                              <InputNumber
                                controls={false}
                                formatter={(value) => {
                                  if (!value) return "";
                                  // Remove all non-digit characters from the input value
                                  const cleanedValue = value.replace(/\D/g, "");

                                  // Format the phone number using regex or any desired logic
                                  let formattedNumber = "";
                                  if (cleanedValue.length > 0) {
                                    formattedNumber += `(${cleanedValue.slice(
                                      0,
                                      3
                                    )}`;
                                  }
                                  if (cleanedValue.length > 3) {
                                    formattedNumber += `) ${cleanedValue.slice(
                                      3,
                                      6
                                    )}`;
                                  }
                                  if (cleanedValue.length > 6) {
                                    formattedNumber += `-${cleanedValue.slice(
                                      6,
                                      10
                                    )}`;
                                  }
                                  return formattedNumber;
                                }}
                                parser={(value) => {
                                  const cleanedValue = value.replace(/\D/g, "");
                                  return cleanedValue.slice(0, 10);
                                }}
                                style={{ width: "100%", borderRadius: "5px" }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            <Form.Item
                              label="About"
                              name="aboutSurgeryCenter"
                              rules={[
                                {
                                  pattern: aboutPattern,
                                  message: "Please enter a valid about",
                                },
                              ]}
                            >
                              <TextArea rows={4} />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            {initialValues?.specialty?.map((item, index) => (
                              <div key={index}>
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    <Form.Item
                                      label={`Specialty ${index + 1}`}
                                      name={`specialty_${index}`}
                                      initialValue={item[`specialty_${index}`]}
                                      rules={[
                                        {
                                          required: true,
                                          message: "Please select any option",
                                        },
                                      ]}
                                    >
                                      <Select
                                        options={remainingOptions}
                                        placeholder="Select specialty"
                                        maxTagCount="responsive"
                                        onChange={(value) => {
                                          handleParentChange(value, index);
                                          handleupdateSpecialty(
                                            index,
                                            value,
                                            item[
                                              `surgeryCenterSpeciality_${index}`
                                            ]
                                          );
                                        }}
                                        value={parentValue}
                                        getPopupContainer={(trigger) =>
                                          trigger.parentNode
                                        }
                                      ></Select>
                                    </Form.Item>
                                  </Col>
                                  <>
                                    {specialty[index]?.specialty !==
                                      "Sports Medicine" && (
                                      <Col span={12}>
                                        <Form.Item
                                          label={`Focus (on body) ${index + 1}`}
                                          name={`surgeryCenterSpeciality_${index}`}
                                          initialValue={
                                            item[
                                              `surgeryCenterSpeciality_${index}`
                                            ]
                                          }
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "Please select any option",
                                            },
                                          ]}
                                        >
                                          <Select
                                            onChange={(e) =>
                                              handleupdateSpecialty(
                                                index,
                                                item[`specialty_${index}`],
                                                e
                                              )
                                            }
                                            mode="multiple"
                                            allowClear
                                            placeholder="Select body part"
                                            maxTagCount="responsive"
                                            options={
                                              dependentOptions[
                                                item[`specialty_${index}`]
                                              ]
                                            }
                                            getPopupContainer={(trigger) =>
                                              trigger.parentNode
                                            }
                                          ></Select>
                                        </Form.Item>
                                      </Col>
                                    )}
                                  </>
                                </Row>

                                {initialValues?.specialty?.length > 1 && (
                                  <Row justify="space-between table-header">
                                    <Row align="middle">
                                      <Typography.Title
                                        level={5}
                                      ></Typography.Title>
                                    </Row>
                                    <Row align="middle">
                                      <Typography.Text
                                        className="hover-thumb"
                                        level={3}
                                        onClick={() =>
                                          handleDeleteSpecialty(index)
                                        }
                                        style={{ color: "red" }}
                                      >
                                        <u>Delete</u>
                                      </Typography.Text>
                                    </Row>
                                  </Row>
                                )}
                              </div>
                            ))}
                            {specialty.length < surgerySpecialty.length ? (
                              <Typography.Text
                                level={3}
                                onClick={handleAddSpecialty}
                                className="hover-thumb add-more-reviews"
                              >
                                <u>+ Add more specialty</u>
                              </Typography.Text>
                            ) : (
                              ""
                            )}
                          </Col>

                          <Col
                            span={12}
                            md={12}
                            xs={24}
                            sm={24}
                            className="mt-20"
                          >
                            <Form.Item
                              label="Street address"
                              name="surgeryCenterAddLine1"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter street address",
                                },
                                {
                                  pattern: streetAddressPattern,
                                  message:
                                    "Please enter a valid street address",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col
                            span={12}
                            md={12}
                            xs={24}
                            sm={24}
                            className="mt-20"
                          >
                            <Form.Item
                              label="City"
                              name="surgeryCenterAddLine2"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter city name",
                                },
                                {
                                  pattern: cityPattern,
                                  message: "Please enter a valid city name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="State"
                              name="surgeryCenterAddLine3"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter state name",
                                },
                                {
                                  pattern: cityPattern,
                                  message: "Please enter a valid state name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Zip code"
                              name="surgeryCenterZip"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter zip code",
                                },
                                {
                                  pattern: zipPattern,
                                  message: "Please enter a valid ZIP code",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Google rating"
                              name="surgeryCenterGoogleRating"
                              rules={[
                                {
                                  pattern: ratingPattern,
                                  message:
                                    "Please enter a valid rating between 0 and 10",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Facebook rating"
                              name="surgeryCenterFaceBookRating"
                              rules={[
                                {
                                  pattern: ratingPattern,
                                  message:
                                    "Please enter a valid rating between 0 and 10",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            <Form.Item
                              label="Google map link"
                              name="surgeryMapSource"
                              rules={[
                                {
                                  // pattern: urlPattern,
                                  message: "Please input valid url",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            {initialValues?.testimonial?.map((item, index) => (
                              <div key={index}>
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    <Form.Item
                                      label={`Testimonial name ${index + 1}`}
                                      name={`testimonialName_${index}`}
                                      initialValue={
                                        item[`testimonialName_${index}`]
                                      }
                                      rules={[
                                        {
                                          pattern: servicesNamePattern,
                                          message: "Please enter a valid name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) =>
                                          handleUpdateTestimonial(
                                            index,
                                            e.target.value,
                                            item[`testimonialRating_${index}`],
                                            item[`review_${index}`]
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label={`Testimonial rating ${index + 1}`}
                                      name={`testimonialRating_${index}`}
                                      initialValue={
                                        item[`testimonialRating_${index}`]
                                      }
                                      rules={[
                                        {
                                          pattern: ratingPattern,
                                          message:
                                            "Please enter a valid rating between 0 and 10",
                                        },
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) =>
                                          handleUpdateTestimonial(
                                            index,
                                            item[`testimonialName_${index}`],
                                            e.target.value,
                                            item[`review_${index}`]
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  label={`Review ${index + 1}`}
                                  name={`review_${index}`}
                                  initialValue={item[`review_${index}`]}
                                  rules={[
                                    {
                                      pattern: aboutPattern,
                                      message: "Please enter a valid review",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    onChange={(e) =>
                                      handleUpdateTestimonial(
                                        index,
                                        item[`testimonialName_${index}`],
                                        item[`testimonialRating_${index}`],
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>

                                {initialValues?.testimonial.length > 1 && (
                                  <Row justify="space-between table-header">
                                    <Row align="middle">
                                      <Typography.Title
                                        level={5}
                                      ></Typography.Title>
                                    </Row>
                                    <Row align="middle">
                                      <Typography.Text
                                        className="hover-thumb"
                                        level={3}
                                        onClick={() =>
                                          handleDeleteTestimonial(index)
                                        }
                                        style={{ color: "red" }}
                                      >
                                        <u>Delete</u>
                                      </Typography.Text>
                                    </Row>
                                  </Row>
                                )}
                              </div>
                            ))}

                            <Typography.Text
                              level={3}
                              onClick={handleAddTestimonial}
                              className="hover-thumb add-more-reviews"
                            >
                              <u>+ Add more testimonials</u>
                            </Typography.Text>
                          </Col>

                          <Col
                            span={12}
                            md={12}
                            xs={24}
                            sm={24}
                            className="mt-20"
                          >
                            <Form.Item
                              label="Account status"
                              name="activeOnPortal"
                            >
                              <Radio.Group>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>
                          <Col
                            span={24}
                            md={24}
                            xs={24}
                            sm={24}
                            className="center-btn"
                          >
                            <Button
                              size="large"
                              className="theme-btn mt-20"
                              htmlType="submit"
                              loading={loading}
                              disabled={saveDisabled}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Images" key="2">
            <Form
              onFinish={updateSurgeryCenterPhotos}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Row>
                <Col span={20} md={24} xs={24} sm={24}>
                  <Row gutter={[20, 20]}>
                    {preSignedImg.map((image, index) => (
                      <Col span={8} md={8} xs={24} sm={24}>
                        <div key={index} className="service-image-preview">
                          <Image
                            src={image}
                            className="service-image-preview-img"
                            onClick={() => setPreviewIndex(index)}
                            preview={{
                              visible:
                                isPreviewVisible && previewIndex === index,
                              onVisibleChange: (visible, prevVisible) => {
                                if (!visible) setPreviewIndex(null);
                                setPreviewVisible(visible);
                              },
                            }}
                          />
                          <div className="hide-icon">
                            <Space>
                              <img
                                src={previewIcon}
                                alt="preview-icon"
                                onClick={() => {
                                  setPreviewIndex(index);
                                  setPreviewVisible(true);
                                }}
                              />
                              <a href={image} download>
                                <img src={downloadIcon} alt="download-icon" />
                              </a>
                              <a onClick={() => onDelete(index)}>
                                <img src={deleteIcon} alt="download-icon" />
                              </a>
                            </Space>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>

                  <Form.Item name="files">
                    <Upload
                      listType="picture-card"
                      onPreview={onPreview}
                      className="basic-img-upload"
                      {...IcImagesProps}
                      beforeUpload={beforeUpload}
                    >
                      <CloudUploadOutlined /> Click here to upload image
                    </Upload>
                  </Form.Item>
                </Col>

                <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                  <Button
                    size="large"
                    className="theme-btn mt-20"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Row>

      <SuccessModel
        message="Surgery Center Updated Successfully"
        open={successModel}
      />
    </>
  );
}
