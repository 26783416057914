import { Row, Card, Tabs, Collapse, Col } from "antd";
import {} from "@ant-design/icons";
import "./Faq.less";

export default function Faq() {
  const { Panel } = Collapse;
  const items = [
    {
      key: "1",
      label: <span className="button-font">General Information</span>,
      children: (
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel
            header={<span className="sub-header-font">What is Delphi?</span>}
            key="1"
          >
            <p className="p-font">
              Delphi is a state-of-the-art cost and quality transparency
              application, combined with a first class advocacy team. It has
              built an extensive network of the highest quality providers and
              facilities in your area who offers exceptional care at an
              exceptional price. Delphi’s software provides members with the
              ability to shop and compare high quality medical providers,
              facilities, services and more all while avoiding high-cost
              alternatives.
            </p>
          </Panel>
          <Panel
            header={
              <span className="sub-header-font">How do I use Delphi?</span>
            }
            key="2"
          >
            <p className="p-font">
              Signup takes less than a minute, our employee advocate resource
              team will be your EAR for all benefit-related questions. Once you
              activate your account, a temporary password will be sent to your
              email. Simply complete the registration process and create your
              personal portal by logging in with your email and password. Use
              the platform to shop for your care, or contact your EAR for any
              assistance either through the platform or by phone.
            </p>
          </Panel>
          <Panel
            header={
              <span className="sub-header-font">Why should I use Delphi?</span>
            }
            key="3"
          >
            <p className="p-font">
              Navigating the complex world of healthcare is challenging, so
              we’re here to help. Access high- quality, top-rated physicians and
              facilities while earning cash rewards. You can simplify healthcare
              access because our advocate will help coordinate anything you
              need. Select preferred low-cost, highly-rated medical
              professionals when you schedule your procedure using Delphi and you
              can earn up to $1500 cash rewards. Health is Wealth!
            </p>
          </Panel>
          <Panel
            header={
              <span className="sub-header-font">
                Why does my employer use Delphi?
              </span>
            }
            key="4"
          >
            <p className="p-font">
              Every day, businesses and employers are over paying tens of
              thousand of dollars to healthcare providers and facilities. Delphi
              helps the employer save their health plan thousands of dollars and
              help reduce your overall healthcare costs, all while still
              improving the quality and access to top doctors and facilities.
              Our advocates are on their team, providing guidance and answers
              for all items related to employees benefits every step of the way.
            </p>
          </Panel>
        </Collapse>
      ),
    },
  
    {
      key: "2",
      label: <span className="button-font">Find My Care</span>,
      children: (
        <Collapse bordered={false} defaultActiveKey={["1"]}>
          <Panel
            header={
              <span className="sub-header-font">
                What type of care can I find through Delphi?
              </span>
            }
            key="1"
          >
            <p className="p-font">
              Lab work, physical therapy, imaging such as MRIs /CTs and
              infusions and more. Most importantly, Delphi helps you identify
              specialists closest to you, keeping cost and quality in mind.
            </p>
          </Panel>
          <Panel
            header={
              <span className="sub-header-font">
                What if I go to get care somewhere else?
              </span>
            }
            key="2"
          >
            <p className="p-font">
              You always have a choice! By not choosing a Delphi provider, you
              will not be eligible for a reward. When you use our platform, not
              only will you receive high-quality care but both you and your
              employer save money, which is a win-win for all.
            </p>
          </Panel>
        </Collapse>
      ),
    },
  ];

  return (
    <Card className="mb-20 mobile-card">
      <Row justify="center">
        <Col span={14} xl={14} md={20} xs={24}>
          <Tabs defaultActiveKey="1" className="faq_tabs" items={items}></Tabs>
        </Col>
      </Row>
    </Card>
  );
}
