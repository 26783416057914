import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyA5oiFl3UTd4rfnZVz8Fc0PcjyO-1kStmU",
  authDomain: "delphi-1a926.firebaseapp.com",
  projectId: "delphi-1a926",
  storageBucket: "delphi-1a926.appspot.com",
  messagingSenderId: "1045322632878",
  appId: "1:1045322632878:web:80242693c764125484e73f",
  measurementId: "G-56Z9XV5RGM",
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

// export const requestPermission = () => {
//   console.log("Requesting permission...");
//   Notification.requestPermission().then((permission) => {
//     console.log("permission", permission);
//     if (permission === "granted") {
//       console.log("Notification permission granted.");

//       return getToken(messaging, {
//         vapidKey:
//           "BH19whN8TFhz_osVPl0UVe-lorCHG-nxztakNIZpgf1NvT9Qej4y2QiFCC3oRiLL_qEIzYXphYEXBJK6EXO043w",
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             console.log("FCM Token: ", currentToken);
//             return currentToken; // Return the FCM token
//           } else {
//             console.log(
//               "No registration token available. Request permission to generate one."
//             );
//             return null; // Return null when no token is available
//           }
//         })
//         .catch((err) => {
//           console.log("An error occurred while retrieving token. ", err);
//           return null; // Return null when no token is available
//         });
//     }
//   });
// };

// export const requestPermission = () => {
//   console.log("Requesting permission...");
//   return Notification.requestPermission().then((permission) => {
//     if (permission === "granted") {

//       return getToken(messaging, {
//         vapidKey:
//           "BH19whN8TFhz_osVPl0UVe-lorCHG-nxztakNIZpgf1NvT9Qej4y2QiFCC3oRiLL_qEIzYXphYEXBJK6EXO043w",
//       })
//         .then((currentToken) => {
//           if (currentToken) {
//             return currentToken; // Return the FCM token
//           } else {
//             console.log(
//               "No registration token available. Request permission to generate one."
//             );
//             return null; // Return null when no token is available
//           }
//         })
//         .catch((err) => {
//           console.log("An error occurred while retrieving token. ", err);
//           return null; // Return null in case of an error
//         });
//     } else {
//       console.log("Notification permission denied.");
//       return null; // Return null when permission is not granted
//     }
//   });
// };

// export const requestPermission = () => {
//   console.log("Requesting permission...");

//   // For Safari-specific push notifications
//   if ("safari" in window && "pushNotification" in window.safari) {
//     return new Promise((resolve, reject) => {
//       const permissionData = window.safari.pushNotification.permission(
//         "your-website-push-id"
//       );

//       if (permissionData.permission === "default") {
//         window.safari.pushNotification.requestPermission(
//           `${process.env.REACT_APP_PUSH_ID}`, // Your website push ID
//           // "https://fcm.googleapis.com/fcm/send", // FCM endpoint URL
//           {},
//           (permission) => {
//             if (permission.permission === "granted") {
//               resolve(permission);
//             } else {
//               reject(permission);
//             }
//           }
//         );
//       } else {
//         resolve(permissionData);
//       }
//     });
//   }

//   if ("Notification" in window) {
//     return Notification.requestPermission().then((permission) => {
//       if (permission === "granted") {
//         // Handle token retrieval or subscribe to topics here
//         return getToken(messaging, {
//           vapidKey:
//             "BH19whN8TFhz_osVPl0UVe-lorCHG-nxztakNIZpgf1NvT9Qej4y2QiFCC3oRiLL_qEIzYXphYEXBJK6EXO043w",
//         })
//           .then((currentToken) => {
//             if (currentToken) {
//               return currentToken; // Return the FCM token
//             } else {
//               console.log(
//                 "No registration token available. Request permission to generate one."
//               );
//               return null; // Return null when no token is available
//             }
//           })
//           .catch((err) => {
//             console.log("An error occurred while retrieving token. ", err);
//             return null; // Return null in case of an error
//           });
//       } else {
//         console.log("Notification permission denied.");
//         return null; // Return null when permission is not granted
//       }
//     });
//   }

//   // Handle the case where push notifications are not supported
//   console.error("Push notifications not supported.");
//   return Promise.reject("Push notifications not supported.");
// };

export const requestPermission = () => {

  if ("safari" in window && "pushNotification" in window.safari) {
    return new Promise((resolve, reject) => {
      const permissionData = window.safari.pushNotification.permission(
        process.env.REACT_APP_PUSH_ID
      );

      if (permissionData.permission === "default") {
        console.log("permissionData.permission De");
        window.safari.pushNotification.requestPermission(
          process.env.REACT_APP_PUSH_ID,
           "https://fcm.googleapis.com/fcm/send",
          {},
          (permission) => {
            if (permission.permission === "granted") {
              resolve(permission);
            } else {
              reject(permission);
            }
          }
        );
      } else {
        resolve(permissionData);
      }
    });
  }

  if ("Notification" in window) {
    return Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, {
          vapidKey:
            "BH19whN8TFhz_osVPl0UVe-lorCHG-nxztakNIZpgf1NvT9Qej4y2QiFCC3oRiLL_qEIzYXphYEXBJK6EXO043w",
        })
          .then((currentToken) => {
            if (currentToken) {
              return currentToken; // Return the FCM token
            } else {
              console.log(
                "No registration token available. Request permission to generate one."
              );
              return null; // Return null when no token is available
            }
          })
          .catch((err) => {
            console.log("An error occurred while retrieving token. ", err);
            return null; // Return null in case of an error
          });
      } else {
        return null; // Return null when permission is not granted
      }
    });
  }

  // Handle the case where push notifications are not supported
  console.error("Push notifications not supported.");
  return Promise.reject("Push notifications not supported.");
};

//used for fetching notification when app is foreground
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
