import * as actions from "../actions/index";

const initialState = {
    pagination: null,
  };

const labReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_PAGE:
            return {
              ...state,
              pagination:  action.payload,
            };

        default:
            return state;
    }
}
export default labReducer;
