import { Card, Col, Layout, PageHeader, Row } from "antd";
import { Content, Header } from "antd/lib/layout/layout";
import logo from "assets/delphy/delphy.svg";
import Container from "commons/Container";
import successIcon from "assets/icons/Success.svg";
import { Link } from "react-router-dom";

export const StripePaymentSuccess = () => {
  return (
    <>
      <Layout className="layout navbar">
        <section className="header">
          <Container>
            <Header className="main-haeder header-menu">
              <Link to="https://delphihc.com/">
                <img src={logo} alt="Bazar Logo" className="main-logo" />
              </Link>
            </Header>
          </Container>
        </section>
        {/* <Layout>
        <PageHeader style={{ backgroundColor: "#CEE1ED" }}>
          <Row className="onboarding-page" justify="center" align="middle">
            <Col span={24} className="responsive-mt-20">
              <img src={logo} alt="Bazar Logo" className="bazar-logo" />
            </Col>
          </Row>
        </PageHeader> */}
        <Container>
          <Card
            className="my-2"
            style={{
              height: "80vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Row justify="center" gutter={20}>
              <div className="no-search">
                <div className="no-search-box">
                  <img src={successIcon} alt="" style={{ width: "200px" }} />
                  <h6>Your payment was successful</h6>
                  <h4>Welcome to Delphi Enhanced Primary Care! 🥳</h4>
                </div>
              </div>
            </Row>
          </Card>
        </Container>
      </Layout>
    </>
  );
};
