import {
  Row,
  Input,
  Button,
  Table,
  Popover,
  Form,
  Col,
  DatePicker,
  Select,
  message,
  Space,
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";

import exportCSV from "assets/icons/exportcsv.svg";
import { DownOutlined, SearchOutlined, EyeOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import { typeOfCare } from "utility/constant";
import { ListRewardSubmissions } from "utility/action";
import search from "utility/search";
import TableSkeleton from "utility/TableSkeleton";

export default function CloseEob() {
  const [eob, setEOB] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [dataSource, setDataSource] = useState(eob);
  const [searchloading, setSearchLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const { Option } = Select;

  const setCloseEobRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      Action: row?.id,
      Name: row?.name,
      DateSubmitted: row?.dateSubmitted
        ? moment(row?.dateSubmitted).format("YYYY-MM-DD")
        : "-",
      TypeOfCare: row?.typeOfCare,
      Procedure: row?.nameOfProcedure,
      Status: row?.status,
      ApprovedBy: row?.approvedBy ? row?.approvedBy : "-",
      Reward: row?.rewardAmount
        ? `$${parseInt(row?.rewardAmount).toLocaleString()}`
        : "-",
    };
  };

  const fetchReward = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        or: [{ status: { eq: "DENIED" } }, { status: { eq: "APPROVED" } }],
      };
      const response = await ListRewardSubmissions(filter);
      const data = response.map((row) => setCloseEobRow(row));
      data.sort(
        (a, b) => new Date(b.DateSubmitted) - new Date(a.DateSubmitted)
      );
      setEOB(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchReward();
  }, [fetchReward]);

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Name",
      "ApprovedBy",
      "DateSubmitted",
      "Procedure",
      "TypeOfCare",
      "Reward",
      "Status",
    ];
    const filteredData = search(eob, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleAdvanceSearch = async (value) => {
    setSearchLoading(true);
    let filter = {
      or: [{ status: { eq: "DENIED" } }, { status: { eq: "APPROVED" } }],
    };
    if (value.Name) filter = { ...filter, name: { contains: value.Name } };
    if (value.DateSubmitted)
      filter = {
        ...filter,
        dateSubmitted: {
          contains: moment(value.DateSubmitted).format("YYYY-MM-DD"),
        },
      };
    if (value.toc) filter = { ...filter, typeOfCare: { contains: value.toc } };
    if (value.status === "APPROVED") {
      filter = { ...filter, status: { eq: "APPROVED" } };
    } else if (value.status === "DENIED") {
      filter = { ...filter, status: { eq: "DENIED" } };
    }

    let variables = {};
    if (Object.keys(filter).length !== 0) variables = { and: [filter] };

    await ListRewardSubmissions(variables)
      .then((response) => {
        const eobData = response.map((row) => setCloseEobRow(row));
        eobData.sort(
          (a, b) => new Date(b.DateSubmitted) - new Date(a.DateSubmitted)
        );
        setDataSource(eobData);
      })
      .catch((error) => {
        console.log(error);
        setSearchLoading(false);
      });
    setSearchLoading(false);
  };

  const headers = [
    { label: "Reward For", key: "rewardFor" },
    { label: "Name", key: "name" },
    { label: "DOB", key: "dob" },
    { label: "Type of care", key: "typeOfCare" },
    { label: "Name Of Procedure", key: "nameOfProcedure" },
    { label: "Claim Number", key: "claimNumber" },
    { label: "Status", key: "status" },
    { label: "Documents", key: "uploadedImages" },
  ];

  const data = csvData.map((row) => ({
    rewardFor: row?.rewardFor,
    name: row?.name,
    dob: row?.dob,
    typeOfCare: row?.typeOfCare,
    nameOfProcedure: row?.nameOfProcedure,
    claimNumber: row?.claimNumber,
    status: row?.status,
    uploadedImages: row?.uploadedImages,
  }));

  const columns = [
    {
      title: "Name",
      dataIndex: "Name",
    },
    {
      title: "Date Submitted",
      dataIndex: "DateSubmitted",
    },
    {
      title: "Procedure",
      dataIndex: "Procedure",
    },
    {
      title: "Type Of Care",
      dataIndex: "TypeOfCare",
    },
    {
      title: "Reward",
      dataIndex: "Reward",
      render: (reward) => {
        return <span style={{ color: "#17A163" }}>{reward}</span>;
      },
    },
    {
      title: "Approved by",
      dataIndex: "ApprovedBy",
    },
    {
      title: "Status",
      dataIndex: "Status",
      render: (status) => {
        return (
          <>
            <Space wrap>
              {status === "APPROVED" ? (
                <>
                  <ul className="status" style={{ color: "green" }}>
                    <li>
                      <span style={{ color: "#00406A" }}>APPROVED </span>{" "}
                    </li>
                  </ul>
                </>
              ) : status === "DENIED" ? (
                <>
                  <ul className="status" style={{ color: "red" }}>
                    <li>
                      <span style={{ color: "#00406A" }}>DENIED </span>{" "}
                    </li>
                  </ul>
                </>
              ) : (
                <ul className="status" style={{ color: "grey" }}>
                  <li>
                    <span style={{ color: "#00406A" }}>PENDING </span>{" "}
                  </li>
                </ul>
              )}
            </Space>
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Link to="/close-eob-detail" state={{ id: id }}>
            <EyeOutlined />
          </Link>
        );
      },
    },
  ];

  const uniqueName = Array.from(new Set(eob.map((item) => item.Name)));

  const content = (
    <div className="update-form popover-form">
      <Form layout="vertical" onFinish={handleAdvanceSearch}>
        <Row justify="center" align="middle" gutter={[20, 30]}>
          <Col>
            <Form.Item label="Name" name="Name">
              <Select
                allowClear
                placeholder="Select name"
                style={{ width: 150 }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {uniqueName.map((item, index) => (
                  <Select.Option
                    key={index}
                    value={item}
                  >{`${item}`}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Status" name="status">
              <Select
                allowClear
                placeholder="Select status"
                style={{ width: 120 }}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="APPROVED">APPROVED</Option>
                <Option value="DENIED">DENIED</Option>
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label="Date submitted" name="DateSubmitted">
              <DatePicker
                style={{ width: "100%" }}
                disabledDate={(d) => !d || d.isAfter(new Date())}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label="Type of care" name="toc">
              <Select
                allowClear
                placeholder="Select type of care"
                options={typeOfCare}
                style={{ width: 150 }}
                getPopupContainer={(trigger) => trigger.parentNode}
              ></Select>
            </Form.Item>
          </Col>

          <Col>
            <Row
              justify="start"
              align="middle"
              style={{ height: "100%", paddingTop: 5 }}
            >
              <Button className="blue-btn" htmlType="submit">
                Search
              </Button>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );

  return (
    <>
      {contextHolder}
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20">
          <Popover
            trigger="click"
            content={content}
            title=""
            placement="topRight"
            color="#F2F9FD"
          >
            <Button className="blue-border-btn inherit-btn">
              Advance search
              <DownOutlined />
            </Button>
          </Popover>

          <CSVLink
            data={data}
            headers={headers}
            filename={"Open EOB.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>
        </Row>
      </Row>

      {loading || searchloading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}
    </>
  );
}
