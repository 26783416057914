import React from "react";
import { Bar } from "@ant-design/plots";
import { Button, Card, Row, Table } from "antd";
import comingsoon from "assets/icons/coming.png";
export default function EmployerChart() {
  const data = [
    {
      label: "The Plus Company, Inc.",
      type: "Registered",
      value: 100,
    },
    {
      label: "The Plus Company, Inc.",
      type: "Not Registered",
      value: 260,
    },
    {
      label: "Orchard Medical Management",
      type: "Registered",
      value: 600,
    },
    {
      label: "Orchard Medical Management",
      type: "Not Registered",
      value: 300,
    },
    {
      label: "Mid-State Health Center",
      type: "Registered",
      value: 350,
    },
    {
      label: "Mid-State Health Center",
      type: "Not Registered",
      value: 500,
    },
    {
      label: "Gateways Community Services",
      type: "Registered",
      value: 500,
    },
    {
      label: "Gateways Community Services",
      type: "Not Registered",
      value: 390,
    },
  ];

  const config = {
    data,
    isGroup: true,
    xField: "value",
    yField: "label",
    color: ["#00406A", "#00406A"],
    seriesField: "type",
    marginRatio: 0,
    dodgePadding: 4,
    label: {
      position: "right",
      style: {
        fill: "#161616",
        fontWeight: "600",
      },
    },
  };

  const columns = [
    {
      title: "Employer number",
      dataIndex: "EmployerNumber",
    },
    {
      title: "Employer name",
      dataIndex: "EmployerName",
    },
    {
      title: "Registered",
      dataIndex: "Registered",
    },
    {
      title: "Not Registered",
      dataIndex: "NotRegistered",
    },
    {
      title: "Total",
      dataIndex: "Total",
    },
  ];

  const TableData = [
    {
      key: "1",
      EmployerNumber: "10",
      EmployerName: "Gateways Community Services",
      Registered: "1",
      NotRegistered: "188",
      Total: "189",
    },
    {
      key: "2",
      EmployerNumber: "10",
      EmployerName: "Gateways Community Services",
      Registered: "1",
      NotRegistered: "188",
      Total: "189",
    },
    {
      key: "3",
      EmployerNumber: "10",
      EmployerName: "Gateways Community Services",
      Registered: "1",
      NotRegistered: "188",
      Total: "189",
    },
    {
      key: "4",
      EmployerNumber: "10",
      EmployerName: "Gateways Community Services",
      Registered: "1",
      NotRegistered: "188",
      Total: "189",
    },
    {
      key: "5",
      EmployerNumber: "10",
      EmployerName: "Gateways Community Services",
      Registered: "1",
      NotRegistered: "188",
      Total: "189",
    },
  ];
  return (
    <>
      {/* <Row className="chart-box">
        <Bar {...config} style={{ width: "100%" }} />
      </Row>

      <Row justify="end" className="mt-20">
        <Button className="blue-border-btn">Download Data</Button>
      </Row>
      <Row>
        <Table
          columns={columns}
          dataSource={TableData}
          className="common-table w-100"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      </Row> */}
          <div className="no-search">
            <div className="no-search-box">
              <img src={comingsoon} alt="" width={500} />
            </div>
          </div>
    </>
  );
}
