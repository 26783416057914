import * as actions from "../actions/index";

const initialState = {
    specialityOption: null,
  };

const specialityReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_SPECIALITY:
            return {
              ...state,
              specialityOption: action.payload,
            };

        default:
            return state;
    }
}
export default specialityReducer;
