import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function ImagingTour(props) {
  const imagingSteps = [
    {
      target: ".img-first-step",
      content: (
        <TourStepContent
          heading="Imaging Center"
          details="Click to choose your desired Imaging Center and proceed to the
          next step for assistance."
          numbers={{ currentStep: 1, totalSteps: 9 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-second-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click on the type of Imaging you need"
          numbers={{ currentStep: 2, totalSteps: 9 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-third-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click Continue"
          numbers={{ currentStep: 3, totalSteps: 9 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={imagingSteps}
        run={props?.imgTour}
        stepIndex={props?.imgTourIndex}
        setTourIndex={props?.setImgTourIndex}
      />
    </>
  );
}
