import { Button, Card, Col, DatePicker, Form, Input, Modal, Row } from "antd";
import { API } from "aws-amplify";
import Container from "commons/Container";
import { getEmployeeRegi } from "graphql/queries";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserData } from "store/actions/userData";
import onBoard from "../../../../assets/img/approval.svg";
export default function Approval() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hpiModel, sethpiModel] = useState(false);
  const [date, setDate] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const userData = useSelector((state) => state.userReducer?.userData);
  // const inList = JSON.parse(userData?.inList);

  const createHpiEmp = async (values) => {
    setLoading(true);
    const myInit = {
      body: {
        id: userData?.id,
        firstName: values?.firstName,
        lastName: values?.lastName,
        memeberID: values?.memeberID,
        zipCode: values?.zipCode,
        dob: date,
      },
    };
    const res = await API.post("bazarhealthREST", `/hpi/verify`, myInit);
    setLoading(false);
  };

  const handleOk = () => {
    sethpiModel(false);
  };

  const handleCancel = () => {
    sethpiModel(false);
  };

  // for date picker
  const onChangeDatepicker = (date, dateString) => {
    setDate(dateString);
  };

  // useEffect(() => {
  //   if (inList?.checked === false) {
  //     sethpiModel(true);
  //   }
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const response = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: userData?.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (response?.data?.getEmployeeRegi?.verified === true) {
        dispatch(setUserData(response?.data?.getEmployeeRegi));
        navigate("/user-profile");
      }
    };
    fetchData();
  });

  return (
    <Container>
      <Card className="my-2">
        <Row justify="center" gutter={15}>
          <div className="no-search-approval">
            <div className="no-search-box">
              <img src={onBoard} alt="" />
              <h6>Pending approval for request!</h6>
              <span>
                Your request to join team has been sent to your employer/admin.{" "}
                <br />
                Once it is approved, you will receive an email to login to your
                account.
              </span>
            </div>
          </div>
        </Row>
      </Card>

      <Modal
        // title="Employee Form"
        open={hpiModel}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={[
          <Form.Item>
            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              form="hpiForm"
              key="submit"
              loading={loading}
            >
              Submit
            </Button>
            ,
          </Form.Item>,
        ]}
        className="suggest-modal"
      >
        <div className="request-appointment">
          <div className="text-center my-2">
            <h6>Employee Form</h6>
            <span className="fs-1">
              Please enter your details to get started.
            </span>
          </div>
        </div>
        <Form
          className="request-appointment"
          name="Employee Form"
          onFinish={createHpiEmp}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="hpiForm"
          form={form}
          layout="vertical"
        >
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: true,
                message: "Please Input Your First Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              {
                required: true,
                message: "Please Input Your Last Name!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="DOB"
            name="dob"
            rules={[
              {
                required: true,
                message: "Please Input Your DOB!",
              },
            ]}
          >
            <DatePicker onChange={onChangeDatepicker} />
          </Form.Item>

          <Form.Item
            label="Member ID*"
            name="memeberID"
            rules={[
              {
                required: true,
                message: "Please Input Memeber Id!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Zip Code*"
            name="zipCode"
            rules={[
              {
                required: true,
                message: "Please Input Zip Code!!",
              },
              {
                pattern: /^[\d]{0,5}$/,
                message: "Value should not be more than 5 character",
              },
              {
                pattern: /^[0-9]+$/,
                message: "Symbol, number or whitespace are not allowed",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
}
