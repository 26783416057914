import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Radio,
  Tabs,
  Upload,
  Avatar,
  message,
  Image,
  Space,
  InputNumber,
} from "antd";
import {
  UserOutlined,
  CameraOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useCallback, useEffect, useState } from "react";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import {
  aboutPattern,
  cityPattern,
  emailPattern,
  formatNumberUS,
  imagingCenterOptions,
  phonePattern,
  ratingPattern,
  servicesNamePattern,
  streetAddressPattern,
  tenDigit,
  urlPattern,
  zipPattern,
} from "utility/constant";
import axios from "axios";
import downloadIcon from "assets/icons/downloadIc.svg";
import deleteIcon from "assets/icons/deletee.svg";
import previewIcon from "assets/icons/previewIc.svg";
import SkeletonBlock from "utility/SkeletonBlocks";
import {
  GetPublicFileFromS3,
  uploadPublicFileToS3,
} from "utility/uploadFileToS3";
import {
  GetImgagingCenterDetails,
  UpdateImgagingCenterDetails,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";
import { API, Storage } from "aws-amplify";
import TestimonialFunctions from "utility/TestimonialFunctions";

export default function ImagingCenterDetail() {
  const [icData, setIcData] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("");
  const [successModel, setSuccessModel] = useState(false);
  const [icPhoto, seticPhoto] = useState(null);
  const [uploadImage, setUploadImage] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const { TextArea } = Input;
  const location = useLocation();
  const icId = location?.state.id;
  const [form] = Form.useForm();

  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [preSignedImg, setPreSignedimg] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const handleValuesChange = () => {
    setSaveDisabled(false);
  };

  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const {
    handleAddTestimonial,
    handleUpdateTestimonial,
    handleDeleteTestimonial,
  } = TestimonialFunctions({ testimonialData, setTestimonialData });

  const fetchIcCenter = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetImgagingCenterDetails(icId);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);
      if (response?.icProfilePhoto) {
        let icProfile;
        if (response?.icProfilePhoto.startsWith("http")) {
          icProfile = response?.icProfilePhoto;
        } else {
          icProfile = await GetPublicFileFromS3(response?.icProfilePhoto);
        }
        seticPhoto(icProfile);
      }
      if (response?.icPhotos) {
        const fileKeyy = response?.icPhotos;
        const urls = [];
        for (const key of fileKeyy) {
          try {
            const url = await Storage.get(key, {
              level: "public",
              expires: 86400,
            });
            urls.push(url);
          } catch (error) {
            console.log(`Error getting pre-signed URL for ${key}: ${error}`);
          }
        }
        setPreSignedimg(urls);
      }
      setIcData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setskeletonLoader(false);
    }
  }, [icId]);

  useEffect(() => {
    fetchIcCenter();
  }, [fetchIcCenter]);

  const updateIcCenter = async (values) => {
    try {
      setLoading(true);
      const testJson = JSON.stringify(testimonialData);
      const inputData = {
        id: icData?.id,
        icPractiseName: values?.icPractiseName,
        icEmail: values?.icEmail,
        activeOnPortal: values?.activeOnPortal,
        icMapSource: values?.icMapSource,
        icAddLine2: values?.icAddLine2,
        icAppointmentLink: values?.icAppointmentLink,
        icID: values?.icID,
        icSpecialities: values?.icSpecialities,
        aboutIC: values?.aboutIC,
        icOfficeAdd: values?.icOfficeAdd,
        icOfficeCity: values?.icOfficeCity,
        icOfficeState: values?.icOfficeState,
        icOfficeZip: values?.icOfficeZip,
        icOfficePhone: values?.icOfficePhone,
        icOfficeFax: values?.icOfficeFax,
        icWebsite: values?.icWebsite,
        icFacility: values?.icFacility,
        icOfficeName: values?.icOfficeName,
        icGoogleRating: values?.icGoogleRating,
        icFaceBookRating: values?.icFaceBookRating,
        icProfilePhoto: key === "" ? undefined : key,
        testimonial: testJson,
      };
      await UpdateImgagingCenterDetails(inputData);
      fetchIcCenter();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Updating Imaging Center Details",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const updateIcCenterImages = async (values) => {
    try {
      let existingImages = icData?.icPhotos || [];
      setLoading(true);
      const inputData = {
        id: icData?.id,
        icPhotos: [...existingImages, ...imageObject],
      };
      await UpdateImgagingCenterDetails(inputData);
      fetchIcCenter();
      setSuccessModel(true);
      form.resetFields();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Updating Imaging Center Details",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    icPractiseName: icData?.icPractiseName,
    icEmail: icData?.icEmail,
    icMapSource: icData?.icMapSource,
    icAddLine2: icData?.icAddLine2,
    icAppointmentLink: icData?.icAppointmentLink,
    icID: icData?.icID,
    activeOnPortal: icData?.activeOnPortal,
    icSpecialities:
      icData?.icSpecialities === null ? [] : icData?.icSpecialities,
    aboutIC: icData?.aboutIC,
    icOfficeAdd: icData?.icOfficeAdd,
    icOfficeCity: icData?.icOfficeCity,
    icOfficeState: icData?.icOfficeState,
    icOfficeZip: icData?.icOfficeZip,
    icOfficePhone: icData?.icOfficePhone,
    icOfficeFax: icData?.icOfficeFax,
    icWebsite: icData?.icWebsite,
    icFacility: icData?.icFacility,
    icOfficeName: icData?.icOfficeName,
    icGoogleRating: icData?.icGoogleRating,
    icFaceBookRating: icData?.icFaceBookRating,
    testimonial:
      testimonialData && testimonialData.length > 0
        ? testimonialData.map((item, index) => ({
            [`testimonialName_${index}`]: item?.testimonialName,
            [`testimonialRating_${index}`]: item?.testimonialRating,
            [`review_${index}`]: item?.review,
          }))
        : [{ testimonialName_0: "", testimonialRating_0: "", review_0: "" }],
  };

  function UserImg() {
    return (
      <img
        src={uploadImage === null ? icPhoto : uploadImage}
        className="user-img"
        alt="Profile"
        style={{ margin: "none" }}
      />
    );
  }

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const props = {
    onChange({ file }) {
      setSaveDisabled(false);
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading Image`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `Image Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const linkToUpload = await API.get(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`
        );

        const response = await axios.put(linkToUpload, file);
        // uploadPublicFileToS3(file)
        //   .then(async (res) => {
        //     const link = await GetPublicFileFromS3(res.key);
        setUploadImage(linkToUpload.split("?")[0]);
        setKey(linkToUpload.split("?")[0]);
        onSuccess();
      } catch (error) {
        onError();
      }
    },
  };

  const IcImagesProps = {
    customRequest({ file, onError, onSuccess }) {
      uploadPublicFileToS3(file)
        .then(async (res) => {
          setImageObject((item) => [...item, res.key]);
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    },
  };

  const onDelete = async (index) => {
    const newImageObject = [...imageObject];
    const deletedKey = newImageObject.splice(index, 1)[0];
    try {
      await Storage.remove(deletedKey, { level: "public" });
      setImageObject(newImageObject);
      const updatedImages = preSignedImg.filter((img, i) => i !== index);
      setPreSignedimg(updatedImages);

      messageApi.success({
        content: "Image Deleted Successfully",
        duration: 5,
      });
    } catch (error) {
      messageApi.error({
        content: "Error Deleting Image",
      });
    }
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {icData?.icOfficeName}
        </Typography.Title>
        <Button style={{ marginLeft: 110 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row className="service-detail-tab">
        <Tabs defaultActiveKey="1" tabPosition="left" className="w-100">
          <Tabs.TabPane tab="Details" key="1">
            <Row justify="start">
              <Col span={20} md={20} xs={24} sm={24}>
                {skeletonLoader ? (
                  <>
                    <Row gutter={[20, 20]} className="mt-3">
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 50 }} />
                      <SkeletonBlock style={{ width: 700, height: 200 }} />
                    </Row>
                  </>
                ) : (
                  <>
                    <Row
                      justify="center"
                      className="mt-20 upload-services-photo"
                    >
                      <div className="relative">
                        <Avatar
                          size={120}
                          icon={
                            icPhoto === null && uploadImage === null ? (
                              <UserOutlined />
                            ) : (
                              <UserImg />
                            )
                          }
                        />
                        <Upload
                          {...props}
                          showUploadList={false}
                          beforeUpload={beforeUpload}
                          className="avatar-uploader"
                        >
                          <Button
                            shape="circle"
                            size="middle"
                            className="btn-primary-dual-color upload-btn"
                          >
                            <CameraOutlined />
                          </Button>
                        </Upload>
                      </div>
                    </Row>
                    <Row className="update-form">
                      <Form
                        name="basic"
                        initialValues={initialValues}
                        onFinish={updateIcCenter}
                        autoComplete="off"
                        layout="vertical"
                        className="mt-30"
                        onValuesChange={handleValuesChange}
                      >
                        <Row gutter={20}>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Name"
                              name="icOfficeName"
                              rules={[
                                {
                                  pattern: servicesNamePattern,
                                  message: "Please enter a valid name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Website"
                              name="icWebsite"
                              rules={[
                                {
                                  pattern: urlPattern,
                                  message: "Please input valid url",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Phone"
                              name="icOfficePhone"
                              rules={[
                                {
                                  pattern: tenDigit,
                                  message: "Please enter a valid phone number",
                                },
                              ]}
                            >
                              <InputNumber
                                controls={false}
                                formatter={(value) => {
                                  return formatNumberUS(value);
                                }}
                                parser={(value) => {
                                  console.log("1");
                                  const cleanedValue = value.replace(/\D/g, "");
                                  return cleanedValue.slice(0, 10);
                                }}
                                onChange={(e) => {
                                  console.log("value", e);
                                }}
                                style={{ width: "100%", borderRadius: "5px" }}
                              />
                            </Form.Item>
                          </Col>
                          {/* adding field for fax */}
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Fax"
                              name="icOfficeFax"
                              rules={[
                                {
                                  pattern: tenDigit,
                                  message: "Please enter a valid fax number",
                                },
                              ]}
                            >
                              <InputNumber
                                controls={false}
                                formatter={(value) => {
                                  return formatNumberUS(value);
                                }}
                                parser={(value) => {
                                  const cleanedValue = value.replace(/\D/g, "");
                                  return cleanedValue.slice(0, 10);
                                }}
                                style={{ width: "100%", borderRadius: "5px" }}
                              />
                            </Form.Item>
                          </Col>

                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Email"
                              name="icEmail"
                              rules={[
                                {
                                  pattern: emailPattern,
                                  message: "Please enter a valid email",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col
                            span={12}
                            md={12}
                            xs={24}
                            sm={24}
                            // className="ic-specialities" Rempving this class will let the specialty in one line.
                          >
                            <Form.Item
                              label="Specialties"
                              name="icSpecialities"
                            >
                              <Select
                                mode="multiple"
                                allowClear
                                options={imagingCenterOptions}
                                getPopupContainer={(trigger) =>
                                  trigger.parentNode
                                }
                              ></Select>
                            </Form.Item>
                          </Col>
                          <Col span={24} md={24} xs={24} sm={24}>
                            <Form.Item
                              label="About"
                              name="aboutIC"
                              rules={[
                                {
                                  pattern: aboutPattern,
                                  message: "Please enter a valid about",
                                },
                              ]}
                            >
                              <TextArea rows={4} />
                            </Form.Item>
                          </Col>

                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Street address"
                              name="icOfficeAdd"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter street address",
                                },
                                {
                                  pattern: streetAddressPattern,
                                  message:
                                    "Please enter a valid street address",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="City"
                              name="icOfficeCity"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter city name",
                                },
                                {
                                  pattern: cityPattern,
                                  message: "Please enter a valid city name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="State"
                              name="icOfficeState"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter state name",
                                },
                                {
                                  pattern: cityPattern,
                                  message: "Please enter a valid state name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Zip code"
                              name="icOfficeZip"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter zip code",
                                },
                                {
                                  pattern: zipPattern,
                                  message: "Please enter a valid ZIP code",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Google rating"
                              name="icGoogleRating"
                              rules={[
                                {
                                  pattern: ratingPattern,
                                  message:
                                    "Please enter a valid rating between 0 and 10",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label="Facebook rating"
                              name="icFaceBookRating"
                              rules={[
                                {
                                  pattern: ratingPattern,
                                  message:
                                    "Please enter a valid rating between 0 and 10",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            <Form.Item
                              label="Google map link"
                              name="icMapSource"
                              rules={[
                                {
                                  // pattern: urlPattern,
                                  message: "Please input valid url",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col span={24} md={24} xs={24} sm={24}>
                            {initialValues?.testimonial?.map((item, index) => (
                              <div key={index}>
                                <Row gutter={[16, 16]}>
                                  <Col span={12}>
                                    <Form.Item
                                      label={`Testimonial name ${index + 1}`}
                                      name={`testimonialName_${index}`}
                                      initialValue={
                                        item[`testimonialName_${index}`]
                                      }
                                      rules={[
                                        {
                                          pattern: servicesNamePattern,
                                          message: "Please enter a valid name",
                                        },
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) =>
                                          handleUpdateTestimonial(
                                            index,
                                            e.target.value,
                                            item[`testimonialRating_${index}`],
                                            item[`review_${index}`]
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                  <Col span={12}>
                                    <Form.Item
                                      label={`Testimonial rating ${index + 1}`}
                                      name={`testimonialRating_${index}`}
                                      initialValue={
                                        item[`testimonialRating_${index}`]
                                      }
                                      rules={[
                                        {
                                          pattern: ratingPattern,
                                          message:
                                            "Please enter a valid rating between 0 and 10",
                                        },
                                      ]}
                                    >
                                      <Input
                                        onChange={(e) =>
                                          handleUpdateTestimonial(
                                            index,
                                            item[`testimonialName_${index}`],
                                            e.target.value,
                                            item[`review_${index}`]
                                          )
                                        }
                                      />
                                    </Form.Item>
                                  </Col>
                                </Row>

                                <Form.Item
                                  label={`Review ${index + 1}`}
                                  name={`review_${index}`}
                                  initialValue={item[`review_${index}`]}
                                  rules={[
                                    {
                                      pattern: aboutPattern,
                                      message: "Please enter a valid review",
                                    },
                                  ]}
                                >
                                  <Input.TextArea
                                    onChange={(e) =>
                                      handleUpdateTestimonial(
                                        index,
                                        item[`testimonialName_${index}`],
                                        item[`testimonialRating_${index}`],
                                        e.target.value
                                      )
                                    }
                                  />
                                </Form.Item>

                                {initialValues?.testimonial.length > 1 && (
                                  <Row justify="space-between table-header">
                                    <Row align="middle">
                                      <Typography.Title
                                        level={5}
                                      ></Typography.Title>
                                    </Row>
                                    <Row align="middle">
                                      <Typography.Text
                                        className="hover-thumb"
                                        level={3}
                                        onClick={() =>
                                          handleDeleteTestimonial(index)
                                        }
                                        style={{ color: "red" }}
                                      >
                                        <u>Delete</u>
                                      </Typography.Text>
                                    </Row>
                                  </Row>
                                )}
                              </div>
                            ))}

                            <Typography.Text
                              level={3}
                              onClick={handleAddTestimonial}
                              className="hover-thumb add-more-reviews"
                            >
                              <u>+ Add more testimonials</u>
                            </Typography.Text>
                          </Col>

                          <Col
                            span={12}
                            md={12}
                            xs={24}
                            sm={24}
                            className="mt-20"
                          >
                            <Form.Item
                              label="Account status"
                              name="activeOnPortal"
                            >
                              <Radio.Group>
                                <Radio value={true}>Active</Radio>
                                <Radio value={false}>Inactive</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Col>

                          <Col
                            span={24}
                            md={24}
                            xs={24}
                            sm={24}
                            className="center-btn"
                          >
                            <Button
                              size="large"
                              className="theme-btn mt-20"
                              htmlType="submit"
                              loading={loading}
                              disabled={saveDisabled}
                            >
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </Row>
                  </>
                )}
              </Col>
            </Row>
          </Tabs.TabPane>

          <Tabs.TabPane tab="Images" key="2">
            <Form
              onFinish={updateIcCenterImages}
              autoComplete="off"
              layout="vertical"
              form={form}
            >
              <Row>
                <Col span={20} md={24} xs={24} sm={24}>
                  <Row gutter={[20, 20]}>
                    {preSignedImg.map((image, index) => (
                      <Col span={8} md={8} xs={24} sm={24}>
                        <div key={index} className="service-image-preview">
                          <Image
                            src={image}
                            className="service-image-preview-img"
                            onClick={() => setPreviewIndex(index)}
                            preview={{
                              visible:
                                isPreviewVisible && previewIndex === index,
                              onVisibleChange: (visible, prevVisible) => {
                                if (!visible) setPreviewIndex(null);
                                setPreviewVisible(visible);
                              },
                            }}
                          />
                          <div className="hide-icon">
                            <Space>
                              <img
                                src={previewIcon}
                                alt="preview-icon"
                                onClick={() => {
                                  setPreviewIndex(index);
                                  setPreviewVisible(true);
                                }}
                              />
                              <a href={image} download>
                                <img src={downloadIcon} alt="download-icon" />
                              </a>
                              <a onClick={() => onDelete(index)}>
                                <img src={deleteIcon} alt="download-icon" />
                              </a>
                            </Space>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                  <Form.Item name="files">
                    <Upload
                      listType="picture-card"
                      // onPreview={onPreview}
                      className="basic-img-upload"
                      {...IcImagesProps}
                      beforeUpload={beforeUpload}
                      showDownloadIcon={false}
                      showRemoveIcon={false}
                      onPreview={false}
                    >
                      <CloudUploadOutlined /> Click here to upload image
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={24} md={24} xs={24} sm={24} className="center-btn">
                  <Button
                    size="large"
                    className="theme-btn mt-20"
                    htmlType="submit"
                    loading={loading}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </Form>
          </Tabs.TabPane>
        </Tabs>
      </Row>

      <SuccessModel
        message="Imaging Center Details Updated Successfully"
        open={successModel}
      />
    </>
  );
}
