/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_mobile_analytics_app_id": "5122805fc0014432b8b15ee494628902",
    "aws_mobile_analytics_app_region": "us-east-2",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "5122805fc0014432b8b15ee494628902",
            "region": "us-east-2"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://pliirxs7efcyvk2zk2wpbawvcu.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-hk2pb6as4baphav2girsrp7xja",
    "aws_cloud_logic_custom": [
        {
            "name": "bazarhealthREST",
            "endpoint": "https://beru4jtvqb.execute-api.us-east-2.amazonaws.com/prod",
            "region": "us-east-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-2:ce3b70d2-117f-45e6-b945-8f1141de4dd2",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_fIRWb7BOw",
    "aws_user_pools_web_client_id": "2ni6l14u08haqdf5hi35pj13mh",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "bazarhealth-20221207143647-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "us-east-2",
    "aws_content_delivery_url": "https://d2xrnb5pmoq1fq.cloudfront.net",
    "aws_user_files_s3_bucket": "bazarhealtha2da62319dd04201b98d37699a417a56111916-prod",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
