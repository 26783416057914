import React, { useState } from "react";
import "react-chatbot-kit/build/main.css";
import "./Chat.less";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import successIcon from "assets/icons/Success.svg";
import Card from "antd/lib/card/Card";

export default function Chat(props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const userData = useSelector((state) => state.userReducer?.userData);

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    form.resetFields(["serviceType"]);
  };

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const inputData = {
        body: {
          customerID: userData?.id,
          serviceType: values.serviceType,
        },
      };

      await API.post("bazarhealthREST", "/employee/suggestService", inputData);

      setLoading(false);
      props.closeModal();
      form.resetFields();
      setSuccessModel(true);
      setIsModalOpen(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
      props.closeModal();
      form.resetFields();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <Card style={{ borderRadius: 10 }}>
        <Form
          name="doctor"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="appForm"
          form={form}
          layout="vertical"
        >
          <div className="request-appointment">
            <h6>Ask your advocate</h6>
            <Row>
              <Col md={24} xs={24}>
                <Form.Item
                  label="What can we help you with ?"
                  name="serviceType"
                  rules={[
                    {
                      required: true,
                      message: "Required!",
                    },
                    {
                      pattern: /^[a-zA-Z0-9,. ]+$/,
                      message: "Please enter a valid input",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              form="appForm"
              key="submit"
              loading={loading}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Card>

      <Modal
        title="Basic Modal"
        open={successModel}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal"
      >
        <img src={successIcon} alt="Success Img" />
        <h6>Your request has been submitted!</h6>
      </Modal>
    </div>
  );
}
