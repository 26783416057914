// TourStepContent.js
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTourSteps } from "store/actions/tourSteps";
import { END_TOUR } from "./constant";

const TourStepContent = ({ heading, details, numbers, onSkipTour }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const endTour = () => {
    navigate("/");
    dispatch(setTourSteps(END_TOUR));
  };
  
  return (
    <>
      <div className="joyride-tour-heading">
        <div>
          <b>{heading}</b>
        </div>
      </div>
      <div className="joyride-tour-details">{details}</div>
      <div className="joyride-tour-numbers">
        <div>
          {numbers.currentStep}
          <span className="joyride-tour-numbers-disable">
            /{numbers.totalSteps}
          </span>{" "}
        </div>
        <div
          className="joyride-tour-navbtn"
          onClick={onSkipTour ? onSkipTour : endTour}
        >
          Skip
        </div>
      </div>
    </>
  );
};

export default TourStepContent;
