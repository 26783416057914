import React, { useState } from "react";
import "./styles/main.css";
import Icon from "../Icon";
import Contact from "./Contact";
import newChat from "assets/chat-images/newChat.svg";
import { useSelector } from "react-redux";
import EmployeeListModal from "../components/EmployeeListModal";

const Sidebar = ({ userData, setOpenEmployeeList }) => {
  const data = userData;



  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredContacts =
    data?.filter((contact) => {
      const fullName = `${contact.senderDetails?.firstName} ${contact.senderDetails?.lastName}`;
      return fullName?.toLowerCase().includes(searchQuery.toLowerCase());
    }) || [];

  const sortedContacts = filteredContacts.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });

  return (
    <>
      <aside className="chat-sidebar">
        <div className="search-wrapper">
          <div className="search-icons">
            <Icon id="search" className="search-icon" />
            <button className="search__back-btn">
              <Icon id="back" />
            </button>
          </div>
          <div className="d-flex">
            <input
              className="search"
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearch}
            />
            <img
              src={newChat}
              alt=""
              style={{ paddingLeft: "10px" }}
              onClick={() => setOpenEmployeeList(true)}
            />
          </div>
        </div>
        <div className="sidebar__contacts">
          {sortedContacts?.map((contact, index) => (
            <>
              <Contact key={index} contact={contact} />
            </>
          ))}
        </div>
      </aside>

    </>
  );
};

export default Sidebar;
