import * as actions from "../actions/index";

const initialState = {
  appData: null,
};

const AppointmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_APPOINTMENT:
      return {
        ...state,
        appData: action.payload,
      };

    default:
      return state;
  }
};
export default AppointmentReducer;
