import {
  Row,
  Input,
  Button,
  Dropdown,
  Menu,
  Table,
  Modal,
  message,
  Select,
  Form,
  Col,
  Typography,
  Upload,
} from "antd";
import "./Admin.less";
import React, { useCallback, useEffect, useState } from "react";
import uploadIcon from "assets/icons/upload.svg";
import { MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { getCompanyRegi, getEmployeeRegi } from "graphql/queries";
import { useSelector } from "react-redux";
import warning from "assets/icons/error.svg";

import { GetFileFromS3, uploadFileToS3 } from "utility/uploadFileToS3";

export default function AdminApproval() {
  const { Search } = Input;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [employee, setEmployeeData] = useState([]);
  const [openUpload, setOpenUpload] = useState(false);
  const [openWarning, setOpenWarning] = useState(false);
  const [dataSource, setDataSource] = useState(employee);
  const [loading, setLoading] = useState(employee);
  const { Option } = Select;

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const companyId = useSelector(
    (state) => state.userReducer?.userData?.companyRegiEmployeeId
  );

  const fetchData = useCallback(async () => {
    setLoading(true);
    const responce = await API.graphql({
      query: getCompanyRegi,
      variables: {
        id: companyId,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const requestedEmployee = responce.data.getCompanyRegi.employee.items
      .filter((row) => row.verified === true)
      .map((row) => ({
        key: row?.employeeEmail,
        id: [row.id, row.employeeEmail],
        searchByName: row?.searchByName,
        firstName: row.firstName,
        lastName: row.lastName,
        emailId: row.employeeEmail,

        role:
          row?.userType === "CORPORATE_ADMIN"
            ? "ADMIN"
            : row?.userType === "INDIVIDUAL"
            ? "INDIVIDUAL"
            : row?.userType === "CORPORATE"
            ? "EMPLOYEE"
            : "DEPENDENT",
      }));
    setLoading(false);
    setEmployeeData(requestedEmployee);
    setDataSource(requestedEmployee);
  }, [companyId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const headers = [
    { label: "Email ID", key: "emailID" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Phone", key: "phoneNumber" },
    { label: "Role", key: "role" },
  ];

  const csvExp = dataSource.map((row) => ({
    emailID: row?.emailId,
    firstName: row?.firstName,
    lastName: row?.lastName,
    role: row?.role,
  }));

  const makeAdmin = async (id) => {
    try {
      const responce = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: id[0],
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (responce.data.getEmployeeRegi.userType === "CORPORATE_ADMIN") {
        messageApi.open({
          key: "employee",
          type: "loading",
          content: `Making ${id[1]} Employee`,
          duration: 0,
        });
        const myInit = {
          body: {
            id: id[0],
          },
        };
        let result = await API.post(
          "bazarhealthREST",
          `/hr/make_employee`,
          myInit
        );

        if (result.status) {
          messageApi.open({
            key: "employee",
            type: "success",
            content: `${id[1]} is now an employee.`,
            duration: 5,
          });
          fetchData();
        } else {
          messageApi.open({
            key: "employee",
            type: "error",
            content: `error making ${id[1]} an employee`,
            duration: 5,
          });
        }
      } else if (responce.data.getEmployeeRegi.userType === "CORPORATE") {
        messageApi.open({
          key: "admin",
          type: "loading",
          content: `Making ${id[1]} Admin`,
          duration: 0,
        });
        const myInit = {
          body: {
            id: id[0],
          },
        };
        let result = await API.post(
          "bazarhealthREST",
          `/hr/make_admin`,
          myInit
        );

        if (result.status) {
          messageApi.open({
            key: "admin",
            type: "success",
            content: `${id[1]} is now an admin.`,
            duration: 5,
          });
          fetchData();
        } else {
          messageApi.open({
            key: "admin",
            type: "warning",
            content: `${id[1]} is already an Admin`,
            duration: 5,
          });
        }
      }
    } catch (error) {
      messageApi.open({
        key: "admin",
        type: "error",
        content: `Error making ${id[1]} an Admin`,
        duration: 5,
      });
      console.log(error);
    }
  };

  const columns = [
    {
      title: "Employee Email",
      dataIndex: "emailId",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },

    {
      title: "Role",
      dataIndex: "role",
    },
    {
      title: "",
      dataIndex: "id",
      className: "actions",
      render: (id, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  {record?.role === "ADMIN" ? (
                    <Link
                      rel="noopener noreferrer"
                      onClick={() => makeAdmin(id)}
                    >
                      Make an Employee
                    </Link>
                  ) : (
                    <Link
                      rel="noopener noreferrer"
                      onClick={() => makeAdmin(id)}
                    >
                      Make an Admin
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    rel="noopener noreferrer"
                    // onClick={() => deleteUser(id)}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const filteredUsers = employee.filter((item) => {
      return (
        item?.searchByName?.indexOf(currValue.toLowerCase()) >= 0 ||
        item?.emailId?.indexOf(currValue.toLowerCase()) >= 0
      );
    });
    setDataSource(filteredUsers);
  };

  const props = {
    onChange({ file }) {
      if (file.status === "removed") {
        // setImageCount(imageCount - 1);
        // setImageObject(
        //   imageObject.filter((item) => item["fileName"] !== file.name)
        // );
        // setUploadFileName(uploadFileName.filter((item) => item !== file.name));
      }

      if (file.status === "error") {
        console.log("error encountered");
      }
    },

    customRequest({ file, onError, onSuccess }) {
      uploadFileToS3(file)
        .then(async (res) => {
          const link = await GetFileFromS3(res.key);
          // setImageObject((item) => [
          //   ...item,
          //   {
          //     fileName: file.name,
          //     fileKey: "private/" + cognitoId + "/" + res.key,
          //   },
          // ]);
          onSuccess();
        })
        .catch((err) => {
          console.log(err);
          onError();
        });
    },
  };

  function UploadIcn() {
    return <img src={uploadIcon} alt="" className="h-30" />;
  }

  return (
    <>
      {contextHolder}
      <Modal
        title="Basic Modal"
        open={isModalOpen}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal"
      >
        {/* <img src={successIcon} alt="Success Img" /> */}
        <h6>Profile Updated Successfully</h6>
      </Modal>
      <Row justify="space-between table-header">
        <Row align="middle">
          <Search
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        {/* <Row className="gap-10 mtr-20">
          <Button className="blue-btn" onClick={() => setOpenWarning(true)}>
            Download Sample file
          </Button>
          <Button className="blue-btn" onClick={() => setOpenUpload(true)}>
            Upload CSV or Excel File
          </Button>
        </Row> */}
      </Row>

      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
      />

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Upload File
          </Typography.Title>
        }
        visible={openUpload}
        onOk={() => setOpenUpload(false)}
        onCancel={() => setOpenUpload(false)}
      >
        <Form
          name="basic"
          // onFinish={createDoctorProfileData}
          autoComplete="off"
          layout="vertical"
          // form={form}
          // onFieldsChange={handleFormChange}
        >
          <Row gutter={20} className="reward-input-box">
            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                name="files"
                rules={[
                  {
                    required: true,
                    message: "Please upload files",
                  },
                ]}
              >
                <Upload {...props}>
                  <Button icon={<UploadIcn />} className="sub-header-font">
                    &nbsp; <br /> Click here to upload files
                  </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button className="theme-btn" htmlType="submit">
                Upload
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        visible={openWarning}
        onOk={() => setOpenWarning(false)}
        centered
        onCancel={() => setOpenWarning(false)}
        className="reward-success-modal"
        footer={null}
      >
        <img src={warning} alt="Delete Img" />
        <h6 className="mt-20">You’ve reached the invitation limit</h6>
        <p className="upload-p">
          If you want to invite more employees, please update your plan.
        </p>

        <Button type="primary" className="theme-btn mt-20">
          Update Plan
        </Button>
      </Modal>
    </>
  );
}
