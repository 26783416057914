import React from "react";
import { Tabs } from "antd";
import OpenEob from "./OpenEob";
import ClosedEob from "./ClosedEob";
import EobChart from "./EobChart";
import comingsoon from "assets/icons/coming.png";

export default function EOBStatus() {
  return (
    <>
      <Tabs className="employer-tabs" defaultActiveKey="1" centered>
        <Tabs.TabPane tab="Open EOB’s" key="1">
          <OpenEob />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Closed EOB’s" key="2">
          <ClosedEob />
          {/* <div className="no-search">
            <div className="no-search-box">
              <img src={comingsoon} alt="" width={500} />
            </div>
          </div> */}
        </Tabs.TabPane>
        <Tabs.TabPane tab="EOB status chart" key="3">
          {/* <EobChart /> */}
          <div className="no-search">
            <div className="no-search-box">
              <img src={comingsoon} alt="" width={500} />
            </div>
          </div>
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
