import {
  Col,
  Modal,
  Row,
  Table,
  message,
  Typography,
  Space,
  Image,
  Grid,
  Pagination,
  Divider,
  Skeleton,
} from "antd";
import { API } from "aws-amplify";
import { listRewardSubmissions } from "graphql/queries";
import {
  EyeOutlined,
  CheckOutlined,
  CloseOutlined,
  FieldTimeOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SkeletonBlock from "utility/SkeletonBlocks";
import { formatNumberUS } from "utility/constant";
import previewIcon from "assets/icons/previewIc.svg";
import TableSkeleton from "utility/TableSkeleton";

export default function UserSubmissions() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [modalValue, setModalValue] = useState({});
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [submission, setSubmission] = useState([]);
  const [preSignedImg, setPreSignedimg] = useState([]);
  const userData = useSelector((state) => state.userReducer?.userData);
  const userId = userData?.id;

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = submission.slice(indexOfFirstItem, indexOfLastItem);

  const getStatusStyle = (value) => {
    if (value === "APPROVED") {
      return { color: "green" };
    } else if (value === "DENIED") {
      return { color: "red" };
    } else {
      return { color: "orange" };
    }
  };

  const getStatusIcon = (value) => {
    if (value === "APPROVED") {
      return <CheckOutlined style={{ marginRight: "5px" }} />;
    } else if (value === "DENIED") {
      return <CloseOutlined style={{ marginRight: "5px" }} />;
    } else {
      return <FieldTimeOutlined style={{ marginRight: "5px" }} />;
    }
  };

  const columns = [
    {
      title: "Name",
      colSpan: 1,
      dataIndex: "name",
      width: "25%",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Procedure",
      dataIndex: "procedures",
      width: "20%",
      render: (data) => <span className="table-font word-break">{data}</span>,
    },

    {
      title: "Submitted",
      colSpan: 1,
      dataIndex: "submitted",
      render: (data) => <span className="table-font">{data}</span>,
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (value) => {
        return (
          <div
            className="table-font d-flex align-center"
            style={getStatusStyle(value)}
          >
            {getStatusIcon(value)}
            {value}
          </div>
        );
      },
    },
    {
      title: "Reward Amount",
      colSpan: 1,
      dataIndex: "rewardAmount",
      width: "17%",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id, value) => {
        return (
          <EyeOutlined
            onClick={() => {
              setModalValue(value?.data);
              setIsModalOpen(true);
              getImagePreSigned(value?.data);
            }}
          />
        );
      },
    },
  ];

  const columns2 = [
    {
      title: "",
      dataIndex: "label1",
      render: (item) => {
        return <strong className="p-font">{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data1",
      render: (item) => {
        return <span className="p-font">{item}</span>;
      },
    },
    {
      title: "",
      dataIndex: "label2",
      className: "border-left",
      render: (item) => {
        return <strong className="p-font">{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data2",
      render: (value, id) => {
        return <span className="p-font">{value}</span>;
      },
    },
  ];

  const data = [
    {
      key: "1",
      label1: <span className="p-font">Claim Created:</span>,
      data1: new Date(modalValue?.createdAt).toLocaleDateString(),

      label2: "Claim For:",
      data2: modalValue?.name,
    },
    {
      key: "2",
      label1: <span className="p-font">Procedure:</span>,
      data1: modalValue?.nameOfProcedure,
      label2: <span className="p-font">Type of Care</span>,
      data2: modalValue?.typeOfCare,
    },
    {
      key: "8",
      label1: <span className="p-font">Status:</span>,
      data1: modalValue?.status,
      label2: <span className="p-font">Reward</span>,
      data2: modalValue?.rewardAmount
        ? `$${parseInt(modalValue?.rewardAmount).toLocaleString()}`
        : "-",
    },
    {
      key: "9",
      label1: <span className="p-font">Approved On:</span>,
      data1: new Date(modalValue?.updatedAt).toLocaleDateString(),
      label2: <span className="p-font">Approved By:</span>,
      data2: modalValue?.approvedBy || "-",
    },
    {
      key: "3",
      label1: <span className="p-font">Employer:</span>,
      data1: userData?.companyID?.name,
      label2: <span className="p-font">Insurance Carrier:</span>,
      data2: userData?.profile?.insuranceCarrier,
    },
    {
      key: "6",
      label1: <span className="p-font">Member ID:</span>,
      data1: userData?.profile?.memberID,
      label2: <span className="p-font">Plan ID:</span>,
      data2: userData?.profile?.planID,
    },
    {
      key: "4",
      label1: <span className="p-font">Email:</span>,
      data1: modalValue?.customerEmail,
      label2: <span className="p-font">Phone:</span>,
      data2: formatNumberUS(userData?.profile?.phone),
    },
    {
      key: "5",
      label1: <span className="p-font">Address:</span>,
      data1: ` ${userData?.profile?.streetAdress}, ${userData?.profile?.city} ${userData?.profile?.state} ${userData?.profile?.zip}`,
    },
  ];

  const newData = [];
  data.forEach((item) => {
    const newItem1 = {
      key: `${item.key}-label1`,
      label1: item.label1,
      data1: item.data1,
    };
    const newItem2 = {
      key: `${item.key}-label2`,
      label1: item.label2,
      data1: item.data2,
    };
    newData.push(newItem1, newItem2);
  });


  // function RenderData() {
  //   return (
  //     <>
  //       <div className="mt-20">
  //         {data.map((item) => (
  //           <Row key={item.key}>
  //             <Col
  //               xs={12}
  //               md={12}
  //               span={12}
  //               style={{ borderBottom: "1px solid #000" }}
  //             >
  //               <strong className="p-font">{item.label1}</strong>
  //             </Col>
  //             <Col
  //               xs={12}
  //               md={12}
  //               span={12}
  //               style={{ borderBottom: "1px solid #000" }}
  //             >
  //               <span className="p-font">{item.data1}</span>
  //             </Col>

  //             <Col xs={12} md={12} span={12}>
  //               <strong className="p-font">{item.label2}</strong>
  //             </Col>
  //             <Col xs={12} md={12} span={12}>
  //               <span className="p-font">{item.data2}</span>
  //             </Col>
  //           </Row>
  //         ))}
  //       </div>
  //     </>
  //   );
  // }

  function RenderData() {
    return (
      <div className="mt-20">
        {newData.map((item) => (
          <Row
            key={item.key}
            style={{
              borderBottom: "0.5px solid grey",
              paddingTop: 5,
              paddingBottom: 5,
            }}
          >
            <Col xs={12} md={12} span={12}>
              <strong className="p-font">{item.label1}</strong>
            </Col>
            <Col xs={12} md={12} span={12}>
              <span className="p-font">{item.data1}</span>
            </Col>
          </Row>
        ))}
      </div>
    );
  }

  const handleOk = () => {
    setIsModalOpen(false);
    setModalValue({});
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setModalValue({});
  };

  const getImagePreSigned = async (data) => {
    setskeletonLoader(true);
    try {
      if (data?.uploadedImages.length !== 0) {
        let url = `/superAdmin/send_presigned_url`;
        const myInit = {
          body: {
            keys: data?.uploadedImages,
          },
        };
        let result = await API.post("bazarhealthREST", url, myInit);
        setPreSignedimg(result);
      }
      setskeletonLoader(false);
    } catch (error) {
      setskeletonLoader(false);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    }
  };

  const fetchData = async () => {
    try {
      setskeletonLoader(true);
      const responce = await API.graphql({
        query: listRewardSubmissions,
        variables: {
          filter: {
            customerID: { eq: userId },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const unsortedData = responce.data.listRewardSubmissions.items.map(
        (row) => ({
          name: row.name,
          procedures: row.nameOfProcedure,
          submitted: new Date(row.createdAt).toLocaleDateString(),
          // providerPrice: "19/12/2022",
          status: row?.status || "PENDING",
          rewardAmount:
            row?.rewardAmount && row?.status === "APPROVED"
              ? `$${parseInt(row?.rewardAmount).toLocaleString()}`
              : "-",
          data: row,
        })
      );

      unsortedData.sort(function (a, b) {
        // Turn your strings into dates, and then subtract them
        // to get a value that is either negative, positive, or zero.
        return new Date(b?.data?.createdAt) - new Date(a?.data?.createdAt);
      });

      setSubmission(unsortedData);
    } catch (error) {
      console.log("error", error);
    } finally {
      setskeletonLoader(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {contextHolder}

      <div className="mobile-procedure">
        {screens.xs === true ? (
          <>
            {skeletonLoader ? (
              <Skeleton
                title={false}
                paragraph={{ rows: 12 }}
                className="mt-20"
              />
            ) : (
              <>
                {currentItems.map((data, index) => (
                  <>
                    <Row justify="space-between mt-20" key={data.id}>
                      <h1 title={data.name}>{data?.name}</h1>
                      <div style={getStatusStyle(data.status)}>
                        {getStatusIcon(data.status)}
                        {data.status}
                        <Divider
                          type="vertical"
                          style={{ height: "100%", border: "0.5px solid #000" }}
                        />
                        <EyeOutlined
                          onClick={() => {
                            // openAppointmentDetails(data);
                            setModalValue(data?.data);
                            setIsModalOpen(true);
                            getImagePreSigned(data?.data);
                          }}
                          style={{ fontSize: "17px", color: "#00406A" }}
                        />
                      </div>
                    </Row>
                    <Row gutter={[10, 10]} className="vertical-line-row mt-10">
                      <Col
                        xs={9}
                        sm={9}
                        md={9}
                        lg={9}
                        xl={9}
                        className="vertical-line-column"
                      >
                        <div className="label avg-sav" title={data.procedures}>
                          Procedure
                        </div>
                        <div className="amount avg-sav">{data?.procedures}</div>
                      </Col>
                      <Col
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        className="vertical-line-column"
                      >
                        <div className="label">Submitted</div>
                        <div className="amount">{data.submitted}</div>
                      </Col>

                      <Col
                        xs={7}
                        sm={7}
                        md={7}
                        lg={7}
                        xl={7}
                        className="vertical-line-column"
                      >
                        <div className="label">Reward</div>
                        <div
                          className="amount"
                          style={{ color: "#229557", fontWeight: 700 }}
                        >
                          {data.rewardAmount}
                        </div>
                      </Col>
                    </Row>
                    {index !== submission.length - 1 && (
                      <Divider className="procedure-divider" />
                    )}{" "}
                  </>
                ))}
                <Pagination
                  current={currentPage}
                  onChange={setCurrentPage}
                  total={submission.length}
                  pageSize={itemsPerPage}
                  showSizeChanger={false}
                  className="mt-20"
                />
              </>
            )}
          </>
        ) : (
          <Row justify="center">
            <Col
              xl={24}
              md={17}
              xs={24}
              className="mb-2 procedures top-procedures mt-1 mt-20"
            >
              {skeletonLoader ? (
                <TableSkeleton />
              ) : (
                <Table
                  columns={columns}
                  dataSource={submission}
                  bordered
                  className="common-table"
                  scroll={{ x: screens.xs ? 992 : "" }}
                  pagination={{
                    position: ["", "bottomCenter"],
                  }}
                />
              )}
            </Col>
          </Row>
        )}
      </div>

      <Modal
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={750}
        className="suggest-modal"
        footer={null}
      >
        <div className="request-appointment">
          <div className="">
            <h6 className="header-font">My Submission</h6>
          </div>

          <Row justify="center" className="update-form">
            <Col span={24} md={24} xs={24} sm={24}>
              {skeletonLoader ? (
                <>
                  <Row
                    gutter={[20, 20]}
                    className="mt-3"
                    style={{ flexDirection: "column" }}
                  >
                    {Array.from({ length: 5 }).map((_, index) => (
                      <SkeletonBlock
                        key={index}
                        style={{
                          width: "100%",
                          height: 50,
                          margin: "10px 0",
                        }}
                      />
                    ))}
                  </Row>
                </>
              ) : (
                <>
                  {screens.xs ? (
                    <RenderData />
                  ) : (
                    <Table
                      columns={columns2}
                      dataSource={data}
                      className="common-table no-header"
                      scroll={{ x: screens.xs ? 700 : "" }}
                      showHeader={false}
                      pagination={false}
                      size="small"
                    />
                  )}
                </>
              )}
            </Col>

            <Col span={24} md={24} xs={24} sm={24} className="mt-30">
              <Typography.Title level={4} className="header-font">
                File
              </Typography.Title>

              {skeletonLoader ? (
                <Row
                  gutter={[20, 20]}
                  className="mt-3"
                  style={{ flexDirection: "column" }}
                >
                  {Array.from({ length: 3 }).map((_, index) => (
                    <SkeletonBlock
                      key={index}
                      style={{
                        width: "100%",
                        height: 150,
                        margin: "10px 0",
                      }}
                    />
                  ))}
                </Row>
              ) : (
                <>
                  <Row gutter={[16, 16]}>
                    {preSignedImg.length === 0 ? (
                      <Col span={16} md={16} xs={24} sm={24}>
                        <Typography.Text level={4}>No Images</Typography.Text>
                      </Col>
                    ) : (
                      preSignedImg.map((image, index) => (
                        <Col
                          span={8}
                          md={8}
                          xs={24}
                          sm={24}
                          key={index}
                          style={{ maxWidth: "150px !important" }}
                        >
                          <div className="image-preview">
                            <Image
                              src={
                                image.fileExtension === "png" ||
                                image.fileExtension === "jpeg" ||
                                image.fileExtension === "jpg"
                                  ? image?.fileKey
                                  : document
                              }
                              className="image-preview-img"
                              onClick={() => setPreviewIndex(index)}
                              preview={{
                                visible:
                                  isPreviewVisible && previewIndex === index,
                                onVisibleChange: (visible, prevVisible) => {
                                  if (!visible) setPreviewIndex(null);
                                  setPreviewVisible(visible);
                                },
                              }}
                            />
                            <div className="hide-icon">
                              <Space>
                                {image.fileExtension === "png" ||
                                image.fileExtension === "jpeg" ||
                                image.fileExtension === "jpg" ? (
                                  <img
                                    src={previewIcon}
                                    alt="preview-icon"
                                    onClick={() => {
                                      setPreviewIndex(index);
                                      setPreviewVisible(true);
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                              </Space>
                            </div>
                          </div>

                          <Typography.Title
                            level={5}
                            style={{
                              textAlign: "center",
                              bottom: 0,
                              margin: "10px 0",
                            }}
                          >
                            {image?.fileName}
                          </Typography.Title>
                        </Col>
                      ))
                    )}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </div>
      </Modal>
    </>
  );
}
