import { Button, Card, Col, Row } from "antd";
import Container from "commons/Container";
import React from "react";
import successIcon from "assets/icons/cross.png";
import { Link } from "react-router-dom";
export default function PaymentFailed() {
  return (
    <Container>
      <Card className="my-2">
        <Row justify="center" gutter={20}>
          <div className="no-search">
            <div className="no-search-box">
              <img src={successIcon} alt="" style={{width: "200px"}}/>
              <h6>Payment Failed !!</h6>
            
               <Link to="/">
                Go to Homepage
                </Link>
            
            </div>
          </div>
        </Row>
      </Card>
    </Container>
  );
}
