import React, { useState } from "react";
import {
  Row,
  DatePicker,
  Col,
  Input,
  Button,
  Checkbox,
  Form,
  Radio,
  Select,
  message,
  Grid,
} from "antd";
import { Analytics, API } from "aws-amplify";
import { createPtAppointment } from "graphql/mutations";
import { useSelector } from "react-redux";
import { aboutPattern, servicesNamePattern } from "utility/constant";
import SuccessModel from "utility/SuccessModel";
export default function PtAppointment(props) {
  const [providerCheck, setProviderCheck] = useState(false);
  const [appointmentFor, setAppointmentFor] = useState([]);
  const [successModel, setSuccessModel] = useState(false);
  const [selfSelect, setSelfSelect] = useState(false);
  const [value, setValue] = useState();
  const [dob, setDob] = useState("");
  const [contacted, setContacted] = useState();
  const [isOtherSelected, setIsOtherSelected] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();
  const { Option } = Select;
  const { TextArea } = Input;
  const isModal = props?.isModal;
  const ptData = props?.data ? props?.data : props?.ptData;
  const ptName = props?.data?.ptName ? props?.data?.ptName : props?.ptName;
  const userData = useSelector((state) => state?.userReducer?.userData);

  const onRadioChange = (e) => {
    setValue(e.target.value);
  };

  const onContactChange = (e) => {
    setContacted(e.target.value);
  };

  const onChange = (e) => {
    setProviderCheck(e.target.checked);
  };

  const onChangeDatepickerDOB = (date, dateString) => {
    setDob(dateString);
  };

  const handleChangePT = (value) => {
    if (value === "My Self") {
      setIsOtherSelected(false);
      setSelfSelect(true);
      form.setFieldsValue({
        name: userData?.profile?.firstName + " " + userData?.profile?.lastName,
      });
    } else if (value === "Other") {
      setIsOtherSelected(true);
      setSelfSelect(false);
      form.setFieldsValue({
        name: "",
      });
    }
    setAppointmentFor(value);
  };

  const onFinish = async (values) => {
    try {
      setCreateLoading(true);
      const inputData = {
        input: {
          userData: {
            id: userData?.id,
            name:
              userData?.profile?.firstName + " " + userData?.profile?.lastName,
            otherName: values?.name,
            appointmentFor: appointmentFor,
            preferredWeekDay: values?.preferredWeekDay,
            preferredTime: values?.preferredTime,
            email: values?.email ? values?.email : userData?.profile?.email,
            phone: values?.phone ? values?.phone : userData?.profile?.phone,
            dob: userData?.profile?.dob,
            otherDob: dob === "" ? null : dob,
            insuranceCarrier: userData?.profile?.insuranceCarrier,
            memberID: userData?.profile?.memberID,
          },
          ptData: {
            id: ptData?.id ? ptData?.id : ptData?.ptID,
            name: ptData?.ptName,
            email: ptData?.ptEmail,
            phone: ptData?.ptPhone,
            fax: ptData?.ptFax,
          },
          userID: userData?.id,
          comment: values?.comment,
          status: true,
          openToOther: providerCheck,
          preferredContact: values?.preferredContact,
          haveReferral: values?.haveReferral,
          viewed: false,
        },
      };
      await API.graphql({
        query: createPtAppointment,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      Analytics.record({
        name: "PT Appointment",
        attributes: { name: ptData?.ptName },
        immediate: true,
      });
      form.resetFields();
      setSelfSelect(false);
      if (isModal) {
        props?.onCloseModal();
      }
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const handleClick = () => {
    props.onUpdateTourIndex(100);
  };

  return (
    <>
      {contextHolder}
      <Form
        name="doctor"
        onFinish={onFinish}
        autoComplete="off"
        id="appForm"
        layout="vertical"
        form={form}
      >
        <div className="request-appointment">
          {isModal ? (
            <div className="text-center">
              <h6 className="sub-header-font">Appointment Form</h6>
              <span className="fs-1 p-font">
                Request an appointment with: {ptName}
              </span>
            </div>
          ) : screens.xs ? (
            ""
          ) : (
            <h6 className="sub-header-font">
              Request an appointment with: {ptName}
            </h6>
          )}

          <Row className="mt-20">
            <Col md={isModal ? 22 : 24} offset={isModal ? 1 : 0}>
              <div className="reward-input-box r-mt-10">
                <Row
                  gutter={20}
                  className={props?.className}
                  onClick={handleClick}
                >
                  <Col md={24}>
                    <Row gutter={20}>
                      <Col md={12} xs={24}>
                        <Form.Item
                          name="appointmentFor"
                          label={
                            <span className="p-font">
                              Who is the appointment for?
                            </span>
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please select any option!",
                            },
                          ]}
                        >
                          <Select
                            className="form-control"
                            defaultValue="Select any option"
                            onChange={handleChangePT}
                            options={[
                              {
                                value: "My Self",
                                label: "My Self",
                              },
                              {
                                value: "Other",
                                label: "Other",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col md={12} xs={24}>
                        <Form.Item
                          label={<span className="p-font">Name</span>}
                          name="name"
                          rules={[
                            {
                              required: true,
                              message: "Please input your name!",
                            },
                            {
                              pattern: servicesNamePattern,
                              message: "Please input a valid name",
                            },
                          ]}
                        >
                          <Input disabled={selfSelect} />
                        </Form.Item>
                      </Col>

                      {isOtherSelected && (
                        <>
                          <Col span={12} md={12} xs={24} sm={24}>
                            <Form.Item
                              label={<span className="p-font">DOB</span>}
                              name="dob"
                              rules={[
                                {
                                  required: true,
                                  message: "Please input DOB!",
                                },
                              ]}
                            >
                              <DatePicker
                                onChange={onChangeDatepickerDOB}
                                disabledDate={(d) =>
                                  !d || d.isAfter(new Date())
                                }
                                className="datepicker"
                                allowClear={false}
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                      <Col md={12} xs={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Which day of the week would you prefer ?
                            </span>
                          }
                          name="preferredWeekDay"
                        >
                          <Select
                            className="form-control"
                            defaultValue="Select Any Option"
                            name="preferredWeekDay"
                            id="preferredWeekDay"
                            options={[
                              {
                                value: "Monday",
                                label: "Monday",
                              },
                              {
                                value: "Tuesday",
                                label: "Tuesday",
                              },
                              {
                                value: "Wednesday",
                                label: "Wednesday",
                              },
                              {
                                value: "Thursday",
                                label: "Thursday",
                              },
                              {
                                value: "Friday",
                                label: "Friday",
                              },
                              {
                                value: "Saturday",
                                label: "Saturday",
                              },
                              {
                                value: "Sunday",
                                label: "Sunday",
                              },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12} md={12} xs={24} sm={24}>
                        <Form.Item
                          label={
                            <span className="p-font">
                              Preferred time of day
                            </span>
                          }
                          name="preferredTime"
                          rules={[
                            {
                              required: true,
                              message: "Please select preferred time of day",
                            },
                          ]}
                        >
                          <Select
                            className="form-control"
                            defaultValue="Select Any Option"
                            allowClear
                          >
                            <Option value="MORNING">Morning</Option>
                            <Option value="LUNCH">Lunch</Option>
                            <Option value="AFTERNOON">Afternoon</Option>
                            <Option value="NO_PREFERENCE">No Preference</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24} xs={24}>
                    <Form.Item
                      label={
                        <span className="p-font">
                          Brief description of injury/symptoms
                        </span>
                      }
                      name="comment"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                        {
                          pattern: aboutPattern,
                          message: "Please input a valid brief description",
                        },
                      ]}
                    >
                      <TextArea rows={4} />
                    </Form.Item>
                  </Col>

                  <Col md={12} xs={24}>
                    <Form.Item
                      label={
                        <span className="p-font">
                          How do you prefer to be contacted ?
                        </span>
                      }
                      name="preferredContact"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onContactChange} value={contacted}>
                        <Radio value="Email" className="p-font">
                          Email
                        </Radio>
                        <Radio value="Phone" className="p-font">
                          Phone
                        </Radio>
                        <Radio value="Either" className="p-font">
                          Either
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>

                  <Col md={12}>
                    <Form.Item
                      label={
                        <span className="p-font">Do you have a referral ?</span>
                      }
                      name="haveReferral"
                      rules={[
                        {
                          required: true,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Radio.Group onChange={onRadioChange} value={value}>
                        <Radio value={1} className="p-font">
                          Yes
                        </Radio>
                        <Radio value={2} className="p-font">
                          No
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="checkbox" className="form-bottom">
                      <span className="fs-1 p-font">
                        I want the earliest available appointment.
                      </span>
                      <br />
                      <Checkbox onChange={onChange}>
                        <span className="fs-15 p-font">
                          I am open to a different provider at the same location
                          if they have a sooner opening.
                        </span>
                      </Checkbox>
                    </Form.Item>

                    <span className="default-msg p-font">
                      This request will be sent to the provider’s scheduling
                      coordinator.
                    </span>
                  </Col>
                </Row>

                <Col
                  span={12}
                  md={24}
                  xs={24}
                  sm={24}
                  style={{ textAlign: "center" }}
                  className="mt-20"
                >
                  <Form.Item className="form-bottom ">
                    {props?.tourIndex === 3 && props?.tour ? (
                      <Button
                        loading={createLoading}
                        type="primary"
                        className="theme-btn button-font pt-third-step"
                        onClick={() => props.onUpdateTourIndex(10)}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        loading={createLoading}
                        type="primary"
                        className="theme-btn button-font pt-third-step"
                        htmlType="submit"
                        form="appForm"
                        key="submit"
                      >
                        Submit
                      </Button>
                    )}
                  </Form.Item>
                </Col>
              </div>
            </Col>
          </Row>
        </div>
      </Form>

      <SuccessModel
        message={
          <span className="header-font">
            Great! Your appointment request has been sent successfully
          </span>
        }
        open={successModel}
      />
    </>
  );
}
