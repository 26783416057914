import { Row } from "antd";
import React from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { DownloadOutlined } from "@ant-design/icons";


pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfPreview = ({ pdfUrl }) => {
  const pageStyle = {
    width: "200px", // Set your preferred width
    height: "50px", // Set your preferred height
  };
  return (
    <Row className="d-flex space-between w-100 chat-pdf">
      {/* <Document file={pdfUrl}>
        <Page pageNumber={1} style={pageStyle} />
      </Document> */}
      <p> PDF File</p>
      <a href={pdfUrl} download="downloaded_file.pdf">
      <DownloadOutlined />
      </a>
    </Row>
  );
};

export default PdfPreview;
