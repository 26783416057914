import * as actions from "../actions/index";

const initialState = {
  globalSearchData: null,
};

const globalSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_GLOBAL_SEARCH_DATA:
      return {
        ...state,
        globalSearchData: action.payload,
      };

    default:
      return state;
  }
};
export default globalSearchReducer;
