import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Col,
  Select,
  Modal,
  Typography,
  Divider,
  message,
  Dropdown,
  Menu,
  Radio,
} from "antd";
import React, { useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { disableSubmitButton, formatCSVData } from "utility/constant";
import {
  ListCompanyRegis,
  ListEmployeesRegis,
  ListPromotionalPartners,
} from "utility/action";
import { useCallback } from "react";
import search from "utility/search";
import SuccessModel from "utility/SuccessModel";
import { API } from "aws-amplify";

export default function Notification() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [doctorData, setdoctordata] = useState([]);
  const { TextArea } = Input;

  const [loading, setLoading] = useState(false);
  const [notificationLoading, setNotificationLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [dataSource, setDataSource] = useState(doctorData);
  const [csvData, setCsvData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showEmployerDropdown, setShowEmployerDropdown] = useState(false);
  const [showReceiverEmail, setShowReceiverEmail] = useState(false);
  const [radioValue, setRadioValue] = useState("");

  const [form] = Form.useForm();

  const handleFormChange = disableSubmitButton(["name"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.resetFields();
    setIsModalOpen(false);
    setSuccessModel(false);
    setShowReceiverEmail(false);
    setShowEmployerDropdown(false);
  };

  const labelKeyMap = {
    "Physician ID": "physicianID",
    "Doctor Name": "name",
    "Doctor Degree": "doctorDegree",
    "Doctor Email": "partnerEmail",
    "About Doctor": "aboutDoctor",
    Education: "doctorEducation",
    "Area of Speciality": "areaOfSpeciality",
    Specialities: "specialities",
    Address: "doctorOfficeAdd",
    City: "doctorOfficeCity",
    State: "doctorOfficeState",
    Zip: "doctorOfficeZip",
    Phone: "partnerPhone",
    "Doctor Practice Name": "doctorPracticeName",
    Website: "partnerAddress",
    "Doctor Facility": "doctorFacility",
    "Surgery Center": "surgeryCenter",
    "Google Rating": "googleRating",
    "Facebook Rating": "facebookRating",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const setDoctorsRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      Action: row?.id,
      name: row?.name,
      email: row?.email,
      createdAt: row?.createdAt,
      phone: row?.phone,
      referral: row?.referal?.promotionalCode?.toUpperCase(),
      couponID: row?.referal?.promotionalID,
      discountType: new Date(row?.createdAt).toLocaleDateString(),
      discount: row?.referal?.discount,
    };
  };

  const columns = [
    {
      title: "Receiver name",
      dataIndex: "name",
    },
    {
      title: "Subject",
      dataIndex: "email",
    },
    {
      title: "Notification Message",
      dataIndex: "phone",
    },

    {
      title: "Sent Date",
      dataIndex: "discountType",
    },

    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id, data) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to={`/partner-detail/${id}`}>Edit</Link>
                </Menu.Item>

                {data?.couponID && (
                  <Menu.Item key={2}>
                    <Link
                      to={`/referred-list/${data?.couponID}`}
                      rel="noopener noreferrer"
                    >
                      View all members
                    </Link>
                  </Menu.Item>
                )}
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchDoctor = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListPromotionalPartners(999);
      const data = response.map((row) => setDoctorsRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setdoctordata(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);
  const [employers, setEmployers] = useState([]);

  const fetchEmployersData = useCallback(async () => {
    await ListCompanyRegis()
      .then((response) => {
        setEmployers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    fetchEmployersData();
    fetchDoctor();
  }, [fetchDoctor]);

  const sendNotification = async (values) => {
    try {
      setNotificationLoading(true);
      let url = `/notification/sendNotification`;
      let fcmPayload;

      if (values?.employer || values?.email) {
        const filter = values?.employer
          ? { companyRegiEmployeeId: { contains: values.employer } }
          : { emailID: { eq: values.email } };

        const response = await ListEmployeesRegis(filter, 999);
        if (response?.length === 0) {
          messageApi.open({
            type: "error",
            content: "Email does not exist",
          });
          return;
        }
        const fcmTokens = response
          .map((item) => item.fcmToken)
          .filter((token) => token !== null);

        if (fcmTokens?.length === 0) {
          messageApi.open({
            type: "error",
            content: "No users are subscribed to notifications",
          });
          return;
        }

        fcmPayload = {
          notification: {
            title: values?.subject,
            body: values?.message,
          },
          tokens: fcmTokens,
        };
      } else if (radioValue === "All") {
        fcmPayload = {
          notification: {
            title: values?.subject,
            body: values?.message,
          },
          topic: "doctor",
        };
      }

      if (fcmPayload) {
        const myInit = {
          body: radioValue === "All" ? { message: fcmPayload } : fcmPayload,
        };
        await API.post("bazarhealthREST", url, myInit);
      }

      setIsModalOpen(false);
      setSuccessModel(true);
      form.resetFields();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error",
      });
      console.log(error);
    } finally {
      setNotificationLoading(false);
      setShowReceiverEmail(false);
      setShowEmployerDropdown(false);
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Action",
      "referal",
      "email",
      "name",
      "key",
      "searchByName",
    ];
    const filteredData = search(doctorData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleRadioChange = (e) => {
    const value = e.target.value;
    setRadioValue(value);
    if (value === "All") {
      setShowEmployerDropdown(false);
      setShowReceiverEmail(false);
    } else if (value === "Employer") {
      setShowEmployerDropdown(true);
    } else if (value === "Email") {
      setShowEmployerDropdown(false);
      setShowReceiverEmail(true);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Notification
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>
        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Send new notification
          </Button>
        </Row>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
        loading={loading}
      />

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            New Notification
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <Form
          name="basic"
          onFinish={sendNotification}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                label="Receiver"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please select receiver option",
                  },
                ]}
              >
                <Radio.Group onChange={handleRadioChange}>
                  <Radio value="All" className="p-font">
                    All
                  </Radio>
                  <Radio value="Employer" className="p-font">
                    Employer
                  </Radio>
                  <Radio value="Email" className="p-font">
                    Email
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>

            {showEmployerDropdown ? (
              <Col span={24} md={24} xs={24} sm={24}>
                <Form.Item
                  label="Select Employer"
                  name="employer"
                  rules={[
                    {
                      required: true,
                      message: "Please select employer",
                    },
                  ]}
                >
                  <Select
                    className="filter-border-selection"
                    allowClear
                    showSearch
                    placeholder="Select Employer"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {employers?.map((item) => (
                      <Select.Option key={item?.id} value={item?.id}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : showReceiverEmail ? (
              <Col span={24} md={24} xs={24} sm={24}>
                <Form.Item
                  label="Receiver Email Address"
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter receiver email",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                label="Subject"
                name="subject"
                rules={[
                  {
                    required: true,
                    message: "Please enter subject",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item
                label="Notification Message"
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please enter notification message",
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={notificationLoading}
                disabled={submitDisabled}
              >
                Send
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message="Notification sent successfully!"
        open={successModel}
      />
    </>
  );
}
