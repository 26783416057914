import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Tabs,
  Modal,
  Skeleton,
  message,
  Grid,
} from "antd";
import { StarFilled } from "@ant-design/icons";
import photos from "assets/icons/photos.svg";
import Container from "commons/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import overview from "assets/icons/overview.svg";
import appointment from "assets/icons/appointment.svg";
import ImagineCenterOverview from "../ImagineCenterOverview/ImagineCenterOverview";
import { Storage } from "aws-amplify";

import Chat from "pages/user/SupportChatbox/Chat";
import IcAppointmnet from "../Appointment/IcAppointmnet";
import { GetImgagingCenterDetails } from "utility/action";
import { useCallback } from "react";
import { GetPublicFileFromS3 } from "utility/uploadFileToS3";
import ImageGallery from "utility/ImageGallery";
import {
  END_TOUR,
  IMAGING_PROFILE_TOUR_START,
  IMAGING_TOUR_END,
} from "utility/constant";
import { useDispatch, useSelector } from "react-redux";
import { setTourSteps } from "store/actions/tourSteps";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";

export default function ImagineCenterProfile() {
  const [IcData, setIcData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch = useDispatch();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  // for chat box
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const location = useLocation();
  const imagingID = location?.state?.id;
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const [testimonialData, setTestimonialData] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const [preSignedImg, setPreSignedimg] = useState([]);
  const [icPhoto, seticPhoto] = useState(null);
  const [activeTab, setActiveTab] = useState("item-1");

  const [imgTour, setImgTour] = useState(false);
  const [imgTourIndex, setImgTourIndex] = useState(0);
  const search = location?.state?.search;
  const navigate = useNavigate();

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const fetchIcCenter = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const response = await GetImgagingCenterDetails(imagingID);
      const testimonialData = JSON.parse(response?.testimonial ?? "[]");
      setTestimonialData(testimonialData);

      if (response?.icProfilePhoto) {
        let icProfile;
        if (response?.icProfilePhoto.startsWith("http")) {
          icProfile = response?.icProfilePhoto;
        } else {
          icProfile = await GetPublicFileFromS3(response?.icProfilePhoto);
        }
        seticPhoto(icProfile);
      }
      if (response?.icPhotos) {
        const fileKeyy = response?.icPhotos;
        const urls = [];
        for (const key of fileKeyy) {
          try {
            const url = await Storage.get(key, {
              level: "public",
              expires: 86400,
            });
            urls.push(url);
          } catch (error) {
            console.log(`Error getting pre-signed URL for ${key}: ${error}`);
          }
        }
        setPreSignedimg(urls);
      }
      setIcData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === IMAGING_PROFILE_TOUR_START) {
        setImgTour(true);
      }
      setTimeout(() => {
        dispatch(setTourSteps(IMAGING_TOUR_END));
      }, 500);
    }
  }, [imagingID]);

  useEffect(() => {
    fetchIcCenter();
  }, [fetchIcCenter]);

  const items = [
    {
      label: (
        <span className="sub-header-font d-flex align-center">
          <img src={overview} className="tabs-icon" />
          {(activeTab === "item-1" && screens.xs) || screens.sm || screens.lg
            ? "Overview"
            : ""}
        </span>
      ),
      key: "item-1",
      children: <ImagineCenterOverview data={IcData} loader={skeletonLoader} />,
    },
    {
      label: (
        <span className="sub-header-font d-flex align-center">
          <img src={photos} className="tabs-icon" />
          {(activeTab === "item-3" && screens.xs) || screens.sm || screens.lg
            ? "Photos"
            : ""}
        </span>
      ),
      key: "item-3",
      children: <ImageGallery data={preSignedImg} />,
    },

    {
      label: (
        <span className="sub-header-font d-flex align-center">
          <img src={appointment} className="tabs-icon" />
          {(activeTab === "item-4" && screens.xs) || screens.sm || screens.lg
            ? "Request An Appointment"
            : ""}
        </span>
      ),
      key: "item-4",
      children: <IcAppointmnet data={IcData} />,
    },
  ];

  const handleEndTour = () => {
    setImgTour(false);
    dispatch(setTourSteps(END_TOUR));
  };

  const serviceSteps = [
    {
      target: ".img-first-step",
      content: (
        <TourStepContent
          heading="Imaging Center Profile"
          details="Simply click on different tabs to explore all the details
          related to the Imaging Center."
          numbers={{ currentStep: 9, totalSteps: 9 }}
          onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={serviceSteps}
        run={imgTour}
        stepIndex={imgTourIndex}
        setTourIndex={setImgTourIndex}
      />

      <Container>
        {/* Sub Header Starts */}
        <Breadcrumb className="hide-version-no">
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Home
            </Link>
          </Breadcrumb.Item>

          {!search && (
            <Breadcrumb.Item>
              <Link to="/imaging-center-list" className="p-font">
                Imaging Center List
              </Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <span className="p-font">{IcData?.icOfficeName}</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        {/* main content starts*/}
        <Card
          className="doctor-profile img-first-step mobile-card"
          onClick={() => {
            if (imgTour) {
              navigate("/");
              setImgTour(false);
            }
          }}
        >
          <Row>
            {skeletonLoader ? (
              <>
                {" "}
                <Skeleton avatar paragraph={{ rows: 2 }}></Skeleton>{" "}
              </>
            ) : (
              <>
                <Col span={24}>
                  <Row justify="space-between" gutter={[0, 20]}>
                    <div className="d-flex gap-4">
                      <img src={icPhoto} alt="" className="doctor-img" />
                      <div className="doctor-detail">
                        <h4 className="doctor-name header-font">
                          {IcData?.icOfficeName}
                        </h4>
                        {/* <h6>Reliant Medical Group (Shrewsbury)</h6> */}
                        <div className="d-flex gap-3">
                          <p className="p-font">
                            <img src={facebook} alt="facebook Icon" />{" "}
                            {IcData?.icFaceBookRating
                              ? IcData?.icFaceBookRating
                              : 0}{" "}
                            <StarFilled style={{ color: "#e7a500" }} />
                          </p>
                          <p className="p-font">
                            <img src={google} alt="facebook Icon" />{" "}
                            {IcData?.icGoogleRating
                              ? IcData?.icGoogleRating
                              : 0}{" "}
                            <StarFilled style={{ color: "#e7a500" }} />
                          </p>
                        </div>
                      </div>
                    </div>
                  </Row>
                </Col>
              </>
            )}
          </Row>
        </Card>
        <Card className="profile-tabs mobile-card">
          <Row>
            <Col span={24}>
              <Tabs items={items} onChange={handleTabChange} />
            </Col>
          </Row>
        </Card>
      </Container>

      
    
    </>
  );
}
