import React, { useCallback, useEffect, useState } from "react";
import { Table, Grid, Tag, Space, message, Button, Form } from "antd";
import { DeleteTwoTone, PauseCircleTwoTone } from "@ant-design/icons";
import DeleteModal from "utility/DeleteModal";
import { DoctorDropdown } from "./DoctorSelector";
import { ListDoctorByName } from "utility/action";
import moment from "moment";
import IndividualInvite from "containers/MainLayout/InviteModals/IndividualInvite";
import { API } from "aws-amplify";

export default function EpcPaymentTable(props) {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [data, setData] = useState([]);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [doctorList, setDoctorList] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [inviteDependent, setInviteDependent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberForm] = Form.useForm();
  console.log(props);
  const IndInvite = async (values) => {
    setLoading(true);
    try {
      console.log(values, "values");
      const myInit = {
        body: {
          email: values?.email,
          employeeID: props?.employeeData?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          phone: values?.phone,
          dob: moment(values?.dob).format("YYYY-MM-DD"),
          doctor: doctorList.find((item) => item?.name === values?.doctor),
        },
      };
      await API.post(
        "bazarhealthREST",
        `/superAdmin/inviteDepAndCharge`,
        myInit
      );

      memberForm.setFieldsValue({
        dob: null, // Set the date of birth field to null or an initial value
      });
      // Reset other fields
      memberForm.resetFields();

      // setDependentList([newDependent, ...dependentList]);
      setLoading(false);
      setInviteDependent(false);
      messageApi.open({
        type: "success",
        content: `Dependent added successfully`,
      });
    } catch (e) {
      setLoading(false);

      messageApi.open({
        type: "error",
        content: `${e?.response?.data?.message}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const handleCancel = () => {
    setDeleteModelPrompt(false);
  };

  const deleteUser = async () => {
    try {
      setDeleteLoading(true);
    } catch (error) {
      console.log(error);
    } finally {
      setDeleteLoading(false);
    }
  };

  function convertEnumToNormalCase(enumString) {
    // Convert to lowercase
    if (!enumString) {
      return "Payment Error";
    }
    let normalCaseString = enumString.toLowerCase();

    // Replace underscores with spaces
    normalCaseString = normalCaseString.replace(/_/g, " ");

    // Capitalize the first letter of each word
    normalCaseString = normalCaseString.replace(/\b\w/g, (char) =>
      char.toUpperCase()
    );

    return normalCaseString;
  }
  const fetchDoctor = useCallback(async () => {
    try {
      const filter = {
        isDpcDoctor: { eq: true },
      };

      const response = await ListDoctorByName(999, filter);
      setDoctorList(
        ...doctorList,
        response.map((item) => ({
          name: item?.doctorName,
          id: item?.id,
          startDate: item?.startDate,
        }))
      );
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  // Function to set initial data from a single object
  const setInitialData = (employee) => {
    return {
      key: employee?.id,
      fullName: `${employee.firstName} ${employee.lastName}`,
      role: "Parent",
      doctor: employee.dpcDoctor?.doctorName,
      status: convertEnumToNormalCase(employee?.DpcDetails?.accountStatus),
    };
  };

  // Function to process employee data
  const processEmployeeData = (employeeData) => {
    // Set Parent details for the table
    console.log(employeeData);
    const processedData = employeeData.map((employee, index) => ({
      key: employee?.id,
      fullName: `${employee.firstName} ${employee.lastName}`,
      role: "Dependent",
      doctor: employee.dpcDoctor?.doctorName,
      status: convertEnumToNormalCase(employee?.DpcDetails?.accountStatus),
    }));

    return processedData;
  };

  // Function to handle modal open
  const openDeleteModal = (record) => {
    setDeleteRecord(record);
    setDeleteModelPrompt(true);
  };

  // useEffect to handle changes in props.employeeData
  useEffect(() => {
    if (props?.employeeData) {
      // Set Parent details for the table
      // Set initial data from the first object
      const initialData = setInitialData(props.employeeData);

      const processedData = processEmployeeData(
        props.employeeData?.dpcMembers?.items
      );
      setData([initialData, ...processedData]);
    }
  }, [props?.employeeData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "fullName",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (data) => <span className="table-font">{data}</span>,
    },
    {
      title: "Doctor",
      dataIndex: "doctor",
      width: 250,
      render: (data, userData) => (
        <DoctorDropdown
          doctorList={doctorList}
          initialValue={data}
          userData={userData}
        />
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (data) => {
        return (
          <>
            <Tag color={data === "Active" ? "green" : "Red"} key={data}>
              {data}
            </Tag>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      // className: "actions",
      render: (_, record) => (
        <Space size="middle">
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              pointerEvents: "none",
            }}
          >
            <PauseCircleTwoTone />
          </span>
          <span
            style={{
              cursor: "pointer",
              display: "inline-block",
              pointerEvents: "none",
            }}
          >
            <DeleteTwoTone onClick={() => openDeleteModal(record)} />
          </span>
        </Space>
      ),
    },
  ];

  return (
    <>
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px 0 0px 0 ",
        }}
      >
        <span
          style={{
            color: "grey",
            fontWeight: "500",
            fontSize: "16px",
          }}
        >
          Users List
        </span>
        <Button
          className="blue-btn inherit-btn"
          onClick={() => setInviteDependent(true)}
          style={{
            backgroundColor: "white",
            borderRadius: "6px",
            color: "black",
            fontWeight: "600",
          }}
        >
          Add dependent
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        className="common-table"
        pagination={false}
        scroll={{ x: screens.xs ? 992 : "" }}
      />

      <DeleteModal
        content={`Are you sure you want to remove ${deleteRecord?.fullName}?`}
        open={deleteModelPrompt}
        onClick={deleteUser}
        buttonLoading={deleteLoading}
        onCancel={handleCancel}
        buttonText="Yes"
      />

      <IndividualInvite
        title="Add your Dependent"
        open={inviteDependent}
        onOk={() => setInviteDependent(false)}
        onCancel={() => setInviteDependent(false)}
        loading={loading}
        IndInvite={IndInvite}
        memberForm={memberForm}
        doctorList={doctorList}
        intialDoctor={props?.employeeData?.dpcDoctor?.doctorName}
      />
    </>
  );
}
