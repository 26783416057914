import externalLink from "assets/icons/external-link.svg";
import React, { useState, useRef, useEffect } from "react";
import { Button, Row, Col, Carousel, Skeleton } from "antd";
import { LeftOutlined, RightOutlined, StarFilled } from "@ant-design/icons";
import quote from "assets/icons/quote.svg";
import SkeletonBlock from "utility/SkeletonBlocks";

export default function Overview(props) {
  const [testimonial, setTestimonial] = useState([
    { testimonialName: "", testimonialRating: "", review: "" },
  ]);

  const surgeryData = props?.data;
  const ttt = props?.data?.testimonial;
  const ref = useRef();

  useEffect(() => {
    const testimonialData = JSON.parse(ttt ?? "[]");
    setTestimonial(testimonialData);
  }, [ttt]);

  return (
    <>
      {props?.loader ? (
        <>
          <Skeleton title={false} paragraph={{ rows: 4 }}></Skeleton>{" "}
          <SkeletonBlock style={{ width: 1100, height: 200 }} />
          <Skeleton
            title={false}
            paragraph={{ rows: 4 }}
            className="mt-2"
          ></Skeleton>{" "}
        </>
      ) : (
        <>
          <div className="doctor-overview">
            <b className="sub-header-font">About Center</b>
            <p className="p-font">
              {surgeryData?.aboutSurgeryCenter
                ? surgeryData?.aboutSurgeryCenter
                : "-"}
            </p>

            <b className="sub-header-font">Specialty</b>
            <p className="p-font">
              {surgeryData?.specailtyAndBodyPart?.map((specialty, index) => (
                <div key={index}>
                  {specialty.specialty} -{" "}
                  <span style={{ color: "#646464", fontWeight: "400" }}>
                    {specialty.surgeryCenterSpeciality.slice(1, -1)}
                  </span>
                </div>
              ))}
            </p>

            <b className="sub-header-font">Address</b>
            <p className="p-font">
              {surgeryData?.surgeryCenterAddLine1
                ? surgeryData?.surgeryCenterAddLine1
                : "-"}{" "}
              {surgeryData?.surgeryCenterAddLine2
                ? surgeryData?.surgeryCenterAddLine2
                : "-"}{" "}
              {surgeryData?.surgeryCenterAddLine3
                ? surgeryData?.surgeryCenterAddLine3
                : "-"}{" "}
              {surgeryData?.surgeryCenterZip
                ? surgeryData?.surgeryCenterZip
                : "-"}
            </p>

            {surgeryData?.surgeryMapSource ? (
              <iframe
                src={surgeryData?.surgeryMapSource}
                height="200"
                width="100%"
                allowFullScreen=""
                loading="lazy"
                className="border-0 mb-2 mt-1"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            ) : (
              ""
            )}

            <a
              target="_blank"
              href={surgeryData?.surgeryCenterWebsite}
              className="sub-header-font"
            >
              Go to Provider's Website{" "}
              <img src={externalLink} alt="" className="h-20" />
            </a>

            <div className="surgical-testimonial">
              <div className="d-flex justify-content-between">
                <b className="sub-header-font">Testimonials</b>
                <div className="testimonial-nav">
                  <Button
                    icon={<LeftOutlined />}
                    onClick={() => {
                      ref.current.prev();
                    }}
                    className="nxt-prv-btn mr-1"
                  />
                  <Button
                    icon={<RightOutlined />}
                    onClick={() => {
                      ref.current.next();
                    }}
                    className="nxt-prv-btn"
                  />
                </div>
              </div>
              <div className="testimonial-card">
                <Carousel
                  autoplay
                  draggable
                  ref={ref}
                  arrows={true}
                  prevArrow={
                    <LeftOutlined className="ant-carousel-btn prev-carousel" />
                  }
                  nextArrow={
                    <RightOutlined className="button ant-carousel-btn next-carousel" />
                  }
                >
                  <div>
                    <Row gutter={20}>
                      {testimonial.map((reviews) => (
                        <>
                          <Col md={8} sm={24} xs={24}>
                            <div className="testimonial-single">
                              <img src={quote} alt="Quote Icon" />
                              <p className="p-font">{reviews?.review}</p>
                              <b className="p-font">
                                {reviews?.review}{" "}
                                <span>{reviews?.testimonialRating}</span>
                              </b>
                              <StarFilled />
                            </div>
                          </Col>
                        </>
                      ))}
                    </Row>
                  </div>
                </Carousel>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
