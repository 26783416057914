import {
  Row,
  Typography,
  Input,
  Button,
  Table,
  Dropdown,
  Menu,
  Modal,
  Form,
  Select,
  Col,
  message,
  Tabs,
  Space,
  Popover,
  Empty,
  Tag,
} from "antd";
import React, { useEffect, useState } from "react";
import successDelete from "assets/icons/successDelete.svg";
import toggle from "assets/icons/downToggle.svg";
import empty from "assets/delphy/empty.svg";
import upToggle from "assets/icons/upToggle.svg";
import exportCSV from "assets/icons/exportcsv.svg";
import deleteIC from "assets/icons/deleteIC.svg";
import deleteIcon from "assets/icons/delete.svg";
import { MoreOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { API } from "aws-amplify";
import { disableSubmitButton, emailPattern } from "utility/constant";
import SuccessModel from "utility/SuccessModel";
import {
  ListCompanyRegis,
  ListCutomEmployeesRegis,
  ListEligibleMembers,
  ListEmployeesRegis,
  ListMembers,
} from "utility/action";
import { useCallback } from "react";
import NotRegisteredEmployees from "./NotRegisteredEmployees";
import { CSVLink } from "react-csv";
import search from "utility/search";
import TableSkeleton from "utility/TableSkeleton";

export default function Employees() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [employers, setEmployers] = useState([]);
  const [nestedData, setNestedData] = useState({});
  const [isLoading, setIsLoading] = useState({});
  const [employerLoading, setEmployerLoading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);
  const [empLoading, setEmpLoading] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [dataSource, setDataSource] = useState(employees);
  const [companyID, setCompanyID] = useState("");
  const [successModel, setSuccessModel] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRow, setSelectedRow] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [indDeleteLoading, setIndDeleteLoading] = useState(false);
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [deleteMultipleModal, setDeleteMultipleModal] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [employerValue, setEmployerValue] = useState(null);
  const [billingValue, setBillingValue] = useState(null);
  const [billingModeValue, setBillingModeValue] = useState(null);
  const [roleValue, setRoleValue] = useState(null);
  const [csvData, setCsvData] = useState([]);
  const [form] = Form.useForm();
  const [popOverform] = Form.useForm();
  const { Option } = Select;

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const dpcDoctorID = searchParams.get("dpcDoctorID");

  const [popoverVisible, setPopoverVisible] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const [submitDisabled, setSubmitDisabled] = useState(true);
  const handleFormChange = disableSubmitButton(
    dpcDoctorID ? ["employeeEmail"] : ["employeeEmail", "companyID"]
  )(form, setSubmitDisabled);

  const handleOk = () => {
    setIsModalOpen(false);
    setDeleteModelPrompt(false);
    setDeleteSuccess(false);
    setDeleteMultipleModal(false);
    form.resetFields();
    setSubmitDisabled(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setDeleteModelPrompt(false);
    setDeleteSuccess(false);
    setDeleteMultipleModal(false);
    form.resetFields();
    setSubmitDisabled(true);
  };

  //fetch employee details
  const setEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      searchByName: row?.searchByName,
      group: row?.group,
      Username: (
        <div>
          {row?.emailID}
          <br />
          <span className="last-login">
            {row?.lastLogin ? (
              <>
                Last Login{" "}
                {(() => {
                  const currentTime = new Date();
                  const lastLoginTime = new Date(row?.lastLogin);
                  const timeDifference = currentTime - lastLoginTime;
                  const minutesAgo = Math.round(timeDifference / 1000 / 60);
                  if (minutesAgo < 1) {
                    return "Just now";
                  } else if (minutesAgo < 60) {
                    return `${minutesAgo} minutes ago`;
                  } else if (minutesAgo < 1440) {
                    return `${Math.floor(minutesAgo / 60)} hours ago`;
                  } else {
                    return (
                      "on" +
                      " " +
                      new Date(row.lastLogin).toLocaleDateString() +
                      " " +
                      new Date(row.lastLogin).toLocaleTimeString()
                    );
                  }
                })()}
              </>
            ) : (
              ""
            )}
          </span>
        </div>
      ),
      searchEmail: row?.emailID,
      Employer: row?.companyID?.name ? row?.companyID?.name : "-",
      FirstName: row?.profile?.firstName
        ? row?.profile?.firstName
        : row?.firstName
        ? row?.firstName
        : "-",
      LastName: row?.profile?.lastName
        ? row?.profile?.lastName
        : row?.lastName
        ? row?.lastName
        : "-",
      Role:
        row?.userType === "CORPORATE_ADMIN" && row.group !== "Member"
          ? "ADMIN"
          : row?.userType === "INDIVIDUAL" && row.group !== "Member"
          ? "INDIVIDUAL"
          : row?.userType === "CORPORATE" && row.group !== "Member"
          ? "EMPLOYEE"
          : row.group === "Member"
          ? "DEPENDENT"
          : "-",
      Profile: row?.profile,
      Member: row?.members,
      Active: dpcDoctorID ? (
        row?.DpcDetails?.accountStatus === "ACTIVE" ? (
          <Tag color="green">PAID</Tag>
        ) : row?.epcCheckoutLink?.expiry * 1000 < Date.now() ? (
          <Tag color="red">Link Expired</Tag>
        ) : (
          <Tag color="orange">UNPAID</Tag>
        )
      ) : row?.accountStatus === "ACTIVE" ? (
        "ACTIVE"
      ) : (
        "INACTIVE"
      ),
      billingMode: row?.billing_status === "NOT_REQUIRED" ? "MANUAL" : "STRIPE",
    };
  };

  const fetchEmployeeData = useCallback(async () => {
    setEmpLoading(true);
    const filter = {
      lastLogin: { attributeExists: true },
    };

    const dpcFilter = {
      dpcDoctorID: { contains: dpcDoctorID },
    };

    const [employees, members] = await Promise.all([
      ListEmployeesRegis(dpcDoctorID ? dpcFilter : filter, 999),
      dpcDoctorID ? ListMembers(dpcFilter, 999) : Promise.resolve([]),
    ]);

    const empData = employees.map((row) => setEmployeesRow(row));

    // Only create memData if dpcDoctorID is truthy
    const memData = dpcDoctorID
      ? members.map((row) => setEmployeesRow(row))
      : [];

    const mergedData = [...empData, ...memData];

    setEmployees(mergedData);
    setDataSource(mergedData);
    setCsvData(mergedData);

    setEmpLoading(false);
  }, []);

  const fetchEmployersData = useCallback(async () => {
    await ListCompanyRegis()
      .then((response) => {
        setEmployers(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleSearchEmp = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Username",
      "Employer",
      "FirstName",
      "LastName",
      "Role",
      "billingMode",
      "Active",
      "searchEmail",
    ];
    const filteredData = search(employees, currValue, searchFields);
    setDataSource(filteredData);
  };

  const createEmployee = async (values) => {
    try {
      setCreateLoading(true);
      const myInit = {
        body: {
          companyId: values?.companyID,
          dpcDoctorID: dpcDoctorID,
          email: values?.employeeEmail?.toLowerCase(),
          firstName: values?.firstName,
          lastName: values?.lastName,
        },
      };

      await API.post(
        "bazarhealthREST",
        dpcDoctorID
          ? `/superAdmin/invite_employee_dpc`
          : `/superAdmin/invite_employee`,
        myInit
      );
      setDataSource([]);
      fetchEmployeeData();
      setCreateLoading(false);
      form.resetFields();
      setIsModalOpen(false);
      setSuccessModel(true);
      setSubmitDisabled(true);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: " An account with the given email already exists.",
      });
      setCreateLoading(false);
    }
  };

  useEffect(() => {
    fetchEmployersData();
  }, [fetchEmployersData]);

  useEffect(() => {
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  const rowSelection = {
    onChange: (selectedRowKeys) => {
      setSelectedRow(selectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      name: record.BusinessName,
    }),
  };

  //for deleting multiple Employees
  const deleteMultiple = async () => {
    try {
      setDeleteLoading(true);
      if (selectedRow.length === 0) {
        messageApi.open({
          type: "error",
          content: "No Member Selected",
        });
      } else {
        const myInit = {
          body: {
            id: selectedRow,
          },
        };

        await API.post(
          "bazarhealthREST",
          `/superAdmin/delete_employee`,
          myInit
        );

        const newArray = dataSource.filter(function (obj) {
          return !selectedRow.includes(obj.key);
        });

        setDataSource(newArray);
        setEmployees(newArray);
        setSelectedRow([]);
        fetchEmployeeData();
        setDeleteMultipleModal(false);
        setDeleteSuccess(true);
      }
      setDeleteLoading(false);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Error Deleting Employees",
        duration: 5,
      });
      setDeleteLoading(false);
      setDeleteMultipleModal(false);
    }
  };

  //for deleting individual users
  const deleteUser = async (id) => {
    try {
      setIndDeleteLoading(true);
      const myInit = {
        body: {
          id: [deleteId[0]],
          type: deleteId[1],
        },
      };
      console.log(myInit);
      await API.post("bazarhealthREST", `/superAdmin/delete_employee`, myInit);
      const newArray = dataSource.filter(function (obj) {
        console.log(obj, "key");
        return obj.key !== deleteId[0];
      });
      setIndDeleteLoading(false);
      setDataSource(newArray);
      setEmployees(newArray);
      setDeleteId("");
      // fetchEmployeeData();
      setDeleteModelPrompt(false);
      setDeleteSuccess(true);
    } catch (error) {
      messageApi.open({
        key: "delete",
        type: "error",
        content: "Error Deleting Member",
        duration: 5,
      });
      setIndDeleteLoading(false);
      setDeleteSuccess(false);
      setDeleteModelPrompt(false);
    }
  };

  const handleMultipleDelete = (e) => {
    e.preventDefault();
    if (selectedRow.length === 0) {
      messageApi.open({
        type: "error",
        content: "No Member Selected",
      });
    } else {
      setDeleteMultipleModal(true);
    }
  };

  const columns = [
    {
      title: "Member Email",
      dataIndex: "Username",
      width: "24%",
    },
    {
      title: "Employer",
      dataIndex: "Employer",
      width: "20%",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      width: "11%",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      width: "11%",
    },
    {
      title: "Role",
      dataIndex: "Role",
      width: "12%",
    },
    {
      title: "Billing Mode",
      dataIndex: "billingMode",
      width: "11%",
    },
    {
      title: "Billing Status",
      dataIndex: "Active",
      width: "11%",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      width: "4.5%",
      render: (id, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link
                    rel="noopener noreferrer"
                    to="/member-detail"
                    state={{ id: [id], member: record?.group }}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId([id]);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const dpcColumns = [
    {
      title: "Member Email",
      dataIndex: "Username",
      width: "24%",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
      width: "11%",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
      width: "11%",
    },
    {
      title: "Role",
      dataIndex: "Role",
      width: "12%",
    },
    {
      title: "Billing Mode",
      dataIndex: "billingMode",
      width: "11%",
    },
    {
      title: "Billing Status",
      dataIndex: "Active",
      width: "11%",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      width: "4.5%",
      render: (id, record) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key="1">
                  <Link
                    rel="noopener noreferrer"
                    to="/member-detail?dpc"
                    state={{ id: [id], member: record?.group, from: "DPC" }}
                  >
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key="2">
                  <Link
                    rel="noopener noreferrer"
                    onClick={(e) => {
                      e.preventDefault();
                      setDeleteModelPrompt(true);
                      setDeleteId([id, record?.Role]);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const headers = [
    { label: "Member Email", key: "searchEmail" },
    { label: "Employer", key: "Employer" },
    { label: "First Name", key: "FirstName" },
    { label: "Last Name", key: "LastName" },
    { label: "Role", key: "Role" },
    { label: "Billing Mode", key: "Active" },
    { label: "Billing Status", key: "billingMode" },
  ];

  const csvExp = csvData.map((row) => ({
    searchEmail: row?.searchEmail,
    Employer: row?.Employer ? row?.Employer : "-",
    FirstName: row?.FirstName ? row?.FirstName : "-",
    LastName: row?.LastName ? row?.LastName : "-",
    Role: row?.Role,
    Active: row?.Active,
    billingMode: row?.billingMode,
  }));

  const expandedRowRender = (record) => {
    const inline = [
      {
        title: "Member Email",
        dataIndex: "emailID", // Map to "emailID" property
        width: "24%",
      },
      {
        title: "Employer",
        dataIndex: "employer",
        data: "",
        width: "20%",
      },
      {
        title: "First Name",
        dataIndex: "firstName",
        width: "11%",
      },
      {
        title: "Last Name",
        dataIndex: "lastName",
        width: "11%",
      },
      {
        title: "Role",
        dataIndex: "role",
        width: "11%",
      },
      {
        title: "Billing Mode",
        dataIndex: "billingMode", // Assuming "billingMode" property is available
        width: "11%",
      },
      {
        title: "Billing Status",
        dataIndex: "billing_status", // Map to "billing_status" property
        width: "11%",
      },
      {
        title: "",
        dataIndex: "Action",
        className: "actions",
        width: "6%",
        render: (id, record) => {
          return (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="1">
                    <Link
                      rel="noopener noreferrer"
                      to="/member-detail"
                      state={{
                        id: [record.id, record.type],
                        member: record?.type,
                      }}
                    >
                      Edit
                    </Link>
                  </Menu.Item>
                  {/* Not Giving delete permission for HPI */}
                  {record?.type === "HPI" || !record.lastLogin ? (
                    ""
                  ) : (
                    <Menu.Item key="2">
                      <Link
                        rel="noopener noreferrer"
                        onClick={() => {
                          setDeleteModelPrompt(true);
                          setDeleteId([record.id, "Dependent"]);
                        }}
                      >
                        Delete
                      </Link>
                    </Menu.Item>
                  )}
                </Menu>
              }
              overlayClassName="download-btn"
              placement="bottomRight"
            >
              <MoreOutlined />
            </Dropdown>
          );
        },
      },
    ];

    const data = nestedData[record.key];
    return (
      <Table
        className="common-table hide-table-head empty-img"
        columns={inline}
        loading={isLoading[record.key] && !data}
        scroll={{ x: 992 }}
        dataSource={nestedData[record.key]}
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                description="No dependents dound"
                style={{ fontSize: "15px" }}
              />
            </>
          ),
        }}
      />
    );
  };

  //This function is called when the dropdown is opened, So it adds the data to the table
  const handleExpand = async (expanded, record) => {
    if (expanded && !nestedData[record.key]) {
      setIsLoading({
        [record.key]: true,
      });
      let dependentList = [];
      const filterE = {
        email: { eq: record?.Profile?.email },
      };
      const response = await ListEligibleMembers(filterE, 999);
      if (response) {
        if (response.length > 0) {
          let memID = response[0]?.memberIDSeq.slice(0, -1);
          const filter = {
            memberIDSeq: { contains: memID },
            memberSfx: { ne: 0 },
            or: {
              registered: { eq: false },
              invited: { eq: false },
            },
          };
          dependentList = await ListEligibleMembers(filter, 999);

          dependentList = dependentList.map((item) => ({
            emailID: item?.email ? item.email : "-",

            // employer: record?.Employer,
            firstName: item?.memberFirstName,
            lastName: item?.memberLastName,
            role: "Dependent",
            billingMode: record?.billingMode,
            billing_status: "INACTIVE",
            type: "HPI",
            id: item?.id,
          }));
        }
      }

      const childData = (record.Member?.items || []).map((item) => ({
        ...item,
        emailID: (
          <div>
            {item?.emailID ? item.emailID : "-"}
            <br />
            <span className="last-login">
              {item?.lastLogin ? (
                <>
                  Last Login{" "}
                  {(() => {
                    const currentTime = new Date();
                    const lastLoginTime = new Date(item?.lastLogin);
                    const timeDifference = currentTime - lastLoginTime;
                    const minutesAgo = Math.round(timeDifference / 1000 / 60);
                    if (minutesAgo < 1) {
                      return "Just now";
                    } else if (minutesAgo < 60) {
                      return `${minutesAgo} minutes ago`;
                    } else if (minutesAgo < 1440) {
                      return `${Math.floor(minutesAgo / 60)} hours ago`;
                    } else {
                      return (
                        "on" +
                        " " +
                        new Date(item.lastLogin).toLocaleDateString() +
                        " " +
                        new Date(item.lastLogin).toLocaleTimeString()
                      );
                    }
                  })()}
                </>
              ) : (
                ""
              )}
            </span>
          </div>
        ),
        role: "Dependent",
        billingMode: record?.billingMode,
        billing_status:
          item?.accountStatus === "ACTIVE" ? "ACTIVE" : "INACTIVE",
        // employer: record?.Employer,
        type: "Member",
      }));

      setNestedData((state) => ({
        ...state,
        [record.key]: [...childData, ...dependentList],
      }));

      setIsLoading({
        [record.key]: false,
      });
    }
  };

  const closeTags = (value) => {
    if (value === billingModeValue) {
      setBillingModeValue(undefined);
      setDataSource(employees);
      popOverform.setFieldsValue({
        billingMode: undefined,
      });
    } else if (value === roleValue) {
      setDataSource(employees);
      setRoleValue(undefined);
      popOverform.setFieldsValue({
        role: undefined,
      });
    } else if (value === billingValue) {
      setDataSource(employees);
      setBillingValue(undefined);
      popOverform.setFieldsValue({
        billingStatus: undefined,
      });
    } else if (value === employerValue) {
      setDataSource(employees);
      setEmployerValue(undefined);
      popOverform.setFieldsValue({
        employerName: undefined,
      });
    }
  };

  const clearForm = () => {
    setDataSource(employees);
    popOverform.resetFields();
    setBillingModeValue(undefined);
    setEmployerValue(undefined);
    setRoleValue(undefined);
    setBillingValue(undefined);
  };

  const handleChange = async (type, value) => {
    setPopoverVisible(false);
    switch (type) {
      case "employer":
        setEmployerValue(value);
        setEmployerLoading(true);
        if (!value) {
          setDataSource(employees);
        } else {
          const filter = {
            companyRegiEmployeeId: {
              contains: employers?.find((item) => item?.name === value)?.id,
            },
            lastLogin: { attributeExists: true },
          };
          try {
            const response = await ListEmployeesRegis(filter);
            setCompanyID(employers?.find((item) => item?.name === value)?.id);
            const empData = response.map((row) => setEmployeesRow(row));

            setDataSource(empData);
          } catch (error) {
            console.log(error);
          }
        }
        setEmployerLoading(false);
        break;

      case "status":
        setBillingValue(value);
        setStatusLoading(true);
        if (value === "ACTIVE") {
          const filter = {
            accountStatus: { eq: value },
            lastLogin: { attributeExists: true },
            companyRegiEmployeeId: { contains: companyID },
          };
          try {
            const response = await ListEmployeesRegis(filter);
            const empData = response.map((row) => setEmployeesRow(row));
            setDataSource(empData);
          } catch (error) {
            console.log(error);
          }
        } else if (value === "INACTIVE") {
          const filter = {
            accountStatus: { ne: "ACTIVE" },
            lastLogin: { attributeExists: true },
            companyRegiEmployeeId: { contains: companyID },
          };
          try {
            const response = await ListEmployeesRegis(filter);
            const empData = response.map((row) => setEmployeesRow(row));
            setDataSource(empData);
          } catch (error) {
            console.log(error);
          }
        } else {
          setDataSource(employees);
        }
        setStatusLoading(false);
        break;

      case "billingMode":
        setEmployerLoading(true);

        setBillingModeValue(value);
        if (value === "STRIPE") {
          if (companyID) {
            const filteredData = employees.filter(
              (obj) =>
                obj?.billingMode === "STRIPE" && obj?.Action === companyID
            );
            setDataSource(filteredData);
          } else {
            const filteredData = employees.filter(
              (obj) => obj?.billingMode === "STRIPE"
            );
            setDataSource(filteredData);
          }
        } else if (value === "MANUAL") {
          const filteredData = employees.filter(
            (obj) => obj?.billingMode === "MANUAL"
          );
          setDataSource(filteredData);
        } else {
          setDataSource(employees);
        }
        setEmployerLoading(false);
        break;

      case "role":
        setEmployerLoading(true);

        setRoleValue(value);
        if (value === "ADMIN") {
          const filteredData = employees.filter((obj) => obj?.Role === "ADMIN");
          setDataSource(filteredData);
        } else if (value === "EMPLOYEE") {
          const filteredData = employees.filter(
            (obj) => obj?.Role === "EMPLOYEE"
          );
          setDataSource(filteredData);
        } else if (value === "DEPENDENT") {
          const filteredData = employees.filter(
            (obj) => obj?.Role === "DEPENDENT"
          );
          setDataSource(filteredData);
        } else if (value === "INDIVIDUAL") {
          const filteredData = employees.filter(
            (obj) => obj?.Role === "INDIVIDUAL"
          );
          setDataSource(filteredData);
        } else {
          setDataSource(employees);
        }
        setEmployerLoading(false);
        break;

      default:
        break;
    }
  };

  const content = (
    <div className="popover-form">
      <Form layout="vertical" form={popOverform}>
        <Row justify="center" align="middle" gutter={[10, 30]}>
          <Col>
            <Form.Item name="employerName">
              <Select
                className="filter-border-selection"
                allowClear
                showSearch
                disabled={empLoading}
                value={employerValue}
                placeholder="Select Employer"
                style={{ width: 200 }}
                onChange={(value) => handleChange("employer", value)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                {employers.map((item) => (
                  <Select.Option key={item?.id} value={item?.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="role">
              <Select
                className="filter-border-selection"
                allowClear
                placeholder="Role"
                onChange={(value) => handleChange("role", value)}
                style={{ width: 110 }}
              >
                <Option value="ADMIN" className="filter-option">
                  ADMIN
                </Option>
                <Option value="EMPLOYEE" className="filter-option">
                  EMPLOYEE
                </Option>
                <Option value="INDIVIDUAL" className="filter-option">
                  INDIVIDUAL
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="billingMode">
              <Select
                className="filter-border-selection"
                allowClear
                style={{ width: 125 }}
                placeholder="Billing Mode"
                maxTagCount="responsive"
                onChange={(value) => handleChange("billingMode", value)}
                value={billingModeValue || undefined}
              >
                <Option value="STRIPE" className="filter-option">
                  STRIPE
                </Option>
                <Option value="MANUAL" className="filter-option">
                  MANUAL
                </Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="billingStatus">
              <Select
                className="filter-border-selection"
                allowClear
                placeholder="Billing Status"
                style={{ width: 130 }}
                value={billingValue}
                onChange={(value) => handleChange("status", value)}
                getPopupContainer={(trigger) => trigger.parentNode}
              >
                <Option value="ACTIVE" className="filter-option">
                  ACTIVE
                </Option>
                <Option value="INACTIVE" className="filter-option">
                  INACTIVE
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col>
            <Row justify="start" align="middle" style={{ height: "100%" }}>
              <span
                onClick={clearForm}
                style={{
                  fontWeight: "600",
                  color: "#00406A",
                  fontSize: "16px",
                }}
              >
                Clear
              </span>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );

  const handleOpenChange = (newOpen) => {
    setPopoverVisible(newOpen);
  };

  return (
    <>
      {contextHolder}

      <Tabs className="employer-tabs" defaultActiveKey="1" centered>
        <Tabs.TabPane
          tab={`${dpcDoctorID ? "Joined Members" : "Registered Members"}`}
          key="1"
        >
          <Row justify="space-between table-header"></Row>
          <Row justify="space-between table-header">
            <Row align="middle">
              <Input
                prefix={<SearchOutlined />}
                placeholder="Search"
                className="searchbar"
                onChange={handleSearchEmp}
                style={{ width: 250 }}
              />
            </Row>

            <Row className="gap-10 mtr-20 bold-font">
              {!dpcDoctorID ? (
                <Popover
                  open={popoverVisible}
                  trigger="click"
                  content={content}
                  title=""
                  placement="bottomRight"
                  color="#F2F9FD"
                  onOpenChange={handleOpenChange}
                >
                  <Button className="blue-border-btn inherit-btn">
                    Add Filters
                  </Button>
                </Popover>
              ) : (
                ""
              )}
              <CSVLink
                data={csvExp}
                headers={headers}
                filename={"Registered Members.csv"}
                className="export-btn"
              >
                <img src={exportCSV} alt="" className="action-icons" />
              </CSVLink>

              <Link>
                <img
                  src={deleteIC}
                  onClick={handleMultipleDelete}
                  alt=""
                  className="action-icons"
                />
              </Link>
              <Button className="blue-btn inherit-btn" onClick={showModal}>
                Add Member
              </Button>
            </Row>
          </Row>

          {employerValue || billingValue || billingModeValue || roleValue ? (
            <Row className="mt-15 tags-filter">
              <p>
                {" "}
                Filter :{" "}
                {employerValue && (
                  <Tag closable onClose={() => closeTags(employerValue)}>
                    {employerValue}
                  </Tag>
                )}
                {billingValue && (
                  <Tag closable onClose={() => closeTags(billingValue)}>
                    {billingValue}
                  </Tag>
                )}
                {billingModeValue && (
                  <Tag closable onClose={() => closeTags(billingModeValue)}>
                    {billingModeValue}
                  </Tag>
                )}
                {roleValue && (
                  <Tag closable onClose={() => closeTags(roleValue)}>
                    {roleValue}
                  </Tag>
                )}
              </p>
            </Row>
          ) : (
            ""
          )}

          {empLoading || employerLoading || statusLoading ? (
            <TableSkeleton />
          ) : (
            <>
              {dpcDoctorID ? (
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={dpcColumns}
                  dataSource={dataSource}
                  defaultPageSize={100}
                  sticky
                  className="common-table emp-table"
                  scroll={{ x: 992 }}
                  pagination={{
                    position: ["", "bottomCenter"],
                    defaultPageSize: 100,
                  }}
                />
              ) : (
                <Table
                  rowSelection={{
                    type: "checkbox",
                    ...rowSelection,
                  }}
                  columns={columns}
                  dataSource={dataSource}
                  sticky
                  className="common-table emp-table"
                  scroll={{ x: 992 }}
                  pagination={{ position: ["", "bottomCenter"] }}
                  expandedRowRender={(parentRecord) =>
                    expandedRowRender(parentRecord)
                  }
                  onExpand={handleExpand}
                  expandable={{
                    expandIcon: ({ expanded, onExpand, record }) =>
                      expanded ? (
                        <img
                          src={upToggle}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ) : (
                        <img
                          src={toggle}
                          onClick={(e) => onExpand(record, e)}
                        />
                      ),
                  }}
                />
              )}
            </>
          )}
        </Tabs.TabPane>

        {dpcDoctorID ? (
          ""
        ) : (
          <Tabs.TabPane tab="Non Registered Members" key="2">
            <NotRegisteredEmployees />
          </Tabs.TabPane>
        )}
      </Tabs>

      <Modal
        className="form-modal"
        width={700}
        centered
        footer={null}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Member
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={createEmployee}
          autoComplete="off"
          layout="vertical"
          form={form}
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Member email"
                name="employeeEmail"
                rules={[
                  {
                    required: true,
                    message: "Please input member email",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            {!dpcDoctorID && (
              <Col span={12} md={12} xs={24} sm={24}>
                <Form.Item
                  label="Employer"
                  name="companyID"
                  rules={[
                    {
                      required: true,
                      message: "Please select any employer",
                    },
                  ]}
                >
                  <Select
                    allowClear
                    placeholder="Select Employer"
                    getPopupContainer={(trigger) => trigger.parentNode}
                  >
                    {employers.map((item) => (
                      <Select.Option
                        value={item.id}
                      >{`${item.name}`}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="First name"
                name="firstName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid first name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Last name"
                name="lastName"
                rules={[
                  {
                    pattern: /^[a-zA-Z]+$/,
                    message: "Please enter a valid last name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteModelPrompt}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        className="reward-success-modal "
        footer={null}
      >
        {console.log(window.location.href, "4")}
        <img src={deleteIcon} alt="Delete Img" />
        <h6>Are you sure ?</h6>
        <p>If Yes, it will delete an member permanently.</p>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={deleteUser}
            loading={indDeleteLoading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleCancel}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>

      <Modal
        title="Basic Modal"
        open={deleteMultipleModal}
        onOk={handleOk}
        centered
        width={600}
        onCancel={handleCancel}
        className="reward-success-modal "
        footer={null}
      >
        <img src={deleteIcon} alt="Delete Img" />
        <h6>
          Are you sure you want to delete all these{" "}
          <span style={{ color: "red", fontStyle: "bold" }}>
            {selectedRow.length}
          </span>{" "}
          members?
        </h6>

        <Space>
          <Button
            type="primary"
            className="theme-btn"
            style={{ width: "106px" }}
            onClick={deleteMultiple}
            loading={deleteLoading}
          >
            Yes
          </Button>
          <Button
            type="primary"
            className="theme-btn "
            onClick={handleCancel}
            style={{ width: "106px" }}
          >
            Cancel
          </Button>
        </Space>
      </Modal>

      <SuccessModel
        message="Delete Successful!"
        open={deleteSuccess}
        imageSrc={successDelete}
      />

      <SuccessModel message="Member Added Successfully!" open={successModel} />
    </>
  );
}
