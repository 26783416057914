import React from "react";
import { Tabs } from "antd";
import OpenAppointments from "./OpenAppointments";
import CloseAppointments from "./CloseAppointments";

export default function AppointmentTab() {
  return (
    <>
      <Tabs className="employer-tabs" defaultActiveKey="1" centered>
        <Tabs.TabPane tab="Open Appointments" key="1">
          <OpenAppointments />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Close Appointments" key="2">
          <CloseAppointments />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
