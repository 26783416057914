import React from "react";
import { useLocation } from "react-router-dom";
import DoctorList from "pages/user/Doctors/DoctorList";
import { Skeleton } from "antd";

export default function CenterDoctorList() {
  const location = useLocation();
  const docDatat = location?.state?.data;
  const loader = location?.state?.loader;

  return (
    <>
      {loader ? (
        <Skeleton
          title={false}
          paragraph={{ rows: 10 }}
          className="mt-2"
        ></Skeleton>
      ) : docDatat ? (
        <DoctorList data={docDatat} />
      ) : (
        ""
      )}
    </>
  );
}
