// query MyQuery($id: ID = “”) {
//     getCompanyRegi(id: $id) {
//       name
//     }
//   }

export const getCompanyRegi = /* GraphQL */ `
  query GetCompanyRegi($id: ID!) {
    getCompanyRegi(id: $id) {
      name
    }
  }
`;

export const listEmployeeRegis = /* GraphQL */ `
  query ListEmployeeRegis(
    $filter: ModelEmployeeRegiFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEmployeeRegis(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        searchByName
        employeeEmail
        emailType
        verified
        role
        userType
        emailID
        inList
        lastLogin
        billing_status
        total_user
        members(limit: 99999) {
          nextToken
          items {
            id
            firstName
            lastName
            emailID
            billing_status
            accountStatus
            lastLogin
          }
        }
        DpcBillingID
        DpcDetails {
          id
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          __typename
        }
        companyID {
          id
          approved
          name
          searchByName
          url
          phoneNumber
          email
          emailDomain
          profileID
          billing_status
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiUnSubscribeIDId
          owner
        }
        unSubscribeID {
          id
          reason
          createdAt
          updatedAt
          unSubscribeEmployeeId
          unSubscribeMemberId
          unSubscribeCompanyId
          owner
        }
        profile {
          email
          profilePhoto
          profileCompleted
          firstName
          lastName
          searchByName
          dob
          insuranceCarrier
          planID
          editStatus
          editRequested
          memberID
          streetAdress
          city
          state
          zip
          phone
          gender
          createdAt
          updatedAt
          owner
        }
        accountStatus
        stripeCustomerID
        stripeSubscriptionID
        stripePriceID
        planID
        plan {
          id
          name
          planDetails
          createdAt
          updatedAt
        }
        metadata
        createdAt
        updatedAt
        companyRegiEmployeeId
        employeeRegiUnSubscribeIDId
        owner
      }
      nextToken
    }
  }
`;

export const listDoctorCustom = /* GraphQL */ `
  query ListDoctorProfiles(
    $filter: ModelDoctorProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDoctorProfiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        physicianID
        doctorName
        searchByName
        byName
        doctorProfilePhoto
        doctorDegree
        doctorEmail
        aboutDoctor
        doctorEducation
        doctorOfficeAdd
        areaOfSpeciality
        bodyArea
        specialities
        doctorOfficeCity
        doctorOfficeState
        doctorOfficeZip
        doctorOfficePhone
        doctorOfficeFax
        doctorPracticeName
        doctorWebsite
        doctorFacility
        surgeryCenter
        googleRating
        facebookRating
        testimonial
        activeOnPortal
        distance
        specialty
        startDate
        isDpcDoctor
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getDoctorCustom = `
query GetDoctorProfile($id: ID!) {
  getDoctorProfile(id: $id) {
    id
    physicianID
    doctorName
    searchByName
    byName
    doctorProfilePhoto
    doctorDegree
    doctorEmail
    aboutDoctor
    doctorEducation
    doctorOfficeAdd
    areaOfSpeciality
    bodyArea
    specialities
    doctorOfficeCity
    doctorOfficeState
    doctorOfficeZip
    doctorOfficePhone
    doctorOfficeFax
    doctorPracticeName
    doctorWebsite
    doctorFacility
    surgeryCenter
    googleRating
    facebookRating
    testimonial
    activeOnPortal
    distance
    specialty
    startDate
    isDpcDoctor
    createdAt
    updatedAt
    __typename
  }
}
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelChatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        senderID
        senderDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          profile {
            profilePhoto
            firstName
            lastName
          }
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        admin
        adminDetails {
          id
          firstName
          lastName
          fcmToken
          searchByName
          employeeEmail
          emailType
          verified
          role
          userType
          emailID
          inList
          lastLogin
          billing_status
          total_user
          promotionalID
          dpcDoctorID
          DpcBillingID
          accountStatus
          stripeCustomerID
          stripeSubscriptionID
          stripePriceID
          planID
          metadata
          createdAt
          updatedAt
          companyRegiEmployeeId
          employeeRegiUnSubscribeIDId
          owner
          __typename
        }
        messages(limit: 9999, sortDirection: ASC) {
          nextToken
          __typename
          items {
            id
            content
            senderID
            createdAt
            systemContent
          }
        }
        status
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
