import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function PtTour(props) {
  const ptServiceSteps = [
    {
      target: ".pt-first-step",
      content: (
        <TourStepContent
          heading="Physical Therapy"
          details="Click to choose your desired Physical Therapy Center and proceed to the next step for assistance."
          numbers={{ currentStep: 1, totalSteps: 6 }}
          onSkipTour={() => props?.handleEndTour()}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={ptServiceSteps}
        run={props?.ptTour}
        stepIndex={props?.ptTourIndex}
        setTourIndex={props?.setPtTourIndex}
      />
    </>
  );
}
