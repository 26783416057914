import { Row, Input, Button, Table, Space } from "antd";
import "./Admin.less";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { CSVLink } from "react-csv";
import exportCSV from "assets/icons/exportcsv.svg";

import { useCallback } from "react";
import {
  ListEmployeeProfiles,
  ListEmployeesRegis,
  UpdateEmployeeProfile,
} from "utility/action";
import SuccessModel from "utility/SuccessModel";

export default function ProfileEditRequest(props) {
  const { Search } = Input;
  const [employee, setEmployeeData] = useState([]);
  const [dataSource, setDataSource] = useState(employee);
  const userData = useSelector((state) => state.userReducer?.userData);
  const [empLoading, setEmpLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);

  const headers = [
    { label: "Email ID", key: "emailID" },
    { label: "First Name", key: "firstName" },
    { label: "Last Name", key: "lastName" },
    { label: "Requested Date", key: "date" },
  ];

  const csvExp = dataSource.map((row) => ({
    emailID: row?.emailId,
    firstName: row?.firstName,
    lastName: row?.lastName,
    date: moment(row?.createdAt).format("YYYY-MM-DD"),
  }));

  const approveEditRequest = async (email) => {
    try {
      const inputData = {
        email: email,
        editStatus: "APPROVED",
      };
      await UpdateEmployeeProfile(inputData);
      setSuccessModel(true);
      fetchEmployeeData();
    } catch (err) {
      console.log(err);
    }
  };

  const denyEditRequest = async (email) => {
    try {
      const inputData = {
        email: email,
        editStatus: "DENIED",
      };
      await UpdateEmployeeProfile(inputData);
      fetchEmployeeData();
    } catch (err) {}
  };

  const setEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.email,
      searchByName: row?.searchByName,
      FirstName: row?.firstName,
      LastName: row?.lastName,
      Email: row?.email,
      RequestDate: row?.editRequested,
    };
  };

  const fetchEmployeeData = useCallback(async () => {
    if (userData.userType === "CORPORATE_ADMIN") {
      setEmpLoading(true);
      const filter = {
        companyRegiEmployeeId: { eq: userData?.id },
      };
      await ListEmployeesRegis(filter)
        .then((response) => {
          const profiles = response
            .filter((item) => item.profile?.editStatus === "REQUESTED")
            .map((item) => item?.profile);

          const empData = profiles.map((row) => setEmployeesRow(row));
          setDataSource(empData);
        })
        .catch((error) => {
          console.log(error);
          setEmpLoading(false);
        });
      setEmpLoading(false);
    } else {
      setEmpLoading(true);
      const filter = {
        editStatus: { eq: "REQUESTED" },
      };
      await ListEmployeeProfiles(filter)
        .then((response) => {
          const empData = response.map((row) => setEmployeesRow(row));
          setDataSource(empData);
        })
        .catch((error) => {
          console.log(error);
          setEmpLoading(false);
        });
      setEmpLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchEmployeeData();
  }, [fetchEmployeeData]);

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const filteredUsers = employee.filter((item) => {
      return (
        item?.searchByName?.indexOf(currValue.toLowerCase()) >= 0 ||
        item?.emailId?.indexOf(currValue.toLowerCase()) >= 0
      );
    });
    setDataSource(filteredUsers);
  };

  const columns = [
    {
      title: "Email ID",
      dataIndex: "Email",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },

    {
      title: "Request Date",
      dataIndex: "RequestDate",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (email) => {
        return (
          <>
            <Space wrap>
              <Button
                className="blue-btn"
                onClick={() => {
                  approveEditRequest(email);
                }}
              >
                Approve
              </Button>

              <Button
                className="blue-border-btn"
                onClick={() => {
                  denyEditRequest(email);
                }}
              >
                Deny
              </Button>
            </Space>
          </>
        );
      },
    },
  ];

  return (
    <>
      {userData.userType === "CORPORATE_ADMIN" ? (
        <>
          <Row justify="space-between table-header">
            <Row align="middle">
              <Search
                placeholder="Search"
                className="searchbar"
                onChange={handleSearch}
                style={{ width: 250 }}
              />
            </Row>

            <Row className="gap-10 mtr-20">
            <CSVLink
            data={csvExp}
            headers={headers}
            filename={"Employees.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

             
            </Row>
          </Row>
        </>
      ) : (
        ""
      )}

      <Table
        rowSelection={{
          type: "Checkbox",
        }}
        loading={empLoading}
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
      />

      <SuccessModel
        message="Edit Request Approved Successfully!"
        open={successModel}
      />
    </>
  );
}
