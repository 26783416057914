import React, { useEffect } from "react";
import Pagination from "rc-pagination";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Select,
  Modal,
  Form,
  Divider,
  message,
  Empty,
  Skeleton,
  Grid,
} from "antd";

import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import { useState } from "react";
import { setICData } from "../../../store/actions/IcAction";
import { SearchOutlined, StarFilled } from "@ant-design/icons";
import Container from "commons/Container";
import { Link, useLocation, useNavigate } from "react-router-dom";
import locationIcon from "assets/icons/location.svg";
import {
  imagingCenterOptions,
  IMAGING_DETAILS_TOUR_START,
  IMAGING_PROFILE_TOUR_START,
  IMAGING_TOUR_END,
} from "utility/constant";
import { useDispatch, useSelector } from "react-redux";

// import SkeletonBlock from "utility/SkeletonBlocks";
import usePaginationSearch from "utility/usePaginationSearch";
import IcAppointmnet from "./Appointment/IcAppointmnet";
import { ListNearByImagingCenters } from "utility/action";
import { handleChangeSpeciality } from "utility/handleSpecialityChnage";
import { Analytics } from "aws-amplify";
import { setTourSteps } from "store/actions/tourSteps";
import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";
import PaginatedList from "utility/PaginatedList";

export default function ImagingCenterList() {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [icName, setICName] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [icData, setIcData] = useState({});
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);

  const [imgTour, setImgTour] = useState(false);
  const [imgTourIndex, setImgTourIndex] = useState(0);

  const specialityOptions = useSelector(
    (state) => state?.specialityReducer?.specialityOption
  );

  const ICDataState = useSelector((state) => state.ICReducer?.ICData);
  const locationCordinates = useSelector(
    (state) => state.userReducer?.userData?.profile?.location
  );

  const [form] = Form.useForm();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const totalLen = ICDataState?.length;
  const [areaOfSpeciality, setAreaOfSpecialty] = useState(
    location?.state?.specialty || specialityOptions
  );
  // const areaOfSpeciality = location?.state?.specialty;

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleICChange = async (value) => {
    setSearchText("");
    setAreaOfSpecialty(value);
    setSkeletonLoader(true);

    await handleChangeSpeciality(
      ListNearByImagingCenters,
      value,
      dispatch,
      "FETCH_IC_DATA",
      {
        lat: locationCordinates?.lat,
        lon: locationCordinates?.lon,
      }
    );
    setSkeletonLoader(false);
  };

  useEffect(() => {
    setICData(ICDataState);
  }, [ICDataState]);

  const rendorICCenter = (Ic) => {
    return (
      <Col span={8} lg={8} md={12} xs={24} key={Ic?.id} className="mt-10">
        <div className="single-list-card-surgery">
          <div className="surgery-profile-card imaging-center-card">
            <div className="d-flex gap-3">
              <img
                src={Ic?.icProfilePhoto}
                alt="Doctor Img"
                className="doctor-img"
              />
              <h4 className="doctor-name sub-header-font">
                {Ic?.icOfficeName}
              </h4>
            </div>

            <div className="d-flex mt-20 rat-box">
              <p className="p-font">
                <img src={facebook} alt="facebook Icon" />{" "}
                {Ic?.icFaceBookRating === 0 || Ic?.icFaceBookRating === null ? (
                  "NA"
                ) : (
                  <>
                    {Ic?.icFaceBookRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
              <Divider className="h-15em" type="vertical" />
              <p className="p-font">
                <img src={google} alt="facebook Icon" />{" "}
                {Ic?.icGoogleRating === 0 || Ic?.icGoogleRating === null ? (
                  "NA"
                ) : (
                  <>
                    {Ic?.icGoogleRating}{" "}
                    <StarFilled style={{ color: "#e7a500" }} />
                  </>
                )}
              </p>
            </div>

            <div className="space-between app-bottom-margin">
              <p className="single-address-line p-font">
                {Ic?.distance !== null && Ic?.distance !== undefined ? (
                  <img src={locationIcon} alt="Address Icon" />
                ) : (
                  ""
                )}

                {parseFloat(Ic?.distance).toFixed(2) !== "0.00" &&
                Ic?.distance !== null &&
                Ic?.distance !== undefined
                  ? ` ${parseFloat(Ic?.distance).toFixed(2)} Miles from
                                  you`
                  : Ic?.distance !== null || Ic?.distance !== undefined
                  ? ""
                  : "Near You"}
              </p>

              <Link
                to="/imaging-center-profile"
                state={{ id: Ic?.id }}
                className="learn-more-btn"
                onClick={() => {
                  if (imgTour) {
                    dispatch(setTourSteps(IMAGING_PROFILE_TOUR_START));
                  }
                }}
              >
                <Button
                  className="blue-border-btn p-font img-fifth-step"
                  onClick={() =>
                    searchText
                      ? Analytics.record({
                          name: "Search",
                          attributes: {
                            Service: "Imaging center",
                            SearchFor: Ic?.icOfficeName,
                          },
                          immediate: true,
                        })
                      : {}
                  }
                >
                  Learn More
                </Button>
              </Link>

              <div
                className="request-app"
                onClick={() => {
                  if (imgTour) {
                    setTimeout(() => {
                      setImgTourIndex(2);
                    }, 400);
                  }
                }}
              >
                <Button
                  className="theme-btn p-font img-second-step"
                  onClick={() => {
                    setOpen(true);

                    setICName(Ic?.icOfficeName);
                    setIcData({
                      icID: Ic?.id,
                      icName: Ic?.icOfficeName,
                      icEmail: Ic?.icEmail,
                      icPhone: Ic?.icOfficePhone,
                      icSpecialties: specialityOptions,
                      icFax: Ic?.icOfficeFax,
                    });
                  }}
                >
                  Request Appointment
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  };

  const {
    paginatedItems,
    searchText,
    setSearchText,
    currentPage,
    handlePaginationChange,
    PrevNextArrow,
  } = usePaginationSearch(ICDataState);

  const handleSearchInputChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  useEffect(() => {
    if (tourSteps === IMAGING_DETAILS_TOUR_START) {
      setImgTour(true);
    }
    setTimeout(() => {
      dispatch(setTourSteps(IMAGING_TOUR_END));
    }, 500);
  }, [tourSteps]);

  const skipTour = (closeModal) => {
    if (closeModal) {
      setOpen(false);
      setImgTour(false);
      dispatch(setTourSteps(IMAGING_TOUR_END));
    }
    setImgTour(false);
    dispatch(setTourSteps(IMAGING_TOUR_END));
  };

  const handleUpdateTourIndex = (index) => {
    if (index === 4) {
      setImgTourIndex(4);
      setOpen(false);
    } else {
      setImgTourIndex(3);
    }
  };

  const serviceSteps = [
    {
      target: ".img-first-step",
      content: (
        <TourStepContent
          heading="Specialty Selection Dropdown"
          details="Click for other types of Imaging"
          numbers={{ currentStep: 4, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-second-step",
      content: (
        <TourStepContent
          heading="Request Appointment"
          details="Click to schedule an appointment with Imaging Center"
          numbers={{ currentStep: 5, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-third-step",
      content: (
        <TourStepContent
          heading="Appointment Form"
          details="Fill out the required information"
          numbers={{ currentStep: 6, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-forth-step",
      content: (
        <TourStepContent
          heading="Submit"
          details="Click the Submit button to schedule an appointment."
          numbers={{ currentStep: 7, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-fifth-step",
      content: (
        <TourStepContent
          heading="Learn More"
          details="Click to view more information about the imaging center"
          numbers={{ currentStep: 8, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".img-sixth-step",
      content: (
        <TourStepContent
          heading="Doctor Profile"
          details="Discover more about doctor."
          numbers={{ currentStep: 9, totalSteps: 9 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={serviceSteps}
        run={imgTour}
        stepIndex={imgTourIndex}
        setTourIndex={setImgTourIndex}
      />

      <Container>
        <div className="d-flex justify-content-between hide-version-no">
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/" className="p-font">
                Home
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <span className="p-font">Imaging Center List</span>
            </Breadcrumb.Item>
            {/* <Breadcrumb.Item>
              <span className="p-font">Center Lists</span>
            </Breadcrumb.Item> */}
          </Breadcrumb>
        </div>

        <Card className="list-content mobile-card">
          <Row
            justify="space-between"
            align="middle"
            gutter={[0, 10]}
            className="mb-20 r-mb-10 "
          >
            <Col>
              <div>
                <p className="card-title-surgery header-font">
                  Imaging Center List ({totalLen} Result)
                </p>
              </div>
            </Col>

            {screens.xs ? (
              ""
            ) : (
              <Col
                md={6}
                xs={24}
                onClick={() => {
                  if (imgTour) {
                    setImgTourIndex(1);
                  }
                }}
              >
                <Select
                  className="specialty-dropdown select-font img-first-step"
                  defaultValue={
                    specialityOptions
                      ? specialityOptions
                      : "Select Any Specialty"
                  }
                  disabled={imgTour ? true : false}
                  options={imagingCenterOptions}
                  onChange={handleICChange}
                />
              </Col>
            )}
          </Row>

          <Col span={24} className="d-flex gap-3">
            <Input
              className="main-search border-bottom-only search-font"
              size="large"
              placeholder="Search Here"
              prefix={<SearchOutlined />}
              onChange={handleSearchInputChange}
              value={searchText}
            />
            <Pagination
              onChange={handlePaginationChange}
              total={ICDataState?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
              className="hide-version-no"
            />
          </Col>

          {screens.xs ? (
            <Col
              md={6}
              xs={24}
              className="mt-10"
              onClick={() => {
                if (imgTour) {
                  setImgTourIndex(1);
                }
              }}
            >
              <Select
                className="specialty-dropdown select-font img-first-step"
                defaultValue={
                  specialityOptions
                    ? specialityOptions
                    : "Select Any Specialty"
                }
                disabled={imgTour ? true : false}
                options={imagingCenterOptions}
                onChange={handleICChange}
              />
            </Col>
          ) : (
            ""
          )}

          <Row gutter={[20, 20]} className="r-mt-10">
            {skeletonLoader ? (
              <>
                {/* <Row gutter={[20, 20]} className="mt-3"> */}
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                <Col span={8} lg={8} md={12} sm={24} xs={24}>
                  <Skeleton.Avatar
                    shape="square"
                    size="500"
                    active
                    style={{
                      width: screens.lg ? "350px" : "300px",
                      height: screens.lg ? "220px" : "200px",
                    }}
                  />
                </Col>
                {/* </Row> */}
              </>
            ) : (
              <>
                {paginatedItems.length > 0 ? (
                  <>
                    {paginatedItems.map(rendorICCenter)}{" "}
                    {screens.xs && (
                      <Col
                        span={8}
                        lg={8}
                        md={12}
                        sm={24}
                        xs={24}
                        className="mt-10"
                      >
                        {/* <PaginatedList
                          dataSource={ICDataState}
                          itemsPerPage={10}
                          className="mt-20"
                        /> */}
                         <Pagination
              onChange={handlePaginationChange}
              total={ICDataState?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
            />
                      </Col>
                    )}
                  </>
                ) : (
                  <div className="no-data">
                    <Col md={24}>
                      <Empty />
                    </Col>
                  </div>
                )}
              </>
            )}
          </Row>
        </Card>
      </Container>

      <Modal
        title="Modal 1000px width"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        width={1000}
        className="appointment-modal desired-modal"
        footer={null}
      >
        <IcAppointmnet
          isModal={true}
          icName={icName}
          icData={icData}
          tour={imgTour}
          tourIndex={imgTourIndex}
          onCloseModal={handleCloseModal}
          className={imgTourIndex === 2 ? "img-third-step" : ""}
          onUpdateTourIndex={handleUpdateTourIndex}
        />
      </Modal>
    </>
  );
}
