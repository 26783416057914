import * as actions from "../actions/index";

const initialState = {
    surgerySpeciality: null,
  };

const surgerySpecialityReducer = (state = initialState,action) => {
    switch (action.type) {
        case actions.FETCH_SURGERY_SPECIALITY:
            return {
              ...state,
              surgerySpeciality: action.payload,
            };

        default:
            return state;
    }
}
export default surgerySpecialityReducer;
