import * as actions from "../actions/index";

const initialState = {
  cognitoUser: null,
};

const cognitoUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COGNITO_USER:
      return {
        ...state,
        cognitoUser: action.payload,
      };

    default:
      return state;
  }
};
export default cognitoUserReducer;
