import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  message,
  Alert,
  InputNumber,
} from "antd";
import { Auth } from "aws-amplify";
import "../Login/Login.less";
import logo from "assets/delphy/delphy.svg";
import { formatNumberUS, tenDigit } from "utility/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";
import navigateBasedOnUser from "../navigationHelper";
import { useDispatch, useSelector } from "react-redux";
import { setToken } from "store/actions/loginAction";
import { setCognitoUser } from "store/actions/cognitoUser";

const SetupMFA = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cognitoUser = useSelector(
    (state) => state.cognitoUserReducer?.cognitoUser
  );

  const [resendLoading, setResendLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [disableSendCode, setDisableSendCode] = useState(true);
  const [showCodeInput, setShowCodeInput] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  const currentRoute = location.pathname;
  const intialNumber = location?.state?.phoneNumber;
  const procedureName = urlParams.get("procedureName");
  const params = new URLSearchParams(location.search);
  const dpcDoctor = params.get("doctorID");
  const [timer, setTimer] = useState(
    currentRoute === "/mfa-authentication" ? 30 : 0
  );
  const [canResend, setCanResend] = useState(
    currentRoute === "/mfa-authentication" ? false : true
  ); // Button state
  const planName =
    params.get("data") === null ? null : atob(params.get("data"));

  let planLocalStorage = localStorage.getItem("planName");
  if (planLocalStorage === "null") {
    planLocalStorage = null;
  }
  const handleMobileNumber = async (value) => {
    setPhoneNumber(value);
    value?.toString().length === 10
      ? setDisableSendCode(false)
      : setDisableSendCode(true);
  };

  useEffect(() => {
    let interval;

    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      setCanResend(true); // Enable resend button when timer ends
    }

    return () => clearInterval(interval);
  }, [timer]);

  const handelSendCodeClick = async () => {
    setLoading(true);
    try {
      const userMobileNumber =
        process.env.NODE_ENV === "development"
          ? `+91${phoneNumber}`
          : `+1${phoneNumber}`;
      const user = await Auth.currentAuthenticatedUser();
      console.log("phoneNumber", userMobileNumber);

      await Auth.updateUserAttributes(user, {
        phone_number: userMobileNumber,
      });

      setTimer(30); // Set timer to 30 seconds
      setCanResend(false); // Disable resend button
      messageApi.success("Verification code sent to your phone number.");
    } catch (error) {
      console.log(error);
      messageApi.error("Error sending verification code: " + error.message);
    } finally {
      setShowCodeInput(true);
      setLoading(false);
    }
  };

  // Retrieve the user object from sessionStorage
  const hanldeMFALogin = async () => {
    try {
      setLoading(true);

      // Make sure the user object is not null and has the expected structure
      if (!cognitoUser) {
        throw new Error("User object is null or undefined");
      }
      console.log("verificationCode", verificationCode);
      // const res = user.sendMFACode(verificationCode);

      const response = await Auth.confirmSignIn(
        cognitoUser,
        verificationCode,
        "SMS_MFA"
      );

      dispatch(setToken(response?.signInUserSession?.accessToken?.jwtToken));
      dispatch(setCognitoUser(response));
      messageApi.success("Mobile number validation successfull.");

      // Redirect to login after MFA setup
      navigateBasedOnUser(
        navigate,
        cognitoUser.signInUserSession.accessToken.payload["cognito:groups"][0],
        dpcDoctor,
        planName,
        planLocalStorage,
        procedureName,
        cognitoUser?.attributes?.sub,
        dispatch
      );
      setLoading(false);
    } catch (error) {
      setError(error.message);
      console.log(error);
      console.log(error.message);
      setLoading(true);
    }
  };

  const resendMFACode = async () => {
    if (!canResend) return;

    try {
      setResendLoading(true);
      setCanResend(false);
      const response = await Auth.signIn(
        location?.state?.username.trim(),
        location?.state?.password
      );
      dispatch(setCognitoUser(response));
      // Start the timer
      setCanResend(false);
      setResendLoading(false);
      setTimer(30);
    } catch (error) {
      setResendLoading(false);
      console.error("Error resending mfa code:", error);
      setError("Error resending mfa code");
    }
  };

  const handleResendCode = async () => {
    console.log("gdfgds");
    if (!canResend) return;

    try {
      setResendLoading(true);
      setCanResend(false);
      const userMobileNumber =
        process.env.NODE_ENV === "development"
          ? `+91${phoneNumber}`
          : `+1${phoneNumber}`;
      const user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        phone_number: userMobileNumber,
      }); // Or the relevant method for your case
      console.log("Verification code resent");
      // Start the timer
      setCanResend(false);
      setResendLoading(false);
      setTimer(30);
    } catch (error) {
      setResendLoading(false);
      console.error("Error resending verification code:", error);
      setError("Error resending verication code");
    }
  };

  const handleSetupMFA = async () => {
    setLoading(true);
    console.log("verificationCode", verificationCode);
    try {
      await Auth.verifyCurrentUserAttributeSubmit(
        "phone_number",
        verificationCode
      );
      const user = await Auth.currentAuthenticatedUser();
      await Auth.setPreferredMFA(user, "SMS");
      const cognitoGroup =
        user.signInUserSession.accessToken.payload["cognito:groups"][0];
      const variable = user?.attributes?.sub;
      messageApi.success("MFA setup completed.");
      const res = await Auth.currentAuthenticatedUser();
      console.log(res);

      // Redirect user to login after mfa setup
      navigate("/login");

      setLoading(false);
    } catch (error) {
      setLoading(false);
      messageApi.error("Error verifying phone number: " + error.message);
      setError("Error verifying phone number");
    }
  };

  const verificationCodeInputComp = () => {
    return (
      <Form.Item
        name="code"
        className="code-form-item"
        rules={[
          {
            required: true,
            message: "Please input your Confirmation Code!",
          },
          {
            pattern: "^[0-9]+$",
            message: "Only numbers are allowed",
          },
        ]}
      >
        <Input
          autoFocus
          id="code"
          key="code"
          name="code"
          className="code"
          onChange={(e) => setVerificationCode(e.target.value)}
          placeholder="Confirmation code"
          tabIndex={1}
          size="large"
          style={{ letterSpacing: "0.5em" }}
        />
      </Form.Item>
    );
  };

  const setupMFA = () => {
    return (
      <Row className="login-wrapper">
        <Col span={12} lg={12} md={0} sm={0} xs={0}>
          <div className="mfa-image"></div>
        </Col>
        <Col
          className="form-footer-container"
          span={12}
          lg={12}
          md={0}
          sm={24}
          xs={24}
        >
          <Row justify="center" align="middle" className="w-100">
            <Col span={12} md={12} sm={20} xs={20}>
              <div className="header"></div>
              <div className="form-logo-container">
                <img src={logo} alt="" className="bazar-logo" />
                <p className="welcome-text header-font mb-0">Enable MFA</p>
                <p className="instruction sub-header-font">
                  Enter your mobile number
                </p>

                {error !== "" && (
                  <Alert
                    style={{ marginBottom: "10px", zIndex: "1000" }}
                    type="error"
                    showIcon={false}
                    message={error}
                    banner
                  />
                )}
                <Form
                  requiredMark={false}
                  layout="vertical"
                  className="login-form w-100"
                >
                  <Form.Item
                    label={
                      <span className="sub-header-font">Mobile number</span>
                    }
                    className="username-form-item"
                    name="mobileNumber"
                    rules={[
                      {
                        pattern: tenDigit,
                        message: "Please enter a valid phone number",
                      },
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      addonBefore={"+1"}
                      disabled={showCodeInput}
                      onChange={(e) => handleMobileNumber(e)}
                      formatter={(value) => formatNumberUS(value)}
                      parser={(value) => {
                        const cleanedValue = value.replace(/\D/g, "");
                        return cleanedValue.slice(0, 10);
                      }}
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </Form.Item>

                  {showCodeInput && verificationCodeInputComp()}

                  <div
                    className=" mb-20"
                    style={{
                      border: "1px dashed #000",
                      borderRadius: "6px",
                      padding: "20px 10px 20px 10px",
                    }}
                  >
                    <span className="referral-code mb-20">What is MFA?</span>

                    <Row className="gap-10 mtr-15 admin-forth-step copy-link">
                      <Col span={24} style={{ display: "flex" }}>
                        It's an extra layer of security that requires you to
                        enter a code when logging into your account
                      </Col>
                    </Row>
                  </div>

                  <Button
                    className="login-button"
                    htmlType="submit"
                    onClick={() =>
                      showCodeInput ? handleSetupMFA() : handelSendCodeClick()
                    }
                    loading={loading}
                    disabled={disableSendCode}
                  >
                    {showCodeInput ? "Continue" : "Send verification code"}
                  </Button>
                  {showCodeInput && (
                    <Button
                      type="link"
                      className="resendButton"
                      onClick={handleResendCode}
                      disabled={!canResend}
                      loading={resendLoading}
                      style={{
                        textAlign: "right",
                        cursor: canResend ? "pointer" : "not-allowed",
                        paddingBottom: 10,
                        color: "#416B88",
                        fontWeight: "bold",
                        paddingLeft: 0,
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <span style={{ color: "grey", paddingRight: "5px" }}>
                        Didn't recieve a code?
                      </span>{" "}
                      {canResend ? "Resend Code" : `Resend Code (${timer}s)`}
                    </Button>
                  )}
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const AuthenticateMFA = () => {
    return (
      <Row className="login-wrapper">
        <Col span={12} lg={12} md={0} sm={0} xs={0}>
          <div className="mfa-image"></div>
        </Col>
        <Col
          className="form-footer-container"
          span={12}
          lg={12}
          md={0}
          sm={24}
          xs={24}
        >
          <Row justify="center" align="middle" className="w-100">
            <Col span={12} md={12} sm={20} xs={20}>
              <div className="header"></div>
              <div className="form-logo-container">
                <img src={logo} alt="" className="bazar-logo" />
                <p className="welcome-text header-font mb-0">
                  Verify your identity
                </p>
                <p className="instruction sub-header-font">
                  We've sent you text message to:
                </p>

                {error !== "" && (
                  <Alert
                    style={{ marginBottom: "10px", zIndex: "1000" }}
                    type="error"
                    showIcon={false}
                    message={error}
                    banner
                  />
                )}
                <Form
                  requiredMark={false}
                  layout="vertical"
                  className="login-form w-100"
                >
                  <Form.Item
                    label={
                      <span className="sub-header-font">Mobile number</span>
                    }
                    className="username-form-item"
                    name="mobileNumber"
                    initialValue={intialNumber.slice(3)}
                  >
                    <InputNumber
                      controls={false}
                      addonBefore={"+1"}
                      disabled={true}
                      // onChange={(e) => handleMobileNumber(e)}
                      formatter={(value) => formatNumberUS(value)}
                      parser={(value) => {
                        const cleanedValue = value.replace(/\D/g, "");
                        return cleanedValue.slice(0, 10);
                      }}
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </Form.Item>
                  {verificationCodeInputComp()}
                  <Button
                    className="login-button"
                    htmlType="submit"
                    onClick={() => hanldeMFALogin()}
                    loading={loading}
                    // disabled={disableSendCode}
                  >
                    Continue
                  </Button>

                  <Button
                    type="link"
                    className="resendButton"
                    onClick={resendMFACode}
                    disabled={!canResend}
                    loading={resendLoading}
                    style={{
                      textAlign: "right",
                      cursor: canResend ? "pointer" : "not-allowed",
                      paddingBottom: 10,
                      color: "#416B88",
                      fontWeight: "bold",
                      paddingLeft: 0,
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <span style={{ color: "grey", paddingRight: "5px" }}>
                      Didn't recieve a code?
                    </span>{" "}
                    {canResend ? "Resend Code" : `Resend Code (${timer}s)`}
                  </Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {contextHolder}
      {currentRoute === "/setup-mfa" && setupMFA()}
      {currentRoute === "/mfa-authentication" && AuthenticateMFA()}
    </>
  );
};

export default SetupMFA;
