import { Card, Tabs } from "antd";
import Container from "commons/Container";
import AdminApproval from "pages/Approval/AdminApproval";
import Invitation from "pages/Approval/Invitation";
import React from "react";
import Account from "./Account";
import UnsubscribeRequest from "./UnsubscribeRequest";

export default function HrHomePage() {
  return (
    <div>
      <Container>
        <Card className="my-2">
          <Tabs className="employer-tabs" defaultActiveKey="1" centered>
            <Tabs.TabPane tab="All Employees" key="1">
              <AdminApproval />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Requested Invitations`} key="2">
              <Invitation />
            </Tabs.TabPane>
            <Tabs.TabPane tab={`Unsubscribe Request`} key="3">
              <UnsubscribeRequest />
            </Tabs.TabPane>
            {/* <Tabs.TabPane tab={`Account Information`} key="4">
              <Account />
            </Tabs.TabPane> */}
          </Tabs>
        </Card>
      </Container>
    </div>
  );
}
