import { Storage } from "aws-amplify";

export const uploadFileToS3 = async (file) => {
  const newFilename = new Date().valueOf();
  return await Storage.put(
    `${file.name.replace(/\.[^/.]+$/, "")}_${newFilename}.${file.name.replace(
      /(.*)\./g,
      ""
    )}`,
    file,
    {
      level: "private", //change it to private
      contentType: file.type, // contentType is optional
    }
  );
};

export const uploadPublicFileToS3 = async (file) => {
  const newFilename = new Date().valueOf();
  return await Storage.put(
    `${file.name.replace(/\.[^/.]+$/, "")}_${newFilename}.${file.name.replace(
      /(.*)\./g,
      ""
    )}`,
    file,
    {
      level: "public", //change it to private
    }
  );
};

export const GetFileFromS3 = async (key, level = "private") => {
  return Storage.get(key, { level, expires: 86400 });
};

export const GetMulipleFileFromS3 = async (key, level = "public") => {
  try {
    const url = await Storage.get(key, {
      level,
      expires: 86400,
    });
    return url;
  } catch (error) {
    console.log(`Error getting pre-signed URL for ${key}: ${error}`);
    return null;
  }
};

export const GetPublicFileFromS3 = async (key, level = "public") => {
  return Storage.get(key, { level, expires: 86400 });
};

export const uploadImage = async (file) => {
  const newFilename = new Date().valueOf();
  return await Storage.put(
    `${file.name.replace(/\.[^/.]+$/, "")}_${newFilename}.${file.name.replace(
      /(.*)\./g,
      ""
    )}`,
    file,
    {
      level: "public",
      contentType: file.type,
      expires: 0, // One year in seconds
    }
  );
};

export const getImage = async (key, level = "public") => {
  return Storage.get(key, { level, expires: 0 });
};
