import { Button, Col, Form, Input, message, Modal, Row, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import logo from "assets/delphy/delphy.svg";
import onBoard from "assets/img/on.svg";
import onBoard2 from "assets/img/onboarding.svg";
import onBoardHeader from "assets/icons/onbordingheader.svg";
import line2 from "assets/icons/Line2.svg";
import line from "assets/icons/Line.svg";
import Info from "assets/icons/infoTooltip.svg";

import "./Onboarding.less";
import "./OnboardingResponsive.less";
import { useNavigate } from "react-router-dom";
import email from "assets/icons/email.svg";
import phone from "assets/icons/call.svg";
import warningIcon from "assets/icons/warningIcon.svg";
import { API, Auth, Hub } from "aws-amplify";
import { updateEmployeeRegi } from "graphql/mutations";
import { getEmployeeRegi } from "graphql/queries";
import { servicesNamePattern } from "utility/constant";
import { useSelector } from "react-redux";
import { setUserData } from "store/actions/userData";
import { useDispatch } from "react-redux";
import { setToken } from "store/actions/loginAction";
import { GetEmployeeDetails } from "utility/action";
export default function Onboarding() {
  const [userData, setUser] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  //For handle Health insurance number side
  const [formDisabled, setFormDisabled] = useState(false);
  const [formwithIdRequired, setFormwithIdRequired] = useState(true);

  //For handle employer id side
  const [formRequired, setFormRequired] = useState(true);
  const [formWithIdDisabled, setFormwithIdDisabled] = useState(false);

  const [statusCode, setStatusCode] = useState("");
  const [verified, setVerified] = useState(false);
  const [hpiModel, sethpiModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onboardLoading, setOnboardLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const [activeColumn, setActiveColumn] = useState(1);
  const userDataState = useSelector((state) => state.userReducer.userData);

  const handleClickColumn1 = () => {
    setActiveColumn(1);
    setFormwithIdDisabled(false);
    setFormRequired(true);
    form.setFields([
      {
        name: "employerNamewithUrl",
        errors: [],
        value: "",
      },
      {
        name: "employerHealthPlanMemberID",
        errors: [],
        value: "",
      },
    ]);
  };

  const handleClickColumn2 = () => {
    setActiveColumn(2);
    setFormDisabled(false);
    setFormwithIdRequired(true);
    form.setFields([
      {
        name: "employerNameWithID",
        errors: [],
        value: "",
      },
      {
        name: "employerID",
        errors: [],
        value: "",
      },
    ]);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    sethpiModel(false);
    form.resetFields();
    setVerified(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    sethpiModel(false);
    form.resetFields();
    setVerified(false);
  };

  const fetchUserGroup = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const cognitoGroup =
        user.signInUserSession.accessToken.payload["cognito:groups"][0];
    } catch (error) {
      if (error?.message === "No current user") {
      }
      console.log("Errrr", error);
    }
  };

  useEffect(() => {
    fetchUserGroup();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            dispatch(setToken(data.signInUserSession.accessToken.jwtToken));
            try {
              const currentUserData = await Auth.currentAuthenticatedUser({
                bypassCache: false,
              });

              await API.get(
                "bazarhealthREST",
                `/createEmployee?id=${currentUserData?.username}`
              );
              const res = await GetEmployeeDetails(
                currentUserData?.attributes?.sub
              );
              if (res !== null) {
                dispatch(setUserData(res));
              }
            } catch (error) {
              console.error("Error fetching employee data:", error);
              alert(error);
            }
            break;
          case "signOut":
            dispatch(setUserData(null));
            break;
        }
      }
    );

    return unsubscribe;
  }, []);

  const handleFieldsChange = (values) => {
    values.map((value) => {
      const isEmpty = value?.value;
      // if (isEmpty === null || isEmpty === undefined || isEmpty === "") {
      //   setFormDisabled(false);
      //   setFormwithIdDisabled(false);

      //   setFormRequired(true);
      //   setFormwithIdRequired(true);
      // } else
      if (
        value?.name[0] === "employerNameWithID" ||
        value?.name[0] === "employerID"
      ) {
        setFormDisabled(true);
        setFormwithIdRequired(false);
      } else if (
        value?.name[0] === "employerNamewithUrl" ||
        value?.name[0] === "employerHealthPlanMemberID"
      ) {
        setFormwithIdDisabled(true);
        setFormRequired(false);
      }
    });
  };

  const employeeOnboarding = async (e) => {
    setOnboardLoading(true);
    setVerified(false);
    try {
      if (e?.employerID) {
        const myInit = {
          body: {
            employerID: e?.employerID,
            employeeID: userDataState?.id,
          },
        };

        await API.post(
          "bazarhealthREST",
          `/employee/registerUnderCompany`,
          myInit
        );

        const responce = await API.graphql({
          query: getEmployeeRegi,
          variables: {
            id: userData?.id,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        dispatch(setUserData(responce.data.getEmployeeRegi));
        form.resetFields();
        setIsModalOpen(false);
        // navigate("/approval");
      } else {
        const myInit = {
          body: {
            employerName: e?.employerNamewithUrl,
            memberID: e?.employerHealthPlanMemberID,
            employeeEmail: userDataState?.emailID,
            firstName: userDataState?.firstName,
            lastName: userDataState?.lastName,
            employeeID: userDataState?.id,
          },
        };

        await API.post(
          "bazarhealthREST",
          `/employee/registerUnderCompany`,
          myInit
        );

        const responce = await API.graphql({
          query: getEmployeeRegi,
          variables: {
            id: userData?.id,
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        dispatch(setUserData(responce.data.getEmployeeRegi));
        form.resetFields();
        setIsModalOpen(false);
      }

      navigate("/user-profile");
    } catch (error) {
      if (error.response && error.response.status) {
        setStatusCode(error.response.status);
      }
      setVerified(true);
    } finally {
      setOnboardLoading(false);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    const inputData = {
      input: {
        id: userDataState?.id,
        userType: "INDIVIDUAL",
      },
    };
    await API.graphql({
      query: updateEmployeeRegi,
      variables: inputData,
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    setLoading(false);
    navigate("/user-profile");
  };

  return (
    <>
      {contextHolder}
      <div className="onboard-wrapper">
        <Row className="onboarding-page" justify="center" align="middle">
          <Col span={24} className="responsive-mt-20">
            <img src={logo} alt="Bazar Logo" className="bazar-logo" />
          </Col>

          <Row justify="center">
            <Col
              xs={23}
              sm={23}
              md={6}
              lg={8}
              xl={10}
              className="centered-col hover-onboarding"
            >
              <img src={onBoard} alt="" />
              <h6>Start an individual account</h6>
              <p>
                I want to setup an Individual/Family account that is separate
                from company plan.
              </p>
              <Row justify="center">
                <Button
                  className="theme-btn onboard-btn mb-30"
                  onClick={handleClick}
                  loading={loading}
                >
                  Get Started
                </Button>
              </Row>
            </Col>
            <Col xs={23} sm={23} md={12} lg={8} xl={4}>
              <div className="separator-on">
                <img src={line2} alt="" />
                <span>OR</span>
                <img src={line2} alt="" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={6}
              lg={8}
              xl={10}
              className="centered-col hover-onboarding"
            >
              <img src={onBoard2} alt="" />
              <h6>Register under my company</h6>
              <p className="register-text">
                I am registering under my company <br /> account
              </p>
              <Row justify="center" className="onboarding-btt mb-30">
                <Button className="theme-btn onboard-btn" onClick={showModal}>
                  Get Started
                </Button>
              </Row>
            </Col>
          </Row>
        </Row>
      </div>

      <Modal
        open={isModalOpen}
        width={920}
        onOk={handleOk}
        centered
        onCancel={handleCancel}
        footer={null}
        className="appointment-modal desired-modal"
        // style={{
        //   top: 30,
        // }}
      >
        <Row className="onboard-modal">
          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="right-col">
            <img src={onBoardHeader} alt="av" />
          </Col>

          <Col xs={24} sm={24} md={14} lg={14} xl={14} className="left-col">
            <h6>Enter your employer details</h6>
            <span className="fs-1">
              Please enter your employment details to get started.
            </span>
          </Col>
        </Row>

        <Form
          className=""
          name="onboard"
          onFinish={employeeOnboarding}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          onFieldsChange={handleFieldsChange}
          id="myForm"
          form={form}
          layout="vertical"
        >
          <Row className="mt-30">
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              className={` onboard-form-1 ${
                activeColumn === 1 ? "active-column" : ""
              }`}
              onClick={handleClickColumn1}
            >
              <Form.Item
                label="Employer name"
                name="employerNameWithID"
                rules={[
                  {
                    required: formRequired,
                    message: "Please enter employer name",
                  },
                  {
                    pattern: servicesNamePattern,
                    message: "Please enter a valid employer name",
                  },
                ]}
              >
                <Input disabled={formWithIdDisabled} />
              </Form.Item>
              <Form.Item
                className=""
                label={
                  <Row justify="space-between " align="middle">
                    <span>Delphi employer id</span>
                    <Tooltip
                      title={
                        <div>
                          Your employer has been given a Delphi Employer ID that
                          should have been shared with you. If not,{" "}
                          <span style={{ fontWeight: "bolder", color: "#fff" }}>
                            Contact your HR administrator.
                          </span>
                        </div>
                      }
                      overlayInnerStyle={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#CECECE",
                        backgroundColor: "#252525",
                      }}
                    >
                      <img src={Info} alt="" style={{ marginLeft: 10 }} />
                    </Tooltip>
                  </Row>
                }
                name="employerID"
                rules={[
                  {
                    required: formRequired,
                    message: "Please enter a valid employer id",
                  },
                ]}
              >
                <Input disabled={formWithIdDisabled} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={4} lg={4} xl={4}>
              <div className="separator-on">
                <img src={line} alt="" />
                OR
                <img src={line} alt="" />
              </div>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={10}
              lg={10}
              xl={10}
              className={`onboard-form-2 ${
                activeColumn === 2 ? "active-column" : ""
              }`}
              onClick={handleClickColumn2}
            >
              <Form.Item
                label="Employer name"
                name="employerNamewithUrl"
                rules={[
                  {
                    required: formwithIdRequired,
                    message: "Please enter your employer name.",
                  },
                ]}
              >
                <Input disabled={formDisabled} />
              </Form.Item>
              <Form.Item
                label={
                  <Row justify="space-between " align="middle">
                    <span>Health plan member id</span>

                    <Tooltip
                      title={
                        <div>
                          This can be found on your insurance card under ‘Member
                          ID’. Delphi will match this information with our
                          database to verify you are a member of this employer
                          health plan.
                        </div>
                      }
                      overlayInnerStyle={{
                        fontSize: "14px",
                        fontWeight: "700",
                        color: "#CECECE",
                        backgroundColor: "#252525",
                      }}
                    >
                      <img src={Info} alt="" style={{ marginLeft: 10 }} />
                    </Tooltip>
                  </Row>
                }
                name="employerHealthPlanMemberID"
                rules={[
                  {
                    required: formwithIdRequired,
                    message: "Please enter your Health Plan Member ID",
                  },
                ]}
              >
                <Input disabled={formDisabled} />
              </Form.Item>
            </Col>

            <Col
              span={12}
              md={24}
              xs={24}
              sm={24}
              style={{ textAlign: "center" }}
            >
              <Button
                className="theme-btn mt-30"
                htmlType="submit"
                loading={onboardLoading}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>

        {verified && (
          <>
            <Row
              className="mt-30 employer-warning mb-20"
              justify="space-between"
              gutter={[0, 10]}
            >
              <Col xs={5} sm={3} md={3} lg={2} xl={2}>
                <img src={warningIcon} />
              </Col>
              <Col
                xs={18}
                sm={21}
                md={15}
                lg={15}
                xl={15}
                className="employer-warning-col"
              >
                <p className="employer-warning-text">
                  {statusCode === 403
                    ? "Apologies, but the details you provided are already in use."
                    : "Apologies, but the details you provided doesn't match our records."}
                </p>
                <span>
                  If you require assistance, please feel free to reach out to
                  the Delphi Health Team.
                </span>
              </Col>
              <Col xs={23} sm={23} md={6} lg={6} xl={6}>
                <div className="employer-contact-text">
                  <Row gutter={[10, 3]}>
                    <Col>
                      <img src={email} alt="Delete Img" />
                      <span> ear@delphillc.com</span>
                    </Col>
                    <Col>
                      <img src={phone} alt="Delete Img" />
                      <span style={{ paddingLeft: 10 }}>(603) 537-6037</span>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </>
  );
}
