import React, { useEffect, useState } from "react";
import "react-chatbot-kit/build/main.css";
import "./Chat.less";
import { Col, Form, Image, Input, message, Row, Space, Upload } from "antd";
import { useSelector } from "react-redux";
import previewIcon from "assets/icons/previewIc.svg";
import newMessageSound from "assets/sounds/Notification.mp3";
import { CheckOutlined } from "@ant-design/icons";
import Card from "antd/lib/card/Card";
import logo from "assets/delphy/logo.svg";
import attach from "assets/chat-images/attach.svg";
import send from "assets/chat-images/send.svg";
import disableSend from "assets/chat-images/disable-send.svg";
import ImageActions from "utility/imageFunctions";
import { createChat, createMessage, updateChat } from "graphql/mutations";
import { API } from "aws-amplify";
import { onCreateMessage, onUpdateChat } from "graphql/subscriptions";
import { listChats } from "graphql/customQuery";
import formatTime from "pages/admin/Chat/utils/formatTime";
import axios from "axios";
import { useRef } from "react";
import PdfPreview from "utility/pdfPreview";
import { satisfactionLevels } from "utility/constant";
import { set } from "react-ga";

export default function UserChat(props) {
  const [imageObject, setImageObject] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedRating, setSelectedRating] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatValue, setChatValue] = useState(null);
  const [convo, setConvo] = useState("");
  const [conversationID, setConversationID] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [chatStatus, setChatStatus] = useState(null);
  const [sendDisabled, setSendDisabled] = useState(false);
  const [form] = Form.useForm();
  const lastMsgRef = useRef(null);

  useEffect(() => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView();
    }
  }, [chatMessages]);

  // Create a ref to store the subscription
  const userData = useSelector((state) => state.userReducer?.userData);
  const userId = userData?.id;
  const sound = new Audio(newMessageSound);
  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);

    form.resetFields(["serviceType"]);
  };

  useEffect(() => {
    const fetchExistingChat = async () => {
      // Replace 'userId' with the actual user ID
      try {
        const response = await API.graphql({
          query: listChats,
          variables: {
            filter: {
              senderID: { eq: userId },
              status: { ne: "CLOSED" },
            },
          }, // Use whatever parameter is necessary to identify the user's chat
        });

        const existingChat = response?.data?.listChats.items[0];
        setConversationID(response?.data?.listChats.items[0]?.id);
        if (existingChat) {
          setChatStatus(existingChat.status);
          const messageArray = existingChat?.messages?.items?.map((item) => {
            const msg =
              item?.content?.trim() === ""
                ? item?.systemContent
                : item?.content;
            const sender =
              item?.systemContent &&
              msg &&
              (msg?.includes("has joined the conversation") ||
                msg.includes("has initiated the conversation"))
                ? "system-admin"
                : item?.senderID;

            return {
              content: msg,
              sender: sender,
              createdAt: item?.createdAt,
            };
          });

          setChatValue(messageArray);
          setChatMessages(messageArray);
        }
      } catch (error) {
        console.error("Error fetching existing chat:", error);
      }
    };

    fetchExistingChat();
  }, []); // Empty dependency array ensures this effect runs only once when the component mounts

  const handleRating = async (index) => {
    setSelectedRating(index);
    //will be updating the feedback in the system and having the chat added as new message in content.
    await API.graphql({
      query: updateChat,
      variables: {
        input: {
          id: conversationID,
          feedback: index,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    setChatStatus("FEEDBACK_DONE");
    setChatMessages(() => [
      {
        content: "Thank you for rating the conversation.",
        sender: "system",
        createdAt: new Date(),
      },
    ]);
    sound.play();
    setTimeout(() => {
      props?.closeModal();
      setConversationID(null);
      setChatMessages([]);
      setChatValue(null);
      setSelectedRating(null);
    }, 3000);
  };

  useEffect(() => {
    // Check if both conversationID and userId are available
    if (conversationID && userId) {
      const createMessageSubscriber = API.graphql({
        query: onCreateMessage,
        variables: {
          filter: {
            chatId: { eq: conversationID },
            senderID: { ne: userId },
          },
        },
      }).subscribe({
        next: async (d) => {
          const newMsg = d?.value?.data?.onCreateMessage;
          if (newMsg) {
            const msg =
              newMsg?.content?.trim() === ""
                ? newMsg?.systemContent
                : newMsg?.content;

            const sender =
              newMsg?.systemContent &&
              msg &&
              msg?.includes("has joined the conversation")
                ? "system-admin"
                : newMsg?.senderID;
            setChatMessages((prevMessages) => [
              ...prevMessages,
              {
                content: msg,
                sender: sender,
                createdAt: newMsg?.createdAt,
              },
            ]);
            sound.play();
          }
        },
        error: (e) => {
          console.log("Subscription error:", e);
        },
      });
      const adminAssignedSubscriber = API.graphql({
        query: onUpdateChat,
        variables: {
          filter: {
            id: { eq: conversationID },
            status: { eq: "OPEN" },
            admin: { ne: userId },
          },
        },
      }).subscribe({
        next: async (d) => {
          const newMsg = d?.value?.data?.onUpdateChat;
          if (newMsg) {
            await API.graphql({
              query: createMessage,
              variables: {
                input: {
                  chatId: conversationID,
                  content: "",
                  systemContent: `${newMsg?.adminDetails?.firstName} ${newMsg?.adminDetails?.lastName} has joined the conversation`,
                },
              },
              authMode: "AMAZON_COGNITO_USER_POOLS",
            });
            sound.play();
          }
        },
        error: (e) => {
          console.log("Subscription error:", e);
        },
      });

      const closeChatSubscriber = API.graphql({
        query: onUpdateChat,
        variables: {
          filter: {
            id: { eq: conversationID },
            status: { eq: "CLOSED" },
          },
        },
      }).subscribe({
        next: async (d) => {
          const newMsg = d?.value?.data?.onUpdateChat;
          if (newMsg) {
            setChatStatus("CLOSED");
            setChatMessages((prevMessages) => [
              ...prevMessages,
              {
                content: "Thank you for reaching out to us.",
                sender: "system",
                createdAt: new Date(),
              },
              {
                content: "How would you rate your experience talking to us?",
                sender: "system",
                createdAt: new Date(),
              },
            ]);
            sound.play();
            closeChatSubscriber.unsubscribe();
          }
        },
        error: (e) => {
          console.log("Subscription error:", e);
        },
      });
      // Cleanup subscription on component unmount
      return () => {
        createMessageSubscriber.unsubscribe();
        closeChatSubscriber.unsubscribe();
        adminAssignedSubscriber.unsubscribe();
      };
    }
  }, [conversationID, userId]);

  const handleChat = async (content) => {
    setSendDisabled(true);
    if (!conversationID) {
      let inputData = {
        input: {
          status: "UNASSIGNED",
          senderID: userId,
        },
      };
      const response = await API.graphql({
        query: createChat,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setChatStatus("UNASSIGNED");

      setConversationID(response?.data?.createChat?.id);
      const newMessage = convo.trim() === "" ? content : convo;
      await API.graphql({
        query: createMessage,
        variables: {
          input: {
            chatId: response?.data?.createChat?.id,
            content: newMessage,
            senderID: userId,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      setChatMessages((prevMessages) => [
        ...prevMessages,
        { content: newMessage, sender: userId, createdAt: new Date() },
      ]);

      await API.graphql({
        query: createMessage,
        variables: {
          input: {
            chatId: response?.data?.createChat?.id,
            systemContent:
              "Hi, Welcome to Delphi. Please wait while we connect you to our next available advocate.",
            content: "",
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
    }

    if (conversationID) {
      await API.graphql({
        query: createMessage,
        variables: {
          input: {
            chatId: conversationID,
            content: content || convo,
            senderID: userId,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const newMessage = convo;
      setChatMessages((prevMessages) => [
        ...prevMessages,
        {
          content: content || newMessage,
          sender: userId,
          createdAt: new Date(),
        },
      ]);
      // After updating the state with new messages
    }
    setChatValue(convo);
    setConvo("");
    setSendDisabled(false);
  };

  const handleChatValue = (e) => {
    // setLoading(true);
    // setTimeout(() => {
    //   setLoading(false);
    // }, 3000);
    if (e.target.value.trim() !== "") {
      setSendDisabled(false);
    } else {
      setSendDisabled(true);
    }
    setConvo(e.target.value);
  };

  const detectEnterPress = (e) => {
    const trimmedValue = convo.trim();
    if ((e.key === "Enter" || e.keyCode === 13) && trimmedValue !== "") {
      handleChat(undefined);
    }
  };

  const imageProps = {
    onChange({ file }) {
      if (file.status === "uploading") {
        messageApi.open({
          key: "member",
          type: "loading",
          content: `Uploading File`,
          duration: 0,
        });
      }
      if (file.status === "done") {
        messageApi.open({
          key: "member",
          type: "success",
          content: `File Uploaded Successfully`,
          duration: 5,
        });
      }
    },
    async customRequest({ file, onError, onSuccess }) {
      try {
        const fileExtension = file.name.split(".").pop().toLowerCase();

        const myInit = {
          body: {
            fileExtension: fileExtension,
          },
        };
        const linkToUpload = await API.post(
          "bazarhealthREST",
          `/superAdmin/generateS3PutPresignedUrl`,
          myInit
        );

        await axios.put(linkToUpload, file);
        setConvo(linkToUpload.split("?")[0]);

        onSuccess();
        await handleChat(linkToUpload.split("?")[0]);
      } catch (error) {
        onError();
      }
    },
  };

  const onPreview = async (file) => {
    const previewImage = ImageActions.imagePreviewer(file);
    await previewImage();
  };

  const beforeUpload = (file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG/PDF file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const renderImagePreviews = () => {
    return imageObject.map((image, index) => (
      <Col span={8} md={8} xs={24} sm={24} key={index}>
        <div className="service-image-preview">
          <Image src={image} className="service-image-preview-img" />
          <div className="hide-icon">
            <Space>
              <img src={previewIcon} alt="preview-icon" />
            </Space>
          </div>
        </div>
      </Col>
    ));
  };

  const renderChatMessages = () => {
    return chatMessages.map((message, index) => {
      const isUserMessage = message.sender === userId;
      return (
        <div>
          <div className="chat__msg-group">
            <p
              key={index}
              className={`chat__msg ${
                message.sender === "system-admin"
                  ? "system-chat"
                  : isUserMessage
                  ? "chat__msg--sent"
                  : "chat__msg--rxd"
              } 
              `}
            >
              <div>
                {message?.content?.startsWith("https") ? (
                  <div className={`chat__msg chat__img-wrapper`}>
                    {message.content.toLowerCase().endsWith(".pdf") ? (
                      <PdfPreview pdfUrl={message.content} />
                    ) : message.content.toLowerCase().endsWith(".png") ||
                      message.content.toLowerCase().endsWith(".jpg") ? (
                      <Image
                        src={message.content}
                        alt="file"
                        className="chat__img"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )}
                  </div>
                ) : (
                  <span>{message?.content} </span>
                )}

                {/* {message?.content?.startsWith("https") ? (
                  <div className={`chat__msg chat__img-wrapper`}>
                    {message.content.toLowerCase().endsWith(".pdf") ? (
                      <PdfPreview pdfUrl={message.content} />
                    ) : message.content.toLowerCase().endsWith(".png") ||
                      message.content.toLowerCase().endsWith(".jpg") ? (
                      <Image
                        src={message.content}
                        alt="file"
                        className="chat__img"
                      />
                    ) : (
                      <p>Unsupported file format</p>
                    )}
                  </div>
                ) : (
                  <span>
                    {message?.content}{" "}
                    {message?.sender === "system-admin"
                      ? formatTime(message?.createdAt)
                      : ""}
                  </span>
                )} */}

                {message?.sender === "system-admin" ? (
                  ""
                ) : (
                  <span className="chat__msg-footer">
                    {message?.createdAt
                      ? formatTime(message.createdAt)
                      : formatTime(chatMessages[index - 1]?.createdAt)}
                  </span>
                )}
              </div>
            </p>
            <div ref={lastMsgRef}></div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      {contextHolder}
      <div>
        <Card className="user-chat">
          <Row justify="center" className="user-chat-modal">
            <img src={logo} alt="" />
            <Col
              md={24}
              className="d-flex justify-content-center user-chat-assistance mt-10"
            >
              <h2>How can we help?</h2>
              <p>
                Our technical team is available from 8:00 AM <br /> to 5:00 PM
                EST.
              </p>
            </Col>
          </Row>

          {chatValue !== null ? (
            <Col
              md={24}
              className="user-chat-convo mt-10"
              style={{ height: "250px", overflowY: "scroll" }}
            >
              {chatStatus !== "CLOSED" && renderChatMessages()}
              {chatStatus === "CLOSED" && renderChatMessages()}
              {chatStatus === "CLOSED" ? (
                <Row gutter={20} className="reward-input-box">
                  {satisfactionLevels.map((level, index) => (
                    <div
                      key={index}
                      className="equal-width-div"
                      onClick={() => handleRating(index)}
                      style={{
                        background: level.background,
                        borderRadius:
                          index === 0
                            ? "6px 0 0 6px"
                            : index === 4
                            ? "0 6px 6px 0"
                            : "",
                        border:
                          selectedRating === index &&
                          !(index === 5 || index === 6 || index === 7)
                            ? "2px solid #00406A"
                            : "",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40px",
                        width: index === 5 || index === 6 ? "150px" : "70px",
                        marginLeft: "10px",
                        color:
                          index === 5 || index === 6 || index === 7
                            ? "#000"
                            : "#fff",
                        fontWeight: "500",
                        opacity: index === 5 || index === 6 ? 0.8 : 1,
                      }}
                    >
                      {selectedRating === index &&
                      !(index === 5 || index === 6 || index === 7) ? (
                        <CheckOutlined />
                      ) : (
                        level.text
                      )}
                    </div>
                  ))}
                </Row>
              ) : (
                ""
              )}
              {loading && (
                <div className="user-chat-loader">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
            </Col>
          ) : (
            ""
          )}

          <Col
            md={24}
            className="d-flex justify-content-center user-chat-assistance chat-input"
          >
            <Input
              placeholder={
                chatStatus === "CLOSED"
                  ? "Refresh to initiate a new chat"
                  : "Type a message"
              }
              value={convo}
              disabled={chatStatus === "CLOSED" ? true : false}
              onChange={handleChatValue}
              onKeyDown={detectEnterPress}
            />

            <Upload
              {...imageProps}
              showUploadList={false}
              onPreview={onPreview}
              disabled={chatStatus === "CLOSED" ? true : false}
              className="basic-img-upload chat-upload"
              beforeUpload={beforeUpload}
            >
              <img src={attach} alt="" srcset="" />
            </Upload>
            {convo.trim() !== "" && !sendDisabled ? (
              <img
                src={send}
                alt=""
                srcset=""
                onClick={
                  convo?.trim() !== ""
                    ? () => handleChat(undefined)
                    : console.log("Enter message to send")
                }
              />
            ) : (
              <img src={disableSend} alt="" srcset="" />
            )}
          </Col>
        </Card>
      </div>
    </>
  );
}
