// this is created for pages which have padding on top and left

import { Loading3QuartersOutlined, RightOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row, Typography } from "antd";
import React from "react";

function Container(props) {
  const { children, style, title, BreadcrumbItems, Actions, isLoading } = props;
  return (
    <div style={style}>
      <Row justify="center">
        <Col
          span={20}
          xl={20}
          md={22}
          sm={24}
          xs={24}
          style={{ padding: "0px 0px" }}
        >
          {/* <Typography.Title className="fs-24 " level={3}>
            {title}{" "}
            {isLoading && <Loading3QuartersOutlined spin className="fs-18" />}
          </Typography.Title> */}
          <Row justify="space-between" align="center">
            <Col style={{ display: "flex", alignItems: "center" }}>
              {BreadcrumbItems && (
                <Breadcrumb separator={<RightOutlined />}>
                  {BreadcrumbItems?.map((item, index) => (
                    <Breadcrumb.Item key={index}>{item}</Breadcrumb.Item>
                  ))}
                </Breadcrumb>
              )}
            </Col>
            <Col>{Actions}</Col>
          </Row>
          {children}
        </Col>
      </Row>
    </div>
  );
}

export default Container;
