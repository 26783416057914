import { Col, Row, Card } from "antd";
import Container from "commons/Container";
import React from "react";
import "./BillingPlan.less";
import { loadStripe } from "@stripe/stripe-js";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import BillingCard from "./BillingCards";
import { ListBillingPlans } from "utility/action";

export default function BillingPlan() {
  const [selectedPlan, setSelectedPlan] = useState("");
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PK}`);
  const [stripe, setStripe] = useState([]);
  const [individualPlanLoading, setIndividualPlanLoading] = useState(false);
  const [familyPlanLoading, setFamilyPlanLoading] = useState(false);
  const userData = useSelector((state) => state?.userReducer?.userData);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedPlanFromUrl = searchParams.get("selectedPlan");

  const getOrgCheckoutSession = async (planId, stripePriceId) => {
    let url = `/billing/checkout/org`;
    try {
      const myInit = {
        body: {
          stripePriceId,
          accountId: userData?.id,
          planId,
        },
      };

      let result = await API.post("bazarhealthREST", url, myInit);
      return result;
    } catch (e) {
      console.log("error retrieving data", e);
      return { error: "Failed to fetch session" };
    }
  };

  const createStripeSession = async (planId, stripePriceId) => {
    try {
      let sessionResult = await getOrgCheckoutSession(planId, stripePriceId);
      if (sessionResult.type === "checkout" && sessionResult.sessionId) {
        stripe
          .redirectToCheckout({
            sessionId: sessionResult.sessionId,
          })
          .catch((e) => {
            console.log(e);
          });
      } else {
        console.log("err");
      }
    } catch (error) {
      console.log("error creating stripe session", error);
    }
  };

  const handlePlanCheckout = async (filterCriteria, setLoadingState) => {
    setLoadingState(true);
    try {
      const res = await ListBillingPlans(filterCriteria);
      const planArray = await Promise.all(
        res?.filter((param) => {
          const details = JSON.parse(param?.planDetails);
          return details?.planName === selectedPlan;
        })
      );
      const obj = JSON.parse(planArray[0].planDetails);
      await createStripeSession(planArray[0].id, obj?.stripePriceId);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingState(false);
    }
  };

  const onChange = (e) => {
    setSelectedPlan(e.target.value);
  };

  const resolveStripe = async () => {
    let s = await stripePromise;
    setStripe(s);
  };

  useEffect(() => {
    resolveStripe();

    if (selectedPlanFromUrl) {
      setSelectedPlan(selectedPlanFromUrl);
    }
  }, [location.search]);

  return (
    <>
      <Container>
        <Row className="top-box"></Row>

        <Card className="mt-10 mb-20 card-container">
          <Row justify="center" className="billing-title mt-30">
            <h1>Delphi Subscription Plan</h1>
            <p>Simplify healthcare, Increase access, Reduce costs.</p>
          </Row>

          <>
            <Row justify="center" className="mt-10">
              <Col xl={17} md={24}>
                <Row gutter={[20]}>
                  <BillingCard
                    title="Individual plan"
                    monthlyValue="INDIVIDUAL_MEMBERSHIP_MONTHLY"
                    annualValue="INDIVIDUAL_MEMBERSHIP_ANNUALLY"
                    selectedPlan={selectedPlan}
                    onChange={onChange}
                    selectedPlanFromUrl={selectedPlanFromUrl}
                    onCheckout={() =>
                      handlePlanCheckout(
                        { name: { contains: "INDIVIDUAL" } },
                        setIndividualPlanLoading
                      )
                    }
                    loading={individualPlanLoading}
                  />

                  <BillingCard
                    title="Family plan"
                    monthlyValue="FAMILY_MEMBERSHIP_MONTHLY"
                    annualValue="FAMILY_MEMBERSHIP_ANNUALLY"
                    selectedPlan={selectedPlan}
                    onChange={onChange}
                    selectedPlanFromUrl={selectedPlanFromUrl}
                    onCheckout={() =>
                      handlePlanCheckout(
                        { name: { contains: "FAMILY" } },
                        setFamilyPlanLoading
                      )
                    }
                    loading={familyPlanLoading}
                    isPopular={true}
                  />
                </Row>
              </Col>
            </Row>

            <Row className="bottom-box"></Row>
          </>
        </Card>
      </Container>
    </>
  );
}
