import GlobalJoyride from "utility/GlobalJoyride";
import TourStepContent from "utility/TourStepContent";

export default function DoctorTour(props) {
  const doctorSteps = [
    {
      target: ".doc-first-step",
      content: (
        <TourStepContent
          heading="Doctor"
          details="Click to choose your desired doctor and proceed to the next
              step for assistance."
          numbers={{ currentStep: 1, totalSteps: 9 }}
          onSkipTour={() => props?.skipTour("closeModal")}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".doc-second-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click the Specialty that you are looking for"
          numbers={{ currentStep: 2, totalSteps: 9 }}
          onSkipTour={() => props?.skipTour("closeModal")}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".doc-third-step",
      content: (
        <TourStepContent
          heading="Specialty"
          details="Click to continue for List."
          numbers={{ currentStep: 3, totalSteps: 9 }}
          onSkipTour={() => props?.skipTour("closeModal")}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={doctorSteps}
        run={props?.docTour}
        stepIndex={props?.docTourIndex}
        setTourIndex={props?.setDocTourIndex}
      />
    </>
  );
}
