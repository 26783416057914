import * as actions from "../actions/index";

const initialState = {
  chatCount: null,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_CHAT_COUNT:
      return {
        ...state,
        chatCount: action.payload,
      };

    default:
      return state;
  }
};
export default chatReducer;
