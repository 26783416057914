import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Select,
  Table,
  Image,
  message,
  Space,
} from "antd";
import downloadIcon from "assets/icons/downloadIc.svg";
import document from "assets/img/documentDownloads.svg";
import previewIcon from "assets/icons/previewIc.svg";
import React, { useState } from "react";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import { GetEmployeeDetails, GetRewardSubmissions } from "utility/action";
import { API } from "aws-amplify";
import { useCallback } from "react";
import { formatNumberUS } from "utility/constant";

export default function CloseEobDetail() {
  const [eobData, setEobData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [preSignedImg, setPreSignedimg] = useState([]);

  const location = useLocation();
  const eobId = location?.state.id;

  const fetchData = useCallback(async () => {
    try {
      setskeletonLoader(true);
      const response = await GetRewardSubmissions(eobId);
      setEobData(response);
      if (response?.uploadedImages.length !== 0) {
        let url = `/superAdmin/send_presigned_url`;
        const myInit = {
          body: {
            keys: response?.uploadedImages,
          },
        };
        let result = await API.post("bazarhealthREST", url, myInit);
        setPreSignedimg(result);
      }
      await GetEmployeeDetails(response?.customerID).then((empRes) => {
        setEmployee(empRes);
      });
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setskeletonLoader(false);
    }
  }, [eobId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: "",
      dataIndex: "label1",
      render: (item) => {
        return <strong>{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data1",
    },
    {
      title: "",
      dataIndex: "label2",
      className: "border-left",
      render: (item) => {
        return <strong>{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data2",
      render: (value, id) => {
        if (id?.key === "4") {
          formatNumberUS(value);
        } else {
          return value;
        }
      },
    },
  ];

  const data = [
    {
      key: "1",
      label1: "Claim created:",
      data1: new Date(eobData?.dateSubmitted).toLocaleDateString(),

      label2: "Claim for:",
      data2: eobData?.name,
    },
    {
      key: "2",
      label1: "Procedure:",
      data1: eobData?.nameOfProcedure,
      label2: "Type of care",
      data2: eobData?.typeOfCare,
    },
    {
      key: "8",
      label1: "Status:",
      data1: eobData?.status,
      label2: "Reward",
      data2: `$${parseInt(eobData?.rewardAmount).toLocaleString()}`,
    },
    {
      key: "9",
      label1: "Approved on:",
      data1: new Date(eobData?.updatedAt).toLocaleDateString(),
      label2: "Approved by:",
      data2: eobData?.approvedBy,
    },
    {
      key: "3",
      label1: "Employer:",
      data1: employee?.companyID?.name,
      label2: "Insurance carrier:",
      data2: employee?.profile?.insuranceCarrier,
    },
    {
      key: "6",
      label1: "Member id:",
      data1: employee?.profile?.memberID,
      label2: "Plan id:",
      data2: employee?.profile?.planID,
    },
    {
      key: "4",
      label1: "Email:",
      data1: eobData?.customerEmail,
      //   data1: employee?.profile?.city,
      label2: "Phone:",
      data2: employee?.profile?.phone,
    },
    {
      key: "5",
      label1: "Address: ",
      data1: employee?.profile?.zip,
    },
  ];

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {eobData?.name}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]}>
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />

                <SkeletonBlock style={{ width: 740, height: 500 }} />
              </Row>
            </>
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={data}
                className="common-table no-header"
                scroll={{ x: 692 }}
                showHeader={false}
                pagination={false}
              />
            </>
          )}
        </Col>

        <Col span={16} md={16} xs={24} sm={24} className="mt-30">
          <Typography.Title level={4}>File</Typography.Title>

          {skeletonLoader ? (
            <Row gutter={[20, 20]} className="mt-3">
              <SkeletonBlock style={{ width: 200, height: 200 }} />
              <SkeletonBlock style={{ width: 200, height: 200 }} />
              <SkeletonBlock style={{ width: 200, height: 200 }} />
            </Row>
          ) : (
            <>
              <Row gutter={[16, 16]}>
                {preSignedImg.length === 0 ? (
                  <Col span={16} md={16} xs={24} sm={24}>
                    <Typography.Text level={4}>No Images</Typography.Text>
                  </Col>
                ) : (
                  <Row gutter={[16, 16]} className="w-100">
                    {preSignedImg.map((image, index) => (
                      <Col span={12} md={8} xs={24} sm={24} key={index}>
                        <div className="image-preview">
                          <Image
                            src={
                              image.fileExtension === "png" ||
                              image.fileExtension === "jpeg" ||
                              image.fileExtension === "jpg"
                                ? image?.fileKey
                                : document
                            }
                            className="image-preview-img"
                            onClick={() => setPreviewIndex(index)}
                            preview={{
                              visible:
                                isPreviewVisible && previewIndex === index,
                              onVisibleChange: (visible, prevVisible) => {
                                if (!visible) setPreviewIndex(null);
                                setPreviewVisible(visible);
                              },
                            }}
                          />
                          <div className="hide-icon">
                            <Space>
                              {image.fileExtension === "png" ||
                              image.fileExtension === "jpeg" ||
                              image.fileExtension === "jpg" ? (
                                <img
                                  src={previewIcon}
                                  alt="preview-icon"
                                  onClick={() => {
                                    setPreviewIndex(index);
                                    setPreviewVisible(true);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <a href={image?.fileKey} download>
                                <img src={downloadIcon} alt="download-icon" />
                              </a>
                            </Space>
                          </div>
                        </div>

                        <Typography.Title
                          level={5}
                          style={{
                            textAlign: "center",
                            bottom: 0,
                            margin: "10px 0",
                          }}
                        >
                          {image?.fileName}
                        </Typography.Title>
                      </Col>
                    ))}
                  </Row>
                )}
              </Row>
            </>
          )}
        </Col>
      </Row>
    </>
  );
}
