import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Layout,
  Menu,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Row,
  message,
  Alert,
  Col,
  Card,
  Grid,
  Divider,
  Space,
  Drawer,
  Tooltip,
  Badge,
} from "antd";
import supportIcon from "assets/icons/support.svg";
import Container from "./../../commons/Container";
import copy from "assets/icons/copy.svg";
import successIcon from "assets/icons/Success.svg";
import notSure from "assets/icons/notsure.svg";
import notification from "assets/menu-icons/user-notification.svg";
import email from "assets/icons/email.svg";
import phone from "assets/icons/call.svg";
import warning from "assets/icons/upgrade.svg";
import empty from "assets/icons/emptyBell.svg";

import moment from "moment";
import {
  ClockCircleOutlined,
  SearchOutlined,
  LeftOutlined,
} from "@ant-design/icons";
import employee from "assets/icons/Dependant.svg";
import member from "assets/icons/Employees.svg";
import logo from "assets/delphy/delphy.svg";
import invite from "assets/icons/invite.svg";
import address from "assets/icons/location.svg";
import "./MainLayout.less";
import { Link, useNavigate } from "react-router-dom";
import { API, Auth, Hub } from "aws-amplify";
import { LOGOUT } from "store/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  DOCTOR_TOUR_START,
  emailPattern,
  END_TOUR,
  IMAGING_TOUR_START,
  INFUSION_TOUR_START,
  LAB_TOUR_START,
  notificationTopics,
  PT_TOUR_START,
  SURGICAL_TOUR_START,
} from "utility/constant";
import { UserOutlined } from "@ant-design/icons";
import SuccessModel from "utility/SuccessModel";
import {
  GetEmployeeDetails,
  ListEligibleMembers,
  UpdateEmployeeRegi,
} from "utility/action";
import { getEmployeeRegi, getMember, listMembers } from "graphql/queries";
import tourLeft from "assets/icons/tourLeft.svg";
import tourRight from "assets/icons/tourRight.svg";
import bazarLogo from "assets/delphy/delphy.svg";
import mobileInvite from "assets/icons/mobile-invite.svg";
import faq from "assets/icons/faq.svg";
import { updateEmployeeProfile, updateMemberProfile } from "graphql/mutations";
import { setUserData } from "store/actions/userData";
import { setTourSteps } from "store/actions/tourSteps";
import GlobalSearch from "pages/user/GlobalSearch/GlobalSearch";
import MenuBar from "pages/user/Home/MenuBar";
import AdminInviteEmployee from "./Tours/AdminInviteEmployee";
import AdminInviteDependent from "./Tours/AdminInviteDependent";
import InviteDependent from "./Tours/InviteDependent";
import HomeTour from "./Tours/HomeTour";
import AdminInvite from "./AdminInvite";
import { GetFileFromS3 } from "utility/uploadFileToS3";
import IndividualInvite from "./InviteModals/IndividualInvite";
import UserNotification from "pages/admin/Notification/UserNotification";
import { setToken } from "store/actions/loginAction";
import { requestPermission } from "pages/user/PushNotifications/firebase";
import { getMessaging, onMessage } from "firebase/messaging";
import UserChat from "pages/user/SupportChatbox/UserChat";
import { onCreateChat } from "graphql/subscriptions";
import newMessageSound from "assets/sounds/Notification.mp3";
import { listChats } from "graphql/customQuery";

export default function UserLayout({ props }) {
  const { Header, Content } = Layout;
  const tabLocation = localStorage.getItem("activeTabKey");
  const sound = new Audio(newMessageSound);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [inviteModel, setInviteModel] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [successModelAdmin, setSuccessModelAdmin] = useState(false);
  const [invsuccessModel, setInvSuccessModel] = useState(false);
  const [error, setError] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const [userDp, setUserDp] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [invitePromptModal, setInvitePromptModal] = useState(false);
  const [dependentList, setDependentList] = useState([]);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [emailVisible, setEmailVisible] = useState(false);
  const [inviteIndDependent, setInviteIndDependent] = useState(false);
  const [inviteIndDependentTour, setInviteIndDependentTour] = useState(false);
  const [timer, setTimer] = useState({});
  const [registered, setRegistered] = useState(null);
  const [intervalId, setIntervalId] = useState(null);
  const [tourModal, setTourModal] = useState(false);
  const [notSureTourModal, setNotSureTourModal] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isInviteCompleted, setIsInvitedComplete] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [isTourRunning, setIsTourRunning] = useState(false);
  const [inviteTourIndex, setInviteTourIndex] = useState(0);
  const [inviteAdminTourIndex, setInviteAdminTourIndex] = useState(0);
  const [inviteTour, setInviteTour] = useState(false);
  const [inviteAdminTour, setInviteAdminTour] = useState(false);
  const [inviteAdminTourDep, setInviteAdminTourDep] = useState(false);
  const [inviteAdminTourDepIndex, setInviteAdminTourDepIndex] = useState(0);
  const [openInviteAfterVisit, setOpenInviteAfterVisit] = useState(false);
  const [contactus, setContactUs] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [notSureModal, setNotSureModal] = useState(false);
  const [upgradePlanModal, setUpgradePlanModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storedActiveTabKey = localStorage.getItem("activeTabKey");
  const userProfileData = useSelector((state) => state.userReducer?.userData);
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);
  const companyID = userProfileData?.companyRegiEmployeeId;
  const companyDetails = userProfileData?.companyID;
  const accountStatus = userProfileData?.accountStatus;
  const firstVisit = userProfileData?.profile;
  const firstVisitProfile = userProfileData?.profile?.firstVisit;
  const profileCompeleted = firstVisit?.profileCompleted;
  const allMembers = userProfileData?.members?.items;
  const userType = userProfileData?.userType;
  const billingStatus = userProfileData?.billing_status;
  const verified = userProfileData?.verified;
  const planObj = JSON.parse(userProfileData?.plan?.planDetails || "{}");
  const individualPlan =
    planObj?.planName === "INDIVIDUAL_MEMBERSHIP_MONTHLY" ||
    planObj?.planName === "INDIVIDUAL_MEMBERSHIP_ANNUALLY";

  const corporatePlan = planObj && !individualPlan;

  let url = window.location.href;
  const urlPath = window.location.pathname;

  const isHomePage = window.location.pathname === "/";

  const [currentPlatform, setCurrentPlatform] = useState(
    localStorage.getItem("currentPlatform")
  );

  const zeroPad = (num, places) => String(num).padStart(places, "0");
  const name = userProfileData?.firstName;
  const rawZip = userProfileData?.profile?.zip;
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const redirectTourStart = localStorage.getItem("redirectTourStart");
  const hostWithProtocol =
    window.location.protocol + "//" + window.location.host;

  const isIndividual =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    userType === "INDIVIDUAL" &&
    !companyID &&
    !companyDetails;
  const isUnderStripeEmployer =
    (billingStatus === "REQUIRED" || billingStatus === null) &&
    (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
    companyID !== null &&
    companyDetails !== null;
  // Drawer stuff
  const [open, setOpen] = useState(false);

  const onClose = () => {
    setOpen(false);
  };

  const [text, setText] = useState(
    `${hostWithProtocol}/signup/?id=${companyID}`
  );
  const [form] = Form.useForm();
  const [memberForm] = Form.useForm();

  const [notificationState, setNotificationState] = useState([]);

  const openFirstTimePopup = async () => {
    const inputData = {
      input: {
        email: firstVisit?.email,
        firstVisit: {
          doctor: firstVisit?.doctor === true ? true : false,
          imaging: firstVisit?.imaging === true ? true : false,
          infusion: firstVisit?.infusion === true ? true : false,
          lab: firstVisit?.lab === true ? true : false,
          physicalTherapy: firstVisit?.physicalTherapy === true ? true : false,
          surgery: firstVisit?.surgery === true ? true : false,
          firstVisit: true,
          homePage: firstVisit?.homePage === true ? true : false,
          procedure: firstVisit?.procedure === true ? true : false,
          reward: firstVisit?.reward === true ? true : false,
        },
      },
    };
    if (
      userProfileData?.role === "DEPENDANT" &&
      firstVisitProfile?.firstVisit === false &&
      accountStatus === "ACTIVE" &&
      (url === "http://localhost:3000/" ||
        url === "https://stage.delphihc.com/" ||
        url === "https://member.delphihc.com/")
    ) {
      setTourModal(true);
      setNotSureTourModal(true);
      await API.graphql({
        query: updateMemberProfile,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const memRegi = await API.graphql({
        query: getMember,
        variables: {
          id: userProfileData?.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      dispatch(setUserData(memRegi.data.getMember));
    } else if (
      firstVisitProfile?.firstVisit === false &&
      accountStatus === "ACTIVE" &&
      userType === "INDIVIDUAL" &&
      (url === "http://localhost:3000/" ||
        url === "https://stage.delphihc.com/" ||
        url === "https://member.delphihc.com/")
    ) {
      setTourModal(true);

      if (
        planObj?.planName === "INDIVIDUAL_MEMBERSHIP_MONTHLY" ||
        planObj?.planName === "INDIVIDUAL_MEMBERSHIP_ANNUALLY"
      ) {
        setNotSureTourModal(true);
      }

      setOpenInviteAfterVisit(true);

      await API.graphql({
        query: updateEmployeeProfile,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const empRegi = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: userProfileData?.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      dispatch(setUserData(empRegi.data.getEmployeeRegi));
      // }
    } else if (
      firstVisitProfile?.firstVisit === false &&
      (userType === "CORPORATE" || userType === "CORPORATE_ADMIN") &&
      (url === "http://localhost:3000/" ||
        url === "https://stage.delphihc.com/" ||
        url === "https://member.delphihc.com/")
    ) {
      setTourModal(true);
      setOpenInviteAfterVisit(true);
      await API.graphql({
        query: updateEmployeeProfile,
        variables: inputData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const empRegi = await API.graphql({
        query: getEmployeeRegi,
        variables: {
          id: userProfileData?.id,
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      dispatch(setUserData(empRegi.data.getEmployeeRegi));
    }
  };

  const showModal = () => {
    setOpen(false);
    if (inviteTour) {
      setTimeout(() => {
        setInviteTourIndex(1);
      }, 500);
      setInviteIndDependentTour(true);
    } else if (userType === "INDIVIDUAL") {
      setInviteIndDependent(true);
    } else if (inviteAdminTourDep) {
      setTimeout(() => {
        setInviteAdminTourDepIndex(2);
      }, 500);
      setIsModalOpen(true);
    } else {
      setIsModalOpen(true);
    }
  };

  const handleOk = () => {
    memberForm.resetFields();
    setEmailVisible(false);
    setInvSuccessModel(false);
    setShowInput(false);
    setIsModalOpen(false);
    setInviteModel(false);
    setInvitePromptModal(false);
    setInviteIndDependent(false);
    setDefaultEmail("");
    setTourModal(false);
    setNotSureTourModal(false);
    setContactUs(false);
  };

  const handleChange = async (value) => {
    if (value === undefined) {
      clearInterval(intervalId);
      setIntervalId(null);
      setEmailVisible(false);
      setRegistered(null);
      return;
    }

    const output = dependentList.filter((index) => value === index.id);
    setDefaultEmail(output[0].email);

    if (output[0]?.lastSend !== null) {
      const responce = await API.graphql({
        query: listMembers,
        variables: {
          filter: {
            email: { eq: output[0].email },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      if (responce.data.listMembers.items.length === 0) {
        setRegistered(false);
        const timestamp = new Date(output[0]?.lastSend);

        const remainingTime =
          timestamp.getTime() + 24 * 60 * 60 * 1000 - Date.now();
        const remainingHours = Math.floor(remainingTime / (1000 * 60 * 60));
        const remainingMinutes = Math.floor(
          (remainingTime % (1000 * 60 * 60)) / (1000 * 60)
        );
        let remainingSeconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        setTimer({
          remainingHours: remainingHours,
          remainingMinutes: remainingMinutes,
          remainingSeconds: remainingSeconds,
        });

        const durationInMs = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
        const now = Date.now();
        const disableUntil = new Date(timestamp).getTime() + durationInMs;

        if (disableUntil > now) {
          setIsDisabled(true);

          // Clear previous interval before setting a new one
          if (intervalId !== null) {
            clearInterval(intervalId);
          }

          const remainingTimeInMs = disableUntil - now;
          setTimeout(() => {
            setIsDisabled(false);
          }, remainingTimeInMs);

          const newIntervalId = setInterval(() => {
            const now = new Date().getTime();
            const remainingTimeInMs = disableUntil - now;
            if (remainingTimeInMs <= 0) {
              clearInterval(newIntervalId);
              setTimer({
                remainingHours: 0,
                remainingMinutes: 0,
                remainingSeconds: 0,
              });
              setIsDisabled(false);
              return;
            }
            const remainingHours = Math.floor(
              remainingTimeInMs / (1000 * 60 * 60)
            );
            const remainingMinutes = Math.floor(
              (remainingTimeInMs % (1000 * 60 * 60)) / (1000 * 60)
            );
            const remainingSeconds = Math.floor(
              (remainingTimeInMs % (1000 * 60)) / 1000
            );
            setTimer({
              remainingHours,
              remainingMinutes,
              remainingSeconds,
            });
          }, 1000);

          setIntervalId(newIntervalId);
        }
      } else {
        setRegistered(true);
        clearInterval(intervalId);
        setIntervalId(null);
        setTimer({
          remainingHours: 0,
          remainingMinutes: 0,
          remainingSeconds: 0,
        });
      }
    }

    setEmailVisible(true);
    memberForm.setFieldsValue({
      depEmail: output[0].email || "",
    });
  };

  const fetchMembers = useCallback(async () => {
    try {
      const filter = {
        email: { eq: userProfileData?.emailID },
      };
      await ListEligibleMembers(filter, 999).then(async (response) => {
        if (response.length > 0) {
          let memID = response[0]?.memberIDSeq.slice(0, -1);
          const filter = {
            memberIDSeq: { contains: memID },
            memberSfx: { ne: 0 },
          };

          const dependentList = await ListEligibleMembers(filter, 999);
          setDependentList(dependentList);
        }
      });
    } catch (err) {
      console.log(err);
    } finally {
      // setLoading(false);
    }
  }, [userProfileData?.emailID]);

  const handleLogout = async () => {
    await Auth.signOut({
      global: true,
    })
      .then((data) => {
        dispatch({
          type: LOGOUT,
        });
        localStorage.clear();
        navigate("/login");
      })
      .catch((err) => {
        console.log(err);
        navigate("/login");
      });
  };

  function UserImg() {
    return (
      <img
        src={userDp}
        className="user-imges"
        alt=""
        // style={{ borderRadius: "100px" }}
      />
    );
  }

  const getMyReward = () => {
    setOpen(false);
    localStorage.setItem("activeTabKey", "item-5");
    localStorage.setItem("startRewardTour", true);
    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  const navigateToProcedure = () => {
    setOpen(false);
    localStorage.setItem("activeTabKey", "item-3");
    localStorage.setItem("startProTour", true);
    setTimeout(() => {
      navigate("/");
    }, 100);
  };

  const handleAdminTour = () => {
    setOpen(false);
    if (inviteAdminTour) {
      setTimeout(() => {
        setInviteAdminTourIndex(1);
      }, 400);
      setInvitePromptModal(true);
    } else if (inviteAdminTourDep) {
      setInvitePromptModal(true);
      setTimeout(() => {
        setInviteAdminTourDepIndex(1);
      }, 400);
    } else {
      setInvitePromptModal(true);
    }
  };

  const runTour = async () => {
    setIsInvitedComplete(false);
    setIsSearchActive(false);
    setOpen(false);
    if (url === "http://localhost:3000/" && activeTab === "item-2") {
      setIsTourRunning(true);
    } else {
      localStorage.setItem("activeTabKey", "item-2");
      localStorage.setItem("redirectTourStart", true);
      setIsTourRunning(true);
      navigate("/");
    }
  };

  const startTour = (tourType) => {
    setOpen(false);
    if (isHomePage) {
      localStorage.setItem("activeTabKey", "item-2");
      dispatch(setTourSteps(tourType));
    } else {
      localStorage.setItem("activeTabKey", "item-2");
      dispatch(setTourSteps(tourType));
      setTimeout(() => {
        navigate("/");
      }, 200);
    }
  };

  const updateMenuClassNames = (menuItems, isParent, isMobile) => {
    return menuItems.map((item) => {
      const updatedItem = { ...item };

      if (isParent && isMobile) {
        updatedItem.className = "menu";
      } else if (!isParent && isMobile) {
        updatedItem.className = "sub-menu";
      }

      if (item.children) {
        updatedItem.children = updateMenuClassNames(
          item.children,
          false,
          isMobile
        );
      }

      return updatedItem;
    });
  };

  //Function to display user name with image
  function WelcomeMessage() {
    const profilePhoto =
      userProfileData?.profile?.profilePhoto === null ? (
        <UserOutlined style={{ marginRight: "5px" }} />
      ) : (
        <UserImg />
      );

    return (
      <span className="button-font fw-600 second-step invite-skip-btn">
        {profilePhoto} Welcome, {name}!
      </span>
    );
  }

  // const initializeFirebaseAndSubscribe = () => {
  //   try {
  //     const channel = new BroadcastChannel("delphi-notification-channel");
  //     // Add a listener to receive messages from the Service Worker
  //     channel.onmessage = (event) => {
  //       const notification = event.data?.notification;

  //       // Update the state to display the notification
  //       const newNotification = {
  //         title: notification?.title,
  //         body: notification?.body,
  //       };

  //       setNotificationState((prevNotifications) => [
  //         newNotification,
  //         ...prevNotifications,
  //       ]);
  //     };

  //     const messaging = getMessaging();
  //     onMessage(messaging, (payload) => {
  //       console.log("Message received. ", payload);

  //       const newNotification = {
  //         title: payload?.notification?.title,
  //         body: payload?.notification?.body,
  //       };

  //       setNotificationState((prevNotifications) => [
  //         newNotification,
  //         ...prevNotifications,
  //       ]);
  //     });

  //     // Clean up the channel when the component unmounts
  //     return () => {
  //       channel.close();
  //     };
  //   } catch (error) {
  //     console.error("Error:", error);
  //   } finally {
  //     // setInitLoading(false);
  //   }
  // };

  const initializeFirebaseAndSubscribe = () => {
    try {
      const channel = new BroadcastChannel("delphi-notification-channel");
      // Add a listener to receive messages from the Service Worker
      channel.onmessage = (event) => {
        const notification = event.data?.notification;

        // Update the state to display the notification
        const newNotification = {
          title: notification?.title,
          body: notification?.body,
        };

        setNotificationState((prevNotifications) => {
          // Check if the new notification's title is not already in the state
          if (
            !prevNotifications.some((n) => n.title === newNotification.title)
          ) {
            return [newNotification, ...prevNotifications];
          }
          return prevNotifications;
        });
      };

      const messaging = getMessaging();
      onMessage(messaging, (payload) => {
        const newNotification = {
          title: payload?.notification?.title,
          body: payload?.notification?.body,
        };

        setNotificationState((prevNotifications) => {
          // Check if the new notification's title is not already in the state
          if (
            !prevNotifications.some((n) => n.title === newNotification.title)
          ) {
            return [newNotification, ...prevNotifications];
          }
          return prevNotifications;
        });
      });

      // Clean up the channel when the component unmounts
      return () => {
        channel.close();
      };
    } catch (error) {
      console.error("Error:", error);
    } finally {
      // setInitLoading(false);
    }
  };

  useEffect(() => {
    initializeFirebaseAndSubscribe();
  }, [setNotificationState]);

  useEffect(() => {
    initializeFirebaseAndSubscribe();
  }, [setNotificationState]);

  //funtion to generate divider betweeen menu items conditionally
  const generateDividerMenuItem = (condition, type) => {
    if (condition) {
      if (type === "Divider") {
        return {
          label: <Divider type="vertical" className="divider" />,
          // key: "invDivider",
        };
      } else if (type === "notification") {
        return {
          label: "",
          key: "notification",
          className: "nav-address",
          icon: (
            <Tooltip
              placement="bottomRight"
              overlayClassName="user-notification"
              color="#fff"
              title={
                <UserNotification
                  notificationState={notificationState}
                  setNotificationState={setNotificationState}
                />
              }
            >
              <img
                src={notificationState.length === 0 ? empty : notification}
                alt=""
              />
            </Tooltip>
          ),
          disabled: true,
        };
      }
      return {
        label: <span className="button-font">{zeroPad(rawZip, 5)}</span>,
        key: "address",
        className: "nav-address",
        icon: rawZip !== null ? <AddressIcon /> : "",
        disabled: true,
      };
    }
    return null;
  };

  //generate different invite button based on user roles
  const generateInviteButton = (
    condition,
    onClickAction,
    className,
    title,
    disable
  ) => {
    if (condition) {
      return (
        <Button
          className={`${className} ${isTourRunning ? "user-select-none" : ""} `}
          onClick={
            individualPlan ? () => setUpgradePlanModal(true) : onClickAction
          }
          disabled={disable}
        >
          {title}
        </Button>
      );
    }
    return null; // Return null if the condition is not met
  };

  //Multiple Invite button of user roles
  const individualInvite = generateInviteButton(
    accountStatus === "ACTIVE" || userProfileData?.DpcDetails !== null,
    showModal,
    "blue-btn inv-first-step first-step",
    "Invite Dependent",
    allMembers?.length < 5 ? false : true
  );

  const employeeInvite = generateInviteButton(
    accountStatus === "ACTIVE",
    showModal,
    "blue-btn first-step admin-dep-first-step",
    "Invite Dependent"
  );

  const adminInvite = generateInviteButton(
    accountStatus === "ACTIVE",
    () => {
      setOpen(false);
      setInvitePromptModal(true);
    },
    "blue-btn first-step inv-first-step admin-first-step admin-dep-first-step",
    "Invite"
  );

  const hpiAdminInvite = generateInviteButton(
    accountStatus === "ACTIVE",
    handleAdminTour,
    "blue-btn first-step inv-first-step admin-first-step admin-dep-first-step",
    "Invite"
  );

  //Genrate labels for menu items
  const generateLabel = (
    text,
    onClickAction,
    key,
    className,
    children = null
  ) => {
    if (onClickAction || (children !== null && children.length > 0)) {
      const label = (
        <Link onClick={onClickAction}>
          <span className="logout-btn">{text}</span>
        </Link>
      );

      if (children !== null && children.length > 0) {
        return {
          label: label,
          key: key,
          className: className,
          children: children,
        };
      } else {
        return {
          label: label,
          key: key,
          className: className,
        };
      }
    }
    return null;
  };

  //Invite Dependent Labels for user roles
  const inviteDependentLabel = generateLabel(
    "Invite Dependent",
    () => {
      setOpen(false);
      setInviteTour(true);
    },
    "need:200",
    "h-30"
  );

  const inviteDependentHLabel = generateLabel(
    "Invite Dependent",
    () => {
      setOpen(false);
      setInviteAdminTourDep(true);
    },
    "needH:2",
    "h-30"
  );

  const inviteLabelWithChildren = generateLabel(
    "Invite",
    null,
    "needc:2",
    "h-30",
    [
      generateLabel(
        "Employee",
        () => {
          setOpen(false);
          setInviteAdminTour(true);
        },
        "needc:3",
        "h-30"
      ),
      generateLabel(
        "Dependent",
        () => {
          setOpen(false);
          setInviteAdminTourDep(true);
        },
        "setting:2"
      ),
    ]
  );

  //function to return invite button
  const getInviteBtn = () => {
    switch (true) {
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE_ADMIN":
      case userType === "CORPORATE_ADMIN":
        return inviteLabelWithChildren;
      case billingStatus === "NOT_REQUIRED" &&
        userType === "CORPORATE" &&
        userProfileData?.role === "DEPENDANT":
        return null;
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE":
        return inviteDependentHLabel;

      case userType === "INDIVIDUAL":
        return inviteDependentLabel;
      default:
        return null;
    }
  };

  //common fields of menu
  const commonFields = [
    {
      label: (
        <Link to="/user-profile">
          <span className="logout-btn">My Account</span>
        </Link>
      ),
      className: "h-30",
    },

    ...(userProfileData?.dpcDoctorID !== null ||
    userProfileData?.DpcDetails !== null ||
    currentPlatform === "DPC"
      ? [
          {
            label: (
              <Link
                to={currentPlatform === "DPC" ? "/" : "/dpc-home"}
                onClick={() => {
                  localStorage.setItem(
                    "currentPlatform",
                    currentPlatform === "DPC" ? "Advocacy" : "DPC"
                  );
                }}
              >
                <span className="logout-btn">
                  {currentPlatform === "DPC"
                    ? "Advocacy"
                    : "Enhanced Primary Care"}
                </span>
              </Link>
            ),
            key: "advocacy",
            className: "h-30",
          },
        ]
      : ""),

    ...(currentPlatform === "DPC"
      ? ""
      : [
          {
            label: (
              <Link to="/my-appointment">
                <span className="logout-btn">My Appointments</span>
              </Link>
            ),
            className: "h-30",
          },

          ...(accountStatus === "ACTIVE" && profileCompeleted === true
            ? [
                {
                  label: <span className="logout-btn">Explore Delphi</span>,
                  className: "h-30",
                  children: [
                    {
                      label: (
                        <div onClick={runTour}>
                          <span className="logout-btn">Home Page</span>
                        </div>
                      ),
                      key: "need:0",
                      className: "h-30",
                    },
                    {
                      label: <span className="logout-btn">Services</span>,
                      key: "services",
                      className: "h-30",
                      children: [
                        {
                          label: (
                            <div onClick={() => startTour(DOCTOR_TOUR_START)}>
                              <span className="logout-btn">Doctor</span>
                            </div>
                          ),
                          key: "doc",
                          className: "h-30",
                        },
                        {
                          label: (
                            <div onClick={() => startTour(SURGICAL_TOUR_START)}>
                              <span className="logout-btn">
                                Surgical Center
                              </span>
                            </div>
                          ),
                          key: "surgical",
                          className: "h-30",
                        },
                        {
                          label: (
                            <div onClick={() => startTour(IMAGING_TOUR_START)}>
                              <span className="logout-btn">Imaging Center</span>
                            </div>
                          ),
                          key: "imaging",
                          className: "h-30",
                        },
                        {
                          label: (
                            <div onClick={() => startTour(INFUSION_TOUR_START)}>
                              <span className="logout-btn">
                                Infusion Center
                              </span>
                            </div>
                          ),
                          key: "infusion",
                          className: "h-30",
                        },
                        {
                          label: (
                            <div onClick={() => startTour(LAB_TOUR_START)}>
                              <span className="logout-btn">Lab</span>
                            </div>
                          ),
                          key: "lab",
                          className: "h-30",
                        },

                        {
                          label: (
                            <div onClick={() => startTour(PT_TOUR_START)}>
                              <span className="logout-btn">
                                Physical Therapy
                              </span>
                            </div>
                          ),
                          key: "pt",
                        },
                      ],
                    },

                    ...(!isIndividual && !isUnderStripeEmployer
                      ? [
                          {
                            label: (
                              <Link onClick={getMyReward}>
                                <span className="logout-btn">
                                  Get My Reward
                                </span>
                              </Link>
                            ),
                            key: "getMyReward",
                            className: "h-30",
                          },
                        ]
                      : ""),

                    ...(userProfileData?.role === "DEPENDANT"
                      ? []
                      : getInviteBtn() !== null
                      ? [getInviteBtn()]
                      : []),

                    {
                      label: (
                        <Link to="/doctor-tour">
                          <span className="logout-btn">
                            Request Appointment
                          </span>
                        </Link>
                      ),
                      key: "need:3",
                      className: "h-30",
                    },
                    {
                      label: (
                        <Link onClick={navigateToProcedure}>
                          <span className="logout-btn">Procedures</span>
                        </Link>
                      ),
                      key: "need:4",
                    },
                  ],
                },
              ]
            : []),
        ]),

    {
      label: (
        <div>
          <span
            className="logout-btn"
            onClick={() => {
              setOpen(false);
              setContactUs(true);
            }}
          >
            Contact Delphi
          </span>
        </div>
      ),
      key: "contactBazar",
      className: "h-30",
    },
    {
      label: (
        <span onClick={handleLogout} className="logout-btn">
          Logout
        </span>
      ),
    },
  ];

  const individualMenu = [
    {
      label: individualInvite,
      key: "invite",
    },
    generateDividerMenuItem(
      accountStatus === "ACTIVE" || userProfileData?.DpcDetails !== null,
      "Divider"
    ),
    generateDividerMenuItem(rawZip !== null, "Zip"),
    generateDividerMenuItem(
      accountStatus === "ACTIVE" || userProfileData?.DpcDetails !== null,
      "Divider"
    ),
    generateDividerMenuItem(
      accountStatus === "ACTIVE" || userProfileData?.DpcDetails !== null,
      "notification"
    ),
    generateDividerMenuItem(rawZip !== null, "Divider"),
    {
      label: WelcomeMessage(),
      key: "user",
      className: "userName",
      children: [
        {
          label: billingStatus !== "REQUIRED" && (
            <Link
              to={
                currentPlatform === "DPC"
                  ? "/manage-billing?source=dpc"
                  : accountStatus === null || accountStatus === undefined
                  ? "/billing"
                  : "/manage-billing"
              }
            >
              <span className="logout-btn">
                {userProfileData?.dpcDoctorID !== null ||
                userProfileData?.DpcDetails !== null
                  ? "Manage Billing"
                  : accountStatus === null || accountStatus === undefined
                  ? "Activate Account"
                  : "Manage Billing"}
              </span>
            </Link>
          ),
          className: "h-30",
        },
        ...commonFields,
      ],
    },
  ];

  const indResponsiveMenu = [
    {
      label:
        billingStatus === "REQUIRED" ? null : (
          <>
            {accountStatus === null || accountStatus === undefined ? (
              <Link to="/billing">
                <span className="logout-btn">Activate Account</span>
              </Link>
            ) : (
              <Link to="/manage-billing">
                <span className="logout-btn">Manage Billing</span>
              </Link>
            )}
          </>
        ),
      key: "billing",
      className: "menu",
    },

    ...updateMenuClassNames(commonFields, true, true),
  ];

  const employeeMenu = [
    {
      label: employeeInvite,
      key: "hpiInvite",
    },
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(rawZip !== null, "Zip"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "notification"),
    generateDividerMenuItem(rawZip !== null, "Divider"),
    {
      label: WelcomeMessage(),
      key: "hpiuser",
      className: "userName",
      children: commonFields,
    },
  ];

  const employeeResponsiveMenu = [
    ...updateMenuClassNames(commonFields, true, true),
  ];

  const corporateMenu = [
    {
      label: adminInvite,
      key: "corporateInvite",
    },
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(rawZip !== null, "Zip"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "notification"),
    generateDividerMenuItem(rawZip !== null, "Divider"),
    {
      label: WelcomeMessage(),
      key: "cUser",
      className: "userName",
      children: [
        {
          label: (
            <>
              {userType === "CORPORATE_ADMIN" && (
                <>
                  {accountStatus === null || accountStatus === undefined ? (
                    <>
                      <Link to="/corporate-billing">
                        <span className="logout-btn">Activate Account</span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/manage-billing">
                        <span className="logout-btn">Manage Billing</span>
                      </Link>
                    </>
                  )}
                </>
              )}
            </>
          ),
          className: "h-30",
        },
        {
          label: (
            <Link to="/admin-approval">
              <span className="logout-btn">Manage Invitations</span>
            </Link>
          ),
          className: "h-30",
        },
        ...commonFields,
      ],
    },
  ];

  const adminMenu = [
    {
      label: hpiAdminInvite,
      key: "cnInvite",
    },
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(rawZip !== null, "Zip"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "Divider"),
    generateDividerMenuItem(accountStatus === "ACTIVE", "notification"),
    generateDividerMenuItem(rawZip !== null, "Divider"),
    {
      label: WelcomeMessage(),
      key: "cnUser",
      className: "userName",
      children: [
        {
          label: (
            <Link to="/admin-approval">
              <span className="logout-btn">Manage Invitations</span>
            </Link>
          ),
          className: "h-30",
        },

        ...commonFields,
      ],
    },
  ];

  const adminResponsiveMenu = [
    ...updateMenuClassNames(commonFields, true, true),
  ];

  const approvalMenu = [
    {
      label: `Welcome, ${name}!`,
      key: "apUser",
      className: "userName",
      children: [
        {
          label: (
            <Button onClick={handleLogout} className="logout-btn">
              Logout
            </Button>
          ),
        },
      ],
    },
  ];

  const approvalResponsiveMenu = [
    {
      label: `Welcome, ${name}!`,
      key: "apUser",
      className: "menu",
      children: [
        {
          label: (
            <span className="logout-btn" onClick={() => setNotSureModal(true)}>
              Contact Delphi
            </span>
          ),
          className: "h-30",
        },

        {
          label: (
            <Button onClick={handleLogout} className="logout-btn">
              Logout
            </Button>
          ),
          className: "sub-menu",
        },
      ],
    },
  ];

  const memberMenu = [
    {
      label: WelcomeMessage(),
      key: "memuser",
      className: "userName",

      children: commonFields,
    },
  ];

  const memberResponsiveMenu = [
    ...updateMenuClassNames(commonFields, true, true),
  ];

  function AddressIcon() {
    return <img src={address} className="address-icon" alt="" />;
  }

  const EmployeeInvite = async (e) => {
    setLoading(true);
    let url = `/employee/invite`;
    const inviteUrl = hostWithProtocol + `/signup/?id=${companyID}`;
    try {
      const myInit = {
        body: {
          userId: userProfileData?.id,
          emails: e?.emails,
          inviteUrl: inviteUrl,
          inviting: "Employee",
        },
      };
      await API.post("bazarhealthREST", url, myInit);
      setLoading(false);
      setInviteModel(false);
      setShowInput(false);
      setSuccessModel(true);
      form.resetFields();
      setNotSureTourModal(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);

      if (openInviteAfterVisit) {
        setTimeout(() => {
          setTourModal(true);
        }, 3000);
      }
    } catch (e) {
      setError(e.message?.message);
      messageApi.open({
        type: "error",
        content: "Error sending Invitation.",
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const IndInvite = async (values) => {
    setLoading(true);
    let url = `/employee/inviteIndiDep`;
    try {
      const myInit = {
        body: {
          email: values?.email,
          employeeID: userProfileData?.id,
          firstName: values?.firstName,
          lastName: values?.lastName,
          relation: values?.relation,
          phone: values?.phone,
          dob: moment(values?.dob).format("YYYY-MM-DD"),
        },
      };

      await API.post("bazarhealthREST", url, myInit);
      setLoading(false);
      memberForm.resetFields();
      setInviteIndDependent(false);
      setSuccessModel(true);
      setNotSureTourModal(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      if (openInviteAfterVisit) {
        setTimeout(() => {
          setTourModal(true);
        }, 3000);
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const MemberInvite = async (e) => {
    setLoading(true);
    let url = `/employee/inviteDependentHPI`;
    try {
      const myInit = {
        body: {
          id: e.depName,
          email: e?.depEmail,
          employeeID: userProfileData?.id,
        },
      };
      await API.post("bazarhealthREST", url, myInit);
      setLoading(false);
      memberForm.resetFields();
      setIsModalOpen(false);
      setSuccessModel(true);
      setNotSureTourModal(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      if (openInviteAfterVisit) {
        setTimeout(() => {
          setTourModal(true);
        }, 3000);
      }
    } catch (e) {
      messageApi.open({
        type: "error",
        content: `${e?.response?.data}`,
      });
      setLoading(false);
      return { error: "Failed to fetch session" };
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const openUrlBox = () => {
    if (inviteAdminTour) {
      setShowInput(true);
      setInviteAdminTourIndex(3);
    } else {
      setShowInput((prevShowInput) => !prevShowInput);
    }
  };

  const onCopy = () => {
    message.success("Copied to clipboard!");
  };

  const handleGlobalSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleEndTour = () => {
    setInviteTour(false);
    setIsTourRunning(false);
    setInviteAdminTour(false);
    setInviteAdminTourDep(false);
    setInviteModel(false);
    setIsModalOpen(false);
    setInvitePromptModal(false);
    setInviteIndDependent(false);
    setInviteIndDependentTour(false);

    dispatch(setTourSteps(END_TOUR));
  };

  //Function to return menu items of user role
  const getMenuItems = () => {
    switch (true) {
      case userProfileData?.group === "Member":
        return memberMenu;
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE_ADMIN":
        return adminMenu;
      case userType === "CORPORATE_ADMIN":
        return corporateMenu;
      case billingStatus === "REQUIRED":
        return employeeMenu;
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE":
        return employeeMenu;
      case userType === "CORPORATE" && !verified:
        return approvalMenu;
      default:
        return individualMenu;
    }
  };

  const getMenuItemsResponsive = () => {
    switch (true) {
      case userProfileData?.group === "Member":
        return memberResponsiveMenu;
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE_ADMIN":
        return adminResponsiveMenu;
      case userType === "CORPORATE_ADMIN":
        return adminResponsiveMenu;
      case billingStatus === "REQUIRED":
        return employeeResponsiveMenu;
      case billingStatus === "NOT_REQUIRED" && userType === "CORPORATE":
        return employeeResponsiveMenu;
      case userType === "CORPORATE" && !verified:
        return approvalResponsiveMenu;
      default:
        return indResponsiveMenu;
    }
  };

  const menuItemsToShow = getMenuItems();

  const menuItemsToShowInResponsive = getMenuItemsResponsive();

  const handleSearchInputFocus = () => {
    if (
      url === "http://localhost:3000/" ||
      url === "https://stage.delphihc.com/" ||
      url === "https://member.delphihc.com/"
    ) {
      setIsSearchActive(true);
    } else {
      navigate("/?search=true");
    }
  };

  const searchParam = useMemo(() => {
    return new URLSearchParams(window.location.search).get("search");
  }, [window.location.search]);

  useEffect(() => {
    const unsubscribe = Hub.listen(
      "auth",
      async ({ payload: { event, data } }) => {
        switch (event) {
          case "signIn":
            dispatch(setToken(data.signInUserSession.accessToken.jwtToken));
            try {
              const currentUserData = await Auth.currentAuthenticatedUser({
                bypassCache: false,
              });

              await API.get(
                "bazarhealthREST",
                `/createEmployee?id=${currentUserData?.username}`
              );
              const res = await GetEmployeeDetails(
                currentUserData?.attributes?.sub
              );
              if (res !== null) {
                dispatch(setUserData(res));
              }
            } catch (error) {
              console.error("Error fetching employee data:", error);
              alert(error);
            }
            break;
          case "signOut":
            dispatch(setUserData(null));
            break;
        }
      }
    );

    return unsubscribe;
  }, []);

  useEffect(() => {
    requestPermission()
      .then(async (fcmToken) => {
        if (
          userProfileData?.fcmToken === null ||
          userProfileData?.fcmToken !== fcmToken
        ) {
          const url = "/notification/subscribeNotification";
          const myInit = {
            body: {
              fcmToken: fcmToken,
              topics: notificationTopics,
            },
          };

          await API.post("bazarhealthREST", url, myInit);

          const inputData = {
            id: userProfileData?.id,
            fcmToken: fcmToken,
          };
          await UpdateEmployeeRegi(inputData);
        } else {
          console.log("No Token Found in the DB.");
        }
      })
      .catch((error) => {
        console.error("An error occurred while requesting permission:", error);
      });

    if (searchParam === "true") {
      setIsSearchActive(true);
    }

    if (redirectTourStart === "true") {
      setIsTourRunning(true);
      setTimeout(() => {
        localStorage.setItem("redirectTourStart", false);
      }, 400);
    }

    setActiveTab(storedActiveTabKey);
    fetchMembers();
    openFirstTimePopup();

    const getImage = async () => {
      const photo = userProfileData?.profile?.profilePhoto?.startsWith("https")
        ? userProfileData?.profile?.profilePhoto
        : await GetFileFromS3(userProfileData?.profile?.profilePhoto);
      setUserDp(photo);
    };

    if (userProfileData?.profile?.profilePhoto !== null) {
      getImage();
    }
  }, [
    searchParam,
    tourSteps,
    isInviteCompleted,
    redirectTourStart,
    storedActiveTabKey,
    fetchMembers,
    firstVisit?.firstVisit,
    userProfileData?.profile?.profilePhoto,
  ]);

  const shoqFaq = () => {
    navigate("/faq");
  };

  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const showModalSupport = () => {
    setSupportIsModalOpen(true);
    // setShowChatBadge(false);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const [showChatBadge, setShowChatBadge] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Subscribe to new chat creation
        const createMessageSubscriber = API.graphql({
          query: onCreateChat,
          variables: {
            filter: {
              status: { eq: "OPEN" },
              senderID: { eq: userProfileData?.id },
            },
          },
        }).subscribe({
          next: (d) => {
            const newChat = d?.value?.data?.onCreateChat;
            if (newChat) {
              // sound.play();
              setShowChatBadge(true);
            }
          },
          error: (e) => {
            console.log("Subscription error:", e);
          },
        });

        // Fetch existing chats
        const response = await API.graphql({
          query: listChats,
          variables: {
            filter: {
              senderID: { eq: userProfileData?.id },
              status: { ne: "CLOSED" },
            },
          },
        });

        // Update state based on fetched data
        response?.data?.listChats?.items?.length > 0
          ? setShowChatBadge(true)
          : setShowChatBadge(false);

        // Clean up the subscription when the component unmounts
        return () => createMessageSubscriber.unsubscribe();
      } catch (error) {
        console.error("Error in fetchData:", error);
      }
    };

    fetchData(); // Call the asynchronous function
  }, []); // Include dependencies if needed

  return (
    <>
      {contextHolder}

      {/* Home Page Tour */}
      <HomeTour
        isTourRunning={isTourRunning}
        setIsTourRunning={setIsTourRunning}
        isInviteCompleted={isInviteCompleted}
        isIndividual={isIndividual}
      />

      {/* Admin Employee Invite Tour */}
      {inviteAdminTour && (
        <AdminInviteEmployee
          inviteAdminTour={inviteAdminTour}
          inviteAdminTourIndex={inviteAdminTourIndex}
          setInviteAdminTourIndex={setInviteAdminTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {/* Admin Dependent Invite Tour */}
      {inviteAdminTourDep && (
        <AdminInviteDependent
          inviteAdminTourDep={inviteAdminTourDep}
          inviteAdminTourDepIndex={inviteAdminTourDepIndex}
          setInviteAdminTourDepIndex={setInviteAdminTourDepIndex}
          handleEndTour={handleEndTour}
        />
      )}

      {/* Invite Dependent tour for individual */}
      {inviteTour && (
        <InviteDependent
          inviteTour={inviteTour}
          inviteTourIndex={inviteTourIndex}
          setInviteTourIndex={setInviteTourIndex}
          handleEndTour={handleEndTour}
        />
      )}

      <Layout className="layout navbar">
        <section className="header">
          <Container>
            <Header className="main-haeder header-menu">
              {isSearchActive || (!isHomePage && screens.xs) ? (
                <>
                  <div
                    className="search-back"
                    onClick={() => {
                      if (!isHomePage) {
                        window.history.back();
                      } else {
                        setIsSearchActive(false);
                        setSearchValue("");
                        navigate("/");
                      }
                    }}
                  >
                    <LeftOutlined />{" "}
                  </div>
                </>
              ) : (
                <Link to="/">
                  <img src={logo} alt="Bazar Logo" className="main-logo" />
                </Link>
              )}

              {urlPath === "/dpc-home"
                ? ""
                : accountStatus === "ACTIVE" && (
                    <div className="global-searchbar d-flex align-center ">
                      <Input
                        disabled={isTourRunning ? true : false}
                        prefix={<SearchOutlined className="search-ic" />}
                        placeholder="Search doctor name, procedure.."
                        className="searchbar search-first-step invite-search-btn searchbar-input"
                        onFocus={handleSearchInputFocus}
                        onChange={handleGlobalSearch}
                        value={searchValue}
                        // style={{height: "40px"}}
                      />
                    </div>
                  )}

              <Button
                icon={<img src={faq} alt="" />}
                className={`menu-icon-faq ${
                  screens.xs ? "home-forth-step" : ""
                } ${userProfileData?.role === "DEPENDANT" && "right-20"} ${
                  isTourRunning && "user-select-none"
                }`}
                onClick={shoqFaq}
              />

              {userProfileData?.role !== "DEPENDANT" && (
                <Button
                  icon={<img src={mobileInvite} alt="" />}
                  className={`menu-icon ${
                    screens.xs
                      ? " first-step admin-first-step admin-dep-first-step inv-first-step"
                      : ""
                  } ${isTourRunning && "user-select-none"}`}
                  onClick={() => {
                    if (userType === "INDIVIDUAL" || userType === "CORPORATE") {
                      showModal();
                    } else if (userType === "CORPORATE_ADMIN") {
                      if (inviteAdminTour || inviteAdminTourDep) {
                        handleAdminTour();
                      } else {
                        setOpen(false);
                        setInvitePromptModal(true);
                      }
                    }
                  }}
                />
              )}

              <Drawer
                title={
                  <Row className="button-font fw-600 second-step invite-skip-btn">
                    {userProfileData?.profile?.profilePhoto === null ? (
                      <UserOutlined className="user-outline-responsive" />
                    ) : (
                      <UserImg />
                    )}{" "}
                    <div className="user-details">
                      <span className="p-font">Welcome, {name}!</span>
                      <span className="button-font">
                        <AddressIcon /> {zeroPad(rawZip, 5)}
                      </span>
                    </div>
                  </Row>
                }
                placement="right"
                className="main-menu-sidebar"
                onClose={onClose}
                open={open}
              >
                <Menu
                  mode="inline"
                  className="responsive-menu-list"
                  items={menuItemsToShowInResponsive}
                />
              </Drawer>

              <div className="nav-btn">
                <Menu
                  // triggerSubMenuAction="click"
                  mode="horizontal"
                  className="header-menu header-child-menu "
                  items={menuItemsToShow}
                />
              </div>
            </Header>
          </Container>
        </section>
        <Content>
          {screens.xs && (
            <MenuBar
              activeTabKey={
                isHomePage
                  ? isSearchActive
                    ? "mobile"
                    : tabLocation
                  : "mobile"
              }
              setOpen={setOpen}
            />
          )}

          {isSearchActive ? (
            <>
              <GlobalSearch
                searchValue={searchValue?.trim()}
                setSearchValue={setSearchValue}
              />
            </>
          ) : (
            props.children
          )}
        </Content>

        <div className="userp-version hide-version-no">
          Version {process.env.REACT_APP_VERSION} © {moment().year()} &nbsp;
          <a
            href="https://delphihc.com/"
            target="_blank"
            style={{ color: "inherit" }}
          >
            delphihc.com
          </a>{" "}
          &nbsp; copyright all rights reserved.
        </div>
      </Layout>

      {/* modal for hr invitation */}
      <AdminInvite
        inviteModel={inviteModel}
        handleOk={handleOk}
        EmployeeInvite={EmployeeInvite}
        onFinishFailed={onFinishFailed}
        form={form}
        inviteAdminTour={inviteAdminTour}
        setInviteAdminTourIndex={setInviteAdminTourIndex}
        screens={screens}
        showInput={showInput}
        openUrlBox={openUrlBox}
        text={text}
        hostWithProtocol={hostWithProtocol}
        companyID={companyID}
        loading={loading}
        setInviteModel={setInviteModel}
        setSuccessModel={setSuccessModel}
        setSuccessModelAdmin={setSuccessModelAdmin}
        onCopy={onCopy}
        copy={copy}
        invite={invite}
        setShowInput={setShowInput}
      />

      {/* invite modal for employee */}
      <Modal
        title="Invite Dependent"
        open={isModalOpen}
        onOk={handleOk}
        centered
        keyboard={false}
        onCancel={handleOk}
        footer={[
          inviteAdminTourDep ? (
            <Button
              type="primary"
              className="theme-btn admin-dep-forth-step"
              onClick={() => {
                setInviteAdminTourDepIndex(4);
                setIsModalOpen(false);
                setSuccessModelAdmin(true);
              }}
            >
              Send Invite
            </Button>
          ) : dependentList.length === 0 ? (
            ""
          ) : !defaultEmail ? (
            <Button
              type="primary"
              className="theme-btn"
              htmlType="submit"
              form="memberInvite"
              key="submit"
              loading={loading}
            >
              Send Invite
            </Button>
          ) : registered ? (
            <>
              <p className="invite-dep-title">
                This dependent is already registered.
              </p>
            </>
          ) : (
            <>
              <p className="invite-dep-title">
                This dependent has already been invited.
              </p>

              <Row
                className="mt-20 "
                style={{
                  justifyContent: "center",
                  fontSize: "18px",
                  color: "#00274C",
                  fontWeight: 700,
                }}
              >
                <p style={{ marginRight: "10px" }}>
                  {" "}
                  <ClockCircleOutlined />
                </p>

                <p justify="center">
                  {timer?.remainingHours} : {timer?.remainingMinutes}:{" "}
                  {timer?.remainingSeconds}
                </p>
              </Row>

              <Button
                className="theme-btn"
                htmlType="submit"
                form="memberInvite"
                key="submit"
                loading={loading}
                disabled={isDisabled}
              >
                Resend Invite
              </Button>
            </>
          ),
        ]}
        className="suggest-modal"
      >
        {error !== "" && (
          <Alert
            style={{ marginBottom: "10px" }}
            type="error"
            showIcon={false}
            message={error}
            banner
          />
        )}

        {inviteAdminTourDep ? (
          <>
            <Form
              autoComplete="off"
              id="memberInvite"
              layout="vertical"
              className="admin-dep-third-step"
              onClick={() => {
                setInviteAdminTourDepIndex(3);
              }}
            >
              <Form.Item
                label="Select Dependent Name"
                name="depName"
                rules={[
                  {
                    required: true,
                    message: "Please select any dependent!",
                  },
                ]}
              >
                <Select
                  className="filter-border-btn"
                  allowClear
                  placeholder="Select any dependent"
                  onChange={handleChange}
                ></Select>
              </Form.Item>

              <Form.Item
                label="Enter Dependent Email "
                name="depEmail"
                initialValue=""
                rules={[
                  {
                    required: true,
                    message: "Please enter dependent email!",
                  },
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email address",
                  },
                ]}
              >
                <Input disabled={defaultEmail ? true : false} />
              </Form.Item>
            </Form>
          </>
        ) : dependentList.length > 0 ? (
          <Form
            name="employee"
            onFinish={MemberInvite}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            id="memberInvite"
            layout="vertical"
            form={memberForm}
            className="admin-dep-third-step"
            onClick={() => {
              setInviteAdminTourDepIndex(3);
            }}
          >
            <Form.Item
              label="Select Dependent Name"
              name="depName"
              rules={[
                {
                  required: true,
                  message: "Please select any dependent!",
                },
              ]}
            >
              <Select
                className="filter-border-btn"
                allowClear
                placeholder="Select any dependent"
                onChange={handleChange}
              >
                {dependentList.map((item) => (
                  <Select.Option key={item?.id} value={item?.name}>
                    {item?.memberFirstName + " " + item?.memberLastName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>

            {emailVisible && (
              <>
                <Form.Item
                  label="Enter Dependent Email "
                  name="depEmail"
                  initialValue=""
                  rules={[
                    {
                      required: true,
                      message: "Please enter dependent email!",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please enter a valid email address",
                    },
                  ]}
                >
                  <Input disabled={defaultEmail ? true : false} />
                </Form.Item>
              </>
            )}
          </Form>
        ) : (
          <p
            className="w-100 invite-dep-title"
            style={{ display: "flex", justifyContent: "center" }}
          >
            No Dependent Found
          </p>
        )}
      </Modal>

      <SuccessModel
        message="Invitation Sent Successfully!"
        open={successModel}
        className="inv-four-step"
      />

      <Modal
        title="Basic Modal"
        open={successModelAdmin}
        onOk={() => {
          setSuccessModelAdmin(false);
          setInviteAdminTour(false);
          setInviteAdminTourDep(false);
        }}
        centered
        keyboard={false}
        onCancel={() => {
          setSuccessModelAdmin(false);
          setInviteAdminTour(false);
          setInviteAdminTourDep(false);
        }}
        className="reward-success-modal admin-seventh-step admin-dep-fifth-step"
      >
        <div
          onClick={() => {
            setInviteAdminTour(false);
            setIsTourRunning(false);
            setSuccessModelAdmin(false);
            setInviteAdminTourDep(false);
            setInvSuccessModel(false);
            setInviteTour(false);
          }}
        >
          <img src={successIcon} alt="Success Img" />
          <h6>Invitation Sent Successfully!</h6>
        </div>
      </Modal>

      {/* Modal For HR Invite with two Option */}
      <Modal
        title="Basic Modal"
        open={invitePromptModal}
        width={400}
        keyboard={false}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal hr-invite-modal"
        footer={null}
      >
        {corporatePlan ? (
          <div className="admin-second-step">
            <img src={member} alt="Delete Img" width={150} />
            <h6>Employee</h6>
            <p>Invite employee of your company.</p>
            <Button
              className="theme-btn"
              onClick={() => {
                setInvitePromptModal(false);
                setInviteModel(true);
              }}
            >
              Invite Employee
            </Button>
          </div>
        ) : (
          <Row gutter={[10, 50]} style={{ alignItems: "end" }}>
            <Col
              span={12}
              md={12}
              xs={24}
              sm={24}
              onClick={() => {
                if (inviteAdminTour) {
                  setTimeout(() => {
                    setInviteAdminTourIndex(2);
                  }, 400);
                  setInvitePromptModal(false);
                  setInviteModel(true);
                }
              }}
            >
              <div className="admin-second-step">
                <img src={member} alt="Delete Img" width={150} />
                <h6>Employee</h6>
                <p>Invite employee of your company.</p>
                <Button
                  className="theme-btn"
                  onClick={() => {
                    setInvitePromptModal(false);
                    setInviteModel(true);
                  }}
                >
                  Invite Employee
                </Button>
              </div>
            </Col>

            <Col
              span={24}
              md={12}
              xs={24}
              sm={24}
              onClick={() => {
                if (inviteAdminTourDep) {
                  setTimeout(() => {
                    setInviteAdminTourDepIndex(2);
                  }, 400);
                  setInvitePromptModal(false);
                  setIsModalOpen(true);
                }
              }}
            >
              <div className="admin-dep-second-step">
                <img
                  src={employee}
                  alt="Delete Img"
                  width={150}
                  className="mb-10"
                />
                <h6>Dependent</h6>
                <p>Invite dependent of your family.</p>
                <Button
                  className="theme-btn"
                  onClick={() => {
                    setInvitePromptModal(false);
                    setIsModalOpen(true);
                  }}
                >
                  Invite Dependent
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </Modal>

      {/* invite modal for individual */}
      <IndividualInvite
        title="Invite Dependent"
        open={inviteIndDependent}
        onOk={handleOk}
        onCancel={handleOk}
        inviteTour={inviteTour}
        loading={loading}
        IndInvite={IndInvite}
        onFinishFailed={onFinishFailed}
        memberForm={memberForm}
        setInviteTourIndex={setInviteTourIndex}
        setInviteIndDependent={setInviteIndDependent}
        setInvSuccessModel={setInvSuccessModel}
      />

      <Modal
        title="Invite Dependent"
        open={inviteIndDependentTour}
        keyboard={false}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        footer={null}
        className="suggest-modal inv-second-step"
      >
        <Form
          name="employee"
          onFinish={IndInvite}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          id="memberInvite"
          layout="vertical"
          form={memberForm}
        >
          <Form.Item
            onClick={() => {
              setInviteIndDependentTour(false);
              setTimeout(() => {
                setInviteTourIndex(2);
              }, 1000);

              setInviteIndDependent(true);
            }}
            label="Email"
            name="memberEmails"
            rules={[
              {
                required: true,
                message: "Required!",
              },
              {
                pattern: emailPattern,
                message: "Please enter a valid email address",
              },
            ]}
          >
            <Input
              onClick={() => {
                setInviteIndDependentTour(false);
                setTimeout(() => {
                  setInviteTourIndex(2);
                  // setInviteIndDependent(true);
                }, 5000);
                setInviteIndDependent(true);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      {/* Tour Modal */}
      <Modal
        open={tourModal}
        onOk={handleOk}
        onCancel={handleOk}
        keyboard={false}
        width={600}
        centered
        footer={null}
        className="suggest-modal"
      >
        <div>
          <div style={{ textAlign: "center" }} className="mt-20">
            <img src={bazarLogo} alt="" />
            <h6
              className="mt-20"
              style={{
                fontWeight: 700,
                color: "#000000",
                fontSize: "36px",
                lineHeight: "40px",
              }}
            >
              Welcome to Delphi!{" "}
            </h6>
            <span
              className="fs-1 mt-30"
              style={{ fontWeight: 400, fontSize: "18px" }}
            >
              The Delphi application and team of advocates are here for your
              healthcare needs
            </span>

            <div>
              {" "}
              <img
                src={tourLeft}
                className="modal-image"
                style={{
                  marginLeft: "-507px",
                  position: "fixed",
                  height: "300px",
                  marginTop: "-50px",
                }}
              />
              <img
                src={tourRight}
                className="modal-image"
                style={{
                  marginLeft: "300px",
                  position: "fixed",
                  height: "300px",
                  marginTop: "-50px",
                }}
              />
            </div>

            {notSureTourModal ? (
              <Card
                className="doctor-profile bottom-padding"
                style={{
                  background: "#F2F9FD",
                  border: "1px solid #A9C8DD",
                  marginTop: "30px",
                }}
              >
                <h6
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    color: "#00274C",
                  }}
                >
                  Would you like to begin a tour on how to use Delphi?
                </h6>
                <Space className="mt-10">
                  <Button
                    className="theme-btn"
                    htmlType="submit"
                    style={{ width: "120px" }}
                    onClick={
                      userProfileData?.role === "DEPENDANT"
                        ? () => {
                            setIsInvitedComplete(true);
                            setIsTourRunning(true);
                            setTourModal(false);
                          }
                        : () => {
                            setIsTourRunning(true);
                            setTourModal(false);
                          }
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    className="theme-border-btn"
                    htmlType="submit"
                    style={{ width: "120px" }}
                    onClick={() => setTourModal(false)}
                  >
                    Not Now
                  </Button>
                </Space>
              </Card>
            ) : (
              <Card
                className="doctor-profile bottom-padding r-mt-20"
                style={{
                  background: "#F2F9FD",
                  border: "1px solid #A9C8DD",
                  marginTop: "40px",
                }}
              >
                <h6
                  style={{
                    fontWeight: 700,
                    fontSize: "24px",
                    color: "#00274C",
                  }}
                >
                  {/* Would you like to invite your dependent to join Bazar Health? */}
                  {userType === "CORPORATE_ADMIN"
                    ? "Would you like to Invite Employee/Dependents to join Delphi?"
                    : "Would you like to Invite Dependents to join Delphi?"}
                </h6>
                <Space className="mt-10">
                  <Button
                    className="theme-btn"
                    htmlType="submit"
                    style={{ width: "120px" }}
                    onClick={() => {
                      setIsInvitedComplete(true);
                      if (userType === "INDIVIDUAL") {
                        setInviteIndDependent(true);
                        setTourModal(false);
                      } else if (userType === "CORPORATE") {
                        setIsModalOpen(true);
                        setTourModal(false);
                      } else if (userType === "CORPORATE_ADMIN") {
                        setInvitePromptModal(true);
                        setTourModal(false);
                      }
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="theme-border-btn"
                    htmlType="submit"
                    style={{ width: "120px" }}
                    onClick={() => setNotSureTourModal(true)}
                  >
                    Not Now
                  </Button>
                </Space>
              </Card>
            )}
          </div>
        </div>
      </Modal>

      <Modal
        title="Basic Modal"
        keyboard={false}
        open={invsuccessModel}
        onOk={handleOk}
        centered
        onCancel={handleOk}
        className="reward-success-modal inv-four-step"
      >
        <div
          onClick={() => {
            setInviteAdminTour(false);
            setIsTourRunning(false);
            setSuccessModelAdmin(false);
            setInviteAdminTourDep(false);
            setInvSuccessModel(false);
            setInviteTour(false);
          }}
        >
          <img src={successIcon} alt="Success Img" />

          <h6>Invitation Sent Successfully!</h6>
        </div>
      </Modal>

      {/* Contact US mODal */}
      <Modal
        onOk={handleOk}
        centered
        keyboard={false}
        open={contactus}
        onCancel={() => {
          setContactUs(false);
        }}
        className="reward-success-modal contact-us-modal"
        destroyOnClose={true}
        footer={null}
        width={521}
      >
        <img src={notSure} alt="Delete Img" />
        <h6 className="header-font mb-0 mt-20">Contact to Delphi</h6>
        <p className="contact-description">
          Get in touch with us for any queries!
        </p>

        <Card
          className="doctor-profile bottom-padding mt-30"
          style={{ background: "#F2F9FD" }}
        >
          <Row gutter={10}>
            <Col md={12} xs={12}>
              <img src={email} alt="Delete Img" /> <br />
              <p className="sub-header-font">ear@delphillc.com</p>
            </Col>

            <Col md={12} xs={12}>
              <img src={phone} alt="Delete Img" /> <br />
              <p className="sub-header-font">(603) 537-6037</p>
            </Col>
          </Row>
        </Card>
      </Modal>

      <Modal
        title="Basic Modal"
        open={upgradePlanModal}
        onOk={() => setUpgradePlanModal(false)}
        centered
        onCancel={() => setUpgradePlanModal(false)}
        className="reward-success-modal"
        footer={null}
        width={520}
      >
        <img src={warning} alt="Delete Img" />
        <h6 className="mt-20">
          To invite your dependents you need to upgrade your membership
        </h6>

        <Link to={`/billing/?selectedPlan=${planObj?.planName}`}>
          <Button type="primary" className="theme-btn mt-20">
            Update plan
          </Button>
        </Link>
      </Modal>

      <Button
        type="primary"
        className="support-btn bottom-75 service-second-step chat-notify-icon"
        onClick={showModalSupport}
      >
        <img src={supportIcon} alt="" />
        {showChatBadge ? <Badge status="success" /> : ""}
      </Button>

      <Modal
        open={isSupportModalOpen}
        onOk={handleOkSupport}
        onCancel={handleOkSupport}
        className="support-modal support-modal-responsive"
      >
        <UserChat
          closeModal={handleOkSupport}
          isSupportModalOpen={isSupportModalOpen}
        />
      </Modal>
    </>
  );
}
