import { Avatar, Button, Col, List, Row, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { getMessaging, onMessage } from "firebase/messaging";
import empty from "assets/icons/emptyNotification.svg";
import admin from "assets/icons/adminNotification.svg";

import "./UserNotification.less";
import { Link } from "react-router-dom";

export default function UserNotification({
  notificationState,
  setNotificationState,
}) {
  const count = 3;
  const fakeDataUrl = `https://randomuser.me/api/?results=${count}&inc=name,gender,email,nat,picture&noinfo`;

  const [initLoading, setInitLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [list, setList] = useState([]);

  const onLoadMore = () => {
    setLoading(true);
    setList(
      data.concat(
        [...new Array(count)].map(() => ({
          loading: true,
          name: {},
          picture: {},
        }))
      )
    );
    fetch(fakeDataUrl)
      .then((res) => res.json())
      .then((res) => {
        const newData = data.concat(res.results);
        setData(newData);
        setList(newData);
        setLoading(false);
        // Resetting window's offsetTop so as to display react-virtualized demo underfloor.
        // In real scene, you can using public method of react-virtualized:
        // https://stackoverflow.com/questions/46700726/how-to-use-public-method-updateposition-of-react-virtualized
        window.dispatchEvent(new Event("resize"));
      });
  };

  const loadMore =
    !initLoading && !loading ? (
      <div
        style={{
          textAlign: "center",
          marginTop: 12,
          height: 32,
          lineHeight: "32px",
        }}
      >
        <Button className="theme-btn" onClick={onLoadMore}>
          Load More
        </Button>
      </div>
    ) : null;

  return (
    <>
      <Row className="user-notify-header">
        <Col span={18} xs={18}>
          <h4> Notifications</h4>
        </Col>
        {/* <Col span={6} xs={6}>
          <h5> {notificationState?.length !== 0 ? "Mark all as read" : ""}</h5>
        </Col> */}
      </Row>

      {notificationState?.length === 0 ? (
        <Row justify="center" className="my-2 empty-notification">
          <img src={empty} alt="" />
          <p>You have no notifications right now. Come back later</p>
        </Row>
      ) : (
        <List
          className="demo-loadmore-list"
          // loading={initLoading}
          itemLayout="horizontal"
          loadMore={loadMore}
          dataSource={notificationState}
          renderItem={(item) => (
            <List.Item >
              <Skeleton avatar title={false} loading={item?.loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={admin} />}
                  title={item?.title}
                  description={item?.body}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      )}
    </>
  );
}
