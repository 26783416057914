import { API, Auth } from "aws-amplify";
import * as queriesdefault from "graphql/queries";
import * as customQueriesdefault from "graphql/customQuery";
// import * as queriesCustom from "graphql/customQueries";
import * as mutationsdefault from "graphql/mutations";
import awsmobile from "aws-exports";
// import * as mutationsCustom from "graphql/customMutation";

const queries = { ...queriesdefault };
const mutations = { ...mutationsdefault };
const customQuery = { ...customQueriesdefault };

// Global functions created to create functions dynamically and make it reusable
export const ExecuteQuery = (
  query,
  id,
  inputData,
  primarObj = {},
  filter,
  nextToken
) => {
  return ((input = inputData) => {
    return API.graphql({
      query: queries[query],
      variables: { input, filter, id, nextToken, ...primarObj },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const ExecuteMutation = (query, inputData, filter) => {
  return ((input = inputData) => {
    return API.graphql({
      query: mutations[query],
      variables: { input, filter },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
  })()
    .then((res) => {
      query = fetchQueryName(query);
      return res?.data?.[query] || res;
    })
    .catch((err) => {
      console.log(err);
      return Promise.reject(err);
    });
};

/**
 *
 * @param {*} query
 * @param {*} input
 * @param {*} id
 * @param {*} filter
 * @param {*} nextTokenParam
 * @param {*} data
 * @param {*} limit
 * @returns
 */
export const ExecuteQueryCustom = async (
  query,
  input,
  byName,
  id,
  filter,
  nextTokenParam,
  data,
  limit,
  searchByName,
  location,
  areaOfSpeciality,
  surgeryCenterSpeciality,
  icSpecialities,
  labType,
  specialty,
  surgeryCenter,
  authMode = "AMAZON_COGNITO_USER_POOLS"
) => {
  let list = data || [];
  let nextToken = nextTokenParam;
  do {
    let res = await API.graphql({
      query: queries[query],
      variables: {
        input,
        byName,
        filter,
        nextToken,
        id,
        limit,
        searchByName,
        location,
        areaOfSpeciality,
        surgeryCenterSpeciality,
        icSpecialities,
        labType,
        specialty,
        surgeryCenter,
      },
      authMode: authMode,
    }).catch(async (e) => {
      console.log("dfdsf", e.message);
      console.log("Error:", e);
      if (
        e.message === "The user is not authenticated" ||
        e.message === "No current user"
      ) {
        await Auth.signOut();
        localStorage.clear();
        window.location.href = "/login";
      }
    });
    let customQueryName;
    if (String(query).endsWith("Custom")) {
      customQueryName = query.split("Custom")[0];
    }
    if (String(query).endsWith("ForCount")) {
      customQueryName = query.split("ForCount")[0];
    }
    nextToken = res?.data?.[customQueryName || query]?.nextToken;
    list = [...list, ...res?.data?.[customQueryName || query]?.items];
    if (!nextToken) {
      return list;
    }
  } while (nextToken);
};

export const ExecutePublicAPI = async (
  query,
  input,
  byName,
  id,
  filter,
  nextTokenParam,
  data,
  limit,
  searchByName
) => {
  let list = data || [];
  let nextToken = nextTokenParam;
  do {
    let res = await API.graphql({
      query: queries[query],
      variables: { input, byName, filter, nextToken, id, limit, searchByName },
      authMode: "API_KEY",
      authToken: awsmobile.aws_appsync_apiKey,
    }).catch((e) => {
      console.log("Error:", e);
    });
    let customQueryName;
    if (String(query).endsWith("Custom")) {
      customQueryName = query.split("Custom")[0];
    }
    if (String(query).endsWith("ForCount")) {
      customQueryName = query.split("ForCount")[0];
    }
    nextToken = res?.data?.[customQueryName || query]?.nextToken;
    list = [...list, ...res?.data?.[customQueryName || query]?.items];
    if (!nextToken) {
      return list;
    }
  } while (nextToken);
};

function fetchQueryName(query) {
  let customQueryName = query;
  if (String(query).endsWith("Custom")) {
    customQueryName = query.split("Custom")[0];
  }
  if (String(query).endsWith("ForCount")) {
    customQueryName = query.split("ForCount")[0];
  }
  return customQueryName;
}

export const ExecuteCustom = async (
  query,
  input,
  byName,
  id,
  filter,
  nextTokenParam,
  data,
  limit,
  searchByName,
  location,
  areaOfSpeciality,
  surgeryCenterSpeciality,
  icSpecialities,
  labType,
  specialty,
  surgeryCenter
) => {
  let list = data || [];
  let nextToken = nextTokenParam;
  do {
    let res = await API.graphql({
      query: customQuery[query],
      variables: {
        input,
        byName,
        filter,
        nextToken,
        id,
        limit,
        searchByName,
        location,
        areaOfSpeciality,
        surgeryCenterSpeciality,
        icSpecialities,
        labType,
        specialty,
        surgeryCenter,
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    }).catch(async (e) => {
      console.log("Error:", e);
      if (
        e.message === "The user is not authenticated" ||
        e.message === "No current user"
      ) {
        await Auth.signOut();
        localStorage.clear();
        window.location.href = "/login";
      }
    });
    let customQueryName;
    if (String(query).endsWith("Custom")) {
      customQueryName = query.split("Custom")[0];
    }
    if (String(query).endsWith("ForCount")) {
      customQueryName = query.split("ForCount")[0];
    }
    nextToken = res?.data?.[customQueryName || query]?.nextToken;
    list = [...list, ...res?.data?.[customQueryName || query]?.items];
    if (!nextToken) {
      return list;
    }
  } while (nextToken);
};
