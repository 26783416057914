import {
  Button,
  Col,
  Divider,
  Row,
  Typography,
  Form,
  Input,
  Select,
  Table,
  Image,
  message,
  Space,
} from "antd";
import moment from "moment";
import downloadIcon from "assets/icons/downloadIc.svg";
import { CheckOutlined } from "@ant-design/icons";
import document from "assets/img/documentDownloads.svg";
import previewIcon from "assets/icons/previewIc.svg";
import React, { useState } from "react";
import BackButton from "commons/BackButton";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import SkeletonBlock from "utility/SkeletonBlocks";
import { useSelector } from "react-redux";
import SuccessModel from "utility/SuccessModel";
import {
  formatNumberUS,
  satisfactionLevels,
  typeOfCare,
} from "utility/constant";
import {
  GetEmployeeDetails,
  GetRewardSubmissions,
  UpdateRewardSubmissions,
} from "utility/action";
import { API } from "aws-amplify";
import { useCallback } from "react";

export default function OpenEobDetail() {
  const [eobData, setEobData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [skeletonLoader, setskeletonLoader] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [compulsoryReward, setCompulsoryReward] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [previewIndex, setPreviewIndex] = useState(0);
  const [isPreviewVisible, setPreviewVisible] = useState(false);
  const [preSignedImg, setPreSignedimg] = useState([]);
  let re = /(?:\.([^.]+))?$/;
  const { Option } = Select;
  const location = useLocation();
  const eobId = location?.state.id;
  const userData = useSelector((state) => state.userReducer.userData);

  const valueToSatisfactionMapping = {
    0: [0],
    1: [0, 1],
    2: [0, 1, 2],
    3: [0, 1, 2, 3],
    4: [0, 1, 2, 3, 4],
  };

  const rating = eobData?.feedback?.rating;
  let filteredSatisfactionLevels;
  let description = "";

  if (rating !== null && valueToSatisfactionMapping[rating]) {
    filteredSatisfactionLevels = satisfactionLevels.filter((level, index) =>
      valueToSatisfactionMapping[rating].includes(index)
    );

    if (rating >= 0 && rating <= 1) {
      description = "Very dissatisfied";
    } else if (rating > 1 && rating <= 3) {
      description = "Partially satisfied";
    } else if (rating === 4) {
      description = "Very satisfied";
    }
  } else {
    filteredSatisfactionLevels = [];
  }

  const fetchData = useCallback(async () => {
    try {
      setskeletonLoader(true);
      setImageLoader(true);
      const response = await GetRewardSubmissions(eobId);
      setEobData(response);
      await GetEmployeeDetails(response?.customerID).then((empRes) => {
        setEmployee(empRes);
      });
      setskeletonLoader(false);
      if (response?.uploadedImages.length !== 0) {
        let url = `/superAdmin/send_presigned_url`;
        const myInit = {
          body: {
            keys: response?.uploadedImages,
          },
        };
        let result = await API.post("bazarhealthREST", url, myInit);
        setPreSignedimg(result);
        setImageLoader(false);
      }
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setskeletonLoader(false);
      setImageLoader(false);
    }
  }, [eobId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const updateEob = async (values) => {
    try {
      setLoading(true);
      const inputData = {
        id: eobData?.id,
        status: values?.Status,
        approvedBy: values?.ApprovedBy,
        approvedDate: moment().format("YYYY-MM-DD"),
        typeOfCare: values?.Procedure,
        rewardAmount: values?.Reward || null,
      };
      await UpdateRewardSubmissions(inputData);
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
      fetchData();
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setLoading(false);
    }
  };

  const initialValues = {
    Procedure: eobData?.typeOfCare,
    Status: eobData?.status,
    Reward: eobData?.rewardAmount,
    ApprovedBy:
      eobData?.approvedBy === null || undefined
        ? userData?.firstName + " " + userData?.lastName
        : eobData?.approvedBy,
  };

  const columns = [
    {
      title: "",
      dataIndex: "label1",
      render: (item) => {
        return <strong>{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data1",
      render: (value, id) => {
        if (id?.key === "3") {
          formatNumberUS(value);
        } else {
          return value;
        }
      },
    },
    {
      title: "",
      dataIndex: "label2",
      className: "border-left",
      render: (item) => {
        return <strong>{item}</strong>;
      },
    },
    {
      title: "",
      dataIndex: "data2",
    },
  ];

  const data = [
    {
      key: "1",
      label1: "Claim created:",
      data1: new Date(eobData?.createdAt).toLocaleDateString(),
      label2: "Type:",
      data2: eobData?.typeOfCare,
    },
    {
      key: "2",
      label1: "Procedure:",
      data1: eobData?.nameOfProcedure,
      label2: "Name:",
      data2: employee?.profile?.firstName + " " + employee?.profile?.lastName,
    },
    {
      key: "3",
      label1: "Phone:",
      data1: employee?.profile?.phone,
      label2: "Address:",
      data2: employee?.profile?.streetAdress,
    },
    {
      key: "4",
      label1: "City:",
      data1: employee?.profile?.city,
      label2: "State:",
      data2: employee?.profile?.state,
    },
    {
      key: "5",
      label1: "Zip code:",
      data1: employee?.profile?.zip,
      label2: "Insurance carrier:",
      data2: employee?.profile?.insuranceCarrier,
    },
    {
      key: "6",
      label1: "Plan id:",
      data1: employee?.profile?.planID,
      label2: "Member id:",
      data2: employee?.profile?.memberID,
    },
    {
      key: "7",
      label1: "Email:",
      data1: employee?.profile?.email,
      label2: "Employer:",
      data2: employee?.companyID?.name,
    },
  ];

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          {eobData?.name}
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="center" className="update-form">
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            <>
              <Row gutter={[20, 20]}>
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />
                <SkeletonBlock style={{ width: 360, height: 50 }} />

                <SkeletonBlock style={{ width: 740, height: 500 }} />
              </Row>
            </>
          ) : (
            <>
              <Form
                name="basic"
                initialValues={initialValues}
                onFinish={updateEob}
                autoComplete="off"
                layout="vertical"
                className="mt-30"
              >
                <Row gutter={20}>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Type of care" name="Procedure">
                      <Select
                        allowClear
                        placeholder="Select type of care"
                        options={typeOfCare}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      ></Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item label="Status" name="Status">
                      <Select
                        placeholder="Select status"
                        allowClear
                        onChange={(value) => {
                          value === "APPROVED"
                            ? setCompulsoryReward(true)
                            : setCompulsoryReward(false);
                        }}
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <Option value="APPROVED">Approved</Option>
                        <Option value="PENDING">Pending</Option>
                        <Option value="DENIED">Denied</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Reward"
                      name="Reward"
                      rules={[
                        {
                          required: compulsoryReward,
                          message: "Required!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select reward"
                        allowClear
                        getPopupContainer={(trigger) => trigger.parentNode}
                      >
                        <Option value="25">$25</Option>
                        <Option value="50">$50</Option>
                        <Option value="100">$100</Option>
                        <Option value="200">$200</Option>
                        <Option value="250">$250</Option>
                        <Option value="500">$500</Option>
                        <Option value="1000">
                          {" "}
                          ${parseInt(1000).toLocaleString()}
                        </Option>
                        <Option value="1500">
                          ${parseInt(1500).toLocaleString()}
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <Form.Item
                      label="Approved by"
                      name="ApprovedBy"
                      initialValue={
                        userData?.firstName + " " + userData?.lastName
                      }
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col span={24} md={24} xs={24} sm={24}>
                    <Button
                      size="large"
                      className="blue-btn mt-20"
                      htmlType="submit"
                      loading={loading}
                    >
                      Update
                    </Button>
                  </Col>
                </Row>
              </Form>
            </>
          )}
        </Col>
        <Col span={16} md={16} xs={24} sm={24}>
          {skeletonLoader ? (
            ""
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={data}
                className="common-table no-header"
                scroll={{ x: 692 }}
                showHeader={false}
                pagination={false}
              />
            </>
          )}
        </Col>

        <Col span={16} md={16} xs={24} sm={24} className="mt-30">
          <Typography.Title level={4}>File</Typography.Title>

          {imageLoader ? (
            <Row gutter={[20, 20]} className="mt-3">
              <SkeletonBlock style={{ width: 200, height: 200 }} />
              <SkeletonBlock style={{ width: 200, height: 200 }} />
              <SkeletonBlock style={{ width: 200, height: 200 }} />
            </Row>
          ) : (
            <>
              <Row gutter={[16, 16]}>
                {preSignedImg.length === 0 ? (
                  <Col span={16} md={16} xs={24} sm={24}>
                    <Typography.Text level={4}>No Images</Typography.Text>
                  </Col>
                ) : (
                  <Row gutter={[16, 16]} className="w-100">
                    {preSignedImg.map((image, index) => (
                      <Col span={12} md={8} xs={24} sm={24} key={index}>
                        <div className="image-preview">
                          <Image
                            src={
                              image.fileExtension === "png" ||
                              image.fileExtension === "jpeg" ||
                              image.fileExtension === "jpg"
                                ? image?.fileKey
                                : document
                            }
                            className="image-preview-img"
                            onClick={() => setPreviewIndex(index)}
                            preview={{
                              visible:
                                isPreviewVisible && previewIndex === index,
                              onVisibleChange: (visible, prevVisible) => {
                                if (!visible) setPreviewIndex(null);
                                setPreviewVisible(visible);
                              },
                            }}
                          />
                          <div className="hide-icon">
                            <Space>
                              {image.fileExtension === "png" ||
                              image.fileExtension === "jpeg" ||
                              image.fileExtension === "jpg" ? (
                                <img
                                  src={previewIcon}
                                  alt="preview-icon"
                                  onClick={() => {
                                    setPreviewIndex(index);
                                    setPreviewVisible(true);
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <a href={image?.fileKey} download>
                                <img src={downloadIcon} alt="download-icon" />
                              </a>
                            </Space>
                          </div>
                        </div>

                        <Typography.Title
                          level={5}
                          style={{
                            textAlign: "center",
                            bottom: 0,
                            margin: "10px 0",
                          }}
                        >
                          {image?.fileName}
                        </Typography.Title>
                      </Col>
                    ))}
                  </Row>
                )}
              </Row>
            </>
          )}
        </Col>

        {eobData?.feedback ? (
          <>
            {" "}
            <Col span={16} md={16} xs={24} sm={24} className="mt-30">
              <Typography.Title level={4}>Review</Typography.Title>
              <Row>
                {filteredSatisfactionLevels?.map((level, index) => (
                  <>
                    <div
                      key={index}
                      className={`equal-width-div ${
                        rating === index ? "selected" : ""
                      }`}
                      style={{
                        background: level.background,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "16px",
                        width: "53px",
                        marginLeft: "5px",
                        padding: "10px",
                        fontWeight: "500",
                        color: "#fff",
                      }}
                    >
                      {rating === index ? <CheckOutlined /> : level.text}
                    </div>
                  </>
                ))}
                <p className="feedback-review">
                  <b>{description}</b>
                </p>
              </Row>
            </Col>
            <Col span={16} md={16} xs={24} sm={24}>
              <p className="feedback-review mt-10">
                {eobData?.feedback?.comment}
              </p>
            </Col>
          </>
        ) : (
          ""
        )}
      </Row>

      <SuccessModel message="EOB Updated Successfully!" open={successModel} />
    </>
  );
}
