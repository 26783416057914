import React from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Input,
  Modal,
  Form,
  Divider,
  Select,
} from "antd";
import successIcon from "assets/icons/Success.svg";
import { useState } from "react";
import { LeftOutlined, SearchOutlined, StarFilled } from "@ant-design/icons";
import Container from "commons/Container";
import "./DoctorList.less";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import locationIcon from "assets/icons/location.svg";

import Pagination from "rc-pagination";

import { useSelector } from "react-redux";

import usePaginationSearch from "utility/usePaginationSearch";
import AppointmentRequest from "./AppointmentRequest/AppointmentRequest";

import doctor1 from "assets/img/doctor1.svg";
import doctor2 from "assets/img/doctor2.svg";
import doctor4 from "assets/img/doctor4.svg";
import doctor6 from "assets/img/doctor6.svg";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";

export default function DoctorTour() {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const doctorStateData = useSelector((state) => state?.doctorReducer?.doctor);
  const [tourIndex, setTourIndex] = useState(0);
  const [isTourRunning, setIsTourRunning] = useState(true);
  const [successModel, setSuccessModel] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const surgeryCenterName = location?.state?.surgicalName;
  const totalLen = doctorStateData?.length;

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const { currentPage, PrevNextArrow } = usePaginationSearch(
    doctorStateData ? doctorStateData : []
  );

  const handleTourChange = () => {
    if (tourIndex === 0) {
      setTourIndex(1);
    } else if (tourIndex === 1) {
      setTourIndex(2);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleUpdateTourIndex = (index) => {
    if (index === 3) {
      setTourIndex(3);
    } else if (index === 5) {
      setTourIndex(4);
      setOpen(false);
      setSuccessModel(true);
    }
  };

  const handleModalCancel = () => {
    // Do nothing here to prevent the modal from closing when "Esc" key is pressed
  };

  const closeTour = () => {
    setSuccessModel(false);
    setIsTourRunning(false);
    navigate("/");
  };

  const doctorSteps = [
    {
      target: ".dr-first-step",
      content: (
        <TourStepContent
          heading="Provider Info"
          details="Choose from a list of services."
          numbers={{ currentStep: 1, totalSteps: 5 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".dr-second-step",
      content: (
        <TourStepContent
          heading="Request Appointment"
          details="Click to request an appointment with the selected provider."
          numbers={{ currentStep: 2, totalSteps: 5 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".dr-third-step",
      content: (
        <TourStepContent
          heading="Submit Appointment"
          details="Please fill out the form with the required details."
          numbers={{ currentStep: 3, totalSteps: 5 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".dr-forth-step",
      content: (
        <TourStepContent
          heading="Submit Appointment"
          details="Click to submit your request for appointment."
          numbers={{ currentStep: 4, totalSteps: 5 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
    {
      target: ".dr-fifth-step",
      content: (
        <TourStepContent
          heading="Request Sent"
          details="Great! Your request for appointment has been sent
          successfully."
          numbers={{ currentStep: 5, totalSteps: 5 }}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      <GlobalJoyride
        steps={doctorSteps}
        run={isTourRunning}
        stepIndex={tourIndex}
        setTourIndex={setTourIndex}
      />

      <Container>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Doctor List</Breadcrumb.Item>
        </Breadcrumb>
        <div className="sub-header d-flex justify-content-between">
          <div className="sub-title-box d-flex">
            <Button
              icon={<LeftOutlined />}
              onClick={() => window.history.back()}
            />
            <div className="sub-title-box-surgery">{totalLen} Result</div>
          </div>
        </div>

        <Card className="list-content" loading={false}>
          <div className="space-between">
            <div>
              <p className="card-title-surgery">
                Doctors List {surgeryCenterName ? `- ${surgeryCenterName}` : ""}
              </p>
            </div>
            <div className="w-40">
              <div>
                <Input
                  style={{
                    height: "40px",
                    borderRadius: "40px",
                  }}
                  className="main-search"
                  size="large"
                  placeholder="Search Here"
                  prefix={<SearchOutlined />}
                />
              </div>
            </div>
          </div>

          <Col span={24} className="d-flex w-100 doc-dropdown mt-20">
            <Input.Group compact>
              <Select
                className="speciality-selectbar"
                defaultValue="Select Any Specailty"
              ></Select>
              <div className="doc-dropdown-bodyPart">
                <div className="doc-dropdown-bodyPart-child"></div>
              </div>
            </Input.Group>

            <Pagination
              showTitle={false}
              total={doctorStateData?.length}
              defaultPageSize={9}
              current={currentPage}
              itemRender={PrevNextArrow}
            />
          </Col>

          <Row gutter={[20, 10]}>
            <>
              <Col md={8} xs={24} className="mt-10">
                <div
                  className="single-list-card-surgery dr-first-step"
                  onClick={handleTourChange}
                >
                  <div className="surgery-profile-card ">
                    <div className="d-flex gap-3">
                      <img
                        src={doctor2}
                        alt="Doctor Img"
                        className="doctor-img"
                      />
                      <h4 className="doctor-name">Francis Lamy, D.O</h4>
                    </div>
                    <div className="d-flex mt-20 rat-box">
                      <p>
                        <img src={facebook} alt="facebook Icon" /> 5{" "}
                        <StarFilled style={{ color: "#e7a500" }} />
                      </p>
                      <Divider className="h-60" type="vertical" />
                      <p>
                        <img src={google} alt="facebook Icon" /> 5
                        <StarFilled style={{ color: "#e7a500" }} />
                      </p>
                    </div>

                    <div className="space-between">
                      <p className="single-address-line">
                        <img src={locationIcon} alt="Address Icon" /> &nbsp;
                        0.15 Miles from you
                      </p>

                      <Link className="learn-more-btn">
                        <Button className="blue-border-btn">Learn More</Button>
                      </Link>
                    </div>
                  </div>

                  <div className="request-app">
                    {tourIndex === 0 ? (
                      <Button className="theme-btn dr-second-step">
                        Request Appointment
                      </Button>
                    ) : (
                      <Button
                        className="theme-btn dr-second-step"
                        onClick={() => {
                          setTourIndex(2);
                          setOpen(true);
                        }}
                      >
                        Request Appointment
                      </Button>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={8} xs={24} className="mt-10">
                <div
                  className="single-list-card-surgery dr-first-step"
                  onClick={handleTourChange}
                >
                  <div className="surgery-profile-card ">
                    <div className="d-flex gap-3">
                      <img
                        src={doctor6}
                        alt="Doctor Img"
                        className="doctor-img"
                      />
                      <h4 className="doctor-name">Melissa M. Wong, M.D.</h4>
                    </div>
                    <div className="d-flex mt-20 rat-box">
                      <p>
                        <img src={facebook} alt="facebook Icon" /> 5{" "}
                        <StarFilled style={{ color: "#e7a500" }} />\
                      </p>
                      <Divider className="h-60" type="vertical" />
                      <p>
                        <img src={google} alt="facebook Icon" /> 5
                        <StarFilled style={{ color: "#e7a500" }} />
                      </p>
                    </div>

                    <div className="space-between">
                      <p className="single-address-line">
                        <img src={locationIcon} alt="Address Icon" /> &nbsp;
                        0.15 Miles from you
                      </p>

                      <Link className="learn-more-btn">
                        <Button className="blue-border-btn">Learn More</Button>
                      </Link>
                    </div>
                  </div>

                  <div className="request-app">
                    <Button
                      className="theme-btn dr-second-step"
                      onClick={() => {
                        setTourIndex(2);
                        setOpen(true);
                      }}
                    >
                      Request Appointment
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={8} xs={24} className="mt-10">
                <div
                  className="single-list-card-surgery dr-first-step"
                  onClick={handleTourChange}
                >
                  <div className="surgery-profile-card ">
                    <div className="d-flex gap-3">
                      <img
                        src={doctor1}
                        alt="Doctor Img"
                        className="doctor-img"
                      />
                      <h4 className="doctor-name">Nikhil A Thakur, MD</h4>
                    </div>
                    <div className="d-flex mt-20 rat-box">
                      <p>
                        <img src={facebook} alt="facebook Icon" /> 5{" "}
                        <StarFilled style={{ color: "#e7a500" }} />\
                      </p>
                      <Divider className="h-60" type="vertical" />
                      <p>
                        <img src={google} alt="facebook Icon" /> 5
                        <StarFilled style={{ color: "#e7a500" }} />
                      </p>
                    </div>

                    <div className="space-between">
                      <p className="single-address-line">
                        <img src={locationIcon} alt="Address Icon" /> &nbsp;
                        0.15 Miles from you
                      </p>

                      <Link className="learn-more-btn">
                        <Button className="blue-border-btn">Learn More</Button>
                      </Link>
                    </div>
                  </div>

                  <div className="request-app">
                    <Button
                      className="theme-btn dr-second-step"
                      onClick={() => {
                        setTourIndex(2);
                        setOpen(true);
                      }}
                    >
                      Request Appointment
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md={8} xs={24} className="mt-10">
                <div
                  className="single-list-card-surgery dr-first-step"
                  onClick={handleTourChange}
                >
                  <div className="surgery-profile-card ">
                    <div className="d-flex gap-3">
                      <img
                        src={doctor4}
                        alt="Doctor Img"
                        className="doctor-img"
                      />
                      <h4 className="doctor-name">John Younghein, M.D.</h4>
                    </div>
                    <div className="d-flex mt-20 rat-box">
                      <p>
                        <img src={facebook} alt="facebook Icon" /> 5{" "}
                        <StarFilled style={{ color: "#e7a500" }} />\
                      </p>
                      <Divider className="h-60" type="vertical" />
                      <p>
                        <img src={google} alt="facebook Icon" /> 5
                        <StarFilled style={{ color: "#e7a500" }} />
                      </p>
                    </div>

                    <div className="space-between">
                      <p className="single-address-line">
                        <img src={locationIcon} alt="Address Icon" /> &nbsp;
                        0.15 Miles from you
                      </p>

                      <Link className="learn-more-btn">
                        <Button className="blue-border-btn">Learn More</Button>
                      </Link>
                    </div>
                  </div>

                  <div className="request-app">
                    <Button
                      className="theme-btn dr-second-step"
                      onClick={() => {
                        setTourIndex(2);
                        setOpen(true);
                      }}
                    >
                      Request Appointment
                    </Button>
                  </div>
                </div>
              </Col>
            </>
          </Row>
        </Card>
      </Container>

      

      <Modal
        title="Modal 1000px width"
        centered
        open={open}
        onOk={() => setOpen(false)}
        onCancel={handleModalCancel}
        width={1000}
        className="appointment-modal"
        footer={null}
      >
        <AppointmentRequest
          isModal={true}
          onCloseModal={handleCloseModal}
          tourIndex={tourIndex}
          tour={isTourRunning}
          className={
            tourIndex === 2
              ? "dr-third-step"
              : tourIndex === 4
              ? "dr-forth-step"
              : ""
          }
          onUpdateTourIndex={handleUpdateTourIndex}
        />
      </Modal>

    

      <Modal
        title="Basic Modal"
        open={successModel}
        onOk={closeTour}
        centered
        className="reward-success-modal dr-fifth-step"
        onCancel={closeTour}
      >
        <div onClick={closeTour}>
          <img src={successIcon} alt="Success Img" />
          <h6>Appointment Requested Successfully!</h6>
        </div>
      </Modal>
    </>
  );
}
