import React from "react";
import { Link, useParams } from "react-router-dom";
import DummyImg from "assets/icons/blank.png";
import mk from "assets/mk.png";
import { GetFileFromS3 } from "utility/uploadFileToS3";
import { API } from "aws-amplify";

const Contact = ({ contact }) => {
  const senderProfile = contact?.senderDetails?.profile;
  const { category, id: currentContactId } = useParams();

  // const { setUserAsUnread } = useUsersContext();
  const getLastMessage = () => {
    const messageDates = Object.keys(contact.messages);
    const recentMessageDate = messageDates[messageDates.length - 1];
    const messages = [...contact.messages[recentMessageDate]];
    const lastMessage = messages.pop();
    return lastMessage;
  };

  const lastMessage = getLastMessage(contact);
  console.log("contact", contact)
  console.log("lastMessage", lastMessage)
  // const getImage = async () => {
  //   // const photo = await GetFileFromS3(contact?.senderDetails?.profile?.profilePhoto);
  //   let url = `/superAdmin/send_presigned_url`;
  //   const myInit = {
  //     body: {
  //       keys: [JSON.stringify({fileName: contact?.senderDetails?.profile?.profilePhoto, fileKey: 'private/' + cont + "/" +contact?.senderDetails?.profile?.profilePhoto})],
  //     },
  //   };
  //   let result = await API.post("bazarhealthREST", url, myInit);
  //   console.log(result)
  //   // console.log(photo);
  //   return result[0]?.fileKey
  //   // setUserDp(photo);
  // };
  return (
    <Link
      className={`sidebar-contact ${
        currentContactId === contact.id ? "active-chat" : ""
      }`}
      to={`/admin-chat/${category}/${contact.id}`}
    >
      <div className="sidebar-contact__avatar-wrapper">
        <img
          src={
            contact?.senderDetails?.profile?.profilePhoto?.startsWith("https") ? 
            contact?.senderDetails?.profile?.profilePhoto
              : DummyImg
          }
          alt="User"
          className="avatar"
        />
      </div>
      <div className="sidebar-contact__content">
        <div className="sidebar-contact__top-content">
          <h2 className="sidebar-contact__name">
            {senderProfile?.firstName + " " + senderProfile?.lastName}{" "}
          </h2>
          <span className="sidebar-contact__time"></span>
        </div>
        <div className="sidebar-contact__bottom-content">
          <p className="sidebar-contact__message-wrapper">
            <span
              className={`sidebar-contact__message ${
                !!contact?.unread ? "sidebar-contact__message--unread" : ""
              }`}
            >
              {contact?.typing ? (
                <i> typing...</i>
              ) : lastMessage?.content?.startsWith("http") ? (
                "attachment"
              ) : (
                lastMessage?.content
              )}
            </span>
          </p>
          <div className="sidebar-contact__icons">
            {!!contact?.unread && (
              <span className="sidebar-contact__unread">{contact?.unread}</span>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Contact;
