import {
  Row,
  Input,
  Button,
  Table,
  Typography,
  Divider,
  message,
  Dropdown,
  Menu,
  Badge,
} from "antd";
import moment from "moment";

import React, { useState } from "react";
import BackButton from "commons/BackButton";
import exportCSV from "assets/icons/exportcsv.svg";

import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { formatCSVData } from "utility/constant";
import { CSVLink } from "react-csv";
import { ListEmployeesRegis } from "utility/action";
import { useCallback } from "react";
import search from "utility/search";

export default function ReferredList() {
  const [doctorData, setdoctordata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState(doctorData);
  const [csvData, setCsvData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { id } = useParams();

  const labelKeyMap = {
    "Physician ID": "physicianID",
    "Doctor Name": "name",
    "Doctor Degree": "doctorDegree",
    "Doctor Email": "partnerEmail",
    "About Doctor": "aboutDoctor",
    Education: "doctorEducation",
    "Area of Speciality": "areaOfSpeciality",
    Specialities: "specialities",
    Address: "doctorOfficeAdd",
    City: "doctorOfficeCity",
    State: "doctorOfficeState",
    Zip: "doctorOfficeZip",
    Phone: "partnerPhone",
    "Doctor Practice Name": "doctorPracticeName",
    Website: "partnerAddress",
    "Doctor Facility": "doctorFacility",
    "Surgery Center": "surgeryCenter",
    "Google Rating": "googleRating",
    "Facebook Rating": "facebookRating",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const setEmployeesRow = function (row) {
    return {
      key: row?.id,
      Action: row?.id,
      searchByName: row?.searchByName,
      Phone: row?.phone,
      createdAt: moment(row?.createdAt).format("DD/MM/YYYY"),

      searchEmail: row?.emailID,
      FirstName: row?.profile?.firstName
        ? row?.profile?.firstName
        : row?.firstName
        ? row?.firstName
        : "-",
      LastName: row?.profile?.lastName
        ? row?.profile?.lastName
        : row?.lastName
        ? row?.lastName
        : "-",
    };
  };

  const columns = [
    {
      title: "Email",
      dataIndex: "searchEmail",
    },
    {
      title: "Phone",
      dataIndex: "Phone",
    },
    {
      title: "First Name",
      dataIndex: "FirstName",
    },
    {
      title: "Last Name",
      dataIndex: "LastName",
    },
    {
      title: "Date of Signup",
      dataIndex: "createdAt",
    },
    {
      title: "",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/member-detail" state={{ id: [id] }}>
                    Edit
                  </Link>
                </Menu.Item>
                {}
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchDoctor = useCallback(async () => {
    try {
      setLoading(true);
      const filter = {
        promotionalID: { contains: id },
      };
      const response = await ListEmployeesRegis(filter);
      const data = response.map((row) => setEmployeesRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setdoctordata(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDoctor();
  }, [fetchDoctor]);

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "Action",
      "referal",
      "email",
      "name",
      "key",
      "searchByName",
    ];
    const filteredData = search(doctorData, currValue, searchFields);
    setDataSource(filteredData);
  };

  return (
    <>
      {contextHolder}
      <Row justify="space-between">
        <BackButton />
        <Typography.Title level={4} className="text-center mb-0">
          Joined members
          <Badge
            size="default"
            color="#00406A"
            count={doctorData?.length === 0 ? "O" : doctorData?.length}
            style={{
              width: 40,
              height: 27,
              marginLeft: 5,
              borderRadius: 20,
              marginRight: 0,
              paddingTop: 3,
              fontSize: 14,
            }}
          />
        </Typography.Title>
        <Button style={{ marginLeft: 60 }}></Button>
        <Divider style={{ margin: "8px 0" }} />
      </Row>

      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <CSVLink
            data={resData}
            headers={headers}
            filename={"Partners.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>
        </Row>
      </Row>

      <Table
        columns={columns}
        dataSource={dataSource}
        className="common-table"
        scroll={{ x: 992 }}
        pagination={{ position: ["", "bottomCenter"] }}
        loading={loading}
      />
    </>
  );
}
