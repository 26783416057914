// import React, { useEffect } from "react";
// import formatTime from "../utils/formatTime.js";
// import formatDate from "../utils/formatDate.js";
// import { useSelector } from "react-redux";

// const Conversation = ({ lastMsgRef, messages }) => {
//   const userData = useSelector((state) => state.userReducer?.userData);

//   const sortedMessages = messages?.items?.sort(
//     (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
//   );

//   useEffect(() => {
//     if (lastMsgRef.current) {
//       lastMsgRef.current.scrollIntoView();
//     }
//   }, [sortedMessages]);

//   return (
//     <div>
//       {sortedMessages?.map((message, msgIndex) => {
//         return (
//           <>
//             <div className="chat__date-wrapper">
//               <span className="chat__date">
//                 {formatDate(message.createdAt)}{" "}
//               </span>
//             </div>
//             <div className="chat__msg-group">
//               <p
//                 key={msgIndex}
//                 className={`chat__msg ${
//                   message?.senderID === userData.id
//                     ? "chat__msg--sent"
//                     : "chat__msg--rxd"
//                 }`}
//               >
//                 <div>
//                   {message?.content.startsWith("https") ? (
//                     <div className={`chat__msg chat__img-wrapper`}>
//                       <img
//                         src={message.content}
//                         alt="Attachment"
//                         className="chat__img"
//                       />
//                     </div>
//                   ) : (
//                     <span>{message?.content}</span>
//                   )}
//                   <span className="chat__msg-footer">
//                     {formatTime(message.createdAt)}
//                   </span>
//                 </div>
//               </p>
//             </div>
//           </>
//         );
//       })}
//       <div ref={lastMsgRef}></div>
//     </div>
//   );
// };

// export default Conversation;

import React, { useEffect } from "react";
import formatTime from "../utils/formatTime.js";
import formatDate from "../utils/formatDate.js";
import { useSelector } from "react-redux";
import PdfPreview from "utility/pdfPreview.js";
import { Image } from "antd";

const Conversation = ({ lastMsgRef, messages }) => {
  const userData = useSelector((state) => state.userReducer?.userData);

  const sortedMessages = messages?.items?.sort(
    (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
  );

  const dateGroups = [];
  let currentDateGroup = [];

  sortedMessages?.forEach((message, index) => {
    const messageDate = formatDate(message.createdAt);
    const previousMessage = sortedMessages[index - 1];

    if (
      previousMessage &&
      formatDate(previousMessage.createdAt) === messageDate
    ) {
      currentDateGroup.push(message);
    } else {
      if (currentDateGroup.length > 0) {
        dateGroups.push({ date: messageDate, messages: currentDateGroup });
      }
      currentDateGroup = [message];
    }
  });

  if (currentDateGroup.length > 0) {
    dateGroups.push({
      date: formatDate(currentDateGroup[0].createdAt),
      messages: currentDateGroup,
    });
  }

  useEffect(() => {
    if (lastMsgRef.current) {
      lastMsgRef.current.scrollIntoView();
    }
  }, [dateGroups]);

  return (
    <div>
      {dateGroups.map((group, index) => {
        return (
          <>
            <div className="chat__date-wrapper">
              <span className="chat__date">{group.date} </span>
            </div>
            {group.messages
              .filter((message) => message?.senderID !== null)
              .map((message, msgIndex) => {
                return (
                  <div className="chat__msg-group">
                    <p
                      key={msgIndex}
                      className={`chat__msg ${
                        message?.senderID === userData.id
                          ? "chat__msg--sent"
                          : "chat__msg--rxd"
                      }`}
                    >
                      <div>
                        {/* {message?.content.startsWith("https") ? (
                        <div className={`chat__msg chat__img-wrapper`}>
                          <img
                            src={message.content}
                            alt="Attachment"
                            className="chat__img"
                          />
                        </div>
                      ) : (
                        <span>{message?.content}</span>
                      )} */}
                        {message?.content?.startsWith("https") ? (
                          <div className={`chat__msg chat__img-wrapper`}>
                            {message.content.toLowerCase().endsWith(".pdf") ? (
                              <PdfPreview pdfUrl={message.content} />
                            ) : message.content
                                .toLowerCase()
                                .endsWith(".png") ||
                              message.content.toLowerCase().endsWith(".jpg") ? (
                              <Image
                                src={message.content}
                                alt="file"
                                className="chat__img"
                              />
                            ) : (
                              <p>Unsupported file format</p>
                            )}
                          </div>
                        ) : (
                          <span>
                            {message?.content}{" "}
                            {message?.sender === "system-admin"
                              ? formatTime(message?.createdAt)
                              : ""}
                          </span>
                        )}
                        <span className="chat__msg-footer">
                          {formatTime(message.createdAt)}
                        </span>
                      </div>
                    </p>
                  </div>
                );
              })}
          </>
        );
      })}
      <div ref={lastMsgRef}></div>
    </div>
  );
};

export default Conversation;
