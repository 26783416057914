import React from "react";
import { Button, Modal, Space } from "antd";

const DynamicConfirmModal = ({
  visible,
  onConfirm,
  onCancel,
  content,
  loading,
}) => {
  return (
    <Modal
      title={content}
      open={visible}
      closable={false}
      centered
      onCancel={onCancel}
      className="reward-success-modal"
      footer={null}
    >
      <h3 className="mt-20">{content}</h3>
      <Space style={{ display: "flex", justifyContent: "center" }}>
        <Button
          type="primary"
          className="theme-btn"
          style={{ width: "106px" }}
          onClick={onConfirm}
          loading={loading}
        >
          Yes
        </Button>
        <Button
          type="primary"
          className="theme-btn"
          onClick={onCancel}
          style={{ width: "106px", background: "grey" }}
        >
          Cancel
        </Button>
      </Space>
    </Modal>
  );
};

export default DynamicConfirmModal;
