import { Grid } from "antd";
import React from "react";
import Joyride from "react-joyride";

const GlobalJoyride = ({ steps, run, stepIndex, setTourIndex }) => {
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  return (
    <Joyride
      hideCloseButton
      disableScrolling
      run={run}
      stepIndex={stepIndex}
      hideBackButton
      disableCloseOnEsc
      showProgress
      disableOverlayClose
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          backgroundColor: "#00274C",
          arrowColor: "#ffffff",
          textColor: "white",
          width: screens?.xs ? "350px" : "400px",
        },
        buttonNext: {
          display: "none",
        },
      }}
      callback={(data) => {
        const { index, type } = data;
        if (type === "step:before") {
          setTourIndex(index);
        }
      }}
    />
  );
};

export default GlobalJoyride;
