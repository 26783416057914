// const formatTime = (timeString) => {
// 	let splitTimeString = timeString.split(":");
// 	return `${splitTimeString[0]}:${splitTimeString[1]}`;
// };

const formatTime = (timestamp) => {
  const currentDate = new Date();
  const messageDate = new Date(timestamp);

  const timeDifference = currentDate - messageDate;
  const isMoreThan24Hours = timeDifference > 24 * 60 * 60 * 1000;

  if (isMoreThan24Hours) {
    return new Date(timestamp).toLocaleString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  } else {
    return new Date(timestamp).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }
};

export default formatTime;
