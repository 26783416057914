import {
  Row,
  Input,
  Button,
  Table,
  Form,
  Col,
  Modal,
  Typography,
  message,
  Divider,
  Select,
  Dropdown,
  Menu,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { SearchOutlined, MoreOutlined } from "@ant-design/icons";
import exportCSV from "assets/icons/exportcsv.svg";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import {
  disableSubmitButton,
  emailPattern,
  formatCSVData,
  imagingCenterOptions,
  servicesNamePattern,
  urlPattern,
} from "utility/constant";
import { CreateIcCenter, DeleteIC, ListICcenterByName } from "utility/action";
import search from "utility/search";
import SuccessModel from "utility/SuccessModel";
import DeleteModal from "utility/DeleteModal";
import { handleStatusChange } from "utility/StatusChange";
import { DeleteService } from "utility/DeleteService";
import TableSkeleton from "utility/TableSkeleton";

export default function ImagingCenter() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [icData, setICData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [createLoading, setCreateLoading] = useState(false);
  const [successModel, setSuccessModel] = useState(false);
  const [dataSource, setDataSource] = useState(icData);
  const [messageApi, contextHolder] = message.useMessage();
  const [deleteModelPrompt, setDeleteModelPrompt] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [form] = Form.useForm();
  const { Option } = Select;

  const labelKeyMap = {
    "Ic Name": "icOfficeName",
    "Ic Email": "icEmail",
    Phone: "icOfficePhone",
    Website: "icWebsite",
    Specialities: "icSpecialities",
    About: "aboutIC",
    Address: "icOfficeAdd",
    "Address Line 2": "icAddLine2",
    City: "icOfficeCity",
    State: "icOfficeState",
    Zip: "icOfficeZip",
    "Google Rating": "icGoogleRating",
    "Facebook Rating": "icFaceBookRating",
    "Google Map Link": "icMapSource",
  };

  const { headers, resData } = formatCSVData(labelKeyMap, csvData);

  const handleFormChange = disableSubmitButton(["icOfficeName"])(
    form,
    setSubmitDisabled
  );

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
    form.resetFields();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSuccessModel(false);
    setDeleteModelPrompt(false);
    form.resetFields();
  };

  const setICRow = function (row) {
    return {
      key: row?.id,
      searchByName: row?.searchByName,
      Action: row?.id,
      IcWebsite: row?.icWebsite,
      OfficeName: row?.icOfficeName,
      OfficeEmail: row?.icEmail,
      createdAt: row?.createdAt,
      Specialties: (
        <div style={{ whiteSpace: "pre-wrap" }}>
          {row?.icSpecialities?.join("\n")}
        </div>
      ),
    };
  };

  const columns = [
    {
      title: "Imaging Center Name",
      dataIndex: "OfficeName",
      width: "30%",
    },
    {
      title: "Imaging Center Website",
      dataIndex: "IcWebsite",
      width: "20%",
    },
    {
      title: "Imaging Center Email",
      dataIndex: "OfficeEmail",
      width: "25%",
    },
    {
      title: "Imaging Center Specialties",
      dataIndex: "Specialties",
      width: "20%",
    },
    {
      title: "",
      width: "5%",
      dataIndex: "Action",
      className: "actions",
      render: (id) => {
        return (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item key={1}>
                  <Link to="/imaging-center-detail" state={{ id: id }}>
                    Edit
                  </Link>
                </Menu.Item>
                <Menu.Item key={2}>
                  <Link
                    rel="noopener noreferrer"
                    onClick={() => {
                      setDeleteModelPrompt(true);
                      setDeleteId(id);
                    }}
                  >
                    Delete
                  </Link>
                </Menu.Item>
              </Menu>
            }
            overlayClassName="download-btn"
            placement="bottomRight"
          >
            <MoreOutlined />
          </Dropdown>
        );
      },
    },
  ];

  const fetchIcCenter = useCallback(async () => {
    try {
      setLoading(true);
      const response = await ListICcenterByName(999);
      const data = response.map((row) => setICRow(row));
      data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setICData(data);
      setDataSource(data);
      setCsvData(response);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchIcCenter();
  }, [fetchIcCenter]);

  const createIcCenter = async (values) => {
    try {
      setDeleteId("");
      setCreateLoading(true);
      const inputData = {
        icOfficeName: values?.icOfficeName,
        searchByName: (values?.icOfficeName).toLowerCase(),
        icWebsite: values?.icWebsite,
        icSpecialities: values?.icSpecialities,
        icEmail: values?.icEmail,
        byName: "ic",
        activeOnPortal: false,
      };

      const response = await CreateIcCenter(inputData);
      const mappedData = {};
      Object.entries(response).forEach(([key, value]) => {
        if (value !== null) {
          mappedData[key] = value;
        }
      });
      const tableData = {
        searchByName: mappedData?.searchByName,
        Action: mappedData?.id,
        OfficeName: mappedData?.icOfficeName,
        OfficeEmail: mappedData?.icEmail,
        IcWebsite: mappedData?.icWebsite,
        createdAt: mappedData?.createdAt,
        Specialties: (
          <div style={{ whiteSpace: "pre-wrap" }}>
            {mappedData?.icSpecialities?.join("\n")}
          </div>
        ),
      };

      const sortByCreatedAt = (data) =>
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setICData((previous) => sortByCreatedAt([...previous, tableData]));
      setDataSource((previous) => sortByCreatedAt([...previous, tableData]));

      setIsModalOpen(false);
      form.resetFields();
      setSuccessModel(true);
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setCreateLoading(false);
    }
  };

  const handleSearch = async (e) => {
    const currValue = e.target.value.trim();
    const searchFields = [
      "IcWebsite",
      "OfficeName",
      "OfficeEmail",
      "Speciality",
    ];
    const filteredData = search(icData, currValue, searchFields);
    setDataSource(filteredData);
  };

  const handleChange = async (value) => {
    try {
      setSelectedValue(value);
      setLoading(true);
      const filterData = await handleStatusChange(
        value,
        setICRow,
        ListICcenterByName,
        icData
      );
      setDataSource(filterData);
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteSurgicalCenter = async () => {
    try {
      setDeleteLoading(true);
      await DeleteService(deleteId, DeleteIC);
      setDeleteModelPrompt(false);
      setSuccessModel(true);
      setSelectedValue(null);
      fetchIcCenter();
      setTimeout(() => {
        setSuccessModel(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
    } finally {
      setDeleteLoading(false);
    }
  };

  return (
    <>
      {contextHolder}
      <Typography.Title level={4} className="text-center mb-0">
        Imaging Center
      </Typography.Title>
      <Divider />
      <Row justify="space-between table-header">
        <Row align="middle">
          <Input
            prefix={<SearchOutlined />}
            placeholder="Search"
            className="searchbar"
            onChange={handleSearch}
            style={{ width: 250 }}
          />
        </Row>

        <Row className="gap-10 mtr-20 bold-font filter-dropdown-list">
          <Select
            allowClear
            placeholder="Status"
            style={{ width: 100 }}
            onChange={handleChange}
            value={selectedValue}
            getPopupContainer={(trigger) => trigger.parentNode}
          >
            <Option value="ACTIVE">ACTIVE</Option>
            <Option value="INACTIVE">INACTIVE</Option>
          </Select>

          <CSVLink
            data={resData}
            headers={headers}
            filename={"IC Center.csv"}
            className="export-btn"
          >
            <img src={exportCSV} alt="" className="action-icons" />
          </CSVLink>

          <Button className="blue-btn inherit-btn" onClick={showModal}>
            Add New
          </Button>
        </Row>
      </Row>

      {loading ? (
        <TableSkeleton />
      ) : (
        <Table
          columns={columns}
          dataSource={dataSource}
          className="common-table"
          scroll={{ x: 992 }}
          pagination={{ position: ["", "bottomCenter"] }}
        />
      )}

      <Modal
        className="form-modal"
        centered
        footer={null}
        width={700}
        title={
          <Typography.Title level={3} className="modal-title">
            Add Imaging center
          </Typography.Title>
        }
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          name="basic"
          form={form}
          onFinish={createIcCenter}
          autoComplete="off"
          layout="vertical"
          onFieldsChange={handleFormChange}
        >
          <Row gutter={20}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Imaging center name"
                name="icOfficeName"
                rules={[
                  {
                    required: true,
                    message: "Please enter a name",
                  },
                  {
                    pattern: servicesNamePattern,
                    message: "Please enter a valid name",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Imaging center website"
                name="icWebsite"
                rules={[
                  {
                    pattern: urlPattern,
                    message: "Please input valid url",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Imaging center email"
                name="icEmail"
                rules={[
                  {
                    pattern: emailPattern,
                    message: "Please enter a valid email",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                label="Imaging center specialities"
                name="icSpecialities"
              >
                <Select
                  mode="multiple"
                  allowClear
                  maxTagCount="responsive"
                  options={imagingCenterOptions}
                  getPopupContainer={(trigger) => trigger.parentNode}
                ></Select>
              </Form.Item>
            </Col>

            <Col span={12} md={24} xs={24} sm={24} className="center-btn">
              <Button
                className="theme-btn"
                htmlType="submit"
                loading={createLoading}
                disabled={submitDisabled}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>

      <SuccessModel
        message={
          deleteId !== ""
            ? "Imaging Center Deleted Successfully"
            : "Imaging Center Added Successfully"
        }
        open={successModel}
      />

      <DeleteModal
        content="Are you sure you want to delete the imaging center?"
        open={deleteModelPrompt}
        onClick={deleteSurgicalCenter}
        buttonLoading={deleteLoading}
        onOk={handleOk}
        onCancel={handleCancel}
        buttonText="Yes"
      />
    </>
  );
}
