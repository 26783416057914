import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Breadcrumb,
  Button,
  Card,
  Tabs,
  Modal,
  Skeleton,
  message,
  Grid,
} from "antd";
import { StarFilled } from "@ant-design/icons";
import Container from "commons/Container";
import "./DoctorProfile.less";
import { Link, useLocation, useNavigate } from "react-router-dom";
import facebook from "assets/icons/facebook.svg";
import google from "assets/icons/google.svg";
import operates from "assets/icons/operates.svg";
import overview from "assets/icons/overview.svg";
import appointment from "assets/icons/appointment.svg";
import DoctorOverview from "../DoctorOverview/DoctorOverview";
import DoctorOperatesAt from "../DoctorOperatesAt/DoctorOperatesAt";
import AppointmentRequest from "../AppointmentRequest/AppointmentRequest";
import Chat from "pages/user/SupportChatbox/Chat";

import { GetPublicFileFromS3 } from "utility/uploadFileToS3";
import { GetDoctorDetails } from "utility/action";
import { useDispatch, useSelector } from "react-redux";
import { setTourSteps } from "store/actions/tourSteps";
import {
  DOCTOR_TOUR_END,
  DOC_PROFILE_TOUR_START,
  END_TOUR,
} from "utility/constant";
import TourStepContent from "utility/TourStepContent";
import GlobalJoyride from "utility/GlobalJoyride";

export default function DoctorProfile() {
  const [doctorData, setDoctorData] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  // for chat box
  const [isSupportModalOpen, setSupportIsModalOpen] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [doctorPhoto, setDoctorPhoto] = useState("");
  let location = useLocation();

  const docID = location?.state?.id;
  const search = location?.state?.search;
  const surgeryCenterName = location?.state?.surgicalName;
  const tourSteps = useSelector((state) => state.tourReducer?.tourSteps);
  const [docTour, setDocTour] = useState(false);
  const [docTourIndex, setDocTourIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("item-1");

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const showModalSupport = () => {
    setSupportIsModalOpen(true);
  };

  const handleOkSupport = () => {
    setSupportIsModalOpen(false);
  };

  const handleCancelSupport = () => {
    setSupportIsModalOpen(false);
  };

  const fetchDocData = useCallback(async () => {
    try {
      setSkeletonLoader(true);
      const response = await GetDoctorDetails(docID);
      setDoctorData(response);
      if (response?.doctorProfilePhoto) {
        let doctorPhoto;

        if (response?.doctorProfilePhoto.startsWith("http")) {
          doctorPhoto = response?.doctorProfilePhoto;
        } else {
          doctorPhoto = await GetPublicFileFromS3(response?.doctorProfilePhoto);
        }
        setDoctorPhoto(doctorPhoto);
      }
    } catch (error) {
      messageApi.open({
        type: "error",
        content: "Something went wrong",
      });
      console.log(error);
    } finally {
      setSkeletonLoader(false);
      if (tourSteps === DOC_PROFILE_TOUR_START) {
        setDocTour(true);
      }
      setTimeout(() => {
        dispatch(setTourSteps(DOCTOR_TOUR_END));
      }, 500);
    }
  }, [docID]);

  useEffect(() => {
    fetchDocData();
  }, [fetchDocData]);

  const items = [
    {
      label: (
        <span className="sub-header-font ">
          <img src={overview} className="tabs-icon" />
          {(activeTab === "item-1" && screens.xs) || screens.sm || screens.lg
            ? "Overview"
            : ""}
        </span>
      ),
      key: "item-1",
      children: <DoctorOverview data={doctorData} loader={skeletonLoader} />,
    },
    {
      label: (
        <span className="sub-header-font">
          <img src={operates} className="tabs-icon" />
          {(activeTab === "item-2" && screens.xs) || screens.sm || screens.lg
            ? "Operates At"
            : ""}
        </span>
      ),
      key: "item-2",
      children: <DoctorOperatesAt data={doctorData} loader={skeletonLoader} />,
      className: "services",
    },
    {
      label: (
        <span className="sub-header-font">
          <img src={appointment} className="tabs-icon" />
          {(activeTab === "item-3" && screens.xs) || screens.sm || screens.lg
            ? "Request An Appointment"
            : ""}
        </span>
      ),
      key: "item-3",
      children: <AppointmentRequest data={doctorData} />,
    },
  ];

  const handleEndTour = () => {
    setDocTour(false);
    dispatch(setTourSteps(END_TOUR));
  };

  const serviceSteps = [
    {
      target: ".doc-first-step",
      content: (
        <TourStepContent
          heading="Doctor Profile"
          details="Simply click on different tabs to explore all the details
          related to the Doctor."
          numbers={{ currentStep: 9, totalSteps: 9 }}
          onSkipTour={handleEndTour}
        />
      ),
      disableBeacon: true,
      spotlightClicks: true,
      placement: "bottom",
    },
  ];

  return (
    <>
      {contextHolder}

      <GlobalJoyride
        steps={serviceSteps}
        run={docTour}
        stepIndex={docTourIndex}
        setTourIndex={setDocTourIndex}
      />

      <Container>
        <Breadcrumb className="hide-version-no">
          <Breadcrumb.Item>
            <Link to="/" className="p-font">
              Home
            </Link>
          </Breadcrumb.Item>
          {!search && (
            <Breadcrumb.Item>
              <Link
                to="/doctor-list"
                state={{ surgicalName: surgeryCenterName }}
                className="p-font"
              >
                Doctor List
              </Link>
            </Breadcrumb.Item>
          )}

          <Breadcrumb.Item>
            <span className="p-font">{doctorData?.doctorName}</span>
          </Breadcrumb.Item>
        </Breadcrumb>

        <Card
          className="doctor-profile doc-first-step mobile-card"
          onClick={() => {
            if (docTour) {
              navigate("/");
              setDocTour(false);
            }
          }}
        >
          <Row>
            {skeletonLoader ? (
              <>
                {" "}
                <Skeleton avatar paragraph={{ rows: 2 }}></Skeleton>{" "}
              </>
            ) : (
              <>
                <Col>
                  <div className="d-flex gap-4">
                    <img
                      src={doctorPhoto}
                      alt=""
                      className="doctor-img image"
                    />
                    <div className="doctor-detail">
                      <h4 className="doctor-name header-font">
                        {doctorData?.doctorName}, {doctorData?.doctorDegree}
                      </h4>
                      <h6 className="sub-header-font">
                        {doctorData?.doctorPracticeName}
                      </h6>
                      <div className="d-flex gap-3">
                        <p className="p-font">
                          <img src={facebook} alt="facebook Icon" />

                          {doctorData?.facebookRating === 0 ||
                          doctorData?.facebookRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {doctorData?.facebookRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>
                        <p className="p-font">
                          <img src={google} alt="facebook Icon" />

                          {doctorData?.googleRating === 0 ||
                          doctorData?.googleRating === null ? (
                            "NA"
                          ) : (
                            <>
                              {doctorData?.googleRating}{" "}
                              <StarFilled style={{ color: "#e7a500" }} />
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </>
            )}
          </Row>
        </Card>
        {/* <div className="doc-first-step" style={{background: "#fff", height: "50px"}}></div> */}
        <Card className="profile-tabs mobile-card">
          <Row>
            <Col span={24}>
              <Tabs items={items} onChange={handleTabChange}></Tabs>
            </Col>
          </Row>
        </Card>
      </Container>

      
    
    </>
  );
}
