import React from "react";
import { Typography } from "antd";
import "./footer.less";
import moment from "moment";

export default function Footer() {
  return (
    <div className="footer-auth hide-version-no">
      <Typography.Text className="footer-text">
        Version {process.env.REACT_APP_VERSION} © {moment().year()}{" "}
        <a
          href="https://delphihc.com/"
          target="_blank"
          style={{ color: "inherit" }}
        >
          delphihc.com
        </a>{" "}
        copyright all rights reserved.
      </Typography.Text>
    </div>
  );
}
