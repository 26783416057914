import React from "react";
import { Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useEffect } from "react";
import { useState } from "react";
import FullPageLayout from "containers/FullPageLayout/FullPageLayout";

export default function PrivateRouteMFA(props) {
  const [loading, setLoading] = useState(true);
  const [isMFASet, setIsMFASet] = useState(false);

  useEffect(() => {
    const checkMFAStatus = async () => {
      try {
        await Auth.currentAuthenticatedUser();
        setIsMFASet(true);
      } catch (error) {
        console.error("Error checking MFA status:", error);
      } finally {
        setLoading(false);
      }
    };

    checkMFAStatus();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    !loading &&
    (isMFASet ? (
      <FullPageLayout>{props.children}</FullPageLayout>
    ) : (
      <Navigate to="/login" />
    ))
  );
}
